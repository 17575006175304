import { faSave, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { Component } from "react";
import {
  Button as MuiButton,
  CircularProgress as Spinner,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  Collapse,
  ListItem,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Card } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { store } from "../../../app/store";
import {
  IGameApp,
  IronsourceBridge,
  IronsourceBridges,
  Version,
} from "../../../model/game-app.model";
import { TooltipState } from "../../../model/versioned-settings.model";
import { postData } from "../../../network/request";
import { ConfirmationModal } from "../ConfirmationModal";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";
import { Styles } from "../../../styles";
import { AppStoreAccount } from "../../../model/app-store.model";
import { Flex } from "../../../components/Flex";

const BUNDLE_ID = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
const EXTERNAL_ID = {
  store_id: /^(0|[1-9][0-9]*)$/,
  tenjin:
    /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/,
};
const NUMBER_REGEX = /^[-]?[0-9]+[.][0-9]+|[-]?[0-9]+$/i;

interface Props {
  isOpen: boolean;
  toggle: () => void;
  gameApp: IGameApp;
  setTooltip: (state?: TooltipState) => void;
  removeGameApp: (objectId: string) => void;
  ironsourceBridges: IronsourceBridges | undefined;
  config?: { [id: string]: any };
  classes: any;
  appStoreAccounts?: AppStoreAccount[];
}

interface Errors {
  tenjin: boolean;
  store_id: boolean;
}

interface State {
  gameApp?: IGameApp;
  bundleIds?: string[];
  ironsourceId?: string;
  filteredVersions?: Version[];
  versionFilterString?: string;
  newVersionNumber?: string;
  confirmDeleteModalOpen?: boolean;
  deletionItem?: string;
  deletionId?: string;
  versionNumberToDelete?: string;
  external_ids?: { [id: string]: string };
  unusedKeys?: string[];
  newKey?: string;
  mediator?: string;
  attributor?: string;
  externalIdErrors?: Errors;
  bundleIdErrors?: Array<boolean>;
  appStoreId?: string | undefined;
}

class GameEdition extends Component<Props, State> {
  state: State = {
    externalIdErrors: {
      tenjin: false,
      store_id: false,
    },
    bundleIdErrors: [],
    newKey: "",
  };
  private versionRef = React.createRef<HTMLInputElement>();
  componentDidMount(): void {
    const { gameApp } = this.props;
    // Set state of fields
    this.setState(
      {
        gameApp: gameApp,
        bundleIds: gameApp.bundle_ids,
        ironsourceId: gameApp.iron_source_bridge_id,
        filteredVersions: gameApp.versions,
        external_ids: gameApp.external_ids,
        mediator: gameApp.mediator,
        attributor: gameApp.attributor,
        appStoreId: gameApp.appstore_id,
      },
      () => {
        this.filterVersions();
        this.setUnusedKeys();
      }
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.config === undefined && this.props.config) {
      this.setUnusedKeys();
    }
  }

  setUnusedKeys() {
    if (!this.props.config) {
      return;
    }

    const unusedKeys = this.getUnusedKeys(
      Object.keys(this.props.gameApp.external_ids),
      Object.keys(this.props.config?.settings.game_app_external_keys)
    );

    this.setState({
      unusedKeys,
      newKey: unusedKeys[0],
    });
  }

  handleChange(stateName: string, e: any) {
    e.stopPropagation();

    this.setState({ [stateName]: e.target.value });
  }

  addNewVersion() {
    if (!this.state.newVersionNumber) {
      return;
    }

    const newVersion = {
      object_type: "game_app_version",
      version_number: this.state.newVersionNumber,
    };

    this.setState(
      (prevState) => {
        return {
          gameApp: {
            ...prevState.gameApp!,
            versions: [...prevState.gameApp!.versions!, newVersion],
          },
        };
      },
      () => {
        this.filterVersions();
        this.setState({ newVersionNumber: "" }, () => this.updateGameApp());
      }
    );
  }

  updateGameApp(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e) {
      e.stopPropagation();
    }

    const updatedGameApp = { ...this.props.gameApp };

    updatedGameApp.bundle_ids = this.state.bundleIds!;
    updatedGameApp.iron_source_bridge_id = this.state.ironsourceId!;
    updatedGameApp.versions = this.state.gameApp!.versions;
    updatedGameApp.external_ids = this.state.external_ids!;
    updatedGameApp.mediator = this.state.mediator!;
    updatedGameApp.attributor = this.state.attributor!;
    updatedGameApp.cas_token = this.state.gameApp!._cas;
    updatedGameApp.appstore_id = this.state.appStoreId;

    // Post new game edition object
    postData(
      `admin/game-apps/${updatedGameApp.object_id}/update`,
      updatedGameApp
    )
      .then((res) => {
        this.setState({ gameApp: res.game_app });
        store.dispatch(
          queueNotification({
            message: "Game app successfully updated!",
            options: {
              key: "game_update",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          store.dispatch(
            queueNotification({
              message:
                "Someone has updated this object since you loaded this page, you\n" +
                "              cannot save. Refresh the page and try again",
              options: {
                key: "game_app_update_concurrency_warning",
                variant: SnackbarVariant.WARNING,
              },
            })
          );
        } else {
          store.dispatch(
            queueNotification({
              message: "Error updating game settings, please try again",
              options: {
                key: "game_app_update_error",
                variant: SnackbarVariant.ERROR,
              },
            })
          );
        }
      });
  }

  filterVersions() {
    const { gameApp, versionFilterString } = this.state;

    let filteredVersions;

    if (versionFilterString) {
      filteredVersions = gameApp!.versions!.filter((version: Version) =>
        version.version_number.includes(versionFilterString)
      );
    } else {
      filteredVersions = gameApp!.versions!;
    }

    filteredVersions = filteredVersions.sort((a: Version, b: Version) =>
      b.version_number
        // @ts-ignore
        .replace(/\d+/g, (n) => +n + 100000)
        // @ts-ignore
        .localeCompare(a.version_number.replace(/\d+/g, (n) => +n + 100000))
    );

    this.setState({ filteredVersions });
  }

  openDeleteModal(
    event: any,
    deletionItem: string,
    deletionId: string,
    versionNumberToDelete?: string
  ) {
    event.stopPropagation();
    this.setState({
      confirmDeleteModalOpen: true,
      deletionItem,
      deletionId,
      versionNumberToDelete,
    });
  }

  deleteItem() {
    const { deletionItem, deletionId } = this.state;

    switch (deletionItem) {
      case "Game Edition":
        this.props.removeGameApp(deletionId!);
        break;
      case "Version":
        this.deleteVersion();
        break;
    }
  }

  deleteVersion() {
    // Delete version at that index
    const versions = Array.from(this.state.gameApp!.versions);
    const index = versions.findIndex(
      (version) => version.version_number === this.state.versionNumberToDelete!
    );

    versions.splice(index, 1);

    this.setState(
      (prevState) => {
        return {
          gameApp: {
            ...prevState.gameApp!,
            versions: versions,
          },
        };
      },
      () => {
        this.setState({ versionNumberToDelete: undefined }, () => {
          this.updateGameApp();
          this.filterVersions();
        });
      }
    );
  }

  updateVersions(event: any, index: number) {
    const filteredVersions = Array.from(this.state.filteredVersions!);

    filteredVersions[index].version_number = event.target.value;
    this.setState({ filteredVersions });
  }

  addExternalID(newKey?: string) {
    if (!this.props.config || !newKey) {
      return;
    }

    const externalIDs: { [id: string]: any } = { ...this.state.external_ids };

    externalIDs[newKey] = "";

    // Remove from unusedKeys
    const unusedKeys = [...(this.state.unusedKeys ?? [])];
    unusedKeys.splice(unusedKeys.indexOf(newKey), 1);

    this.setState({
      external_ids: externalIDs,
      unusedKeys,
      newKey: unusedKeys[0],
    });
  }

  deleteExternalId(key: string) {
    const externalIDs: { [id: string]: any } = { ...this.state.external_ids };
    const externalIdErrors = this.state.externalIdErrors;

    if (key === "tenjin") {
      externalIdErrors!.tenjin = false;
    }
    if (key === "store_id") {
      externalIdErrors!.store_id = false;
    }

    delete externalIDs[key];

    // Add to unusedKeys
    const unusedKeys = [...(this.state.unusedKeys ?? [])];
    unusedKeys.push(key);

    this.setState({
      external_ids: externalIDs,
      unusedKeys,
      newKey: unusedKeys[0],
      externalIdErrors,
    });
  }

  getUnusedKeys(externalIDS: string[], allowedKeys: string[]) {
    return allowedKeys.filter((key: string) => !externalIDS.includes(key));
  }

  addBundleId() {
    const bundleIds = [...(this.state.bundleIds ?? [])];

    bundleIds.push("");

    this.setState({ bundleIds });
  }

  deleteBundleId(index: number) {
    const { bundleIdErrors = [] } = this.state;
    const bundleIds = [...(this.state.bundleIds ?? [])];
    bundleIdErrors.splice(index, 1);
    bundleIds.splice(index, 1);

    this.setState({ bundleIds, bundleIdErrors });
  }

  changeBundleId(index: number, value: string) {
    const { bundleIdErrors = [] } = this.state;
    const bundleIds = [...(this.state.bundleIds ?? [])];
    bundleIdErrors[index] = value ? !BUNDLE_ID.test(value) : false;
    bundleIds[index] = value;

    this.setState({ bundleIds, bundleIdErrors });
  }

  hasInvalidFields = () => {
    const { externalIdErrors, bundleIdErrors } = this.state;
    const saveDisabled =
      externalIdErrors?.tenjin ||
      externalIdErrors?.store_id ||
      bundleIdErrors?.some((e) => e);
    return saveDisabled;
  };

  render() {
    const {
      isOpen,
      toggle,
      gameApp,
      ironsourceBridges,
      classes,
      appStoreAccounts,
    } = this.props;
    const {
      bundleIds,
      bundleIdErrors = [],
      ironsourceId,
      filteredVersions,
      confirmDeleteModalOpen,
      deletionItem,
      appStoreId,
    } = this.state;

    const saveDisabled = this.hasInvalidFields();

    const filterAppStoreAccounts = (stringValue: string) => {
      const filteredAccounts = (appStoreAccounts || []).filter(
        (obj) => obj.game_edition_id === stringValue
      );

      return filteredAccounts;
    };

    return (
      <React.Fragment>
        <ListItem className={classes.collapseButton} onClick={toggle}>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Box>
              <Typography variant="h6" component="span">
                {gameApp.game_edition_id}
              </Typography>
              <Typography
                variant="h6"
                component="span"
                style={{ fontSize: 12 }}
                className={classNames("font-weight-lighter", "last-updated", {
                  "text-color-orange": isOpen,
                })}
              >
                &nbsp;Last updated{" "}
                {moment(gameApp.updated_on).format("DD/MM/YYYY HH:mm")}
              </Typography>
            </Box>
            <Box>
              <Tooltip title={saveDisabled ? "Please validate the fields" : ""}>
                <span onClick={(e) => e.stopPropagation()}>
                  <MuiButton
                    className={classNames(classes.button, classes.buttonSave)}
                    onClick={(event) => this.updateGameApp(event)}
                    disabled={saveDisabled}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </MuiButton>
                </span>
              </Tooltip>
              <MuiButton
                className={classNames(classes.button, classes.buttonRemove)}
                onClick={(event) =>
                  this.openDeleteModal(event, "Game Edition", gameApp.object_id)
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </MuiButton>
            </Box>
          </Flex>
        </ListItem>
        <Collapse in={isOpen} style={{ width: "100%" }}>
          <div className={classes.cardContainer}>
            <div className="game-app-settings" id="game-app-settings">
              <Box>
                <Typography component="div" className={classes.sectionTitle}>
                  Bundle IDs
                </Typography>
                <Card
                  className={classNames(
                    classes.sectionContainer,
                    classes.sectionBorder,
                    classes.cardContainer
                  )}
                >
                  {bundleIds && bundleIds.length !== 0 ? (
                    <Grid
                      container
                      spacing={2}
                      className={classes.sectionSubContainer}
                    >
                      {bundleIds.map((id, index) => (
                        <React.Fragment key={`${index}-${id}`}>
                          <Grid item md={12}>
                            <TextField
                              error={bundleIdErrors[index]}
                              fullWidth
                              helperText={
                                bundleIdErrors[index] &&
                                "Enter a valid bundle ID"
                              }
                              id={`game-app-bundle-id-${gameApp.object_id}`}
                              inputProps={{
                                className: classes.customTextfield,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className={classes.textfieldIcon}
                                    onClick={() => this.deleteBundleId(index)}
                                  >
                                    <Close />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) =>
                                this.changeBundleId(index, e.target.value)
                              }
                              placeholder="Enter Bundle ID"
                              value={id ?? ""}
                              variant="outlined"
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                  ) : (
                    <Flex display="flex" justifyContent="center">
                      <small className="text-muted">No Bundle IDs</small>
                    </Flex>
                  )}
                  <Flex display="flex" justifyContent="flex-end">
                    <MuiButton
                      className="px-0"
                      variant="outlined"
                      onClick={() => this.addBundleId()}
                    >
                      Add
                    </MuiButton>
                  </Flex>
                </Card>
              </Box>
              <Box>
                <Flex display="flex" flexDirection="column">
                  <Typography component="div" className={classes.sectionTitle}>
                    Ironsource Bridge ID
                  </Typography>
                  <Card className={classes.sectionContainer}>
                    <FormControl
                      fullWidth
                      className={classes.textFieldStyle}
                      size="small"
                    >
                      <Select
                        variant="outlined"
                        label="Appstore ID"
                        disabled={!ironsourceBridges}
                        placeholder="Enter Ironsource Bundle ID"
                        id={`game-app-ironsource-id-${gameApp.object_id}`}
                        value={ironsourceId ?? ""}
                        onChange={(e) => this.handleChange("ironsourceId", e)}
                      >
                        <MenuItem value="">None</MenuItem>
                        {ironsourceBridges &&
                          ironsourceBridges.map((bridge: IronsourceBridge) => {
                            return (
                              <MenuItem
                                key={bridge.object_id}
                                value={bridge.object_id}
                              >
                                {`${bridge.game_edition_id.toUpperCase()} | ${
                                  bridge.app_user
                                }`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Card>
                </Flex>
              </Box>
              <Box>
                <Flex display="flex" flexDirection="column">
                  <Typography component="div" className={classes.sectionTitle}>
                    Appstore ID
                  </Typography>
                  <Card className={classes.sectionContainer}>
                    <FormControl
                      fullWidth
                      size="small"
                      className={classes.textFieldStyle}
                    >
                      <Select
                        variant="outlined"
                        label="Appstore ID"
                        disabled={!appStoreAccounts}
                        placeholder="Select Appstore ID"
                        id={`game-appstore-id-${gameApp.object_id}`}
                        value={appStoreId ?? ""}
                        onChange={(e) => this.handleChange("appStoreId", e)}
                      >
                        <MenuItem value="">None</MenuItem>
                        {(
                          filterAppStoreAccounts(gameApp.game_edition_id) ?? []
                        )?.map((item: AppStoreAccount) => {
                          return (
                            <MenuItem
                              key={item?.object_id}
                              value={item?.object_id}
                            >
                              {item?.name?.toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Card>
                </Flex>
              </Box>
              <Typography component="div" className={classes.sectionTitle}>
                Mediator
              </Typography>
              <Card className={classes.sectionContainer}>
                <FormControl
                  fullWidth
                  size="small"
                  className={classes.textFieldStyle}
                >
                  <Select
                    value={this.state.mediator ?? ""}
                    className={classes.textFieldStyle}
                    variant="outlined"
                    label="Mediator"
                    id={`game-mediator-id-${gameApp.object_id}`}
                    onChange={(event) => this.handleChange("mediator", event)}
                  >
                    {this.props.config?.settings &&
                      Object.keys(
                        this.props.config?.settings.game_app_mediators
                      ).map((mediator) => (
                        <MenuItem value={mediator} key={mediator}>
                          {
                            this.props.config?.settings?.game_app_mediators[
                              mediator
                            ]
                          }
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Card>
              <Typography component="div" className={classes.sectionTitle}>
                Attributor
              </Typography>

              <Card className={classes.sectionContainer}>
                <FormControl
                  fullWidth
                  size="small"
                  className={classes.textFieldStyle}
                >
                  <Select
                    variant="outlined"
                    value={this.state.attributor ?? ""}
                    label="Attributor"
                    id={`game-attributor-id-${gameApp.object_id}`}
                    onChange={(event) => this.handleChange("attributor", event)}
                  >
                    {this.props.config?.settings &&
                      Object.keys(
                        this.props.config?.settings.game_app_attributors
                      ).map((attributor) => (
                        <MenuItem value={attributor} key={attributor}>
                          {
                            this.props.config?.settings?.game_app_attributors[
                              attributor
                            ]
                          }
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Card>
              <Box>
                <Flex display="flex" flexDirection="column">
                  <Typography component="div" className={classes.sectionTitle}>
                    External IDs
                  </Typography>
                  <Card
                    className={classNames(
                      classes.sectionContainer,
                      classes.sectionSubContainer,
                      classes.sectionBorder
                    )}
                  >
                    {this.renderExternalIDs()}

                    <FormControl
                      size="small"
                      className={classes.textFieldStyle}
                    >
                      <hr />
                      <Flex
                        display="flex"
                        alignItems="center"
                        justifyContent="end"
                      >
                        <Select
                          variant="outlined"
                          value={this.state.newKey ?? ""}
                          className="w-25"
                          onChange={(e) => this.handleChange("newKey", e)}
                          disabled={
                            this.props.config === undefined ||
                            (this.state.unusedKeys &&
                              this.state.unusedKeys.length === 0)
                          }
                        >
                          {this.state.unusedKeys?.map((key: string) => (
                            <MenuItem key={key} value={key}>
                              {
                                this.props.config?.settings
                                  .game_app_external_keys[key]
                              }
                            </MenuItem>
                          ))}
                        </Select>
                        <MuiButton
                          className={classes.externalAddButton}
                          disabled={
                            this.props.config === undefined ||
                            (this.state.unusedKeys &&
                              this.state.unusedKeys.length === 0)
                          }
                          variant="outlined"
                          onClick={() => this.addExternalID(this.state.newKey)}
                        >
                          Add
                        </MuiButton>
                      </Flex>
                    </FormControl>
                  </Card>
                </Flex>
              </Box>
              <Typography component="div" className={classes.sectionTitle}>
                Versions
              </Typography>
              <Card
                className={classNames(
                  classes.sectionBorder,
                  classes.sectionContainer
                )}
              >
                <Flex
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ m: 2, pb: 2 }}
                  className="text-muted"
                >
                  <TextField
                    fullWidth
                    inputProps={{
                      className: classes.customTextfield,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className={classes.textfieldSearchIcon}
                          onClick={() => this.addNewVersion()}
                        >
                          <FontAwesomeIcon icon={faSearch} className="mr-2" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(event) => {
                      this.setState(
                        { versionFilterString: event.target.value },
                        () => this.filterVersions()
                      );
                    }}
                    placeholder="Search"
                  />
                </Flex>
                <Flex
                  display="flex"
                  justifyContent="space-between"
                  className="create"
                  sx={{ my: 2 }}
                >
                  <TextField
                    fullWidth
                    inputProps={{
                      className: classes.customTextfield,
                    }}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Create">
                          <InputAdornment
                            position="end"
                            className={classes.textfieldIcon}
                            onClick={() => this.addNewVersion()}
                          >
                            ↵
                          </InputAdornment>
                        </Tooltip>
                      ),
                    }}
                    onChange={(e) => this.handleChange("newVersionNumber", e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.addNewVersion();
                      }
                    }}
                    placeholder="Add new version number"
                    value={
                      this.state.newVersionNumber
                        ? this.state.newVersionNumber
                        : ""
                    }
                    variant="outlined"
                  />
                </Flex>
                {filteredVersions && filteredVersions.length === 0 ? (
                  <Flex display="flex" justifyContent="center" sx={{ mb: 1 }}>
                    <small className="text-muted text-center">
                      No Versions Found
                    </small>
                  </Flex>
                ) : (
                  filteredVersions && (
                    <Grid container spacing={1}>
                      {filteredVersions.map(
                        (version: Version, index: number) => {
                          return this._renderVersion(version, index);
                        }
                      )}
                    </Grid>
                  )
                )}
              </Card>
            </div>
          </div>
        </Collapse>
        <ConfirmationModal
          isOpen={confirmDeleteModalOpen}
          title={`Delete ${deletionItem}?`}
          confirmColor="danger"
          confirmContent="Delete"
          denyContent="Cancel"
          onResolve={(resolved) => {
            if (resolved) {
              this.deleteItem();
            }
            this.setState({ confirmDeleteModalOpen: false });
          }}
          onClose={() => this.setState({ confirmDeleteModalOpen: false })}
        >
          Are you sure you want to delete this {deletionItem}?
        </ConfirmationModal>
      </React.Fragment>
    );
  }

  private _renderVersion(version: Version, index: number) {
    const { classes, gameApp } = this.props;

    return (
      <React.Fragment key={`${index}-${gameApp.object_id}`}>
        <Grid
          item
          md={10}
          id={`v${version.created_on}-${version.version_number.replace(
            /\./g,
            "dot"
          )}-${gameApp.object_id}`}
        >
          <TextField
            fullWidth
            inputProps={{
              className: classes.customTextfield,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.textfieldIcon}
                  onClick={(event) =>
                    this.openDeleteModal(
                      event,
                      "Version",
                      version.version_number,
                      version.version_number
                    )
                  }
                >
                  <Close />
                </InputAdornment>
              ),
            }}
            onChange={(event) => this.updateVersions(event, index)}
            value={version.version_number}
            variant="outlined"
          />
        </Grid>
        <Grid item md={2}>
          <small className="text-muted text-center">
            {moment(version.created_on).format("DD/MM/YYYY HH:mm")}
          </small>
        </Grid>
      </React.Fragment>
    );
  }

  private renderExternalIDs() {
    const externalIdErrors = {
      tenjin: false,
      store_id: false,
      ...this.state.externalIdErrors,
    };

    const { classes } = this.props;

    if (this.state.external_ids === undefined) {
      return <Spinner size={30} color="primary" />;
    }

    if (Object.keys(this.state.external_ids).length === 0) {
      return (
        <Flex display="flex" justifyContent="space-between" sx={{ my: 2 }}>
          <small className="text-muted">No External IDs</small>
        </Flex>
      );
    }

    return Object.keys(this.state.external_ids!).map((key) => {
      const keyName = key === "tenjin" ? "tenjin" : "store_id";
      const isInvalid = externalIdErrors[keyName];
      return (
        <Grid key={key} container spacing={3}>
          <Grid item xs={12} md={2}>
            <span>{`${this.props.config?.settings.game_app_external_keys[key]}:`}</span>
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              fullWidth
              helperText={
                isInvalid &&
                `Enter a valid ${key === "tenjin" ? "UUID" : "App Store ID"}`
              }
              error={isInvalid}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              inputProps={{
                className: classes.customTextfield,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.textfieldIcon}
                    onClick={() => this.deleteExternalId(key)}
                  >
                    <Close />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => {
                const { value } = event.target;
                const regex =
                  key === "store_id"
                    ? this.props.gameApp.game_edition_id === "android"
                      ? BUNDLE_ID
                      : NUMBER_REGEX
                    : EXTERNAL_ID[keyName];

                externalIdErrors[keyName] = value ? !regex.test(value) : false;
                this.setState({
                  external_ids: {
                    ...this.state.external_ids,
                    [key]: value,
                  },
                  externalIdErrors,
                });
              }}
              placeholder="External ID"
              value={this.state.external_ids![key]}
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    });
  }
}

export default withStyles(Styles)(GameEdition);
