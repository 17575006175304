import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import Endpoints, { Endpoint } from "../../constants/endpoints";
import { getData } from "../../network/request";

export const FETCH_GAME_EDITIONS = createAsyncThunk(
  "game_editions/FETCH",
  async () => {
    return await getDataByEndpoint(Endpoints.GAME_EDITIONS);
  }
);

export const FETCH_COHORTED_TESTS = createAsyncThunk(
  "cohorted_tests/FETCH",
  async () => {
    return await getDataByEndpoint(Endpoints.COHORTED_TESTS);
  }
);

export async function getDataByEndpoint(endpoint: Endpoint) {
  const response = await getData(endpoint.url);

  return get(response, endpoint.object_path);
}
