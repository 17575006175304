import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IGameWizardRules } from "./game-wizard.model";
import { TooltipState } from "../../model/versioned-settings.model";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import {
  fetchConfig,
  fetchGameVersions,
  fetchModelTemplateGroups,
  fetchRules,
  fetchUsers,
  saveRules,
} from "./gameWizardRulesFormThunks";

interface FormState {
  game_wizard_rules?: IGameWizardRules;
  dropdowns: {
    [id: string]: any;
  };
  loading: { [id: string]: boolean };
  error: { [id: string]: string };
  tooltip?: TooltipState;
  modelTemplatesGroups?: any[];
}

const initialState: FormState = {
  dropdowns: {},
  loading: {},
  error: {},
};

const gameWizardRulesFormSlice = createSlice({
  name: "game_wizard_rules_form",
  initialState,
  reducers: {
    updateGameWizardRules: (state, { payload }) => {
      if (!isNullOrUndefined(state.game_wizard_rules)) {
        state.game_wizard_rules[payload.key as keyof IGameWizardRules] =
          payload.value;

        if (payload.key === "allowed_admin_users") {
          state.game_wizard_rules.allowed_admin_users.sort();
        }
      }
    },
    updateGameWizardRulesVersion: (state, { payload }) => {
      if (!isNullOrUndefined(state.game_wizard_rules)) {
        if (!state.game_wizard_rules.edition_versions) {
          state.game_wizard_rules.edition_versions = {};
        }
        state.game_wizard_rules.edition_versions[payload.game_edition_id] =
          payload.value;
      }
    },
    deleteGameWizardRulesVersion: (state, { payload }) => {
      delete state.game_wizard_rules?.edition_versions[payload.game_edition_id];
    },
    setTooltip(state, { payload }) {
      state.tooltip = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRules.pending, (state) => {
      state.loading.rules = true;
    });
    builder.addCase(fetchRules.fulfilled, (state, { payload }) => {
      state.game_wizard_rules = payload;
      state.loading.rules = false;
    });
    builder.addCase(fetchRules.rejected, (state, action) => {
      state.loading.rules = false;
      state.error.rules = action.error.message ?? "Error fetching rules";
    });
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading.users = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.dropdowns.users =
        payload && Object.values(payload)?.map((user: any) => user.email);
      state.loading.users = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading.users = false;
      state.error.users = action.error.message ?? "Error fetching users";
    });
    builder.addCase(fetchConfig.pending, (state) => {
      state.loading.config = true;
    });
    builder.addCase(fetchConfig.fulfilled, (state, { payload }) => {
      state.dropdowns.config = payload;
      state.loading.config = false;
    });
    builder.addCase(fetchConfig.rejected, (state, action) => {
      state.loading.config = false;
      state.error.config = action.error.message ?? "Error fetching config";
    });
    builder.addCase(fetchGameVersions.pending, (state) => {
      state.loading.game_apps = true;
    });
    builder.addCase(fetchGameVersions.fulfilled, (state, { payload }) => {
      state.dropdowns.game_apps = {};
      payload?.forEach(
        (gameApp: any) =>
          (state.dropdowns.game_apps[gameApp.game_edition_id] =
            gameApp.versions.map((version: any) => version.version_number))
      );

      state.loading.game_apps = false;
    });
    builder.addCase(fetchGameVersions.rejected, (state, action) => {
      state.loading.game_apps = false;
      state.error.game_apps =
        action.error.message ?? "Error fetching game apps";
    });
    builder.addCase(fetchModelTemplateGroups.pending, (state) => {
      state.loading.model_template_ids = true;
    });
    builder.addCase(
      fetchModelTemplateGroups.fulfilled,
      (state, { payload }) => {
        state.dropdowns.modelTemplateGroupsIds = payload.map(
          (templateGroup: any) => ({
            value: templateGroup.object_id,
            label: templateGroup.name,
          })
        );
        state.loading.model_template_ids = false;
        state.modelTemplatesGroups = payload;
      }
    );
    builder.addCase(fetchModelTemplateGroups.rejected, (state, action) => {
      state.loading.model_template_ids = false;
      state.error.model_template_ids =
        action.error.message ?? "Error fetching model templates";
    });
    builder.addCase(saveRules.pending, (state) => {
      state.loading.savingRules = true;
    });
    builder.addCase(saveRules.fulfilled, (state, { payload }) => {
      state.game_wizard_rules = payload;
      state.loading.savingRules = false;
    });
    builder.addCase(saveRules.rejected, (state) => {
      state.loading.savingRules = false;
    });
  },
});

export const {
  updateGameWizardRules,
  updateGameWizardRulesVersion,
  deleteGameWizardRulesVersion,
  setTooltip,
} = gameWizardRulesFormSlice.actions;

export const selectDropdowns = (state: RootState) =>
  state.game_wizard_rules_form.dropdowns;

export const selectGameWizardRules = (state: RootState) =>
  state.game_wizard_rules_form.game_wizard_rules;

export const selectModelTemplatesGroups = (state: RootState) =>
  state.game_wizard_rules_form.modelTemplatesGroups;

export const selectLoading = (state: RootState) =>
  state.game_wizard_rules_form.loading;

export const selectError = (state: RootState) =>
  state.game_wizard_rules_form.error;

export const selectTooltip = (state: RootState) =>
  state.game_wizard_rules_form.tooltip;

export default gameWizardRulesFormSlice.reducer;
