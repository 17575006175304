import { useMemo } from "react";
import { useConfig } from "../../../network/useConfig";
import { useGameConfig } from "../../../network/useGameConfig";
import { defaultCategories } from "../defaultLevelFields";

export const useCategories = () => {
  const { gameConfig } = useGameConfig();
  const { config } = useConfig();

  return useMemo(() => {
    if (gameConfig?.hasOwnProperty("categories")) {
      return gameConfig.categories;
    } else if (config?.hasOwnProperty("default_categories")) {
      return config.default_categories;
    } else {
      return defaultCategories;
    }
  }, [gameConfig, config]);
};
