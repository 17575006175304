import { Menu, MenuProps } from "@mui/material";
import { withStyles } from "@mui/styles";

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: 120,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
