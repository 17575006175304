import { NestedSetting } from "../../../../model/versioned-settings.model";

export const isSettingValid = (
  setting: Pick<NestedSetting, "__value__" | "__type__">
): boolean => {
  let isValid = true;

  // Data type check
  const typeOfValue = getType(setting.__value__).toLowerCase();

  const typeOfSetting =
    setting.__type__ === "int" || setting.__type__ === "double"
      ? "number"
      : setting.__type__;

  if (typeOfValue !== typeOfSetting) {
    isValid = false;
  }

  return isValid;
};

function getType(value: any): string {
  if (typeof value !== "object" && typeof value !== "function") {
    return typeof value;
  }
  if (value === null) {
    return "null";
  }

  if (
    Object.getPrototypeOf(value) === Function.prototype &&
    /^class/.test(String(value))
  ) {
    return "class";
  }

  // Symbol.toStringTag often specifies the "display name" of the
  // object's class.
  if (
    Symbol.toStringTag in value &&
    typeof value[Symbol.toStringTag] === "string"
  ) {
    return value[Symbol.toStringTag];
  }

  // The name of the constructor; for example `Array`, `GeneratorFunction`,
  // `Number`, `String`, `Boolean` or `MyCustomObject`
  if (
    typeof value.constructor.name === "string" &&
    value.constructor.name !== ""
  ) {
    return value.constructor.name;
  }

  // At this point there's no robust way to get the type of value,
  // so we use the base implementation.
  return typeof value;
}
