import CollectionEditor from "../../../components/CollectionEditor/CollectionEditor";

import Endpoints from "../../../constants/endpoints";
import { useCollection } from "../../../network/useCollection";
import { TasksModel } from "./TasksModel";

export const TasksCrud = () => {
  const { collection: tasks, isLoading } = useCollection(Endpoints.DAILY_TASKS);
  const model = TasksModel();

  return (
    <CollectionEditor
      isLoading={isLoading}
      page="daily_tasks"
      response={tasks}
      model={model}
      endpoint={Endpoints.DAILY_TASKS}
      displayNameTemplate={`{title}`}
    />
  );
};
