import { Star, StarBorder } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  darken,
  lighten,
  TextField,
  useTheme,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { LoginRoute } from "../../constants/routes";
import { isLiveEnv } from "../../data/AxiosConfig";
import { AutoCompleteSelect } from "../../features/AddToAll/components/AutocompleteSelect/AutoCompleteSelect";
import { selectDarkMode } from "../../features/general/generalSlice";
import {
  DEFAULT_GAME_ID,
  logout,
  selectSession,
  setGameId,
} from "../../features/session/sessionSlice";
import { useConfig } from "../../network/useConfig";
import ImageFallback from "../../utils/ImageFallback";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { makeStyles } from "@mui/styles";

const StyledDropdown = styled(TextField)<{ bordercolor: string }>`
  display: flex;
  align-items: center;
  border: ${(props) => `1px ${props.bordercolor} solid`};
  border-radius: 5px;
  transition: border-color 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${(props) => darken(props.bordercolor, 0.2)};
  }
`;

const useStyles = makeStyles((theme: any) => {
  return {
    outerBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    innerBox: {
      display: "flex",
      alignItems: "center",
    },
    accordion: {
      margin: 0,
      padding: 4,
      height: 5,
      "& .Mui-expanded": {
        padding: 0,
        margin: 0,
      },
    },
  };
});

interface Favourite {
  value: string;
  label: string;
  group: string;
  order: number;
}

const StyledAccordionSummary = styled(AccordionSummary)<{ color: string }>`
  "&.Mui-expanded" {
    position: sticky;
    top: -8px;
    zindex: 5;
    background: ${(props) => props.color};
  }
`;

export const GamesDropdown = ({ expanded }: { expanded: boolean }) => {
  const { config } = useConfig();
  const dispatch = useDispatch();
  const { game_id, session_id, auth_data } = useSelector(selectSession);
  const history = useHistory();
  const isLoggedIn = !!session_id;

  const permissions: string[] = auth_data?.permission_role_ids || [];
  const READ_PERMISSION = "UKCV_admin_user_permission_role_7";

  const [favourites, setFavourites] = useState<Favourite[]>(
    JSON.parse(String(localStorage.getItem("favourites"))) ?? []
  );

  const [expandedGroup, setExpandedGroup] = useState<string[]>(
    JSON.parse(String(localStorage.getItem("expandedGroups"))) ?? []
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const darkMode = useSelector(selectDarkMode) === "dark";

  const color = darkMode ? "#161B24" : "#f5f5f5";

  const games = config?.games_available;

  const classes = useStyles();

  const options: any = useMemo(() => {
    const reference: Record<string, number> = {
      Kwalee: 1,
      Publishing: 2,
      Tictales: 3,
      Casual: 4,
      "PC/Console": 5,
      Zombie: 6,
      Zomblishing: 7,
      Tools: 8,
    };

    const groupAllGames = (games: any) => [
      ...favourites,
      ...Object.values(
        Object.entries(games ?? {})
          .map(([key, value]: any) => ({
            value: key,
            label: value.split(" ").slice(1).join(" "),
            group: value.split(" ")[0].replace(/[()]/g, ""),
            order: reference[value.split(" ")[0].replace(/[()]/g, "")],
          }))
          .sort((a, b) => b.label.localeCompare(a.label))
          .reduce((hash: any, obj: any) => {
            if (obj.group === undefined) return hash;
            return Object.assign(hash, {
              [obj.group]: (hash[obj.group] || []).concat(obj),
            });
          }, {})
      )
        .flat()
        .filter((item: any) => item.group !== "DO")
        .filter((item: any) =>
          permissions.includes(READ_PERMISSION) ? true : item.group !== "CPI"
        )
        .sort((a: any, b: any) => (a.order > b.order ? 1 : -1)),
    ];

    return groupAllGames(games);
  }, [favourites, games, permissions]);

  let location = useLocation();

  const handleChange = (value: string) => {
    if (isLoggedIn) {
      dispatch(logout(true, value, location.pathname));
      history.push(LoginRoute);
    } else {
      dispatch(setGameId(value));
    }
  };

  React.useEffect(() => {
    if (!game_id && session_id) {
      dispatch(logout());
    }
  }, [dispatch, game_id, history, session_id]);

  React.useEffect(() => {
    localStorage.setItem("favourites", JSON.stringify(favourites));
  }, [favourites]);

  React.useEffect(() => {
    localStorage.setItem("expandedGroups", JSON.stringify(expandedGroup));
  }, [expandedGroup]);

  const handleSaveToFavourites = (event: any, item: any) => {
    event.stopPropagation();
    setFavourites([
      ...favourites,
      {
        value: item.value,
        group: "Favourites",
        order: item.order,
        label: item.label,
      },
    ]);
  };

  const handleRemoveFromFavourites = (event: any, item: any) => {
    event.stopPropagation();
    setFavourites(favourites.filter((i) => i.label !== item.label));
  };

  const handleExpand =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedGroup(
        expandedGroup.includes(panel)
          ? expandedGroup.filter((i) => i !== panel)
          : [...expandedGroup, panel]
      );
    };

  const handleClearExpand = () => setExpandedGroup([]);

  return expanded ? (
    <Box mb={2} sx={{ width: "100%" }}>
      <AutoCompleteSelect
        disableClearable
        disabled={isNullOrUndefined(games)}
        options={options}
        getOptionLabel={(option: any) => option.label ?? ""}
        getOptionSelected={(option: any, value: any) => {
          if (
            value &&
            Object.keys(value).length === 0 &&
            Object.getPrototypeOf(value) === Object.prototype
          ) {
            return true;
          }
          return option.value === value.value;
        }}
        filterOptions={(options, params) => {
          return options.filter(
            (item: any) =>
              item.value
                .toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              item.label.toLowerCase().includes(params.inputValue.toLowerCase())
          );
        }}
        renderOptions={(props, option, { selected }) => (
          <li {...props}>
            <Box className={classes.outerBox}>
              <Box className={classes.innerBox}>
                <ImageFallback
                  gameId={option.value}
                  style={{
                    height: 25,
                    width: 25,
                    marginRight: 10,
                    borderRadius: 5,
                  }}
                  baseURL={
                    "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-icons/{gameId}"
                  }
                />
                <Box>
                  <Box>{option.label}</Box>
                  <Box sx={{ color: "text.secondary" }}>{option.value}</Box>
                </Box>
              </Box>
              <Box>
                {favourites?.find((item) => item.label === option.label) ? (
                  <Star
                    onClick={(event) =>
                      handleRemoveFromFavourites(event, option)
                    }
                  />
                ) : (
                  <StarBorder
                    onClick={(event) => handleSaveToFavourites(event, option)}
                  />
                )}
              </Box>
            </Box>
          </li>
        )}
        selected={
          options.find(
            (option: any) => option.value === (game_id ?? DEFAULT_GAME_ID)
          ) ?? {}
        }
        placeholder={"Select game"}
        onOptionSelected={(event, selectedOption, reason) => {
          handleChange(selectedOption.value);
        }}
        group={(option: { group: string }) => option.group}
        size="medium"
        id="games-dropdown"
        open={open}
        onOpen={() => !isNullOrUndefined(games) && setOpen(true)}
        onClose={() => !isNullOrUndefined(games) && setOpen(false)}
        renderInput={(params) => {
          return (
            <ValueComponent
              variant="outlined"
              {...params}
              handleClearExpand={handleClearExpand}
              gameId={game_id}
              placeholder="Select..."
              size="medium"
              onClick={() => handleOpen()}
            />
          );
        }}
        renderGroup={(params) => {
          return (
            <Box key={params.key} sx={{ margin: 0, padding: 0 }}>
              <Accordion
                disableGutters
                onChange={handleExpand(params.group)}
                expanded={!expandedGroup.includes(params.group) ?? false}
              >
                <StyledAccordionSummary
                  color={color}
                  expandIcon={<GridExpandMoreIcon />}
                  // darkMode={darkMode}
                  id={params.key}
                >
                  {params.group}
                </StyledAccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  {params.children}
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        }}
      />
    </Box>
  ) : null;
};

const ValueComponent = ({ gameId, handleClearExpand, ...restProps }: any) => {
  const theme = useTheme();
  const themeKey = useSelector(selectDarkMode);

  let borderColor = isLiveEnv()
    ? "#D32F2F"
    : lighten(theme.palette.primary.main, 0.4);

  if (themeKey === "light" && !isLiveEnv()) {
    borderColor = "#ffffff";
  }

  return (
    <StyledDropdown
      bordercolor={borderColor}
      {...restProps}
      onChange={() => handleClearExpand()}
      InputProps={{
        ...restProps.InputProps,
        sx: {
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
          "> input": {
            cursor: "pointer",
            marginBottom: 2,
          },
        },
        startAdornment: (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ImageFallback
                gameId={gameId}
                style={{
                  height: 45,
                  width: 45,
                  marginRight: 10,
                  borderRadius: 5,
                }}
                baseURL={
                  "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-icons/{gameId}"
                }
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                left: 70,
                bottom: 14,
                fontSize: 12,
                fontWeight: "light",
              }}
            >
              {gameId}
            </Box>
          </Box>
        ),
      }}
    ></StyledDropdown>
  );
};
