import axios from "axios";

export enum Environments {
  STAGING = "staging",
  LIVE = "live",
}

const serverURLs: Record<Environments, string> = {
  staging: "https://backend-uat-admin.servers.kwalee.com:947/",
  live: "https://backend-admin.servers.kwalee.com:953/",
};

const environmentNames: Record<Environments, string> = {
  staging: "UAT",
  live: "Live",
};

export const isLiveEnv = () => {
  return getEnvKey() === Environments.LIVE;
};

export const getEnvKey = () => {
  return (
    process.env.REACT_APP_ENV === Environments.LIVE
      ? Environments.LIVE
      : Environments.STAGING
  ) as Environments;
};

export function getEnvironmentName() {
  const envKey = getEnvKey();

  return environmentNames[envKey];
}

export function getServerURL() {
  const envKey = getEnvKey();

  return serverURLs[envKey];
}

export const axiosConfig = axios.create({
  baseURL: getServerURL(),
  headers: {
    "Content-Type": "application/json",
  },
});
