import { themePalettes } from "../../../theme/options/palette";

export const lightTheme = {
  darkMode: false,
  backgroundColor: themePalettes.light.background,
  textColor: "#121212",
  lightTextColor: "#c1c1c1",
  filter: {
    backgroundColor: "white",
  },
  badge: {
    //@ts-ignore
    hoverColor: themePalettes.light.primary?.main,
  },
  selectedSettings: {
    backgroundColor: "#F8F8F8",
    textColor: "#4E4E4E",
    collapseColor: "#ececec",
    collapseHoverColor: "#dad9d9",
  },
  setting: {
    backgroundColor: "#fff",
    textColor: "black",
    boxShadow: "#ffffff",
    testingBackgroundColor: "#f9d6e7",
  },
};
