import React, { useState } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  faChartLine,
  faList,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  IJanusSetting,
  IconButton,
  ICountryConfig,
  IWeightedConfig,
} from "./JanusSetting";

import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { WeightedValueTooltip } from "./WeightedValueTooltip";
import {
  configSelector,
  loadingSettingsSelector,
} from "../AddToAll/addToAllSlice";
import { normaliseWeightedConfigs } from "./normaliseWeightedConfigs";
import { WeightedConfig } from "./WeightedConfig";
import { AddWeightedConfig } from "./AddWeightedConfig";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  countryConfigIndex: number;
  janusSettingIndex: number;
  countryConfig: ICountryConfig;
  updateCountryConfig: (index: number, newValue: ICountryConfig) => void;
  removeCountryConfig: (index: number) => void;
  janusSetting: IJanusSetting;
}

const CountryConfig = ({
  janusSettingIndex,
  countryConfigIndex,
  countryConfig,
  updateCountryConfig,
  removeCountryConfig,
  janusSetting,
}: Props) => {
  const adminConfig = useSelector(configSelector);
  const [graphView, setGraphView] = useState(true);
  const [tooltipIndex, setTooltipIndex] = useState<number | null>(null);
  const [normalised, setNormalised] = useState(false);
  const [mouseCoords, setMouseCoords] = useState({
    x: 0,
    y: 0,
  });

  const updateWeightedConfig = (
    index: number | undefined | null,
    updates: Partial<IWeightedConfig>
  ) => {
    let newWeightedConfigs = [...countryConfig.weighted_values];

    if (!isNullOrUndefined(index)) {
      const newValue = {
        ...countryConfig.weighted_values[index],
        ...updates,
      };

      newWeightedConfigs.splice(index, 1, newValue);

      // If normalised then update all adjacent values
      if (normalised) {
        newWeightedConfigs = normaliseWeightedConfigs(
          newWeightedConfigs,
          index
        );
      }

      const sortedConfigs = newWeightedConfigs
        .slice()
        .sort((a, b) => a.value - b.value);

      const newCountryConfig = {
        ...countryConfig,
        weighted_values: sortedConfigs,
      };

      // If location has changed and tooltip is open update tooltip index
      const newIndex = sortedConfigs.indexOf(newValue);

      if (newIndex !== index && !isNullOrUndefined(tooltipIndex)) {
        setTooltipIndex(newIndex);
      }

      updateCountryConfig(countryConfigIndex, newCountryConfig);
    }
  };

  const addWeightedConfig = (newConfig: IWeightedConfig) => {
    let newWeightedConfigs = [...countryConfig.weighted_values];

    newWeightedConfigs.push(newConfig);

    // If normalised then update all adjacent values
    if (normalised) {
      newWeightedConfigs = normaliseWeightedConfigs(
        newWeightedConfigs,
        newWeightedConfigs.length - 1
      );
    }

    const newCountryConfig = {
      ...countryConfig,
      weighted_values: newWeightedConfigs
        .slice()
        .sort((a, b) => a.value - b.value),
    };

    updateCountryConfig(countryConfigIndex, newCountryConfig);
  };

  const removeWeightedConfig = (index: number) => {
    let newWeightedConfigs = [...countryConfig.weighted_values];

    newWeightedConfigs.splice(index, 1);

    // If normalised then update all adjacent values
    if (normalised) {
      newWeightedConfigs = normaliseWeightedConfigs(newWeightedConfigs);
    }

    const newCountryConfig = {
      ...countryConfig,
      weighted_values: newWeightedConfigs
        .slice()
        .sort((a, b) => a.value - b.value),
    };

    updateCountryConfig(countryConfigIndex, newCountryConfig);
  };

  const data = {
    labels: countryConfig.weighted_values
      .map((value) => value.value)
      .sort((a, b) => a - b),
    defaults: {
      color: "#fff",
    },
    datasets: [
      {
        showLine: true,
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: countryConfig.weighted_values.map((value) => value.weight),
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        onHover: function (e: any) {
          e.target.style.cursor = "pointer";
        },
        radius: 7,
        hoverRadius: 8,
        hitRadius: 3,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        events: ["click"],
        external: function (context: {
          tooltip: any;
          chart: { canvas: { getBoundingClientRect: () => any } };
        }) {
          // Tooltip Element
          var tooltipEl = document.getElementById(
            `weighted-value-tooltip-${countryConfig.country}`
          );

          // Create element on first render
          if (!tooltipEl) {
            return;
          }

          // Hide if no tooltip
          var tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            // @ts-ignore
            tooltipEl.style.opacity = 0;
            tooltipEl.style.pointerEvents = "none";
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          const values = countryConfig.weighted_values.map(
            (value) => value.value
          );

          const label = parseFloat(tooltipModel.dataPoints[0].parsed.x);

          const index = values.indexOf(label);
          setTooltipIndex(index);

          tooltipEl.style.opacity = String(0.9);
          tooltipEl.style.pointerEvents = "auto";
        },
      },
    },
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "-10px", right: "10px" }}>
        <IconButton
          onClick={() => removeCountryConfig(countryConfigIndex)}
          icon={faTrash}
        />
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12}>
          <Autocomplete
            options={adminConfig?.settings.countries}
            value={adminConfig?.settings.countries.find(
              (country: any) => country.value === countryConfig.country
            )}
            getOptionLabel={(option) => option.label} // Adjust this based on your data structure
            onChange={(event, selectedOption) => {
              const newValue = {
                ...countryConfig,
                country: selectedOption.value,
              };
              updateCountryConfig(countryConfigIndex, newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
        </Grid>
      </Grid>

      {/* <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12}>
          <Typography>Value</Typography>
          <Input
            type="number"
            placeholder={`Enter Value...`}
            value={"settingValue" ?? ""}
            disabled
          />
        </Grid>
      </Grid> */}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item sm={12}>
          <Typography sx={{ my: 2 }}>Weighted Values</Typography>

          <Box>
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button onClick={() => setGraphView(true)}>
                <IconButton icon={faChartLine} id="graph-button" />
              </Button>
              <Button onClick={() => setGraphView(false)}>
                <IconButton icon={faList} id="list-button" />
              </Button>
            </ButtonGroup>

            {countryConfig.weighted_values.length !== 0 ? (
              graphView ? (
                <>
                  <Box
                    onMouseMove={(event: { screenX: any; screenY: any }) =>
                      setMouseCoords({ x: event.screenX, y: event.screenY })
                    }
                    id={`weighted-config-graph-${janusSettingIndex}-${countryConfig.country}`}
                  >
                    <Box my={2}>
                      <Typography variant="body2">
                        Click on the points below to edit
                      </Typography>
                    </Box>

                    <Scatter
                      //@ts-ignore
                      type="scatter"
                      data={data}
                      options={chartOptions}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={normalised}
                          onChange={(event) => {
                            const weighted_values = normaliseWeightedConfigs(
                              countryConfig.weighted_values
                            );

                            updateCountryConfig(countryConfigIndex, {
                              ...countryConfig,
                              weighted_values,
                            });

                            setNormalised(event.target.checked);
                          }}
                        />
                      }
                      label="Normalised"
                    />

                    <Typography variant="body2">
                      Weights will be normalised on save
                    </Typography>
                  </Box>
                </>
              ) : (
                countryConfig.weighted_values.map((value, index) => (
                  <WeightedConfig
                    key={`${index}-${value.value}-${value.weight}`}
                    index={index}
                    updateWeightedConfig={updateWeightedConfig}
                    removeWeightedConfig={removeWeightedConfig}
                    weightedConfig={value}
                  />
                ))
              )
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                sx={{ my: 2 }}
                alignItems="center"
              >
                <Typography variant="body2">No Weighted Values</Typography>
              </Box>
            )}
            <hr />
            <AddWeightedConfig addWeightedConfig={addWeightedConfig} />
          </Box>
        </Grid>
      </Grid>

      <WeightedValueTooltip
        removeWeightedConfig={removeWeightedConfig}
        target={`weighted-config-graph-${janusSettingIndex}-${countryConfig.country}`}
        country={countryConfig.country}
        updateIndex={(newIndex) => setTooltipIndex(newIndex)}
        updateWeightedConfig={updateWeightedConfig}
        weightedValues={countryConfig.weighted_values}
        index={tooltipIndex}
      />
    </Box>
  );
};

export default CountryConfig;
