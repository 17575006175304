import Endpoints from "../../../constants/endpoints";
import { FieldType, Form } from "../../../model/crud.model";
import {
  ICurrency,
  IDailyTask,
  IInventoryItem,
  IObjective,
} from "../../../model/daily-tasks.model";
import { CountryConfig } from "../../../model/versioned-settings.model";
import { useCollection } from "../../../network/useCollection";
import { useConfig } from "../../../network/useConfig";
import { useEndpointAsOptions } from "../../../utils/useEndpointAsOptions";

export const RewardsModel = (parentField: string) => {
  const { collection: inventoryItems } = useCollection<IInventoryItem>(
    Endpoints.INVENTORY_ITEMS
  );
  const { collection: currencies } = useCollection<ICurrency>(
    Endpoints.CURRENCIES
  );

  const rewards = [...(inventoryItems ?? []), ...(currencies ?? [])];
  const rewardOptions = useEndpointAsOptions(rewards, [], ["name"], "_");

  const model: Form = [
    {
      display_name: "Reward",
      name: "reward_id",
      type: FieldType.SELECT,
      options: rewardOptions,
      parentField: parentField,
      valueFunction: (obj) =>
        rewards?.find((reward) => reward.code === obj.reward_resource_code)
          ?.object_id,
      onChangeOverride: (value, obj: IDailyTask) => {
        const chosenReward = rewards.find(
          (reward) => reward.object_id === value.reward_id
        );

        const resourceCode = chosenReward?.code;
        const rewardType = chosenReward?.object_type;

        return {
          ...obj,
          reward_id: value.reward_id,
          reward_resource_code: resourceCode,
          reward_type: rewardType,
        };
      },
    },
    {
      display_name: "Reward Value",
      name: "reward_value",
      type: FieldType.NUMBER,
      parentField: parentField,
      required: true,
    },
  ];

  return model;
};

export const TasksModel = () => {
  const { collection: objectives } = useCollection<IObjective>(
    Endpoints.OBJECTIVES
  );
  const objectiveOptions = objectives?.map((objective) => ({
    label: JSON.parse(objective.name)[""],
    value: objective.object_id,
  }));

  const { config } = useConfig();
  const allCountries = config?.countries.filter(
    (country: CountryConfig) => country.value !== "DEFAULT"
  );
  const defaultCountry = { label: "Default", value: "" };
  const countries = [...(allCountries ?? []), defaultCountry];

  const model: Form = [
    {
      display_name: "Title (Localised)",
      name: "title",
      type: FieldType.OBJECT,
      isLocalised: true,
      main_field: true,
      itemModel: [
        {
          display_name: "value",
          name: "value",
          type: FieldType.STRING,
          disableOnUpdate: true,
        },
      ],
      keyModel: {
        name: "country",
        display_name: "Country",
        type: FieldType.SELECT,
        options: countries,
        defaultValue: "",
      },
      defaultValue: {},
    },
    {
      display_name: "Description (Localised)",
      name: "description",
      type: FieldType.OBJECT,
      isLocalised: true,
      itemModel: [
        {
          display_name: "value",
          name: "value",
          type: FieldType.STRING,
        },
      ],
      keyModel: {
        name: "country",
        display_name: "Country",
        type: FieldType.SELECT,
        options: countries,
        defaultValue: "",
      },
      defaultValue: {},
    },
    {
      display_name: "Is Active",
      name: "active",
      type: FieldType.CHECKBOX,
      defaultValue: false,
    },
    {
      display_name: "Minimum Rank",
      name: "min_rank",
      type: FieldType.NUMBER,
      required: true,
    },
    {
      display_name: "Maximum Rank",
      name: "max_rank",
      type: FieldType.NUMBER,
      required: true,
    },
    {
      display_name: "Is Date Based",
      name: "date_based_daily_task",
      type: FieldType.CHECKBOX,
      defaultValue: false,
    },
    {
      display_name: "Date for task",
      name: "date_for_daily_task",
      format: "YYYYMMDD",
      type: FieldType.DATETIME,
      hiddenFunction: (value, obj) => !obj.date_based_daily_task,
    },
    {
      display_name: "Objectives",
      name: "objectives",
      type: FieldType.MULTISELECT,
      defaultValue: [],
      selectOptions: objectiveOptions,
      limitTags: 10,
    },
    {
      display_name: "Rewards",
      name: "rewards",
      page: "daily_tasks",
      type: FieldType.COLLECTION,
      nestedModel: true,
      defaultValue: [],
      collectionModel: RewardsModel("rewards"),
    },
  ];

  return model;
};
