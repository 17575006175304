import { Box, CircularProgress, Button } from "@mui/material";
import { Alert } from "@mui/material";
import React, { Suspense, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { BidReductionModal } from "./components/BidReduction/BidReductionModal";
import { CampaignsList } from "./components/CampaignsList";

export const XpromoCampaigns = () => {
  const [isBidReductionOpen, setIsBidReductionOpen] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  return (
    <ErrorBoundary
      FallbackComponent={({ error }: { error: any }) => {
        return error?.response?.status === 404 ? (
          <Alert color="warning">No campaigns found</Alert>
        ) : (
          <Alert color="error">{error.message}</Alert>
        );
      }}
    >
      <Suspense
        fallback={
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        }
      >
        <Button
          variant="outlined"
          sx={{ marginY: 2 }}
          onClick={() => setIsBidReductionOpen(true)}
        >
          Edit Bid Reduction
        </Button>
        <CampaignsList campaignId={id ?? undefined} />
      </Suspense>
      <BidReductionModal
        isOpen={isBidReductionOpen}
        onClose={() => setIsBidReductionOpen(false)}
      />
    </ErrorBoundary>
  );
};
