import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

export const CopyableText = ({
  text,
  displayText,
}: {
  text: string;
  displayText?: string | JSX.Element;
}) => {
  const [copiedTooltip, setCopiedTooltip] = useState("Copy to Clipboard");

  useEffect(() => {
    if (copiedTooltip === "Copied!") {
      setTimeout(() => setCopiedTooltip("Copy to Clipboard"), 1000);
    }
  }, [copiedTooltip]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);

    setCopiedTooltip("Copied!");
  };

  return (
    <Tooltip title={copiedTooltip}>
      <small
        onClick={() => copyToClipboard(text)}
        style={{ cursor: "pointer" }}
      >
        {displayText ? displayText : text}
      </small>
    </Tooltip>
  );
};
