import { Checkbox } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mutate } from "swr";
import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import { deleteItem } from "../../components/CollectionEditor/crudService";
import { Flex } from "../../components/Flex";
import Endpoints from "../../constants/endpoints";
import { FieldType, Form } from "../../model/crud.model";
import { useConfig } from "../../network/useConfig";
import { useUserAccounts } from "../../network/useUserAccounts";
import { useUserPermissions } from "../../network/useUserPermissions";
import { useUserRegistry } from "../../network/useUserRegistry";
import { getGameID } from "../../utils/game-admin.util";
import { getEndpointData } from "../../utils/getEndpointData";
import { IGameWizardRules } from "../GameWizardRulesForm/game-wizard.model";
import { selectGameWizardRules } from "../GameWizardRulesForm/gameWizardRulesFormSlice";
import {
  fetchRules,
  saveRules,
} from "../GameWizardRulesForm/gameWizardRulesFormThunks";

interface GameSchema {
  object_id: string;
  game_name: string;
  category_id: string;
}

export const UserManager = () => {
  const { userAccounts, isLoading } = useUserAccounts();
  const dispatch = useDispatch();

  const { userPermissions } = useUserPermissions();
  const { userRegistry } = useUserRegistry();
  const rules = useSelector(selectGameWizardRules);
  const currentGameId = getGameID();

  const { config } = useConfig();

  const games = config?.games_available;

  const options: any = useMemo(() => {
    const groupAllGames = (games: GameSchema[]) => [
      ...Object.values(
        Object.entries(games ?? {})
          .map(([key, value]: any) => ({
            value: key,
            label: value,
            group: value.split(" ")[0].replace(/[()]/g, ""),
          }))
          .sort((a, b) => b.label.localeCompare(a.label))
          .reduce((hash: any, obj: any) => {
            if (obj.group === undefined) return hash;
            return Object.assign(hash, {
              [obj.group]: (hash[obj.group] || []).concat(obj),
            });
          }, {})
      )
        .flat()
        .filter((item: any) => item.group !== "DO"),
    ];

    return groupAllGames(games);
  }, [games]);

  const groups = [...new Set(options.map((k: any) => k.group))];

  let permittedGroup: string[] = [];

  useEffect(() => {
    const fetchAll = () => {
      dispatch(fetchRules());
    };

    fetchAll();
  }, [dispatch]);

  const handlePermittedGames = (filtered: string[]) => {
    return {
      ...options.reduce(
        (obj: any, q: any) => ({
          ...obj,
          [q.value]: false,
        }),
        {}
      ),
      ...options
        ?.filter((item: any) => filtered.includes(item.group))
        .reduce(
          (obj: any, q: any) => ({
            ...obj,
            [q.value]: true,
          }),
          {}
        ),
    };
  };

  const model: Form = [
    {
      display_name: "Email Address",
      name: "email",
      type: FieldType.STRING,
      main_field: true,
    },
    {
      display_name: "Name",
      name: "name",
      type: FieldType.STRING,
    },
    {
      display_name: "Password Reset",
      name: "password",
      type: FieldType.PASSWORD,
      defaultValue: "ola123",
    },
    {
      display_name: "Is Superuser",
      name: "superuser",
      type: FieldType.CHECKBOX,
      defaultValue: false,
    },
    {
      display_name: "Permission Roles",
      name: "permission_role_ids",
      type: FieldType.MULTISELECT,
      defaultValue: [],
      selectOptions: userPermissions?.map((i, idx) => ({
        value: i.object_id,
        label: i.name,
      })),
      limitTags: 10,
    },
    {
      display_name: "Override permissions",
      name: "override_permissions",
      type: FieldType.OBJECT,
      isLocalised: true,
      main_field: true,
      itemModel: [
        {
          display_name: "Allow Access",
          name: "value",
          valueFunction: (obj) => obj,
          type: FieldType.CUSTOM,
          renderOverride: (option, onChangeFunction, value) => (
            <Flex>
              <Flex alignItems="center">
                <Checkbox
                  checked={option?.methods.GET}
                  onChange={(event, k) => {
                    return onChangeFunction({
                      [value]: {
                        ...option,
                        methods: {
                          ...option?.methods,
                          GET: !option?.methods.GET,
                        },
                      },
                    });
                  }}
                />{" "}
                GET
              </Flex>
              <Flex alignItems="center">
                <Checkbox
                  checked={option?.methods.POST}
                  onChange={(event, k) => {
                    return onChangeFunction({
                      value: {
                        ...option,
                        methods: {
                          ...option.methods,
                          POST: !option?.methods.POST,
                        },
                      },
                    });
                  }}
                />{" "}
                POST
              </Flex>
            </Flex>
          ),
        },
      ],
      keyModel: {
        name: "override_permissions",
        display_name: "Permissions",
        type: FieldType.SELECT,
        options: userRegistry
          ? Object.entries(userRegistry)?.map(([key, value]) => ({
              value: key,
              label: value.pretty_name,
            }))
          : [],
        defaultValue: "",
      },
      defaultValue: {},
    },
    {
      display_name: "Select Permitted Games",
      name: "permitted_games_group",
      type: FieldType.CUSTOM,
      main_field: false,
      renderOverride: (option, onChangeFunction, value) => {
        return (groups as string[]).map((a) => {
          return (
            <Flex>
              <Flex alignItems="center">
                <Checkbox
                  onChange={(event, k) => {
                    const filtered: string[] = permittedGroup.includes(
                      String(a)
                    )
                      ? permittedGroup.filter((p) => p !== a)
                      : [...permittedGroup, a];

                    permittedGroup = filtered;
                    return onChangeFunction({
                      permitted_games: handlePermittedGames(filtered),
                    });
                  }}
                />{" "}
                {a}
              </Flex>
            </Flex>
          );
        });
      },
    },

    {
      display_name: "Permitted Games",
      name: "permitted_games",
      type: FieldType.OBJECT,
      isLocalised: true,
      main_field: true,

      itemModel: [
        {
          display_name: "Allow Access",
          name: "permitted_values",
          type: FieldType.CHECKBOX,
        },
      ],
      keyModel: {
        name: "game",
        display_name: "Game",
        type: FieldType.SELECT,
        options: games
          ? Object.entries(games).map(([key, value]) => ({
              label: value,
              value: key,
            }))
          : [],
        defaultValue: "",
      },
      valueFunction(obj?) {
        return (
          obj.permitted_games ?? {
            ...options.reduce(
              (obj: any, q: any) => ({
                ...obj,
                [q.value]: false,
              }),
              {}
            ),
          }
        );
      },
    },
  ];

  return (
    <CollectionEditor
      response={Object.values(userAccounts ?? [])}
      page="users"
      isLoading={isLoading}
      parentField="email"
      onModalClose={() => (permittedGroup = [])}
      model={model}
      endpoint={Endpoints.USER_ACCOUNTS}
      onDelete={async (obj: any) => {
        try {
          const success = await deleteItem(
            `${Endpoints.USER_ACCOUNTS.url}/${obj.object_id}/delete`
          );
          if (success) {
            const response = Object.values(userAccounts ?? []).filter(
              (item, idx) => item.object_id !== obj.object_id
            );

            const newArray = [...response];

            const { url, objectPath } = getEndpointData(
              Endpoints.USER_ACCOUNTS,
              "users",
              currentGameId
            );

            await dispatch(
              saveRules({
                ...rules,
                allowed_admin_users: rules?.allowed_admin_users.filter(
                  (item, idx) => item !== obj.email
                ),
              } as IGameWizardRules)
            );

            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: newArray,
                };
              },
              false
            );
          }
        } catch (e) {
          console.error(e);
        }
      }}
    />
  );
};
