import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Slider,
  Tooltip,
  Box,
} from "@mui/material";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { IWeightedConfig } from "./JanusSetting";
import { Flex } from "../../components/Flex";

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    align-items: center;
  }
  .MuiSlider-markLabel {
    color: white;
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  margin: 0 1rem;
`;

export interface WeightedValueTooltipProps {
  country: string;
  weightedValues: IWeightedConfig[];
  index?: number | null;
  updateWeightedConfig: (
    index: number | undefined | null,
    newValue: Partial<IWeightedConfig>
  ) => void;
  removeWeightedConfig: (index: number) => void;
  updateIndex: (newIndex: number | null) => void;
  target?: string;
}

export const WeightedValueTooltip = ({
  country,
  weightedValues,
  index,
  updateWeightedConfig,
  removeWeightedConfig,
  updateIndex,
  target,
}: WeightedValueTooltipProps) => {
  const [tempValue, setTempValue] = useState<IWeightedConfig>(
    weightedValues[index as number]
  );

  useEffect(() => {
    setTempValue(weightedValues[index as number]);
  }, [weightedValues, index]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    let id = event.target.id;
    const updates = {
      [id]: parseFloat(value),
    };
    setTempValue({ ...tempValue, ...updates });
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const updates = {
      weight: newValue as number,
    };

    updateWeightedConfig(index, updates);
  };

  const handleBlur = () => {
    let updates: { weight: number | null; value: number | null } = {
      ...tempValue,
    };
    const weight = tempValue.weight;
    if (isNullOrUndefined(weight) || weight < 0 || weight > 100) {
      updates = {
        ...updates,
        weight: 0 as number,
      };
    }

    updateWeightedConfig(index, updates);
  };

  const value = tempValue?.value;
  const weight = tempValue?.weight;

  if (isNullOrUndefined(index)) {
    return (
      <Flex
        style={{ opacity: 0, pointerEvents: "none" }}
        id={`weighted-value-tooltip-${country}`}
      />
    );
  }

  const StyledTopRightButton = styled(Button)`
    position: absolute;
    top: 18px;
    right: 10px;
  `;

  return (
    <StyledTooltip
      style={{
        position: "absolute",
        top: "80px", // Adjust the top position as needed
        right: "25px", // Adjust the right position as needed
      }}
      open={true}
      title={
        <Flex style={{ padding: 20 }}>
          <StyledTopRightButton
            onClick={() => updateIndex(null)}
            variant="outlined"
            color="error"
            size="small"
          >
            <FontAwesomeIcon icon={faTimes} />
          </StyledTopRightButton>
          <StyledButton
            variant="outlined"
            onClick={() => updateIndex(index - 1)}
            disabled={index === 0}
          >
            <FontAwesomeIcon icon={faChevronCircleLeft} />
          </StyledButton>
          <Box>
            <FormControl>
              <InputLabel htmlFor="value">Value</InputLabel>

              <Input
                type="number"
                id="value"
                value={value}
                margin="dense"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 1,
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="weight">Weight</InputLabel>
              <Input
                type="number"
                id="weight"
                value={weight ?? 0}
                margin="dense"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 1,
                }}
              />
            </FormControl>
            <Slider
              value={typeof weight === "number" ? weight : 0}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              aria-labelledby="vertical-slider"
              step={0.05} // Add step here
              min={0} // Add min here
              max={1} // Add max here
              marks={[
                {
                  value: 0,
                  label: "0",
                },
                {
                  value: 1,
                  label: "1",
                },
              ]}
            />
            <Button
              onClick={() => removeWeightedConfig(index)}
              color="error"
              variant="outlined"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Box>
          <StyledButton
            variant="outlined"
            onClick={() => updateIndex(index + 1)}
            disabled={index === weightedValues.length - 1}
          >
            <FontAwesomeIcon icon={faChevronCircleRight} />
          </StyledButton>
        </Flex>
      }
    >
      <span id={`weighted-value-tooltip-${country}`}>{target}</span>
    </StyledTooltip>
  );
};
