import { Box } from "@mui/material";
import { FieldType, Form } from "../../../../../model/crud.model";
import { useImageResources } from "../../../../../network/useImageResources";
import { AutoCompleteSelect } from "../../../../AddToAll/components/AutocompleteSelect/AutoCompleteSelect";
import { useCategories } from "../../../hooks/useCategories";

export const AreaModel = () => {
  const categories = useCategories();

  const { image_resources, isLoading } = useImageResources();

  return [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: categories,
      defaultValue: "default",
      hidden: true,
    },
    {
      display_name: "Client ID",
      name: "client_id",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Area Type",
      name: "area_type",
      type: FieldType.SELECT,
      options: { default: "Default" },
      defaultValue: "default",
      hidden: true,
    },
    {
      display_name: "Level Background Image",
      name: "level_background_image_id",
      required: false,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Level Cost",
      name: "level_cost",
      type: FieldType.NUMBER,
      defaultValue: 0,
    },
    {
      display_name: "Area Title",
      name: "area_title",
      type: FieldType.STRING,
      defaultValue: null,
    },
    {
      display_name: "Area Map ID",
      name: "area_map_id",
      type: FieldType.STRING,
      defaultValue: null,
    },
    {
      display_name: "Area Image",
      name: "area_image_resource_id",
      valueFunction: (obj) => obj,
      type: FieldType.CUSTOM,
      renderOverride: (option, onChangeFunction, value) => {
        return (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <AutoCompleteSelect
              disabled={isLoading}
              options={(image_resources ?? []).map((i, idx) => ({
                value: i.object_id,
                label: i.name,
              }))}
              selected={option.area_image_resource_id}
              id="area-image-dropdown"
              onOptionSelected={(event, selectedOption) => {
                return onChangeFunction({
                  [value]: selectedOption.value,
                });
              }}
            />
            {option.area_image_resource_id && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src={`http://d2kre6881rwtik.cloudfront.net/${
                    image_resources.find(
                      (item) => item.object_id === option[value]
                    )?.image_urls.default_url
                  }`}
                  alt={option[value]}
                  style={{
                    height: 125,
                    width: 125,
                    marginTop: 12,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      },
    },
    {
      display_name: "Undo Cost",
      name: "undo_cost_modal",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: UndoCostModal("undo_cost_modal"),
      page: "areas",
      defaultValue: {
        undo_cost: [0, 0, 0],
      },
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          ...value.undo_cost_modal,
        };
      },
    },
    {
      display_name: "DIY Cost",
      name: "diy_cost_modal",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: DIYCostModal("diy_cost_modal"),
      page: "areas",
      defaultValue: {
        diy_cost: [0, 0, 0],
      },
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          ...value.diy_cost_modal,
        };
      },
    },

    {
      display_name: "Drill Cost",
      name: "drill_cost_modal",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: DrillCostModal("drill_cost_modal"),
      page: "areas",
      defaultValue: {
        diy_cost: [0, 0, 0],
      },
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          ...value.drill_cost_modal,
        };
      },
    },
    {
      display_name: "Buy Card Cost",
      name: "buy_card_cost_modal",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: BuyCardCostModal("buy_card_cost_modal"),
      page: "areas",
      defaultValue: {
        buy_card_cost: [0, 0, 0],
      },
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          ...value.buy_card_cost_modal,
        };
      },
    },
    {
      display_name: "Reward Settings",
      name: "reward_settings_modal",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: RewardSettingsModal("reward_settings_modal"),
      page: "areas",
      defaultValue: {
        general_coin_award: undefined,
        coin_award_increase: undefined,
        min_level_reward: undefined,
        max_level_reward: undefined,
        level_reward_rounder: undefined,
        card_complete_reward: undefined,
      },
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          ...value,
          ...value.reward_settings_modal,
        };
      },
    },
  ] as Form;
};

export const UndoCostModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Undo Cost",
      name: "undo_cost",
      type: FieldType.ARRAY,
      defaultValue: [0, 0, 0],
      useAtomicValue: true,
      fixedSize: true,
      valueFunction(obj?) {
        return obj?.undo_cost ? obj?.undo_cost : [0, 0, 0];
      },
      parentField: parentField,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
  ];

  return model;
};

export const DIYCostModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "DIY Cost",
      name: "diy_cost",
      type: FieldType.ARRAY,
      defaultValue: [0, 0, 0],
      useAtomicValue: true,
      parentField: parentField,
      fixedSize: true,
      valueFunction(obj?) {
        return obj?.diy_cost ? obj?.diy_cost : [0, 0, 0];
      },
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
  ];

  return model;
};

export const DrillCostModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Drill Cost",
      name: "drill_cost",
      type: FieldType.ARRAY,
      defaultValue: [0, 0, 0],
      useAtomicValue: true,
      parentField: parentField,
      fixedSize: true,
      valueFunction(obj?) {
        return obj?.drill_cost ? obj?.drill_cost : [0, 0, 0];
      },
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
  ];

  return model;
};

export const BuyCardCostModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Buy Card Cost",
      name: "buy_card_cost",
      type: FieldType.ARRAY,
      defaultValue: [0, 0, 0],
      useAtomicValue: true,
      parentField: parentField,
      fixedSize: true,
      valueFunction(obj?) {
        return obj?.buy_card_cost ? obj?.buy_card_cost : [0, 0, 0];
      },
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
  ];

  return model;
};

export const RewardSettingsModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "General Coin Award",
      name: "general_coin_award",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.general_coin_award ? obj?.general_coin_award : 0;
      },
    },
    {
      display_name: "Coin Award Increase",
      name: "coin_award_increase",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.coin_award_increase ? obj?.coin_award_increase : 0;
      },
    },
    {
      display_name: "Min Level Reward",
      name: "min_level_reward",
      type: FieldType.NUMBER,
      valueFunction(obj?) {
        return obj?.min_level_reward ? obj?.min_level_reward : 0;
      },
    },
    {
      display_name: "Max Level Reward",
      name: "max_level_reward",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.max_level_reward ? obj?.max_level_reward : 0;
      },
    },
    {
      display_name: "Level Reward Rounder",
      name: "level_reward_rounder",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.level_reward_rounder ? obj?.level_reward_rounder : 0;
      },
    },
    {
      display_name: "Card Complete Reward",
      name: "card_complete_reward",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.card_complete_reward ? obj?.card_complete_reward : 0;
      },
    },
  ];

  return model;
};
