import React, { ReactNode } from "react";
import { Dialog } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => {
  const isLightMode = theme.palette.mode === "light";

  return {
    dialog: {
      background: isLightMode ? "#f8f8f8" : theme.palette.background.paper,
    },
  };
});

export const DialogWrapper = ({
  children,
  isOpen,
  onClose,
  size,
}: {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}) => {
  const classes = useStyles();

  return (
    <Dialog
      maxWidth={size ?? "md"}
      fullWidth
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
    >
      {children}
    </Dialog>
  );
};
