import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDatabaseObject } from "../../model/database-object.model";
import {
  DELETE_JANUS_SETTING,
  UPDATE_JANUS_SETTING,
  SHOW_TOAST,
  SET_SELECTOR,
} from "./JanusSettingsSlice";
import { Flex } from "../../components/Flex";
import { loadingSettingsSelector } from "../AddToAll/addToAllSlice";
import { ToastTypes } from "../LogsPage/ToastTypes";
import CountryConfig from "./CountryConfig";
import { toReactSelectObject } from "../../utils/toReactSelectObject";
import { IGameEdition } from "../../model/game-edition.model";
import { faFileUpload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { SettingPath } from "./SettingPath";
import { setCurrentPlatform } from "../general/generalSlice";

const Status = {
  active: "Active",
  disabled: "Disabled",
} as const;

export const Scope = {
  new_players: "New Players",
  existing_players: "Existing Players",
};

export const IconButton = styled(FontAwesomeIcon)`
  cursor: pointer;
  transition: color 0.1s ease-in-out;

  &:hover {
    color: lightgray;
  }
`;

export interface IJanusSetting extends Partial<IDatabaseObject> {
  game_id: string;
  game_edition_id: string;
  status: keyof typeof Status;
  country_setting_config: ICountryConfig[];
  settings_config: ISettingsConfig[];
  scope: keyof typeof Scope;
}

export interface IPath {
  node_type: "dict" | "list";
  node_key: number | string;
}

export interface ICountryConfig {
  country: string;
  weighted_values: IWeightedConfig[];
}

export interface IWeightedConfig {
  value: any;
  weight: number | null;
}
export interface ISettingsConfig {
  key: string;
  path: IPath[];
}

export interface Props {
  janusSetting: IJanusSetting;
  gameEditions?: IGameEdition[];
  loadingGameEditions: boolean;
  janusSettingIndex: number;
}

let fileLoading: File | undefined;

const JanusSetting = ({
  janusSetting,
  gameEditions,
  janusSettingIndex,
}: Props) => {
  const fileInput = useRef<HTMLInputElement>();
  const fileReader = useRef(new FileReader());
  const [showExampleObject, setShowExampleObject] = useState(false);

  const loadingSettings = useSelector(loadingSettingsSelector);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const openFileDialog = () => {
    if (!fileInput.current) {
      return;
    }

    fileInput.current.click();
  };

  const onFilesAdded = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length < 1) {
      return;
    }

    const file = files[0];

    const typeSplit = file.type.toLowerCase().split("/");
    if (typeSplit.length !== 2 || !["json"].includes(typeSplit[1])) {
      dispatch(
        SHOW_TOAST({
          type: ToastTypes.ERROR,
          message: "Invalid JSON Format",
        })
      );

      return;
    }

    fileLoading = file;
    fileReader.current.readAsText(file);

    // Clear file input so the same file could be added again
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  const onImport = (content: string) => {
    const countryConfigsJSON = JSON.parse(content);
    let valid = true;

    const countryConfigs: ICountryConfig[] = [];

    try {
      // Create config array
      Object.keys(countryConfigsJSON).forEach((key) => {
        countryConfigs.push({
          country: key,
          weighted_values: countryConfigsJSON[key],
        });
      });

      if (!Array.isArray(countryConfigs)) {
        valid = false;
      }

      // Check if JSON is valid
      countryConfigs?.map((config: any) => {
        if (!config.hasOwnProperty("country")) {
          valid = false;
        }

        if (!Array.isArray(config?.weighted_values)) {
          valid = false;
        }

        config?.weighted_values?.map((value: any) => {
          if (
            !(value.hasOwnProperty("weight") && value.hasOwnProperty("value"))
          ) {
            valid = false;
          }
        });
      });
    } catch {
      dispatch(
        SHOW_TOAST({
          type: ToastTypes.ERROR,
          message: "Invalid JSON Format",
        })
      );
    }

    if (valid) {
      dispatch(
        UPDATE_JANUS_SETTING({
          id: janusSetting.object_id,
          country_setting_config: countryConfigs,
        })
      );
      dispatch(
        SHOW_TOAST({
          type: ToastTypes.SUCCESS,
          message: "Configs Imported Successfully",
        })
      );
    }
  };

  useEffect(() => {
    // On load complete
    fileReader.current.addEventListener("load", () => {
      const file = fileLoading;
      if (!file) {
        return;
      }

      fileLoading = undefined;

      let content;
      if (typeof fileReader.current.result === "string") {
        content = fileReader.current.result;
      }

      if (!content) {
        return;
      }

      onImport(content);
    });

    // On error
    fileReader.current.addEventListener("error", (err) => {
      console.error(err);

      fileLoading = undefined;
    });
  }, []);

  const platformArray = gameEditions?.map((edition) => ({
    value: edition.name,
    label: edition.display_name,
  }));

  const [collapseOpen, setCollapseOpen] = useState(false);
  const currentPlatform = platformArray?.find(
    (platform) => platform.value === janusSetting.game_edition_id
  );

  const currentPlatformText = currentPlatform
    ? currentPlatform.label
    : janusSetting.game_edition_id
    ? janusSetting.game_edition_id
    : "Select Platform";

  const exampleData = {
    DEFAULT: [
      {
        value: 1,
        weight: 0.15,
      },
      {
        value: 2.3456,
        weight: 0.2,
      },
      {
        value: 3,
        weight: 0.4,
      },
      {
        value: 4.52353252,
        weight: 0.25,
      },
    ],
    US: [
      {
        value: 12.08,
        weight: 0.15,
      },
      {
        value: 17.6,
        weight: 0.2,
      },
      {
        value: 25.02,
        weight: 0.4,
      },
      {
        value: 32.13,
        weight: 0.25,
      },
    ],
  };

  const dispatch = useDispatch();

  const updateSetting = (event: any) => {
    dispatch(
      UPDATE_JANUS_SETTING({
        id: janusSetting.object_id,
        [event.target.id]: event.target.value,
      })
    );
  };

  const updateCountryConfig = (index: number, newValue: ICountryConfig) => {
    const newCountryConfigs = [...janusSetting.country_setting_config];

    newCountryConfigs.splice(index, 1, newValue);

    dispatch(
      UPDATE_JANUS_SETTING({
        id: janusSetting.object_id,
        country_setting_config: newCountryConfigs,
      })
    );
  };

  const addCountryConfig = () => {
    const newCountryConfig = {
      country: "",
      weighted_values: [],
      settings_config: [],
    };

    const newCountryConfigs = [...janusSetting.country_setting_config];

    newCountryConfigs.push(newCountryConfig);

    dispatch(
      UPDATE_JANUS_SETTING({
        id: janusSetting.object_id,
        country_setting_config: newCountryConfigs,
      })
    );
  };

  const removeCountryConfig = (index: number) => {
    const newCountryConfigs = [...janusSetting.country_setting_config];

    newCountryConfigs.splice(index, 1);

    dispatch(
      UPDATE_JANUS_SETTING({
        id: janusSetting.object_id,
        country_setting_config: newCountryConfigs,
      })
    );
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "0.5rem 0",
        padding: 0,
      }}
    >
      <Box
        py={2}
        px={3}
        sx={{
          cursor: "pointer",
          transition: "background-color 0.1s ease-in-out",
        }}
        onClick={() => setCollapseOpen(!collapseOpen)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box>
            {!janusSetting.object_id?.includes("new") ? (
              <h3>
                {janusSetting.object_id} ·{" "}
                <small>{`${currentPlatformText} · Created ${moment(
                  janusSetting?.created_on
                ).format("LLL")}`}</small>{" "}
              </h3>
            ) : (
              <h3>
                New Janus Setting ·{" "}
                <small>{`${currentPlatformText} · Draft ${moment().format(
                  "LLL"
                )}`}</small>{" "}
              </h3>
            )}
          </Box>

          <Tooltip title="Delete" placement="top">
            <Button color="error" id="deleteButton" onClick={openPopover}>
              Delete
            </Button>
          </Tooltip>

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box p={2}>
              <Typography variant="h6">Confirm deletion</Typography>
              <Typography variant="body1">
                Are you sure you want to delete this Janus setting?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                }}
              >
                <Button
                  color="secondary"
                  onClick={(event) => {
                    event.stopPropagation();
                    closePopover();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="error"
                  onClick={(event) => {
                    event.stopPropagation();
                    closePopover();
                    dispatch(DELETE_JANUS_SETTING(janusSetting.object_id));
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>

      <Collapse in={collapseOpen}>
        <Box p={3}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <Autocomplete
                options={
                  (platformArray || []) as readonly {
                    value: string;
                    label: string;
                  }[]
                }
                getOptionLabel={(option) => option.label}
                value={currentPlatform}
                onChange={(event, newValue) => {
                  dispatch(
                    UPDATE_JANUS_SETTING({
                      id: janusSetting.object_id,
                      game_edition_id: newValue?.value || "", // Assuming you want to set the "value" property of the selected option
                    })
                  );
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label="Platform" variant="outlined" />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={6} sm={12}>
              <Autocomplete
                options={Object.keys(Status).map((value) =>
                  toReactSelectObject(Status as Record<string, string>, value)
                )}
                getOptionLabel={(option) => option.label}
                value={toReactSelectObject(Status, janusSetting.status)}
                onChange={(event, newValue) => {
                  dispatch(
                    UPDATE_JANUS_SETTING({
                      id: janusSetting.object_id,
                      status: newValue?.value || "", // Assuming you want to set the "value" property of the selected option
                    })
                  );
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label="Status" variant="outlined" />
                )}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Autocomplete
                options={Object.keys(Scope).map((value) =>
                  toReactSelectObject(Scope as Record<string, string>, value)
                )}
                getOptionLabel={(option) => option.label}
                value={toReactSelectObject(Scope, janusSetting.scope)}
                onChange={(event, newValue) => {
                  dispatch(
                    UPDATE_JANUS_SETTING({
                      id: janusSetting.object_id,
                      scope: newValue?.value || "", // Assuming you want to set the "value" property of the selected option
                    })
                  );
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label="Scope" variant="outlined" />
                )}
              />
            </Grid>

            <Grid item md={6} sm={12}>
              <Typography>Setting</Typography>
              <Grid>
                {(janusSetting?.settings_config ?? []).map((config) => (
                  <Grid item md={12}>
                    <Flex sx={{ alignItems: "center" }}>
                      <SettingPath janusSetting={config} />
                      <Button
                        disabled={loadingSettings}
                        onClick={() => {
                          dispatch(
                            setCurrentPlatform(janusSetting.game_edition_id)
                          );
                          dispatch(
                            SET_SELECTOR({
                              isOpen: true,
                              object_id: janusSetting.object_id,
                              selectedId: config.key,
                            })
                          );
                        }}
                      >
                        Change
                      </Button>
                    </Flex>
                  </Grid>
                ))}

                <Button
                  disabled={loadingSettings}
                  onClick={() => {
                    dispatch(setCurrentPlatform(janusSetting.game_edition_id));
                    dispatch(
                      SET_SELECTOR({
                        isOpen: true,
                        object_id: janusSetting.object_id,
                        selectedId: undefined,
                      })
                    );
                  }}
                >
                  Select
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 3, mb: 3 }} />

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item sm={12}>
              <Typography>Configs</Typography>
              <Box>
                <input
                  ref={(ref) => (fileInput.current = ref ?? undefined)}
                  className="d-none"
                  type="file"
                  accept={"json"}
                  onChange={onFilesAdded}
                />
                <Flex alignItems="center">
                  <Button type="button" onClick={openFileDialog}>
                    <FontAwesomeIcon icon={faFileUpload} className="mr-2" />
                    Import JSON File
                  </Button>
                </Flex>

                <Tooltip title="Example Object Structure" placement="top">
                  <Button
                    color="secondary"
                    onClick={() => setShowExampleObject(!showExampleObject)}
                  >
                    Example Object
                  </Button>
                </Tooltip>
                {showExampleObject && (
                  <div>
                    <pre>{JSON.stringify(exampleData, null, 2)}</pre>
                  </div>
                )}
                {janusSetting.country_setting_config.length !== 0 ? (
                  janusSetting.country_setting_config.map((config, index) => {
                    return (
                      <CountryConfig
                        janusSettingIndex={janusSettingIndex}
                        key={index}
                        countryConfigIndex={index}
                        updateCountryConfig={updateCountryConfig}
                        removeCountryConfig={removeCountryConfig}
                        janusSetting={janusSetting}
                        countryConfig={config}
                      />
                    );
                  })
                ) : (
                  <Flex justifyContent="center" m={2}>
                    <small>No Country Configs</small>
                  </Flex>
                )}
                <Button onClick={() => addCountryConfig()}>
                  <FontAwesomeIcon icon={faPlus} /> &nbsp;Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Card>
  );
};

export default JanusSetting;
