import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { AnalyticsEventModel } from "../model/analytics_event.model";
import { getData } from "./request";

type Event = {
  events: AnalyticsEventModel[];
  isLoading: boolean;
  isError: boolean;
};

export function useEvents() {
  const { data, error } = useSWR<Record<string, any>>(
    Endpoints.ANALYTICS_EVENT.url,
    getData
  );

  const events: Event = {
    events: data?.[Endpoints.ANALYTICS_EVENT.object_path] ?? [],
    isLoading: !error && !data,
    isError: error,
  };

  return events;
}
