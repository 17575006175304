import {
  Box,
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import { store } from "../../app/store";
import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import {
  createItem,
  updateItem,
} from "../../components/CollectionEditor/crudService";
import { FormContent } from "../../components/CollectionEditor/FormContent";
import { DialogWrapper } from "../../components/DialogWrapper";
import { SearchBar } from "../../components/SearchBar";
import Endpoints from "../../constants/endpoints";
import { FieldType } from "../../model/crud.model";
import { Endpoint } from "../../model/types";
import { postData } from "../../network/request";
import { useConfig } from "../../network/useConfig";
import { getGameID } from "../../utils/game-admin.util";
import { getEndpointData } from "../../utils/getEndpointData";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { selectSession } from "../session/sessionSlice";

interface GameSchema {
  object_id: string;
  game_name: string;
  category_id: string;
}
export const GameCreation = () => {
  const { config } = useConfig();
  const { auth_data } = useSelector(selectSession);

  const permissions: string[] = useMemo(
    () => auth_data?.permission_role_ids || [],
    [auth_data]
  );
  const CREATE_PERMISSION = "UKCV_admin_user_permission_role_6";

  const gamesAvailable: Record<string, string> | undefined =
    config?.games_available;

  const [games, setGames] = useState<GameSchema[]>([]);
  const [defaultGames, setDefaultGames] = useState<GameSchema[]>([]);

  const gameEndpoints: Record<string, Endpoint> = {
    gameCreation: Endpoints.GAME_CREATION,
    gameUpdate: Endpoints.GAME_CREATION_UPDATE,
  };

  const currentGameId = getGameID();
  const [searchTerm, setSearchTerm] = useState("");

  const [newValues, setFormValue] = useState<{
    category_id: string;
    game_names: string[];
  }>({
    category_id: "",
    game_names: [],
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const games: GameSchema[] = gamesAvailable
      ? Object.entries(gamesAvailable).map(([key, value]: any) => ({
          object_id: key,
          game_name: value.split(" ").slice(1).join(" "),
          category_id: value.split(" ")[0].replace(/[()]/g, ""),
        }))
      : [];

    setGames(games);
    setDefaultGames(games);
  }, [gamesAvailable]);

  useEffect(() => {
    setGames(
      defaultGames.filter(
        (item) =>
          item.game_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.object_id.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, defaultGames]);

  const gamesCategories: Record<string, string> | undefined = useMemo(() => {
    return config?.game_categories
      .filter((item: any) =>
        permissions.includes(CREATE_PERMISSION) ? true : item !== "CPI"
      )
      .reduce((obj: any, cur: any) => ({ ...obj, [cur]: cur }), {});
  }, [config?.game_categories, permissions]);

  const model = [
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: gamesCategories,
      defaultValue: "",
    },
    {
      display_name: "Game Name",
      name: "game_name",
      type: FieldType.STRING,
      defaultValue: "",
      main_field: true,
    },
  ];

  const multiple_games_modal = [
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: gamesCategories,
      defaultValue: "",
    },

    {
      display_name: "Game Names",
      name: "game_names",
      type: FieldType.ARRAY,
      defaultValue: [],
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Name",
          name: "name",
          type: FieldType.STRING,
          defaultValue: "",
        },
      ],
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          game_names: value.game_names.map((item: any) =>
            typeof item === "string" ? item : item.name
          ),
        };
      },
    },
  ];

  const submitMultipleGames = async () => {
    setSaving(true);

    const gamesArr = newValues.game_names.map((item: string) => ({
      name: item.split(" ").join("").toLowerCase(),
      display_name: `(${newValues.category_id}) ${item}`,
    }));

    const filteredGames = gamesArr.filter(
      (i: { display_name: string; name: string }) =>
        !(games ?? []).map((j: GameSchema) => j.object_id).includes(i.name)
    );

    try {
      const filteredGamesBulkCall = filteredGames.map(
        (item: { display_name: string; name: string }) =>
          postData(Endpoints.GAME_CREATION.url, {
            ...item,
          })
      );

      Promise.allSettled(filteredGamesBulkCall).then(function (values) {
        store.dispatch(
          queueNotification({
            message: "Games created Successfully",
            options: {
              key: "game_creation_success",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );
        setOpen(false);
        setGames([
          ...(games ?? []),
          ...(filteredGames.map(
            (item: { display_name: string; name: string }) => ({
              game_name: item.display_name,
              object_id: item.name,
              category_id: item.display_name.split(" ")[0].replace(/[()]/g, ""),
            })
          ) as GameSchema[]),
        ]);
      });
      setSaving(false);
    } catch (error: any) {
      if (!!error.response.data.error_message) {
        const errorMessage = error.response.data.error_message;
        store.dispatch(
          queueNotification({
            message: errorMessage,
            options: {
              key: "error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
      setSaving(false);
    }
  };

  return (
    <Box>
      {permissions?.includes("UKCV_admin_user_permission_role_8") && (
        <Box>
          <DialogWrapper
            isOpen={!!open}
            onClose={() => setOpen(false)}
            size="sm"
          >
            <DialogTitle>Add multiple games for CPI tests</DialogTitle>
            <DialogContent>
              <FormContent
                formValue={newValues}
                model={multiple_games_modal}
                page="game_creation"
                setFormValue={setFormValue}
              />
            </DialogContent>
            <DialogActions>
              <Button
                className="ml-1"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={saving}
                onClick={async () => {
                  submitMultipleGames();
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </DialogWrapper>
        </Box>
      )}
      <CollectionEditor
        response={games}
        page="game_creation"
        isLoading={!defaultGames.length && !games.length}
        parentField="name"
        endpoint={Endpoints.GAME_CREATION}
        model={model}
        renderCreateOverride={(onClick) => (
          <>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button onClick={onClick}>Create Game</Button>
              {permissions?.includes("UKCV_admin_user_permission_role_8") && (
                <Button onClick={() => setOpen(true)}>Bulk Create</Button>
              )}
            </ButtonGroup>
          </>
        )}
        renderSearchBar={() => {
          return (
            <SearchBar
              sx={{ marginBottom: 1 }}
              debounce
              value={searchTerm}
              setValue={(value: string) => setSearchTerm(value)}
            />
          );
        }}
        hideDelete
        onCreate={async (obj: any) => {
          try {
            const name = obj.game_name.split(" ").join("").toLowerCase();
            const display_name = `(${obj.category_id}) ${obj.game_name}`;

            await createItem(
              gameEndpoints.gameCreation,
              { name, display_name },
              false,
              currentGameId
            );

            const { url, objectPath } = getEndpointData(
              gameEndpoints.gameCreation,
              "game_creation",
              currentGameId
            );

            const newObj = { ...obj, object_id: name };

            setGames([...(games ?? []), newObj]);

            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: [[...(games ?? []), newObj]],
                };
              },
              false
            );
          } catch (e) {
            console.error(e);
          }
        }}
        onUpdate={async (obj: any) => {
          try {
            const display_name = `(${obj.category_id}) ${obj.game_name}`;

            await updateItem(
              gameEndpoints.gameUpdate,
              {
                name: obj.object_id,
                display_name,
                can_override_config: true,
                object_id: obj.object_id,
              },
              false,
              currentGameId
            );

            const newArray = (games ?? []).map((item: GameSchema) =>
              item.object_id === obj.object_id
                ? { ...item, game_name: obj.game_name }
                : item
            );

            const { url, objectPath } = getEndpointData(
              gameEndpoints.gameCreation,
              "game_creation",
              currentGameId
            );

            setGames(newArray);
            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: newArray,
                };
              },
              false
            );
          } catch (e) {
            console.error(e);
          }
        }}
      />
    </Box>
  );
};
