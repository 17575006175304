import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { IWeightedConfig } from "./JanusSetting";

interface Props {
  addWeightedConfig: (newConfig: IWeightedConfig) => void;
}

const columnSizes = [3, 9];

export const AddWeightedConfig = ({ addWeightedConfig }: Props) => {
  const defaultValue = {
    value: null,
    weight: null,
  };

  const [newConfig, setNewConfig] = useState<IWeightedConfig>(defaultValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewConfig({
      ...newConfig,
      [name]: parseFloat(value) || null,
    });
  };

  const isButtonDisabled =
    newConfig.value === null || newConfig.weight === null;

  const handleAddConfig = () => {
    if (!isButtonDisabled) {
      addWeightedConfig(newConfig);
      setNewConfig(defaultValue);
    }
  };

  return (
    <Box
      sx={{
        padding: "2rem 1.5rem",
        margin: "0.5rem 0",
        position: "relative",
        borderRadius: 4,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={columnSizes[0]}>
          <Typography variant="body1">Weight</Typography>
        </Grid>
        <Grid item xs={columnSizes[1]}>
          <TextField
            type="number"
            name="weight"
            placeholder="Enter Weight..."
            value={newConfig.weight || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={columnSizes[0]}>
          <Typography variant="body1">Value</Typography>
        </Grid>
        <Grid item xs={columnSizes[1]}>
          <TextField
            type="number"
            name="value"
            placeholder="Enter Value..."
            value={newConfig.value || ""}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <Button
        disabled={isButtonDisabled}
        onClick={handleAddConfig}
        variant="contained"
        sx={{ mt: 2 }}
      >
        Add
      </Button>
    </Box>
  );
};
