import { useSelector } from "react-redux";
import { GameId } from "../../../model/sidebar.model";
import { DefaultLevelFields } from "../defaultLevelFields";
import { FormModelsMap } from "../FormModelsMap";
import { selectSession } from "../../session/sessionSlice";

export const useGameModel = (gameId?: GameId) => {
  const { game_id: storeGameId } = useSelector(selectSession);
  if (!gameId) {
    // Get game id from store
    gameId = storeGameId;
  }

  return FormModelsMap()[gameId ?? ""]?.model ?? DefaultLevelFields();
};
