import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Flex } from "../../../components/Flex";
import { SettingsDialog } from "../../../components/SettingsDialog";
import {
  NestedSetting,
  NestedSettings,
  ValueType,
  VersionedSettings,
} from "../../../model/versioned-settings.model";
import { createSettingTitle } from "../../../utils/createSettingTitle";
import { getSettingsSources } from "../../../utils/getSettingsSources";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { selectDarkMode } from "../../general/generalSlice";
import { settingsSelector } from "../addToAllSlice";
import { AutoCompleteSelect } from "./AutocompleteSelect/AutoCompleteSelect";
import { StyledDropdown } from "./Filter/Filter";

export interface SettingsSource {
  id: string;
  settingIds: string[];
  nested_settings?: NestedSettings;
}

interface SourcePickerProps {
  onChange: (sourceId?: string) => void;
  selected?: string;
}

const StyledSourcePicker = styled(Box)`
  border: 1px solid #1d2b3b;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.5rem 0;
  max-height: 300px;
  overflow-y: auto;
`;

export const SourcePicker = ({ selected, onChange }: SourcePickerProps) => {
  const settings = useSelector(settingsSelector);
  const [selectedValues, setSelectedValues] = useState<{
    country: string;
    platform: string;
    version: string;
  }>({
    country: "",
    platform: "",
    version: "",
  });
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [settingsTitles, setSettingsTitles] = useState("");

  const theme = useSelector(selectDarkMode);
  const isDarkMode = theme === "dark";

  const [selectedSettings, setSelectedSettings] = useState<
    NestedSetting | undefined
  >();
  const [sources, setSources] = useState<SettingsSource[] | undefined>();

  useEffect(() => {
    const newSources = getSettingsSources(settings);
    setSources(newSources);
  }, [settings]);

  const handleGameEditionLabels = (value: string) => {
    switch (value) {
      case "ios":
        return "iOS";
      case "chinaios":
        return "China iOS";
      default:
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  };
  const countries = [
    ...new Set(settings.map((item: VersionedSettings) => item.country)),
  ].map((j: any) => ({
    label: j.charAt(0).toUpperCase() + j.slice(1).toLowerCase(),
    value: j,
  }));

  const gameEditions = [
    ...new Set(
      settings
        .filter((m: VersionedSettings) =>
          sources
            ?.map((k: SettingsSource) => k.settingIds)
            .flat()
            .includes(m.object_id)
        )
        .map((item: VersionedSettings) => item.game_edition_id)
    ),
  ].map((j: any) => ({
    label: handleGameEditionLabels(j),
    value: j,
  }));

  const versions = [
    ...new Set(settings.map((item: VersionedSettings) => item.max_version)),
  ].map((j: any) => ({
    label: j.charAt(0).toUpperCase() + j.slice(1).toLowerCase(),
    value: j,
  }));

  const getFiltereData = () => {
    return settings.find(
      (item: VersionedSettings) =>
        item.country === selectedValues.country &&
        item.game_edition_id === selectedValues.platform &&
        item.max_version === selectedValues.version
    );
  };
  return (
    <>
      <small>
        Pick a source from the settings below. <br />
        <br />
        Settings are grouped as{" "}
        <strong>
          <i>unique</i>
        </strong>{" "}
        settings objects. Hover a setting to view the other equivalent settings
        or click a setting to view it's contents.
      </small>
      <StyledSourcePicker>
        {isNullOrUndefined(sources) ? (
          <CircularProgress />
        ) : (
          <Flex justifyContent="flex-start" flexDirection="column">
            <StyledDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                options={countries}
                sx={{ mt: 2 }}
                id="countries"
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Country" />
                )}
                onOptionSelected={(event, selectedOption) =>
                  setSelectedValues({
                    ...selectedValues,
                    country: selectedOption.value,
                  })
                }
              />
            </StyledDropdown>

            <StyledDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                options={gameEditions}
                sx={{ mt: 2 }}
                id="platforms"
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Platform" />
                )}
                onOptionSelected={(event, selectedOption) =>
                  setSelectedValues({
                    ...selectedValues,
                    platform: selectedOption.value,
                  })
                }
              />
            </StyledDropdown>
            <StyledDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                options={versions}
                sx={{ mt: 2 }}
                id="versions"
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Version" />
                )}
                onOptionSelected={(event, selectedOption) =>
                  setSelectedValues({
                    ...selectedValues,
                    version: selectedOption.value,
                  })
                }
              />
            </StyledDropdown>

            {selectedValues?.country &&
              selectedValues?.platform &&
              selectedValues?.version && (
                <Box alignSelf="center">
                  <FormControlLabel
                    label={
                      <Button
                        variant="contained"
                        size="small"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        sx={{ my: 2 }}
                        color={"secondary"}
                        onClick={() => {
                          const setting = {
                            __description__: "",
                            __modified__: "",
                            __tag__: "",
                            __type__: ValueType.OBJECT,
                            __user__: "",
                            __value__:
                              sources.find((i: any) =>
                                i.settingIds.includes(
                                  getFiltereData().object_id
                                )
                              )?.nested_settings ?? {},
                          };

                          const settingTitles =
                            sources
                              .find((i: any) =>
                                i.settingIds.includes(
                                  getFiltereData().object_id
                                )
                              )
                              ?.settingIds.map((id) => {
                                const setting = settings?.find(
                                  (propSetting: VersionedSettings) =>
                                    propSetting.object_id === id
                                );
                                return createSettingTitle(setting) ?? id;
                              })
                              .join(" | ") ?? "";

                          setSelectedSettings(setting);
                          setSettingsTitles(settingTitles);
                          setIsSettingsDialogOpen(true);
                        }}
                      >
                        {selectedValues.platform}, {selectedValues.version},{" "}
                        {selectedValues.country}
                      </Button>
                    }
                    id="hideTooltips"
                    control={
                      <Checkbox
                        checked={
                          selected ===
                          (sources ?? []).find((i: any) =>
                            i.settingIds.includes(getFiltereData()?.object_id)
                          )?.id
                        }
                        onChange={() =>
                          onChange(
                            sources.find((i: any) =>
                              i.settingIds.includes(getFiltereData()?.object_id)
                            )?.id
                          )
                        }
                      />
                    }
                  />
                </Box>
              )}
          </Flex>
        )}
      </StyledSourcePicker>
      <SettingsDialog
        readOnly
        isOpen={isSettingsDialogOpen}
        onClose={() => setIsSettingsDialogOpen(false)}
        settings={selectedSettings}
        text={settingsTitles}
      />
    </>
  );
};
