import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { Reducer } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from "connected-react-router";
import gameWizardRulesFormSlice from "../features/GameWizardRulesForm/gameWizardRulesFormSlice";
import generalReducer, { GeneralState } from "../features/general/generalSlice";
import notificationReducer from "../features/notification/notificationSlice";
import settingReducer from "../features/AddToAll/components/Setting/settingSlice";
import sessionReducer from "../features/session/sessionSlice";
import crudReducer from "../components/CollectionEditor/crudSlice";
import layoutReducer, { LayoutState } from "../layout/layoutSlice";
import addToAllFilterReducer from "../features/AddToAll/components/Filter/filterSlice";
import addToAllReducer from "../features/AddToAll/addToAllSlice";
import janusSettingReducer from "../features/JanusSettings/JanusSettingsSlice";
import adminConfigReducer from "../features/Users/adminConfigSlice";
import clientConfigReducer from "../features/Users/clientConfigSlice";
import logReducer from "../features/LogsPage/logSlice";
import expandedLogReducer from "../features/LogsPage/ExpandedLog/expandedLogSlice";
import filterReducer from "../features/LogsPage/Filter/filterSlice";

import history from "../utils/history-util";

const layoutPersistConfig = {
  key: "layout",
  storage,
  blacklist: [],
};

const generalPersistConfig = {
  key: "general",
  storage,
  blacklist: ["showAllChangelogs", "loadingIFrame"],
};

const filterPersistConfig = {
  key: "filter",
  storage,
  blacklist: ["searchTerm", "versionOptions", "tooltip"],
};

const dataLogsFilterPersistConfig = {
  key: "filter",
  storage,
  blacklist: ["darkMode", "filters"],
};

export const store = configureStore({
  reducer: {
    router: connectRouter(history) as Reducer<RouterState>,
    crud: crudReducer,
    notification: notificationReducer,
    setting: settingReducer,
    session: sessionReducer,
    // @ts-ignore
    general: persistReducer(
      generalPersistConfig,
      generalReducer
    ) as unknown as Reducer<GeneralState, any>,
    // @ts-ignore
    layout: persistReducer(
      layoutPersistConfig,
      layoutReducer
    ) as unknown as Reducer<LayoutState, any>,
    // @ts-ignore
    filterReducer: persistReducer(filterPersistConfig, addToAllFilterReducer),
    addToAllReducer,
    janus_settings: janusSettingReducer,
    adminConfigReducer,
    clientConfigReducer,
    log: logReducer,
    expanded_log: expandedLogReducer,
    // @ts-ignore
    filter: persistReducer(dataLogsFilterPersistConfig, filterReducer),
    game_wizard_rules_form: gameWizardRulesFormSlice,
  },
  //@ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(thunk)
      .concat(routerMiddleware(history)),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
