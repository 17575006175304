import React from "react";
import styled from "styled-components";
import { UPDATE_FILTER, selectFilters, RESET_FILTERS } from "./filterSlice";
import { useSelector, useDispatch } from "react-redux";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import {
  QUEUE_NOTIFICATION,
  selectConfig,
  selectLoadingConfig,
} from "../logSlice";
import { ToastTypes } from "../ToastTypes";
import { fetchLogs } from "../logThunks";
import moment from "moment";
import { Box, Button, Checkbox, FormControl, TextField } from "@mui/material";
import { Flex } from "../../../components/Flex";
import { AutoCompleteSelect } from "../../AddToAll/components/AutocompleteSelect/AutoCompleteSelect";

interface FilterType {
  key: string;
  name: string;
  isMulti?: boolean;
  options?: string[];
  isDate?: boolean;
  isTextbox?: boolean;
  isDatePicker?: boolean;
  isCheckbox?: boolean;
}

const filters: FilterType[] = [
  {
    key: "target_game_id",
    name: "Game",
    options: ["tuna", "tirescale", "mergezoo"],
  },
  {
    key: "user_platform",
    name: "User Platform",
    options: ["admin", "kanga", "heimdall", "optimus"],
  },
  { key: "related_object_id", name: "Object ID", isTextbox: true },
  {
    key: "related_object_type",
    name: "Object Type",
    isTextbox: true,
  },
  { key: "user", name: "User", isTextbox: true },
  {
    key: "change_type",
    name: "Change Type",
    options: ["create", "update", "destroy"],
  },
  {
    key: "change_location",
    name: "Change Location",
    isTextbox: true,
  },
  { key: "date", name: "Date", isDatePicker: true },
  {
    key: "include_non_game_specific",
    name: "Include Non Game Specific",
    isCheckbox: true,
  },
];

const StyledFilter = styled(Box)`
  padding-bottom: 2rem;
  padding-top: 2rem;
  border-radius: 4px;
  border-bottom: 2px solid orange;
`;

const StyledDropdowns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0.75rem;
  flex-wrap: wrap;
`;

const StyledDropdown = styled.div`
  margin: 0.3rem;
  width: 200px;
`;

const StyledButtons = styled.div`
  margin-top: 1.4rem;
  button {
    margin: 0.2rem;
  }
`;

const Filter = () => {
  const dispatch = useDispatch();

  const filterValues = useSelector(selectFilters);
  const config = useSelector(selectConfig);
  const loadingConfig = useSelector(selectLoadingConfig);

  const handleFetchLogs = async () => {
    dispatch(UPDATE_FILTER({ key: "page", value: 1 }));
    const resultAction = await dispatch(
      fetchLogs({ ...filterValues, page: 1 })
    );
    //@ts-ignore
    if (!fetchLogs.fulfilled.match(resultAction)) {
      //@ts-ignore
      const errorMessage = `Error fetching logs: ${resultAction.error.message}`;
      dispatch(
        QUEUE_NOTIFICATION({
          message: errorMessage,
          type: ToastTypes.ERROR,
          id: `error_fetch_logs`,
        })
      );
    }
  };

  return (
    <StyledFilter>
      <Flex display="flex" justifyContent="center" alignItems="center">
        <StyledDropdowns>
          {filters.map((filter) => {
            if (filter.isDatePicker) {
              return (
                <StyledDropdown key={filter.key}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box className="filter-label" sx={{ mb: 1 }}>
                      {filter.name}
                    </Box>
                    <DesktopDatePicker
                      value={
                        filterValues[filter.key]
                          ? moment(filterValues[filter.key]).toISOString(true)
                          : undefined
                      }
                      inputFormat="MM/dd/yyyy"
                      onChange={(value: any) => {
                        const formattedDate = moment(value).format("YYYYMMDD");
                        if (formattedDate && formattedDate !== "Invalid date") {
                          dispatch(
                            UPDATE_FILTER({
                              key: filter.key,
                              value: formattedDate,
                            })
                          );
                        } else {
                          dispatch(
                            UPDATE_FILTER({
                              key: filter.key,
                              value: undefined,
                            })
                          );
                        }
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </StyledDropdown>
              );
            }
            if (filter.isTextbox) {
              return (
                <StyledDropdown key={filter.key}>
                  <Box className="filter-label" sx={{ mb: 1 }}>
                    {filter.name}
                  </Box>
                  <TextField
                    key={filter.key}
                    variant="outlined"
                    size="small"
                    type="text"
                    placeholder={`Enter ${filter.name}...`}
                    value={filterValues[filter.key] ?? ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        UPDATE_FILTER({
                          key: filter.key,
                          value: event.target.value,
                        })
                      )
                    }
                  />
                </StyledDropdown>
              );
            }

            if (filter.isCheckbox) {
              return (
                <StyledDropdown key={filter.key}>
                  <Flex
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: 1 }}
                  >
                    <Box className="filter-label">{filter.name}</Box>
                    <FormControl>
                      <Checkbox
                        key={filter.key}
                        style={{ position: "static", margin: 0 }}
                        defaultChecked={true}
                        placeholder={`Enter ${filter.name}...`}
                        value={filterValues[filter.key] ?? true}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          dispatch(
                            UPDATE_FILTER({
                              key: filter.key,
                              value: event.target.checked,
                            })
                          )
                        }
                      />
                    </FormControl>
                  </Flex>
                </StyledDropdown>
              );
            }

            return (
              <StyledDropdown key={filter.key}>
                <Box className="filter-label" sx={{ mb: 1 }}>
                  {filter.name}
                </Box>
                <AutoCompleteSelect
                  disabled={loadingConfig}
                  id="auto-id"
                  options={
                    filter.key === "target_game_id"
                      ? Object.keys(config?.settings?.games_available ?? {})
                      : filter.options
                  }
                  getOptionLabel={(option: any) =>
                    filter.key === "target_game_id"
                      ? config?.settings?.games_available[option] ?? option
                      : option
                  }
                  getOptionSelected={(option: any, value: any) =>
                    option.value === value.value
                  }
                  selected={filterValues[filter.key]}
                  onOptionSelected={(event, selectedOption, reason) => {
                    dispatch(
                      UPDATE_FILTER({
                        key: filter.key,
                        value: selectedOption,
                      })
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      placeholder="Select..."
                    />
                  )}
                  size="small"
                />
              </StyledDropdown>
            );
          })}
          <StyledButtons>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleFetchLogs()}
            >
              Apply
            </Button>
            <Button
              variant="outlined"
              onClick={() => dispatch(RESET_FILTERS())}
            >
              Reset
            </Button>
          </StyledButtons>
        </StyledDropdowns>
      </Flex>
    </StyledFilter>
  );
};

export default Filter;
