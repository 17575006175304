import Endpoints from "../../../../constants/endpoints";
import { FieldType, Form } from "../../../../model/crud.model";
import { IInventoryItem } from "../../../../model/daily-tasks.model";
import { useCollection } from "../../../../network/useCollection";
import { useSolitaireAreas } from "../../../../network/useSolitaireAreas";
import { DefaultLevelFields } from "../../defaultLevelFields";
import { ISolitaireArea } from "./types";

export const CassolitairehfModel = () => {
  const { areas } = useSolitaireAreas();

  const { collection: lootBoxes } = useCollection<IInventoryItem>(
    Endpoints.INVENTORY_ITEMS
  );

  const areaClientIds: { [id: string]: any } = {};

  areas.forEach(
    (area: ISolitaireArea) => (areaClientIds[area.object_id] = area.name)
  );

  return [
    ...DefaultLevelFields(),
    {
      display_name: "Area ID",
      name: "area_id",
      required: true,
      type: FieldType.SELECT,
      options: areaClientIds,
    },
    {
      display_name: "Combo System",
      name: "combo_system",
      type: FieldType.ARRAY,
      defaultValue: [5],
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
    {
      display_name: "Background Image",
      name: "background_image_id",
      required: false,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Star Collection",
      name: "star_collection",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: StarCollectionModal("star_collection"),
      page: "levels",
      defaultValue: {
        override_scene_value: false,
        star_decay_timer: [0, 0, 0],
      },
    },
    {
      display_name: "Combo Reward Override",
      name: "combo_reward_override",
      type: FieldType.ARRAY,
      defaultValue: [],
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
    {
      display_name: "Level Difficulty",
      name: "level_difficulty",
      type: FieldType.NUMBER,
      defaultValue: 0,
    },
    {
      display_name: "CMS Settings",
      name: "cms_settings",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: CMSModal("cms_settings"),
      page: "levels",
      defaultValue: {
        cms_deck_size: undefined,
        cms_deck_range: undefined,
        cms_deck_base: undefined,
        cms_deck_first: undefined,
        cms_deck_increase: undefined,
        cms_board_range: undefined,
      },
      onChangeOverride: (value: any, obj: any) => {
        // Move settings up a level since the object structure is different on backend
        return {
          ...obj,
          ...value,
          ...value.cms_settings,
        };
      },
    },
    {
      display_name: "Combo Reveal Settings",
      name: "combo_reveal_settings",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: ComboRevealModal("combo_reveal_settings"),
      page: "levels",
      defaultValue: {
        combo_reveal_modifier: undefined,
        combo_threshold: undefined,
        combo_step_value: undefined,
      },
      onChangeOverride: (value: any, obj: any) => {
        // Move settings up a level since the object structure is different on backend
        return {
          ...obj,
          ...value,
          ...value.combo_reveal_settings,
        };
      },
    },
    {
      display_name: "Effected by Dynamic Difficulty",
      name: "effected_by_dynamic_difficulty",
      type: FieldType.CHECKBOX,
      defaultValue: true,
    },
    {
      display_name: "Loot Box",
      name: "lootbox_code",
      type: FieldType.SELECT,
      options: (lootBoxes ?? []).map((item: any) => ({
        label: item.code,
        value: item.code,
      })),
    },
  ] as Form;
};

export const StarCollectionModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Override Scene Value",
      name: "override_scene_value",
      type: FieldType.CHECKBOX,
      parentField: parentField,
      defaultValue: false,
    },
    {
      display_name: "Star Decay Timer",
      name: "star_decay_timer",
      type: FieldType.ARRAY,
      parentField: parentField,
      defaultValue: [0, 0, 0],
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Value",
          name: "value",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
    },
  ];

  return model;
};

export const CMSModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "CMS Deck Size",
      name: "cms_deck_size",
      type: FieldType.NUMBER,
      parentField: parentField,
      defaultValue: 7,
      helperText: "Set CMS Deck Size to -1 to disable CMS Deck",
    },
    {
      display_name: "CMS Deck Range",
      name: "cms_deck_range",
      type: FieldType.NUMBER,
      parentField: parentField,
    },
    {
      display_name: "CMS Deck Base",
      name: "cms_deck_base",
      type: FieldType.NUMBER,
      parentField: parentField,
    },
    {
      display_name: "CMS Deck First",
      name: "cms_deck_first",
      type: FieldType.NUMBER,
      parentField: parentField,
    },
    {
      display_name: "CMS Deck Increase",
      name: "cms_deck_increase",
      type: FieldType.NUMBER,
      parentField: parentField,
    },
    {
      display_name: "CMS Board Range",
      name: "cms_board_range",
      type: FieldType.NUMBER,
      parentField: parentField,
    },
  ];

  return model;
};

export const ComboRevealModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Combo Reveal Modifier",
      name: "combo_reveal_modifier",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.combo_reveal_modifier ? obj?.combo_reveal_modifier : 1;
      },
    },
    {
      display_name: "Combo Threshold",
      name: "combo_threshold",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.combo_threshold ? obj?.combo_threshold : 2;
      },
    },
    {
      display_name: "Combo Step Value",
      name: "combo_step_value",
      type: FieldType.NUMBER,
      parentField: parentField,
      valueFunction(obj?) {
        return obj?.combo_step_value ? obj?.combo_step_value : 0.15;
      },
    },
  ];

  return model;
};
