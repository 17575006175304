import {
  VersionedSettings,
  VersionedSettingss,
} from "../model/versioned-settings.model";

export const getSettingById = (
  objectId: string,
  versionedSettings?: VersionedSettingss
): VersionedSettings | undefined => {
  return versionedSettings?.find((setting) => setting.object_id === objectId);
};
