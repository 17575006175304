import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import _ from "lodash";
import md5 from "md5";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogWrapper } from "../../../../components/DialogWrapper";
import { Flex } from "../../../../components/Flex";
import {
  CountryConfig,
  NestedSetting,
  ReactSelectArray,
  TooltipState,
  VersionedSettings,
  VersionedSettingss,
} from "../../../../model/versioned-settings.model";
import { createSettingTitle } from "../../../../utils/createSettingTitle";
import { getSettingPath } from "../../../../utils/getSettingPath";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import {
  updateModifiedByPath,
  updateParentDifferent,
} from "../../../../utils/updateModified";
import { convertToTopLevelSetting } from "../../../../worker";
import {
  combinedSettingsSelector,
  countriesSelector,
  setAfterCombineYScrollPosition,
  setIdsToOverrideFilters,
  settingsSelector,
  updateCombinedSettings,
} from "../../addToAllSlice";
import {
  blankTooltip,
  editModeSelector,
  selectAllPlatforms,
  selectVersionOptions,
  setTooltip,
  updateDropdown,
  updateSwitcher,
} from "../Filter/filterSlice";
import SettingsContainer from "../Setting/SettingsContainer";
import TooltipSearch from "./TooltipSearch";

interface Props {
  tooltip: TooltipState;
  settings?: VersionedSettingss;
  countries?: ReactSelectArray;
}

export function selectSettingIds(settingIds: string[], dispatch: any) {
  dispatch(setAfterCombineYScrollPosition(window.scrollY));

  // Clear tooltip
  dispatch(setTooltip(undefined));

  // Clear switcher
  dispatch(updateSwitcher(undefined));

  // Select only those specific setting ids
  dispatch(setIdsToOverrideFilters(settingIds));
}

const DifferencesModal = (props: Props) => {
  const dispatch = useDispatch();

  const [groupedValues, setGroupedValues] = useState<{
    [id: string]: string[];
  }>();
  const [groupedValuesOrder, setGroupedValuesOrder] = useState<string[]>();
  const [groupedValuesBool, setGroupedValuesBool] = useState(true);
  const [valueMap, setValueMap] = useState<{ [id: string]: any }>({});

  const [valueSearchTerm, setValueSearchTerm] = useState("");
  const [settingSearchTerm, setSettingSearchTerm] = useState("");
  const [showAllGroup, setShowAllGroup] = useState<{ [id: string]: boolean }>(
    {}
  );

  const settings = useSelector(settingsSelector);

  const combinedSettings = useSelector(combinedSettingsSelector);

  const isEditMode = useSelector(editModeSelector);

  const calculateGroupedValues = useCallback(() => {
    const groupedValuesVar: { [id: string]: string[] } = {};
    const valueMapVar: { [id: string]: any } = {};

    if (
      props.tooltip.values &&
      Object.keys(props.tooltip.values).length !== 0
    ) {
      Object.entries(props.tooltip.values).forEach(([key, value]) => {
        const valueKeyForGrouping =
          value && typeof value === "object" ? md5(value) : value;
        valueMapVar[valueKeyForGrouping] = value;

        if (!groupedValuesVar[valueKeyForGrouping]) {
          groupedValuesVar[valueKeyForGrouping] = [];
        }
        groupedValuesVar[valueKeyForGrouping].push(key);
        showAllGroup[valueKeyForGrouping] =
          showAllGroup[valueKeyForGrouping] ?? false;
      });
    }

    const groupedValuesOrderVar = Object.keys(groupedValuesVar).sort(
      (a, b) => groupedValuesVar[a].length - groupedValuesVar[b].length
    );

    setGroupedValues(groupedValuesVar);
    setGroupedValuesOrder(groupedValuesOrderVar);
    setValueMap(valueMapVar);
  }, [props.tooltip.values, showAllGroup]);

  useEffect(() => {
    calculateGroupedValues();
  }, [calculateGroupedValues]);

  const getFilteredSettings = (stringIncludedInId: string) => {
    return Object.keys(props.tooltip.values ?? {}).filter((id) =>
      id.includes(stringIncludedInId)
    );
  };

  const androidSettings = getFilteredSettings("android");
  const iosSettings = getFilteredSettings("ios");
  const latestSettings = getFilteredSettings("latest");

  const getValueOfSetting = (
    valueOrHash: NestedSetting | string,
    valuePath: (string | number)[]
  ) => {
    // Get one of the setting ids that has this value, use path to get value
    const tooltipValues = props.tooltip.values ?? {};
    const settingId = Object.keys(tooltipValues).find(
      (key) => tooltipValues[key] === valueOrHash
    );
    const settingToTakeValueFrom = settings?.find(
      (setting: VersionedSettings) => setting.object_id === settingId
    );
    return _.get(convertToTopLevelSetting(settingToTakeValueFrom), valuePath);
  };

  const getValuePath = () => {
    const path = props.tooltip.path;
    const stringPath = getSettingPath(path) ?? [];

    return (["__value__"] as (string | number)[]).concat(
      ...stringPath.map((segment, index) =>
        index === stringPath.length - 1 ? [segment] : [segment, "__value__"]
      )
    );
  };

  const setValue = (
    newValueOrHash: NestedSetting | string,
    settingFilter?: { game_edition_id?: string; max_version?: string }
  ) => {
    const path = props.tooltip.path;
    const stringPath = getSettingPath(path) ?? [];

    const pathWithValuesInBetween = getValuePath();

    const valuePath = [...(pathWithValuesInBetween ?? []), "__value__"];

    let newCombinedSettings: NestedSetting = _.cloneDeep(combinedSettings);

    const settingValueToSet = getValueOfSetting(
      newValueOrHash,
      pathWithValuesInBetween
    );

    // If value is undefined, delete value from combinedSettings
    if (!settingValueToSet) {
      _.unset(newCombinedSettings, pathWithValuesInBetween ?? []);
    } else {
      // If it doesn't exist on certain settings it needs creating
      _.set(newCombinedSettings, valuePath ?? [], settingValueToSet);
      const settingToSet = {
        different: false,
        __value__: settingValueToSet.__value__,
        __description__: settingValueToSet.__description__,
        __tag__: settingValueToSet.__tag__,
        __type__: settingValueToSet.__type__,
        __modified__: settingValueToSet.__modified__,
        __user__: settingValueToSet.__user__,
        __valuehash__: settingValueToSet.__valuehash__,
      };
      _.set(newCombinedSettings, pathWithValuesInBetween ?? [], settingToSet);
    }

    // Update modified
    newCombinedSettings = updateModifiedByPath(newCombinedSettings, stringPath);

    // Update different
    newCombinedSettings = updateParentDifferent(
      newCombinedSettings,
      stringPath
    );

    dispatch(setTooltip(blankTooltip));

    // Update changes with the difference
    dispatch(updateCombinedSettings(newCombinedSettings));
  };

  const parentExistsOnAll =
    props.tooltip.currentLevel === 0 ||
    Object.values(props.tooltip.parentValues ?? {}).every(
      (value) => !isNullOrUndefined(value)
    );

  return (
    <DialogWrapper
      isOpen={!!props.tooltip.path}
      onClose={() => dispatch(setTooltip(blankTooltip))}
    >
      <DialogTitle>View Differences</DialogTitle>
      <DialogContent>
        <Box className="popover-tooltip">
          {!props.tooltip.isCollection && (
            <>
              <FormControl>
                <Typography>
                  <Checkbox
                    size="small"
                    id="hideCheck"
                    checked={groupedValuesBool}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setGroupedValuesBool(event.target.checked)
                    }
                  />
                  Group settings that have the same value
                </Typography>
              </FormControl>
              <br />

              <TooltipSearch
                placeholder={
                  groupedValuesBool
                    ? "Search for a value..."
                    : "Search for a setting..."
                }
                onChange={(text: string) => {
                  groupedValuesBool
                    ? setValueSearchTerm(text)
                    : setSettingSearchTerm(text);
                }}
              />
            </>
          )}
          <Box my={2}>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  disabled={latestSettings.length === 0}
                  onClick={() => selectSettingIds(latestSettings, dispatch)}
                  variant="contained"
                  size="small"
                >
                  Edit Latest
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={androidSettings.length === 0}
                  onClick={() => selectSettingIds(androidSettings, dispatch)}
                  variant="contained"
                  size="small"
                >
                  Edit Android
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={iosSettings.length === 0}
                  onClick={() => selectSettingIds(iosSettings, dispatch)}
                  variant="contained"
                  size="small"
                >
                  Edit iOS
                </Button>
              </Grid>
            </Grid>
          </Box>

          {!groupedValues ? (
            <CircularProgress />
          ) : groupedValuesBool && groupedValuesOrder ? (
            Object.keys(groupedValues ?? {})
              .sort(
                (a, b) =>
                  groupedValuesOrder.indexOf(a) - groupedValuesOrder.indexOf(b)
              )
              .filter((key) =>
                key === "null"
                  ? "does not exist".includes(valueSearchTerm.toLowerCase())
                  : key.toLowerCase().includes(valueSearchTerm.toLowerCase())
              )
              .map((key: string) => {
                return (
                  <Box
                    flexDirection={
                      props.tooltip.isCollection ? "column" : "row"
                    }
                    className="setting-id-value"
                    key={key}
                  >
                    {isNullOrUndefined(groupedValues) ? (
                      <CircularProgress />
                    ) : (
                      !isNullOrUndefined(groupedValues[key]) && (
                        <Box
                          className="setting-id"
                          width={props.tooltip.isCollection ? "100%" : "auto"}
                        >
                          <Box
                            className="value"
                            width={props.tooltip.isCollection ? "100%" : "auto"}
                          >
                            {isNullOrUndefined(key) ||
                            key === "null" ||
                            key === "undefined" ? (
                              <span className="setting-value-not-exist">
                                {" "}
                                Does not exist
                              </span>
                            ) : props.tooltip.isCollection ? (
                              <SettingsContainer
                                setting={getValueOfSetting(key, getValuePath())}
                                readOnly
                              />
                            ) : (
                              key
                            )}{" "}
                            ({groupedValues[key].length}):
                          </Box>
                          <div
                            className={classNames("setting-badge-container", {
                              truncated:
                                !showAllGroup[key] &&
                                groupedValues[key].length > 7,
                            })}
                          >
                            {groupedValues[key].map(
                              (id: string, index: number) => {
                                if (!showAllGroup[key] && index > 7) {
                                  return null;
                                }
                                return <SettingBadge key={id} id={id} />;
                              }
                            )}
                            {!showAllGroup[key] &&
                              groupedValues[key].length > 7 && (
                                <p className="read-more">
                                  <button
                                    onClick={() => {
                                      setShowAllGroup({
                                        ...showAllGroup,
                                        [key]: !showAllGroup[key],
                                      });
                                    }}
                                    className="button"
                                  >
                                    {!showAllGroup[key]
                                      ? "Show More"
                                      : "Show Less"}
                                  </button>
                                </p>
                              )}
                          </div>
                        </Box>
                      )
                    )}

                    <br />

                    <Flex m={2} flexDirection="column">
                      <Box>
                        <Button
                          onClick={() =>
                            selectSettingIds(groupedValues[key], dispatch)
                          }
                          variant="outlined"
                          size="small"
                        >
                          Edit group
                        </Button>
                      </Box>
                      <Divider style={{ margin: "1rem 0" }} />
                      <Box mb={1}>
                        <Button
                          onClick={() => setValue(valueMap[key])}
                          variant="outlined"
                          size="small"
                          disabled={!parentExistsOnAll || !isEditMode}
                        >
                          Set selected settings to this value
                        </Button>
                      </Box>
                      {/*<Box mb={1}>*/}
                      {/*  <Button*/}
                      {/*    onClick={() =>*/}
                      {/*      selectSettingIds(groupedValues[key], dispatch)*/}
                      {/*    }*/}
                      {/*    variant="outlined"*/}
                      {/*    size="small"*/}
                      {/*  >*/}
                      {/*    Set this value on specific settings*/}
                      {/*  </Button>*/}
                      {/*</Box>*/}

                      {/*<Box mb={1}>*/}
                      {/*  <Button*/}
                      {/*    onClick={() =>*/}
                      {/*      setValue(valueMap[key], props.tooltip.path, {*/}
                      {/*        game_edition_id: "android",*/}
                      {/*        max_version: "latest",*/}
                      {/*      })*/}
                      {/*    }*/}
                      {/*    variant="outlined"*/}
                      {/*    size="small"*/}
                      {/*  >*/}
                      {/*    Set this value on Android Latest*/}
                      {/*  </Button>*/}
                      {/*</Box>*/}
                      {/*<Box mb={1}>*/}
                      {/*  <Button*/}
                      {/*    onClick={() =>*/}
                      {/*      setValue(valueMap[key], props.tooltip.path, {*/}
                      {/*        game_edition_id: "ios",*/}
                      {/*        max_version: "latest",*/}
                      {/*      })*/}
                      {/*    }*/}
                      {/*    variant="outlined"*/}
                      {/*    size="small"*/}
                      {/*  >*/}
                      {/*    Set this value on iOS Latest*/}
                      {/*  </Button>*/}
                      {/*</Box>*/}
                    </Flex>
                  </Box>
                );
              })
          ) : (
            props.tooltip.values &&
            Object.keys(props.tooltip.values).length !== 0 &&
            Object.keys(props.tooltip.values)
              .sort()
              .filter((key) => {
                // If matches either of the setting title array then return true
                const settingTitle = createSettingTitle(
                  props.settings?.find((setting) => setting.object_id === key),
                  props.countries
                );

                const settingTitleArr = settingTitle?.split(",");
                const settingSearchTermArr = settingSearchTerm?.split(" ");

                if (settingSearchTermArr.length === 0) {
                  return true;
                }

                if (settingSearchTermArr.length === 1) {
                  return settingTitleArr?.some((title) =>
                    title
                      .toLowerCase()
                      .includes(settingSearchTermArr[0].toLowerCase())
                  );
                }

                return settingSearchTermArr.every((term) =>
                  settingTitleArr?.some((title) =>
                    title.toLowerCase().includes(term.toLowerCase())
                  )
                );
              })
              .map((key: string) => {
                return (
                  <div className="setting-id-value" key={key}>
                    <span className="setting-id">
                      <SettingBadge id={key} />
                    </span>
                    <br />
                    {!isNullOrUndefined(props.tooltip.values![key as any]) ? (
                      <span className="value">
                        {" "}
                        {props.tooltip.values![key as any].toString()}
                      </span>
                    ) : (
                      <span className="setting-value-not-exist">
                        {" "}
                        Does not exist
                      </span>
                    )}
                  </div>
                );
              })
          )}
        </Box>
      </DialogContent>
    </DialogWrapper>
  );
};

export default DifferencesModal;

const SettingBadge = ({ id }: { id: string; settingTitleArr?: string[] }) => {
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelector);
  const platforms = useSelector(selectAllPlatforms);
  const versions = useSelector(selectVersionOptions);
  const settings = useSelector(settingsSelector);

  const settingTitle = createSettingTitle(
    settings?.find((setting: VersionedSettings) => setting.object_id === id),
    countries
  );

  const settingTitleArr = settingTitle?.split(",");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editSettings = (
    platformToSelect: string,
    versionToSelect: string,
    countryToSelect: string
  ) => {
    // Clear tooltip
    dispatch(setTooltip(undefined));

    // Set platforms
    dispatch(
      updateDropdown({
        dropdown: "platforms",
        selected: platforms.filter(
          (platform: any) => platformToSelect.trim() === platform.label
        ),
      })
    );

    // Set countries
    dispatch(
      updateDropdown({
        dropdown: "countries",
        selected: countries.filter(
          (country: CountryConfig) => countryToSelect.trim() === country.label
        ),
      })
    );

    // Set versions
    dispatch(
      updateDropdown({
        dropdown: "versions",
        selected: versions.filter(
          (version: any) => versionToSelect.trim() === version.label
        ),
      })
    );

    // Clear switcher
    dispatch(updateSwitcher(undefined));

    // Clear setting ids
    dispatch(setIdsToOverrideFilters(undefined));
  };

  return (
    <Box m={1}>
      <Button
        variant="contained"
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {settingTitle ?? id}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            if (settingTitleArr) {
              editSettings(
                settingTitleArr[0],
                settingTitleArr[1],
                settingTitleArr[2]
              );
            }
          }}
        >
          Edit
        </MenuItem>
      </Menu>
    </Box>
  );
};
