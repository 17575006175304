import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { RootState } from "../../app/store";

export type NotificationPayloadOptions = OptionsObject & { key: SnackbarKey };
export type NotificationPayload = {
  message: SnackbarMessage;
  options: NotificationPayloadOptions;
  dismissed?: boolean;
};

export enum SnackbarVariant {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

interface NotificationState {
  notifications: NotificationPayload[];
}

const initialState: NotificationState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    queueNotification: (
      state,
      action: PayloadAction<{
        message: SnackbarMessage;
        options?: OptionsObject;
        dismissed?: boolean;
      }>
    ) => {
      state.notifications = [
        ...state.notifications,
        {
          ...action.payload,
          options: {
            preventDuplicate: true,
            ...action.payload.options,
            key:
              action.payload.options?.key ??
              new Date().getTime() + Math.random(),
          },
        } as any,
      ];
    },
    closeNotification: (
      state,
      action: PayloadAction<{ key: SnackbarKey; dismissAll?: boolean }>
    ) => {
      state.notifications = state.notifications.map((notification) =>
        action.payload.dismissAll ||
        notification.options.key === action.payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      );
    },
    removeNotification: (state, action: PayloadAction<SnackbarKey>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.options.key !== action.payload
      );
    },
  },
});

export const { queueNotification, closeNotification, removeNotification } =
  notificationSlice.actions;

export const selectNotifications = (state: RootState) =>
  state.notification.notifications;

export default notificationSlice.reducer;
