import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setSearchField,
  setSearchTerm,
} from "../components/CollectionEditor/crudSlice";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    dispatch(setSearchTerm(""));
    dispatch(setSearchField("display_name"));
  }, [dispatch, pathname]);

  return null;
}
