import { FieldType, Form } from "../../../../model/crud.model";

export const BidReductionModel = () => {
  const bidReductionModel: Form = [
    {
      main_field: true,
      display_name: "Bid Reduction",
      name: "margin",
      type: FieldType.NUMBER,
      defaultValue: 0,
      required: true,
    },
  ];

  return bidReductionModel;
};
