import { IDatabaseObject } from "../../model/database-object.model";

export interface ICampaign extends IDatabaseObject {
  budget?: number;
  budget_interval_ms?: number;
  budget_updated_on?: number;
  campaign_type: string;
  click_url?: string;
  country_codes?: string[];
  end?: number;
  game_ids?: string[];
  is_simulation?: boolean;
  min_bids_per_country?: { [id: string]: number[] };
  max_bids_per_country?: { [id: string]: number[] };
  min_ltvs_per_country?: { [id: string]: number[] };
  name?: string;
  probabilities_per_country?: { [id: string]: number[] };
  spend?: number;
  start?: number;
  campaign_name?: string;
  xpromo_creative_id: string;
  qa_status: CampaignStatus;
}

export enum CampaignStatus {
  UNTESTED = "untested",
  APPROVED = "approved",
  FAILED = "failed",
}
