import { DifferenceType } from "../utils/DifferenceType";
import SettingParts from "../utils/SettingParts";
import { IDatabaseObject } from "./database-object.model";

export type VersionedSettingss = VersionedSettings[];

export interface VersionedSettings extends IDatabaseObject {
  game_id: string;
  game_edition_id: string;
  max_version: string;
  country: string;
  nested_settings: NestedSettings;
  name: string;
  updated_on: number;
  _cas?: number;
  cas_token?: number;
}

export interface NestedSettings {
  [id: string]: NestedSetting;
}

export enum ValueType {
  STRING = "string",
  OBJECT = "object",
  ARRAY = "array",
  INT = "int",
  DOUBLE = "double",
  BOOLEAN = "boolean",
  NULL = "null",
  NUMBER = "number",
}

export const ValueTypeColors = {
  [ValueType.OBJECT]: "#6f42c1",
  [ValueType.ARRAY]: "#fd7e14",
  [ValueType.STRING]: "#198754",
  [ValueType.INT]: "#0d6efd",
  [ValueType.DOUBLE]: "#0aa2c0",
  [ValueType.BOOLEAN]: "#ffc107",
  [ValueType.NULL]: "#d63384",
  [ValueType.NUMBER]: "#0d6efd",
};

type SettingValueMap = {
  [ValueType.STRING]: string;
  [ValueType.OBJECT]: { [id: string]: NestedSetting };
  [ValueType.ARRAY]: NestedSetting[];
  [ValueType.INT]: number;
  [ValueType.DOUBLE]: number;
  [ValueType.BOOLEAN]: boolean;
  [ValueType.NULL]: null;
};

export type SettingValue<T extends ValueType> = T extends keyof SettingValueMap
  ? SettingValueMap[T]
  : never;

export type NestedSetting<T extends ValueType = ValueType> = {
  different?: boolean;
  values?: any;
  lastChangeFound?: number;
  id?: string;
  ids?: { [id: string]: string };
  name?: string;
  __value__: SettingValue<T>;
  __user__: string;
  __type__: T;
  __modified__: string;
  __tag__: string;
  __description__: string;
  __valuehash__?: string;
  valuehashdifferent?: boolean;
};

export type TooltipTextArray = TooltipText[];

interface TooltipText {
  added?: boolean;
  removed?: boolean;
  value: string;
}

export interface TooltipState {
  target: HTMLElement | null;
  text: string | TooltipTextArray;
  onChange?: (str: string | TooltipTextArray) => void;
  values?: { [id: string]: any };
  isCollection?: boolean;
  path?: IPath[];
  parentValues?: Record<string, any>;
  currentLevel?: number;
}

export interface DiffTooltipState {
  id: string;
  target: HTMLElement;
  text: string | TooltipTextArray;
  onChange?: (str: string | TooltipTextArray) => void;
  values?: { [id: string]: any };
  isCollection?: boolean;
  path?: IPath[];
}

export enum GameEdition {
  ANDROID = "android",
  IOS = "ios",
}

export enum GameEditionLabel {
  ANDROID = "Android",
  IOS = "iOS",
}

export interface IPath {
  node_type: "dict" | "list";
  node_key: number | string;
}

export type ReactSelectArray = ReactSelectObject[];

export interface ReactSelectObject {
  label: string;
  value: string;
}

export interface SwitcherObject {
  name: string;
  game_edition_id_filters: string[];
  game_edition_version_filters: string[];
  country_filters: string[];
  versioned_settings_id_filters: string[];
  country_group_filters: string[];
}

export interface CountryConfig {
  label: string;
  value: string;
}

export const serverTimeFormat = "YYYYMMDDTHHmmss.SSS[Z]";

export type Diff = { [key: string]: DifferenceEntry };

export interface DifferenceEntry {
  type: { [key in keyof typeof SettingParts]: DifferenceType };
  //changed: { [id: keyof NestedSetting]: DifferenceType };
  children: Diff;
}
