import {
  IconButton,
  lighten,
  Checkbox,
  alpha,
  MenuItem,
  Divider,
  Menu,
  Typography,
  Box,
  Collapse,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React, { useState } from "react";
import { GameId } from "../../model/sidebar.model";
import { CrudKeys } from "./crudSlice";
import { FormContent } from "./FormContent";
import { DuplicateModal } from "./Modals/DuplicateModal";
import { FormItem } from "../../model/crud.model";
import { Endpoint, INestedRoute } from "../../model/types";
import { DeleteConfirmDialog } from "./Modals/DeleteConfirmDialog";
import { ItemModal } from "./Modals/ItemModal";
import { ConfirmDialog } from "./Modals/ConfirmDialog";
import { FaCopy, FaEdit, FaEllipsisV, FaTrash } from "react-icons/all";
import { getDisplayName } from "./getDisplayName";
import { Flex } from "../Flex";

interface CollectionItemProps {
  index: number;
  obj: any;
  model: FormItem[];
  updateItem?: (obj: any) => void;
  createItem?: (obj: any) => void;
  deleteItem?: (obj: any, index: number) => void;
  deployItem?: (obj: any) => void;
  page: keyof CrudKeys;
  selected: string[];
  setSelected: (array: string[]) => void;
  parentOnChange?: (update: { [id: string]: any }) => any;
  useModal?: boolean;
  parentObject?: any;
  nestedRouteArray?: INestedRoute[];
  onModalOpen?: (formValue: { [id: string]: any }) => void;
  onModalClose?: (formValue: { [id: string]: any }) => void;
  endpoint?: Endpoint;
  nestedCrud?: boolean;
  displayNameTemplate?: string;
  currentGameId?: GameId;
  id?: string;
  validObj?: { [id: string]: any };
  draggable?: boolean;
  hideCheckbox?: boolean;
  notInOrder?: boolean;
  isSingleton?: boolean;
  startAdornment?: () => JSX.Element | null;
  extraMenuItems?: (
    handleClose: (event: MouseEvent) => void
  ) => (JSX.Element | null)[];
  actionButtons?: () => JSX.Element;
  hideDustbin?: boolean;
  getDisplayName?: (obj: any) => string;
}

const useStyles = makeStyles((theme: any) => {
  let background = lighten(theme.palette.background.paper, 0.1);
  let subBoxBackground = background;
  const themeKey = theme.palette.mode;

  if (themeKey === "light") {
    background = "#ffffff";
    subBoxBackground = "#dddddd";
  }

  const invalidColour = "rgba(212, 17, 17, 0.3)";
  return {
    box: (props: { invalid?: boolean; notInOrder?: boolean }) => ({
      background: props.invalid
        ? props.notInOrder
          ? alpha(invalidColour, 0.5)
          : invalidColour
        : props.notInOrder
        ? alpha(background, 0.5)
        : background,
      alignItems: "center",
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      borderRadius: "4px",
      minHeight: "50px",
      padding: "0.5rem 1rem 0.5rem 0.2rem",
      cursor: "pointer",
      transition: "background-color 0.1s ease-in",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      margin: "0.2rem 0",
      justifyContent: "flex-start",
      "&:hover": {
        background: props.invalid
          ? lighten(invalidColour, 0.15)
          : lighten(theme.palette.background.paper, 0.15),
      },
    }),
    subBox: {
      padding: "1rem",
      border: `1px solid ${subBoxBackground}`,
      borderTop: "none",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    grip: {
      cursor: "grab",
    },
  };
});

export const CollectionItemContext = React.createContext<{
  parentIndex?: number;
}>({});

export function CollectionItem({
  index,
  obj,
  model,
  updateItem,
  deleteItem,
  createItem,
  deployItem,
  page,
  setSelected,
  selected,
  parentOnChange,
  useModal,
  parentObject,
  nestedRouteArray,
  onModalOpen,
  onModalClose,
  endpoint,
  nestedCrud,
  displayNameTemplate,
  currentGameId,
  id,
  validObj,
  hideCheckbox,
  notInOrder,
  isSingleton,
  startAdornment,
  extraMenuItems,
  actionButtons,
  hideDustbin,
  getDisplayName: getDisplayNameProp,
}: CollectionItemProps) {
  const [isOpen, setIsOpen] = useState(!useModal);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [newObj, setObj] = useState(obj ?? {});
  const invalid = Object.keys(validObj ?? {}).length !== 0;
  const classes = useStyles({ invalid, notInOrder });

  const displayName = getDisplayNameProp
    ? getDisplayNameProp(newObj)
    : getDisplayName(model, obj, displayNameTemplate, page);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <CollectionItemContext.Provider value={{ parentIndex: index }}>
      <Flex id={id} className={classes.box} onClick={() => setIsOpen(!isOpen)}>
        <Flex justifyContent="space-between" width="100%" alignItems="center">
          <Flex alignItems="center">
            {startAdornment && startAdornment()}
            {!nestedCrud && (
              <>
                {!isSingleton && !hideCheckbox && (
                  <Checkbox
                    checked={selected.includes(obj.object_id)}
                    onClick={(event) => {
                      event.stopPropagation();
                      if (
                        obj?.name
                          ? !obj?.name.includes("DELETED")
                          : !obj?.code?.includes("DELETED")
                      ) {
                        if (selected.includes(obj.object_id)) {
                          const index = selected.findIndex(
                            (id) => id === obj.object_id
                          );
                          const newSelected = [...selected];
                          newSelected.splice(index, 1);
                          setSelected(newSelected);
                        } else {
                          if (!selected.includes(obj.object_id)) {
                            setSelected([...selected, obj.object_id]);
                          }
                        }
                      }
                    }}
                  />
                )}
              </>
            )}
            <Box ml={9}>
              <Typography>
                {obj.object_id} {obj.unique_id && `| ${obj.unique_id}`}
              </Typography>
              <Typography variant="h6">{displayName}</Typography>
            </Box>
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            {actionButtons && actionButtons()}
            {!isSingleton && (
              <IconButton id="demo-customized-button" onClick={handleClick}>
                <FaEllipsisV />
              </IconButton>
            )}
            <Menu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {extraMenuItems && extraMenuItems(handleClose)}
              <MenuItem
                onClick={(event: any) => {
                  setIsOpen(!isOpen);
                  handleClose(event);
                }}
                disableRipple
              >
                <FaEdit />
                Edit
              </MenuItem>
              <MenuItem
                disabled={nestedCrud}
                onClick={(event: any) => {
                  setDuplicateModalOpen(true);
                  handleClose(event);
                }}
                disableRipple
              >
                <FaCopy />
                Duplicate
              </MenuItem>

              {!hideDustbin && (
                <div>
                  <Divider />
                  <MenuItem
                    onClick={(event: any) => {
                      setDeleteIsOpen(true);
                      handleClose(event);
                    }}
                    disableRipple
                  >
                    <FaTrash />
                    Delete
                  </MenuItem>
                </div>
              )}
            </Menu>
            {!isSingleton && !hideDustbin && (
              <IconButton
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  setDeleteIsOpen(true);
                }}
                aria-label="delete"
                size="small"
                color="secondary"
              >
                <FaTrash />
              </IconButton>
            )}
          </Flex>
        </Flex>
      </Flex>
      {useModal ? (
        <ItemModal
          index={index}
          model={model}
          initialFormValue={obj}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          updateItem={updateItem}
          page={page}
          parentOnChange={parentOnChange}
          parentObject={parentObject}
          nestedRouteArray={nestedRouteArray}
          onModalOpen={onModalOpen}
          onModalClose={onModalClose}
          createItem={createItem}
        />
      ) : (
        <Collapse in={isOpen}>
          <Box className={classes.subBox}>
            <Box p={3}>
              <FormContent
                validObj={validObj}
                formValue={newObj}
                setFormValue={setObj}
                index={index}
                model={model}
                updateItem={updateItem}
                page={page}
                parentOnChange={parentOnChange}
                parentFormValue={parentObject}
              />
            </Box>
          </Box>
        </Collapse>
      )}

      <ConfirmDialog
        object={obj}
        page={page}
        isOpen={confirmIsOpen}
        onClose={() => setConfirmIsOpen(false)}
        deployItem={deployItem}
      />
      <DeleteConfirmDialog
        index={index}
        object={obj}
        title="Delete?"
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        deleteItem={deleteItem}
        page={page}
      />
      <DuplicateModal
        page={page}
        isOpen={duplicateModalOpen}
        onClose={() => setDuplicateModalOpen(false)}
        formValue={obj}
        baseModel={model}
        endpoint={endpoint}
        createItem={createItem}
        currentGameId={currentGameId}
      />
    </CollectionItemContext.Provider>
  );
}
