import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { getData } from "./request";

export function useClientConfig(suspense?: boolean) {
  const { data, error } = useSWR(Endpoints.CLIENT_CONFIG.url, getData, {
    suspense,
  });

  return {
    config: data?.config.settings as Record<string, any> | undefined,
    isLoading: !error && !data,
    isError: error,
  };
}
