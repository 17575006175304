import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { getData } from "./request";

export function useSolitaireAreaOrders() {
  const { data, error } = useSWR(Endpoints.SOLITAIRE_AREA_ORDERS.url, getData, {
    suspense: false,
  });

  return {
    areaOrders:
      (data &&
        (data as { [id: string]: any })[
          Endpoints.SOLITAIRE_AREA_ORDERS.object_path
        ]) ??
      [],
    isLoading: !error && !data,
    isError: error,
  };
}
