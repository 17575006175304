import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ValueType } from "../../model/versioned-settings.model";
import { useConfig } from "../../network/useConfig";
import AdminContainer from "./AdminContainer";
import { nanoid } from "@reduxjs/toolkit";
import { getConfigData, saveConfigData } from "./adminConfigSlice";

export const handleType = (value: any) => {
  if (Array.isArray(value)) {
    return ValueType.ARRAY;
  } else if (typeof value === ValueType.OBJECT && !Array.isArray(value)) {
    return ValueType.OBJECT;
  } else {
    return typeof value;
  }
};

export const AdminConfig = () => {
  const { config } = useConfig();
  const dispatch = useDispatch();
  const items = useSelector(getConfigData);

  const encodeFormatRecursively = useCallback((config: any): any => {
    if (
      handleType(config) === ValueType.BOOLEAN ||
      handleType(config) === ValueType.STRING ||
      handleType(config) === ValueType.NUMBER
    ) {
      return config;
    }
    return Object.entries(config ?? [])
      .map(([key, value]) => {
        return {
          key: key,
          val: {
            id: nanoid(10),
            __type__: handleType(value),
            __value__: encodeFormatRecursively(value),
          },
        };
      })
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.val }), {});
  }, []);

  useEffect(() => {
    const p = encodeFormatRecursively(config);

    dispatch(saveConfigData(p));
  }, [config, dispatch, encodeFormatRecursively]);

  return <AdminContainer data={items} />;
};
