import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVersionMap,
  useGetLocalisation,
  useGetVersionMap,
} from "../../network/useLocalisation";
import useDebounce from "../../utils/useDebounce";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { selectSession } from "../session/sessionSlice";

type VersionRecord = {
  min: string;
  max: string;
  version: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

export default function LocalisationModal({ open, onClose }: Props) {
  const dispatch = useDispatch();

  const { game_id } = useSelector(selectSession);

  const { localisations, isLoading } = useGetLocalisation();
  const { versionMap } = useGetVersionMap();

  const [editedVersionMap, setEditedVersionMap] = useState<VersionRecord[]>([]);

  const localisationVersions = Object.values(localisations ?? {}).sort(
    (a, b) => b.loc_version - a.loc_version
  );

  useEffect(() => {
    const newVersionMap: VersionRecord[] = [];
    for (const [key, value] of Object.entries(versionMap ?? {})) {
      const split = key.split(":");
      const min = split[0] ?? "";
      const max = split[1] ?? "";

      newVersionMap.push({
        min,
        max,
        version: value,
      });
    }

    setEditedVersionMap(newVersionMap);
  }, [versionMap, setEditedVersionMap]);

  const getLocalisationLabel = useCallback(
    (version: number) => {
      const v = localisationVersions.find(
        (item) => item.loc_version === version
      );
      if (!v) {
        return version.toString();
      }

      return `Ver. ${v.loc_version} - ${moment(v.created_on).format(
        "DD/MM/YYYY"
      )}`;
    },
    [localisationVersions]
  );

  const onSave = async () => {
    const newVersionMap = editedVersionMap.reduce((acc, item) => {
      let key = `${item.min}:${item.max}`;
      if (item.min === item.max) {
        key = item.min;
      }

      acc[key] = item.version;
      return acc;
    }, {} as Record<string, number>);

    try {
      await updateVersionMap(game_id!, newVersionMap);

      dispatch(
        queueNotification({
          message: "Localisation map saved successfully",
          options: {
            key: "localisation_map_save_success",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      onClose();
    } catch (e) {
      console.error(e);

      dispatch(
        queueNotification({
          message: "Failed to save localisation map, please try again...",
          options: {
            key: "localisation_map_save_fail",
            variant: SnackbarVariant.ERROR,
          },
        })
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Version Map</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ maxHeight: 440 }} component={Paper}>
          <Table stickyHeader style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "33%" }}>Min Version</TableCell>
                <TableCell sx={{ width: "33%" }}>Max Version</TableCell>
                <TableCell sx={{ width: "33%" }}>Localisation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editedVersionMap.map((version, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <VersionField
                        value={version.min}
                        onChange={(value) => {
                          setEditedVersionMap((prev) => {
                            const newVersionMap = [...prev];
                            newVersionMap[index].min = value;
                            return newVersionMap;
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <VersionField
                        value={version.max}
                        onChange={(value) => {
                          setEditedVersionMap((prev) => {
                            const newVersionMap = [...prev];
                            newVersionMap[index].max = value;
                            return newVersionMap;
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Autocomplete
                        fullWidth
                        disableClearable
                        size="small"
                        sx={{ flex: 1 }}
                        loading={isLoading}
                        options={localisationVersions.map(
                          (item) => item.loc_version
                        )}
                        value={version.version}
                        getOptionLabel={getLocalisationLabel}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" fullWidth />
                        )}
                        onChange={(event, newValue) => {
                          setEditedVersionMap((prev) => {
                            const newVersionMap = [...prev];
                            newVersionMap[index].version = newValue;
                            return newVersionMap;
                          });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEditedVersionMap((prev) => {
                        const newVersionMap = [...prev];
                        newVersionMap.push({
                          min: "",
                          max: "",
                          version: null as any,
                        });
                        return newVersionMap;
                      });
                    }}
                  >
                    Add
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type VersionFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

function VersionField({ value, onChange }: VersionFieldProps) {
  const [temp, setTemp] = useState(value);
  const debouncedValue = useDebounce(temp, 500);

  useEffect(() => {
    setTemp(value);
  }, [value]);

  const validateVersion = (version: string) => {
    if (!/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/.test(version)) {
      return "Invalid version";
    }

    return undefined;
  };

  const error = useMemo(
    () => validateVersion(debouncedValue),
    [debouncedValue]
  );

  const triggerChange = useCallback(() => {
    if (validateVersion(debouncedValue) !== undefined) {
      return;
    }

    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  useEffect(() => {
    triggerChange();
  }, [debouncedValue, triggerChange]);

  return (
    <TextField
      variant="standard"
      value={temp}
      onChange={(event) => setTemp(event.target.value)}
      onBlur={triggerChange}
      error={!!error}
      helperText={error}
    />
  );
}
