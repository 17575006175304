import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { FaTrash } from "react-icons/all";
import { useSelector } from "react-redux";
import {
  CollectionEditorOptions,
  Form,
  IExternalObjectForNesting,
} from "../../model/crud.model";
import { DatabaseObject } from "../../model/log";
import { GameId } from "../../model/sidebar.model";
import { Endpoint, INestedRoute } from "../../model/types";
import { createDefaultObject } from "../../utils/createDefaultObject";
import { equals } from "../../utils/equals";
import { flattenObjectToArray } from "../../utils/flattenObjectToArray";
import { CrudKeys, selectSearchField, selectSearchTerm } from "./crudSlice";
import { validateForm } from "./Fields/validateForm";
import { getDisplayName } from "./getDisplayName";
import { DeleteConfirmDialog } from "./Modals/DeleteConfirmDialog";
import { CollectionItem } from "./CollectionItem";
import { ItemModal } from "./Modals/ItemModal";
import { v4 } from "uuid";
import { updateItem, createItem, deleteItem, deleteItems } from "./crudService";
import { mutate } from "swr";
import { getEndpointData } from "../../utils/getEndpointData";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { Alert } from "@mui/material";
import { Flex } from "../Flex";

export interface CollectionEditorProps {
  response?: any;
  model?: any;
  endpoint?: Endpoint;
  onCreate?: (obj: any) => void;
  createMultipleItems?: (arr: any[]) => void;
  deleteMultipleItems?: (arr: any[]) => void;
  onUpdate?: (obj: any) => void;
  onDelete?: (obj: any, index: number) => void;
  deployItem?: (obj: any) => void;
  page: keyof CrudKeys;
  filterObjectIdArray?: string[];
  defaultValueOverrides?: { [id: string]: any };
  parentObject?: { [id: string]: any };
  collectionEditorOptions?: CollectionEditorOptions;
  collapsableParentObject?: { [id: string]: any };
  nestedModel?: boolean;
  parentOnChange?: (update: { [id: string]: any }) => void;
  parentField?: string;
  nestedCrud?: boolean;
  nestedRouteArray?: INestedRoute[];
  onModalOpen?: (formValue: { [id: string]: any }) => void;
  onModalClose?: (formValue: { [id: string]: any }) => void;
  keyOverrides?: IExternalObjectForNesting;
  displayNameTemplate?: string;
  currentGameId?: GameId;
  isError?: boolean;
  isLoading?: boolean;
  isSingleton?: boolean;
  hideDelete?: boolean;
  renderCreateOverride?: (onClick: () => void) => React.ReactNode;
  renderSearchBar?: () => React.ReactNode;
  getDisplayName?: (obj: any) => string;
}

export const searchFilter = (
  searchTerm: string,
  searchField: string,
  model: Form,
  displayNameTemplate?: string,
  obj?: any,
  page?: string,
  keyOverrides?: IExternalObjectForNesting
) => {
  let overrideElement = obj;
  if (searchTerm === "") {
    return true;
  }

  if (keyOverrides?.model.map((item) => item.name).includes(searchField)) {
    overrideElement = keyOverrides.data.find(
      (object) => object.object_id === obj[keyOverrides.fieldName]
    );
  }

  if (searchField === "display_name") {
    const displayName = getDisplayName(
      model,
      overrideElement,
      displayNameTemplate,
      page
    );
    return displayName
      .toString()
      .toLowerCase()
      .includes(searchTerm?.toLowerCase());
  }

  // Flatten object into array of fields
  let flattenedObject = flattenObjectToArray(overrideElement ?? {});

  // Filter array to just Search field and map to values
  flattenedObject = flattenedObject.filter(
    (item) => Object.keys(item)[0] === searchField
  );

  // If values contain the the search term then return
  if (
    flattenedObject
      .map((item) => item[searchField].toString().toLowerCase())
      .some((item) => item.includes(searchTerm.toLowerCase()))
  ) {
    return true;
  }
};

export const CollectionEditor = ({
  isError,
  isLoading,
  model,
  response,
  onCreate,
  onUpdate,
  onDelete,
  deployItem,
  page,
  filterObjectIdArray,
  defaultValueOverrides,
  parentObject,
  deleteMultipleItems,
  collectionEditorOptions,
  collapsableParentObject,
  nestedModel,
  parentOnChange,
  parentField,
  nestedRouteArray,
  onModalOpen,
  onModalClose,
  endpoint,
  keyOverrides,
  nestedCrud,
  displayNameTemplate,
  currentGameId,
  isSingleton,
  hideDelete,
  renderCreateOverride,
  renderSearchBar,
  getDisplayName,
}: CollectionEditorProps) => {
  // Get response from either endpoint or from parent object
  const data = response
    ? response
    : parentObject && parentObject[parentField as string];

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const hasParams = Object.keys(params).length !== 0;

  const [isOpen, setIsOpen] = useState(hasParams);
  //const [searchTerm, setSearchTerm] = useState("");
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);

  const [selected, setSelected] = useState<string[]>([]);

  const disabledByOptions =
    collectionEditorOptions?.disableAddButton &&
    collectionEditorOptions.disableAddButton(
      collapsableParentObject &&
        collapsableParentObject[collectionEditorOptions?.disabledValueKey ?? ""]
    );

  const parentObjectId = parentObject?.object_id;

  const searchTerm = useSelector(selectSearchTerm);
  const searchField = useSelector(selectSearchField);

  const filteredResponse: Partial<DatabaseObject>[] | undefined = data
    ?.filter((obj?: any) =>
      !nestedCrud
        ? searchFilter(
            searchTerm,
            searchField,
            model,
            displayNameTemplate,
            obj,
            page,
            keyOverrides
          )
        : true
    )
    ?.filter((obj?: any) =>
      obj?.name
        ? !obj?.name.includes("DELETED")
        : !obj?.code?.includes("DELETED")
    )
    ?.filter((obj: any) => {
      if (!filterObjectIdArray) {
        return true;
      } else {
        return filterObjectIdArray?.includes(obj.object_id);
      }
    });

  const filteredObjectIds = filteredResponse?.map((obj: any) => obj.object_id);

  const [idArray, setIdArray] = useState<string[]>(
    filteredResponse?.map(
      (item: Partial<DatabaseObject>) => item.object_id ?? v4()
    ) ?? []
  );

  useEffect(() => {
    const newSelected: string[] = [];
    // If selected element isn't visible remove from selected array
    selected.forEach((id) => {
      if (filteredObjectIds?.includes(id)) {
        newSelected.push(id);
      }
    });

    setSelected(newSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOnCreate = async (obj: any) => {
    try {
      let object: any = (await createItem(endpoint!, obj, false)) as any;
      if (isNullOrUndefined(object)) {
        return;
      }

      const { url, objectPath } = getEndpointData(endpoint!, page);

      mutate(
        url,
        async (prev: any) => {
          return {
            [objectPath]: [...((prev && prev[objectPath ?? ""]) ?? []), object],
          };
        },
        false
      );
    } catch (e) {
      console.error(e);
    }
  };

  const defaultOnUpdate = async (obj: any) => {
    try {
      delete obj.session_id;

      const object: any = (await updateItem(endpoint!, obj, false)) as any;
      if (isNullOrUndefined(object)) {
        return;
      }
      const newArray = [...response];
      const index = newArray.findIndex(
        (item) => item.object_id === obj.object_id
      );
      newArray[index] = object;
      const { url, objectPath } = getEndpointData(endpoint!, page);

      mutate(
        url,
        async (prev: any) => {
          return {
            [objectPath]: newArray,
          };
        },
        false
      );
    } catch (e) {
      console.error(e);
    }
  };

  const defaultOnDelete = async (obj: any) => {
    try {
      const success = await deleteItem(
        `${endpoint!.url}/${obj.object_id}/delete`
      );
      if (success !== false) {
        const newArray = [...response];

        const index = newArray.findIndex(
          (item) => item.object_id === obj.object_id
        );

        newArray.splice(index, 1);
        const { url, objectPath } = getEndpointData(endpoint!, page);

        mutate(
          url,
          async (prev: any) => {
            return {
              [objectPath]: newArray,
            };
          },
          false
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const defaultDeleteMultiple = async (arr: any[]) => {
    const newArr = arr.filter((obj) =>
      filteredObjectIds?.includes(obj.object_id)
    );

    try {
      await deleteItems(endpoint!, arr);

      // Remove from creatives array
      const newArray = [...response];
      for (const obj of newArr) {
        const index = newArray.findIndex(
          (item) => item.object_id === obj.object_id
        );
        newArray.splice(index, 1);
      }

      const { url, objectPath } = getEndpointData(endpoint!, page);

      mutate(
        url,
        async (prev: any) => {
          return {
            [objectPath]: newArray ?? [],
          };
        },
        false
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div>
        <Flex flexDirection="column" mb={5}>
          <Flex>
            {renderCreateOverride ? (
              <div>
                {renderCreateOverride(async () => {
                  if (nestedModel) {
                    const newId = v4();

                    const newObj = createDefaultObject(model);

                    // Create new object
                    onCreate && (await onCreate(newObj));

                    setIdArray([...(idArray ?? []), newId]);

                    // Scroll to new object
                    document
                      .getElementById(newId)
                      ?.scrollIntoView({ behavior: "smooth" });
                  } else {
                    setIsOpen(true);
                  }
                })}
              </div>
            ) : null}
            {page !== "xpromo_campaigns" && !isError && !renderCreateOverride && (
              <Button
                variant="outlined"
                disabled={
                  (page === "creatives" && !parentObjectId) || disabledByOptions
                }
                onClick={async () => {
                  if (nestedModel) {
                    const newId = v4();

                    const newObj = createDefaultObject(model);

                    // Create new object
                    onCreate && (await onCreate(newObj));

                    setIdArray([...(idArray ?? []), newId]);

                    // Scroll to new object
                    document
                      .getElementById(newId)
                      ?.scrollIntoView({ behavior: "smooth" });
                  } else {
                    setIsOpen(true);
                  }
                }}
              >
                Add
              </Button>
            )}
          </Flex>
          {(page === "cpitest" || page === "ctest") && disabledByOptions && (
            <Typography variant="h6">
              You must first publish this game before tests can be created
            </Typography>
          )}
        </Flex>

        {renderSearchBar && renderSearchBar()}

        <Flex m="0.4rem">
          {!nestedCrud && !hideDelete && (
            <Checkbox
              disabled={filteredObjectIds?.length === 0}
              checked={
                filteredObjectIds?.length !== 0 &&
                equals(filteredObjectIds, selected)
              }
              onChange={(event) => {
                if (!filteredObjectIds) {
                  return;
                }
                event.stopPropagation();
                if (equals(filteredObjectIds, selected)) {
                  setSelected([]);
                } else {
                  setSelected(filteredObjectIds);
                }
              }}
            />
          )}

          {selected.length !== 0 && (
            <Box alignSelf="center">
              <Button
                variant="contained"
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  setDeleteIsOpen(true);
                }}
                color="secondary"
                size="small"
                startIcon={<FaTrash />}
              >
                Delete selected
              </Button>
            </Box>
          )}
        </Flex>

        <Flex flexDirection="column" alignItems="stretch">
          {isLoading ? (
            <Flex justifyContent="center">
              <CircularProgress />
            </Flex>
          ) : isNullOrUndefined(filteredResponse) ||
            filteredResponse?.length === 0 ? (
            <Flex justifyContent="center">
              <small>No elements found</small>
            </Flex>
          ) : response?.error ? (
            <Alert severity="error">Error</Alert>
          ) : (
            // TODO sort this response - combine id array and response e.g. responseWithIds: Record<Id, Object>
            // Currently sorting this breaks the functionality of adding/removing or updating nested model items
            filteredResponse?.map((obj: any, index: number) => {
              const validObj = validateForm(obj, model);

              const objectId = obj?.object_id ?? idArray[index];

              return (
                <CollectionItem
                  validObj={validObj}
                  useModal={!nestedModel}
                  index={index}
                  updateItem={onUpdate ?? defaultOnUpdate}
                  deleteItem={(obj, index) => {
                    const newIdArray = [...idArray];
                    newIdArray.splice(index, 1);
                    //Remove from id array
                    setIdArray(newIdArray);
                    onDelete ? onDelete(obj, index) : defaultOnDelete(obj);
                  }}
                  deployItem={deployItem}
                  createItem={onCreate ?? defaultOnCreate}
                  model={model}
                  obj={obj}
                  key={objectId}
                  id={objectId}
                  page={page ?? ""}
                  selected={selected}
                  setSelected={setSelected}
                  parentOnChange={parentOnChange}
                  parentObject={parentObject}
                  nestedRouteArray={nestedRouteArray}
                  onModalOpen={onModalOpen}
                  onModalClose={onModalClose}
                  endpoint={endpoint}
                  nestedCrud={nestedCrud}
                  displayNameTemplate={displayNameTemplate}
                  currentGameId={currentGameId}
                  hideCheckbox={hideDelete}
                  hideDustbin={hideDelete}
                  getDisplayName={getDisplayName}
                />
              );
            })
          )}
        </Flex>
      </div>
      <ItemModal
        defaultValueOverrides={defaultValueOverrides}
        model={model}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isCreate={true}
        createItem={onCreate ?? defaultOnCreate}
        updateItem={onUpdate ?? defaultOnUpdate}
        page={page}
        parentOnChange={parentOnChange}
        nestedRouteArray={nestedRouteArray}
        initialFormValue={params}
        onModalOpen={onModalOpen}
        onModalClose={onModalClose}
      />
      <DeleteConfirmDialog
        title="Delete Multiple?"
        isOpen={deleteIsOpen}
        objects={response?.filter((object: { object_id: string }) =>
          selected.includes(object.object_id)
        )}
        onClose={() => setDeleteIsOpen(false)}
        deleteMultipleItems={deleteMultipleItems ?? defaultDeleteMultiple}
        clearSelected={() => setSelected([])}
        multidelete
        page={page}
      />
    </>
  );
};

export default CollectionEditor;
