import {
  Alert,
  Box,
  Button,
  CircularProgress as Spinner,
  List,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Flex } from "../../components/Flex";
import { AppStoreAccount } from "../../model/app-store.model";
import {
  GameApps,
  IGameApp,
  IronsourceBridges,
} from "../../model/game-app.model";
import { IGameEdition } from "../../model/game-edition.model";
import { TooltipState } from "../../model/versioned-settings.model";
import { getData, postData } from "../../network/request";
import { getGameID } from "../../utils/game-admin.util";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { AddNewGameEditionModal } from "./AddNewGameEditionModal";
import GameEdition from "./GameEdition/GameEdition";

export default function GameApp() {
  const location = useLocation();

  const dispatch = useDispatch();

  const [openGameEdition, setOpenGameEdition] = useState<string>();
  const [gameEditions, setGameEditions] = useState<IGameEdition[]>([]);

  const [isLoadingGameApps, setIsLoadingGameApps] = useState(true);
  const [gameApps, setGameApps] = useState<GameApps>([]);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [tooltip, setTooltip] = useState<TooltipState>();

  const [config, setConfig] = useState<Record<string, any>>();
  const [ironsourceBridges, setIronsouceBridges] =
    useState<IronsourceBridges>();
  const [appStoreAccounts, setAppStoreAccounts] = useState<AppStoreAccount[]>(
    []
  );

  const { gameId, errorGettingGameID } = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const gameId = getGameID(urlParams.get("game_id") as string);

    return {
      gameId,
      errorGettingGameID: !gameId,
    };
  }, [location.search]);

  // Fetch general data
  useEffect(() => {
    let isMounted = true;

    // AppStoreAccounts
    (async () => {
      let appStoreAccounts: AppStoreAccount[] = [];

      try {
        const res = await getData("/admin/app-store-account");
        appStoreAccounts = res.app_store_accounts.map((i: AppStoreAccount) => ({
          store_name: i.store_name,
          object_id: i.object_id,
          name: i.name,
          game_edition_id:
            i.game_edition_id === "google" ? "android" : i.game_edition_id,
        }));
      } catch (e) {
        console.error(e);
      }

      if (isMounted) {
        setAppStoreAccounts(appStoreAccounts);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  // Fetch game specific data
  useEffect(() => {
    let isMounted = true;

    // GameApps
    (async () => {
      let gameApps: GameApps = [];

      if (gameId) {
        setIsLoadingGameApps(true);

        try {
          const res = await getData("/admin/game-apps");
          gameApps = res.game_apps;
        } catch (e) {
          console.error(e);
        }
      }

      if (isMounted) {
        setIsLoadingGameApps(false);
        setGameApps(gameApps);
      }
    })();

    // IronsourceBridges
    (async () => {
      let ironsourceBridges: IronsourceBridges | undefined;

      if (gameId) {
        try {
          const res = await getData("/admin/ironsource-app-bridges");
          ironsourceBridges = res.ironsource_bridges;
        } catch (e) {
          console.error(e);
        }
      }

      if (isMounted) {
        setIronsouceBridges(ironsourceBridges);
      }
    })();

    // AdminConfig
    (async () => {
      let config: Record<string, any> | undefined;

      if (gameId) {
        try {
          const res = await getData("/admin/config");
          config = res.config;
        } catch (e) {
          console.error(e);
        }
      }

      if (isMounted) {
        setConfig(config);
      }
    })();

    // GameEditions
    (async () => {
      let gameEditions: IGameEdition[] = [];

      if (gameId) {
        try {
          const res = await getData("/admin/game-editions");
          gameEditions = res.game_editions;
        } catch (e) {
          console.error(e);
        }
      }

      if (isMounted) {
        setGameEditions(gameEditions);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [gameId]);

  const toggle = useCallback(
    (gameEditionId: string) => {
      let newOpenGameEdition: string | undefined;

      if (openGameEdition !== gameEditionId) {
        newOpenGameEdition = gameEditionId;
      }

      setOpenGameEdition(newOpenGameEdition);
    },
    [openGameEdition, setOpenGameEdition]
  );

  const addGameApp = useCallback(
    async (gameApp: IGameApp) => {
      if (!gameApp.game_id || !gameApp.game_edition_id) {
        dispatch(
          queueNotification({
            message: "Please fill out all required fields",
            options: {
              key: `empty_fields`,
              variant: SnackbarVariant.ERROR,
            },
          })
        );

        return;
      }

      const res = await postData(`admin/game-apps`, gameApp);

      setGameApps([...gameApps, res.game_app]);

      dispatch(
        queueNotification({
          message: "Game app successfully updated!",
          options: {
            key: `game_update`,
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    },
    [gameApps, setGameApps, dispatch]
  );

  const removeGameApp = useCallback(
    async (id: string) => {
      await postData(`admin/game-apps/${id}/delete`);

      setGameApps(gameApps.filter((i) => i.object_id !== id));

      dispatch(
        queueNotification({
          message: "Game app successfully deleted!",
          options: {
            key: `game_delete`,
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    },
    [gameApps, setGameApps, dispatch]
  );

  return (
    <Box mb={3}>
      <Flex display="flex" justifyContent="end" mt={2}>
        <Button variant="outlined" onClick={() => setAddModalOpen(true)}>
          Add Game Edition
        </Button>
      </Flex>
      <Flex display="flex" justifyContent="center" mt={3}>
        {!isLoadingGameApps && gameApps.length === 0 ? (
          <Flex display="flex" justifyContent="center" flexDirection="column">
            <Typography variant="h3">No Game Editions found</Typography>
            <Typography
              variant="h6"
              style={{ color: "#bbbbbb", fontSize: 12, marginTop: 4 }}
            >
              Add a game addition by clicking the button above
            </Typography>
          </Flex>
        ) : errorGettingGameID ? (
          <Flex display="flex" justifyContent="center">
            <Alert severity="error">Error getting user values</Alert>
          </Flex>
        ) : !isLoadingGameApps ? (
          <List style={{ width: "100%" }}>
            {gameApps.map((gameApp: IGameApp) => (
              <GameEdition
                key={gameApp.object_id}
                isOpen={openGameEdition === gameApp.game_edition_id}
                toggle={() => toggle(gameApp.game_edition_id)}
                gameApp={gameApp}
                setTooltip={setTooltip}
                removeGameApp={removeGameApp}
                ironsourceBridges={ironsourceBridges}
                config={config}
                appStoreAccounts={appStoreAccounts}
              />
            ))}
          </List>
        ) : (
          <Flex display="flex" flexDirection="row" justifyContent="center">
            <Spinner size={30} color="primary" />
          </Flex>
        )}

        <AddNewGameEditionModal
          isOpen={addModalOpen}
          addGameApp={addGameApp}
          appStoreAccounts={appStoreAccounts}
          title="Add New Game Edition"
          confirmColor="primary"
          confirmContent="Add"
          denyContent="Cancel"
          onResolve={() => setAddModalOpen(false)}
          onClose={() => setAddModalOpen(false)}
          gameId={gameId}
          ironsourceBridges={ironsourceBridges}
          gameEditions={gameEditions}
        />
      </Flex>
    </Box>
  );
}
