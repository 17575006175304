import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../app/store";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { DialogWrapper } from "../../../components/DialogWrapper";
import { FieldType } from "../../../model/crud.model";
import {
  ReactSelectArray,
  serverTimeFormat,
  ValueType,
  VersionedSettings,
  VersionedSettingss,
} from "../../../model/versioned-settings.model";
import { useConfig } from "../../../network/useConfig";
import { getGameID } from "../../../utils/game-admin.util";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";
import {
  countriesSelector,
  setDuplicateSettingsModal,
  settingsSelector,
} from "../addToAllSlice";
import { saveSelectedSettings } from "../addToAllThunks";

interface DeleteVersionedSettingsProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const InjectConversionTableModal = ({
  isOpen,
  onClose,
}: DeleteVersionedSettingsProps) => {
  const [newValues, setFormValue] = useState<{ ad_network?: string }>({});
  const [uploadedJSON, setUploadedJSON] = useState();

  const dispatch = useDispatch();

  const { config } = useConfig();
  const countries: ReactSelectArray = useSelector(countriesSelector) ?? [];
  const games = config?.games_available;
  const gameIds = Object.keys(games ?? {});
  const currentGameId = getGameID();
  const settings = useSelector(settingsSelector);

  const initialValue = {};
  const countryCodes = countries.map((countryObj) => countryObj.value);
  const countryCodeLookup: { [id: string]: string } = countries.reduce(
    (obj, item) => {
      return {
        ...obj,
        [item.value]: item.value,
      };
    },
    initialValue
  );

  countryCodeLookup["XX"] = "DEFAULT";

  const adNetworkOptions = { tenjin: "Tenjin", appsFlyer: "Appsflyer" };

  const model = [
    {
      display_name: "Ad Network",
      name: "ad_network",
      type: FieldType.SELECT,
      options: adNetworkOptions,
      required: true,
      defaultValue: "tenjin",
    },
  ];

  const validateJSON = (data?: { [id: string]: any }) => {
    if (isNullOrUndefined(data)) {
      return;
    }

    const validatedData: { [id: string]: any } = {};

    for (let gameId of Object.keys(data)) {
      const gameData = data[gameId];

      // If invalid gameId or not logged into this game
      if (!gameIds.includes(gameId) || gameId !== currentGameId) {
        store.dispatch(
          queueNotification({
            message: "Invalid game id in JSON",
            options: {
              key: "invalid_game_id",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }

      for (let gameEditionIdTuple of Object.keys(gameData)) {
        const gameEditionIdData = gameData[gameEditionIdTuple];
        const conversionMethod =
          gameEditionIdData.conversion_method ?? "revenue";
        const attributionWindow = gameEditionIdData.attribution_window;

        delete gameEditionIdData.conversion_method;
        delete gameEditionIdData.attribution_window;

        const keysToCountryCodes: { [id: string]: any } = {};
        const countryBlacklist: string[] = [];
        const countryTuples = Object.keys(gameEditionIdData);

        for (let countryTuple of countryTuples) {
          let tupleCountries = countryTuple
            .split(",")
            .map((code) => code.trim())
            .filter(
              (code) =>
                !["attribution_window", "conversion_method", "other"].includes(
                  code
                )
            );

          for (let countryCode of tupleCountries) {
            // If invalid country code
            if (!countryCodes.includes(countryCode)) {
              store.dispatch(
                queueNotification({
                  message: "Country not recognised",
                  options: {
                    key: "invalid_country",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            }

            if (countryBlacklist.includes(countryCode)) {
              store.dispatch(
                queueNotification({
                  message: "Country mentioned multiple times",
                  options: {
                    key: "multiple_times",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            }

            countryBlacklist.push(countryCode);

            keysToCountryCodes[countryTuple] = tupleCountries;
          }
        }

        if (gameEditionIdData.other) {
          keysToCountryCodes.other = countryCodes.filter(
            (countryCode) => !countryBlacklist.includes(countryCode)
          );
        }

        if (keysToCountryCodes) {
          const gameEditionIds = gameEditionIdTuple.split(",");

          // find settings objects that match gameEditions and game
          const gameVs = settings.filter(
            (setting: VersionedSettings) =>
              gameEditionIds.includes(setting.game_edition_id) &&
              setting.game_id === gameId
          );

          if (!gameVs) {
            store.dispatch(
              queueNotification({
                message:
                  "Default settings missing for game_id and game_edition_ids",
                options: {
                  key: "default_settings_missing",
                  variant: SnackbarVariant.ERROR,
                },
              })
            );
          }

          for (let gameEditionId of gameEditionIds) {
            for (let [key, newCountryCodes] of Object.entries(
              keysToCountryCodes
            )) {
              const countryData = gameEditionIdData[key];

              const interstitialValue = countryData.interstitial ?? 0;
              const rewardValue = countryData.reward ?? 0;

              const conversionTable = Object.values(
                countryData.conversion_table
              );

              for (let countryCode of newCountryCodes) {
                validatedData[gameId] = validatedData[gameId] ?? {};
                validatedData[gameId][gameEditionId] =
                  validatedData[gameId][gameEditionId] ?? {};
                validatedData[gameId][gameEditionId][countryCode] = {
                  ...(!isNullOrUndefined(attributionWindow) && {
                    attribution_window: attributionWindow,
                  }),
                  ...(!isNullOrUndefined(conversionMethod) && {
                    conversion_method: conversionMethod,
                  }),
                  ...(!isNullOrUndefined(interstitialValue) && {
                    interstitial_value: interstitialValue,
                  }),
                  ...(!isNullOrUndefined(rewardValue) && {
                    reward_value: rewardValue,
                  }),
                  ...(!isNullOrUndefined(conversionTable) && {
                    conversion_table: conversionTable,
                  }),
                };
              }
            }
          }
        }
      }
    }

    return validatedData;
  };

  const generateSettingsObject = (
    validatedData: {
      [id: string]: any;
    },
    gameId: string,
    gameEditionId: string,
    country: string
  ) => {
    const currentTime = moment().format(serverTimeFormat);

    const conversionTable = validatedData[gameId][gameEditionId][country][
      "conversion_table"
    ].map((val: string) => ({
      __description__: "",
      __modified__: currentTime,
      __tag__: "",
      __type__: "double",
      __user__: "oliver.pethick@kwalee.com",
      __value__: parseFloat(val),
      __valuehash__: "",
    }));

    const keyMap = {
      attribution_window: "attributionWindow",
      conversion_method: "conversionMethod",
      interstitial_value: "averageIntAdRevenue",
      reward_value: "averageRVAdRevenue",
      conversion_table: "conversionTable",
    };

    const attributionSettingsValue: { [id: string]: any } = {
      attributionWindow: {
        __description__: "",
        __modified__: currentTime,
        __tag__: "",
        __type__: "int",
        __user__: "oliver.pethick@kwalee.com",
        __value__: parseInt(
          validatedData[gameId][gameEditionId][country]["attribution_window"]
        ),
        __valuehash__: "",
      },
      conversionMethod: {
        __description__: "",
        __modified__: currentTime,
        __tag__: "",
        __type__: "string",
        __user__: "oliver.pethick@kwalee.com",
        __value__:
          validatedData[gameId][gameEditionId][country][
            "conversion_method"
          ].toString(),
        __valuehash__: "",
      },
      averageIntAdRevenue: {
        __description__: "",
        __modified__: currentTime,
        __tag__: "",
        __type__: "double",
        __user__: "oliver.pethick@kwalee.com",
        __value__: parseFloat(
          validatedData[gameId][gameEditionId][country]["interstitial_value"]
        ),
        __valuehash__: "",
      },
      averageRVAdRevenue: {
        __description__: "",
        __modified__: currentTime,
        __tag__: "",
        __type__: "double",
        __user__: "oliver.pethick@kwalee.com",
        __value__: parseFloat(
          validatedData[gameId][gameEditionId][country]["reward_value"]
        ),
        __valuehash__: "",
      },
      conversionTable: {
        __description__: "",
        __modified__: currentTime,
        __tag__: "",
        __type__: "array",
        __user__: "oliver.pethick@kwalee.com",
        __value__: conversionTable,
        __valuehash__: "",
      },
    };

    const newAttributionSettingsValue: { [id: string]: any } = {};

    Object.entries(validatedData[gameId][gameEditionId][country]).forEach(
      ([key, value]) => {
        if (!isNullOrUndefined(value)) {
          //@ts-ignore
          newAttributionSettingsValue[keyMap[key]] =
            //@ts-ignore
            attributionSettingsValue[keyMap[key]];
        }
      }
    );

    return newAttributionSettingsValue;
  };

  const applyDataToSettings = (
    validatedData: { [id: string]: any },
    key: string
  ) => {
    const clonedSettings = _.cloneDeep(settings);
    const settingsToUpdate: VersionedSettingss = [];
    for (let gameId in validatedData) {
      for (let gameEditionId in validatedData[gameId]) {
        for (let country in validatedData[gameId][gameEditionId]) {
          const currentTime = moment().format(serverTimeFormat);

          const attributionSettingsValue = generateSettingsObject(
            validatedData,
            gameId,
            gameEditionId,
            country
          );

          const settings = clonedSettings.filter(
            (settingObj: VersionedSettings) =>
              settingObj.game_edition_id === gameEditionId &&
              settingObj.country === country &&
              settingObj.game_id === gameId
          );

          settings.forEach((setting: VersionedSettings) => {
            if (setting) {
              const initialValue = setting.nested_settings[key];

              setting.nested_settings[key] = {
                __description__: "Auto-generated",
                __modified__: currentTime,
                __tag__: "ConversionTableInjection",
                __type__: ValueType.OBJECT,
                __user__: "oliver.pethick@kwalee.com",
                __value__: {
                  ...(typeof initialValue?.__value__ === "object" && {
                    ...initialValue?.__value__,
                  }),
                  ...attributionSettingsValue,
                },
                __valuehash__: "",
              };
              setting.cas_token = setting._cas;
              settingsToUpdate.push(setting);
            }
          });
        }
      }
    }

    dispatch(saveSelectedSettings({ selectedSettings: settingsToUpdate }));
  };

  const handleJSONUpload = (
    //@ts-ignore
    e: Event<HTMLInputElement>
  ) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target?.files[0], "UTF-8");
    fileReader.onload = (e) => {
      try {
        // @ts-ignore
        const json = JSON.parse(e.target.result);
        setUploadedJSON(json);
      } catch (e) {
        console.error(e);
        dispatch(
          queueNotification({
            message: "Invalid JSON file",
            options: {
              key: "create_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
    };
  };

  const allCountriesWithSettings = settings?.map(
    (setting: VersionedSettings) => setting.country
  );

  const settingsExistForAllCountries = countryCodes.every((code) =>
    allCountriesWithSettings?.includes(code)
  );

  return (
    <DialogWrapper isOpen={!!isOpen} onClose={() => onClose()} size="sm">
      <DialogTitle>Inject Conversion Table</DialogTitle>
      <DialogContent>
        <small>Upload a JSON below to inject</small>
        <br />
        <small>
          This will save settings on confirm, ensure all previous unsaved
          changes have been saved.
        </small>
        <FormContent
          isCreate
          formValue={newValues}
          model={model}
          page="game_settings"
          setFormValue={setFormValue}
        />
        <Box my={2}>
          <input type="file" onChange={(event) => handleJSONUpload(event)} />
        </Box>
        {!settingsExistForAllCountries && (
          <Alert severity="warning">
            <AlertTitle>Missing Settings</AlertTitle>
            Settings must exist for each country before conversion table can be
            injected.
            <Box mt={2}>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => dispatch(setDuplicateSettingsModal(true))}
              >
                Duplicate Settings
              </Button>
            </Box>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          className="ml-1"
          color="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          disabled={!settingsExistForAllCountries || !uploadedJSON}
          size="small"
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!isNullOrUndefined(uploadedJSON)) {
              //@ts-ignore
              const validatedData = validateJSON(uploadedJSON);
              if (validatedData) {
                onClose();
                applyDataToSettings(
                  validatedData,
                  `${newValues.ad_network}AttributionSettings`
                );
              }
            } else {
              store.dispatch(
                queueNotification({
                  message: "No JSON uploaded",
                  options: {
                    key: "no_json",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            }

            return;
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
