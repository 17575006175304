import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { VersionedSettings } from "../../../model/versioned-settings.model";
import { createSettingTitle } from "../../../utils/createSettingTitle";
import { countriesSelector, settingsSelector } from "../addToAllSlice";

type SelectedSettingsProps = {
  selectedSettingsIds: string[];
};

const SelectedSettingsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;

  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
  }
`;

const StyledSelectedSettingsContents = styled.div`
  background-color: ${(props) => props.theme.selectedSettings.backgroundColor};
  padding: 0 0 0.3rem 0.7rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  transition: background-color 0.1s ease-in-out;

  max-height: 100px;
  overflow: auto;

  span {
    font-size: 12px;
    line-height: 1;
    margin: 0;
    padding: 0;
    font-style: italic;
    color: ${(props) => props.theme.selectedSettings.textColor};
  }
`;

export default function SelectedSettings(props: SelectedSettingsProps) {
  const settings = useSelector(settingsSelector);
  const countries = useSelector(countriesSelector);

  return (
    <SelectedSettingsContainer>
      <small>Selected Settings</small>

      <StyledSelectedSettingsContents>
        <Box>
          {props.selectedSettingsIds?.sort().map((id: string) => (
            <React.Fragment key={id}>
              <span>
                {createSettingTitle(
                  settings?.find(
                    (setting: VersionedSettings) => setting.object_id === id
                  ),
                  countries
                )}
              </span>
              <br />
            </React.Fragment>
          ))}
        </Box>
      </StyledSelectedSettingsContents>
    </SelectedSettingsContainer>
  );
}
