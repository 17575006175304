import { Box, Button, CircularProgress, darken, useTheme } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FaSave } from "react-icons/all";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import {
  createItem,
  updateItem,
} from "../../../../../components/CollectionEditor/crudService";
import { FormContent } from "../../../../../components/CollectionEditor/FormContent";
import { ItemModal } from "../../../../../components/CollectionEditor/Modals/ItemModal";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal";
import { Flex } from "../../../../../components/Flex";
import Endpoints from "../../../../../constants/endpoints";
import { FieldType, Form } from "../../../../../model/crud.model";
import { ILevel, ILeverOrder } from "../../../../../model/level.model";
import { useGameEditions } from "../../../../../network/useGameEditions";
import { useLevelOrder } from "../../../../../network/useLevelOrder";
import { useLevels } from "../../../../../network/useLevels";
import { useSolitaireAreaOrders } from "../../../../../network/useSolitaireAreaOrders";
import { useSolitaireAreas } from "../../../../../network/useSolitaireAreas";
import { getEndpointData } from "../../../../../utils/getEndpointData";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import { reorder } from "../../../../../utils/reorder";
import { useEndpointAsOptions } from "../../../../../utils/useEndpointAsOptions";
import { DraggableLevel } from "../../../components/DraggableLevel";
import { saveLevelOrder } from "../../../levelOrderService";
import { getSplitLevelIds } from "../SolitaireLevels";
import { NotInLevelOrderLevels } from "../../../NotInLevelOrderLevels";
import { selectSession } from "../../../../session/sessionSlice";
import {
  CassolitairehfLevel,
  ISolitaireArea,
  ISolitaireAreaOrder,
} from "../types";
import { AreaModel } from "./areaModel";
import { saveAreaOrder } from "./areasService";

export const Areas = () => {
  const { game_id: currentGameId } = useSelector(selectSession);
  const page = `${currentGameId}_areas`;

  const [removedAreas, setRemovedAreas] = useState<string[]>([]);

  const { gameEditions } = useGameEditions();
  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const filtersModel: Form = [
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.MULTISELECT,
      selectOptions: Object.entries(gameEditionOptions).map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      }),
      chipSize: "small",
      selectSize: "small",
      valueFunction(obj?) {
        return filters.game_edition_id;
      },
      label: "Game Edition",
      limitTags: 2,
      placeholder: "Game Editions",
      id: "game_editions",
      disabled: false,
      needGrouping: false,
    },
  ];

  const [filters, setFilters] = useState<{
    [id: string]: any;
  }>({
    game_edition_id: ["ios", "android"],
    category_id: "default",
  });

  const { areas: areasContent, isLoading: isLoadingAreas } =
    useSolitaireAreas();

  const areas: any = useMemo(
    () =>
      areasContent.map((item: any) => ({
        ...item,
        undo_cost_modal: {
          undo_cost: item.undo_cost,
        },
        diy_cost_modal: {
          diy_cost: item.diy_cost,
        },
        drill_cost_modal: {
          drill_cost: item.drill_cost,
        },
        buy_card_cost_modal: {
          buy_card_cost: item.buy_card_cost,
        },
        reward_settings_modal: {
          general_coin_award: item.general_coin_award,
          coin_award_increase: item.coin_award_increase,
          min_level_reward: item.min_level_reward,
          max_level_reward: item.max_level_reward,
          level_reward_rounder: item.level_reward_rounder,
          card_complete_reward: item.card_complete_reward,
        },
      })),
    [areasContent]
  );

  const { areaOrders, isLoading: isLoadingAreaOrders } =
    useSolitaireAreaOrders();

  const theme = useTheme();

  const { levels } = useLevels<CassolitairehfLevel>();
  const { levelOrders } = useLevelOrder();

  const currentLevelOrder: ILeverOrder[] | undefined = useMemo(() => {
    return levelOrders?.filter(
      (order: ILeverOrder) =>
        filters.game_edition_id.includes(order.game_edition_id) &&
        order.category_id === filters.category_id
    );
  }, [levelOrders, filters]);

  const initialOrderedLevelIds: string[] = useMemo(
    () => [
      ...new Set(
        currentLevelOrder.map((item) => item?.ordered_level_ids).flat()
      ),
    ],
    [currentLevelOrder]
  );

  const [orderedLevelIds, setOrderedLevelIds] = useState(
    initialOrderedLevelIds
  );

  const areaClientIds: string[] | undefined = areaOrders.find(
    (areaOrder: ISolitaireAreaOrder) =>
      areaOrder.category_id === filters.category_id &&
      filters.game_edition_id.includes(areaOrder.game_edition_id)
  )?.ordered_area_ids;

  useEffect(() => {
    const newOrderedLevelIds = initialOrderedLevelIds ?? [];
    setOrderedLevelIds(newOrderedLevelIds);
  }, [areaClientIds, initialOrderedLevelIds, levels]);

  const { splitOrderedLevelIds } = getSplitLevelIds(
    levels,
    orderedLevelIds,
    areaClientIds
  );

  const [approveModalOpen, setApproveModalOpen] = useState(false);

  const currentAreaOrder: ISolitaireAreaOrder[] | undefined = useMemo(() => {
    return areaOrders?.filter(
      (order: ISolitaireAreaOrder) =>
        filters.game_edition_id.includes(order.game_edition_id) &&
        order.category_id === filters.category_id
    );
  }, [filters, areaOrders]);

  const initialOrderedAreaIds: string[] = useMemo(
    () => [
      ...new Set(
        currentAreaOrder
          ?.map((item: ISolitaireAreaOrder) => item?.ordered_area_ids)
          .flat()
      ),
    ],
    [currentAreaOrder]
  );

  const [orderedAreaIds, setOrderedAreaIds] = useState(initialOrderedAreaIds);

  useEffect(() => {
    setOrderedAreaIds(initialOrderedAreaIds ?? []);
  }, [initialOrderedAreaIds]);

  const allAreaIds = areas.map((area: ISolitaireArea) => area.object_id);

  const unaddedAreaIds = allAreaIds.filter(
    (id: string) => !orderedAreaIds?.includes(id)
  );

  const addLevelIds = (ids: string[]) => {
    let newIds = [...(orderedAreaIds ?? [])];
    newIds = newIds.concat(...ids);

    setOrderedAreaIds(newIds);
    const newRemovedAreas = [...removedAreas];
    ids.forEach((id) => {
      if (removedAreas.includes(id)) {
        const index = newRemovedAreas.findIndex(
          (removedId) => removedId === id
        );
        newRemovedAreas.splice(index, 1);
      }
    });

    setRemovedAreas(newRemovedAreas);
  };

  const removeLevelId = (index: number) => {
    let newIds = [...(orderedAreaIds ?? [])];
    newIds.splice(index, 1);

    setOrderedAreaIds(newIds);
  };

  const removeFromLevelOrder = (areaId: string) => {
    const index = orderedAreaIds?.findIndex((id: string) => id === areaId);

    if (!isNullOrUndefined(index)) {
      removeLevelId(index);
      const newRemovedAreas = [...removedAreas];
      if (!removedAreas.includes(areaId)) {
        // Add to removedAreas array
        newRemovedAreas.push(areaId);
      }

      setRemovedAreas(newRemovedAreas);
    }
  };

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrderedAreaIds = reorder(
      orderedAreaIds ?? [],
      result.source.index,
      result.destination.index
    );

    setOrderedAreaIds(newOrderedAreaIds);
  }

  const areaModel = AreaModel();
  const [isNewOpen, setIsNewOpen] = useState(false);

  const onSave = () => {
    // Update Area Order
    saveAreaOrder(currentAreaOrder, orderedAreaIds, filters, areaOrders);

    // Generate new level order ids from split level ids and area order
    const newOrderedLevelIds: string[] = [];

    orderedAreaIds?.forEach((areaId) => {
      splitOrderedLevelIds[areaId].forEach((levelId) => {
        newOrderedLevelIds.push(levelId);
      });
    });

    //Update and save level orders object
    saveLevelOrder(currentLevelOrder, newOrderedLevelIds, filters, levelOrders);
    setRemovedAreas([]);
  };

  const isLoading = isLoadingAreas || isLoadingAreaOrders;

  return (
    <>
      <FormContent
        formValue={filters}
        model={filtersModel}
        page="level_order"
        setFormValue={setFilters}
        incorporateLabels
        inline
      />
      <Box mt={4} display="flex" justifyContent="space-between">
        <Button
          variant="outlined"
          onClick={async () => {
            setIsNewOpen(true);
          }}
          disabled={isLoading}
        >
          Add Area
        </Button>

        <Button
          startIcon={<FaSave />}
          onClick={() => {
            // If areas have been removed that actively contain levels
            const activeAreas = removedAreas.filter(
              (areaId) => splitOrderedLevelIds[areaId].length !== 0
            );
            const removedActiveAreas = activeAreas.length !== 0;

            if (removedActiveAreas) {
              // Output warning modal that all levels will be removed from the level order
              setApproveModalOpen(true);
            } else {
              onSave();
            }
          }}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Save Area Order
        </Button>
      </Box>
      {!isLoading ? (
        <Box
          bgcolor={darken(theme.palette.background.paper, 0.2)}
          p={2}
          borderRadius={4}
          mt={5}
          mb={3}
        >
          <DragDropContext
            onDragEnd={(result, provided) => onDragEnd(result, provided)}
          >
            <Droppable droppableId="area-orders">
              {(topDropProvided) => (
                <div
                  ref={topDropProvided.innerRef}
                  {...topDropProvided.droppableProps}
                >
                  {orderedAreaIds?.map((id: string, index: number) => {
                    const area = areas?.find(
                      (level: ILevel) => level.object_id === id
                    );
                    return (
                      <DraggableLevel
                        modelOverride={areaModel}
                        key={`${id}-${index}`}
                        id={`${id}-${index}`}
                        level={area}
                        index={index}
                        addToLevelOrder={addLevelIds}
                        removeFromLevelOrder={removeFromLevelOrder}
                        levels={areas}
                        orderedLevelIds={orderedAreaIds}
                        saveLevelOrder={(areaIds) =>
                          saveAreaOrder(
                            currentAreaOrder,
                            areaIds,
                            filters,
                            areaOrders
                          )
                        }
                        page={page}
                        endpoint={Endpoints.SOLITAIRE_AREAS}
                      />
                    );
                  })}

                  {topDropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {orderedAreaIds?.length === 0 && (
            <Alert severity="info">No areas in order</Alert>
          )}
          <DragDropContext
            onDragEnd={(result, provided) => onDragEnd(result, provided)}
          >
            <Droppable droppableId="not-in-area-levels">
              {(topDropProvided) => (
                <div
                  ref={topDropProvided.innerRef}
                  {...topDropProvided.droppableProps}
                >
                  {unaddedAreaIds.length !== 0 && (
                    <NotInLevelOrderLevels
                      unaddedLevelIds={unaddedAreaIds}
                      levelIds={allAreaIds}
                      levels={areas}
                      modelOverride={areaModel}
                      addToLevelOrder={addLevelIds}
                      saveLevelOrder={(areaIds: string[]) =>
                        saveAreaOrder(
                          currentAreaOrder,
                          areaIds,
                          filters,
                          areaOrders
                        )
                      }
                      orderedLevelIds={orderedAreaIds}
                      page={page}
                      endpoint={Endpoints.SOLITAIRE_AREAS}
                    />
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      ) : (
        <Flex width="100%" m={2} p={1} justifyContent="center">
          <CircularProgress />
        </Flex>
      )}

      <ConfirmationModal
        isOpen={approveModalOpen}
        title="Are you sure?"
        confirmContent="Remove"
        denyContent="Cancel"
        page="areas"
        onClose={() => setApproveModalOpen(false)}
        onResolve={(resolved: boolean) => {
          if (resolved) {
            onSave();

            setApproveModalOpen(false);
          } else {
            setApproveModalOpen(false);
          }
        }}
      >
        <p>
          Removing this from the level order will remove all levels in this area
          from the level order. Are you sure?
        </p>
      </ConfirmationModal>
      {isNewOpen && (
        <ItemModal
          endpoint={Endpoints.SOLITAIRE_AREAS}
          defaultValueOverrides={{
            category_id: "default",
            level_type: "default",
          }}
          model={areaModel}
          isOpen={isNewOpen}
          onClose={() => setIsNewOpen(false)}
          isCreate={true}
          createItem={async (obj: any) => {
            const flattenObj: any = (obj: any) =>
              Object.keys(obj).reduce(
                (acc, cur) =>
                  typeof obj[cur] === "object"
                    ? { ...acc, ...flattenObj(obj[cur]) }
                    : { ...acc, [cur]: obj[cur] },
                {}
              );
            try {
              let object: any = (await createItem(
                Endpoints.SOLITAIRE_AREAS,
                flattenObj(obj),
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }

              const { url, objectPath } = getEndpointData(
                Endpoints.SOLITAIRE_AREAS,
                page,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: [
                      ...((prev && prev[objectPath ?? ""]) ?? []),
                      object,
                    ],
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          updateItem={async (obj: any) => {
            try {
              const object: any = (await updateItem(
                Endpoints.SOLITAIRE_AREAS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }
              const newArray = [...areas];
              const index = newArray.findIndex(
                (item) => item.object_id === obj.object_id
              );
              newArray[index] = object;
              const { url, objectPath } = getEndpointData(
                Endpoints.SOLITAIRE_AREAS,
                page,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: newArray,
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          //@ts-ignore
          page={page}
        />
      )}
    </>
  );
};
