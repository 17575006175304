import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  CrudKeys,
  selectIsSaving,
} from "../../../components/CollectionEditor/crudSlice";
import { AutoModalCloser } from "../../../components/CollectionEditor/Modals/AutoModalCloser";

interface DeleteConfirmDialogProps {
  page: keyof CrudKeys;
  isOpen: boolean;
  onClose: () => void;
  stopCampaign: () => void;
  formValue: any;
}

export const getPageName = (pageName: string): keyof CrudKeys => {
  if (pageName === "ctest" || pageName === "cpitest") {
    return "campaigns";
  } else {
    return pageName as keyof CrudKeys;
  }
};

export const StopCampaignDialog = ({
  isOpen,
  onClose,
  formValue,
  stopCampaign,
  page,
}: DeleteConfirmDialogProps) => {
  const isSaving = useSelector(selectIsSaving);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <AutoModalCloser onClose={onClose} page={page} />
      <DialogTitle>Stop Campaign?</DialogTitle>

      <DialogContent>
        Are you sure? You can't undo this action afterwards.
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaving[getPageName(page)]}
          onClick={() => {
            stopCampaign();
          }}
        >
          Stop
        </Button>
      </DialogActions>
    </Dialog>
  );
};
