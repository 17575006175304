import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IWeightedConfig } from "./JanusSetting";

interface Props {
  index: number;
  weightedConfig: IWeightedConfig;
  updateWeightedConfig: (
    index: number,
    updates: Partial<IWeightedConfig>
  ) => void;
  removeWeightedConfig: (index: number) => void;
}

export const columnSizes = [3, 9];

export const WeightedConfig = ({
  index,
  weightedConfig,
  updateWeightedConfig,
  removeWeightedConfig,
}: Props) => {
  const [tempValue, setTempValue] = useState<IWeightedConfig>(weightedConfig);

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newWeight = parseFloat(event.target.value);
    setTempValue({ ...tempValue, weight: isNaN(newWeight) ? null : newWeight });
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setTempValue({ ...tempValue, value: isNaN(newValue) ? null : newValue });
  };

  const handleBlur = () => {
    updateWeightedConfig(index, tempValue);
  };

  return (
    <Box
      sx={{
        padding: "2rem 1.5rem",
        margin: "1rem 0",
        position: "relative",
        borderRadius: 4,
      }}
    >
      <IconButton
        onClick={() => removeWeightedConfig(index)}
        size="small"
        sx={{
          position: "absolute",
          top: "-10px",
          right: "10px",
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={columnSizes[0]}>
          <Typography variant="body1">Weight</Typography>
        </Grid>
        <Grid item xs={12} sm={columnSizes[1]}>
          <FormControl fullWidth>
            <TextField
              type="number"
              placeholder="Enter Weight..."
              value={tempValue.weight ?? ""}
              onBlur={handleBlur}
              onChange={handleWeightChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={columnSizes[0]}>
          <Typography variant="body1">Value</Typography>
        </Grid>
        <Grid item xs={12} sm={columnSizes[1]}>
          <FormControl fullWidth>
            <TextField
              type="number"
              placeholder="Enter Value..."
              value={tempValue.value || 0}
              onBlur={handleBlur}
              onChange={handleValueChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
