import { deepClone } from "../components/ExpandableDiffSetting/ExpandableDiffSetting";
import { NestedSetting } from "../model/versioned-settings.model";
import { checkIfCollection } from "./checkIfCollection";

export function removeDescAndTag(setting: NestedSetting) {
  let clonedSetting = deepClone(setting);

  const newSetting = {
    ...clonedSetting,
    __tag__: "",
    __description__: "",
  };

  // If setting type is collection then recursively run
  if (checkIfCollection(newSetting)) {
    Object.keys(newSetting.__value__).forEach(
      (key) =>
        //@ts-ignore
        (newSetting.__value__[key] = removeDescAndTag(
          //@ts-ignore
          newSetting.__value__![key]
        ))
    );
  }

  return newSetting;
}
