import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  CountryConfig,
  SwitcherObject,
  VersionedSettings,
} from "../../../model/versioned-settings.model";
import { useReactSelectGameEditions } from "../../../utils/useReactSelectGameEditions";
import {
  configSelector,
  countriesSelector,
  isDisabledSelector,
  setIdsToOverrideFilters,
  settingsSelector,
  updateExpandedSettings,
} from "../addToAllSlice";
import {
  selectVersionOptions,
  switcherSelector,
  updateDropdown,
  updateSwitcher,
} from "./Filter/filterSlice";

const StyledSwitcher = styled.div`
  overflow: auto;
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  height: 60px;
`;

const StyledOption = styled.span`
  margin: 0.5rem;
  font-weight: 300;
  font-size: 0.9em;
  padding: 0.2rem;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    ${(props: any) =>
      `background-color: ${props.theme.lightTextColor ?? "#d2d2d2"};`}
  }

  ${(props: any) => props.selected && `font-weight: 600; font-size: 1.5em;`}
`;

const Switcher = (props: any) => {
  const dispatch = useDispatch();
  const countries = useSelector(countriesSelector);
  const config = useSelector(configSelector);
  const versions = useSelector(selectVersionOptions);
  const settings = useSelector(settingsSelector);

  const platforms = useReactSelectGameEditions();

  const switcherOptions: SwitcherObject[] = props.switcherOptions
    ? props.switcherOptions
    : config?.settings.game_settings_quick_switcher;

  const switcherOrder = ["All Latest", "All iOS", "All Android"];
  const sortedOptions = switcherOptions
    ?.slice()
    .sort(
      (a, b) => switcherOrder.indexOf(b.name) - switcherOrder.indexOf(a.name)
    );

  const possibleCountryOptions = countries?.filter((country: { value: any }) =>
    settings
      ?.map((setting: VersionedSettings) => setting.country)
      .includes(country.value)
  );

  return (
    <StyledSwitcher>
      {versions?.length !== 0 &&
        sortedOptions &&
        sortedOptions.map((option) => (
          <StyledOption
            onClick={() => {
              if (!props.disabled) {
                props.updateSwitcher(option.name);
                dispatch(updateExpandedSettings(undefined));
                if (option.versioned_settings_id_filters.length === 0) {
                  dispatch(setIdsToOverrideFilters([]));

                  // Set platforms
                  dispatch(
                    updateDropdown({
                      dropdown: "platforms",
                      selected:
                        option.game_edition_id_filters.length === 0
                          ? platforms
                          : platforms.filter((platform: any) =>
                              option.game_edition_id_filters.includes(
                                platform.value
                              )
                            ),
                    })
                  );

                  // Extract countries from country groups
                  const countryGroups = config.settings.country_groups.groups;
                  const allCountries: string[] = [];
                  option.country_group_filters.forEach((groupKey) =>
                    countryGroups[groupKey].forEach((country: string) =>
                      allCountries.push(country)
                    )
                  );

                  // Concatenate with countries
                  const combinedCountries = allCountries.concat(
                    option.country_filters
                  );

                  // Set countries
                  dispatch(
                    updateDropdown({
                      dropdown: "countries",
                      selected:
                        option.country_filters.length === 0 &&
                        option.country_group_filters.length === 0
                          ? possibleCountryOptions
                          : countries.filter((country: CountryConfig) =>
                              combinedCountries.includes(country.value)
                            ),
                    })
                  );

                  // Set versions
                  dispatch(
                    updateDropdown({
                      dropdown: "versions",
                      selected:
                        option.game_edition_version_filters.length === 0
                          ? versions
                          : versions.filter((version: any) =>
                              option.game_edition_version_filters.includes(
                                version.value
                              )
                            ),
                    })
                  );
                } else {
                  // Select only those specific setting ids
                  dispatch(
                    setIdsToOverrideFilters(
                      option.versioned_settings_id_filters
                    )
                  );
                  dispatch(
                    updateDropdown({
                      dropdown: "platforms",
                      selected: [],
                    })
                  );
                  dispatch(
                    updateDropdown({
                      dropdown: "countries",
                      selected: [],
                    })
                  );
                  dispatch(
                    updateDropdown({
                      dropdown: "versions",
                      selected: [],
                    })
                  );
                }
              }
            }}
            //@ts-ignore
            selected={props.switcher === option.name}
            key={option.name}
          >
            {option.name}
          </StyledOption>
        ))}
    </StyledSwitcher>
  );
};

const mapStateToProps = (state: any) => ({
  switcher: switcherSelector(state.filterReducer),
  disabled: isDisabledSelector(state.addToAllReducer),
});

const mapDispatchToProps = { updateSwitcher };

export default connect(mapStateToProps, mapDispatchToProps)(Switcher);
