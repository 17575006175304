import {
  lighten,
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaGripVertical } from "react-icons/all";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import { CollectionItem } from "../../../components/CollectionEditor/CollectionItem";
import {
  createItem,
  deleteItem,
  updateItem,
} from "../../../components/CollectionEditor/crudService";
import { Form } from "../../../model/crud.model";
import { ILevel } from "../../../model/level.model";
import { Endpoint } from "../../../model/types";
import { getEndpointData } from "../../../utils/getEndpointData";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { useGameModel } from "../hooks/useGameModel";
import { selectSession } from "../../session/sessionSlice";

interface DraggableLevelProps {
  /* The game level to be passed to CollectionItem */
  level: ILevel;
  index: number;
  id: string;
  levels: ILevel[];
  notInOrder?: boolean;
  addToLevelOrder: (
    ids: string[],
    versionId?: string,
    location?: number,
    idFilter?: (id: string, currentIndex?: number) => boolean
  ) => void;
  removeFromLevelOrder?: (levelId: string, versionId?: string) => void;
  globalIndex?: number;
  modelOverride?: Form;
  orderedLevelIds?: string[];
  saveLevelOrder: (newLevelIds: string[]) => void;
  page: string;
  endpoint: Endpoint;
  selected?: string[];
  setSelected?: (array: string[]) => void;
  isDragDisabled?: boolean;
  deleteOverride?: (obj: any, index: number) => void;
  versionId?: string;
}

const useStyles = makeStyles((theme: any) => {
  return {
    grip: {
      cursor: "grab",
      padding: "1rem 1rem",
      borderRadius: 4,
      transition: "background-color .1s ease-in-out",
      "&:hover": {
        backgroundColor: lighten(theme.palette.background.paper, 0.3),
      },
    },
  };
});

/**
 * A level collection item that is draggable
 * @returns Returner
 */
export const DraggableLevel = ({
  id,
  level,
  index,
  levels,
  notInOrder,
  addToLevelOrder,
  removeFromLevelOrder,
  globalIndex,
  modelOverride,
  orderedLevelIds,
  saveLevelOrder,
  page,
  endpoint,
  setSelected,
  selected,
  isDragDisabled,
  deleteOverride,
  versionId,
}: DraggableLevelProps) => {
  const gameLevelModel = useGameModel();
  const modelToUse = modelOverride ? modelOverride : gameLevelModel;
  const { game_id: currentGameId } = useSelector(selectSession);
  const url = endpoint.url.replace("{game_id}", currentGameId ?? "");
  const newEndpoint = { ...endpoint, url };
  const classes = useStyles();

  const isSolitaire = useMemo(
    () => currentGameId === "cassolitairehf",
    [currentGameId]
  );

  const [location, setLocation] = useState<number | undefined>();
  const [dupeLocation, setDupeLocation] = useState<number | undefined>();

  const moveLevel = () =>
    addToLevelOrder(
      [level.object_id],
      versionId,
      location,
      (id: string, currentIndex?: number) => {
        // If moving the level, filter out the level at the current index
        return !(!notInOrder && index === currentIndex);
      }
    );

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <CollectionItem
            hideCheckbox={!isSolitaire}
            startAdornment={() => (
              <>
                <IconButton
                  size="small"
                  disabled={isDragDisabled}
                  className={classes.grip}
                  {...provided.dragHandleProps}
                >
                  <FaGripVertical />
                </IconButton>
                {!notInOrder && (
                  <Box ml="1rem" mr="0.5rem" width="50px">
                    <small>
                      {!isNullOrUndefined(globalIndex)
                        ? `${globalIndex + 1}. (${index + 1}.)`
                        : `${index + 1}.`}
                    </small>
                  </Box>
                )}
              </>
            )}
            actionButtons={() => (
              <>
                {notInOrder && addToLevelOrder && (
                  <Box mr="1rem">
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        addToLevelOrder([level.object_id], versionId);
                      }}
                    >
                      Add to Order
                    </Button>
                  </Box>
                )}
                {!notInOrder && removeFromLevelOrder && (
                  <Box mr="1rem">
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromLevelOrder(level.object_id, versionId);
                      }}
                    >
                      Remove from Order
                    </Button>
                  </Box>
                )}
              </>
            )}
            extraMenuItems={(handleClose) => [
              <MenuItem
                disabled={isSolitaire}
                key="insert-level"
                onClick={(event: any) => {
                  moveLevel();
                  handleClose(event);
                }}
                disableRipple
              >
                {notInOrder ? "Insert at" : "Move to"}{" "}
                <TextField
                  disabled={isSolitaire}
                  onClick={(e) => e.stopPropagation()}
                  placeholder="Location"
                  sx={{ marginLeft: 2, maxWidth: 100 }}
                  inputProps={{ min: 1 }}
                  type="number"
                  size="small"
                  value={location ?? ""}
                  onChange={(e) => setLocation(parseInt(e.target.value))}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      moveLevel();
                    }
                  }}
                />
              </MenuItem>,
              !notInOrder ? (
                <MenuItem
                  disabled={isSolitaire}
                  key="duplicate-in-order"
                  onClick={(event: any) => {
                    addToLevelOrder([level.object_id], versionId, index + 1);
                    handleClose(event);
                  }}
                  disableRipple
                >
                  Duplicate ref in order
                </MenuItem>
              ) : null,
              !notInOrder ? (
                <MenuItem
                  disabled={isSolitaire}
                  key="duplicate-level-at-location"
                  onClick={(event: any) => {
                    addToLevelOrder([level.object_id], versionId, dupeLocation);
                    handleClose(event);
                  }}
                  disableRipple
                >
                  Duplicate ref to
                  <TextField
                    disabled={isSolitaire}
                    onClick={(e) => e.stopPropagation()}
                    placeholder="Location"
                    sx={{ marginLeft: 2, maxWidth: 100 }}
                    inputProps={{ min: 1 }}
                    type="number"
                    size="small"
                    value={dupeLocation ?? ""}
                    onChange={(e) => setDupeLocation(parseInt(e.target.value))}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        addToLevelOrder(
                          [level.object_id],
                          versionId,
                          dupeLocation
                        );
                      }
                    }}
                  />
                </MenuItem>
              ) : null,
            ]}
            globalIndex={globalIndex}
            removeFromLevelOrder={removeFromLevelOrder}
            notInOrder={notInOrder}
            isLevelOrder
            draggable
            useModal
            obj={level ?? {}}
            model={modelToUse}
            //@ts-ignore
            page={page}
            selected={selected ?? []}
            setSelected={setSelected || (() => null)}
            index={index}
            deleteItem={
              deleteOverride
                ? deleteOverride
                : async (obj: any) => {
                    try {
                      // Remove from level order
                      const levelOrderIndex = orderedLevelIds?.findIndex(
                        (id: string) => id === obj.object_id
                      );

                      let newIds = [...(orderedLevelIds ?? [])];
                      if (
                        !isNullOrUndefined(levelOrderIndex) &&
                        levelOrderIndex !== -1
                      ) {
                        newIds.splice(levelOrderIndex, 1);

                        // Save level order
                        // Update and save level orders object
                        saveLevelOrder(newIds);
                      }

                      const success = await deleteItem(
                        `${newEndpoint.url}/${obj.object_id}/delete`
                      );
                      if (success !== false) {
                        const newArray = [...levels];

                        const index = newArray.findIndex(
                          (item) => item.object_id === obj.object_id
                        );

                        newArray.splice(index, 1);
                        const { url, objectPath } = getEndpointData(
                          newEndpoint,
                          page,
                          currentGameId
                        );

                        mutate(
                          url,
                          async (prev: any) => {
                            return {
                              [objectPath]: newArray,
                            };
                          },
                          false
                        );
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }
            }
            updateItem={async (obj: any) => {
              try {
                const object: any = (await updateItem(
                  newEndpoint,
                  obj,
                  false,
                  currentGameId
                )) as any;
                if (isNullOrUndefined(object)) {
                  return;
                }
                const newArray = [...levels];
                const index = newArray.findIndex(
                  (item) => item.object_id === obj.object_id
                );
                newArray[index] = object;
                const { url, objectPath } = getEndpointData(
                  newEndpoint,
                  page,
                  currentGameId
                );

                mutate(
                  url,
                  async (prev: any) => {
                    return {
                      [objectPath]: newArray,
                    };
                  },
                  false
                );
              } catch (e) {
                console.error(e);
              }
            }}
            id={id}
            createItem={async (obj: any) => {
              try {
                let object: any = (await createItem(
                  newEndpoint,
                  obj,
                  false,
                  currentGameId
                )) as any;
                if (isNullOrUndefined(object)) {
                  return;
                }

                const { url, objectPath } = getEndpointData(
                  newEndpoint,
                  page,
                  currentGameId
                );

                mutate(
                  url,
                  async (prev: any) => {
                    return {
                      [objectPath]: [
                        ...((prev && prev[objectPath ?? ""]) ?? []),
                        object,
                      ],
                    };
                  },
                  false
                );
              } catch (e) {
                console.error(e);
              }
            }}
          />
        </div>
      )}
    </Draggable>
  );
};
