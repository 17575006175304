import { useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { selectSession } from "../features/session/sessionSlice";
import { IVersionedLevel } from "../model/level.model";
import { getData } from "./request";

export function useVersioned<T = IVersionedLevel>() {
  const { game_id: gameId } = useSelector(selectSession);

  const url = Endpoints.VERSIONED_LEVELS.url.replace("{game_id}", gameId ?? "");
  const objectPath = Endpoints.VERSIONED_LEVELS.object_path.replace(
    "{game_id}",
    gameId ?? ""
  );
  const { data, error } = useSWR([url, gameId], (url) => getData(url), {
    suspense: false,
  });

  return useMemo(
    () => ({
      versionedLevel:
        ((data && (data as { [id: string]: any })[objectPath]) as T[]) ??
        ([] as T[]),
      isLoading: !error && !data,
      isError: error,
    }),
    [data, error, objectPath]
  );
}
