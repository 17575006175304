import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LoginRoute } from "../../constants/routes";
import { axiosConfig } from "../../data/AxiosConfig";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { queueNotification } from "../notification/notificationSlice";
import { AppThunk, RootState } from "../../app/store";
import { push } from "connected-react-router";

export interface SessionState {
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  user_id: string | undefined;
  session_id: string | undefined;
  game_id: string | undefined;
  return_path: string | undefined;
  auth_data: Record<string, never> | undefined;
}

export const DEFAULT_GAME_ID = "tens";

const initialState: SessionState = {
  isLoggingIn: false,
  isLoggingOut: false,
  user_id: undefined,
  session_id: undefined,
  game_id: DEFAULT_GAME_ID,
  auth_data: undefined,
  return_path: undefined,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setIsLoggingIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggingIn = action.payload;
    },
    setIsLoggingOut: (state, action: PayloadAction<boolean>) => {
      state.isLoggingOut = action.payload;
    },
    setToken: (
      state,
      action: PayloadAction<{
        user_id?: string | undefined;
        session_id?: string | undefined;
        game_id?: string | undefined;
        auth_data?: Record<string, never> | undefined;
        return_path?: string | undefined;
      }>
    ) => {
      state.user_id = action.payload.user_id;
      state.session_id = action.payload.session_id;
      state.auth_data = action.payload.auth_data;

      if (!isNullOrUndefined(action.payload.game_id)) {
        state.game_id = action.payload.game_id;
      }

      if (!isNullOrUndefined(action.payload.return_path)) {
        state.return_path = action.payload.return_path;
      }
    },
    setTokenTimeout: (state, action: PayloadAction<string | undefined>) => {
      state.session_id = action.payload;
    },
    setGameId: (state, action: PayloadAction<string | undefined>) => {
      state.game_id = action.payload;
    },
    setReturnPath: (state, action: PayloadAction<string | undefined>) => {
      state.return_path = action.payload;
    },
  },
});

export const { setIsLoggingIn, setIsLoggingOut } = sessionSlice.actions;
export const { setTokenTimeout, setToken, setGameId, setReturnPath } =
  sessionSlice.actions;

export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setIsLoggingIn(true));

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const game_id = getState().session.game_id;

    formData.append(
      "interface_id",
      game_id !== null ? `game_admin_${String(game_id)}` : "game_admin_tens"
    );
    formData.append("game_id", game_id !== null ? String(game_id) : "tens");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    try {
      const response: any = await axiosConfig.post(
        "/admin/authenticate",
        formData,
        config
      );

      if (response) {
        dispatch(
          setToken({
            user_id: email,
            session_id: response.data.session_id,
            game_id: game_id ?? undefined,
            auth_data: response.data.user,
          })
        );
      }

      dispatch(push("/"));
    } catch (e) {
      console.error(e);

      dispatch(
        queueNotification({
          message: "Invalid username/password...",
          options: {
            key: "login_fail",
            variant: "error",
          },
        })
      );
    }

    dispatch(setIsLoggingIn(false));
  };

export const logout =
  (notify?: boolean, nextGameId?: string, returnPath?: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      setToken({
        user_id: undefined,
        session_id: undefined,
        auth_data: undefined,
        return_path: undefined,
        game_id: undefined,
      })
    );

    if (nextGameId) {
      dispatch(
        setToken({
          game_id: nextGameId,
          return_path: returnPath,
        })
      );
    }

    sessionStorage.setItem("reloadCount", String(0));
    dispatch(push(LoginRoute));
  };

export const selectSession = (state: RootState) => state.session;

const persistConfig = {
  key: sessionSlice.name,
  storage,
  blacklist: ["isLoading", "return_path"],
};

export default persistReducer(persistConfig, sessionSlice.reducer);
