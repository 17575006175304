import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { RestrictedGroupManagerModel } from "../model/restricted-group-manager.model";
import { getData } from "./request";

type RestrictedGroupManager = {
  restrictedGroupManager: RestrictedGroupManagerModel[];
  isLoading: boolean;
  isError: boolean;
};

export function useRestrictedGroupManager() {
  const { data, error } = useSWR<Record<string, any>>(
    Endpoints.RESTRICTED_GROUP_MANAGERS.url,
    getData
  );

  const restrictedGroupManager: RestrictedGroupManager = {
    restrictedGroupManager:
      data?.[Endpoints.RESTRICTED_GROUP_MANAGERS.object_path] ?? [],
    isLoading: !error && !data,
    isError: error,
  };

  return restrictedGroupManager;
}
