export function equals(a?: string[], b?: string[]) {
  if (a?.length !== b?.length) {
    return false;
  }

  const seen: { [id: string]: number } = {};
  a?.forEach(function (v) {
    var key = typeof v + v;
    if (!seen[key]) {
      seen[key] = 0;
    }
    seen[key] += 1;
  });

  return b?.every(function (v) {
    const key = typeof v + v;
    if (seen[key]) {
      seen[key] -= 1;
      return true;
    }
    return false;
  });
}
