import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectColumnVisibility } from "../Filter/filterSlice";
import { selectLogs, selectConfig } from "../logSlice";
import moment from "moment";
import ExpandedLog from "../ExpandedLog/ExpandedLog";
import { Paper, Table, TableContainer } from "@mui/material";
import MUIDataTable from "mui-datatables";

const LogsTable = () => {
  const logs = useSelector(selectLogs);
  const config = useSelector(selectConfig);

  const userColumnVisibility = useSelector(selectColumnVisibility);

  const columnVisibility =
    userColumnVisibility ??
    config?.settings?.data_logs_default_column_visibility;

  const initialColumns = [
    {
      name: "object_id",
      label: "Log ID",
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.object_id ?? false),
      },
    },
    {
      name: "change_type",
      label: "Change Type",
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.change_type ?? true),
      },
    },
    {
      name: "related_object_id",
      label: "Object",
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.related_object_id ?? true),
      },
    },
    {
      name: "related_object_type",
      label: "Object Type",
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.related_object_type ?? false),
      },
    },
    {
      name: "created_on",
      label: "Time",
      type: "date",
      sortable: true,
      options: {
        filter: true,
        sort: true,
        display: columnVisibility?.created_on ?? true,
        customBodyRender: (value: any) =>
          moment(value).format("YYYY-MM-DD HH:mm"),
      },
    },
    {
      name: "game_id",
      label: "Game",
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.game_id ?? false),
        customBodyRender: (value: any) => {
          return value === "kanga"
            ? "Kanga"
            : config?.settings?.games_available[value]
            ? config?.settings?.games_available[value]
            : value;
        },
      },
    },
    {
      name: "user",
      label: "User",
      options: {
        filter: true,
        sort: true,
        display: columnVisibility?.user ?? true,
      },
    },
    {
      name: "change_location",
      label: "Change Location",
      sortable: true,
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.change_location ?? false),
      },
    },
    {
      name: "user_platform",
      label: "User Platform",
      sortable: true,
      options: {
        filter: true,
        sort: true,
        display: !(columnVisibility?.user_platform ?? false),
      },
    },
  ];
  const [columns] = useState(initialColumns);

  const options = {
    expandableRows: true,
    selectableRows: undefined,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={8}>
              <TableContainer component={Paper}>
                <Table>
                  <ExpandedLog
                    row={{
                      object_id: rowData[0],
                      change_type: rowData[1],
                      related_object_id: rowData[2],
                      related_object_type: rowData[3],
                      created_on: rowData[4],
                      game_id: rowData[5],
                      user: rowData[6],
                      change_location: rowData[7],
                      user_platform: rowData[8],
                    }}
                  />
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  return (
    <>
      <MUIDataTable
        data={logs?.slice().sort((a, b) => b.updated_on - a.updated_on) ?? []}
        columns={columns}
        title="Logs Page"
        options={options}
      />
    </>
  );
};

export default LogsTable;
