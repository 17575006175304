import React, { Suspense, useState } from "react";
import {
  createItem,
  deployItem,
  updateItem,
} from "../../components/CollectionEditor/crudService";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import Endpoints from "../../constants/endpoints";
import { Endpoint } from "../../model/types";
import { useCampaigns } from "../../network/useCampaigns";
import { useCreatives } from "../../network/useCreatives";
import { useConfig } from "../../network/useConfig";
import { useGameEditions } from "../../network/useGameEditions";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import { ErrorBoundary } from "react-error-boundary";
import { StatusModal } from "./StatusModal";
import { Box, CircularProgress } from "@mui/material";
import { Flex } from "../../components/Flex";
import { deleteCreative, deleteCreatives } from "./creativeService";
import { ParentCollapseEditor } from "../../components/CollectionEditor/ParentCollapseEditor";
import { XpromoCreativesModel } from "./XpromoCreativesModel";
import { Alert } from "@mui/material";

const CreativesList = () => {
  const { creatives } = useCreatives();
  const { config } = useConfig();
  const { gameEditions } = useGameEditions();
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [saveFunction, setSaveFunction] = useState<() => void>(() => {});

  const games = config?.games_available;
  const adTypes = config?.promotion_creative.ad_type;

  const { campaigns } = useCampaigns();

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const initialNestedKeys = [
    { key: "target_game_id", values: games },
    { key: "game_edition_id", values: gameEditionOptions },
    { key: "ad_type", values: adTypes },
  ];

  return (
    <>
      <ParentCollapseEditor
        onSave={(objToSave, saveFunction: () => void) => {
          if (objToSave.status === "approved") {
            setApproveModalOpen(true);
            setSaveFunction(() => saveFunction);
          } else {
            saveFunction();
          }
        }}
        nestedKeys={initialNestedKeys}
        allNestedKeys={initialNestedKeys}
        model={XpromoCreativesModel()}
        response={creatives}
        page="xpromo_creatives"
        endpoint={Endpoints.XPROMO_CREATIVES}
        updateItem={updateItem}
        deleteItem={(url: string, obj: any) =>
          deleteCreative(url, obj, campaigns)
        }
        deleteItems={(endpoint: Endpoint, arr: any[]) =>
          deleteCreatives(endpoint, arr, campaigns)
        }
        createItem={createItem}
        deployItem={deployItem}
      />
      <StatusModal
        page="xpromo_creatives"
        isOpen={statusIsOpen}
        onClose={() => setStatusIsOpen(false)}
      />
      <ConfirmationModal
        isOpen={approveModalOpen}
        title="Are you sure?"
        confirmContent="Save"
        denyContent="Cancel"
        page="xpromo_creatives"
        onClose={() => setApproveModalOpen(false)}
        onResolve={(resolved: boolean) => {
          if (resolved) {
            // Save
            saveFunction();
            setApproveModalOpen(false);
          } else {
            setApproveModalOpen(false);
          }
        }}
      >
        <p>Saving this creative will revert it's status to disabled.</p>
      </ConfirmationModal>
    </>
  );
};

export const XpromoCreatives = () => {
  return (
    <Box mb={3}>
      <ErrorBoundary
        FallbackComponent={({ error }: { error: any }) => {
          return error?.response?.status === 404 ? (
            <Alert severity="warning">No settings found</Alert>
          ) : (
            <Alert severity="error">{error.message}</Alert>
          );
        }}
      >
        <Suspense
          fallback={
            <Flex justifyContent="center" p={4}>
              <CircularProgress />
            </Flex>
          }
        >
          <CreativesList />
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
};
