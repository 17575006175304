import { FC } from "react";
import { FormItem } from "../../model/crud.model";
import { CassolitairehfModel } from "./GameSpecific/cassolitairehf/cassolitairehfModel";
import { SolitaireLevels } from "./GameSpecific/cassolitairehf/SolitaireLevels";
import {
  displayNameTemplate as puzzlesurvivalDisplayNameTemplate,
  nestedKeys as puzzlesurvivalNestedKeys,
  PuzzlesurvivalModel,
} from "./GameSpecific/puzzlesurvival/puzzlesurvivalModel";
import {
  displayNameTemplate as sliceItDisplayNameTemplate,
  nestedKeys as sliceItNestedKeys,
  sliceitModel,
} from "./GameSpecific/sliceit/sliceitModel";

import {
  displayNameTemplate as tidyMatchtDisplayNameTemplate,
  nestedKeys as tidyMatchtNestedKeys,
  TidymatchModel,
} from "./GameSpecific/tidymatch/tidymatchModel";

interface LevelMap {
  [id: string]: MapItem;
}

interface MapItem {
  model: FormItem[];
  levelOrder?: FC;
  nestedKeys?: string[];
  displayNameTemplate?: string;
}

export const FormModelsMap = () => {
  const levelsMap: LevelMap = {
    cassolitairehf: {
      levelOrder: SolitaireLevels,
      model: CassolitairehfModel(),
    },
    sliceit: {
      model: sliceitModel(),
      displayNameTemplate: sliceItDisplayNameTemplate,
      nestedKeys: sliceItNestedKeys,
    },
    puzzlesurvival: {
      model: PuzzlesurvivalModel(),
      displayNameTemplate: puzzlesurvivalDisplayNameTemplate,
      nestedKeys: puzzlesurvivalNestedKeys,
    },
    tidymatch: {
      model: TidymatchModel(),
      displayNameTemplate: tidyMatchtDisplayNameTemplate,
      nestedKeys: tidyMatchtNestedKeys,
    },
  };

  return levelsMap;
};
