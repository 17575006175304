import { useTheme } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { platformBodyColors } from "../../../theme/EmotionTheme";
import { useCurrentPage } from "../../../utils/getCurrentPage";
import { selectDarkMode } from "../../general/generalSlice";
import { dropdownsSelector } from "./Filter/filterSlice";

export const GameSettingsBackgroundColor = () => {
  const dropdowns = useSelector(dropdownsSelector);
  const theme = useTheme();
  const themeKey = useSelector(selectDarkMode);
  const page = useCurrentPage();

  const platforms = dropdowns?.platforms;

  useEffect(() => {
    const defaultBackgroundColor = theme.palette.background.default;

    if (platforms) {
      const platform = platforms.length === 1 && platforms[0].value;
      const platformColor = platform
        ? platformBodyColors[theme.palette.mode ?? ""][platform]
        : defaultBackgroundColor;

      const bodyElt = document.querySelector("body");
      const isGameSettings = page.id === "game-settings";
      const isLilacMode = themeKey === "lilac";

      bodyElt!.style.backgroundColor =
        isGameSettings && !isLilacMode ? platformColor : defaultBackgroundColor;
    }
  }, [platforms, theme, page, themeKey]);

  return null;
};
