import {
  Box,
  Button,
  CircularProgress,
  darken,
  useTheme,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FaList, FaSave } from "react-icons/all";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import { store } from "../../../../app/store";
import {
  createItem,
  updateItems,
} from "../../../../components/CollectionEditor/crudService";
import {
  CrudKeys,
  selectIsSaving,
} from "../../../../components/CollectionEditor/crudSlice";
import { FormContent } from "../../../../components/CollectionEditor/FormContent";
import { ItemModal } from "../../../../components/CollectionEditor/Modals/ItemModal";
import { Flex } from "../../../../components/Flex";
import Endpoints from "../../../../constants/endpoints";
import {
  FieldType,
  Form,
  MultipleFormValue,
} from "../../../../model/crud.model";
import { ILevel, ILeverOrder } from "../../../../model/level.model";
import { useGameEditions } from "../../../../network/useGameEditions";
import { useLevelOrder } from "../../../../network/useLevelOrder";
import { useLevels } from "../../../../network/useLevels";
import { useSolitaireAreaOrders } from "../../../../network/useSolitaireAreaOrders";
import { useSolitaireAreas } from "../../../../network/useSolitaireAreas";
import { getEndpointData } from "../../../../utils/getEndpointData";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import matches from "../../../../utils/matches";
import { reorder } from "../../../../utils/reorder";
import { useEndpointAsOptions } from "../../../../utils/useEndpointAsOptions";
import { FullScreenProgressBar } from "../../../AddToAll/components/FullScreenProgressBar";
import { useCategories } from "../../hooks/useCategories";
import { useGameModel } from "../../hooks/useGameModel";
import { CassolitairehfLevel, ISolitaireAreaOrder } from "./types";
import { ISolitaireArea } from "./types";
import { DraggableLevel } from "../../components/DraggableLevel";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../notification/notificationSlice";
import { selectSession } from "../../../session/sessionSlice";
import { saveLevelOrder } from "../../levelOrderService";
import { NotInLevelOrderLevels } from "../../NotInLevelOrderLevels";
import { BrokenLevelReference } from "../../components/BrokenLevelReference";
import CopyLevelOrderModal from "../../components/CopyLevelOrderModal";

export function getSplitLevelIds(
  levels: CassolitairehfLevel[],
  orderedLevelIds: string[],
  areaClientIds?: string[]
) {
  const splitOrderedLevelIds: { [id: string]: string[] } = {};
  const splitUnaddedLevelIds: { [id: string]: string[] } = {};
  areaClientIds?.forEach((areaClientId: string) => {
    splitUnaddedLevelIds[areaClientId] = [];
    splitOrderedLevelIds[areaClientId] = [];
    const levelsByArea = levels
      .filter((level: CassolitairehfLevel) => {
        return level.area_id === areaClientId;
      })
      .filter((level: ILevel) => orderedLevelIds.includes(level.object_id))
      .sort(
        (a: ILevel, b: ILevel) =>
          orderedLevelIds.indexOf(a.object_id) -
          orderedLevelIds.indexOf(b.object_id)
      );

    const unaddedLevels = levels
      .filter((level: CassolitairehfLevel) => {
        return level.area_id === areaClientId;
      })
      .filter((level: ILevel) => !orderedLevelIds.includes(level.object_id));

    splitUnaddedLevelIds[areaClientId] = unaddedLevels.map(
      (level: ILevel) => level.object_id
    );

    splitOrderedLevelIds[areaClientId] = levelsByArea.map(
      (level: ILevel) => level.object_id
    );
  });

  return { splitUnaddedLevelIds, splitOrderedLevelIds };
}

export const getIndicesForArea = (
  areaClientId: string,
  orderedLevelIds: string[],
  levels: CassolitairehfLevel[]
) => {
  const newOrderedLevelIds = _.cloneDeep(orderedLevelIds);

  // find index and group in ordered ids
  const firstIndex = newOrderedLevelIds.findIndex(
    (levelId: string, index: number) => {
      const level = levels.find(
        (level: CassolitairehfLevel) => level.object_id === levelId
      );

      return level?.area_id === areaClientId;
    }
  );

  newOrderedLevelIds.reverse();

  const lastIndex =
    newOrderedLevelIds.length -
    1 -
    newOrderedLevelIds.findIndex((levelId: string) => {
      const level = levels.find(
        (level: CassolitairehfLevel) => level.object_id === levelId
      );

      return level?.area_id === areaClientId;
    });

  newOrderedLevelIds.reverse();

  return { first: firstIndex, last: lastIndex };
};

export const SolitaireLevels = () => {
  const { gameEditions } = useGameEditions();
  const { levelOrders, isLoading: isLoadingLevelOrders } = useLevelOrder();
  const { levels: gameLevels, isLoading: isLoadingLevels } = useLevels<any>();
  const levels: any = useMemo(
    () =>
      gameLevels.map((item) => ({
        ...item,
        cms_settings: {
          cms_deck_size: item.cms_deck_size,
          cms_deck_range: item.cms_deck_range,
          cms_deck_base: item.cms_deck_base,
          cms_deck_first: item.cms_deck_first,
          cms_deck_increase: item.cms_deck_increase,
          cms_board_range: item.cms_board_range,
        },
        combo_reveal_settings: {
          combo_reveal_modifier: item.combo_reveal_modifier,
          combo_threshold: item.combo_threshold,
          combo_step_value: item.combo_step_value,
        },
      })),
    [gameLevels]
  );

  const categories = useCategories();
  const theme = useTheme();
  const { game_id: currentGameId } = useSelector(selectSession);
  const page = `${currentGameId}_levels`;
  const gameModel = useGameModel();
  const { areas } = useSolitaireAreas();
  const { areaOrders } = useSolitaireAreaOrders();

  const groupKey = "area_id";

  const url = Endpoints.LEVELS.url.replace("{game_id}", currentGameId ?? "");
  const newEndpoint = { ...Endpoints.LEVELS, url };

  const [showAllAreas, setShowAllAreas] = useState(false);
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [groupKey]: null,
    }));
  }, [showAllAreas]);

  const [filters, setFilters] = useState<{
    [id: string]: any;
  }>({
    game_edition_id: ["ios", "android"],
    category_id: "default",
    [groupKey]: null,
  });

  const getLevel = useCallback(
    (levelId: string) => {
      return levels.find(
        (level: CassolitairehfLevel) => level.object_id === levelId
      );
    },
    [levels]
  );

  const currentLevelOrder: ILeverOrder[] | undefined = useMemo(() => {
    return levelOrders?.filter(
      (order: ILeverOrder) =>
        filters.game_edition_id.includes(order.game_edition_id) &&
        order.category_id === filters.category_id
    );
  }, [filters, levelOrders]);

  const initialOrderedLevelIds: string[] = useMemo(
    () => [
      ...new Set(
        currentLevelOrder.map((item) => item?.ordered_level_ids).flat()
      ),
    ],
    [currentLevelOrder]
  );

  const [orderedLevelIds, setOrderedLevelIds] = useState(
    initialOrderedLevelIds
  );

  const areaClientIds: string[] | undefined = useMemo(() => {
    let areaIds =
      areaOrders.find(
        (areaOrder: ISolitaireAreaOrder) =>
          areaOrder.category_id === filters.category_id &&
          filters.game_edition_id.includes(areaOrder.game_edition_id)
      )?.ordered_area_ids ?? [];

    if (showAllAreas) {
      areaIds = [
        ...areaIds,
        ...areas
          .map((area: ISolitaireArea) => area.object_id)
          .filter((id: string) => !areaIds.includes(id)),
      ];
    }

    return areaIds;
  }, [areaOrders, filters, showAllAreas]);

  useEffect(() => {
    const newOrderedLevelIds = initialOrderedLevelIds ?? [];
    setOrderedLevelIds(newOrderedLevelIds);
  }, [areaClientIds, initialOrderedLevelIds, levels]);

  const { splitOrderedLevelIds, splitUnaddedLevelIds } = getSplitLevelIds(
    levels,
    orderedLevelIds,
    areaClientIds
  );

  const [isNewOpen, setIsNewOpen] = useState(false);
  const [isMultipleOpen, setIsMultipleOpen] = useState(false);

  const areaNames: { [id: string]: any } = {};

  areaClientIds?.forEach((id: string) => {
    areaNames[id] = areas.find(
      (area: ISolitaireArea) => area.object_id === id
    )?.name;
  });

  function onlyUnique(value: any, index: any, self: string | any[]) {
    return self.indexOf(value) === index;
  }

  function hasDuplicates(array: any[]) {
    return new Set(array).size !== array.length;
  }

  const getClientIdsOrder = () =>
    orderedLevelIds
      .map((levelId: string) => getLevel(levelId)?.area_id)
      .filter(onlyUnique);

  useEffect(() => {
    if (isLoadingLevels) {
      return;
    }

    // If orderedLevelIds are not in area order, sort into area order
    const clientIds = orderedLevelIds.map(
      (levelId: string) => getLevel(levelId)?.area_id
    );

    const splitByValue: string[][] = [];
    let newArr: string[] = [];
    let prevClientId: string | undefined = "";
    clientIds.forEach((clientId: string | undefined, index: number) => {
      if (prevClientId && clientId === prevClientId) {
        newArr.push(clientId);
      } else if (index !== 0) {
        splitByValue.push(newArr);
        newArr = [];
        clientId && newArr.push(clientId);
      }

      // If last element
      if (index === clientIds.length - 1) {
        splitByValue.push(newArr);
      }

      prevClientId = clientId;
    });

    const reducedArr: string[] = [];
    // Reduce each array down to one array
    splitByValue.forEach((arr) => {
      reducedArr.push(arr[0]);
    });

    // If a value is repeated then not sorted
    if (hasDuplicates(reducedArr)) {
      // Order ids by area and set
      store.dispatch(
        queueNotification({
          message:
            "SolitaireLevels were not in order of area client ID and have been reordered",
          options: {
            key: "reordered",
            variant: SnackbarVariant.WARNING,
          },
        })
      );
      const newOrderedLevelIds = orderedLevelIds.sort(
        (a: string, b: string) => {
          const levelA = getLevel(a);
          const levelB = getLevel(b);

          return (
            (areaClientIds?.indexOf(levelB?.area_id ?? "") ?? -1) -
            (areaClientIds?.indexOf(levelA?.area_id ?? "") ?? -1)
          );
        }
      );
      setOrderedLevelIds(newOrderedLevelIds);
    }
  }, [
    areaClientIds,
    getLevel,
    orderedLevelIds,
    splitOrderedLevelIds,
    isLoadingLevels,
  ]);

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const levelIdsMap: { [id: string]: string } = {};
  levels?.forEach((level: ILevel) => {
    levelIdsMap[level.object_id] = level.name;
  });

  const addLevelIds = (ids: string[]) => {
    let newIds = [...orderedLevelIds];
    newIds = newIds.concat(...ids);

    setOrderedLevelIds(newIds);
  };

  const removeLevelId = (index: number) => {
    let newIds = [...orderedLevelIds];
    newIds.splice(index, 1);

    setOrderedLevelIds(newIds);
  };

  const removeFromLevelOrder = (levelId: string) => {
    const index = orderedLevelIds.findIndex((id: string) => id === levelId);

    removeLevelId(index);
  };

  const model: Form = [
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.MULTISELECT,
      selectOptions: Object.entries(gameEditionOptions).map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      }),
      chipSize: "small",
      selectSize: "small",
      valueFunction(obj?) {
        return filters.game_edition_id;
      },
      label: "Game Edition",
      limitTags: 2,
      placeholder: "Game Editions",
      id: "game_editions",
      disabled: false,
      needGrouping: false,
    },
    {
      display_name: groupKey,
      name: groupKey,
      type: FieldType.SELECT,
      options: areaNames,
      defaultValue: "",
    },
  ];

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const areaClientIdsOrder = getClientIdsOrder();
    let newOrderedLevelIds = _.cloneDeep(orderedLevelIds);

    // Get area to move from current index and idsOrder
    const areaToMove = areaClientIdsOrder[result.source.index];

    const { first: sourceFirstIndex, last: sourceLastIndex } =
      getIndicesForArea(areaToMove ?? "", orderedLevelIds, levels);

    // Move all ids to this new location
    const idsToMove = newOrderedLevelIds.splice(
      sourceFirstIndex,
      sourceLastIndex - sourceFirstIndex
    );

    // Find destination index in ordered ids and insert
    const destArea = areaClientIdsOrder[result.destination.index];
    const { first: destFirstIndex } = getIndicesForArea(
      destArea ?? "",
      orderedLevelIds,
      levels
    );

    newOrderedLevelIds.splice(destFirstIndex, 0, ...idsToMove);

    setOrderedLevelIds(newOrderedLevelIds);
  }

  function onInnerDragEnd(
    result: DropResult,
    provided: ResponderProvided,
    areaClientId: string
  ) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrderedLevelIds = reorder(
      splitOrderedLevelIds[areaClientId],
      result.source.index,
      result.destination.index
    );

    const newSplitOrderedLevelIds = {
      ...splitOrderedLevelIds,
      [areaClientId]: newOrderedLevelIds,
    };

    // Set level ids as concatenation of all split areas
    const concatenatedLevelIds = _.flatMap(
      Object.values(newSplitOrderedLevelIds)
    );

    setOrderedLevelIds(concatenatedLevelIds);
  }

  const getGlobalIndex = (topLevelIndex: number, index: number) => {
    const indexArr = Object.values(splitOrderedLevelIds).map(
      (arr) => arr.length
    );

    const indexArrTotals = indexArr.map(
      (
        (sum) => (value: number) =>
          (sum += value)
      )(0)
    );

    return topLevelIndex >= 1
      ? indexArrTotals[topLevelIndex - 1] + index
      : index;
  };

  const isLoading = isLoadingLevels || isLoadingLevelOrders;

  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);

  const setSelected = (id: string) => {
    if (!selectedLevels.includes(id)) {
      setSelectedLevels([...selectedLevels, id]);
    } else {
      setSelectedLevels(selectedLevels.filter((levelId) => levelId !== id));
    }
  };

  const levelIds = levels.map((level: ILevel) => level.object_id);

  const createMultipleFormValue = (
    selectedLevels: string[]
  ): Record<string, MultipleFormValue> => {
    const multipleFormValue: Record<string, MultipleFormValue> = {};
    // For each field in model
    gameModel.forEach((field) => {
      // For each level in selectedLevels
      const values = selectedLevels.map((levelId) => {
        const level = getLevel(levelId);

        return level?.[field.name as keyof CassolitairehfLevel] ?? "";
      });

      // If all values are the same, return that value
      if (values.every((value) => matches(value, values[0]))) {
        multipleFormValue[field.name] = {
          different: false,
          value: values[0],
        };
      } else {
        multipleFormValue[field.name] = {
          different: true,
        };
      }
    });

    return multipleFormValue;
  };

  const [multipleFormValues, setMultipleFormValues] = useState<
    Record<string, MultipleFormValue>
  >(createMultipleFormValue(selectedLevels));

  useEffect(() => {
    setMultipleFormValues(createMultipleFormValue(selectedLevels));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevels, levels]);

  const isSaving = useSelector(selectIsSaving);
  const { objectPath } = getEndpointData(newEndpoint, page, currentGameId);
  return (
    <>
      {isMultipleOpen && isSaving && isSaving[objectPath as keyof CrudKeys] && (
        <FullScreenProgressBar title="Saving multiple items, please do not refresh the page until this screen has closed." />
      )}
      <FormContent
        formValue={filters}
        model={model}
        page="level_order"
        setFormValue={setFilters}
        incorporateLabels
        inline
      />
      <Box
        mt={4}
        py={2}
        display="flex"
        position="sticky"
        top="0"
        bgcolor="background.default"
        justifyContent="space-between"
        zIndex={999}
      >
        <Box>
          <Button
            variant="outlined"
            onClick={async () => {
              setIsNewOpen(true);
            }}
            disabled={isLoading}
          >
            Add Level
          </Button>
          <CopyLevelOrderModal
            categories={categories}
            gameEditionOptions={gameEditionOptions}
            orderedLevelIds={orderedLevelIds}
            levelOrders={levelOrders}
            levelFilters={filters}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllAreas}
                onChange={(event, checked) => setShowAllAreas(checked)}
              />
            }
            label="Show disabled areas"
          />
          <Button
            startIcon={<FaList />}
            disabled={
              !areaClientIds || isLoading || selectedLevels.length === 0
            }
            onClick={() => setIsMultipleOpen(true)}
            style={{ marginRight: "0.7rem" }}
            variant="outlined"
            color="secondary"
          >
            Update Multiple Levels ({selectedLevels.length} Selected)
          </Button>
          <Button
            startIcon={<FaSave />}
            disabled={!areaClientIds || isLoading}
            onClick={() =>
              saveLevelOrder(
                currentLevelOrder,
                orderedLevelIds,
                filters,
                levelOrders
              )
            }
            variant="contained"
            color="primary"
          >
            Save Level Order
          </Button>
        </Box>
      </Box>

      {!isLoading ? (
        <Box mt={5}>
          {!levels && <Alert severity="info">No objects found</Alert>}
          {!areaClientIds ? (
            <Alert severity="info">
              No Area Order exists for this category and game edition. Create
              one from the <Link to="/levels/level-areas">Level Areas</Link>{" "}
              page.
            </Alert>
          ) : (
            <>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={matches(selectedLevels, levelIds, {
                        fuzzyComparison: true,
                      })}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (
                          matches(selectedLevels, levelIds, {
                            fuzzyComparison: true,
                          })
                        ) {
                          setSelectedLevels([]);
                        } else {
                          setSelectedLevels(levelIds);
                        }
                      }}
                    />
                  }
                  label="Select all"
                />
              </Box>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="area-client-ids">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {Object.entries(splitOrderedLevelIds)
                        ?.filter(([areaClientId, levelIds]) =>
                          filters[groupKey]
                            ? areaClientId === filters[groupKey]
                            : true
                        )
                        .sort(
                          ([aKey], [bKey]) =>
                            areaClientIds.indexOf(aKey) -
                            areaClientIds.indexOf(bKey)
                        )
                        .map(([areaClientId, levelIds], topLevelIndex) => {
                          return (
                            <Draggable
                              key={areaClientId}
                              draggableId={areaClientId}
                              index={topLevelIndex}
                              isDragDisabled
                            >
                              {(topDragProvided) => (
                                <div
                                  ref={topDragProvided.innerRef}
                                  {...topDragProvided.draggableProps}
                                  {...topDragProvided.dragHandleProps}
                                >
                                  <Box
                                    bgcolor={darken(
                                      theme.palette.background.paper,
                                      0.2
                                    )}
                                    p={2}
                                    borderRadius={4}
                                    mb={3}
                                  >
                                    <Flex alignItems="center">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={levelIds.every((r) =>
                                              selectedLevels.includes(r)
                                            )}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              if (
                                                levelIds.every((r) =>
                                                  selectedLevels.includes(r)
                                                )
                                              ) {
                                                setSelectedLevels(
                                                  selectedLevels.filter(
                                                    (el) =>
                                                      !levelIds.includes(el)
                                                  )
                                                );
                                              } else {
                                                setSelectedLevels(
                                                  [
                                                    ...selectedLevels,
                                                    ...levelIds,
                                                  ].filter(
                                                    (v, i, a) =>
                                                      a.indexOf(v) === i
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        }
                                        label=""
                                      />
                                      <h3>
                                        {
                                          areas.find(
                                            (area: ISolitaireArea) =>
                                              area.object_id === areaClientId
                                          )?.name
                                        }
                                      </h3>
                                    </Flex>
                                    <DragDropContext
                                      onDragEnd={(result, provided) =>
                                        onInnerDragEnd(
                                          result,
                                          provided,
                                          areaClientId
                                        )
                                      }
                                    >
                                      <Droppable droppableId={areaClientId}>
                                        {(topDropProvided) => (
                                          <div
                                            ref={topDropProvided.innerRef}
                                            {...topDropProvided.droppableProps}
                                          >
                                            {levelIds?.map(
                                              (id: string, index: number) => {
                                                const level = levels?.find(
                                                  (level: ILevel) =>
                                                    level.object_id === id
                                                );

                                                if (!level) {
                                                  return (
                                                    <BrokenLevelReference
                                                      id={id}
                                                      index={index}
                                                      removeLevelId={
                                                        removeLevelId
                                                      }
                                                    />
                                                  );
                                                }

                                                return (
                                                  <DraggableLevel
                                                    isDragDisabled={false}
                                                    selected={selectedLevels}
                                                    setSelected={() =>
                                                      setSelected(id)
                                                    }
                                                    key={`${id}-${index}`}
                                                    id={`${id}-${index}`}
                                                    level={level}
                                                    index={index}
                                                    removeFromLevelOrder={
                                                      removeFromLevelOrder
                                                    }
                                                    addToLevelOrder={
                                                      addLevelIds
                                                    }
                                                    levels={levels}
                                                    globalIndex={getGlobalIndex(
                                                      topLevelIndex,
                                                      index
                                                    )}
                                                    orderedLevelIds={
                                                      orderedLevelIds
                                                    }
                                                    saveLevelOrder={(
                                                      levelIds
                                                    ) =>
                                                      saveLevelOrder(
                                                        currentLevelOrder,
                                                        levelIds,
                                                        filters,
                                                        levelOrders
                                                      )
                                                    }
                                                    page={page}
                                                    endpoint={Endpoints.LEVELS}
                                                  />
                                                );
                                              }
                                            )}

                                            {topDropProvided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                    {splitOrderedLevelIds[areaClientId]
                                      ?.length === 0 && (
                                      <Alert severity="info">
                                        No levels in order
                                      </Alert>
                                    )}
                                    {splitUnaddedLevelIds[areaClientId]
                                      .length !== 0 && (
                                      <NotInLevelOrderLevels
                                        selected={selectedLevels}
                                        setSelected={setSelected}
                                        unaddedLevelIds={
                                          splitUnaddedLevelIds[areaClientId]
                                        }
                                        levelIds={levelIds}
                                        levels={levels}
                                        addToLevelOrder={addLevelIds}
                                        saveLevelOrder={(levelIds: string[]) =>
                                          saveLevelOrder(
                                            currentLevelOrder,
                                            levelIds,
                                            filters,
                                            levelOrders
                                          )
                                        }
                                        page={page}
                                        orderedLevelIds={orderedLevelIds}
                                        endpoint={Endpoints.LEVELS}
                                      />
                                    )}
                                  </Box>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </Box>
      ) : (
        <Flex width="100%" m={2} p={1} justifyContent="center">
          <CircularProgress />
        </Flex>
      )}

      {isNewOpen && (
        <ItemModal
          endpoint={newEndpoint}
          defaultValueOverrides={{
            category_id: "default",
            level_type: "default",
          }}
          model={gameModel}
          isOpen={isNewOpen}
          onClose={() => setIsNewOpen(false)}
          isCreate
          createItem={async (obj: any) => {
            const flattenObj: any = (obj: any) =>
              Object.keys(obj).reduce(
                (acc, cur) =>
                  typeof obj[cur] === "object"
                    ? { ...acc, ...flattenObj(obj[cur]) }
                    : { ...acc, [cur]: obj[cur] },
                {}
              );
            try {
              let object: any = (await createItem(
                newEndpoint,
                flattenObj(obj),
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }

              const { url, objectPath } = getEndpointData(
                newEndpoint,
                page,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: [
                      ...((prev && prev[objectPath ?? ""]) ?? []),
                      object,
                    ],
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          //@ts-ignore
          page={page}
        />
      )}

      {isMultipleOpen && (
        <ItemModal
          multipleFormValues={multipleFormValues}
          setMultipleFormValues={(obj) => setMultipleFormValues(obj)}
          endpoint={newEndpoint}
          defaultValueOverrides={{
            category_id: "default",
            level_type: "default",
          }}
          model={gameModel}
          isOpen={isMultipleOpen}
          onClose={() => {
            setIsMultipleOpen(false);
            setMultipleFormValues(createMultipleFormValue(selectedLevels));
          }}
          updateItem={async (obj: any) => {
            const { url, objectPath } = getEndpointData(
              newEndpoint,
              page,
              currentGameId
            );
            try {
              const newLevels = [...levels];
              const levelsToUpdate = selectedLevels.map((levelId) => {
                const levelToUpdate = levels?.find(
                  (level: ILevel) => level.object_id === levelId
                );

                return { ...levelToUpdate, ...obj };
              });

              if (isNullOrUndefined(levelsToUpdate)) {
                return;
              }

              const serverLevels = await updateItems(
                newEndpoint,
                levelsToUpdate,
                currentGameId
              );

              // Replace old levels with new levels
              for (const levelId of selectedLevels) {
                const levelToUseInReplace = serverLevels?.find(
                  (level: ILevel) => level.object_id === levelId
                );

                const index = levels?.findIndex(
                  (item: { object_id: any }) =>
                    item.object_id === levelToUseInReplace.object_id
                );

                if (index !== -1) {
                  newLevels[index] = levelToUseInReplace;
                }
              }

              mutate(
                url,
                async () => {
                  return {
                    [objectPath]: newLevels,
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          createItem={async (obj: any) => {
            try {
              let object: any = (await createItem(
                newEndpoint,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }

              const { url, objectPath } = getEndpointData(
                newEndpoint,
                page,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: [
                      ...((prev && prev[objectPath ?? ""]) ?? []),
                      object,
                    ],
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          //@ts-ignore
          page={page}
        />
      )}
    </>
  );
};
