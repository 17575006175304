import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { ICampaign } from "../features/XpromoCampaigns/xpromo-campaign.model";
import { getData } from "./request";

type UseCampaigns = {
  campaigns: ICampaign[];
  isLoading: boolean;
  isError: boolean;
};

export function useCampaigns() {
  const { data, error } = useSWR(Endpoints.XPROMO_CAMPAIGNS.url, getData, {
    suspense: true,
  });

  const campaigns: UseCampaigns = {
    campaigns:
      data &&
      (data as { [id: string]: any })[Endpoints.XPROMO_CAMPAIGNS.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return campaigns;
}
