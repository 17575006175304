import moment from "moment/moment";
import { CampaignStatus, ICampaign } from "./xpromo-campaign.model";

export const isCampaignExpired = (campaign: ICampaign): boolean => {
  return (
    campaign.budget_updated_on !== null &&
    (campaign.end ?? 0) < moment().valueOf()
  );
};

export const isCampaignPending = (campaign: ICampaign): boolean => {
  return campaign.budget_updated_on === null;
};

export const isCampaignRunning = (campaign: ICampaign): boolean => {
  return (
    campaign.budget_updated_on !== null &&
    (campaign?.start ?? 0) < moment().valueOf() &&
    moment().valueOf() < (campaign.end ?? 0)
  );
};

export const isCampaignError = (campaign: ICampaign): boolean => {
  return (
    campaign.budget_updated_on !== null &&
    moment().valueOf() < (campaign.start ?? 0)
  );
};

export const isCampaignWarning = (campaign: ICampaign) =>
  campaign.qa_status === CampaignStatus.FAILED && isCampaignRunning(campaign);
