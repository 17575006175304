import { Checkbox } from "@mui/material";
import { useMemo } from "react";
import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import { Flex } from "../../components/Flex";
import Endpoints from "../../constants/endpoints";
import { FieldType, Form } from "../../model/crud.model";
import { useUserPermissions } from "../../network/useUserPermissions";
import { useUserRegistry } from "../../network/useUserRegistry";

export const UserPermission = () => {
  const { userPermissions, isLoading } = useUserPermissions();
  const { userRegistry } = useUserRegistry();

  const userPermissionsFiltered = useMemo(() => {
    const keys = Object.keys(userRegistry ?? {});

    return (userPermissions ?? []).map((i, idx) =>
      i.name === "Admin"
        ? {
            ...i,
            permissions: keys.reduce(
              (obj: any, key: any) => ({
                ...obj,
                [key]: (userPermissions?.find((i, idx) => i.name === "Admin")
                  ?.permissions ?? {})[key],
              }),
              {}
            ),
          }
        : i
    );
  }, [userPermissions, userRegistry]);

  const model: Form = [
    {
      display_name: "Name",
      name: "name",
      type: FieldType.STRING,
      main_field: true,
    },
    {
      display_name: "Permissions",
      name: "permissions",
      type: FieldType.OBJECT,
      itemModel: [
        {
          display_name: "Allow Access",
          name: "value",
          valueFunction: (obj) => obj,
          type: FieldType.CUSTOM,
          renderOverride: (option, onChangeFunction, value) => (
            <Flex>
              <Flex alignItems="center">
                <Checkbox
                  checked={option?.methods.GET}
                  onChange={(event, k) => {
                    return onChangeFunction({
                      [value]: {
                        ...option,
                        methods: {
                          ...option?.methods,
                          GET: !option?.methods.GET,
                        },
                      },
                    });
                  }}
                />{" "}
                GET
              </Flex>
              <Flex alignItems="center">
                <Checkbox
                  checked={option?.methods.POST}
                  onChange={(event, k) => {
                    return onChangeFunction({
                      value: {
                        ...option,
                        methods: {
                          ...option.methods,
                          POST: !option?.methods.POST,
                        },
                      },
                    });
                  }}
                />{" "}
                POST
              </Flex>
            </Flex>
          ),
        },
      ],
      keyModel: {
        name: "permissions",
        display_name: "Permissions",
        type: FieldType.SELECT,
        options: userRegistry
          ? Object.entries(userRegistry)?.map(([key, value]) => ({
              value: key,
              label: value.pretty_name,
            }))
          : [],

        defaultValue: "",
      },
      defaultValue: {},
    },
  ];

  return (
    <CollectionEditor
      response={Object.values(userPermissionsFiltered ?? [])}
      page="users"
      isLoading={isLoading}
      parentField="name"
      model={model}
      endpoint={Endpoints.USER_PERMISSIONS}
    />
  );
};
