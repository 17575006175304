import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { push } from "connected-react-router";
import React from "react";
import { FaBars } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { getEnvironmentName, isLiveEnv } from "../../data/AxiosConfig";
import { FullScreenProgressBar } from "../../features/AddToAll/components/FullScreenProgressBar";
import { logout, selectSession } from "../../features/session/sessionSlice";
import { useMenuTrees } from "../../network/useMenuTrees";
import { useViewport } from "../../utils/useViewport";

interface HeaderProps {
  title: string;
  isExpanded: boolean;
  setIsExpanded: (expanded: boolean) => void;
  expanded: boolean;
}

export const Header = ({
  title,
  setIsExpanded,
  isExpanded,
  expanded,
}: HeaderProps) => {
  const {
    session_id,
    user_id,
    isLoggingOut,
    game_id: gameId,
    auth_data,
  } = useSelector(selectSession);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const location = useLocation();

  const { width } = useViewport();
  const isMobile = width < 769;

  const isLoggedIn = !!session_id;

  const { convertedMenuTrees } = useMenuTrees();

  const accountRoute = convertedMenuTrees.find(
    (route) => route.name === "Account"
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {isMobile && (
          <IconButton size="small">
            <FaBars onClick={() => setIsExpanded(!isExpanded)} />
          </IconButton>
        )}
        <Tooltip title="Home">
          <Link
            sx={{
              fontFamily: "aller",
              fontWeight: "bold",
              textDecoration: "none",
              color: isLiveEnv() ? "#D32F2F" : "white",
            }}
            component={NavLink}
            to="/"
          >
            {getEnvironmentName()} {title}
          </Link>
        </Tooltip>
        <Box>
          <Tooltip title="Account">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                src={`http://d2kre6881rwtik.cloudfront.net/${auth_data?.image_url}`}
                sx={{ width: 32, height: 32 }}
              >
                {user_id?.charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {accountRoute?.elements
            ?.filter((route) => route.name !== "Logout")
            .map((route) => {
              return (
                <MenuItem
                  key={route.id}
                  disabled={!isLoggedIn}
                  onClick={() =>
                    dispatch(push(`${accountRoute.path}${route.path}`))
                  }
                >
                  {route.name}
                </MenuItem>
              );
            })}
          <MenuItem
            disabled={!isLoggedIn}
            onClick={() =>
              dispatch(logout(true, gameId ?? "", location.pathname))
            }
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>

      {expanded && (
        <Divider sx={{ marginY: 2, marginX: 1, borderColor: "#3d4060" }} />
      )}

      {isLoggingOut && <FullScreenProgressBar title="Logging Out..." />}
    </>
  );
};
