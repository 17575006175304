import { useSelector } from "react-redux";
import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { selectSession } from "../features/session/sessionSlice";
import { getData } from "./request";

export function useGameConfig() {
  const { data, error } = useSWR(Endpoints.GAME_CONFIG.url, getData, {
    suspense: true,
  });

  const { game_id: currentGameId } = useSelector(selectSession);

  const objectPath = Endpoints.GAME_CONFIG.object_path.replace(
    "{game_id}",
    currentGameId ?? ""
  );

  return {
    gameConfig: data && (data as { [id: string]: any })[objectPath]?.settings,
    isLoading: !error && !data,
    isError: error,
  };
}
