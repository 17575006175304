import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { DialogWrapper } from "../../../components/DialogWrapper";
import { FieldType, FormItem } from "../../../model/crud.model";
import { VersionedSettings } from "../../../model/versioned-settings.model";
import { addGameSettings } from "../addToAllThunks";
import { SettingsSelector } from "./SettingsSelector";

interface AddNewVersionedSettingsProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const AddNewVersionedSettings = ({
  isOpen,
  onClose,
}: AddNewVersionedSettingsProps) => {
  const [newValues, setFormValue] = useState<{
    game_edition_ids?: string[];
    country_codes?: string[];
    max_versions?: string[];
    name?: string;
  }>({});

  const dispatch = useDispatch();

  const model: FormItem[] = [
    {
      display_name: "Name",
      name: "name",
      type: FieldType.STRING,
    },
  ];

  return (
    <DialogWrapper isOpen={!!isOpen} onClose={() => onClose()} size="sm">
      <DialogTitle>Create New Versioned Settings</DialogTitle>
      <DialogContent>
        <SettingsSelector
          newValues={newValues}
          setFormValue={(newValue) => setFormValue(newValue)}
          filterVersions
          singleSelect
        />
        <FormContent
          isCreate
          formValue={newValues}
          model={model}
          page="game_settings"
          setFormValue={setFormValue}
        />
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          className="ml-1"
          color="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={async () => {
            dispatch(addGameSettings(newValues as VersionedSettings));
            onClose();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
