import { GameId } from "../model/sidebar.model";
import { Endpoint } from "../model/types";

export const getEndpointData = (
  endpoint: Endpoint,
  page: string,
  currentGameId?: GameId
) => {
  const objectPath =
    page === endpoint.object_path?.replace("{game_id}", currentGameId ?? "")
      ? endpoint.object_path?.replace("{game_id}", currentGameId ?? "")
      : endpoint.object_path;

  const singleObjectPath =
    page ===
    endpoint.single_object_path?.replace("{game_id}", currentGameId ?? "")
      ? endpoint.single_object_path?.replace("{game_id}", currentGameId ?? "")
      : endpoint.single_object_path;

  const url =
    page === endpoint.object_path?.replace("{game_id}", currentGameId ?? "")
      ? endpoint.url?.replace("{game_id}", currentGameId ?? "")
      : endpoint.url;
  return { url, objectPath, singleObjectPath };
};
