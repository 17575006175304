/**
 * Axios Request Interceptor
 */
import { axiosConfig } from "../AxiosConfig";
import { AXIOS_VARIABLES, setPendingRequest } from "./variables";

axiosConfig.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (
        AXIOS_VARIABLES.PENDING_REQUESTS < AXIOS_VARIABLES.MAX_REQUESTS_COUNT
      ) {
        setPendingRequest(AXIOS_VARIABLES.PENDING_REQUESTS + 1);
        clearInterval(interval);
        resolve(config);
      }
    }, AXIOS_VARIABLES.INTERVAL_MS);
  });
});
