import DeleteIcon from "@mui/icons-material/Delete";
import PresetIcon from "@mui/icons-material/Tune";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import compressFile from "./compressFile";
import GeneratorError from "./GeneratorError";
import { MAX_FILE_SIZE_MB } from "./IECGenerator";
import readFile from "./readFile";

const VIDEO_HTML = `
<html>
<head>
  <meta name="ad.size" content="width={width},height={height}">
  <script type="text/javascript">
    var clickTag = "{url}";
  </script>
  <style type="text/css">
      body {
          background: #000;
          margin: 0;
          width: 100vw;
          text-align: center;
      }

      video {
          margin: 0 auto;
          height: {height}px;
          width: {width}px;
          cursor: pointer;
      }
  </style>
</head>
<body>
<video id="vid" playsinline autoplay loop muted>
  <source type="{type}" src="{source}" />
</video>

<script type='text/javascript'>
  const vidEl = document.getElementById('vid');

  vidEl.addEventListener('click', onClick);
  function onClick() {
    window.open(clickTag);
  }
</script>
</body>
</html>
`;

const IMAGE_HTML = `
<html>
<head>
  <meta name="ad.size" content="width={width},height={height}">
  <script type="text/javascript">
    var clickTag = "{url}";
  </script>
  <style type="text/css">
      body {
          background: #000;
          margin: 0;
          width: 100vw;
          text-align: center;
      }

      img {
          margin: 0 auto;
          height: {height}px;
          width: {width}px;
          cursor: pointer;
      }
  </style>
</head>
<body>
<img id="img" src="{source}" />

<script type='text/javascript'>
  const imgEl = document.getElementById('img');

  imgEl.addEventListener('click', onClick);
  function onClick() {
    window.open(clickTag);
  }
</script>
</body>
</html>
`;

type Values = {
  media: File[];
  url: string;
  width: number;
  height: number;
};

const SIZE_PRESETS = [
  [970, 250],
  [300, 600],
  [300, 250],
];

export default function Banner() {
  const [downloading, setDownloading] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const exportFile = async (
    file: File,
    width: number,
    height: number,
    targetUrl: string
  ) => {
    setDownloading((prev) => [...prev, file]);

    const original = file;
    file = await compressFile(file);
    const b64 = await readFile(file);

    const fileType = b64.substring(5, b64.indexOf(";"));

    let fileContents;
    if (fileType.startsWith("video/")) {
      fileContents = VIDEO_HTML;
    } else {
      fileContents = IMAGE_HTML;
    }

    // Replacements
    fileContents = fileContents.replace(/{width}/gi, width.toString());
    fileContents = fileContents.replace(/{height}/gi, height.toString());
    fileContents = fileContents.replace(/{type}/gi, fileType);
    fileContents = fileContents.replace(/{name}/gi, file.name);
    fileContents = fileContents.replace(/{source}/gi, b64);
    fileContents = fileContents.replace(/{url}/gi, targetUrl);

    // Timestamp
    const currentDate = new Date();
    fileContents =
      `<!-- Generated: ${currentDate.getUTCDate()}/${
        currentDate.getUTCMonth() + 1
      }/${currentDate.getUTCFullYear()} -->\n` + fileContents;

    const blob = new Blob([fileContents], { type: "text/html" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    let name = file.name;
    const extensionIndex = name.lastIndexOf(".");
    if (extensionIndex > -1) {
      name = name.substring(0, extensionIndex);
    }
    a.download = name + "_banner.html";

    if (blob.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      throw new GeneratorError(
        `Output file size can not exceed ${MAX_FILE_SIZE_MB}MB, please reduce video size or try recompression...`,
        [file]
      );
    }

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    setDownloading((prev) => prev.filter((f) => f !== original));
  };

  const formik = useFormik<Values>({
    initialValues: {
      media: [],
      url: "",
      width: SIZE_PRESETS[0][0],
      height: SIZE_PRESETS[0][1],
    },
    onSubmit: async (values, { setSubmitting }) => {
      const promises: Promise<void>[] = [];
      for (const file of values.media) {
        if (downloading.includes(file)) {
          continue;
        }

        promises.push(
          exportFile(file, values.width, values.height, values.url)
        );
      }

      await Promise.allSettled(promises);

      setSubmitting(false);
    },
  });

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();

    if (!event.dataTransfer) {
      return;
    }

    event.dataTransfer.dropEffect = "copy";
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();

    if (!event.dataTransfer) {
      return;
    }

    formik.setFieldValue("videos", [
      ...formik.values.media,
      ...Array.from(event.dataTransfer.files),
    ]);
  };

  useEffect(() => {
    document.addEventListener("dragover", onDragOver);
    document.addEventListener("drop", onDrop);

    return () => {
      document.removeEventListener("dragover", onDragOver);
      document.removeEventListener("drop", onDrop);
    };
  }, []);

  const onPresetClose = () => {
    setAnchorEl(null);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          mb={2}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              type="number"
              variant="standard"
              placeholder="Width"
              {...formik.getFieldProps("width")}
            />
            x
            <TextField
              type="number"
              variant="standard"
              placeholder="Height"
              {...formik.getFieldProps("height")}
            />
            <IconButton
              size="small"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <PresetIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={onPresetClose}
            >
              {SIZE_PRESETS.map(([width, height]) => (
                <MenuItem
                  key={`${width}x${height}`}
                  onClick={() => {
                    formik.setFieldValue("width", width);
                    formik.setFieldValue("height", height);
                    onPresetClose();
                  }}
                >
                  {width}x{height}
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <TextField
            variant="standard"
            placeholder="Target URL"
            {...formik.getFieldProps("url")}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Media</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formik.values.media.map((media, index) => (
                <TableRow key={`${index}-${media.name}`}>
                  <TableCell>{media.name}</TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() =>
                          exportFile(
                            media,
                            formik.values.width,
                            formik.values.height,
                            formik.values.url
                          )
                        }
                        disabled={
                          downloading.includes(media) ||
                          isNaN(formik.values.width) ||
                          formik.values.width < 1 ||
                          isNaN(formik.values.height) ||
                          formik.values.height < 1
                        }
                      >
                        Download
                      </Button>

                      <IconButton
                        color="error"
                        onClick={() => {
                          const media = [...formik.values.media];
                          media.splice(index, 1);
                          formik.setFieldValue("media", media);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: "right" }}>
                  <Button onClick={() => inputRef.current?.click()}>
                    Add Media
                  </Button>
                  <input
                    ref={inputRef}
                    type="file"
                    hidden
                    multiple
                    accept={[
                      "video/mp4",
                      "image/png",
                      "image/gif",
                      "image/jpeg",
                      "image/jpg",
                      "image/bmp",
                      "image/webp",
                    ].join(",")}
                    onChange={(event) => {
                      formik.setFieldValue("media", [
                        ...formik.values.media,
                        ...Array.from(inputRef.current?.files ?? []),
                      ]);

                      setTimeout(() => (event.target.value = ""), 0);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box textAlign="right" mt={2}>
          <Button
            type="submit"
            variant="contained"
            disabled={
              formik.values.media.length < 1 ||
              isNaN(formik.values.width) ||
              formik.values.width < 1 ||
              isNaN(formik.values.height) ||
              formik.values.height < 1 ||
              formik.isSubmitting
            }
          >
            Download All
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}
