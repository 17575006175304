import React from "react";
import { alpha, Tooltip, Typography, useTheme } from "@mui/material";
import { Flex } from "../Flex";

type FieldLabelProps = {
  serverName: string;
  displayName: string;
};

export const FieldLabel = ({ serverName, displayName }: FieldLabelProps) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center">
      <Tooltip
        title={
          <>
            <strong>Server Name</strong> {serverName}
          </>
        }
      >
        <Typography
          sx={{
            textDecoration: "underline",
            opacity: 0.8,
            textDecorationStyle: "dashed",
            textUnderlineOffset: "0.2rem",
            textDecorationColor: alpha(theme.palette.text.primary, 0.4),
            transition: ".4s",
            "&:hover": {
              textDecorationColor: alpha(theme.palette.text.primary, 1),
            },
          }}
        >
          {displayName}
        </Typography>
      </Tooltip>
    </Flex>
  );
};
