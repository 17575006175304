import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface LayoutState {
  keepSidebarOpen: boolean;
}

const initialState: LayoutState = {
  keepSidebarOpen: true,
};

export const accountSettingsSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setKeepSidebarOpen: (state, { payload }) => {
      state.keepSidebarOpen = payload;
    },
  },
});

export const { setKeepSidebarOpen } = accountSettingsSlice.actions;

export const selectKeepSidebarOpen = (state: RootState) =>
  state.layout.keepSidebarOpen;

export default accountSettingsSlice.reducer;
