import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Checkbox,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { ThemeProvider } from "styled-components";
import { Flex } from "../../components/Flex";
import {
  serverTimeFormat,
  TooltipState,
  ValueType,
} from "../../model/versioned-settings.model";
import ExpandableSetting from "../../features/AddToAll/components/Setting/Setting";
import { StyledExpandableSetting } from "../AddToAll/components/Setting/SettingsContainer";
import { darkTheme } from "../AddToAll/styles/DarkTheme";
import { lightTheme } from "../AddToAll/styles/LightTheme";
import { lilacTheme } from "../AddToAll/styles/LilacTheme";
import { selectDarkMode, selectGameEditions } from "../general/generalSlice";
import { FETCH_GAME_EDITIONS } from "../general/generalThunks";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import {
  selectError,
  selectGameWizardRules,
  updateGameWizardRules,
  setTooltip,
  updateGameWizardRulesVersion,
  deleteGameWizardRulesVersion,
} from "./gameWizardRulesFormSlice";
import { AutoCompleteSelect } from "../AddToAll/components/AutocompleteSelect/AutoCompleteSelect";
import { selectDropdowns, selectLoading } from "./gameWizardRulesFormSlice";
import {
  fetchConfig,
  fetchGameVersions,
  fetchModelTemplateGroups,
  fetchRules,
  fetchUsers,
  saveRules,
} from "./gameWizardRulesFormThunks";
import { useAppStoreAccounts } from "../../network/useAppStoreAccounts";
import { IAccount } from "../../model/game-app.model";

const GameWizardRulesForm = () => {
  const dispatch = useDispatch();
  const [platformToAdd, setPlatformToAdd] = useState<any | undefined>();

  const dropdowns = useSelector(selectDropdowns);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const rules = useSelector(selectGameWizardRules);

  const gameEditions = useSelector(selectGameEditions);

  const darkMode = useSelector(selectDarkMode);
  const isDarkMode = darkMode === "dark";
  const isLilacMode = darkMode === "lilac";

  const users = dropdowns?.users as string[];
  const usersValue = rules?.allowed_admin_users;

  const countries = dropdowns?.config?.settings?.countries;
  const countriesValue = rules?.countries;

  const gameEditionVersions = dropdowns.game_apps;
  const gameEditionVersionsValue = rules?.edition_versions;

  const { appStoreAccounts, isLoading } = useAppStoreAccounts();

  const appStoreAccountsMapped = appStoreAccounts?.map((i) => ({
    ...i,
    game_edition_id:
      i.game_edition_id === "google" ? "android" : i.game_edition_id,
  }));

  const modelTemplateIDs = dropdowns.modelTemplateGroupsIds;
  const modelTemplateIDsValue = modelTemplateIDs?.filter((template: any) =>
    rules?.model_templates_groups_ids.includes(template.value)
  );

  const [hideTooltips, setHideTooltips] = useState(true);

  const topLevelSetting = {
    __value__: rules?.nested_settings ?? {},
    __user__: "no-one",
    __type__: ValueType.OBJECT,
    __modified__: "",
    __tag__: "",
    __description__: "",
  };

  useEffect(() => {
    const fetchAll = () => {
      dispatch(fetchRules());
      dispatch(fetchUsers());
      dispatch(fetchGameVersions());
      dispatch(fetchConfig());
      dispatch(fetchModelTemplateGroups());
      dispatch(FETCH_GAME_EDITIONS());
    };

    fetchAll();
  }, [dispatch]);

  const onChange = (
    selectedOption: any,
    rulesKey: string,
    edition?: string
  ) => {
    if (rulesKey === "game_edition_versions") {
      dispatch(
        updateGameWizardRulesVersion({
          game_edition_id: edition,
          value: selectedOption,
        })
      );
    } else {
      dispatch(
        updateGameWizardRules({
          key: rulesKey,
          value: selectedOption,
        })
      );
    }
  };

  const filterAppStoreAccounts = (stringValue?: string) => {
    const filteredAccounts = (appStoreAccountsMapped || []).filter(
      (obj) => obj.game_edition_id === stringValue
    );
    return filteredAccounts;
  };

  return loading.rules ? (
    <div className="App d-flex justify-content-center">
      <CircularProgress color="primary" />
    </div>
  ) : error.rules ? (
    <Alert color="error">{error.rules}</Alert>
  ) : (
    <Grid className="App">
      <div className="d-flex mb-3 justify-content-end">
        <Button
          onClick={async () => {
            const response = await dispatch(saveRules(rules));

            if (!response) {
              dispatch(
                queueNotification({
                  message: "Error saving rules",
                  options: {
                    key: "error",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            } else {
              dispatch(
                queueNotification({
                  message: "Rules saved successfully",
                  options: {
                    key: "success",
                    variant: SnackbarVariant.SUCCESS,
                  },
                })
              );
            }
          }}
          disabled={loading.savingRules}
          color="primary"
          startIcon={<FontAwesomeIcon icon={faSave} />}
          variant="contained"
        >
          Save
        </Button>
      </div>
      <h4>Rules</h4>
      <Grid container alignItems="center" m={2}>
        <Grid item xs={4}>
          <InputLabel>Allowed Admin Users</InputLabel>
        </Grid>
        <Grid item xs={8}>
          <AutoCompleteSelect
            multiselect
            disabled={loading.users || !!error.users}
            options={users}
            selected={usersValue ?? []}
            id="admin-users-dropdown"
            limitTags={10}
            onOptionSelected={(event, selectedOption) =>
              onChange(selectedOption, "allowed_admin_users")
            }
          />
          <Button onClick={() => onChange([...users], "allowed_admin_users")}>
            Select all
          </Button>
          <Box>
            <Typography gutterBottom>
              This array is the list of users that will have permissions added
              on new game creation, if a user is deleted from User Manager this
              will trigger a 404 and no user perms will be updated. Delete this
              user entry from this array to fix.
            </Typography>
            <Typography gutterBottom>
              Remove external users from this array or any user that shouldn't
              have access to any newly created Kwalee games and requires access
              on a game by game basis.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container alignItems="center" m={2}>
        <Grid item xs={4}>
          <InputLabel>Countries</InputLabel>
        </Grid>
        <Grid item xs={8}>
          <AutoCompleteSelect
            multiselect
            disabled={loading.config || !!error.config}
            options={countries ?? []}
            selected={countriesValue?.map((countryCode: string) =>
              countries?.find((country: any) => country.value === countryCode)
            )}
            id="countries-dropdown"
            limitTags={10}
            onOptionSelected={(event, selectedOption) =>
              onChange(
                selectedOption.map((option: { value: any }) => option.value),
                "countries"
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" m={2}>
        <Grid item xs={4}>
          <InputLabel>Platform Versions</InputLabel>
        </Grid>
        <Grid item xs={8}>
          {Object.keys(rules?.edition_versions ?? {}).map((edition: string) => {
            const gameEdition = gameEditions?.find(
              (currentEdition) => currentEdition.name === edition
            );
            return (
              <Box key={edition} sx={{ mb: 2 }}>
                <Grid container alignItems="center">
                  <Grid item xs={3}>
                    <InputLabel>
                      {gameEdition ? gameEdition.display_name : edition}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <Flex width="100%">
                      <AutoCompleteSelect
                        multiselect
                        id="versions-dropdown"
                        disabled={loading.game_apps || !!error.game_apps}
                        options={
                          (gameEditionVersions &&
                            gameEditionVersions[edition]) ??
                          []
                        }
                        selected={
                          gameEditionVersionsValue &&
                          gameEditionVersionsValue[edition]
                        }
                        limitTags={10}
                        onOptionSelected={(event, selectedOption) => {
                          onChange(
                            selectedOption,
                            "game_edition_versions",
                            edition
                          );
                        }}
                      />
                      <Button
                        color="error"
                        size="small"
                        onClick={() =>
                          dispatch(
                            deleteGameWizardRulesVersion({
                              game_edition_id: edition,
                            })
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </Flex>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="appstore-label">Appstore ID</InputLabel>
                      <Select
                        label="Appstore Id"
                        variant="outlined"
                        labelId="appstore-label"
                        id="appstore-select"
                        disabled={!appStoreAccountsMapped}
                        sx={{ width: "100%" }}
                        size="small"
                        value={rules?.edition_appstores[edition]}
                        onChange={(e) => {
                          onChange(
                            {
                              ...rules?.edition_appstores,
                              [edition]: e.target.value,
                            },
                            "edition_appstores"
                          );
                        }}
                      >
                        <MenuItem value="">None</MenuItem>
                        {(filterAppStoreAccounts(edition) ?? [])?.map(
                          (item: IAccount) => {
                            return (
                              <MenuItem
                                key={item?.object_id}
                                value={item?.object_id}
                              >
                                {item?.name.toUpperCase()}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Flex m={2}>
            <InputLabel sx={{ marginRight: 1 }}>Add Platform</InputLabel>
            <AutoCompleteSelect
              sx={{ width: "50%" }}
              id="platforms-dropdown"
              disabled={loading.game_apps || !!error.game_apps || !gameEditions}
              options={gameEditions
                ?.filter(
                  (edition) =>
                    !Object.keys(gameEditionVersionsValue ?? {}).includes(
                      edition.name
                    )
                )
                .map((edition) => ({
                  value: edition.name,
                  label: edition.display_name,
                }))}
              selected={platformToAdd}
              getOptionLabel={(type: any) => type && type.label}
              limitTags={10}
              onOptionSelected={(
                option: any,
                selected: any,
                newSelected: any
              ) => {
                setPlatformToAdd(newSelected);
              }}
            />

            <div className="my-2 d-flex justify-content-end">
              <Button
                disabled={!platformToAdd}
                onClick={() => {
                  dispatch(
                    updateGameWizardRulesVersion({
                      game_edition_id: platformToAdd?.value,
                      value: [],
                    })
                  );
                  setPlatformToAdd(undefined);
                }}
              >
                Add
              </Button>
            </div>
          </Flex>
        </Grid>
      </Grid>
      <Grid container alignItems="center" m={2}>
        <Grid item xs={4}>
          <InputLabel>Model Templates Groups</InputLabel>
        </Grid>
        <Grid item xs={8}>
          <AutoCompleteSelect
            multiselect
            id="template-groups-dropdown"
            disabled={loading.model_template_ids || !!error.model_template_ids}
            selected={modelTemplateIDsValue}
            options={modelTemplateIDs ?? []}
            limitTags={10}
            getOptionLabel={(option: { value: any; label: any }) =>
              `${option.value} - ${option.label}`
            }
            onOptionSelected={(event, selectedOption) =>
              onChange(
                selectedOption.map((option: { value: any }) => option.value),
                "model_templates_groups_ids"
              )
            }
          />
        </Grid>
      </Grid>
      <div className="mt-5 d-flex align-items-center">
        <h4>Default Game Settings</h4>
        Hide Tooltips
        <Checkbox
          checked={hideTooltips}
          onChange={() => setHideTooltips(!hideTooltips)}
        />
      </div>

      <ThemeProvider
        theme={isDarkMode ? darkTheme : isLilacMode ? lilacTheme : lightTheme}
      >
        <StyledExpandableSetting
          id="versioned-settings"
          onMouseLeave={() => dispatch(setTooltip(undefined))}
          darkMode={isDarkMode}
        >
          <ExpandableSetting
            key={"top-level"}
            name={"top-level"}
            topLevel
            setting={topLevelSetting}
            setTooltip={(tooltip?: TooltipState) =>
              dispatch(setTooltip(tooltip))
            }
            isVisible
            isEditing={true}
            updateSettings={(value) => {
              if (value !== undefined) {
                value = {
                  ...value,
                  __modified__: moment().format(serverTimeFormat),
                  __user__: localStorage.getItem("user_id")!,
                };
              }

              // @ts-ignore
              dispatch(
                updateGameWizardRules({
                  key: "nested_settings",
                  value: value?.__value__,
                })
              );
            }}
            isExpanded
            searchSettingsObject={undefined}
            turnOnEditing={() => {}}
            searchTerm={undefined}
            hideModifiedText={false}
            hideTooltips={hideTooltips}
          />
        </StyledExpandableSetting>
      </ThemeProvider>
    </Grid>
  );
};

export default GameWizardRulesForm;
