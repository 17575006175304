import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { getData } from "./request";

export function useCohortedTests() {
  const { data, error } = useSWR(Endpoints.COHORTED_TESTS.url, getData);

  return {
    cohortedTests:
      data &&
      (data as { [id: string]: any })[Endpoints.COHORTED_TESTS.object_path],
    isLoading: !error && !data,
    isError: error,
  };
}
