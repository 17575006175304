import { NestedSettings } from "./model/versioned-settings.model";
import {
  NestedSetting,
  VersionedSettings,
  VersionedSettingss,
} from "./model/versioned-settings.model";
import { getSettingsDiff } from "./features/AddToAll/helpers/getSettingsDiff";
import { mergeSettingsDiffs } from "./features/AddToAll/helpers/mergeSettingsDiffs";
import { odiffResult } from "odiff";
import { get, set, unset } from "lodash";

export const convertToTopLevelSetting = (settings?: VersionedSettings) => {
  if (!settings) {
    return;
  }

  return {
    __type__: "object",
    __value__: settings.nested_settings,
  };
};

export const generateCombinedSettings = (settings: any[]) => {
  //@ts-ignore
  let multiDiff: NestedSetting = {};
  const diffedSettingsIds: string[] = [];

  if (!settings) {
    return;
  }

  // If only one object exists
  if (settings.length === 1) {
    //@ts-ignore
    multiDiff = getSettingsDiff(
      //@ts-ignore
      convertToTopLevelSetting(settings[0]),
      convertToTopLevelSetting(settings[0]),
      settings[0].object_id,
      settings[0].object_id,
      true,
      diffedSettingsIds
    );

    diffedSettingsIds.push(settings[0].object_id);
  } else {
    // Compare object 1 with the first of object 2 to generate initial multiDiff
    //@ts-ignore
    multiDiff = getSettingsDiff(
      //@ts-ignore
      convertToTopLevelSetting(settings[0]),
      convertToTopLevelSetting(settings[1]),
      settings[0].object_id,
      settings[1].object_id,
      true,
      diffedSettingsIds
    );

    diffedSettingsIds.push(settings[0].object_id, settings[1].object_id);

    // Then for each objects diff with the multidiff and then add diff to multidiff
    settings.forEach((setting: VersionedSettings, index) => {
      if (index < 2) {
        return;
      }

      const diff = getSettingsDiff(
        //@ts-ignore
        multiDiff,
        //@ts-ignore
        convertToTopLevelSetting(setting),
        setting.object_id,
        setting.object_id,
        false,
        diffedSettingsIds
      );

      diffedSettingsIds.push(setting.object_id);

      multiDiff = mergeSettingsDiffs(multiDiff, diff);
    });
  }

  return multiDiff;
};

export const applyChangesToSettings = (
  settings: VersionedSettingss,
  changes: odiffResult[]
) => {
  return settings.map((setting: VersionedSettings) => {
    // Deep merge with changes object
    const newSetting = {
      ...setting,
      cas_token: setting._cas,
      id: setting.object_id,
      nested_settings: applyODiffChanges(changes, setting.nested_settings),
    };

    return newSetting;
  });
};

export const applyODiffChanges = (
  changes: odiffResult[],
  setting: NestedSettings
) => {
  let newSetting = { ...setting };

  // Loop through changes
  changes.forEach((change) => {
    // Generate lodash path
    const path = [...change.path];
    if (path[0] === "__value__") {
      path.shift();
    }

    // Switch statement for change type
    switch (change.type) {
      case "add":
      case "rm":
        // Get current array
        const array = get(newSetting, path);
        // modify
        if (change.type === "add") {
          array.splice(change.index, 0, ...change.vals);
        } else {
          array.splice(change.index, change.vals.length);
        }

        // Set value
        newSetting = JSON.parse(JSON.stringify(set(newSetting, path, array)));
        break;
      case "set":
        newSetting = JSON.parse(
          JSON.stringify(set(newSetting, path, change.val))
        );
        break;
      case "unset":
        unset(newSetting, path);
        break;
      default:
        break;
    }
  });

  return newSetting;
};
