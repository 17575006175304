import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { DialogWrapper } from "../../../components/DialogWrapper";
import {
  VersionedSettings,
  VersionedSettingss,
} from "../../../model/versioned-settings.model";
import { saveSelectedSettings } from "../addToAllThunks";
import { selectSettingIds } from "./DifferencesModal/DifferencesModal";

interface UnsavedSettingsProps {
  isOpen?: boolean;
  onClose: () => void;
  unsavedSettings: VersionedSettingss;
}

export const UnsavedSettingsDialog = ({
  isOpen,
  onClose,
  unsavedSettings,
}: UnsavedSettingsProps) => {
  const dispatch = useDispatch();
  const unsavedSettingsIds = unsavedSettings?.map(
    (setting: VersionedSettings) => setting.object_id
  );

  return (
    <DialogWrapper isOpen={!!isOpen} onClose={() => onClose()} size="sm">
      <DialogTitle>Warning: Unsaved settings</DialogTitle>
      <DialogContent>
        <Alert severity="error">
          <AlertTitle>Unsaved settings</AlertTitle>
          {unsavedSettings.map((setting: VersionedSettings) => (
            <>
              <small>{setting.object_id}</small>
              <br />
            </>
          ))}
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          className="ml-1"
          color="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            selectSettingIds(unsavedSettingsIds, dispatch);
            onClose();
          }}
        >
          Edit group
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(
              saveSelectedSettings({ selectedSettings: unsavedSettings })
            );
            onClose();
          }}
        >
          Retry
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
