import { Alert, Button } from "@mui/material";

type BrokenLevelReferenceProps = {
  id: string;
  index: number;
  removeLevelId: (index: number) => void;
};

export const BrokenLevelReference = ({
  id,
  index,
  removeLevelId,
}: BrokenLevelReferenceProps) => {
  return (
    <Alert
      severity="error"
      action={
        <Button
          onClick={() => removeLevelId(index)}
          color="inherit"
          size="small"
        >
          REMOVE
        </Button>
      }
    >
      {`Broken level reference: Level ${index + 1} (${id}) not found in levels`}
    </Alert>
  );
};
