import { Box, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Flex } from "../../components/Flex";
import { FieldType, FormItem } from "../../model/crud.model";
import { useGameEditions } from "../../network/useGameEditions";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import { useCategories } from "./hooks/useCategories";
import { useLevelTypes } from "./hooks/useLevelTypes";

export const defaultLevelTypes = { default: "Default" };
export const defaultCategories = { default: "Default" };

export const DefaultVersionedFields = () => {
  const [filters, setFilters] = useState<{
    [id: string]: any;
  }>({
    game_edition_id: ["ios", "android"],
    category_id: "default",
  });

  const levelTypes = useLevelTypes();
  const categories = useCategories();
  const { gameEditions } = useGameEditions();
  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const defaultVersionedFields: FormItem[] = [
    {
      main_field: true,
      display_name: "Version",
      name: "version",
      required: false,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.SELECT,
      options: gameEditionOptions,
      defaultValue: "",
    },
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: categories,
      defaultValue: "",
    },
    {
      display_name: "Is Active",
      name: "is_active",
      type: FieldType.CHECKBOX,
      defaultValue: false,
    },
  ];

  return defaultVersionedFields;
};
