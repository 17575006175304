import { SnapshotData } from "../../../model/log";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getData } from "../../../network/request";

export const fetchSnapshots = createAsyncThunk(
  "snapshots/fetch",
  async ({ logId, snapshotTypes }: SnapshotData) => {
    const response = await getData("data/snapshots", {
      log_id: logId,
      snapshot_types: JSON.stringify(snapshotTypes),
    });

    return {
      log_id: logId,
      data_snapshots: response.data_snapshots,
    };
  }
);
