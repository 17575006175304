import { TextareaAutosize } from "@mui/material";
import { Flex } from "../../../../components/Flex";
import { FieldType, Form, FormItem } from "../../../../model/crud.model";
import { useCategories } from "../../hooks/useCategories";
import { useLevelTypes } from "../../hooks/useLevelTypes";
import { styled } from "@mui/system";
import { useState } from "react";

export const displayNameTemplate = "{name}_{category_id}";

export const nestedKeys = ["category_id", "level_type"];

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  resize: "vertical",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
}));

interface CustomTextAreaFieldProps {
  value: any;
  onChangeFunction: (newValue: any) => void;
  fieldName: string;
}

const CustomTextAreaField = ({
  value,
  onChangeFunction,
  fieldName,
}: CustomTextAreaFieldProps) => {
  const [inputValue, setInputValue] = useState(JSON.stringify(value, null, 2));

  const handleBlur = () => {
    try {
      onChangeFunction({
        [fieldName]: JSON.parse(inputValue),
      });
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Flex>
      <StyledTextareaAutosize
        minRows={3}
        maxRows={10}
        value={inputValue}
        placeholder={`Enter ${fieldName}...`}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Flex>
  );
};

export const PuzzlesurvivalModel = () => {
  const levelTypes = useLevelTypes();
  const categories = useCategories();

  return [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Client ID",
      name: "client_id",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: categories,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Level Type",
      name: "level_type",
      type: FieldType.SELECT,
      options: levelTypes,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Level Data",
      name: "level_data",
      type: FieldType.CUSTOM,
      main_field: false,
      renderOverride: (value, onChangeFunction, fieldName) => (
        <CustomTextAreaField
          value={value}
          onChangeFunction={onChangeFunction}
          fieldName={fieldName}
        />
      ),
    },
    {
      display_name: "Stages",
      name: "stages",
      type: FieldType.COLLECTION,
      required: true,
      nestedModel: true,
      collectionModel: StageModal("stages"),
      getDisplayName: (obj) => obj.stage_client_id,
      page: "stages",
      defaultValue: [],
    },
  ] as FormItem[];
};

export const StageModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Stage Client ID",
      name: "stage_client_id",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
      parentField,
    },
    {
      display_name: "Rainbow",
      name: "rainbow",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Colors",
      name: "colors",
      type: FieldType.ARRAY,
      parentField,
      defaultValue: [],
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Color",
          name: "color",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          colors: value.colors.map((item: any) =>
            typeof item === "number" ? item : item.color
          ),
        };
      },
    },
    {
      display_name: "Moves",
      name: "moves",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Random",
      name: "random",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Random Goal Monochrome",
      name: "random_goal_monochrome",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Grid Data",
      name: "grid_data",
      type: FieldType.STRING,
      valueFunction(obj?) {
        return JSON.stringify(obj.grid_data);
      },
      defaultValue: 0,
      parentField,
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          grid_data: JSON.parse(value.grid_data),
        };
      },
    },
  ];

  return model;
};
