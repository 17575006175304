export default {
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  body1: {
    fontSize: 13,
  },
  h1: {
    fontSize: "2rem",
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 600,
    marginBottom: "1rem",
  },
  h3: {
    fontSize: "1.5rem",
  },
  h4: {
    fontSize: "1.125rem",
  },
  h5: {
    fontSize: "1.0625rem",
  },
  h6: {
    fontSize: "1rem",
  },
  p: {
    marginBottom: "1rem",
  },
} as const;
