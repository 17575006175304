import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IGameEdition } from "../../model/game-edition.model";
import { FETCH_COHORTED_TESTS, FETCH_GAME_EDITIONS } from "./generalThunks";

export interface GeneralState {
  darkMode: string;
  seenChangelogs: Record<string, boolean>;
  hideZeroElements?: boolean;
  loading: {
    games?: boolean;
    gameEditions?: boolean;
    cohortedTests?: boolean;
  };
  gameEditions?: IGameEdition[];
  whatsNewOpen: boolean;
  showAllChangelogs: boolean;
  loadingIFrame: boolean;
  currentPlatform?: string;
}

const initialState: GeneralState = {
  darkMode: "dark",
  hideZeroElements: false,
  loading: {},
  seenChangelogs: {},
  whatsNewOpen: true,
  showAllChangelogs: false,
  loadingIFrame: true,
};

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setHideZeroElements: (state, action) => {
      state.hideZeroElements = action.payload;
    },
    setWhatsNewOpen: (state, action) => {
      state.whatsNewOpen = !!action.payload;
    },
    setShowAllChangelogs: (state, action) => {
      state.showAllChangelogs = !!action.payload;
    },
    addToSeenChangelog: (state, action) => {
      action.payload.forEach((version: string) => {
        state.seenChangelogs[version] = true;
      });
    },
    setCurrentPlatform: (state, action) => {
      state.currentPlatform = action.payload;
    },
    setLoadingIFrame: (state, action) => {
      state.loadingIFrame = !!action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_GAME_EDITIONS.pending, (state) => {
      state.loading.gameEditions = true;
    });
    builder.addCase(FETCH_GAME_EDITIONS.fulfilled, (state, { payload }) => {
      //@ts-ignore
      state.gameEditions = payload;
      state.loading.gameEditions = false;
    });
    builder.addCase(FETCH_GAME_EDITIONS.rejected, (state) => {
      state.loading.gameEditions = false;
    });
    builder.addCase(FETCH_COHORTED_TESTS.pending, (state) => {
      state.loading.cohortedTests = true;
    });
    builder.addCase(FETCH_COHORTED_TESTS.fulfilled, (state, { payload }) => {
      //@ts-ignore
      state.cohortedTests = payload;
      state.loading.cohortedTests = false;
    });
    builder.addCase(FETCH_COHORTED_TESTS.rejected, (state) => {
      state.loading.cohortedTests = false;
    });
  },
});

export const {
  setDarkMode,
  setHideZeroElements,
  addToSeenChangelog,
  setWhatsNewOpen,
  setShowAllChangelogs,
  setLoadingIFrame,
  setCurrentPlatform,
} = generalSlice.actions;

export const selectDarkMode = (state: RootState) =>
  state.general.darkMode ?? "light";

export const selectSeenChangelogs = (state: RootState) =>
  state.general.seenChangelogs;

export const selectWhatsNewOpen = (state: RootState) =>
  state.general.whatsNewOpen;

export const selectShowAllChangelogs = (state: RootState) =>
  state.general.showAllChangelogs;

export const selectHideZeroElements = (state: RootState) =>
  state.general.hideZeroElements;

export const selectGameEditions = (state: RootState) =>
  state.general.gameEditions;

export const selectLoadingIFrame = (state: RootState) =>
  state.general.loadingIFrame;

export const selectCurrentPlatform = (state: RootState) =>
  state.general.currentPlatform;

export const selectLoadingGeneral = (state: RootState) => state.general.loading;

export default generalSlice.reducer;
