import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getPageName } from "./ConfirmDialog";
import { CrudKeys, selectIsSaving } from "../crudSlice";

interface AutoModalCloserProps {
  onClose: () => void;
  page: keyof CrudKeys;
}

export const AutoModalCloser = ({ onClose, page }: AutoModalCloserProps) => {
  const isSaving = useSelector(selectIsSaving);

  useEffect(() => {
    if (
      isSaving[getPageName(page)] !== undefined &&
      !isSaving[getPageName(page)]
    ) {
      onClose();
    }
  }, [isSaving, onClose, page]);

  return null;
};
