// Endpoints dependant on URL
import { CancelToken } from "axios";
import { store } from "../app/store";
import { axiosConfig } from "../data/AxiosConfig";

export interface RequestAdditions {
  interface_id: string;
  game_id: string;
  session_id: string;
  user_email: string;
}

export function getRequestAdditions(): RequestAdditions {
  const { game_id, session_id, user_id } = store.getState().session;

  return {
    interface_id: `game_admin_${game_id}`,
    game_id: game_id ?? "",
    session_id: session_id ?? "",
    user_email: user_id ?? "",
  };
}

export function getData<T = any>(
  uri: string,
  data?: {},
  cancelToken?: CancelToken,
  onDownloadProgress?: any,
  objectPath?: string
) {
  return axiosConfig
    .get<T>(uri, {
      params: {
        ...getRequestAdditions(),
        ...data,
      },
      cancelToken,
      onDownloadProgress,
    })
    .then((res: { data: { [id: string]: any } }) => {
      let data;
      if (objectPath) {
        data = res.data[objectPath];
      } else {
        data = res.data;
      }
      return data;
    });
}

export function postData<T = any>(
  uri: string,
  data: {} | FormData = {},
  objectPath?: string,
  config?: { [id: string]: any }
) {
  const params = new URLSearchParams();
  const additions = getRequestAdditions();
  let body: {} | FormData = params;
  if (data instanceof FormData) {
    body = data;
    for (const key in additions) {
      let val = (additions as any)[key];
      if (typeof val === "object") {
        val = JSON.stringify(val);
      }

      data.append(key, val);
    }
  } else {
    for (const key in data) {
      let val = (data as any)[key];
      if (typeof val === "object") {
        val = JSON.stringify(val);
      }

      params.append(key, val);
    }
    for (const key in additions) {
      let val = (additions as any)[key];
      if (typeof val === "object") {
        val = JSON.stringify(val);
      }

      params.append(key, val);
    }
  }

  return axiosConfig
    .post<T>(uri, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      ...config,
    })
    .then((res: { data: { [id: string]: any } }) => {
      let data;

      if (objectPath) {
        data = res.data[objectPath];
      } else {
        data = res.data;
      }
      return data;
    })
    .catch((e) => {
      throw e;
    });
}

export function deleteData<T = any>(
  uri: string,
  data?: {},
  cancelToken?: CancelToken
) {
  return axiosConfig
    .post<T>(uri, {
      params: {
        ...getRequestAdditions(),
        ...data,
      },
      cancelToken,
    })
    .then((res) => {
      return res.data;
    });
}
