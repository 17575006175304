import Banner from "./Banner";
import DAPI from "./DAPI";
import Mindworks from "./Mindworks";
import MRaid from "./MRaid";

export type AdType = keyof typeof AD_TYPE;

export const AD_TYPE = {
  dAPI: {
    name: "In App (dAPI) - e.g. IronSource",
    component: DAPI,
  },
  mRaid: {
    name: "In App (mRaid) - e.g. xPromo (Game Admin), AppLovin, Unity",
    component: MRaid,
  },
  mindworks: {
    name: "In App (Mindworks) - e.g. Mintegral",
    component: Mindworks,
  },
  banner: {
    name: "Banner (Google ClickTag)",
    component: Banner,
  },
} as const;
