import { FieldType, Form, FormItem } from "../../../../model/crud.model";
import { useCategories } from "../../hooks/useCategories";
import { useLevelTypes } from "../../hooks/useLevelTypes";

export const displayNameTemplate = "{name}_{category_id}";

export const nestedKeys = ["category_id", "level_type"];

export const TidymatchModel = () => {
  const levelTypes = useLevelTypes();
  const categories = useCategories();

  return [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Client ID",
      name: "client_id",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: categories,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Level Type",
      name: "level_type",
      type: FieldType.SELECT,
      options: levelTypes,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Levels",
      name: "levels",
      type: FieldType.COLLECTION,
      required: true,
      nestedModel: true,
      collectionModel: LevelModal("levels"),
      getDisplayName: (obj) => obj.level_time.toString(),
      page: "levels",
      defaultValue: [],
    },
  ] as FormItem[];
};

export const LevelModal = (parentField: string) => {
  return [
    {
      display_name: "Level Time",
      name: "level_time",
      type: FieldType.NUMBER,
      defaultValue: -1,
      parentField,
    },

    {
      display_name: "Stages",
      name: "stages",
      type: FieldType.COLLECTION,
      required: true,
      nestedModel: true,
      collectionModel: StageModal("stages"),
      getDisplayName: (obj) => obj.stage_client_id,
      page: "levels",
      parentField: "levels",
      defaultValue: [],
    },
  ] as FormItem[];
};

export const StageModal = (parentField: string) => {
  const model: Form = [
    {
      display_name: "Seed",
      name: "seed",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Number of Matches",
      name: "num_matches",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Number of Unique Items",
      name: "num_unique_items",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Number of Playable Front",
      name: "num_playable_front",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Number of Matches Front",
      name: "num_matches_front",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Mininum Paired Front",
      name: "min_paired_front",
      type: FieldType.NUMBER,
      defaultValue: 1,
      parentField,
    },
    {
      display_name: "Is Horizontal",
      name: "is_horizontal",
      type: FieldType.CHECKBOX,
      defaultValue: false,
      parentField,
    },
    {
      display_name: "Categories",
      name: "categories",
      type: FieldType.ARRAY,
      defaultValue: [],
      parentField,
    },

    {
      display_name: "Locked Match Requirements",
      name: "locked_match_requirements",
      type: FieldType.ARRAY,
      defaultValue: [],
      parentField,
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Locked Match Requirements",
          name: "locked_match_requirements",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          locked_match_requirements: value.locked_match_requirements.map(
            (item: any) =>
              typeof item === "number" ? item : item.locked_match_requirements
          ),
        };
      },
    },
    {
      display_name: "Mystery Objects",
      name: "mystery_objects",
      type: FieldType.ARRAY,
      defaultValue: [],
      parentField,
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Treasure Chest Item Percents",
          name: "mystery_objects",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          mystery_objects: value.mystery_objects?.map((item: any) =>
            typeof item === "number" ? item : item.mystery_objects
          ),
        };
      },
    },

    {
      display_name: "Treasure Chest Item Percents",
      name: "treasure_chest_item_percents",
      type: FieldType.ARRAY,
      defaultValue: [],
      parentField,
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Treasure Chest Item Percents",
          name: "treasure_chest_item_percents",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          treasure_chest_item_percents: value.treasure_chest_item_percents.map(
            (item: any) =>
              typeof item === "number"
                ? item
                : item.treasure_chest_item_percents
          ),
        };
      },
    },

    {
      display_name: "Hide Key Behind Locked",
      name: "hide_key_behind_locked",
      type: FieldType.CHECKBOX,
      defaultValue: false,
      parentField,
    },
    {
      display_name: "Number of Compartments",
      name: "num_compartments",
      type: FieldType.NUMBER,
      defaultValue: 12,
      parentField,
    },
    {
      display_name: "Back Row Fill",
      name: "back_row_fill",
      type: FieldType.NUMBER,
      defaultValue: 0,
      parentField,
    },
    {
      display_name: "Dispenser Requirements",
      name: "dispenser_requirements",
      type: FieldType.ARRAY,
      defaultValue: [],
      parentField,
      useAtomicValue: true,
      itemModel: [
        {
          useAtomicValue: true,
          display_name: "Dispenser Requirements",
          name: "dispenser_requirements",
          type: FieldType.NUMBER,
          defaultValue: 0,
        },
      ],
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          dispenser_requirements: value.dispenser_requirements.map(
            (item: any) =>
              typeof item === "number" ? item : item.dispenser_requirements
          ),
        };
      },
    },
  ];

  return model;
};
