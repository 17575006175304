import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";

export interface SettingsState {
  entities?: any;
  isInputFocused?: boolean;
}

const initialState: SettingsState = {};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    createSetting(state, action) {
      state.entities.settings[action.payload.id] = action.payload;
    },
    updateSetting(state, action) {
      state.entities.settings[action.payload.id] = action.payload;
    },
    deleteSetting(state, action) {
      delete state.entities.settings[action.payload.id];
    },
    setIsInputFocused: (state, action) => {
      state.isInputFocused = action.payload;
    },
  },
});

export const selectIsInputFocused = (state: RootState) =>
  state.setting.isInputFocused;

export const {
  createSetting,
  updateSetting,
  deleteSetting,
  setIsInputFocused,
} = settingSlice.actions;

export default settingSlice.reducer;
