import { store } from "../../app/store";
import Endpoints from "../../constants/endpoints";
import { logout } from "../../features/session/sessionSlice";
import { axiosConfig } from "../AxiosConfig";
import { AXIOS_VARIABLES, setPendingRequest } from "./variables";

/**
 * Axios Response Interceptor
 */
axiosConfig.interceptors.response.use(
  function (response) {
    setPendingRequest(Math.max(0, AXIOS_VARIABLES.PENDING_REQUESTS - 1));
    return Promise.resolve(response);
  },
  function (error) {
    setPendingRequest(Math.max(0, AXIOS_VARIABLES.PENDING_REQUESTS - 1));

    const { status } = error.response ?? {};

    if (
      (status === 403 &&
        !!store.getState().session.session_id &&
        !error.config.url.startsWith("optimus/")) ||
      (status === 403 && error.config.url.startsWith(Endpoints.CONFIG.url))
    ) {
      // @ts-ignore
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);
