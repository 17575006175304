import { store } from "../../app/store";
import { setSaving } from "../../components/CollectionEditor/crudService";
import { Endpoint } from "../../model/types";
import { ICampaign } from "../XpromoCampaigns/xpromo-campaign.model";
import { postData } from "../../network/request";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";

export function deleteCreative(url: string, obj: any, campaigns: ICampaign[]) {
  // If creative is used in a campaign output error
  if (
    campaigns?.some((campaign) => campaign.xpromo_creative_id === obj.object_id)
  ) {
    const campaignsUsedIn = campaigns?.filter(
      (campaign) => campaign.xpromo_creative_id === obj.object_id
    );

    campaignsUsedIn?.forEach((campaign) => {
      // Output error
      store.dispatch(
        queueNotification({
          message: `Creative is currently used in ${campaign.name} ${campaign.object_id}, please delete this campaign before deleting`,
          options: {
            key: `delete_error_${campaign.object_id}`,
            variant: SnackbarVariant.ERROR,
          },
        })
      );
    });

    return Promise.reject();
  } else {
    const savingUrl = url.split("/")[1].replace("/", "");
    setSaving(savingUrl, true);

    return postData(url)
      .then((response) => {
        setSaving(savingUrl, false);

        store.dispatch(
          queueNotification({
            message: "Deleted Successfully",
            options: {
              key: "delete_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );

        setSaving(savingUrl, undefined);

        return response;
      })
      .catch((err) => {
        setSaving(savingUrl, false);

        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "delete_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );

        setSaving(savingUrl, undefined);

        return err;
      });
  }
}

export async function deleteCreatives(
  endpoint: Endpoint,
  arr: any[],
  campaigns: ICampaign[]
) {
  const serverValues = [];
  for (const obj of arr) {
    setSaving(endpoint.object_path, true);

    const response = await deleteCreative(
      `${endpoint.url}/${obj.object_id}/delete`,
      obj,
      campaigns
    )
      .then((response) => {
        return response[endpoint?.object_path ?? ""];
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "create_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );

        setSaving(endpoint.object_path, undefined);
      });

    serverValues.push(response);
  }
  setSaving(endpoint.object_path, false);

  store.dispatch(
    queueNotification({
      message: "Multiple Items Deleted Successfully",
      options: {
        key: "update_notification",
        variant: SnackbarVariant.SUCCESS,
      },
    })
  );

  setSaving(endpoint.object_path, undefined);

  return serverValues;
}
