import { Alert, Tooltip, Typography, AlertTitle } from "@mui/material";
import React, { useState } from "react";
import { mutate } from "swr";
import {
  createItem,
  deleteItem,
  deleteItems,
  deployItem,
  updateItem,
} from "../../../components/CollectionEditor/crudService";
import { ItemModal } from "../../../components/CollectionEditor/Modals/ItemModal";
import { ParentCollapseEditor } from "../../../components/CollectionEditor/ParentCollapseEditor";
import Endpoints from "../../../constants/endpoints";
import { FieldType, StatusBadge } from "../../../model/crud.model";
import { ICreative } from "../../XpromoCreatives/xpromo-creative.model";
import { useCampaigns } from "../../../network/useCampaigns";
import { useConfig } from "../../../network/useConfig";
import { useCreatives } from "../../../network/useCreatives";
import { useGameEditions } from "../../../network/useGameEditions";
import { getEndpointData } from "../../../utils/getEndpointData";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { useEndpointAsOptions } from "../../../utils/useEndpointAsOptions";
import { XpromoCreativesModel } from "../../XpromoCreatives/XpromoCreativesModel";
import {
  isCampaignError,
  isCampaignExpired,
  isCampaignPending,
  isCampaignRunning,
  isCampaignWarning,
} from "../campaignStatus";
import { XpromoCampaignsModel } from "../XpromoCampaignsModel";
import WarningIcon from "@mui/icons-material/Warning";
import { ICampaign } from "../xpromo-campaign.model";
import { useHistory } from "react-router-dom";

interface CampaignProps {
  campaignId?: string | undefined;
}

export const CampaignsList = (props: CampaignProps) => {
  const { campaigns } = useCampaigns();
  const { creatives } = useCreatives();
  const history = useHistory();

  const { config } = useConfig();
  const { gameEditions } = useGameEditions();

  const [creativesModalOpen, setCreativesModalOpen] = useState<
    string | undefined
  >();

  const [campaignModalOpen, setCampaignModalOpen] = useState<
    string | undefined
  >(props?.campaignId);

  const games = config?.games_available as Record<string, string> | undefined;
  const adTypes = config?.promotion_creative.ad_type;

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const statusFunctions = {
    pending: isCampaignPending,
    running: isCampaignRunning,
    expired: isCampaignExpired,
    error: isCampaignError,
    warning: isCampaignWarning,
  };

  const statusValues = {
    pending: "Pending",
    running: "Running",
    expired: "Expired",
    error: "Error",
    warning: "Warning",
  };

  const initialNestedKeys = [
    {
      key: "target_game_id",
      values: games,
      functions: statusFunctions,
      functionsUsedForStatusBadges: true,
    },
    {
      key: "game_edition_id",
      values: gameEditionOptions,
      functions: statusFunctions,
      functionsUsedForStatusBadges: true,
    },
    {
      key: "ad_type",
      values: adTypes,
      functions: statusFunctions,
      functionsUsedForStatusBadges: true,
    },
    { key: "status", values: statusValues, functions: statusFunctions },
  ];

  const keyOverrides = {
    fieldName: "xpromo_creative_id",
    keysToOverride: ["target_game_id", "game_edition_id", "ad_type"],
    data: creatives ?? [],
    model: [
      {
        display_name: "Creative - Name",
        name: "name",
        type: FieldType.STRING,
      },
      {
        display_name: "Creative - File",
        name: "url",
        type: FieldType.STRING,
      },
    ],
  };

  const statusBadges: StatusBadge[] = [
    {
      key: "status",
      value: "warning",
      display_name: "Live test has failed QA status",
      backgroundColor: "rgba(182,170,46,0.51)",
      color: "white",
      renderBadge: () => (
        <Tooltip key="warning_badge" title={"Campaign with failed QA status"}>
          <WarningIcon />
        </Tooltip>
      ),
    },
    {
      key: "status",
      value: "running",
      display_name: "Running",
      backgroundColor: "rgba(56, 176, 56, 0.51)",
      color: "white",
    },
    {
      key: "status",
      value: "pending",
      display_name: "Pending",
      backgroundColor: "rgb(193, 150, 23)",
      color: "black",
    },
    {
      key: "status",
      value: "expired",
      display_name: "Expired",
      backgroundColor: "rgb(129, 26, 26)",
      color: "white",
    },
  ];

  const creativeFormModel = XpromoCreativesModel();
  const campaignFormModel = XpromoCampaignsModel({ setCreativesModalOpen });

  const failedRunningCampaigns = campaigns.filter(isCampaignWarning);

  return (
    <>
      {failedRunningCampaigns.length !== 0 && (
        <Alert sx={{ marginBottom: 2 }} severity="warning">
          <AlertTitle>
            The following running{" "}
            {failedRunningCampaigns.length === 1
              ? "campaign has"
              : "campaigns have"}{" "}
            a QA status of failed:
          </AlertTitle>
          {failedRunningCampaigns
            .sort((a, b) => a.name?.localeCompare(b.name ?? "") ?? 0)
            .map(({ object_id, name }) => (
              <Typography my={1} key={object_id}>
                <strong>{name}</strong> {object_id}
              </Typography>
            ))}
        </Alert>
      )}

      {!!campaignModalOpen && (
        <ItemModal
          model={campaignFormModel}
          initialFormValue={campaigns.find(
            (campaign: ICampaign) => campaign.object_id === props?.campaignId
          )}
          isOpen={!!props?.campaignId}
          onClose={() => {
            setCampaignModalOpen(undefined);
          }}
          endpoint={Endpoints.XPROMO_CAMPAIGNS}
          updateItem={async (obj) => {
            const object: any = (await updateItem(
              Endpoints.XPROMO_CAMPAIGNS,
              obj
            )) as any;
            if (isNullOrUndefined(object)) {
              return;
            }
            const newArray = [...campaigns];
            const index = newArray.findIndex(
              (item) => item.object_id === obj.object_id
            );
            newArray[index] = object;
            const { url, objectPath } = getEndpointData(
              Endpoints.XPROMO_CAMPAIGNS,
              "xpromo_campaigns"
            );

            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: newArray,
                };
              },
              false
            );
          }}
          page="xpromo_campaigns"
        />
      )}

      <ParentCollapseEditor
        page="xpromo_campaigns"
        nestedKeys={initialNestedKeys}
        allNestedKeys={initialNestedKeys}
        model={campaignFormModel}
        response={campaigns}
        keyOverrides={keyOverrides}
        endpoint={Endpoints.XPROMO_CAMPAIGNS}
        updateItem={updateItem}
        deleteItem={deleteItem}
        deleteItems={deleteItems}
        createItem={createItem}
        deployItem={deployItem}
        statusBadges={statusBadges}
      />
      {creativesModalOpen && (
        <ItemModal
          model={creativeFormModel}
          initialFormValue={creatives.find(
            (creative: ICreative) => creative.object_id === creativesModalOpen
          )}
          isOpen={!!creativesModalOpen}
          onClose={() => setCreativesModalOpen(undefined)}
          updateItem={async (obj) => {
            const object: any = (await updateItem(
              Endpoints.XPROMO_CREATIVES,
              obj
            )) as any;
            if (isNullOrUndefined(object)) {
              return;
            }
            const newArray = [...creatives];
            const index = newArray.findIndex(
              (item) => item.object_id === obj.object_id
            );
            newArray[index] = object;
            const { url, objectPath } = getEndpointData(
              Endpoints.XPROMO_CREATIVES,
              "xpromo_creatives"
            );

            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: newArray,
                };
              },
              false
            );
          }}
          page="xpromo_creatives"
          createItem={(obj) => {
            createItem(Endpoints.XPROMO_CREATIVES, obj);
          }}
        />
      )}
    </>
  );
};
