import { capitalize } from "@mui/material";
import {
  ReactSelectArray,
  VersionedSettings,
} from "../model/versioned-settings.model";

export const createSettingTitle = (
  settings?: VersionedSettings,
  countries?: ReactSelectArray
) => {
  if (!settings) {
    return;
  }

  return `${
    settings?.game_edition_id === "ios"
      ? "iOS"
      : capitalize(settings?.game_edition_id ?? "")
  }, ${capitalize(settings?.max_version ?? "")}, ${
    countries?.find(
      (country) => country.value === settings?.country.toUpperCase()
    )?.label ?? settings?.country.toUpperCase()
  }`;
};
