import isNullOrUndefined from './isNullOrUndefined';
import { unique } from './unique';

const matches = (
  a: any,
  b: any,
  options: Partial<{
    typeCoercion: boolean;
    fuzzyComparison: boolean;
    caseInsensitive: boolean;
  }> = {},
) => {
  const {
    typeCoercion = false,
    fuzzyComparison = false,
    caseInsensitive = false,
  } = options;

  const aType = typeof a;
  const bType = typeof b;
  if (!typeCoercion && aType !== bType) {
    // If we aren't doing type coercion, don't let different types match
    return false;
  }

  if (aType === 'object') {
    // Objects need to be the same type for comparison
    if (aType !== bType) {
      return false;
    }

    if (Array.isArray(a)) {
      // Check array length, if one is larger than the other, they don't match
      const aArr = a as any[];
      let bArr = b as any[];
      if (aArr.length !== bArr.length) {
        return false;
      }

      // Fuzzy comparison compares arrays without ordering
      if (fuzzyComparison) {
        // Clone b array so we don't mutate the object
        bArr = [...bArr];

        // Iterate a values
        for (const val of aArr) {
          // Find a value in b
          const index = bArr.findIndex(v => matches(val, v, options));
          if (index < 0) {
            // Not found, no match
            return false;
          }

          // Remove value from b
          bArr.splice(index, 1);
        }

        // If any values remaining in b, arrays didn't contain the same objects
        if (bArr.length > 0) {
          return false;
        }
      } else {
        // Compare array 1:1
        if (!aArr.every((val, index) => matches(val, b[index], options))) {
          return false;
        }
      }
    } else if (isNullOrUndefined(a)) {
      // eslint-disable-next-line eqeqeq
      if (typeCoercion ? a != b : a !== b) {
        return false;
      }
    } else {
      // Must be an object
      if (
        ![...Object.keys(a), ...Object.keys(b)]
          .filter(unique)
          .every(key => matches(a[key], b[key], options))
      ) {
        return false;
      }
    }
  } else {
    let aVal = a;
    let bVal = b;

    // Turn strings to lowercase if case insensitive
    if (caseInsensitive) {
      if (aType === 'string') {
        aVal = aVal.toLowerCase();
      }

      if (bType === 'string') {
        bVal = bVal.toLowerCase();
      }
    }

    // Compare values
    // eslint-disable-next-line eqeqeq
    if (typeCoercion ? aVal != bVal : aVal !== bVal) {
      return false;
    }
  }

  // Values must match
  return true;
};

export default matches;
