import { FormItem } from "../model/crud.model";

export const createDefaultObject = (
  model: FormItem[],
  defaultValueOverrides?: { [id: string]: any }
) => {
  const defaultObj: { [id: string]: any } = {};
  model.forEach((field) => {
    if (
      !field.notAvailableOnCreate &&
      (field.hasOwnProperty("defaultValue") || field.required)
    ) {
      if (
        defaultValueOverrides &&
        defaultValueOverrides.hasOwnProperty(field.name)
      ) {
        defaultObj[field.name] = defaultValueOverrides[field.name];
      } else {
        defaultObj[field.name] = field.defaultValue;
      }
    }
  });

  return defaultObj;
};
