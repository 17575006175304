import { Box, Button } from "@mui/material";
import React from "react";
import { FormItem } from "../../../model/crud.model";
import { Flex } from "../../Flex";

export const ButtonField = ({
  valueProp,
  onChange,
  options,
}: {
  field: FormItem;
  valueProp: string;
  onChange: any;
  options: { [id: string]: string };
}) => {
  return (
    <Flex alignItems="center">
      <small>{options[valueProp]}</small>
      <Box ml={2}>
        <Button
          size="small"
          onClick={() => {
            onChange();
          }}
          variant="contained"
        >
          Change
        </Button>
      </Box>
    </Flex>
  );
};
