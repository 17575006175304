import { NestedSetting, ValueType } from "../model/versioned-settings.model";

export const checkIfCollection = (
  setting?: NestedSetting
): setting is NestedSetting<ValueType.ARRAY | ValueType.OBJECT> => {
  if (!setting) {
    return false;
  }

  switch (setting.__type__) {
    case ValueType.OBJECT:
    case ValueType.ARRAY:
      return true;
    default:
      return false;
  }
};
