import { LoadingButton } from "@mui/lab";
import { Divider, Typography, Grid, Box, Chip } from "@mui/material";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../../components/Footer";
import { getEnvironmentName } from "../../data/AxiosConfig";
import logo from "../../img/Kwalee-All-Black-Logo.png";
import { Layout } from "../../layout/Layout";
import meta from "../../meta";
import backgroundShape1 from "../../img/background_shape_1.svg";
import backgroundShape2 from "../../img/background_shape_2.svg";
import { login, selectSession } from "../session/sessionSlice";
import { Flex } from "../../components/Flex";
import { selectDarkMode } from "../general/generalSlice";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { Formik, Form, Field } from "formik";

import * as yup from "yup";

import { FieldProps, getIn } from "formik";
import { TextFieldProps, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";

export const FormTextField: React.FC<FieldProps & TextFieldProps> = (props) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;

  return (
    <TextField
      variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      style={{ minWidth: 300 }}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      {...rest}
      {...field}
    />
  );
};

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

export function Login() {
  const theme = useSelector(selectDarkMode);
  const { isLoggingIn, session_id } = useSelector(selectSession);
  const { game_id } = useSelector(selectSession);
  const dispatch = useDispatch();

  const isDarkMode = theme === "dark";

  const history = useHistory();

  useEffect(() => {
    if (!!session_id) {
      history.push("/");
    }
  }, [history, session_id]);

  interface DarkModeProps {
    darkMode: boolean;
  }

  const StyledLogo = styled.img`
    filter: ${(props: DarkModeProps) => props.darkMode && "invert(1)"};
  `;

  const StyledBackgroundImg = styled.img`
    position: absolute;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 0;
  `;

  const onSubmit = (values: any) => {
    dispatch(login(values.email, values.password));
  };

  const currentEnv = getEnvironmentName();

  return (
    <Layout>
      <Flex height="100vh" justifyContent="flex-end" flexDirection="column">
        <Flex
          mx="auto"
          maxWidth="440px"
          height="80%"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Flex width="100%" alignItems="center" flexDirection="column">
            <StyledLogo
              darkMode={isDarkMode}
              src={logo}
              width="100"
              alt="Logo"
            />
            <Typography
              variant="h1"
              sx={{
                justifyContent: "center",
                textAlign: "center",
                fontSize: "3.5em",
                width: "100%",
                fontFamily: "aller",
                fontWeight: "bold",
                alignItems: "center",
              }}
            >
              {currentEnv === "Live" ? (
                <Chip
                  sx={{
                    height: "auto",
                    borderRadius: 2,
                    padding: 1,
                    marginBottom: 1,
                  }}
                  color="error"
                  icon={
                    <FiberManualRecordIcon
                      sx={{ animation: "flashing-blink 2s linear infinite" }}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: "2rem",
                        fontWeight: 400,
                        fontFamily: "aller",
                        letterSpacing: 1.5,
                        textTransform: "uppercase",
                      }}
                    >
                      Live
                    </span>
                  }
                />
              ) : (
                <Chip
                  sx={{
                    height: "auto",
                    borderRadius: 2,
                    padding: 1,
                    marginBottom: 1,
                  }}
                  color="primary"
                  label={
                    <span
                      style={{
                        fontSize: "2rem",
                        fontWeight: 400,
                        fontFamily: "aller",
                        letterSpacing: 1.5,
                        textTransform: "uppercase",
                      }}
                    >
                      UAT
                    </span>
                  }
                />
              )}{" "}
              Game Admin{" "}
            </Typography>
            <sup>v{meta.version}</sup>
            <Divider />
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values: FormValues) => {
                onSubmit(values);
              }}
            >
              {() => (
                <Form autoComplete="off">
                  <Grid container spacing={2} sx={{ mt: 5 }}>
                    <Grid item xs={12}>
                      <Field
                        name="email"
                        label="Email"
                        size="small"
                        type="text"
                        component={FormTextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="password"
                        label="Password"
                        type="password"
                        size="small"
                        component={FormTextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <LoadingButton
                          loading={isLoggingIn}
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!game_id}
                        >
                          Login
                        </LoadingButton>
                      </Box>

                      {!game_id && (
                        <Box mt={2} sx={{ textAlign: "center" }}>
                          <small>Please select a Game</small>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Flex>
          <Footer />
        </Flex>
        <StyledBackgroundImg src={backgroundShape1} alt="Background Shape 1" />
        <StyledBackgroundImg src={backgroundShape2} alt="Background Shape 2" />
      </Flex>
    </Layout>
  );
}
