import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { IPath, ISettingsConfig } from "./JanusSetting";

interface Props {
  janusSetting: ISettingsConfig;
}

const formatSettingPath = (path: IPath[]) => {
  return path.map((element, index) => (
    <Link
      key={index}
      color="inherit"
      href="#"
      onClick={(event) => {
        event.preventDefault();
        // Handle click event if needed
      }}
    >
      {element.node_key}
    </Link>
  ));
};

export const SettingPath = ({ janusSetting }: Props) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {janusSetting?.path && janusSetting?.path.length !== 0 ? (
        formatSettingPath(janusSetting?.path)
      ) : (
        <Typography color="textPrimary">No Setting Selected</Typography>
      )}
    </Breadcrumbs>
  );
};
