import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { selectSession } from "../../features/session/sessionSlice";
import { NavigationItem } from "../../model/navigation.model";
import { NavItem } from "./NavItem";

const StyledNavItems = styled(Box)`
  display: flex;
  flex: 1 1;
  min-height: 0;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

interface NavListProps {
  navItems?: NavigationItem[];
  expanded: boolean;
  isMobile: boolean;
  isSuperuser?: boolean;
  searchObject: any;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setIsExpanded: (bool: boolean) => void;
}

export const NavList = ({
  navItems,
  expanded,
  isMobile,
  isSuperuser,
  searchObject,
  searchTerm,
  setSearchTerm,
  setIsExpanded,
}: NavListProps) => {
  const { session_id } = useSelector(selectSession);
  const isLoggedIn = !!session_id;

  // Store page ids in local storage
  // Get page ids and generate nav items from nav routes
  // const favouritesItem = {
  //   id: "shortcuts",
  //   name: "My Shortcuts",
  //   icon: FaStar,
  //   component: LevelSetup,
  //   exact: true,
  //   elements: [
  //     {
  //       component: LevelSetup,
  //       exact: true,
  //       icon: FaFill,
  //       id: "level-setup",
  //       name: "Level Setup",
  //       path: "/levels/level-setup",
  //       subtitle: "Add, remove and modify levels for this game.",
  //     },
  //   ],
  // };

  return navItems ? (
    <StyledNavItems mt={2} display={isMobile && !expanded ? "none" : "flex"}>
      {isLoggedIn && (
        //   <NavItem
        //     isNavbarExpanded={expanded}
        //     searchObject={searchObject}
        //     searchTerm={searchTerm}
        //     //@ts-ignore
        //     item={favouritesItem}
        //     key={favouritesItem.id}
        //     setSearchTerm={setSearchTerm}
        //     setNavbarExpanded={setIsExpanded}
        //   />
        <>
          {navItems
            .filter((item: any) => !item.hidden)
            .filter((item: any) =>
              !item.requiresSuperuser ? true : isSuperuser
            )
            .map((item: any, index: number) => (
              <NavItem
                isNavbarExpanded={expanded}
                searchObject={
                  searchObject &&
                  searchObject
                    .sort((a: { name: string }, b: { name: any }) =>
                      a.name.localeCompare(b.name)
                    )
                    .filter((route: { hidden: any }) => !route.hidden)[index]
                }
                searchTerm={searchTerm}
                item={item}
                key={item.id}
                parentPath={item.path}
                setSearchTerm={setSearchTerm}
                setNavbarExpanded={setIsExpanded}
              />
            ))}
        </>
      )}
    </StyledNavItems>
  ) : (
    <p>No Nav Items</p>
  );
};
