import React, { useState } from "react";
import { FieldType, Form, TimeMetric } from "../../model/crud.model";
import { useConfig } from "../../network/useConfig";
import { useGameEditions } from "../../network/useGameEditions";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import { StatusModal } from "./StatusModal";

export const XpromoCreativesModel = () => {
  const { config } = useConfig();
  const { gameEditions } = useGameEditions();
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [formValue, setFormValue] = useState<
    { [id: string]: any } | undefined
  >();

  const games = config?.games_available;
  const sdkVersions = config?.kwalee_sdk_versions;
  const keys = config?.promotion_creative.key;
  const assetTypes = config?.promotion_creative.asset_type;
  const stageTypes = config?.promotion_creative.stage_type;
  const adTypes = config?.promotion_creative.ad_type;
  const orientations = config?.promotion_creative.orientation;
  const statusOptions = config?.promotion_creative.creative_status;

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const timerModel: Form = [
    {
      main_field: true,
      display_name: "Key",
      name: "key",
      type: FieldType.SELECT,
      defaultValue: "",
      options: keys,
      required: true,
      parentField: "timers",
    },
    {
      display_name: "Value",
      name: "value",
      type: FieldType.TIMEINPUT,
      defaultValue: null,
      parentField: "timers",
      required: true,
      defaultMetric: TimeMetric.MILLISECONDS,
    },
  ];

  const stageModel: Form = [
    {
      display_name: "File",
      name: "url",
      type: FieldType.FILEUPLOAD,
      defaultValue: "",
      parentField: "stages",
      fileUploadUrl: "admin/promotion-creatives/file-upload",
    },
    {
      main_field: true,
      display_name: "Asset Type",
      name: "asset_type",
      disabled: true,
      type: FieldType.SELECT,
      defaultValue: "",
      options: assetTypes,
      parentField: "stages",
      hidden: true,
    },
    {
      display_name: "Stage Type",
      name: "stage_type",
      type: FieldType.SELECT,
      defaultValue: "",
      required: true,
      options: stageTypes,
      parentField: "stages",
    },
    {
      display_name: "Timers",
      name: "timers",
      type: FieldType.COLLECTION,
      page: "xpromo_creatives",
      nestedModel: true,
      defaultValue: [],
      collectionModel: timerModel,
      parentField: "stages",
    },
  ];

  const creativeModel: Form = [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      type: FieldType.STRING,
      required: true,
      defaultValue: "",
    },
    {
      display_name: "Ad Type",
      name: "ad_type",
      type: FieldType.SELECT,
      options: adTypes,
      required: true,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Status",
      name: "status",
      type: FieldType.BUTTON_MODAL,
      notAvailableOnCreate: true,
      options: statusOptions,
      onChangeOverride: (update: any, formValue: { [id: string]: any }) => {
        setFormValue({ ...formValue, ...update });
        setStatusIsOpen(true);
      },
      modal: (setParentFormValue: any) => {
        return (
          <StatusModal
            page="xpromo_creatives"
            isOpen={statusIsOpen}
            onClose={() => setStatusIsOpen(false)}
            formValue={formValue}
            setParentFormValue={setParentFormValue}
          />
        );
      },
      defaultValue: "",
    },
    {
      display_name: "Target Game",
      name: "target_game_id",
      type: FieldType.SELECT,
      options: games,
      required: true,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.SELECT,
      options: gameEditionOptions,
      required: true,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Minimum SDK Version",
      name: "minimum_kwalee_sdk_version",
      type: FieldType.SELECT,
      options: sdkVersions,
      defaultValue: "",
    },
    {
      display_name: "Orientation",
      name: "orientation",
      type: FieldType.SELECT,
      options: orientations,
      defaultValue: "",
    },
    {
      display_name: "Stages",
      name: "stages",
      type: FieldType.COLLECTION,
      page: "xpromo_creatives",
      nestedModel: true,
      defaultValue: [],
      collectionModel: stageModel,
    },
  ];

  return creativeModel;
};
