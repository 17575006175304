export interface Endpoint {
  url: string;
  object_path: string;
  single_object_path?: string;
}

export default class Endpoints {
  static readonly GAME_EDITIONS: Endpoint = {
    url: "admin/game-editions",
    object_path: "game_editions",
  };
  // static readonly GAME_WIZARD: Endpoint = {
  //   url: "/admin/game-wizard",
  // };

  static readonly GAME_APP: Endpoint = {
    url: "admin/game-apps",
    object_path: "game_apps",
  };

  static readonly LEVELS: Endpoint = {
    url: "admin/{game_id}/levels",
    object_path: "{game_id}_levels",
    single_object_path: "{game_id}_level",
  };

  static readonly VERSIONED_LEVELS: Endpoint = {
    url: "admin/versioned-level-orders",
    object_path: "versioned_level_orders",
  };

  static readonly LEVEL_ORDER: Endpoint = {
    url: "admin/level-orders",
    object_path: "level_orders",
    single_object_path: "level_order",
  };

  static readonly USER_ACCOUNTS: Endpoint = {
    url: "admin/admin-users",
    object_path: "users",
    single_object_path: "users",
  };

  static readonly USER_PERMISSIONS: Endpoint = {
    url: "admin/permission-roles",
    object_path: "roles",
    single_object_path: "roles",
  };

  static readonly RESTRICTED_GROUP_MANAGERS: Endpoint = {
    url: "admin/analytics/restricted-group-managers",
    object_path: "restricted_event_group_managers",
    single_object_path: "restricted_event_group_manager",
  };

  static readonly ANALYTICS_EVENT: Endpoint = {
    url: "/admin/analytics/events",
    object_path: "sampling_events",
    single_object_path: "sampling_event",
  };

  static readonly ANALYTICS_EVENT_GROUP: Endpoint = {
    url: "/admin/analytics/event-groups",
    object_path: "event_groups",
    single_object_path: "event_group",
  };

  static readonly USER_REGISTRY: Endpoint = {
    url: "admin/endpoint-registry",
    object_path: "registry",
    single_object_path: "registry",
  };

  static readonly MENU_TREES: Endpoint = {
    url: "admin/menu-trees",
    object_path: "trees",
  };

  static readonly APP_STORE_ACCOUNTS: Endpoint = {
    url: "admin/app-store-account",
    object_path: "app_store_accounts",
    single_object_path: "app_store_account",
  };

  static readonly GAME_CONFIG: Endpoint = {
    url: "admin/config/game",
    object_path: "{game_id}_admin_game_id",
  };
  static readonly CLIENT_CONFIG: Endpoint = {
    url: "admin/client-config",
    object_path: "{game_id}_admin_game_id",
  };

  static readonly GAME_CREATION: Endpoint = {
    url: "admin/game-wizard",
    object_path: "game_creation",
    single_object_path: "game_creation",
  };

  static readonly GAME_CREATION_UPDATE: Endpoint = {
    url: "/admin/game",
    object_path: "game_creation",
    single_object_path: "game_creation",
  };

  static readonly COHORTED_TESTS: Endpoint = {
    url: "optimus/cohorted_tests",
    object_path: "cohorted_tests",
  };

  static readonly XPROMO_CREATIVES: Endpoint = {
    url: "admin/xpromo-creatives",
    object_path: "xpromo_creatives",
    single_object_path: "xpromo_creative",
  };

  static readonly XPROMO_CAMPAIGNS: Endpoint = {
    url: "admin/xpromo-campaigns",
    object_path: "xpromo_campaigns",
    single_object_path: "xpromo_campaign",
  };

  static readonly PROMO_CREATIVES_FILE: Endpoint = {
    url: "admin/promotion-creatives/file-upload",
    single_object_path: "url",
    object_path: "url",
  };

  static readonly CONFIG: Endpoint = {
    url: "admin/config",
    object_path: "config",
  };

  static readonly SETTINGS_CUTOFF: Endpoint = {
    url: "/admin/versioned-settings-version-cut-off",
    object_path: "versioned-settings-version-cut-off",
  };

  static readonly SOLITAIRE_AREAS: Endpoint = {
    url: "/admin/cassolitairehf/areas",
    object_path: "cassolitairehf_areas",
    single_object_path: "cassolitairehf_area",
  };

  static readonly SOLITAIRE_AREA_ORDERS: Endpoint = {
    url: "/admin/cassolitairehf/area-orders",
    object_path: "cassolitairehf_area_orders",
    single_object_path: "cassolitairehf_area_order",
  };

  static readonly BID_REDUCTION: Endpoint = {
    url: "/admin/global-margin",
    object_path: "global_margin",
  };

  static readonly DAILY_TASKS: Endpoint = {
    url: "admin/daily_tasks",
    object_path: "daily_tasks",
    single_object_path: "daily_task",
  };

  static readonly OBJECTIVES: Endpoint = {
    url: "admin/objectives",
    object_path: "objectives",
    single_object_path: "objective",
  };

  static readonly INVENTORY_ITEMS: Endpoint = {
    url: "admin/inventory-data",
    object_path: "inventory_items",
    single_object_path: "inventory_item",
  };

  static readonly CURRENCIES: Endpoint = {
    url: "admin/currency-data",
    object_path: "currencies",
    single_object_path: "currency",
  };

  static readonly LOGOUT: Endpoint = {
    url: "admin/logout",
    object_path: "session_id",
  };
  static readonly IMAGE_RESOURCES: Endpoint = {
    url: "admin/image-resources/",
    object_path: "image_resources",
  };
  static readonly LOCALISATION: Endpoint = {
    url: "admin/localisation/game/{game_id}",
    object_path: "{game_id}",
  };
  static readonly LOCALISATIONVERSIONMAP: Endpoint = {
    url: "admin/localisation/active-version-map/{game_id}",
    object_path: "{object_path}",
  };
  static readonly LOCALISATION_OBJECT: Endpoint = {
    url: "admin/localisation/{instance_id}",
    object_path: "{instance_id}",
  };
  static readonly LOCALISATION_OBJECT_GAMEID_VERSION: Endpoint = {
    url: "admin/localisation/version/{version}/{game_id}",
    object_path: "extra_data",
  };
  static readonly UPDATE_VERSION_MAP: Endpoint = {
    url: "admin/localisation/active-version-map/{game_id}/update",
    object_path: "{game_id}",
  };
}
