import { store } from "../app/store";
import { setGameId } from "../features/session/sessionSlice";

export const getContextUrl = () => {
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port
  );
};

export const getGameID = (game?: string) => {
  const game_id = store.getState().session.game_id;

  if (game) {
    store.dispatch(setGameId(game));
  }

  return game_id;
};
