import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import { deleteItem } from "../../components/CollectionEditor/crudService";
import { FormItem } from "../../model/crud.model";
import { ILevel } from "../../model/level.model";
import { Endpoint } from "../../model/types";
import { getEndpointData } from "../../utils/getEndpointData";
import { selectSession } from "../session/sessionSlice";
import { DraggableLevel } from "./components/DraggableLevel";
import { useGameModel } from "./hooks/useGameModel";

const useStyles = makeStyles(() => {
  return {
    container: ({
      expanded,
      unaddedLevelIds,
    }: {
      expanded?: boolean;
      unaddedLevelIds?: string[];
    }) => ({
      height: expanded
        ? "auto"
        : unaddedLevelIds?.length === 1
        ? "50px"
        : "100px",
      overflow: "hidden",
      maskImage: expanded
        ? "none"
        : "linear-gradient(to bottom, black 50%, transparent 100%)",
      position: "relative",
      zIndex: 9,
    }),
    button: {
      display: "inline-block",
      zIndex: 10,
      marginTop: "0.5rem",
      position: "absolute",
      left: "55.5%",
      transform: "translateX(-50%)",
    },
  };
});

type NotInLevelOrderLevelsProps = {
  unaddedLevelIds: string[];
  levelIds: string[];
  levels: ILevel[];
  addToLevelOrder: (
    ids: string[],
    versionId?: string,
    location?: number
  ) => void;
  modelOverride?: FormItem[];
  versionId?: string;
  searchTerm?: string;
  saveLevelOrder: (levelIds: string[]) => void;
  orderedLevelIds?: string[];
  page: string;
  endpoint: Endpoint;
  removeLevelId?: (id: string) => void;
  selected?: string[];
  setSelected?: (id: string) => void;
};

export const NotInLevelOrderLevels = ({
  unaddedLevelIds,
  levels,
  addToLevelOrder,
  modelOverride,
  saveLevelOrder,
  orderedLevelIds,
  page,
  endpoint,
  searchTerm,
  selected,
  setSelected,
  versionId,
}: NotInLevelOrderLevelsProps) => {
  const [expanded, setExpanded] = useState(true);

  const classes = useStyles({ expanded, unaddedLevelIds });

  const gameLevelModel = useGameModel();
  const modelToUse = modelOverride ? modelOverride : gameLevelModel;

  const { game_id: currentGameId } = useSelector(selectSession);
  const url = endpoint.url.replace("{game_id}", currentGameId ?? "");
  const newEndpoint = { ...endpoint, url };

  return (
    <>
      <Box id="not-in-order" mt="2rem" className={classes.container}>
        {unaddedLevelIds
          ?.sort((a: string, b: string) => b.localeCompare(a))
          ?.map((id: string, index: number) => {
            const level = levels?.find(
              (level: ILevel) => level.object_id === id
            );

            if (!level) {
              return null;
            }

            if (
              !!searchTerm &&
              !level.name.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return null;
            }

            return (
              <DraggableLevel
                selected={selected}
                setSelected={() => setSelected && setSelected(level.object_id)}
                notInOrder
                isDragDisabled
                addToLevelOrder={addToLevelOrder}
                saveLevelOrder={saveLevelOrder}
                modelOverride={modelToUse}
                key={level.object_id}
                id={level.object_id}
                level={level}
                index={index}
                versionId={versionId}
                levels={levels}
                orderedLevelIds={orderedLevelIds}
                page={page}
                endpoint={endpoint}
                deleteOverride={async (obj: any) => {
                  try {
                    const success = await deleteItem(
                      `${newEndpoint.url}/${obj.object_id}/delete`
                    );
                    if (success !== false) {
                      const newArray = [...levels];

                      const index = newArray.findIndex(
                        (item) => item.object_id === obj.object_id
                      );

                      newArray.splice(index, 1);
                      const { url, objectPath } = getEndpointData(
                        newEndpoint,
                        page,
                        currentGameId
                      );

                      mutate(
                        url,
                        async (prev: any) => {
                          return {
                            [objectPath]: newArray,
                          };
                        },
                        false
                      );
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }}
              />
            );
          })}
      </Box>
      <Button
        variant="outlined"
        size="small"
        className={classes.button}
        onClick={() => setExpanded(!expanded)}
      >
        Show {expanded ? "Less" : "More"}
      </Button>
    </>
  );
};
