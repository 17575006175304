import { IDatabaseObject } from "./database-object.model";

export const resourceUrl = "https://d2kre6881rwtik.cloudfront.net/";

export interface Endpoint {
  url: string;
  object_path: string;
  single_object_path?: string;
}

export interface INestedRoute {
  name: string;
  value: any;
}

export interface INestedKey {
  key: string;
  values: { [id: string]: any };
  functions?: { [id: string]: (obj: any) => boolean };
  functionsUsedForStatusBadges?: boolean;
}

export type INestedKeys = INestedKey[];

export interface IGameEdition extends IDatabaseObject {
  name: string;
  display_name: string;
}
