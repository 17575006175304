export const flattenObjectToArray = (objectToFlatten: {
  [id: string]: any;
}) => {
  let arr: { [id: string]: string }[] = [];

  Object.keys(objectToFlatten).forEach((key) => {
    const value = objectToFlatten[key];
    if (typeof value === "object" && value !== null) {
      arr = arr.concat(flattenObjectToArray(value));
    } else {
      arr.push({ [key]: value });
    }
  });

  return arr;
};
