import { Box, Button, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ReactNode } from "react";

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  confirmColor?: string;
  confirmContent?: ReactNode;
  denyColor?: string;
  denyContent?: ReactNode;
  children?: ReactNode;
  onClose?: () => void;
  onResolve?: (resolved: boolean) => void;
}

export const ConfirmationModal = (props: Props) => {
  const handleCancel = () => {
    const { onResolve } = props;

    if (onResolve) {
      onResolve(false);
    }
  };

  const handleConfirm = () => {
    const { onResolve } = props;

    if (onResolve) {
      onResolve(true);
    }
  };

  const handleClose = () => {
    const { onClose } = props;

    if (onClose) {
      onClose();
    }
  };

  const useStyles = makeStyles((theme: any) => {
    const isLightMode = theme.palette.mode === "light";
    return {
      modalContent: {
        background: isLightMode ? "#f8f8f8" : "#1B2635",
      },
      main: {
        position: "absolute",
        top: "30%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        border: "2px solid #000",
        backgroundColor: isLightMode ? "#EDEDED" : "#1B2635",
        padding: theme.spacing(2),
      },
      buttonGroup: {
        display: "flex",
        flexDirection: "row",
        paddingTop: theme.spacing(2),
      },
      button: {
        marginRight: theme.spacing(1),
      },
    };
  });

  const classes = useStyles();

  return (
    <Modal
      open={props.isOpen || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.main}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>
        <Typography id="modal-modal-description">{props.children}</Typography>

        <Box className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleConfirm}
          >
            {props.confirmContent || "Yes"}
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            {props.denyContent || "No"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
