import useSWR from "swr";
import { Endpoint } from "../model/types";
import { getData } from "./request";

type UseCollection<T> = {
  collection?: T[];
  isLoading: boolean;
  isError: boolean;
};

export function useCollection<T>(endpoint: Endpoint) {
  const { data, error } = useSWR(endpoint.url, getData, {
    revalidateOnFocus: false,
  });

  const collection: UseCollection<T> = {
    collection: data && (data as { [id: string]: any })[endpoint.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return collection;
}
