import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { PropsWithChildren, useMemo } from "react";
import { selectDarkMode } from "../features/general/generalSlice";
import { Notifier } from "../features/notification/Notifier";
import breakpoints from "./options/breakpoints";
import overrides from "./options/overrides";
import palette, { themePalettes } from "./options/palette";
import props from "./options/props";
import typography from "./options/typography";

const defaultOptions = {
  palette: {
    ...palette,
  },
  typography: {
    ...typography,
  },
  breakpoints: { values: { ...breakpoints.values } },
  props: {
    ...props,
  },
  overrides: {
    ...overrides,
  },
};

// Modify theme background color based on game settings platforms
export const platformBodyColors: { [id: string]: any } = {
  light: {
    android: "#e0edd4",
    ios: "#caf0fe",
    chinaios: "#e5b0b0",
  },
  dark: {
    android: "#354735",
    ios: "#0d3043",
    chinaios: "#522323",
  },
};

export default function EmotionTheme(props: PropsWithChildren<{}>) {
  const themeKey = useSelector(selectDarkMode);

  const theme = useMemo(() => {
    const currentTheme = themePalettes[themeKey ?? ""];

    const themeOptions = {
      palette: {
        ...currentTheme,
      },
    };

    return createTheme({
      ...defaultOptions,
      ...themeOptions,
    });
  }, [themeKey]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <CssBaseline />
        <Notifier />
        {props.children}
      </SnackbarProvider>
    </ThemeProvider>
  );
}
