import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSaving } from "../../../components/CollectionEditor/crudSlice";
import { AutoModalCloser } from "../../../components/CollectionEditor/Modals/AutoModalCloser";
import { DialogWrapper } from "../../../components/DialogWrapper";
import { VersionedSettings } from "../../../model/versioned-settings.model";
import {
  settingsSelector,
  setNumberOfSettingsToDelete,
  setDeletingSettings,
  setCurrentDeleteNumber,
} from "../addToAllSlice";
import { deleteGameSettings } from "../addToAllThunks";
import { SettingsSelector } from "./SettingsSelector";

interface DeleteVersionedSettingsProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const DeleteVersionedSettings = ({
  isOpen,
  onClose,
}: DeleteVersionedSettingsProps) => {
  const settings = useSelector(settingsSelector);
  const dispatch = useDispatch();
  const isSaving = useSelector(selectIsSaving);

  const [newValues, setFormValue] = useState<{
    game_edition_ids?: string[];
    country_codes?: string[];
    max_versions?: string[];
  }>({});

  const deleteSettings = async () => {
    if (settingsIdsToDelete?.length) {
      try {
        onClose();
        dispatch(setCurrentDeleteNumber(0));
        dispatch(setDeletingSettings(true));
        dispatch(setNumberOfSettingsToDelete(settingsIdsToDelete.length));

        const batchSize = 10;
        const delay = 100;

        for (let i = 0; i < settingsIdsToDelete.length; i += batchSize) {
          const batch = settingsIdsToDelete.slice(i, i + batchSize);

          await Promise.all(
            batch.map(async (settingId: any) => {
              await dispatch(deleteGameSettings(settingId));
            })
          );

          await new Promise((resolve) => setTimeout(resolve, delay));

          dispatch(setCurrentDeleteNumber(i + batch.length));
        }
      } catch (error) {
        console.error("Error deleting settings:", error);
      } finally {
        dispatch(setDeletingSettings(false));
      }
    }
  };

  const settingsIdsToDelete =
    settings
      ?.filter(
        (setting: VersionedSettings) =>
          newValues.game_edition_ids?.includes(setting.game_edition_id) &&
          newValues.max_versions?.includes(setting.max_version) &&
          newValues.country_codes?.includes(setting.country)
      )
      .map((setting: VersionedSettings) => setting.object_id) ?? [];

  return (
    <>
      <AutoModalCloser onClose={onClose} page="game_settings" />
      <DialogWrapper isOpen={!!isOpen} onClose={() => onClose()} size="sm">
        <DialogTitle>Delete Versioned Settings</DialogTitle>
        <DialogContent>
          <SettingsSelector
            newValues={newValues}
            setFormValue={(newValue) => setFormValue(newValue)}
            showSelectedSettings
          />
        </DialogContent>

        <DialogActions>
          <Button
            size="small"
            className="ml-1"
            color="secondary"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSaving.game_settings}
            size="small"
            variant="contained"
            color="primary"
            onClick={async () => {
              deleteSettings();
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </DialogWrapper>
    </>
  );
};
