import { MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
      cursor: "pointer",
    },
  },
}))(MenuItem);
