import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../../app/store";

import { getData, postData } from "../../network/request";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { IJanusSetting } from "./JanusSetting";
import { normaliseWeightedConfigs } from "./normaliseWeightedConfigs";

export const FETCH_JANUS_SETTINGS = createAsyncThunk(
  "janus_settings/FETCH",
  async () => {
    const response = await getData("admin/janus-settings");

    return response.janus_settings;
  }
);

export const ADD_JANUS_SETTING = createAsyncThunk(
  "janus_settings/ADD",
  async ({
    path,
    game_edition_id,
    status,
    country_setting_config,
    scope,
    game_id,
  }: any) => {
    return await postData("admin/janus-settings", {
      path: JSON.stringify(path),
      game_edition_id,
      status,
      country_setting_config,
      scope,
      game_id,
    });
  }
);

const getSettingById = (id: string, janusSettings?: IJanusSetting[]) => {
  return janusSettings?.find((setting) => setting.object_id === id);
};

export const SAVE_SETTINGS = createAsyncThunk(
  "janus-settings/SAVE",
  async ({
    janusSettings,
    changedSettings,
    newSettings,
    deletedSettings,
  }: {
    janusSettings?: IJanusSetting[];
    changedSettings: string[];
    newSettings: string[];
    deletedSettings: string[];
  }) => {
    // Remove any settings from all arrays if they appear in deleted settings and remove new settings from updated
    changedSettings = changedSettings.filter(
      (id) => !deletedSettings.includes(id) && !newSettings.includes(id)
    );
    newSettings = newSettings.filter((id) => !deletedSettings.includes(id));
    deletedSettings = deletedSettings.filter((id) => !id.includes("new"));

    const updatedJanusSettings: IJanusSetting[] = [];

    const normalisedSettings: IJanusSetting[] = [];

    // Normalise janus settings
    janusSettings?.forEach((setting) => {
      const normalisedCountryConfigs = setting.country_setting_config.map(
        (config) => ({
          ...config,
          weighted_values: normaliseWeightedConfigs(config.weighted_values),
        })
      );

      normalisedSettings.push({
        ...setting,
        country_setting_config: normalisedCountryConfigs,
      });
    });

    // Save new settings
    await Promise.all(
      newSettings.map(async (id) => {
        // Get janus setting by id
        const setting = getSettingById(id, normalisedSettings);
        // POST janus setting
        const newSetting = await postData("/admin/janus-settings", setting);

        // add to array
        updatedJanusSettings.push(newSetting.janus_settings);
      })
    );

    // Update changed settings
    await Promise.all(
      changedSettings.map(async (id) => {
        // Get janus setting by id
        const setting = getSettingById(id, normalisedSettings);

        //POST janus setting
        const changedSetting = await postData(
          `/admin/janus-settings/${id}/update`,
          setting
        );

        // add to array
        updatedJanusSettings.push(changedSetting.janus_settings);
      })
    );

    // Delete deleted settings
    await Promise.all(
      deletedSettings.map(async (id) => {
        // Get janus setting by id
        await postData(`/admin/janus-settings/${id}/delete`);
      })
    );

    store.dispatch(
      queueNotification({
        message: "Successfully Updated Janus Settings",
        options: {
          key: "create_error",
          variant: SnackbarVariant.SUCCESS,
        },
      })
    );

    // Return array of updated settings
    return updatedJanusSettings;
  }
);
