import React, { ComponentType } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { LoginRoute } from "../../constants/routes";

interface ProtectedRouteProps {
  path: string;
  exact?: boolean;

  component: ComponentType<any>;
  isAuthorised: boolean | (() => boolean);
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const { search } = useLocation();

  let isAuthorised: boolean;
  if (typeof props.isAuthorised === "boolean") {
    isAuthorised = props.isAuthorised;
  } else {
    isAuthorised = props.isAuthorised();
  }

  if (!isAuthorised) {
    return (
      <Redirect
        to={{
          pathname: LoginRoute,
          search,
        }}
      />
    );
  }

  return (
    <Route path={props.path} component={props.component} exact={props.exact} />
  );
}
