import { Button, FormLabel, Grid, Switch, Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mutate } from "swr";
import {
  selectDarkMode,
  selectHideZeroElements,
  setDarkMode,
} from "../../features/general/generalSlice";
import {
  FieldType,
  Form,
  FormItem,
  IExternalObjectForNesting,
  StatusBadge,
} from "../../model/crud.model";
import { IDatabaseObject } from "../../model/database-object.model";
import { GameId } from "../../model/sidebar.model";
import { INestedKeys, Endpoint } from "../../model/types";
import { getEndpointData } from "../../utils/getEndpointData";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { Flex } from "../Flex";
import { SearchBar } from "../SearchBar";
import { CollapsableCollectionEditor } from "./CollapsableCollectionEditor";
import { searchFilter } from "./CollectionEditor";
import {
  CrudKeys,
  selectSearchField,
  selectSearchTerm,
  setSearchField,
  setSearchTerm,
} from "./crudSlice";
import { validateForm } from "./Fields/validateForm";
import { FormContent } from "./FormContent";
import { ItemModal } from "./Modals/ItemModal";

interface ParentCollapseEditorProps {
  nestedKeys: INestedKeys;
  model: Form;
  response: any;
  filterObjectIdArray?: string[];
  allNestedKeys?: INestedKeys;
  nestedRouteArray?: { name: string; value: any }[];
  defaultValueOverrides?: { [id: string]: any };
  /*
    Used to specify the levels of nesting that use the value of an external object rather than the objects in the crud
    e.g. pulling game_edition_id from the corresponding xpromo_creative_id field, through the xpromo creatives objects
   */
  keyOverrides?: IExternalObjectForNesting;
  page: keyof CrudKeys;
  endpoint: Endpoint;
  updateItem: (
    endpoint: Endpoint,
    obj: any,
    isFormData?: boolean,
    gameId?: GameId
  ) => void;
  deleteItem: (url: string, obj: any) => void;
  deleteItems: (endpoint: Endpoint, arr: any) => void;
  createItem: (
    endpoint: Endpoint,
    obj: any,
    isFormData?: boolean,
    gameId?: GameId
  ) => void;
  deployItem: (endpoint: Endpoint, obj: any) => void;
  currentGameId?: GameId;
  displayNameTemplate?: string;
  /*
    Badges to breakdown the total badge value on the collapse button
   */
  statusBadges?: StatusBadge[];
  onSave?: (objToSave: { [id: string]: any }, saveFunction: () => void) => void;
}

export const ParentCollapseEditor = ({
  nestedKeys,
  model,
  response,
  filterObjectIdArray,
  allNestedKeys,
  nestedRouteArray,
  defaultValueOverrides,
  keyOverrides,
  page,
  endpoint,
  updateItem,
  deleteItem,
  deleteItems,
  createItem,
  deployItem,
  currentGameId,
  displayNameTemplate,
  statusBadges,
  onSave,
}: ParentCollapseEditorProps) => {
  const searchField = useSelector(selectSearchField);
  const searchTerm = useSelector(selectSearchTerm);
  const hideZeroElements = useSelector(selectHideZeroElements);
  const dispatch = useDispatch();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [itemCountSort, setItemCountSort] = useState(false);

  const currentLevelCollection = nestedKeys[0];
  const nextCollection = nestedKeys[1];

  let collectionByNextCollection: { [x: string]: any } = {};

  if (nextCollection) {
    Object.keys(nextCollection.values ?? {}).forEach((nextValue) => {
      Object.keys(currentLevelCollection.values ?? {}).forEach(
        (currentValue) => {
          if (collectionByNextCollection[currentValue]) {
            collectionByNextCollection[currentValue].push(nextValue);
          } else {
            collectionByNextCollection[currentValue] = [nextValue];
          }
        }
      );
    });
  }

  const topLevel = nestedKeys.length === (allNestedKeys ?? []).length;

  const nestedKeysClone = [...nestedKeys];
  nestedKeysClone.shift();

  let lastLevelChildObjects: { [id: string]: any[] } = {};

  Object.keys(currentLevelCollection?.values ?? {}).forEach((key) => {
    // Calculate how many promo creatives match the current level
    response
      ?.filter((item: any) => !isNullOrUndefined(item))
      ?.filter((obj?: any) =>
        searchFilter(
          searchTerm,
          searchField,
          model,
          displayNameTemplate,
          obj,
          page,
          keyOverrides
        )
      )
      .forEach((item: { [x: string]: any }) => {
        let element = item;
        let overrideElement = item;
        // If keyOverrides then use the item[externalObjectForNesting.fieldName] as item
        if (keyOverrides) {
          overrideElement = keyOverrides.data.find(
            (object) => object.object_id === item[keyOverrides.fieldName]
          );
        }

        if (isNullOrUndefined(lastLevelChildObjects[key])) {
          lastLevelChildObjects[key] = [];
        }

        const newNestedRouteArray = [
          ...(nestedRouteArray ?? []),
          { name: currentLevelCollection.key, value: key },
        ];

        const functions = currentLevelCollection?.functions;
        const usedForStatusBadges =
          currentLevelCollection?.functionsUsedForStatusBadges;

        if (
          newNestedRouteArray?.every((keyInfo) => {
            element = item;
            let value = item[keyInfo.name];

            if (
              keyOverrides?.keysToOverride.includes(keyInfo.name) &&
              !isNullOrUndefined(overrideElement)
            ) {
              element = overrideElement;
              value = element[keyInfo.name];
            }

            if (functions && !usedForStatusBadges) {
              // Execute order 66
              Object.keys(functions).forEach((key) => {
                const func = functions[key];
                if (func(element)) {
                  value = key;
                }
              });
            }

            return value === keyInfo.value;
          })
        ) {
          lastLevelChildObjects[key].push(item);
        }
      });
  });

  const selectedSearchFieldOptions: { [id: string]: any } = {};

  model.forEach((item: FormItem) => {
    selectedSearchFieldOptions[item.name] = item.display_name;

    if (item.collectionModel) {
      item.collectionModel?.forEach((item: FormItem) => {
        selectedSearchFieldOptions[item.name] = item.display_name;
      });
    }
  });

  if (keyOverrides) {
    keyOverrides.model.forEach((item: FormItem) => {
      selectedSearchFieldOptions[item.name] = item.display_name;

      if (item.collectionModel) {
        item.collectionModel?.forEach((item: FormItem) => {
          selectedSearchFieldOptions[item.name] = item.display_name;
        });
      }
    });
  }

  selectedSearchFieldOptions.display_name = "Display Name";
  selectedSearchFieldOptions.object_id = "Object ID";

  const selectedFieldsModel = [
    {
      name: "search_field",
      display_name: "Search Field",
      options: selectedSearchFieldOptions,
      type: FieldType.SELECT,
      hideLabel: true,
    },
  ];

  const darkMode = useSelector(selectDarkMode);
  const isDarkMode = darkMode === "dark";
  const invalidItemsArray = response.filter(
    (item: any) => Object.keys(validateForm(item, model)).length !== 0
  );

  return (
    <>
      {topLevel && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={2}>
              <Box>
                <FormLabel htmlFor="dark-mode">Order by Item Count</FormLabel>
                <Switch
                  checked={itemCountSort ?? false}
                  onChange={() => setItemCountSort(!itemCountSort)}
                  id="item-count-sort"
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Box>
                <FormLabel htmlFor="dark-mode">Dark Mode</FormLabel>
                <Switch
                  checked={isDarkMode ?? false}
                  onChange={() =>
                    dispatch(setDarkMode(isDarkMode ? "light" : "dark"))
                  }
                  id="item-count-sort"
                />
              </Box>
            </Grid>
          </Grid>

          <Flex my="1rem" width="100%" alignItems="center">
            {page === "xpromo_campaigns" && (
              <Box mr={2}>
                <Button
                  onClick={() => setIsAddModalOpen(true)}
                  variant="outlined"
                >
                  Add
                </Button>
              </Box>
            )}
            <Box width="100%" mr={2}>
              <SearchBar
                debounce
                disabled={!currentLevelCollection?.values}
                value={searchTerm}
                setValue={(value: string) => dispatch(setSearchTerm(value))}
              />
            </Box>

            <Box width="40%" mt={-1}>
              <FormContent
                formValue={{ search_field: searchField }}
                model={selectedFieldsModel}
                page={page}
                setFormValue={(value) =>
                  dispatch(setSearchField(Object.values(value)[0]))
                }
              />
            </Box>
          </Flex>
        </>
      )}

      {topLevel && invalidItemsArray.length !== 0 && (
        <CollapsableCollectionEditor
          type="error"
          page={page}
          filterObjectIdArray={
            invalidItemsArray.map((item: IDatabaseObject) => item.object_id) ??
            []
          }
          obj={{ code: "Invalid Items" }}
          displayKey="code"
          key={"unknown"}
          response={response}
          endpoint={endpoint}
          model={model}
          updateItem={updateItem}
          //@ts-ignore
          deleteItem={deleteItem}
          deleteItems={deleteItems}
          createItem={createItem}
          deployItem={deployItem}
          statusBadges={statusBadges}
        />
      )}

      {Object.keys(currentLevelCollection?.values ?? {})
        .sort((a: string, b: string) => {
          if (!itemCountSort) {
            // If both elements are 0
            if (
              lastLevelChildObjects[b]?.length === 0 &&
              lastLevelChildObjects[a]?.length === 0
            ) {
              // Sort alphabetically
              return currentLevelCollection?.values[a].localeCompare(
                currentLevelCollection?.values[b]
              );
            } else {
              // If one element is 0
              if (
                lastLevelChildObjects[b]?.length === 0 ||
                lastLevelChildObjects[a]?.length === 0
              ) {
                // Element that isn't 0 is first
                return lastLevelChildObjects[b]?.length === 0 ? -1 : 1;
              } else {
                // Else if no elements are 0
                // Sort alphabetically
                return currentLevelCollection?.values[a].localeCompare(
                  currentLevelCollection?.values[b]
                );
              }
            }
          } else {
            if (
              lastLevelChildObjects[b]?.length ===
              lastLevelChildObjects[a]?.length
            ) {
              return currentLevelCollection?.values[a].localeCompare(
                currentLevelCollection?.values[b]
              );
            } else {
              return (
                lastLevelChildObjects[b]?.length -
                lastLevelChildObjects[a]?.length
              );
            }
          }
        })
        ?.filter((id: any) => {
          if (!filterObjectIdArray) {
            return true;
          } else {
            return filterObjectIdArray?.includes(id);
          }
        })
        .filter((id: string) =>
          hideZeroElements ? lastLevelChildObjects[id]?.length !== 0 : true
        )
        .map((key) => {
          const newNestedRouteArray = [
            ...(nestedRouteArray ?? []),
            { name: currentLevelCollection.key, value: key },
          ];

          const newDefaultValueOverrides = {
            ...defaultValueOverrides,
            [currentLevelCollection.key]: key,
          };
          return (
            <CollapsableCollectionEditor
              currentGameId={currentGameId}
              displayNameTemplate={displayNameTemplate}
              currentLevel={currentLevelCollection}
              nestedKeys={nestedKeysClone}
              allNestedKeys={allNestedKeys}
              defaultValueOverrides={newDefaultValueOverrides}
              lastLevelChildCount={lastLevelChildObjects[key]?.length}
              lastLevelChildObjects={lastLevelChildObjects[key]}
              page={page}
              filterObjectIdArray={
                (collectionByNextCollection &&
                  collectionByNextCollection[key]) ??
                []
              }
              key={key}
              nestedRouteArray={newNestedRouteArray}
              displayKey="title"
              //@ts-ignore
              obj={{ title: currentLevelCollection?.values[key] }}
              response={response}
              endpoint={endpoint}
              model={model}
              lastLevel={allNestedKeys?.length === newNestedRouteArray.length}
              keyOverrides={keyOverrides}
              updateItem={(
                endpoint: Endpoint,
                obj: any,
                isFormData?: boolean,
                gameId?: GameId
              ) => {
                if (onSave) {
                  // save if onSave returns true
                  onSave(obj, () =>
                    updateItem(endpoint, obj, isFormData, gameId)
                  );
                } else {
                  updateItem(endpoint, obj, isFormData, gameId);
                }
              }}
              //@ts-ignore
              deleteItem={deleteItem}
              deleteItems={deleteItems}
              createItem={createItem}
              deployItem={deployItem}
              statusBadges={statusBadges}
            />
          );
        })}
      <ItemModal
        endpoint={endpoint}
        defaultValueOverrides={defaultValueOverrides}
        model={model}
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        isCreate={true}
        createItem={async (obj: any) => {
          try {
            let object: any = (await createItem(
              endpoint,
              obj,
              false,
              currentGameId
            )) as any;
            if (isNullOrUndefined(object)) {
              return;
            }

            const { url, objectPath } = getEndpointData(
              endpoint,
              page,
              currentGameId
            );

            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: [...(prev[objectPath ?? ""] ?? []), object],
                };
              },
              false
            );
          } catch (e) {
            console.error(e);
          }
        }}
        updateItem={async (obj: any) => {
          try {
            const object: any = (await updateItem(
              endpoint,
              obj,
              false,
              currentGameId
            )) as any;
            if (isNullOrUndefined(object)) {
              return;
            }
            const newArray = [...response];
            const index = newArray.findIndex(
              (item) => item.object_id === obj.object_id
            );
            newArray[index] = object;
            const { url, objectPath } = getEndpointData(
              endpoint,
              page,
              currentGameId
            );

            mutate(
              url,
              async (prev: any) => {
                return {
                  [objectPath]: newArray,
                };
              },
              false
            );
          } catch (e) {
            console.error(e);
          }
        }}
        page={page}
        nestedRouteArray={nestedRouteArray}
      />
    </>
  );
};
