import { themePalettes } from "../../../theme/options/palette";

export const lilacTheme = {
  darkMode: true,
  backgroundColor: themePalettes.lilac.background,
  textColor: "white",
  lightTextColor: "#5a5a5a",
  filter: {
    backgroundColor: themePalettes.lilac.background?.paper,
  },
  badge: {
    //@ts-ignore
    hoverColor: themePalettes.lilac.primary?.main,
  },
  dropdown: {
    backgroundColor: themePalettes.lilac.background?.paper,
    borderColor: "#676767",
  },
  selectedSettings: {
    backgroundColor: "#582f8c",
    textColor: "white",
    collapseColor: "#2b1747",
    collapseHoverColor: "#2d146b",
  },
  setting: {
    backgroundColor: "#5a4380",
    hoverColor: "#513c73",
    textColor: "white",
    boxShadow: "#333333",
    borderColor: "#333333",
    testingBackgroundColor: "#69013c",
  },
};
