import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogWrapper } from "../../../components/DialogWrapper";
import {
  ReactSelectArray,
  VersionedSettings,
} from "../../../model/versioned-settings.model";
import { getSettingById } from "../../../utils/getSettingById";
import { getSettingId } from "../../../utils/getSettingId";
import { getSettingsSources } from "../../../utils/getSettingsSources";
import { settingsSelector } from "../addToAllSlice";
import { addGameSettings, saveSettings } from "../addToAllThunks";
import { SettingsSelector } from "./SettingsSelector";
import { SourcePicker } from "./SourcePicker";
import { Alert } from "@mui/material";
import { Flex } from "../../../components/Flex";

interface CutoffSettingsModalProps {
  isOpen?: boolean;
  onClose: () => void;
  versions?: ReactSelectArray;
}

export const DuplicateVersionedSettings = ({
  isOpen,
  onClose,
}: CutoffSettingsModalProps) => {
  const dispatch = useDispatch();
  const settings = useSelector(settingsSelector);

  const [errors, setErrors] = useState<
    { title: string; message: string; objectId: string }[]
  >([]);
  const [overrideSettings, setOverrideSettings] = useState(false);
  const [progress, setProgress] = useState<
    { total: number; current: number } | undefined
  >();
  const [creatingSettings, setCreatingSettings] = useState(false);
  const [savingSetting, setSavingSetting] = useState(false);

  const [newValues, setFormValue] = useState<{
    game_edition_ids?: string[];
    country_codes?: string[];
    max_versions?: string[];
  }>({});

  const overwriteSetting = async (objectId: string, overwriteAll?: boolean) => {
    setSavingSetting(true);
    const settingToOverride = getSettingById(objectId ?? "", settings);

    //@ts-ignore
    const settingWithValues: Partial<VersionedSettings> | undefined = {
      ...settingToOverride,
      nested_settings: settingsToDupe ?? {},
    };

    // Update cas token
    settingWithValues.cas_token = settingToOverride?._cas;

    await dispatch(
      saveSettings({ objectId: objectId ?? "", setting: settingWithValues })
    );

    setSavingSetting(false);

    if (!overwriteAll) {
      // Remove from error array
      const newErrors = [...errors];

      const errorIndex = errors.findIndex(
        (error) => error.objectId === objectId
      );

      newErrors.splice(errorIndex, 1);

      setErrors(newErrors);
    }

    if (errors.length === 0) {
      onClose();
    }
  };

  const overwriteAllRemaining = async (objectIds: string[]) => {
    setCreatingSettings(true);
    let error = false;
    // Remove from error array
    await Promise.all(
      objectIds.map(async (objectId) => {
        await overwriteSetting(objectId, true).catch(() => {
          error = true;
        });
      })
    );

    setErrors([]);

    setCreatingSettings(false);

    if (!error) {
      onClose();
    }
  };

  const removeFromErrors = (objectId: string) => {
    // Remove from error array
    const newErrors = [...errors];

    const errorIndex = errors.findIndex((error) => error.objectId === objectId);

    newErrors.splice(errorIndex, 1);

    setErrors(newErrors);
  };

  const createSettings = async (formValue: { [id: string]: any }) => {
    setCreatingSettings(true);
    const { game_edition_ids, country_codes, max_versions } = formValue;

    const settingsToCreate: any[] = [];

    // Create settings
    game_edition_ids?.forEach((game_edition_id: string) => {
      country_codes?.forEach((country: string) => {
        max_versions?.forEach((max_version: string) => {
          // If this version exists for this game edition
          settingsToCreate.push({ game_edition_id, country, max_version });
        });
      });
    });

    setProgress({ total: settingsToCreate.length, current: 0 });

    // Add to error logging
    const newErrors: React.SetStateAction<
      { title: string; message: string; objectId: string }[]
    > = [];

    // Save settings
    await Promise.all(
      settingsToCreate.map(
        async (setting: VersionedSettings, index: number) => {
          const settingWithValues = {
            ...setting,
            nested_settings: settingsToDupe ?? {},
          };

          const resultAction = await dispatch(
            addGameSettings(settingWithValues)
          );

          //@ts-ignore
          if (!addGameSettings.fulfilled.match(resultAction)) {
            //@ts-ignore
            const conflict = resultAction.error.message.includes("409");
            // If conflict error and override settings is true then update settings with new nestedSettings
            if (conflict) {
              // Find id of setting
              const objectId = getSettingId(settingWithValues, settings);

              const settingToOverride = getSettingById(
                objectId ?? "",
                settings
              );

              if (overrideSettings) {
                await dispatch(
                  saveSettings({
                    objectId: objectId ?? "",
                    setting: {
                      ...settingToOverride,
                      ...settingWithValues,
                    },
                  })
                );
              } else {
                if (objectId) {
                  newErrors.push({
                    title: "Conflict",
                    message: `Setting with ID ${objectId} already exists, overwrite this setting?`,
                    objectId: objectId,
                  });
                }
              }
            }
          }

          setProgress({ total: progress?.total ?? 0, current: index + 1 });
        }
      )
    );

    setErrors(newErrors);

    setCreatingSettings(false);
  };

  const sources = getSettingsSources(settings);
  const [selectedSourceSettingsId, setSelectedSourceSettingsId] = useState<
    string | undefined
  >();
  const settingsToDupe = sources.find(
    (source) => source.id === selectedSourceSettingsId
  )?.nested_settings;

  return (
    <DialogWrapper size="sm" isOpen={!!isOpen} onClose={() => onClose()}>
      <DialogTitle>Duplicate Versioned Settings</DialogTitle>
      <DialogContent>
        <SourcePicker
          selected={selectedSourceSettingsId}
          onChange={(sourceId?: string) =>
            setSelectedSourceSettingsId(sourceId)
          }
        />
        <SettingsSelector
          newValues={newValues}
          setFormValue={(newValue) => setFormValue(newValue)}
          filterVersions
        />
        <FormControlLabel
          id="overwriteSettings"
          control={
            <Checkbox
              checked={overrideSettings}
              onChange={(event) => setOverrideSettings(event.target.checked)}
            />
          }
          label="Overwrite existing settings"
        />
        {creatingSettings && (
          <Box mt="1rem">
            <Alert severity="info">
              Creating settings do not close this window...
            </Alert>
          </Box>
        )}
      </DialogContent>
      {errors.length !== 0 && (
        <Flex pb="1rem" pr="1rem" width="100%" justifyContent="flex-end">
          <Button
            onClick={() =>
              overwriteAllRemaining(errors.map((error) => error.objectId))
            }
          >
            Overwrite all remaining conflicts
          </Button>
        </Flex>
      )}

      {errors.map((error) => (
        <Box mx="1rem">
          <Alert severity="error">
            <p className="mb-0">
              <strong>{error.title} </strong>
              {error.message}
            </p>
            <Flex width="100%" mt="0.5rem">
              <Box mr="0.5rem"></Box>
              <Button
                color="secondary"
                onClick={async () => {
                  removeFromErrors(error.objectId);
                }}
              >
                Ignore
              </Button>
              <Button
                variant="contained"
                disabled={savingSetting}
                color="primary"
                onClick={() => {
                  overwriteSetting(error.objectId);
                }}
              >
                Overwrite
              </Button>
            </Flex>
          </Alert>
        </Box>
      ))}

      <DialogActions>
        <Button className="ml-1" color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={creatingSettings || !selectedSourceSettingsId}
          onClick={async () => {
            createSettings(newValues);
          }}
        >
          Duplicate
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
