import { useState } from "react";
import { mutate } from "swr";
import { store } from "../../app/store";
import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import Endpoints from "../../constants/endpoints";
import { AnalyticsEventModel } from "../../model/analytics_event.model";
import { FieldType, Form } from "../../model/crud.model";
import { postData } from "../../network/request";
import { useEvents } from "../../network/useEvent";
import { getGameID } from "../../utils/game-admin.util";
import { getEndpointData } from "../../utils/getEndpointData";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { AnalyticsEventModal } from "./AnalyticsEventModal";

export const AnalyticsEvent = () => {
  const { events, isLoading } = useEvents();

  const currentGameId = getGameID();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedObject, setSelectedObject] =
    useState<AnalyticsEventModel | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const model: Form = [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
      maxLength: 20,
      onChangeOverride(value?, obj?) {
        return { ...obj, name: value.name.trim() };
      },
    },
    {
      display_name: "Description",
      name: "description",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
      onChangeOverride(value?, obj?) {
        return { ...obj, description: value.description.trim() };
      },
    },
  ];

  const handleDelete = async (
    obj: AnalyticsEventModel,
    deleteFlag: boolean
  ) => {
    try {
      await postData(
        `${Endpoints.ANALYTICS_EVENT.url}/${obj.object_id}/delete`,
        { force_delete: deleteFlag }
      );

      const newArray = (events ?? []).filter(
        (item: AnalyticsEventModel) => item.object_id !== obj.object_id
      );

      const { url, objectPath } = getEndpointData(
        Endpoints.ANALYTICS_EVENT,
        "sampling_event",
        currentGameId
      );

      mutate(
        url,
        async (prev: any) => {
          return {
            [objectPath]: newArray,
          };
        },
        false
      );

      store.dispatch(
        queueNotification({
          message: "Deleted Successfully",
          options: {
            key: "delete_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.error_message);
        setShowConfirmationModal(true);
        setSelectedObject(obj);
      } else {
        console.error(error);
      }
    }
  };

  const handleConfirmation = async (confirm: boolean) => {
    if (confirm && selectedObject) {
      try {
        // Update with force_delete set to true
        await handleDelete(selectedObject, true);
        setShowConfirmationModal(false);
      } catch (error: any) {
        store.dispatch(
          queueNotification({
            message: error.response.data.error_message,
            options: {
              key: "delete_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
    }
  };

  return (
    <>
      <CollectionEditor
        isLoading={isLoading}
        page="sampling_events"
        response={events}
        displayNameTemplate={`{name}`}
        model={model}
        endpoint={Endpoints.ANALYTICS_EVENT}
        onDelete={(obj) => handleDelete(obj, false)}
      />
      {showConfirmationModal && (
        <AnalyticsEventModal
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={() => handleConfirmation(true)}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
