import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { fetchSnapshots } from "./expandedLogThunks";

interface LogState {
  snapshots: any;
  loadingSnapshots: boolean;
  showUnchanged: {[id: string]: boolean};
}

const initialState: LogState = {
  snapshots: {},
  showUnchanged:{},
  loadingSnapshots: true,
};

export const expandedLogSlice = createSlice({
  name: "expanded_log",
  initialState,
  reducers: {
    SET_SHOW_UNCHANGED: (state, {payload}) => {
      state.showUnchanged[payload.key] = payload.value;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSnapshots.pending, (state) => {
      state.loadingSnapshots = true;
    });
    builder.addCase(fetchSnapshots.fulfilled, (state, { payload }) => {
      //@ts-ignore
      state.snapshots[payload.log_id] = payload.data_snapshots;
      state.loadingSnapshots = false;
    });
    builder.addCase(fetchSnapshots.rejected, (state) => {
      state.loadingSnapshots = false;
    });
  },
});

export const { SET_SHOW_UNCHANGED } = expandedLogSlice.actions;

// Selectors
export const selectSnapshots = (state: RootState) =>
  state.expanded_log.snapshots;
export const selectLoadingSnapshots = (state: RootState) =>
  state.expanded_log.loadingSnapshots;

export const selectShowUnchanged = (state: RootState) => state.expanded_log.showUnchanged;

export default expandedLogSlice.reducer;
