import {
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import {
  addToSeenChangelog,
  selectSeenChangelogs,
  selectShowAllChangelogs,
  setShowAllChangelogs,
} from "../features/general/generalSlice";
import { IWhatsNewNote } from "../model/navigation.model";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type WhatsNewModalProps = {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  changelog: IWhatsNewNote[];
};

export const WhatsNewModal = ({
  open,
  setOpen,
  changelog,
}: WhatsNewModalProps) => {
  const dispatch = useDispatch();
  const currentPath = useLocation().pathname;
  const showAllChangelogs = useAppSelector(selectShowAllChangelogs);

  // Get changelogs that user hasn't seen
  const seenChangelogs = useSelector(selectSeenChangelogs);
  const filteredChangelogs = changelog.filter(
    (log) =>
      log.pathName &&
      currentPath === log.pathName &&
      (!Object.keys(seenChangelogs).includes(log.version) || showAllChangelogs)
  );

  const handleClose = () => {
    dispatch(addToSeenChangelog(filteredChangelogs.map((log) => log.version)));
    setOpen(false);
    dispatch(setShowAllChangelogs(false));
  };

  if (filteredChangelogs.length === 0) {
    return null;
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {!showAllChangelogs ? "What's New" : "Changelog"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {filteredChangelogs.map((log) => (
            <ChangelogItem key={log.version} log={log} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleClose}>
            {!showAllChangelogs ? "Got it!" : "Close"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

type ChangelogItemProps = {
  log: IWhatsNewNote;
};

const ChangelogItem = ({ log }: ChangelogItemProps) => {
  return (
    <>
      <Divider textAlign="left">
        <Typography
          variant="h4"
          fontWeight={600}
          color="secondary"
          gutterBottom
        >
          {log.title.toUpperCase()}
        </Typography>
      </Divider>

      <Typography variant="subtitle1" gutterBottom>
        {moment(log.date).format("LL")}
      </Typography>

      <Typography variant="subtitle2" gutterBottom>
        v{log.version}
      </Typography>
      {log.imageUrl && (
        <CardMedia
          sx={{ marginBottom: 2 }}
          component="img"
          image={log.imageUrl}
        />
      )}
      <Typography gutterBottom>{log.description}</Typography>
      {log.features &&
        log.features.map((feature) => {
          return (
            <Box key={feature.title} m={2}>
              <Typography fontWeight={600} gutterBottom>
                • {feature.title}
              </Typography>

              {feature.description && (
                <Typography ml={2} gutterBottom>
                  {feature.description}
                </Typography>
              )}

              {feature.imageUrl && (
                <CardMedia
                  sx={{ marginBottom: 2 }}
                  component="img"
                  image={feature.imageUrl}
                />
              )}
            </Box>
          );
        })}
    </>
  );
};
