import { FieldType, Form } from "../../../model/crud.model";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";

export const validateForm = (obj: { [id: string]: any }, model?: Form) => {
  // Set valid to empty object
  let valid = {};

  if (!model) {
    return valid;
  }

  // For each item in form
  model.forEach((formItem) => {
    const value = obj[formItem.name];
    if (
      formItem.type === FieldType.COLLECTION &&
      value &&
      value.length !== 0 &&
      !formItem.isSingleton
    ) {
      for (let item of value) {
        const validObj = validateForm(item, formItem.collectionModel ?? []);
        if (Object.keys(validObj).length !== 0) {
          valid = { ...valid, [formItem.name]: validObj };
          break;
        }
      }
    } else {
      // Check if its required that it exists
      if (
        formItem.maxLength &&
        typeof value === "string" &&
        value.length > formItem.maxLength
      ) {
        valid = {
          ...valid,
          [formItem.name]: `${formItem.display_name} cannot be greater than ${formItem.maxLength}`,
        };
      }
      if (
        (formItem.required && isNullOrUndefined(value)) ||
        (formItem.required && typeof value === "string" && value === "")
      ) {
        // Otherwise set valid to false
        valid = {
          ...valid,
          [formItem.name]: `${formItem.display_name} is required`,
        };
      }
    }
  });

  return valid;
};
