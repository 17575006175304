import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import moment from "moment/moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../app/store";
import { DialogWrapper } from "../../../components/DialogWrapper";
import {
  ReactSelectArray,
  serverTimeFormat,
  ValueType,
  VersionedSettings,
  VersionedSettingss,
} from "../../../model/versioned-settings.model";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";
import { selectSession } from "../../session/sessionSlice";
import {
  countriesSelector,
  setDuplicateSettingsModal,
  settingsSelector,
} from "../addToAllSlice";
import { saveSelectedSettings } from "../addToAllThunks";

interface DeleteVersionedSettingsProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const InjectAdSettingsModal = ({
  isOpen,
  onClose,
}: DeleteVersionedSettingsProps) => {
  const [uploadedJSON, setUploadedJSON] = useState([]);

  const dispatch = useDispatch();
  const countries: ReactSelectArray = useSelector(countriesSelector) ?? [];

  const settings = useSelector(settingsSelector);

  const initialValue = {};

  const countryCodes = countries.map((countryObj) => countryObj.value);
  const countryCodeLookup: { [id: string]: string } = countries.reduce(
    (obj, item) => {
      return {
        ...obj,
        [item.value]: item.value,
      };
    },
    initialValue
  );

  countryCodeLookup["XX"] = "DEFAULT";

  const { user_id: user }: any = useSelector(selectSession);

  const validateJSONData = (data?: { [id: string]: any }) => {
    if (isNullOrUndefined(data)) {
      return;
    }

    let validatedData: { [id: string]: any } = {};

    for (let gameId of Object.keys(data)) {
      const gameData = data[gameId];
      validatedData = gameData;
    }
    return validatedData;
  };

  const callName = (threshold: any) => {
    let newInterstitialLocationSettings: any;

    for (let value in threshold) {
      newInterstitialLocationSettings = threshold[value].map((val: string) => ({
        __description__: "",
        __modified__: moment().format(serverTimeFormat),
        __tag__: "",
        __type__: "double",
        __user__: user,
        __value__: parseFloat(val),
        __valuehash__: "",
      }));
    }
    return newInterstitialLocationSettings;
  };

  const callChild = (item: any) => {
    let objTest: any;
    for (let child in item) {
      objTest = {
        [child]: {
          __description__: "",
          __modified__: moment().format(serverTimeFormat),
          __tag__: "",
          __type__: `${child === "impressionsThreshold" ? "array" : "object"}`,
          __user__: user,
          __value__:
            child === "impressionsThreshold"
              ? callName(item)
              : callChild(item[child]),
          __valuehash__: "",
        },
      };
    }
    return objTest;
  };

  const generateAdSettingObject = (
    validatedData: any,
    element: any,
    updateObjectKey: string
  ) => {
    const currentTime = moment().format(serverTimeFormat);

    const childObjects = validatedData[element]?.map((item: any) => ({
      __tag__: "",
      __description__: "",
      __modified__: currentTime,
      __type__: "object",
      __user__: user,
      __value__: callChild(item),
      __valuehash__: "",
    }));

    const newAttributionSettingsValue: any = {
      [updateObjectKey]: {
        __description__: "",
        __modified__: currentTime,
        __tag__: "",
        __type__: "array",
        __user__: user,
        __value__: childObjects,
        __valuehash__: "",
      },
    };

    return newAttributionSettingsValue;
  };

  const applyDataToSettings = (validatedData: any, key: string) => {
    const cloneSetting = _.cloneDeep(settings);
    const settingsToUpdate: VersionedSettingss = [];

    for (let updateObjectKey of Object.keys(validatedData)) {
      for (let objects in validatedData) {
        const attributionSettingsValue = generateAdSettingObject(
          validatedData,
          objects,
          updateObjectKey
        );
        const currentTime = moment().format(serverTimeFormat);

        cloneSetting.forEach((setting: VersionedSettings) => {
          if (setting) {
            const initialValue = setting.nested_settings[key];

            setting.nested_settings[key] = {
              __description__: "Auto-generated",
              __modified__: currentTime,
              __tag__: "adSettingsInjection",
              __type__: ValueType.OBJECT,
              __user__: user,
              __value__: {
                ...(typeof initialValue?.__value__ === "object" && {
                  ...initialValue?.__value__,
                }),
                ...attributionSettingsValue,
              },
              __valuehash__: "",
            };
            setting.cas_token = setting._cas;
            settingsToUpdate.push(setting);
          }
        });
      }
    }
    dispatch(saveSelectedSettings({ selectedSettings: settingsToUpdate }));
  };

  const handleJSONDataUpload = (
    //@ts-ignore
    e: Event<HTMLInputElement>
  ) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target?.files[0], "UTF-8");
    fileReader.onload = (e) => {
      try {
        // @ts-ignore
        const jsonData = JSON.parse(e.target.result);
        setUploadedJSON(jsonData);
      } catch (e) {
        console.error(e);
        dispatch(
          queueNotification({
            message: "Invalid JSON file",
            options: {
              key: "create_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
    };
  };

  const allCountriesWithSettings = settings?.map(
    (setting: VersionedSettings) => setting.country
  );

  const settingsExistForAllCountries = countryCodes.every((code) =>
    allCountriesWithSettings?.includes(code)
  );

  return (
    <DialogWrapper isOpen={!!isOpen} onClose={() => onClose()} size="sm">
      <DialogTitle>Inject adSettings</DialogTitle>
      <DialogContent>
        <small>Upload a JSON below to inject</small>
        <br />
        <small>
          This will save settings on confirm, ensure all previous unsaved
          changes have been saved.
        </small>
        <Box my={2}>
          <input
            type="file"
            onChange={(event) => handleJSONDataUpload(event)}
          />
        </Box>
        {!settingsExistForAllCountries && (
          <Alert severity="warning">
            <AlertTitle>Missing Settings</AlertTitle>
            Settings must exist for each country before ad settings table can be
            injected.
            <Box mt={2}>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => dispatch(setDuplicateSettingsModal(true))}
              >
                Duplicate Settings
              </Button>
            </Box>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          className="ml-1"
          color="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          disabled={!uploadedJSON}
          size="small"
          variant="contained"
          color="primary"
          onClick={async () => {
            if (!isNullOrUndefined(uploadedJSON)) {
              //@ts-ignore
              const validatedData = validateJSONData(uploadedJSON);
              if (validatedData) {
                onClose();
                applyDataToSettings(uploadedJSON, "adSettings");
              }
            } else {
              store.dispatch(
                queueNotification({
                  message: "No JSON uploaded",
                  options: {
                    key: "no_json",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            }

            return;
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
