import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { CrudKeys, selectIsSaving } from "../crudSlice";
import { AutoModalCloser } from "./AutoModalCloser";
import { getPageName } from "./ConfirmDialog";

interface DeleteConfirmDialogProps {
  index?: number;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  deleteItem?: (obj: any, index: number) => void;
  deleteMultipleItems?: (arr: any[]) => void;
  object?: any;
  objects?: any[];
  page: keyof CrudKeys;
  clearSelected?: () => void;
  multidelete?: boolean;
}

export const DeleteConfirmDialog = ({
  index,
  isOpen,
  onClose,
  title,
  deleteItem,
  object,
  page,
  objects,
  deleteMultipleItems,
  multidelete,
  clearSelected,
}: DeleteConfirmDialogProps) => {
  const isSaving = useSelector(selectIsSaving);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <AutoModalCloser onClose={onClose} page={page} />
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        Are you sure you want to delete these items? You can't undo this action
        afterwards.
        <Box mt={2}>
          {objects?.map((obj) => (
            <Typography key={obj.object_id}>
              <strong>{obj.name}</strong> <small>{obj.object_id}</small>
            </Typography>
          ))}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={isSaving[getPageName(page)]}
          color="primary"
          variant="contained"
          onClick={async () => {
            if (multidelete) {
              //When deleting nested objects needs to only select objects from it's parent
              deleteMultipleItems &&
                objects &&
                (await deleteMultipleItems(objects));
              clearSelected && clearSelected();
            } else {
              if (!isNullOrUndefined(index)) {
                deleteItem && deleteItem(object, index);
              }
            }
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
