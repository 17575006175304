import { DifferenceEntry } from "../model/versioned-settings.model";
import { DifferenceType } from "./DifferenceType";

export function recursiveHasBeenModified(diff?: DifferenceEntry) {
  let beenModified = false;

  if (!diff) {
    return beenModified;
  }

  if (diff.children) {
    Object.values(diff.children).forEach((value) => {
      if (!beenModified) {
        beenModified = recursiveHasBeenModified(value);
      }
    });
  }

  if (
    Object.values(diff.type).some((value) => value !== DifferenceType.UNCHANGED)
  ) {
    beenModified = true;
  }

  return beenModified;
}
