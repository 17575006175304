import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, Component, CSSProperties, DragEvent } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
  style?: CSSProperties;
  multiple?: boolean;
  disabled?: boolean;
  onFilesAdded?: (files: File[]) => void;
}

interface State {
  isHovered?: boolean;
}

export default class Dropzone extends Component<Props, State> {
  state: State = {};

  private fileInputRef = React.createRef<HTMLInputElement>();

  openFileDialog = () => {
    if (this.props.disabled || !this.fileInputRef.current) {
      return;
    }

    this.fileInputRef.current.click();
  };

  onFilesAdded = (event: ChangeEvent<HTMLInputElement>) => {
    if (this.props.disabled) {
      return;
    }

    this._onFilesAdded(event.target.files);
  };

  onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this.setState({ isHovered: true });
  };

  onDragLeave = () => {
    this.setState({ isHovered: false });
  };

  onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (this.props.disabled) {
      return;
    }

    this._onFilesAdded(event.dataTransfer.files);
    this.onDragLeave();
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    // Disable hover as component is disabled
    if (!prevProps.disabled && this.props.disabled && this.state.isHovered) {
      this.onDragLeave();
    }
  }

  render() {
    return (
      <div
        className={classNames('dropzone', this.props.className, {
          hovered: this.state.isHovered,
        })}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{
          ...this.props.style,
          cursor: this.props.disabled ? 'no-drop' : 'pointer',
        }}
      >
        <input
          ref={this.fileInputRef}
          className="dropzone-input"
          type="file"
          multiple={this.props.multiple}
          onChange={this.onFilesAdded}
        />

        {this.props.children ? (
          <div>{this.props.children}</div>
        ) : (
          <FontAwesomeIcon icon={faFileUpload} />
        )}
      </div>
    );
  }

  private _onFilesAdded(files: FileList | null) {
    if (!files || files.length < 1) {
      return;
    }

    if (this.props.onFilesAdded) {
      this.props.onFilesAdded(Array.from(files));

      // Clear file input so the same file could be added again
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = '';
      }
    }
  }
}
