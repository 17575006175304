import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsSaving, CrudKeys } from "../crudSlice";
import { AutoModalCloser } from "./AutoModalCloser";

interface DeleteConfirmDialogProps {
  page: keyof CrudKeys;
  isOpen: boolean;
  onClose: () => void;
  deployItem?: (obj: any) => void;
  object: any;
}

export const getPageName = (pageName: string): keyof CrudKeys => {
  if (pageName === "ctest" || pageName === "cpitest") {
    return "campaigns";
  } else {
    return pageName as keyof CrudKeys;
  }
};

export const ConfirmDialog = ({
  isOpen,
  onClose,
  deployItem,
  object,
  page,
}: DeleteConfirmDialogProps) => {
  const isSaving = useSelector(selectIsSaving);

  const title: { [id: string]: string } = {
    creatives: "Upload Creative to Facebook?",
    ctest: "Publish Test to Facebook?",
    cpitest: "Publish Test to Facebook?",
    games: "Publish Game to Facebook?",
    changes_made: "Changes made will be lost.",
  };

  const button: { [id: string]: string } = {
    creatives: "Upload",
    ctest: "Publish",
    cpitest: "Publish",
    games: "Publish",
    changes_made: "Close",
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <AutoModalCloser onClose={onClose} page={page} />

      <DialogTitle>{title[page]}</DialogTitle>

      <DialogContent>
        Are you sure? You can't undo this action afterwards.
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={isSaving[page]}
          color="primary"
          variant="contained"
          onClick={() => {
            deployItem && deployItem(object);
          }}
        >
          {button[page]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
