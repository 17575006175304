export interface DatabaseObjectID {
  object_id: string;
}

export interface DatabaseObjectType {
  object_type: string;
}

export interface IDatabaseObject extends DatabaseObjectID, DatabaseObjectType {
  created_on: number;
  deleted_on: null;
  updated_on: number;
}

export const serverTimeFormat = "YYYYMMDDTHHmmss.SSS[Z]";
