import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { getData } from "./request";

export function useCreatives() {
  const { data, error } = useSWR(Endpoints.XPROMO_CREATIVES.url, getData, {
    suspense: true,
  });

  return {
    creatives:
      data &&
      (data as { [id: string]: any })[Endpoints.XPROMO_CREATIVES.object_path],
    isLoading: !error && !data,
    isError: error,
  };
}
