import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ValueType } from "../../model/versioned-settings.model";
import { nanoid } from "@reduxjs/toolkit";
import { getConfigData, saveConfigData } from "./clientConfigSlice";
import { useClientConfig } from "../../network/useClientConfig";
import ClientContainer from "./ClientContainer";

export const handleType = (value: any) => {
  if (Array.isArray(value)) {
    return ValueType.ARRAY;
  } else if (typeof value === ValueType.OBJECT && !Array.isArray(value)) {
    return ValueType.OBJECT;
  } else {
    return typeof value;
  }
};

export const ClientConfig = () => {
  const { config } = useClientConfig();

  const dispatch = useDispatch();
  const items = useSelector(getConfigData);

  const encodeFormatRecursively = useCallback((p: any): any => {
    if (
      handleType(p) === ValueType.BOOLEAN ||
      handleType(p) === ValueType.STRING ||
      handleType(p) === ValueType.NUMBER
    ) {
      return p;
    }
    return Object.entries(p ?? [])
      .map(([key, value]) => {
        return {
          key: key,
          val: {
            id: nanoid(10),
            __type__: handleType(value),
            __value__: encodeFormatRecursively(value),
          },
        };
      })
      .reduce((obj, item) => Object.assign(obj, { [item.key]: item.val }), {});
  }, []);

  useEffect(() => {
    const data = encodeFormatRecursively(config);

    dispatch(saveConfigData(data));
  }, [config, dispatch, encodeFormatRecursively]);

  return <ClientContainer data={items} />;
};
