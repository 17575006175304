import { useMemo } from "react";
import useSWR from "swr";
import { getData } from "./request";

export function useGameEditions() {
  const { data, error } = useSWR("admin/game-editions", getData, {
    suspense: false,
    shouldRetryOnError: false,
  });

  return useMemo(
    () => ({
      gameEditions: (
        (data ?? {}) as {
          [id: string]: any;
        }
      )["game_editions"],
      isLoading: !error && !data,
      isError: error,
    }),
    [data, error]
  );
}
