import { AnyAction } from "@reduxjs/toolkit";
import { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { store } from "../app/store";
import { logout, selectSession } from "../features/session/sessionSlice";
import { useConfig } from "../network/useConfig";
import isNullOrUndefined from "../utils/isNullOrUndefined";
import { useQuery } from "../utils/useQuery";

export const QueryParamWrapper = ({
  children,
}: PropsWithChildren<any>): JSX.Element => {
  let query = useQuery();
  const { gameIds } = useConfig();
  const { game_id } = useAppSelector(selectSession);

  const queryGameId = query.get("gameId");

  const location = useLocation();

  if (
    !isNullOrUndefined(queryGameId) &&
    gameIds?.includes(queryGameId) &&
    game_id !== queryGameId
  ) {
    store.dispatch(
      logout(true, queryGameId, location.pathname) as unknown as AnyAction
    );
  }

  return children;
};
