import { CohortedTest } from "../model/cohorted-test.model";

export function getCurrentAbTestedSettings(
  tests: CohortedTest[],
  countryCode?: string,
  platform?: { label: string; value: string }[]
) {
  const abTestedSettings: { [id: string]: any } = {};

  const platformValues = platform?.map((k) => k.value);

  // Only include current country code tests and paused/active tests
  const pausedAndActiveTests = tests.filter(
    (test) =>
      test.status === "paused" ||
      test.status === "active" ||
      test.status === "partial_pause" ||
      (test.status === "pending_active" &&
        test.country_codes?.includes(countryCode ?? ""))
  );
  pausedAndActiveTests
    .filter((item) => platformValues?.includes(item.game_edition_id))
    .forEach((test) => {
      // For each feature
      test.features?.forEach((feature) => {
        feature.variants.forEach((variant: { effects: any[] }) => {
          variant.effects.forEach((effect?: any) => {
            // Combine features into object
            if (effect) {
              if (!abTestedSettings[effect.key]) {
                abTestedSettings[effect.key] = {};
              }
              effect.alterations.forEach((alt: any | undefined) => {
                if (alt) {
                  if (alt.path === "") {
                    abTestedSettings[effect.key][test.object_id!] =
                      alt.altered_value;
                  } else {
                    if (!abTestedSettings[effect.key][alt.path]) {
                      abTestedSettings[effect.key][alt.path] = {};
                    }
                    abTestedSettings[effect.key][alt.path][test.object_id!] =
                      alt.altered_value;
                  }
                }
              });
            }
          });
        });
      });
    });

  return abTestedSettings;
}
