import { CircularProgress } from "@mui/material";
import { Alert } from "@mui/material";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import React, { useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { store } from "../../app/store";
import { useCohortedTests } from "../../network/useCohortedTests";
import { getCurrentAbTestedSettings } from "../../utils/getCurrentAbTestedSettings";
import { getVersions } from "../../utils/getVersions";
import { selectDarkMode } from "../general/generalSlice";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { selectSession } from "../session/sessionSlice";
import Filter from "./components/Filter/Filter";
import SettingsContainer from "./components/Setting/SettingsContainer";
import Switcher from "./components/Switcher";
import {
  dropdownsSelector,
  setVersionOptions,
} from "./components/Filter/filterSlice";
import { darkTheme } from "./styles/DarkTheme";
import { lightTheme } from "./styles/LightTheme";
import Search from "./components/Search";
import { lilacTheme } from "./styles/LilacTheme";
import { UnsavedSettingsDialog } from "./components/UnsavedSettingsDialog";
import {
  currentSaveNumberSelector,
  errorSelector,
  loadingSettingsSelector,
  requestStatusSelector,
  savingSettingsSelector,
  setConfig,
  setSettings,
  setGameEditions,
  settingsSelector,
  selectUnsavedSettings,
  numberOfSettingsToSaveSelector,
  setUnsavedSettings,
  selectCurrentDeleteNumber,
  deletingSettingsSelector,
  numberOfSettingsToDeleteSelector,
} from "./addToAllSlice";
import {
  fetchConfig,
  fetchGameEditions,
  fetchSettings,
} from "./addToAllThunks";
import { FullScreenProgressBar } from "./components/FullScreenProgressBar";
import { Flex } from "../../components/Flex";

const GlobalStyle = createGlobalStyle`
  body {
  transition: background-color .2s ease-in-out, color .2s ease-in-out;
  
    background-color: ${(props) =>
      //@ts-ignore
      props.theme.backgroundColor};
    
    color: ${(props) =>
      //@ts-ignore
      props.theme.textColor};
  }
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
`;

const StyledSettingsContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  justify-content: center;
`;

export const StyledProgressBarPopup = styled.div`
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  padding: 0 5rem;
  background: #1b1e21;
  opacity: 0.7;

  div {
    display: flex;
    width: 600px;
    flex-direction: column;
    justify-content: center;

    .col-form-label-sm {
      color: white;
    }

    .progress {
      width: 600px;
      height: 30px;
    }

    .progress-bar {
      height: 30px;
    }
  }
`;

const AddToAll = (props: any) => {
  const dispatch = useDispatch();
  const { game_id } = useSelector(selectSession);
  const currentSaveNumber = useSelector(currentSaveNumberSelector);
  const savingSettings = useSelector(savingSettingsSelector);
  const currentDeleteNumber = useSelector(selectCurrentDeleteNumber);
  const deletingSettings = useSelector(deletingSettingsSelector);
  const requestStatus = useSelector(requestStatusSelector);
  const unsavedSettings = useSelector(selectUnsavedSettings);
  const settings = useSelector(settingsSelector);

  const darkMode = useSelector(selectDarkMode);
  const isDarkMode = darkMode === "dark";
  const isLilacMode = darkMode === "lilac";

  const numberOfSettingsToSave = useSelector(numberOfSettingsToSaveSelector);
  const numberOfSettingsToDelete = useSelector(
    numberOfSettingsToDeleteSelector
  );

  const { cohortedTests, isError: cohortedTestError } = useCohortedTests();

  const dropdowns = useSelector(dropdownsSelector);

  const abTestedSettings = getCurrentAbTestedSettings(
    cohortedTests ?? [],
    "DEFAULT",
    dropdowns?.platforms
  );

  const hasCohortedTestPermission = useMemo(() => {
    if (cohortedTestError?.response?.status === 403) {
      return false;
    }

    return true;
  }, [cohortedTestError]);

  useEffect(() => {
    const handleFetch = async (
      name: string,
      func: () => AsyncThunkAction<any, void, {}>
    ) => {
      const resultAction = await dispatch(func());
      //@ts-ignore
      if (!func.fulfilled.match(resultAction)) {
        //@ts-ignore
        const returnedError = `Error fetching ${name}: ${resultAction.error.message}`;
        store.dispatch(
          queueNotification({
            message: returnedError,
            options: {
              key: `error_fetch_${name}`,
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
    };

    // If settings exist update in redux store
    if (props.history?.location?.state?.settings) {
      const versionOptions = getVersions(
        props.history?.location?.state?.settings
      );
      dispatch(setVersionOptions(versionOptions));
      dispatch(setSettings(props.history?.location?.state?.settings));
    } else {
      //@ts-ignore
      handleFetch("settings", fetchSettings);
    }

    // If config exist update in redux store
    if (props.history?.location?.state?.config) {
      dispatch(setConfig(props.history?.location?.state?.config));
    } else {
      handleFetch("config", fetchConfig);
    }

    // If game editions exist update in redux store
    if (props.history?.location?.state?.gameEditionsReactSelect) {
      dispatch(
        setGameEditions(props.history?.location?.state?.gameEditionsReactSelect)
      );
    } else {
      handleFetch("game editions", fetchGameEditions);
    }
  }, [dispatch, props.history, game_id]);

  useEffect(() => {
    if (settings) {
      const versionOptions = getVersions(settings);
      dispatch(setVersionOptions(versionOptions));
    }
  }, [dispatch, settings]);

  return (
    <ThemeProvider
      theme={isDarkMode ? darkTheme : isLilacMode ? lilacTheme : lightTheme}
    >
      <GlobalStyle />
      <>
        {savingSettings && (
          <FullScreenProgressBar
            current={currentSaveNumber}
            total={numberOfSettingsToSave}
            title="Saving Settings"
          />
        )}
        {deletingSettings && (
          <FullScreenProgressBar
            current={currentDeleteNumber}
            total={numberOfSettingsToDelete}
            title="Deleting Settings"
          />
        )}
        <Filter />
        <Container>
          <Switcher />
          {unsavedSettings && unsavedSettings.length !== 0 && (
            <UnsavedSettingsDialog
              onClose={() => dispatch(setUnsavedSettings([]))}
              unsavedSettings={unsavedSettings}
              isOpen={!!unsavedSettings}
            />
          )}
          <Search />

          {!hasCohortedTestPermission && (
            <Alert severity="warning">
              You do not have permission to view cohorted tests, settings that
              are currently being A/B tested will not warn that they are!
            </Alert>
          )}

          <StyledSettingsContainer>
            {props.loadingSettings ? (
              <Flex display="flex" flexDirection="column" alignItems="center">
                <CircularProgress />
                <small className="mt-3 text-muted">{requestStatus}</small>
              </Flex>
            ) : props.error ? (
              <Alert severity="error">{props.error.message}</Alert>
            ) : (
              <SettingsContainer abTestedSettings={abTestedSettings} />
            )}
          </StyledSettingsContainer>
        </Container>
      </>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => ({
  settings: settingsSelector(state),
  loadingSettings: loadingSettingsSelector(state),
  error: errorSelector(state.addToAllReducer),
});

const mapDispatchToProps = { setSettings, setConfig };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddToAll));
