import {
  Delete,
  Edit,
  KeyboardDoubleArrowDown,
  Save,
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  darken,
  useTheme,
  Alert,
  Typography,
  Divider,
  Fab,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { mutate } from "swr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createItem,
  deleteItem,
  updateItem,
} from "../../components/CollectionEditor/crudService";
import { FormContent } from "../../components/CollectionEditor/FormContent";
import { ItemModal } from "../../components/CollectionEditor/Modals/ItemModal";
import { Flex } from "../../components/Flex";
import { SearchBar } from "../../components/SearchBar";
import Endpoints from "../../constants/endpoints";
import { FieldType, Form } from "../../model/crud.model";
import { ILevel, IVersionedLevel } from "../../model/level.model";
import { useGameEditions } from "../../network/useGameEditions";
import { useLevels } from "../../network/useLevels";
import { useVersioned } from "../../network/useVersioned";
import { getEndpointData } from "../../utils/getEndpointData";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { reorder } from "../../utils/reorder";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import { useCategories } from "./hooks/useCategories";
import { useVersionedLevels } from "./hooks/useVersionedLevels";
import { DraggableLevel } from "./components/DraggableLevel";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { saveLevelOrder } from "./levelOrderService";
import { NotInLevelOrderLevels } from "./NotInLevelOrderLevels";
import { selectSession } from "../session/sessionSlice";
import { BrokenLevelReference } from "./components/BrokenLevelReference";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import styled from "styled-components";
import { selectDarkMode } from "../general/generalSlice";
import { useSelector } from "react-redux";
import { useGameModel } from "./hooks/useGameModel";

type GameEdition = "ios" | "android" | "amazon" | "chinaios";

export const VersionedLevels = () => {
  const dispatch = useAppDispatch();
  const { game_id: currentGameId } = useAppSelector(selectSession);

  const versionedPage = `${currentGameId}_versioned_levels`;
  const levelPage = `${currentGameId}_levels`;

  const [removedLevels, setRemovedLevels] = useState<string[]>([]);

  const { gameEditions } = useGameEditions();
  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const filtersModel: Form = [
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.MULTISELECT,
      selectOptions: Object.entries(gameEditionOptions).map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      }),
      chipSize: "small",
      selectSize: "small",
      valueFunction(obj?) {
        return filters.game_edition_id;
      },
      label: "Game Edition",
      limitTags: 2,
      placeholder: "Game Editions",
      id: "game_editions",
      disabled: false,
      needGrouping: false,
    },
  ];

  const darkMode = useSelector(selectDarkMode) === "dark";

  const color = darkMode ? "#161B24" : "#f5f5f5";

  const [filters, setFilters] = useState<{
    [id: string]: any;
  }>({
    game_edition_id: ["ios", "android"],
    category_id: "default",
  });

  const theme = useTheme();

  const { levels, isLoading: isLoadingLevels } = useLevels();

  const { versionedLevel, isLoading: isLoadingVersionedLevels } =
    useVersioned();

  const [versionLevelOrder, setVersionLevelOrder] = useState(versionedLevel);

  const isLoading = isLoadingLevels || isLoadingVersionedLevels;

  const currentLevelOrder: IVersionedLevel[] | undefined = useMemo(() => {
    return versionLevelOrder?.filter(
      (order: IVersionedLevel) =>
        filters.game_edition_id.includes(order?.game_edition_id) &&
        order.category_id === filters.category_id
    );
  }, [versionLevelOrder, filters]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (versionedLevel.length > 0) {
      setVersionLevelOrder(versionedLevel ?? []);
    }
  }, [versionedLevel]);

  const allLevelIds = levels.map((level: ILevel) => level.object_id);

  const addLevelIds = (
    ids: string[],
    versionId?: string,
    location?: number,
    idFilter?: (id: string, currentIndex?: number) => boolean
  ) => {
    if (location && location < 0) {
      dispatch(
        queueNotification({
          message:
            "Index is out of levels range, please specify a location 1 or above",
          options: {
            key: "invalid_index",
            variant: SnackbarVariant.ERROR,
          },
        })
      );
      return;
    }

    const levelOrderObject = versionLevelOrder.find(
      (obj) => obj.object_id === versionId
    );

    // Extract the orderedLevelIds from the found object
    const orderedLevelIdsArray = levelOrderObject?.ordered_level_ids;

    // Pass filter function if one is provided
    let newIds = [...(orderedLevelIdsArray ?? [])].filter((id, index) =>
      idFilter ? idFilter(id, index) : true
    );

    newIds =
      !isNullOrUndefined(location) || (location && location > newIds.length)
        ? [
            ...newIds.slice(0, location - 1),
            ...ids,
            ...newIds.slice(location - 1),
          ]
        : newIds.concat(...ids);

    const updatedLevelOrderObject = { ...levelOrderObject };

    updatedLevelOrderObject.ordered_level_ids = newIds;

    // Update the levelOrderArray with the modified object
    const updatedLevelOrderArray: IVersionedLevel[] = versionLevelOrder
      .map((obj) =>
        obj.object_id === versionId ? updatedLevelOrderObject : obj
      )
      .filter((obj) => !!obj) as IVersionedLevel[];

    // Set the updated levelOrderArray
    setVersionLevelOrder(updatedLevelOrderArray);

    const newRemovedLevels = [...removedLevels];
    ids.forEach((id) => {
      if (removedLevels.includes(id)) {
        const index = newRemovedLevels.findIndex(
          (removedId) => removedId === id
        );
        newRemovedLevels.splice(index, 1);
      }
    });

    setRemovedLevels(newRemovedLevels);
  };

  const removeLevelId = (index: number, objectId?: string) => {
    const levelOrderObject = versionLevelOrder.find(
      (obj) => obj.object_id === objectId
    );

    const newIds = [...(levelOrderObject?.ordered_level_ids ?? [])];
    newIds.splice(index, 1);

    const updatedLevelOrderObject = { ...levelOrderObject };

    updatedLevelOrderObject.ordered_level_ids = newIds;

    // Update the levelOrderArray with the modified object
    const updatedLevelOrderArray: IVersionedLevel[] = versionLevelOrder
      .map((obj) =>
        obj.object_id === objectId ? updatedLevelOrderObject : obj
      )
      .filter((obj) => !!obj) as IVersionedLevel[];

    setVersionLevelOrder(updatedLevelOrderArray);
  };

  const removeFromLevelOrder = (areaId: string, versionId?: string) => {
    const levelOrderObject = versionLevelOrder.find(
      (obj) => obj.object_id === versionId
    );

    const index = levelOrderObject?.ordered_level_ids?.findIndex(
      (id: string) => id === areaId
    );

    if (!isNullOrUndefined(index)) {
      removeLevelId(index, versionId);
      const newRemovedAreas = [...removedLevels];
      if (!removedLevels.includes(areaId)) {
        // Add to removedAreas array
        newRemovedAreas.push(areaId);
      }

      setRemovedLevels(newRemovedAreas);
    }
  };

  function onDragEnd(
    result: DropResult,
    provided: ResponderProvided,
    versionId?: string
  ) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const levelOrderObject = versionLevelOrder.find(
      (obj) => obj.object_id === versionId
    );

    const newOrderedAreaIds = reorder(
      levelOrderObject?.ordered_level_ids ?? [],
      result.source.index,
      result.destination.index
    );

    const updatedLevelOrderObject = { ...levelOrderObject };

    updatedLevelOrderObject.ordered_level_ids = newOrderedAreaIds;

    const updatedLevelOrderArray: IVersionedLevel[] = versionLevelOrder
      .map((obj) =>
        obj.object_id === versionId ? updatedLevelOrderObject : obj
      )
      .filter((obj) => !!obj) as IVersionedLevel[];

    setVersionLevelOrder(updatedLevelOrderArray);
  }

  const renderChipText = (value: GameEdition) => {
    switch (value) {
      case "ios":
        return "iOS";
      case "android":
        return "Android";
      case "amazon":
        return "Amazon";
      default:
        return "China iOS";
    }
  };

  const versionModel = useVersionedLevels();
  const gameModel = useGameModel();

  const [isNewOpen, setIsNewOpen] = useState(false);
  const [isVersionNewOpen, setIsVersionNewOpen] = useState(false);

  const StyledAccordionSummary = styled(AccordionSummary)<{ color: string }>`
    "&.Mui-expanded" {
      position: sticky;
      top: -8px;
      zindex: 5;
      background: ${(props) => props.color};
    }
  `;

  const [initialFormValues, setInitialFormValues] = useState({});
  const [isCreate, setIsCreate] = useState<boolean>(true);

  const handleEdit = (item: any) => {
    setIsCreate(false);
    setInitialFormValues(item);
    setIsVersionNewOpen(true);
  };

  const handleSave = async (item: any) => {
    try {
      await updateItem(Endpoints.VERSIONED_LEVELS, item, false, currentGameId);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (item: any) => {
    try {
      await deleteItem(
        `${Endpoints.VERSIONED_LEVELS.url}/${item.object_id}/delete`
      );

      setVersionLevelOrder(
        versionLevelOrder.filter((obj) => obj.object_id !== item.object_id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <FormContent
        formValue={filters}
        model={filtersModel}
        page="level_order"
        setFormValue={setFilters}
        incorporateLabels
        inline
      />
      <Box
        mt={4}
        py={2}
        display="flex"
        position="sticky"
        top="0"
        bgcolor="background.default"
        justifyContent="space-between"
        zIndex={998}
      >
        <Box>
          <Button
            variant="outlined"
            onClick={async () => {
              setIsNewOpen(true);
            }}
            disabled={isLoading}
          >
            Add Level
          </Button>

          <Button
            variant="outlined"
            onClick={async () => {
              setIsVersionNewOpen(true);
            }}
            sx={{ ml: 2 }}
            disabled={isLoading}
          >
            Add Versions
          </Button>
        </Box>
      </Box>
      {!isLoading ? (
        <Box
          bgcolor={darken(theme.palette.background.paper, 0.2)}
          p={2}
          borderRadius={4}
          mt={5}
          mb={3}
        >
          <SearchBar
            sx={{ marginBottom: 1 }}
            debounce
            value={searchTerm}
            setValue={(value: string) => setSearchTerm(value)}
          />
          <Divider sx={{ marginY: 2 }} />

          {currentLevelOrder &&
            currentLevelOrder.map((item, index) => (
              <Box key={index} sx={{ mb: 2, padding: 0 }}>
                <Accordion disableGutters>
                  <StyledAccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    // darkMode={darkMode}
                    color={color}
                    id={item.object_id}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", flexGrow: 1 }}>
                        <Typography
                          sx={{
                            fontSize: "1.1rem",
                          }}
                        >
                          {item.version}
                        </Typography>
                        <Chip
                          label={renderChipText(
                            item.game_edition_id as GameEdition
                          )}
                          color="primary"
                          sx={{ ml: 2, px: 1, fontWeight: "bold" }}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSave(item);
                          }}
                        >
                          <Save />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEdit(item);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(item);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  </StyledAccordionSummary>
                  <AccordionDetails style={{ padding: 0 }}>
                    <DragDropContext
                      onDragEnd={(result, provided) =>
                        onDragEnd(result, provided, item.object_id)
                      }
                    >
                      <Droppable droppableId="area-orders">
                        {(topDropProvided) => (
                          <div
                            ref={topDropProvided.innerRef}
                            {...topDropProvided.droppableProps}
                          >
                            {item.ordered_level_ids?.map(
                              (id: string, index: number) => {
                                const level = levels?.find(
                                  (level: ILevel) => level.object_id === id
                                );

                                if (
                                  !!searchTerm &&
                                  !level?.name
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return null;
                                }

                                if (!level) {
                                  return (
                                    <BrokenLevelReference
                                      id={id}
                                      index={index}
                                      removeLevelId={removeLevelId}
                                    />
                                  );
                                }

                                const key = `${id}-${index}`;
                                return (
                                  <DraggableLevel
                                    addToLevelOrder={addLevelIds}
                                    modelOverride={gameModel}
                                    key={key}
                                    id={key}
                                    level={level}
                                    index={index}
                                    removeFromLevelOrder={removeFromLevelOrder}
                                    levels={levels}
                                    versionId={item.object_id}
                                    orderedLevelIds={
                                      versionLevelOrder.find(
                                        (obj) =>
                                          obj.object_id === item.object_id
                                      )?.ordered_level_ids ?? []
                                    }
                                    saveLevelOrder={(levelIds) =>
                                      saveLevelOrder(
                                        currentLevelOrder,
                                        levelIds,
                                        filters,
                                        item.ordered_level_ids
                                      )
                                    }
                                    page={levelPage}
                                    endpoint={Endpoints.LEVELS}
                                  />
                                );
                              }
                            )}

                            {topDropProvided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {item.ordered_level_ids?.length === 0 && (
                      <Alert severity="info">No levels in order</Alert>
                    )}

                    <DragDropContext
                      onDragEnd={(result, provided) =>
                        onDragEnd(result, provided)
                      }
                    >
                      <Droppable droppableId="not-in-order-levels">
                        {(topDropProvided) => (
                          <div
                            ref={topDropProvided.innerRef}
                            {...topDropProvided.droppableProps}
                          >
                            {allLevelIds.filter(
                              (id: string) =>
                                !item.ordered_level_ids?.includes(id)
                            ).length !== 0 && (
                              <>
                                <Typography
                                  sx={{
                                    marginTop: 4,
                                    flexGrow: 1,
                                    marginLeft: 2,
                                  }}
                                  variant="h5"
                                >
                                  Not in Level Order
                                </Typography>
                                <NotInLevelOrderLevels
                                  searchTerm={searchTerm}
                                  unaddedLevelIds={allLevelIds.filter(
                                    (id: string) =>
                                      !item.ordered_level_ids?.includes(id)
                                  )}
                                  levelIds={allLevelIds}
                                  levels={levels}
                                  modelOverride={gameModel}
                                  addToLevelOrder={addLevelIds}
                                  versionId={item.object_id}
                                  saveLevelOrder={(levelIds: string[]) =>
                                    saveLevelOrder(
                                      currentLevelOrder,
                                      levelIds,
                                      filters,
                                      item.ordered_level_ids
                                    )
                                  }
                                  orderedLevelIds={item.ordered_level_ids}
                                  page={levelPage}
                                  endpoint={Endpoints.LEVELS}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}

          {currentLevelOrder?.length === 0 && (
            <Alert severity="info">
              No versioned levels found for the selected game edition
            </Alert>
          )}
          <Tooltip title="Scroll to Not in Order Levels" placement="top">
            <Fab
              sx={{ position: "fixed", right: 50, bottom: 50 }}
              href="#not-in-order"
              color="secondary"
              aria-label="scroll"
            >
              <KeyboardDoubleArrowDown />
            </Fab>
          </Tooltip>
        </Box>
      ) : (
        <Flex width="100%" m={2} p={1} justifyContent="center">
          <CircularProgress />
        </Flex>
      )}
      {isNewOpen && (
        <ItemModal
          endpoint={Endpoints.LEVELS}
          defaultValueOverrides={{
            category_id: "default",
            level_type: "default",
          }}
          model={gameModel}
          isOpen={isNewOpen}
          onClose={() => setIsNewOpen(false)}
          isCreate={true}
          createItem={async (obj: any) => {
            try {
              let object: any = (await createItem(
                Endpoints.LEVELS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }

              const { url, objectPath } = getEndpointData(
                Endpoints.LEVELS,
                levelPage,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: [
                      ...((prev && prev[objectPath ?? ""]) ?? []),
                      object,
                    ],
                  };
                },
                false
              );

              setIsNewOpen(false);
            } catch (e) {
              console.error(e);
            }
          }}
          updateItem={async (obj: any) => {
            try {
              const object: any = (await updateItem(
                Endpoints.LEVELS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }
              const newArray = [...levels];
              const index = newArray.findIndex(
                (item) => item.object_id === obj.object_id
              );
              newArray[index] = object;
              const { url, objectPath } = getEndpointData(
                Endpoints.LEVELS,
                levelPage,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: newArray,
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          //@ts-ignore
          page={levelPage}
        />
      )}

      {isVersionNewOpen && (
        <ItemModal
          endpoint={Endpoints.VERSIONED_LEVELS}
          defaultValueOverrides={{
            category_id: "default",
            level_type: "default",
          }}
          initialFormValue={isCreate ? null : initialFormValues}
          model={versionModel}
          isOpen={isVersionNewOpen}
          onClose={() => {
            setIsVersionNewOpen(false);
            setIsCreate(true);
          }}
          isCreate={isCreate}
          createItem={async (obj: any) => {
            try {
              let object: any = (await createItem(
                Endpoints.VERSIONED_LEVELS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }

              const { url, objectPath } = getEndpointData(
                Endpoints.VERSIONED_LEVELS,
                versionedPage,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: [
                      ...((prev && prev[objectPath ?? ""]) ?? []),
                      object,
                    ],
                  };
                },
                false
              );

              setVersionLevelOrder([
                ...versionLevelOrder,
                object.versioned_level_order,
              ]);
            } catch (e) {
              console.error(e);
            }

            setIsVersionNewOpen(false);
          }}
          updateItem={async (obj: any) => {
            try {
              const object: any = (await updateItem(
                Endpoints.VERSIONED_LEVELS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }
              const newArray = [...levels];
              const index = newArray.findIndex(
                (item) => item.object_id === obj.object_id
              );
              newArray[index] = object;
              const { url, objectPath } = getEndpointData(
                Endpoints.VERSIONED_LEVELS,
                versionedPage,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: newArray,
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }

            setIsVersionNewOpen(false);
            setInitialFormValues({});
            setIsCreate(true);
          }}
          //@ts-ignore
          page={versionedPage}
        />
      )}
    </>
  );
};
