import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Link,
  Tooltip,
} from "@mui/material";
import { Alert } from "@mui/material";
import React, { Suspense, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet-async";
import { FaChevronRight, FaCog, FaSignOutAlt } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changelog } from "../changelog";
import { Footer } from "../components/Footer";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { Heading } from "../components/typography/Heading";
import { Paragraph } from "../components/typography/Paragraph";
import { LoginRoute } from "../constants/routes";
import {
  Environments,
  getEnvironmentName,
  getEnvKey,
} from "../data/AxiosConfig";
import {
  selectLoadingIFrame,
  setShowAllChangelogs,
} from "../features/general/generalSlice";
import { selectSession } from "../features/session/sessionSlice";
import meta from "../meta";
import { useConfig } from "../network/useConfig";
import { useMenuTrees } from "../network/useMenuTrees";
import { flattenPaths } from "../utils/flatttenPaths";
import { useLocation } from "react-router-dom";
import { useViewport } from "../utils/useViewport";
import { checkPermissions } from "./checkPermissions";
import { selectKeepSidebarOpen, setKeepSidebarOpen } from "./layoutSlice";
import { APP_NAME } from "../App";
import { FullScreenProgressBar } from "../features/AddToAll/components/FullScreenProgressBar";

interface LayoutProps {
  children: React.ReactNode;
}

const StylediFrame = styled.iframe`
  body {
    background-color: #1a202c !important;
  }

  #sidebar-wrapper {
    display: none;
  }
`;

export const Layout = ({ children }: LayoutProps) => {
  const { game_id: gameId, auth_data, session_id } = useSelector(selectSession);
  const isSuperuser = auth_data?.superuser ?? false;

  const loadingIFrame = useSelector(selectLoadingIFrame);

  const location = useLocation();

  const { games } = useConfig();

  const currentGameName = (gameId && games && games[gameId])?.replace(
    /\s*\(.*?\)\s*/g,
    ""
  );

  const isLoggedIn = !!session_id;

  const { mergedNavRoutes, isLoading: isLoadingMenuTrees } = useMenuTrees();

  const path = useLocation().pathname;
  const paths = path.split("/");
  paths.shift();

  const flattenedRoutes = useMemo(
    () =>
      flattenPaths(mergedNavRoutes)
        .filter((route) => route.name !== "Logout")
        .filter((route) =>
          checkPermissions(
            route.permissions,
            auth_data?.generated_permissions,
            isSuperuser
          )
        ),
    [auth_data, isSuperuser, mergedNavRoutes]
  );

  let pathArr: any[] = [];
  paths.forEach((path) => {
    const pathObject = flattenedRoutes.find(
      (route) => route.current_path === `/${path}`
    );

    if (pathObject) {
      pathArr.push(pathObject);
    }
  });

  const page = pathArr && pathArr.length !== 0 && pathArr[pathArr.length - 1];
  const pathArrClone = [...pathArr];
  pathArrClone.pop();

  // Get base game admin url
  const url = window.location.href;
  let baseAdminUrl =
    getEnvKey() === Environments.LIVE
      ? "https://old.admin.servers.kwalee.com"
      : "https://old.uat-admin.servers.kwalee.com";
  if (url.indexOf("localhost") > -1) {
    // Un-comment to enable local development
    //baseAdminUrl = "http://127.0.0.1:8888";
  }

  // Set page name
  const titlePrefix = `Game Admin`;

  const accountDropdown = [
    { id: "account", name: "Account Settings", icon: FaCog, path: "/account" },
    {
      id: "log-out",
      name: "Log Out",
      icon: FaSignOutAlt,
      path: LoginRoute,
      onClick: () => {
        // @ts-ignore
        store.dispatch(logout(false, undefined, location.pathname));
      },
    },
  ];

  const { width } = useViewport();
  const isMobile = width < 769;

  const dispatch = useDispatch();
  const keepSidebarOpen = useSelector(selectKeepSidebarOpen);

  const iframeExport =
    process.env.REACT_APP_IS_IFRAME === "true" || getAncestorOrigins();

  function getAncestorOrigins() {
    if (window.location.ancestorOrigins !== undefined) {
      return window.location.ancestorOrigins[0]?.includes("127.0.0.1:8888");
    }
    const urls = [];
    let parentWin = window;
    while (parentWin !== window.top) {
      if (parentWin.document.referrer) {
        try {
          const url = new URL(parentWin.document.referrer);
          urls.push(url.origin);
        } catch (e) {
          // console.error
        }
      }
      // @ts-ignore
      parentWin = parentWin.parent;
    }
    return urls.includes("127.0.0.1:8888");
  }

  const hideSidebar = false;
  const hideTitle = false;

  const isLoginPage = paths.includes("login");
  const isDiffTool = paths.includes("import-game-settings");

  const currentPath = useLocation().pathname;

  const filteredChangelogs = changelog.filter(
    (log) => log.pathName && currentPath === log.pathName
  );

  return (
    <Box display="flex">
      {!(hideSidebar || iframeExport) && (
        <Sidebar
          navItems={mergedNavRoutes}
          title={titlePrefix}
          version={`${meta.version}`}
          keepSidebarOpen={keepSidebarOpen}
          setKeepSidebarOpen={(value: boolean) =>
            dispatch(setKeepSidebarOpen(value))
          }
          accountDropdown={accountDropdown}
          isSuperuser={isSuperuser}
          isLoadingNavItems={isLoadingMenuTrees && isLoggedIn}
        />
      )}
      <Box
        height="100%"
        width="100%"
        mx="auto"
        px={page.isOldAdmin ? 0 : 4}
        pl={
          page.isOldAdmin
            ? 0
            : !isMobile && !(hideSidebar || iframeExport)
            ? keepSidebarOpen
              ? "320px"
              : "100px"
            : 4
        }
        maxWidth={
          page.isOldAdmin
            ? "none"
            : isDiffTool
            ? "2000px"
            : keepSidebarOpen
            ? "1600px"
            : "940px"
        }
      >
        <Box display="flex" flexDirection="column" height="99.6vh">
          <Box
            flex="1 0 auto"
            mt={!page.isOldAdmin ? (isMobile ? "65px" : "10px") : 0}
            mb={!page.isOldAdmin ? "10px" : 0}
          >
            {!(hideTitle || iframeExport) && !page.isOldAdmin && (
              <Box sx={{ marginY: 2 }}>
                <Breadcrumbs separator={<FaChevronRight />}>
                  {pathArr &&
                    pathArr.length === paths.length &&
                    pathArrClone.map(
                      (path) => path && <Link key={path.id}>{path.name}</Link>
                    )}
                </Breadcrumbs>

                {!isLoginPage && (
                  <Box display="flex" alignItems="baseline">
                    <Helmet>
                      <title>{`${page.name} ${
                        !!currentGameName ? `| ${currentGameName}` : ""
                      }  | ${getEnvironmentName()} ${APP_NAME}`}</title>
                    </Helmet>
                    <Heading>{page.name}</Heading>
                    <Tooltip
                      title={
                        filteredChangelogs.length === 0
                          ? "No Changelogs for this page"
                          : ""
                      }
                    >
                      <Box>
                        <Button
                          sx={{ marginLeft: 1 }}
                          disabled={filteredChangelogs.length === 0}
                          onClick={() => dispatch(setShowAllChangelogs(true))}
                        >
                          View Changelog
                        </Button>
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                {page.subtitle && (
                  <Box mb={1}>
                    <Paragraph>{page.subtitle}</Paragraph>
                  </Box>
                )}
              </Box>
            )}
            <ErrorBoundary
              FallbackComponent={({ error }: { error: any }) => {
                return error?.response?.status === 404 ? (
                  <Alert severity="info">No objects found</Alert>
                ) : (
                  <Alert severity="error">{error.message}</Alert>
                );
              }}
            >
              <Suspense
                fallback={
                  <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress />
                  </Box>
                }
              >
                {!page.isOldAdmin && <>{children}</>}
                {page.isOldAdmin && (
                  <>
                    {loadingIFrame && (
                      <FullScreenProgressBar spinner title="Loading..." />
                    )}
                    <StylediFrame
                      style={{
                        border: "none",
                        width: "100%",
                        height: "100%",
                        overflow: loadingIFrame ? "hidden" : "visible",
                      }}
                      src={`${baseAdminUrl}${page.oldAdminURL}`}
                    />
                  </>
                )}
              </Suspense>
            </ErrorBoundary>
          </Box>
          {!page.isOldAdmin && !isLoginPage && <Footer />}
        </Box>
      </Box>
    </Box>
  );
};
