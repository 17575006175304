import React, { useEffect } from "react";
import { fetchLogs, fetchConfig } from "./logThunks";
import { useDispatch, useSelector } from "react-redux";
import { selectLoadingLogs, QUEUE_NOTIFICATION } from "./logSlice";
import { Box } from "@mui/material";
import Filter from "./Filter/Filter";
import { ToastTypes } from "./ToastTypes";
import LogsTable from "./LogsTable/LogsTable";
import { UPDATE_FILTER } from "./Filter/filterSlice";
import { CircularProgress } from "@mui/material";

function LogsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleFetchLogs = async () => {
      dispatch(
        UPDATE_FILTER({
          key: "page",
          value: 1,
        })
      );
      dispatch(
        UPDATE_FILTER({
          key: "include_non_game_specific",
          value: true,
        })
      );
      const resultAction = await dispatch(
        fetchLogs({ page: 1, include_non_game_specific: true })
      );
      //@ts-ignore
      if (!fetchLogs.fulfilled.match(resultAction)) {
        //@ts-ignore
        const errorMessage = `Error fetching logs: ${resultAction.error.message}`;
        dispatch(
          QUEUE_NOTIFICATION({
            message: errorMessage,
            type: ToastTypes.ERROR,
            id: `error_fetch_logs`,
          })
        );
      }
    };

    const handleFetchConfig = async () => {
      const resultAction = await dispatch(fetchConfig());
      //@ts-ignore
      if (!fetchConfig.fulfilled.match(resultAction)) {
        //@ts-ignore
        const errorMessage = `Error fetching config: ${resultAction.error.message}`;
        dispatch(
          QUEUE_NOTIFICATION({
            message: errorMessage,
            type: ToastTypes.ERROR,
            id: `error_fetch_config`,
          })
        );
      }
    };

    handleFetchLogs();
    handleFetchConfig();
  }, [dispatch]);

  const loadingLogs = useSelector(selectLoadingLogs);

  return (
    <>
      <Filter />
      {loadingLogs ? (
        <Box sx={{ p: 5 }} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ pt: 3, margin: "auto", overflow: "auto", color: "black" }}>
          <LogsTable />
        </Box>
      )}
    </>
  );
}

export default LogsPage;
