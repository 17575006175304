import _ from "lodash";
import { mutate } from "swr";
import { store } from "../../app/store";
import Endpoints from "../../constants/endpoints";
import { ILeverOrder } from "../../model/level.model";
import { postData } from "../../network/request";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";

export const saveLevelOrder = (
  currentLevelOrder?: ILeverOrder[] | undefined,
  orderedLevelIds?: string[] | undefined,
  filters?: { [id: string]: any },
  levelOrders?: any
) => {
  const newLevelOrders = _.cloneDeep(levelOrders);

  const gameId = store.getState().session.game_id;

  if (currentLevelOrder) {
    const requestMap = currentLevelOrder.map((item: ILeverOrder) =>
      postData(
        `${Endpoints.LEVEL_ORDER.url}/${item?.object_id}/update`,
        { ordered_level_ids: orderedLevelIds },
        Endpoints.LEVEL_ORDER.single_object_path
      )
    );

    Promise.allSettled(requestMap)
      .then((response) => {
        store.dispatch(
          queueNotification({
            message: "Updated Level Order Successfully",
            options: {
              key: "update_level_order_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );

        const responseLevelOrder: any | undefined = (response[0] as any)?.value;

        const indexToReplace = newLevelOrders.findIndex(
          (order: ILeverOrder) =>
            order.object_id === responseLevelOrder?.object_id
        );

        newLevelOrders.splice(indexToReplace, 1, responseLevelOrder);

        mutate(
          [Endpoints.LEVEL_ORDER.url, gameId],
          async (prev: any) => {
            return {
              [Endpoints.LEVEL_ORDER.object_path]: newLevelOrders,
            };
          },
          false
        );

        return response;
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "update_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  } else {
    postData(
      `${Endpoints.LEVEL_ORDER.url}`,
      { ...filters, ordered_level_ids: orderedLevelIds },
      Endpoints.LEVEL_ORDER.single_object_path
    )
      .then((response) => {
        store.dispatch(
          queueNotification({
            message: "Updated Level Order Successfully",
            options: {
              key: "update_level_order_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );

        newLevelOrders.push(response);

        mutate(
          [Endpoints.LEVEL_ORDER.url, gameId],
          async (prev: any) => {
            return {
              [Endpoints.LEVEL_ORDER.object_path]: newLevelOrders,
            };
          },
          false
        );

        return response;
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "update_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  }
};
