import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
} from "@mui/material";
import { Alert } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { mutate } from "swr";
import { store } from "../../app/store";
import {
  CrudKeys,
  selectIsSaving,
} from "../../components/CollectionEditor/crudSlice";
import { validateForm } from "../../components/CollectionEditor/Fields/validateForm";
import { FormContent } from "../../components/CollectionEditor/FormContent";
import Endpoints from "../../constants/endpoints";
import { FieldType } from "../../model/crud.model";
import { ICampaign } from "../XpromoCampaigns/xpromo-campaign.model";
import { ICreative } from "./xpromo-creative.model";
import { postData } from "../../network/request";
import { useCampaigns } from "../../network/useCampaigns";
import { useConfig } from "../../network/useConfig";
import { useCreatives } from "../../network/useCreatives";
import { createDefaultObject } from "../../utils/createDefaultObject";
import { getEndpointData } from "../../utils/getEndpointData";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { getPageName } from "../XpromoCampaigns/components/RenewDialog";

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  page: keyof CrudKeys;
  formValue?: { [id: string]: any };
  setParentFormValue?: {
    setParentFormValue: (formValue: { [id: string]: any }) => void;
  };
}

export const StatusModal = ({
  isOpen,
  onClose,
  page,
  formValue,
  setParentFormValue,
}: StatusModalProps) => {
  const { config } = useConfig();
  const statusOptions = config?.promotion_creative.creative_status;

  const model = useMemo(
    () => [
      {
        main_field: true,
        display_name: "Status",
        name: "status",
        type: FieldType.SELECT,
        options: statusOptions,
        required: true,
        initialValue: formValue?.status,
      },
      {
        display_name: "Description",
        name: "description",
        type: FieldType.STRING,
        defaultValue: "",
      },
      {
        display_name: "OS Version",
        name: "os_version",
        type: FieldType.STRING,
        defaultValue: "",
      },
      {
        display_name: "Device Name",
        name: "device_name",
        type: FieldType.STRING,
        defaultValue: "",
      },
    ],
    [formValue?.status, statusOptions]
  );

  useEffect(() => {
    setFormValue({
      ...createDefaultObject(model ?? []),
    });
  }, [formValue?.status, model]);
  const { campaigns } = useCampaigns();
  const { creatives } = useCreatives();

  const [newValues, setFormValue] = useState<{ [id: string]: any }>({});
  const [error, setError] = useState<
    { message: string; campaigns: ICampaign[] } | undefined
  >();
  const isSaving = useSelector(selectIsSaving);
  const cancelRef = React.createRef<HTMLButtonElement>();

  const updateStatus = (statusUpdate: { [id: string]: any }) => {
    postData(
      `admin/xpromo-creatives/${formValue?.object_id}/status`,
      statusUpdate,
      "status",
      config
    )
      .then((response) => {
        store.dispatch(
          queueNotification({
            message: "Updated Successfully",
            options: {
              key: "update_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );

        // Update creative with new status
        const newCreatives: ICreative[] = [...creatives];

        // Replace creative with old
        const index = newCreatives.findIndex(
          (creative) => creative.object_id === formValue?.object_id
        );

        newCreatives[index].status = response;

        const { url, objectPath } = getEndpointData(
          Endpoints.XPROMO_CREATIVES,
          page
        );

        mutate(
          url,
          async (prev: any) => {
            return {
              [objectPath]: newCreatives,
            };
          },
          false
        );

        setParentFormValue &&
          setParentFormValue["setParentFormValue"](newCreatives[index]);

        onClose();

        return response;
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response?.data.error_message,
            options: {
              key: "update_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>Update Status</DialogTitle>

      <DialogContent>
        <p>Select the values below to set the status.</p>

        <FormContent
          formValue={newValues}
          model={model}
          page={page ?? ""}
          setFormValue={setFormValue}
        />

        {error && (
          <Box mt={3}>
            <Alert severity="warning">
              {error.message}
              {error.campaigns.map((campaign) => (
                <>
                  <br />
                  <small>
                    <strong>{campaign.object_id}</strong> {campaign.name}
                  </small>
                </>
              ))}
            </Alert>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button ref={cancelRef} onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={isSaving[getPageName(page)]}
          color="primary"
          variant="contained"
          onClick={() => {
            setError(undefined);
            // Get all campaigns that use this creative
            const objectId = formValue?.object_id;
            const filteredCampaigns = campaigns.filter(
              (campaign: ICampaign) => campaign.xpromo_creative_id === objectId
            );

            const inUse = filteredCampaigns
              .map((campaign: ICampaign) => campaign.xpromo_creative_id)
              .includes(objectId);

            // Check if switched to failed
            if (newValues.status === "failed" && inUse) {
              // If campaign is in use then show error message

              store.dispatch(
                queueNotification({
                  message:
                    "Creative is currently in use in, cannot be changed to failed",
                  options: {
                    key: "creative_in_use",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
              setError({
                message:
                  "Status cannot be changed to failed as this Creative is currently in use in the following campaigns:",
                campaigns: filteredCampaigns,
              });
            } else {
              if (!validateForm(newValues, model)) {
                store.dispatch(
                  queueNotification({
                    message: "Please fill in all required fields",
                    options: {
                      key: "invalid_form",
                      variant: SnackbarVariant.ERROR,
                    },
                  })
                );
              } else {
                // Update status
                updateStatus(newValues);
              }
            }
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
