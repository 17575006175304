import { IWhatsNewNote } from "./model/navigation.model";

export const changelog: IWhatsNewNote[] = [
  {
    version: "0.2.15",
    date: "2023-02-24",
    pathName: "/ops/admin-config",
    title: "Added a new Admin config page.",
    description: "Configure Admin config using a new page.",
  },
  {
    version: "0.2.14",
    date: "2023-02-20",
    pathName: "/game-settings/game-settings",
    title: "Added Link to settings docs.",
    description:
      "Added an option in setting option (three dots) to access documentation related to a game setting.",
  },
  {
    version: "0.2.13",
    date: "2023-02-16",
    pathName: "/",
    title: "Limit max reloads in an iframe",
    description:
      "Added a max number of reloads possible when passing auth data to iframe",
  },
  {
    version: "0.2.12",
    date: "2023-02-15",
    pathName: "/game-settings/game-settings",
    title: "Fix diff issue in Game settings",
    description:
      "Fix diff issue when importing game settings from one platform to another after accepting all changes on the Array",
  },
  {
    version: "0.2.10",
    date: "2023-02-14",
    pathName: "/levels/edit-levels",
    title: "Added reorder support to stage ids.",
    description: "The support for reordering stage ids is added now.",
  },
  {
    version: "0.2.9",
    date: "2023-02-09",
    pathName: "/game-settings/game-settings",
    title: "Fixed background colour issue when changing platforms.",
    description:
      "The background colour change related to the platform selected on Game settings page should not reflect on other pages.",
  },
  {
    version: "0.2.8",
    date: "2023-02-03",
    pathName: "/game-settings/game-settings",
    title: "Export Settings modal to let you select a setting",
    description:
      "Replace select settings buttons with Select settings dropdown in Export settings.",
  },
  {
    version: "0.2.7",
    date: "2023-02-02",
    pathName: "/",
    title: "Fix Rate Limiting Axios Interceptor",
    description:
      "Fix rate limiting concurrency issue that arised after splitting interceptors in separate files",
  },
  {
    version: "0.2.6",
    date: "2023-01-30",
    pathName: "/levels/level-areas",
    title: "Area object Imageresource",
    description: "Add Image resource dropdown in Level Areas.",
  },
  {
    version: "0.2.5",
    date: "2023-01-04",
    pathName: "/levels/level-areas",
    title: "Category field Removed",
    description:
      "Category ID filter is removed level order and level area pages.",
  },
  {
    version: "0.2.4",
    date: "2022-12-14",
    pathName: "/levels/level-areas",
    title: "Multiple platform support",
    description: "Search, save and update multiple platforms at once",
    features: [
      {
        title: "Select game edition",
        description:
          "Game edition dropdown now have iOS and Andrioid checked in by default.",
      },
      {
        title: "Save / Update areas to multiple game editions",
        description:
          "After creating areas, clicking on Save Area Order will save area orders for multiple game editions that are selected in the dropdown.",
      },
    ],
  },
  {
    version: "0.2.3",
    date: "2022-12-14",
    pathName: "/levels/edit-levels",
    title: "Multiple platform support",
    description: "Search, save and update multiple platforms at once",
    features: [
      {
        title: "Select game edition",
        description:
          "Game edition dropdown now have iOS and Andrioid checked in by default.",
      },
      {
        title: "Save / Update levels to multiple game editions",
        description:
          "After creating levels, clicking on Save Level Order will save level orders for multiple game editions that are selected in the dropdown.",
      },
    ],
  },
  {
    version: "0.2.29",
    date: "2022-12-05",
    pathName: "/ops/ops-tools",
    title: "Add CPI Category",
    description: "Added CPI Category viewing and create restriction.",
  },
  {
    version: "0.2.28",
    date: "2022-11-30",
    pathName: "/ops/ops-tools",
    title: "Add a game creation page",
    description: "You can now create games using a new game creation page.",
    features: [
      {
        title: "Add a game",
        description: "Click on the Add button to create new games.",
      },
      {
        title: "Add multiple games",
        description:
          "Click on Add multiple games button to create bulk games for CPI tests (only visible for users with Producer role)",
      },
      {
        title: "Update Games name and category",
        description: "Click on the row or 3 dots to update game information.",
      },
    ],
  },
  {
    version: "0.2.27",
    date: "2022-11-11",
    pathName: "/levels/edit-levels",
    title: "Display server name of field",
    description:
      "You can now change view the server name for each level field by hoverring the label.",
  },
  {
    version: "0.2.21",
    date: "2022-11-03",
    pathName: "/marketing/xpromo-campaigns",
    title: "Add QA status support to the Xpromo Campaigns",
    description:
      "You can now change / update QA status in the Xpromo Campaigns.",
  },
  {
    version: "0.2.2",
    date: "2022-10-28",
    pathName: "/levels/edit-levels",
    title: "Copy Level Order from one category / platform to another",
    description:
      "You can now copy level order from one category / platform to another",
    features: [
      {
        title: "Copy level order button",
        description:
          "On clicking the Copy level order button, a modal pops up with an option to copy level order has been provided.",
      },
    ],
  },
  {
    version: "0.2.19",
    date: "2022-10-24",
    pathName: "/levels/edit-levels",
    title: "Levels Page Duplicate Level Support",
    description: "You can now have duplicate level ids in the level order",
    features: [
      {
        title: "Duplicate reference",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-24+at+15.57.27.png",
        description:
          'In the three dots menu you can select the "Duplicate ref" options',
      },
      {
        title: "Floating action button",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-24+at+15.58.12.png",
        description:
          "Jump to out of level order levels has been moved to a floating action point button, click this button to be scrolled to the bottom of the page where not in level order levels are",
      },
    ],
  },
  {
    version: "0.2.15",
    date: "2022-10-14",
    pathName: "/marketing/xpromo-campaigns",
    title: "Running Campaigns Warnings",
    description:
      "Campaigns that have a QA status of failed will now display a warning",
  },
  {
    version: "0.2.13",
    date: "2022-10-14",
    pathName: "/game-settings/game-app",
    title: "Game App Notification changes",
    description: "Removed React Toastify",
  },
  {
    version: "0.2.12",
    date: "2022-10-13",
    pathName: "/ops/data-logs",
    title: "Data Logs",
    description: "User column to show by default",
  },
  {
    version: "0.2.11",
    date: "2022-10-10",
    pathName: "/game-settings/game-app",
    title: "Game App Updates",
    description: "Fixed light mode issue in the Game app Delete Modal",
    features: [
      {
        title: "Game App Delete Modal Light Mode Fix",
        imageUrl: "",
        description:
          "Support for light and dark mode is added for the Game app delete modal.",
      },
    ],
  },
  {
    version: "0.2.10",
    date: "2022-10-05",
    pathName: "/levels/edit-levels",
    title: "Levels Page Updates",
    description: "Quality of life updates to the Levels page",
    features: [
      {
        title: "Jump to out of level order levels",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-05+at+14.49.52.png",
        description:
          "You can now click Go to out of level order levels to jump directly to levels that are not currently in the order",
      },
      {
        title: "Adding levels to specific position in level order",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-05+at+14.50.47.png",
        description:
          "By clicking the 3 dots on the right of a level you can change its order or add it to a certain position from the not in level order levels section",
      },
      {
        title: "Search support",
        description: "Use the text box to search for a specific level",
      },
      {
        title: "Fix for duplicating levels",
      },
      {
        title: "Display broken level reference",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-05+at+14.51.47.png",
        description:
          "If somehow a level id is saved in the order with no level in the backend associated you can now remove this",
      },
    ],
  },
  {
    version: "0.2.9",
    date: "2022-10-03",
    pathName: "/levels/edit-levels",
    title: "Levels Page Overhaul",
    imageUrl:
      "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-03+at+16.25.48.png",
    description: "Level Setup and Level Order are now merged into one page",
    features: [
      {
        title: "Drag levels",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/drag-levels.gif",
        description:
          "You can now use the drag squares on the left to reorder levels",
      },
      {
        title: "Adding levels",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-03+at+16.38.30.png",
        description:
          "Use the top left button to add new levels, this levels will be automatically added to the end of the page, outside of the level order",
      },
      {
        title: "Adding and removing levels from the Level Order",
        imageUrl:
          "https://kwalee-creative.s3.eu-west-1.amazonaws.com/game-admin-changlog-images/Screenshot+2022-10-03+at+16.39.09.png",
        description:
          "Use the button on the right to remove or add a level to the level order",
      },
    ],
  },
];
