import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { SWRConfig } from "swr";
import App from "./App";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import EmotionTheme from "./theme/EmotionTheme";
import { HelmetProvider } from "react-helmet-async";
import "./data";

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <EmotionTheme>
          <SWRConfig
            value={{
              dedupingInterval: 5 * 60 * 1000,
              shouldRetryOnError: false,
            }}
          >
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </SWRConfig>
        </EmotionTheme>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
