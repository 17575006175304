import { axiosConfig, getServerURL } from "../../data/AxiosConfig";
import { GameId } from "../../model/sidebar.model";
import {
  getData,
  getRequestAdditions,
  postData,
  RequestAdditions,
} from "../../network/request";
import { Endpoint } from "../../model/types";
import { store } from "../../app/store";
import {
  queueNotification,
  SnackbarVariant,
} from "../../features/notification/notificationSlice";
import { setIsSaving } from "./crudSlice";

export const fetcher = (
  endpoint: Endpoint,
  params?: { [id: string]: any },
  mutate?: (array: any[]) => void
) => {
  return getData(
    endpoint.url,
    { params },
    undefined,
    undefined,
    endpoint.object_path
  )
    .then((response) => (mutate ? mutate(response) : response))
    .catch((error) => {
      if (error.response) {
        const errorInfo = new Error(
          "An error occurred while fetching the data."
        );
        // Attach extra info to the error object.
        //@ts-ignore
        errorInfo.info = error.response.data.error_message;
        //@ts-ignore
        errorInfo.status = error.response.status;
        throw errorInfo;
      }
    });
};

function getFormDataFromObj(obj: { [id: string]: any }): FormData {
  const formData = new FormData();

  Object.entries(obj).forEach(([key, value]) => {
    if (key === "image") {
      // @ts-ignore
      formData.append(key, value, "image.png");
    } else {
      // @ts-ignore
      formData.append(key, value);
    }
  });

  return formData;
}

export function setSaving(object_path: string, value?: boolean) {
  //Dispatch isSavingCrudItem
  store.dispatch(setIsSaving({ key: object_path, value }));
}

export function createItem(
  endpoint: Endpoint,
  obj: any,
  isFormData?: boolean,
  gameId?: GameId
) {
  let data = obj;

  const singleObjectPath = gameId
    ? endpoint.single_object_path?.replace("{game_id}", gameId ?? "")
    : endpoint.single_object_path;

  const objectPath = gameId
    ? endpoint.object_path?.replace("{game_id}", gameId ?? "")
    : endpoint.object_path;

  const url = gameId
    ? endpoint.url?.replace("{game_id}", gameId ?? "")
    : endpoint.url;

  setSaving(objectPath, true);

  // If form data convert to form data
  if (isFormData) {
    data = getFormDataFromObj(obj);
  }

  return postData(url, data, singleObjectPath)
    .then((response) => {
      setSaving(objectPath, false);

      store.dispatch(
        queueNotification({
          message: "Created Successfully",
          options: {
            key: "update_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      setSaving(objectPath, undefined);

      return response;
    })
    .catch((err) => {
      setSaving(objectPath, false);

      store.dispatch(
        queueNotification({
          message: err.response.data.error_message,
          options: {
            key: "create_error",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      setSaving(objectPath, undefined);
    });
}

export async function createItems(
  endpoint: Endpoint,
  arr: any[],
  isFormData?: boolean
) {
  const serverValues = [];
  for (const obj of arr) {
    let data = obj;

    setSaving(endpoint.object_path, true);

    // If form data convert to form data
    if (isFormData) {
      data = getFormDataFromObj(obj);
    }

    const response = await postData(endpoint.url, data)
      .then((response) => {
        return response[endpoint?.single_object_path ?? ""];
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "create_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });

    serverValues.push(response);
  }
  setSaving(endpoint.object_path, false);

  store.dispatch(
    queueNotification({
      message: "Created Successfully",
      options: {
        key: "update_notification",
        variant: SnackbarVariant.SUCCESS,
      },
    })
  );

  setSaving(endpoint.object_path, undefined);

  return serverValues;
}

export async function deleteItems(endpoint: Endpoint, arr: any[]) {
  const serverValues = [];
  for (const obj of arr) {
    setSaving(endpoint.object_path, true);

    const response = await postData(`${endpoint.url}/${obj.object_id}/delete`)
      .then((response) => {
        return response[endpoint?.object_path ?? ""];
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "create_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );

        setSaving(endpoint.object_path, undefined);
      });

    serverValues.push(response);
  }
  setSaving(endpoint.object_path, false);

  store.dispatch(
    queueNotification({
      message: "Multiple Items Deleted Successfully",
      options: {
        key: "update_notification",
        variant: SnackbarVariant.SUCCESS,
      },
    })
  );

  setSaving(endpoint.object_path, undefined);

  return serverValues;
}

export function deployItem(endpoint: Endpoint, obj: any) {
  setSaving(endpoint.object_path, true);

  return postData(`${endpoint.url}/${obj.object_id}/deploy`)
    .then((response) => {
      setSaving(endpoint.object_path, false);

      store.dispatch(
        queueNotification({
          message: "Updated Successfully",
          options: {
            key: "update_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      setSaving(endpoint.object_path, undefined);

      return response;
    })
    .catch((err) => {
      setSaving(endpoint.object_path, false);

      store.dispatch(
        queueNotification({
          message: err.response.data.error_message,
          options: {
            key: "deploy_error",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      setSaving(endpoint.object_path, undefined);
    });
}

export async function updateItems(
  endpoint: Endpoint,
  arr: any[],
  gameId?: GameId
) {
  const serverValues = [];
  const objectPath = gameId
    ? endpoint.object_path?.replace("{game_id}", gameId ?? "")
    : endpoint.object_path;

  const singleObjectPath = gameId
    ? endpoint.single_object_path?.replace("{game_id}", gameId ?? "")
    : endpoint.single_object_path;

  for (const obj of arr) {
    setSaving(objectPath, true);

    const response = await postData(
      `${endpoint.url}/${obj.object_id}/update`,
      obj,
      singleObjectPath
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "create_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );

        setSaving(objectPath, undefined);
      });

    serverValues.push(response);
  }
  setSaving(objectPath, false);

  store.dispatch(
    queueNotification({
      message: "Multiple Items Updated Successfully",
      options: {
        key: "update_notification",
        variant: SnackbarVariant.SUCCESS,
      },
    })
  );

  setSaving(objectPath, undefined);

  return serverValues;
}

export function updateItem(
  endpoint: Endpoint,
  obj: any,
  isFormData?: boolean,
  gameId?: GameId,
  exact?: boolean
) {
  const API_URL = getServerURL();

  let data = obj;
  let config = {
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  };

  const objectPath = gameId
    ? endpoint.object_path?.replace("{game_id}", gameId ?? "")
    : endpoint.object_path;

  const singleObjectPath = gameId
    ? endpoint.single_object_path?.replace("{game_id}", gameId ?? "")
    : endpoint.single_object_path;

  setSaving(objectPath, true);

  // If form data convert to form data
  if (isFormData) {
    data = getFormDataFromObj(obj);
    config = {
      baseURL: API_URL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }

  let url =
    endpoint.url === "/admin/game"
      ? `${endpoint.url}/${obj.object_id}`
      : `${endpoint.url}/${obj.object_id}/update`;

  if (exact) url = endpoint.url;

  return postData(url, data, singleObjectPath, config)
    .then((response) => {
      setSaving(objectPath, false);

      store.dispatch(
        queueNotification({
          message: "Updated Successfully",
          options: {
            key: "update_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      setSaving(objectPath, undefined);

      return response;
    })
    .catch((err) => {
      setSaving(objectPath, false);

      store.dispatch(
        queueNotification({
          message: err.response.data.error_message,
          options: {
            key: "update_error",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      setSaving(objectPath, undefined);
    });
}

export function deleteUser(url: string) {
  return axiosConfig.post(url).then((response) => {
    store.dispatch(
      queueNotification({
        message: "Deleted Successfully",
        options: {
          key: "delete_notification",
          variant: SnackbarVariant.SUCCESS,
        },
      })
    );
  });
}

export function deleteItem(url: string) {
  const savingUrl = url.split("/")[1].replace("/", "");
  setSaving(savingUrl, true);

  return postData(url)
    .then((response) => {
      setSaving(savingUrl, false);

      store.dispatch(
        queueNotification({
          message: "Deleted Successfully",
          options: {
            key: "delete_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      setSaving(savingUrl, undefined);

      return response;
    })
    .catch((err) => {
      setSaving(savingUrl, false);

      store.dispatch(
        queueNotification({
          message: err.response.data.error_message,
          options: {
            key: "delete_error",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      setSaving(savingUrl, undefined);

      return false;
    });
}

export function uploadFile(
  url: string,
  file: any,
  objectId: string,
  savingString: string
) {
  const API_URL = getServerURL();

  setSaving(`${savingString}`, true);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("filename", file.name);
  formData.append("field_name", "stages.url");
  formData.append("object_id", objectId);

  const config = {
    baseURL: API_URL,
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  // Append request additions
  const requestAdditions = getRequestAdditions();
  Object.keys(requestAdditions).forEach((key) => {
    formData.append(key, requestAdditions[key as keyof RequestAdditions]);
  });

  return axiosConfig
    .post(url, formData, config)
    .then((response) => {
      setSaving(savingString, false);

      store.dispatch(
        queueNotification({
          message: "Uploaded Successfully",
          options: {
            key: "upload_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      setSaving(savingString, undefined);

      return response;
    })
    .catch((err) => {
      setSaving(savingString, false);

      store.dispatch(
        queueNotification({
          message: err.response?.data.error_message,
          options: {
            key: "upload_error",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      setSaving(savingString, undefined);
    });
}
