import { IconButton, TextField } from "@mui/material";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { FaEdit } from "react-icons/all";
import { Flex } from "../../components/Flex";
import { Environments, getEnvKey } from "../../data/AxiosConfig";
import { FieldType, Form } from "../../model/crud.model";
import { useConfig } from "../../network/useConfig";
import { useCreatives } from "../../network/useCreatives";
import { useGameEditions } from "../../network/useGameEditions";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import { ICreative } from "../XpromoCreatives/xpromo-creative.model";
import { QAModal } from "./QAModal";

type XpromoCampaignsModelProps = {
  setCreativesModalOpen: (isOpen: string | undefined) => void;
};

export const XpromoCampaignsModel = ({
  setCreativesModalOpen,
}: XpromoCampaignsModelProps) => {
  const { creatives } = useCreatives();

  const { config } = useConfig();
  const { gameEditions } = useGameEditions();

  const games = config?.games_available as Record<string, string> | undefined;
  const countries = config?.countries;
  const adTypes = config?.promotion_creative.ad_type;
  const campaignTypes = config?.promotion_creative.campaign_type;
  const statusOptions = config?.promotion_creative.creative_status;
  const country_groups = config?.country_groups;

  const [formValue, setFormValue] = useState<
    { [id: string]: any } | undefined
  >();

  const [statusIsOpen, setStatusIsOpen] = useState(false);

  const formatCountries = (countries: any, country_groups: any) => {
    if (country_groups && countries) {
      const items = Object.entries(country_groups?.groups).map(
        ([key, value]) => {
          return {
            key: key,
            value: value,
          };
        }
      );

      const resp = items.map((i: any) => {
        return i.value.map((k: any) => ({
          group: i.key,
          value: k,
          label: countries?.find((j: any) => j.value === k).label,
        }));
      });

      return [
        { group: "Select All", value: "select-all", label: "Select All" },
        ...resp.flat(),
      ];
    }
  };

  const formattedCountries = useMemo(
    () => formatCountries(countries, country_groups),
    [countries, country_groups]
  );

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const [creativeOptionsLabel, setCreativeOptionsLabel] =
    useState<keyof ICreative>("name");

  const creativeOptions =
    creatives
      ?.sort((a: ICreative, b: ICreative) =>
        a?.game_edition_id?.localeCompare(b.game_edition_id)
      )
      .map((creative: ICreative) => ({
        label: creative[creativeOptionsLabel],
        value: creative.object_id,
      })) ?? [];

  const campaignModel: Form = [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      type: FieldType.STRING,
    },
    {
      display_name: "Campaign Type",
      name: "campaign_type",
      type: FieldType.SELECT,
      options: campaignTypes,
      required: true,
      defaultValue: "testing",
    },
    {
      display_name: "Xpromo Creative",
      name: "xpromo_creative_id",
      type: FieldType.SELECT,
      options: creativeOptions,
      changeAutocompleteLabel: (label: keyof ICreative) =>
        setCreativeOptionsLabel(label),
      autoCompleteLabelValue: creativeOptionsLabel,
      groupBy: (option) => {
        const creative = creatives.find(
          (creative: ICreative) => creative.object_id === option.value
        );

        return gameEditionOptions[creative.game_edition_id];
      },
      renderOptionOverride: (option) => {
        const creative = creatives.find(
          (creative: ICreative) => creative.object_id === option.value
        );

        const gameEdition = gameEditionOptions[creative.game_edition_id];
        const adType = adTypes[creative.ad_type];
        const game = games?.[creative.target_game_id];
        const status = statusOptions[creative.status];
        const objectId = creative.object_id;

        return (
          <Flex justifyContent="space-between" width="100%">
            <div>
              {option.label}{" "}
              <small style={{ fontWeight: 200 }}>
                {creativeOptionsLabel === "name" ? objectId : creative.name}
              </small>
              <br />
              <small>
                <strong>{status}</strong> {`${gameEdition}, ${adType}, ${game}`}
              </small>
            </div>
            <IconButton
              style={{ pointerEvents: "all" }}
              onClick={(event) => {
                setCreativesModalOpen(option.value);
                event.stopPropagation();
              }}
            >
              <FaEdit />
            </IconButton>
          </Flex>
        );
      },
      isOptionDisabled: (option) =>
        creatives.find((creative: ICreative) => creative.object_id === option)
          .status !== "approved",
      required: true,
      defaultValue: "",
    },
    {
      display_name: "QA Status",
      name: "qa_status",
      type: FieldType.BUTTON_MODAL,
      notAvailableOnCreate: true,
      options: statusOptions,
      onChangeOverride: (update: any, formValue: { [id: string]: any }) => {
        setFormValue({ ...formValue, ...update });
        setStatusIsOpen(true);
      },
      modal: (setParentFormValue: any) => {
        return (
          <QAModal
            page="xpromo_campaigns"
            isOpen={statusIsOpen}
            onClose={() => setStatusIsOpen(false)}
            formValue={formValue}
            setParentFormValue={setParentFormValue}
          />
        );
      },
      defaultValue: "",
    },
    {
      display_name: "Click URL",
      name: "click_url",
      type: FieldType.STRING,
      notAvailableOnCreate: true,
    },
    {
      display_name: "Campaign Identifier",
      name: "campaign_name",
      type: FieldType.STRING,
      notAvailableOnCreate: true,
    },
    {
      display_name: "Start",
      name: "start",
      type: FieldType.DATETIME,
      defaultValue: moment().valueOf(),
      locale: false,
    },
    {
      display_name: "End",
      name: "end",
      type: FieldType.DATETIME,
      defaultValue: null,
      locale: false,
      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          end: isNaN(value.end) || value.end === "undefined" ? null : value.end,
        };
      },
    },
    {
      display_name: "Is Active",
      name: "is_active",
      type: FieldType.CHECKBOX,
      defaultValue: false,
    },
    {
      display_name: "Countries",
      name: "country_codes",
      type: FieldType.MULTISELECT,
      selectOptions: formattedCountries,
      limitTags: 10,
      id: "country_autocomplete",
      label: "Country List",
      placeholder: "countries",
      disabled: false,
      needGrouping: true,
      selectSize: "small",
    },
    {
      display_name: "Games",
      name: "game_ids",
      id: "game_autocomplete",
      selectOptions: [
        { label: "Select All", value: "select-all" },
        ...Object.entries(games ?? {}).map(([key, value]) => ({
          label: value,
          value: key,
        })),
      ],
      label: "Games List",
      disabled: false,
      selectSize: "small",
      needGrouping: false,
      type: FieldType.MULTISELECT,
      options: games,
    },
    {
      display_name: "Budget",
      name: "budget",
      type: FieldType.NUMBER,
    },
    {
      display_name: "Daily Budget",
      name: "daily_budget",
      type: FieldType.CUSTOM,
      main_field: false,
      valueFunction: (obj: {
        end: moment.MomentInput;
        start: moment.MomentInput;
        budget: number;
        daily_budget: number;
      }) => {
        if (obj?.end) {
          const days = moment(obj?.end).diff(moment(obj?.start), "days");
          return obj?.budget / days;
        } else {
          return obj?.daily_budget;
        }
      },
      renderOverride: (value, onChangeFunction, fieldName, formValue) => {
        return (
          <Flex>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              sx={{ width: "100%" }}
              disabled={formValue?.end !== null}
              placeholder={`Enter ${fieldName}...`}
              type="number"
              value={isNaN(value) || value === "undefined" ? 0 : value}
              onChange={(event) => {
                return onChangeFunction({
                  [fieldName]: Number(event.target.value),
                });
              }}
            />
          </Flex>
        );
      },
    },

    {
      display_name: "Remaining Day Budget",
      name: "remaining_day_budget",
      type: FieldType.NUMBER,
      disabledOnCreate: true,
    },
    {
      display_name: "Spend",
      name: "spend",
      type: FieldType.NUMBER,
      disabled: true,
    },
    {
      display_name: "Budget Updated On",
      name: "budget_updated_on",
      type: FieldType.DATETIME,
      disabled: true,
      defaultValue: null,
      locale: false,
    },
    {
      display_name: "Budget Interval",
      name: "budget_interval_ms",
      type: FieldType.TIMEINPUT,
      defaultValue: 900000,
    },
    {
      display_name: "Localised Fields",
      name: "localised_fields",
      hideLabel: true,
      type: FieldType.MULTIPLEVIEWS,
      errorFunction: (obj: {
        campaign_type: string;
        margin_per_country: { [x: string]: any };
        min_bids_per_country: { [x: string]: any };
        max_bids_per_country: { [x: string]: any };
        probabilities_per_country: { [id: string]: any };
        min_ltvs_per_country: { [id: string]: any };
      }) => {
        const { min_bids_per_country, max_bids_per_country } = obj;

        let localisedObject: { [id: string]: any };
        if (obj.campaign_type === "testing" || obj.campaign_type === "boost") {
          localisedObject = obj?.probabilities_per_country;
        } else {
          localisedObject = obj?.min_ltvs_per_country;
        }

        const hasOnlyDefaultCountry = (localisedObject?: {
          [id: string]: any[];
        }) => {
          const keys = Object.keys(localisedObject ?? {});

          if (keys.length !== 1) {
            return false;
          }

          if (keys[0] !== "DEFAULT") {
            return false;
          }

          return true;
        };

        // Min
        const arrayLengthsNotEqualMin =
          min_bids_per_country?.DEFAULT?.length !==
          localisedObject?.DEFAULT?.length;

        const onlyDefaultCountryMin =
          hasOnlyDefaultCountry(min_bids_per_country) &&
          hasOnlyDefaultCountry(localisedObject);

        // Max
        const arrayLengthsNotEqualMax =
          max_bids_per_country?.DEFAULT?.length !==
          localisedObject?.DEFAULT?.length;

        const onlyDefaultCountryMax =
          hasOnlyDefaultCountry(max_bids_per_country) &&
          hasOnlyDefaultCountry(localisedObject);

        // If probabilities_per_country length doesn't equal min_bids_per_country or max_bids_per_country
        if (
          arrayLengthsNotEqualMin ||
          !onlyDefaultCountryMin ||
          arrayLengthsNotEqualMax ||
          !onlyDefaultCountryMax
        ) {
          return {
            error: true,
            message:
              "Advanced view does not match simplified view, modifying from the simple view will override Advanced view",
          };
        }

        return {
          error: false,
        };
      },
      views: [
        {
          title: "Simple",
          fields: [
            {
              display_name: "Impression Count",
              name: "impression_count",
              type: FieldType.NUMBER,
              valueFunction: (obj: {
                max_bids_per_country: { DEFAULT: string | any[] };
              }) => {
                return obj?.max_bids_per_country?.DEFAULT?.length;
              },
              onChangeOverride: (
                value: { impression_count: number },
                obj: {
                  min_bid: any;
                  max_bid: any;
                  probability: any;
                  min_ltv: any;
                  campaign_type: string;
                }
              ) => {
                let calculatedLTVValue = [];
                let calculatedMinBidValue = [];
                let calculatedBidValue = [];
                let calculatedProbValue = [];

                for (let i = 0; i < value.impression_count; i++) {
                  calculatedMinBidValue.push(obj.min_bid ?? 0);
                  calculatedBidValue.push(obj.max_bid ?? 0);
                  calculatedProbValue.push(obj.probability ?? 0);
                  calculatedLTVValue.push(obj.min_ltv ?? 0);
                }

                let returnObj: { [id: string]: any } = {
                  impression_count: value.impression_count,
                };

                if (
                  obj.campaign_type === "testing" ||
                  obj.campaign_type === "boost"
                ) {
                  returnObj = {
                    ...returnObj,
                    min_bids_per_country: { DEFAULT: calculatedMinBidValue },
                    max_bids_per_country: { DEFAULT: calculatedBidValue },
                    probabilities_per_country: { DEFAULT: calculatedProbValue },
                  };
                } else {
                  returnObj = {
                    ...returnObj,
                    min_bids_per_country: { DEFAULT: calculatedMinBidValue },
                    max_bids_per_country: { DEFAULT: calculatedBidValue },
                    min_ltvs_per_country: { DEFAULT: calculatedLTVValue },
                  };
                }

                return returnObj;
              },
            },
            {
              display_name: "Probability",
              name: "probability",
              type: FieldType.PROBABILITY,
              valueFunction: (obj: {
                probabilities_per_country: { DEFAULT: any[] };
              }) => {
                return !!obj?.probabilities_per_country?.DEFAULT &&
                  obj.probabilities_per_country.DEFAULT.length > 0
                  ? obj.probabilities_per_country.DEFAULT[0]
                  : 0;
              },
              hiddenFunction: (value: any, obj: any) =>
                obj.campaign_type === "ml",
              onChangeOverride: (
                value: { probability: any },
                obj: { impression_count: number }
              ) => {
                let calculatedValue = [];

                for (let i = 0; i < obj.impression_count; i++) {
                  calculatedValue.push(value.probability);
                }

                return {
                  probabilities_per_country: { DEFAULT: calculatedValue },
                  probability: value.probability,
                };
              },
            },
            {
              display_name: "Min LTV",
              name: "min_ltv",
              type: FieldType.NUMBER,
              valueFunction: (obj: {
                min_ltvs_per_country: { DEFAULT: any[] };
              }) => {
                return !!obj?.min_ltvs_per_country?.DEFAULT &&
                  obj.min_ltvs_per_country.DEFAULT.length > 0
                  ? obj.min_ltvs_per_country.DEFAULT[0]
                  : 0;
              },
              hiddenFunction: (value: any, obj: any) =>
                obj.campaign_type === "testing" ||
                obj.campaign_type === "boost",
              onChangeOverride: (
                value: { min_ltv: any },
                obj: { impression_count: number }
              ) => {
                let calculatedValue = [];

                for (let i = 0; i < obj.impression_count; i++) {
                  calculatedValue.push(value.min_ltv);
                }

                return {
                  min_ltvs_per_country: { DEFAULT: calculatedValue },
                  min_ltv: value.min_ltv,
                };
              },
            },
            {
              display_name: "Min Bid",
              name: "min_bid",
              type: FieldType.NUMBER,
              valueFunction: (obj: {
                min_bids_per_country: { DEFAULT: any[] };
              }) => {
                return !!obj?.min_bids_per_country?.DEFAULT &&
                  obj.min_bids_per_country.DEFAULT.length > 0
                  ? obj.min_bids_per_country.DEFAULT[0]
                  : 0;
              },
              onChangeOverride: (
                value: { min_bid: any },
                obj: { impression_count: number }
              ) => {
                let calculatedValue = [];

                for (let i = 0; i < obj.impression_count; i++) {
                  calculatedValue.push(value.min_bid);
                }

                return {
                  min_bids_per_country: { DEFAULT: calculatedValue },
                  min_bid: value.min_bid,
                };
              },
            },
            {
              display_name: "Max Bid",
              name: "max_bid",
              type: FieldType.NUMBER,
              valueFunction: (obj: {
                max_bids_per_country: { DEFAULT: any[] };
              }) => {
                return !!obj?.max_bids_per_country?.DEFAULT &&
                  obj.max_bids_per_country.DEFAULT.length > 0
                  ? obj.max_bids_per_country.DEFAULT[0]
                  : 0;
              },
              onChangeOverride: (
                value: { max_bid: any },
                obj: { impression_count: number }
              ) => {
                let calculatedValue = [];

                for (let i = 0; i < obj.impression_count; i++) {
                  calculatedValue.push(value.max_bid);
                }

                return {
                  max_bids_per_country: { DEFAULT: calculatedValue },
                  max_bid: value.max_bid,
                };
              },
            },
            {
              display_name: "Bid Adjustment",
              name: "pseudo_margin",
              type: FieldType.NUMBER,
              valueFunction: (obj: {
                margin_per_country: { DEFAULT: number };
              }) => {
                return obj?.margin_per_country?.DEFAULT
                  ? obj.margin_per_country.DEFAULT
                  : 0;
              },
              onChangeOverride: (value: { pseudo_margin: any }) => {
                return {
                  margin_per_country: { DEFAULT: value.pseudo_margin },
                  pseudo_margin: value.pseudo_margin,
                };
              },
            },
          ],
        },
        {
          title: "Advanced",
          fields: [
            {
              display_name: "Probabilities Per Country",
              name: "probabilities_per_country",
              importValueFromJSON: true,
              type: FieldType.OBJECT,
              hiddenFunction: (value: any, obj: any) =>
                obj.campaign_type === "ml",
              itemModel: [
                {
                  useAtomicValue: true,
                  display_name: "Values",
                  name: "values",
                  type: FieldType.ARRAY,
                  defaultValue: [],
                  itemModel: [
                    {
                      useAtomicValue: true,
                      display_name: "Probability",
                      name: "probability",
                      type: FieldType.PROBABILITY,
                      defaultValue: 0,
                    },
                  ],
                },
              ],
              keyModel: {
                name: "country",
                display_name: "Country",
                type: FieldType.SELECT,
                options: countries,
                defaultValue: "",
              },
              defaultValue: {},
            },
            {
              display_name: "Minimum LTVs Per Country",
              name: "min_ltvs_per_country",
              importValueFromJSON: true,
              type: FieldType.OBJECT,
              hiddenFunction: (value: any, obj: any) =>
                obj.campaign_type === "testing" ||
                obj.campaign_type === "boost",
              itemModel: [
                {
                  useAtomicValue: true,
                  display_name: "Values",
                  name: "values",
                  type: FieldType.ARRAY,
                  defaultValue: [],
                  itemModel: [
                    {
                      useAtomicValue: true,
                      display_name: "LTV",
                      name: "ltv",
                      type: FieldType.NUMBER,
                      defaultValue: 0,
                    },
                  ],
                },
              ],
              keyModel: {
                name: "country",
                display_name: "Country",
                type: FieldType.SELECT,
                options: countries,
                defaultValue: "",
              },
              defaultValue: {},
            },
            {
              display_name: "Minimum Bids Per Country",
              name: "min_bids_per_country",
              hidden: getEnvKey() === Environments.LIVE,
              importValueFromJSON: true,
              type: FieldType.OBJECT,
              itemModel: [
                {
                  useAtomicValue: true,
                  display_name: "Values",
                  name: "values",
                  type: FieldType.ARRAY,
                  defaultValue: [],
                  itemModel: [
                    {
                      useAtomicValue: true,
                      display_name: "Bid",
                      name: "bid",
                      type: FieldType.NUMBER,
                      defaultValue: 0,
                    },
                  ],
                },
              ],
              keyModel: {
                name: "country",
                display_name: "Country",
                type: FieldType.SELECT,
                options: countries,
                defaultValue: "",
              },
              defaultValue: {},
            },
            {
              display_name: "Bid Adjustment Per Country",
              name: "margin_per_country",
              importValueFromJSON: true,
              type: FieldType.OBJECT,
              itemModel: [
                {
                  display_name: "Bid Adjustment",
                  name: "value",
                  type: FieldType.NUMBER,
                  defaultValue: 0,
                },
              ],
              keyModel: {
                name: "country",
                display_name: "Country",
                type: FieldType.SELECT,
                options: countries,
                defaultValue: "",
              },
              defaultValue: {},
            },
            {
              display_name: "Maximum Bids Per Country",
              name: "max_bids_per_country",
              importValueFromJSON: true,
              type: FieldType.OBJECT,
              itemModel: [
                {
                  useAtomicValue: true,
                  display_name: "Values",
                  name: "values",
                  type: FieldType.ARRAY,
                  defaultValue: [],
                  itemModel: [
                    {
                      useAtomicValue: true,
                      display_name: "Bid",
                      name: "bid",
                      type: FieldType.NUMBER,
                      defaultValue: 0,
                    },
                  ],
                },
              ],
              keyModel: {
                name: "country",
                display_name: "Country",
                type: FieldType.SELECT,
                options: countries,
                defaultValue: "",
              },
              defaultValue: {},
            },
          ],
        },
      ],
    },
  ];

  return campaignModel;
};
