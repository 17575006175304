import {
  Breadcrumbs,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Link,
  Box,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/all";
import { useSelector } from "react-redux";
import { store } from "../../../app/store";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../features/notification/notificationSlice";
import { ExtendDialog } from "../../../features/XpromoCampaigns/components/ExtendDialog";
import { RenewDialog } from "../../../features/XpromoCampaigns/components/RenewDialog";
import { StopCampaignDialog } from "../../../features/XpromoCampaigns/components/StopCampaignDialog";
import {
  isCampaignExpired,
  isCampaignRunning,
} from "../../../features/XpromoCampaigns/campaignStatus";
import { FormItem, MultipleFormValue } from "../../../model/crud.model";
import { Endpoint, INestedRoute } from "../../../model/types";
import { CopyableText } from "../../CopyableText";
import { DialogWrapper } from "../../DialogWrapper";
import { validateForm } from "../Fields/validateForm";
import { AutoModalCloser } from "./AutoModalCloser";
import { ConfirmDialog, getPageName } from "./ConfirmDialog";
import { CrudKeys, selectIsSaving } from "../crudSlice";
import { FormContent } from "../FormContent";

interface ItemModalProps {
  index?: number;
  isOpen: boolean;
  initialFormValue?: any;
  onClose: () => void;
  isCreate?: boolean;
  createItem?: (obj: any) => void;
  updateItem?: (obj: any, index?: number) => void;
  model?: FormItem[];
  page: keyof CrudKeys;
  defaultValueOverrides?: { [id: string]: any };
  parentOnChange?: (
    update: { [id: string]: any },
    parentObject?: { [id: string]: any }
  ) => void;
  parentObject?: any;
  nestedRouteArray?: INestedRoute[];
  setHasChanged?: (hasChanged: boolean) => void;
  hasChanged?: boolean;
  onModalOpen?: (formValue: { [id: string]: any }) => void;
  onModalClose?: (formValue: { [id: string]: any }) => void;
  endpoint?: Endpoint;
  multipleFormValues?: Record<string, MultipleFormValue>;
  setMultipleFormValues?: (obj: any) => void;
}

export function ItemModal({
  index,
  isOpen,
  initialFormValue,
  onClose,
  isCreate,
  createItem,
  updateItem,
  model,
  page,
  defaultValueOverrides,
  parentOnChange,
  parentObject,
  nestedRouteArray,
  onModalOpen,
  onModalClose,
  multipleFormValues,
  setMultipleFormValues,
}: ItemModalProps) {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const isSaving = useSelector(selectIsSaving);
  const [renewIsOpen, setRenewIsOpen] = useState(false);
  const [stopIsOpen, setStopIsOpen] = useState(false);
  const [extendIsOpen, setExtendIsOpen] = useState(false);

  const [formValue, setFormValue] = useState(initialFormValue ?? {});

  useEffect(() => {
    setFormValue(initialFormValue ?? {});
  }, [initialFormValue]);

  const stopCampaign = () => {
    const currentTime = moment().valueOf();
    const newFormValue = { ...formValue, end: currentTime };
    setFormValue(newFormValue);
    updateItem && updateItem(newFormValue, index);
  };

  const newOnClose = () => {
    if (hasChanged) {
      setConfirmIsOpen(true);
    } else {
      onModalClose && onModalClose(initialFormValue);
      onClose();
    }
  };

  const isInvalid = Object.keys(validateForm(formValue, model)).length !== 0;

  useEffect(() => {
    if (multipleFormValues) {
      const newFormValue: Record<string, any> = {};
      // Set form values
      Object.keys(multipleFormValues).forEach((key) => {
        if (!multipleFormValues[key].different) {
          newFormValue[key] = multipleFormValues[key].value;
        }
      });

      setFormValue(newFormValue);
    }
  }, [multipleFormValues]);

  return (
    <>
      <DialogWrapper isOpen={isOpen} onClose={newOnClose}>
        <AutoModalCloser onClose={onClose} page={page} />
        <DialogContent>
          <DialogTitle>
            {!isCreate && <CopyableText text={formValue.object_id} />}
            <br />
            {!isCreate && page === "xpromo_campaigns" && (
              <CopyableText
                text={`${window.location.href}?id=${formValue.object_id}`}
              />
            )}

            <Box mt={2}>
              <Breadcrumbs separator={<FaChevronRight color="gray.500" />}>
                {initialFormValue &&
                  nestedRouteArray &&
                  nestedRouteArray.map((route) => {
                    if (!initialFormValue[route.name]) {
                      return null;
                    }
                    return (
                      <Link key={route.name}>
                        {initialFormValue[route.name]}
                      </Link>
                    );
                  })}
              </Breadcrumbs>
            </Box>
          </DialogTitle>
          <DialogContent>
            <FormContent
              multipleFormValues={multipleFormValues}
              setMultipleFormValues={setMultipleFormValues}
              formValue={formValue}
              isCreate={isCreate}
              index={index}
              model={model}
              updateItem={updateItem}
              page={page ?? ""}
              parentOnChange={parentOnChange}
              setFormValue={setFormValue}
              parentFormValue={parentObject}
              defaultValueOverrides={defaultValueOverrides}
              setHasChanged={setHasChanged}
              initialFormValue={initialFormValue}
              onModalOpen={onModalOpen}
              validObj={validateForm(formValue, model)}
            />
          </DialogContent>

          <DialogActions>
            {page === "xpromo_campaigns" && formValue?.object_id && (
              <>
                <Button
                  disabled={
                    isSaving.xpromo_campaigns ||
                    !isCampaignRunning(formValue) ||
                    hasChanged
                  }
                  onClick={() => setExtendIsOpen(true)}
                >
                  Extend
                </Button>
                <Button
                  disabled={
                    isSaving.xpromo_campaigns ||
                    !isCampaignRunning(formValue) ||
                    hasChanged
                  }
                  onClick={() => setStopIsOpen(true)}
                >
                  Stop
                </Button>
                <Button
                  disabled={
                    isSaving.xpromo_campaigns ||
                    !isCampaignExpired(formValue) ||
                    hasChanged
                  }
                  onClick={() => setRenewIsOpen(true)}
                >
                  Renew
                </Button>
              </>
            )}
            <Button disabled={isSaving[getPageName(page)]} onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={
                isSaving[getPageName(page)] ||
                (isInvalid && !multipleFormValues) ||
                initialFormValue?.paused
              }
              color="primary"
              variant="contained"
              onClick={() => {
                if (isInvalid && !multipleFormValues) {
                  store.dispatch(
                    queueNotification({
                      message: "Please fill in all required fields",
                      options: {
                        key: "invalid_form",
                        variant: SnackbarVariant.ERROR,
                      },
                    })
                  );
                } else {
                  if (multipleFormValues) {
                    updateItem && updateItem(formValue);
                  } else if (isCreate) {
                    createItem && createItem(formValue);
                  } else {
                    updateItem && updateItem(formValue, index);
                  }

                  setHasChanged && setHasChanged(false);
                }
              }}
            >
              {multipleFormValues
                ? "Update multiple"
                : isCreate
                ? "Create"
                : "Save"}
            </Button>
          </DialogActions>
        </DialogContent>
      </DialogWrapper>
      <StopCampaignDialog
        page={page}
        isOpen={stopIsOpen}
        onClose={() => setStopIsOpen(false)}
        formValue={formValue}
        stopCampaign={stopCampaign}
      />
      <ExtendDialog
        page={page}
        isOpen={extendIsOpen}
        onClose={() => setExtendIsOpen(false)}
        formValue={formValue}
        updateItem={updateItem}
      />
      <RenewDialog
        page={page}
        isOpen={renewIsOpen}
        onClose={() => setRenewIsOpen(false)}
        formValue={formValue}
        createItem={createItem}
      />
      <ConfirmDialog
        page="changes_made"
        isOpen={confirmIsOpen}
        onClose={() => setConfirmIsOpen(false)}
        object={initialFormValue}
        deployItem={() => {
          onModalClose && onModalClose(initialFormValue);
          onClose();
          if (!isCreate) {
            setFormValue(initialFormValue);
          }
          setConfirmIsOpen(false);
          setHasChanged(false);
        }}
      />
    </>
  );
}
