import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import Endpoints from "../../constants/endpoints";
import { FieldType, Form } from "../../model/crud.model";
import { useRestrictedGroupManager } from "../../network/useRestrictedGroupManager";
import { useUserAccounts } from "../../network/useUserAccounts";

export const RestrictedGroupManager = () => {
  const { userAccounts, isLoading } = useUserAccounts();
  const { restrictedGroupManager } = useRestrictedGroupManager();

  const model: Form = [
    {
      display_name: "Select User",
      name: "restricted_user_id",
      type: FieldType.SELECT,
      main_field: true,
      defaultValue: "",
      options: Object.values(userAccounts ?? [])?.map((i, idx) => ({
        value: i.object_id,
        label: i.email,
      })),

      onChangeOverride: (value: any, obj: any) => {
        return {
          ...obj,
          restricted_user_id: value.restricted_user_id,
          restricted_user_email: Object.values(userAccounts ?? [])
            ?.map((i, idx) => ({
              value: i.object_id,
              label: i.email,
            }))
            .find((k) => k.value === value.restricted_user_id)?.label,
        };
      },
    },
  ];

  return (
    <CollectionEditor
      response={restrictedGroupManager}
      page="restricted_event_group_managers"
      isLoading={isLoading}
      displayNameTemplate={`{restricted_user_id}`}
      model={model}
      endpoint={Endpoints.RESTRICTED_GROUP_MANAGERS}
    />
  );
};
