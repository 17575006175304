import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import SettingsContainer from "../features/AddToAll/components/Setting/SettingsContainer";
import { NestedSetting } from "../model/versioned-settings.model";

interface SettingsDialogProps {
  isOpen?: boolean;
  countryFilter?: string[];
  readOnly?: boolean;
  onClose: () => void;
  settings?: NestedSetting;
  text?: string;
}

export const SettingsDialog = ({
  isOpen,
  readOnly,
  countryFilter,
  onClose,
  settings,
  text,
}: SettingsDialogProps) => {
  return (
    <Dialog fullWidth maxWidth="md" open={!!isOpen} onClose={onClose}>
      <Box maxHeight="200px" overflow="auto">
        <DialogTitle>{text}</DialogTitle>
      </Box>

      <DialogContent>
        <SettingsContainer
          setting={settings}
          readOnly={readOnly}
          countryFilter={countryFilter}
        />
      </DialogContent>
    </Dialog>
  );
};
