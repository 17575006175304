import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSearchParams<T extends string>() {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(
    () =>
      Object.fromEntries(
        new URLSearchParams(location.search).entries()
      ) as Record<T, string | undefined>,
    [location.search]
  );
  const setSearchParams = useCallback(
    (params: Record<string, string> | URLSearchParams) => {
      if (!(params instanceof URLSearchParams)) {
        params = new URLSearchParams(Object.entries(params));
      }
      history.push({ search: params.toString() });
    },
    [history]
  );

  return [searchParams, setSearchParams] as const;
}
