import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Flex } from "../../../components/Flex";

export const StyledProgressBarPopup = styled.div`
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  display: flex;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  padding: 0 5rem;
  background: rgba(27, 30, 33, 0.7);

  .progress {
    display: flex;
    width: 600px;
    flex-direction: column;
    justify-content: center;

    .col-form-label-sm {
      color: white;
    }

    .progress {
      width: 600px;
      height: 30px;
    }

    .progress-bar {
      height: 30px;
    }
  }

  small {
    color: white;
  }
`;

export const FullScreenProgressBar = ({
  current,
  total,
  title,
  spinner,
}: {
  current?: number;
  total?: number;
  title: string;
  spinner?: boolean;
}) => {
  const value = (current ?? 0) / (total ?? 0);
  const percentage = value * 100;

  return (
    <StyledProgressBarPopup>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        {spinner && (
          <Box mb={2}>
            <CircularProgress />
          </Box>
        )}
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
        {(current || total) && (
          <Box className="progress">
            <LinearProgress
              variant="determinate"
              style={{ height: 10, borderRadius: 5 }}
              value={isNaN(percentage) ? 0 : percentage}
            />
            <small>{`${Math.floor(
              isNaN(percentage) ? 0 : percentage
            )} %`}</small>
          </Box>
        )}
      </Flex>
    </StyledProgressBarPopup>
  );
};
