import isNullOrUndefined from "./isNullOrUndefined";

export function validateVersionedSettings<T>(obj: T): any {
  // Remove undefined values and add hidden property to each setting
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      return obj
        .filter((el) => !isNullOrUndefined(el))
        .map((el) => validateVersionedSettings(el)) as never as T;
    }

    return Object.fromEntries(
      Object.entries(obj)
        .filter(([key, value]) => !isNullOrUndefined(value))
        .map(([key, value]) => [key, validateVersionedSettings(value)])
    );
  }

  return obj;
}
