import {
  alpha,
  Button,
  Chip,
  darken,
  Divider,
  lighten,
  Tooltip,
  Collapse,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import { GameId } from "../../model/sidebar.model";
import { Endpoint } from "../../model/types";
import { INestedKeys } from "../../model/types";
import {
  CollectionEditorOptions,
  IExternalObjectForNesting,
  StatusBadge,
} from "../../model/crud.model";
import { getEndpointData } from "../../utils/getEndpointData";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { Flex } from "../Flex";
import CollectionEditor, { searchFilter } from "./CollectionEditor";
import { CrudKeys, selectSearchField, selectSearchTerm } from "./crudSlice";
import { ParentCollapseEditor } from "./ParentCollapseEditor";

interface Props {
  key: string;
  nestedRouteArray: { name: string; value: any }[];
  obj: any;
  response: any;
  model: any;
  filterObjectIdArray: string[];
  endpoint: Endpoint;
  page: keyof CrudKeys;
  defaultValueOverrides?: { [id: string]: any };
  type?: string;
  displayKey: string;
  collectionEditorOptions?: CollectionEditorOptions;
  nestedKeys?: INestedKeys;
  allNestedKeys?: INestedKeys;
  lastLevel?: boolean;
  lastLevelChildCount?: number;
  lastLevelChildObjects?: any[];
  keyOverrides?: IExternalObjectForNesting;
  currentLevel?: any;
  updateItem: (
    endpoint: Endpoint,
    obj: any,
    isFormData?: boolean,
    gameId?: GameId
  ) => void;
  deleteItem: (url: string, obj: any) => boolean;
  deleteItems: (endpoint: Endpoint, arr: any) => void;
  createItem: (
    endpoint: Endpoint,
    obj: any,
    isFormData?: boolean,
    gameId?: GameId
  ) => void;
  deployItem: (endpoint: Endpoint, obj: any) => void;
  currentGameId?: GameId;
  displayNameTemplate?: string;
  statusBadges?: StatusBadge[];
  statusBadgeCounts?: { [id: string]: number };
  hideZeroElements?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  let btnBackground = lighten(theme.palette.background.paper, 0.05);
  const invalidBtnBackground = "rgba(160,13,13,0.3)";
  const color = theme.palette.text.primary;
  let collapseBackground = alpha(theme.palette.background.paper, 0.4);
  const invalidBackground = "rgba(212, 17, 17, 0.3)";

  const themeKey = theme.palette.mode;

  if (themeKey === "light") {
    btnBackground = "#ffffff";
    collapseBackground = "#efefef";
  }

  return {
    collapseButton: (props: { error?: boolean }) => ({
      color,
      background: props.error ? invalidBtnBackground : btnBackground,
      borderRadius: "4px",
      height: "50px",
      padding: "0.5rem 1rem",
      cursor: "pointer",
      transition: "background-color 0.1s ease-in",
      border: `1px solid ${darken(
        props.error ? invalidBtnBackground : btnBackground,
        0.1
      )}`,
      marginTop: "0",
      justifyContent: "flex-start",
      "&:hover": {
        background: darken(
          props.error ? invalidBtnBackground : btnBackground,
          0.05
        ),
      },
    }),
    collapseBackground: (props: { error?: boolean }) => ({
      background: props.error ? invalidBackground : collapseBackground,
      borderRadius: "4px",
      width: "100%",
      marginTop: "1rem",
    }),
  };
});

export const CollapsableCollectionEditor = ({
  obj,
  response,
  model,
  filterObjectIdArray,
  defaultValueOverrides,
  endpoint,
  page,
  type,
  displayKey,
  collectionEditorOptions,
  nestedKeys,
  allNestedKeys,
  nestedRouteArray,
  lastLevel,
  lastLevelChildCount = 0,
  lastLevelChildObjects,
  keyOverrides,
  currentLevel,
  updateItem,
  deleteItem,
  createItem,
  deployItem,
  deleteItems,
  currentGameId,
  displayNameTemplate,
  statusBadges,
}: Props) => {
  const isErrorCollapse = type === "error";

  const searchTerm = useSelector(selectSearchTerm);
  const searchField = useSelector(selectSearchField);

  const [open, setOpen] = useState<boolean>(false);

  const classes = useStyles({ error: isErrorCollapse });

  let newFilterObjectIdArray = filterObjectIdArray;

  if (lastLevel) {
    newFilterObjectIdArray = [];
    response
      ?.filter((item: any) => !isNullOrUndefined(item))
      ?.filter((obj?: any) =>
        searchFilter(
          searchTerm,
          searchField,
          model,
          displayNameTemplate,
          obj,
          page,
          keyOverrides
        )
      )
      .forEach((item: { [x: string]: any }) => {
        let element = item;
        let overrideElement = item;

        // If keyOverrides then use the item[externalObjectForNesting.fieldName] as item
        if (keyOverrides) {
          overrideElement = keyOverrides.data.find(
            (object) => object.object_id === item[keyOverrides.fieldName]
          );
        }

        const functions = currentLevel?.functions;

        if (
          nestedRouteArray?.every((keyInfo) => {
            element = item;
            let value = item[keyInfo.name];

            if (
              keyOverrides?.keysToOverride.includes(keyInfo.name) &&
              !isNullOrUndefined(overrideElement)
            ) {
              element = overrideElement;
              value = element[keyInfo.name];
            }

            if (functions && currentLevel.key === keyInfo.name) {
              // Execute order 66
              Object.keys(functions).forEach((key) => {
                const func = functions[key];
                if (func(element)) {
                  value = key;
                }
              });
            }

            return value === keyInfo.value;
          })
        ) {
          newFilterObjectIdArray.push(element.object_id);
        }
      });
  }

  let statusBadgeCounts: { [id: string]: number } = {};

  if (statusBadges) {
    let objectIds: string[] | undefined = lastLevelChildObjects?.map(
      (obj: { object_id: any }) => obj.object_id
    );

    const filteredResponse = response.filter((item: any) =>
      objectIds?.includes(item.object_id)
    );

    // For each status count the total and add to object
    statusBadges.forEach((badgeInfo) => {
      filteredResponse.forEach((item: any) => {
        const isValid =
          currentLevel?.functions &&
          currentLevel?.functions[badgeInfo.value](item);

        if (!statusBadgeCounts.hasOwnProperty(badgeInfo.value)) {
          statusBadgeCounts[badgeInfo.value] = 0;
        }

        if (isValid) {
          statusBadgeCounts[badgeInfo.value]++;
        }
      });
    });
  }

  const count =
    !lastLevel && !isErrorCollapse
      ? lastLevelChildCount
      : newFilterObjectIdArray.length;

  return (
    <Box className={classes.collapseBackground} key={obj.code}>
      <Box>
        <Button
          className={classes.collapseButton}
          onClick={() => setOpen(!open)}
          fullWidth
          variant="contained"
          color="primary"
          disableElevation
          style={{ justifyContent: "space-between" }}
        >
          {obj[displayKey]}
          <Flex alignItems="center">
            <Tooltip title="Total" aria-label="remove">
              <Box ml="0.5rem">
                <Chip
                  label={count}
                  size="small"
                  color="primary"
                  style={{ minWidth: "30px" }}
                />
              </Box>
            </Tooltip>
            {statusBadgeCounts && Object.keys(statusBadgeCounts).length !== 0 && (
              <Flex ml="0.5rem">
                <Divider orientation="vertical" flexItem />
              </Flex>
            )}
            {statusBadgeCounts &&
              Object.keys(statusBadgeCounts).map((key) => {
                const value = statusBadgeCounts[key];
                const statusBadgeInfo =
                  statusBadges &&
                  statusBadges.find((badge) => badge.value === key);

                if (value && statusBadgeInfo?.renderBadge) {
                  return statusBadgeInfo.renderBadge();
                } else if (!value && statusBadgeInfo?.renderBadge) {
                  return null;
                }

                return (
                  <Tooltip
                    key={key}
                    title={statusBadgeInfo?.display_name ?? ""}
                    aria-label="remove"
                  >
                    <Box ml="0.5rem">
                      <Chip
                        label={value}
                        size="small"
                        style={{
                          opacity: value === 0 ? 0.5 : 1,
                          minWidth: "30px",
                          backgroundColor: statusBadgeInfo?.backgroundColor,
                          color: statusBadgeInfo?.color,
                        }}
                        color="primary"
                      />
                    </Box>
                  </Tooltip>
                );
              })}
          </Flex>
        </Button>
      </Box>

      <Collapse in={open}>
        {open && (
          <Box mx="1rem" mb="1rem">
            {nestedKeys && nestedKeys?.length >= 1 ? (
              <ParentCollapseEditor
                filterObjectIdArray={newFilterObjectIdArray}
                defaultValueOverrides={defaultValueOverrides}
                nestedKeys={nestedKeys}
                model={model}
                response={response}
                allNestedKeys={allNestedKeys}
                nestedRouteArray={nestedRouteArray}
                keyOverrides={keyOverrides}
                page={page}
                endpoint={endpoint}
                updateItem={updateItem}
                deleteItem={deleteItem}
                deleteItems={deleteItems}
                createItem={createItem}
                deployItem={deployItem}
                currentGameId={currentGameId}
                displayNameTemplate={displayNameTemplate}
                statusBadges={statusBadges}
              />
            ) : (
              <Box mt="1rem">
                <CollectionEditor
                  isError={isErrorCollapse}
                  currentGameId={currentGameId}
                  displayNameTemplate={displayNameTemplate}
                  keyOverrides={keyOverrides}
                  nestedRouteArray={nestedRouteArray}
                  defaultValueOverrides={defaultValueOverrides}
                  filterObjectIdArray={newFilterObjectIdArray}
                  page={page}
                  response={response}
                  model={model}
                  endpoint={endpoint}
                  deployItem={async (obj: any) => {
                    try {
                      const object: any = (await deployItem(
                        endpoint,
                        obj
                      )) as any;
                      if (isNullOrUndefined(object)) {
                        return;
                      }
                      const newArray = [...response];
                      const index = newArray.findIndex(
                        (item) => item.object_id === obj.object_id
                      );
                      newArray[index] = object;
                      const { url, objectPath } = getEndpointData(
                        endpoint,
                        page,
                        currentGameId
                      );

                      mutate(
                        url,
                        async (prev: any) => {
                          return {
                            [objectPath]: newArray,
                          };
                        },
                        false
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  onCreate={async (obj: any) => {
                    try {
                      let object: any = (await createItem(
                        endpoint,
                        obj,
                        false,
                        currentGameId
                      )) as any;
                      if (isNullOrUndefined(object)) {
                        return;
                      }

                      const { url, objectPath } = getEndpointData(
                        endpoint,
                        page,
                        currentGameId
                      );

                      mutate(
                        url,
                        async (prev: any) => {
                          return {
                            [objectPath]: [
                              ...(prev[objectPath ?? ""] ?? []),
                              object,
                            ],
                          };
                        },
                        false
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  onUpdate={async (obj: any) => {
                    try {
                      const object: any = (await updateItem(
                        endpoint,
                        obj,
                        false,
                        currentGameId
                      )) as any;
                      if (isNullOrUndefined(object)) {
                        return;
                      }
                      const newArray = [...response];
                      const index = newArray.findIndex(
                        (item) => item.object_id === obj.object_id
                      );
                      newArray[index] = object;
                      const { url, objectPath } = getEndpointData(
                        endpoint,
                        page,
                        currentGameId
                      );

                      mutate(
                        url,
                        async (prev: any) => {
                          return {
                            [objectPath]: newArray,
                          };
                        },
                        false
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  onDelete={async (obj: any) => {
                    try {
                      const success = await deleteItem(
                        `${endpoint.url}/${obj.object_id}/delete`,
                        obj
                      );
                      if (success) {
                        const newArray = [...response];

                        const index = newArray.findIndex(
                          (item) => item.object_id === obj.object_id
                        );

                        newArray.splice(index, 1);
                        const { url, objectPath } = getEndpointData(
                          endpoint,
                          page,
                          currentGameId
                        );

                        mutate(
                          url,
                          async (prev: any) => {
                            return {
                              [objectPath]: newArray,
                            };
                          },
                          false
                        );
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  deleteMultipleItems={async (arr: any[]) => {
                    const newArr = arr.filter((obj) =>
                      filterObjectIdArray?.includes(obj.object_id)
                    );

                    try {
                      await deleteItems(endpoint, arr);

                      // Remove from creatives array
                      const newArray = [...response];
                      for (const obj of newArr) {
                        const index = newArray.findIndex(
                          (item) => item.object_id === obj.object_id
                        );
                        newArray.splice(index, 1);
                      }

                      const { url, objectPath } = getEndpointData(
                        endpoint,
                        page,
                        currentGameId
                      );

                      mutate(
                        url,
                        async (prev: any) => {
                          return {
                            [objectPath]: newArray,
                          };
                        },
                        false
                      );
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                  collectionEditorOptions={collectionEditorOptions}
                  collapsableParentObject={obj}
                />
              </Box>
            )}
          </Box>
        )}
      </Collapse>
    </Box>
  );
};
