import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Search } from "../../components/Sidebar/Search";
import { JanusSourcePicker } from "./JanusSettingContainer";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const JanusSettingsSelector = ({ isOpen, handleClose }: Props) => {
  const [selectedSourceSettingsId, setSelectedSourceSettingsId] = useState<
    string | undefined
  >();

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>Select Setting</DialogTitle>
      <DialogContent>
        {/* <Search /> */}

        <JanusSourcePicker
          selected={selectedSourceSettingsId}
          onChange={(sourceId?: string) =>
            setSelectedSourceSettingsId(sourceId)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JanusSettingsSelector;
