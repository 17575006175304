import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  darken,
  useTheme,
  Alert,
  Typography,
  Divider,
  Fab,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";
import { FaSave } from "react-icons/all";
import { mutate } from "swr";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createItem,
  updateItem,
} from "../../components/CollectionEditor/crudService";
import { FormContent } from "../../components/CollectionEditor/FormContent";
import { ItemModal } from "../../components/CollectionEditor/Modals/ItemModal";
import { Flex } from "../../components/Flex";
import { SearchBar } from "../../components/SearchBar";
import Endpoints from "../../constants/endpoints";
import { FieldType, Form } from "../../model/crud.model";
import { ILevel, ILeverOrder } from "../../model/level.model";
import { useGameEditions } from "../../network/useGameEditions";
import { useLevelOrder } from "../../network/useLevelOrder";
import { useLevels } from "../../network/useLevels";
import { getEndpointData } from "../../utils/getEndpointData";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { reorder } from "../../utils/reorder";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import { useCategories } from "./hooks/useCategories";
import { useGameModel } from "./hooks/useGameModel";
import { DraggableLevel } from "./components/DraggableLevel";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { saveLevelOrder } from "./levelOrderService";
import { NotInLevelOrderLevels } from "./NotInLevelOrderLevels";
import { selectSession } from "../session/sessionSlice";
import { BrokenLevelReference } from "./components/BrokenLevelReference";
import CopyLevelOrderModal from "./components/CopyLevelOrderModal";

export const Levels = () => {
  const dispatch = useAppDispatch();
  const { game_id: currentGameId } = useAppSelector(selectSession);
  const page = `${currentGameId}_levels`;

  const categories = useCategories();

  const [removedLevels, setRemovedLevels] = useState<string[]>([]);

  const { gameEditions } = useGameEditions();
  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const filtersModel: Form = [
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.MULTISELECT,
      selectOptions: Object.entries(gameEditionOptions).map(([key, value]) => {
        return {
          label: value,
          value: key,
        };
      }),
      chipSize: "small",
      selectSize: "small",
      valueFunction(obj?) {
        return filters.game_edition_id;
      },
      label: "Game Edition",
      limitTags: 2,
      placeholder: "Game Editions",
      id: "game_editions",
      disabled: false,
      needGrouping: false,
    },
  ];

  const [filters, setFilters] = useState<{
    [id: string]: any;
  }>({
    game_edition_id: ["ios", "android"],
    category_id: "default",
  });

  const theme = useTheme();

  const { levels, isLoading: isLoadingLevels } = useLevels();
  const { levelOrders, isLoading: isLoadingLevelOrders } = useLevelOrder();

  const isLoading = isLoadingLevels || isLoadingLevelOrders;

  const currentLevelOrder: ILeverOrder[] | undefined = useMemo(() => {
    return levelOrders?.filter(
      (order: ILeverOrder) =>
        filters.game_edition_id.includes(order?.game_edition_id) &&
        order.category_id === filters.category_id
    );
  }, [levelOrders, filters]);

  const initialOrderedLevelIds: string[] = useMemo(
    () => [
      ...new Set(
        currentLevelOrder.map((item) => item?.ordered_level_ids).flat()
      ),
    ],
    [currentLevelOrder]
  );

  const [orderedLevelIds, setOrderedLevelIds] = useState(
    initialOrderedLevelIds
  );

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (initialOrderedLevelIds.length > 0) {
      setOrderedLevelIds(initialOrderedLevelIds);
    }
  }, [initialOrderedLevelIds]);

  const allLevelIds = levels.map((level: ILevel) => level.object_id);

  const unaddedLevelIds = allLevelIds.filter(
    (id: string) => !orderedLevelIds?.includes(id)
  );

  const addLevelIds = (
    ids: string[],
    versionId?: string,
    location?: number,
    idFilter?: (id: string, currentIndex?: number) => boolean
  ) => {
    if (location && location < 0) {
      dispatch(
        queueNotification({
          message:
            "Index is out of levels range, please specify a location 1 or above",
          options: {
            key: "invalid_index",
            variant: SnackbarVariant.ERROR,
          },
        })
      );
      return;
    }

    // Pass filter function if one is provided
    let newIds = [...(orderedLevelIds ?? [])].filter((id, index) =>
      idFilter ? idFilter(id, index) : true
    );

    newIds =
      !isNullOrUndefined(location) || (location && location > newIds.length)
        ? [
            ...newIds.slice(0, location - 1),
            ...ids,
            ...newIds.slice(location - 1),
          ]
        : newIds.concat(...ids);

    setOrderedLevelIds(newIds);
    const newRemovedLevels = [...removedLevels];
    ids.forEach((id) => {
      if (removedLevels.includes(id)) {
        const index = newRemovedLevels.findIndex(
          (removedId) => removedId === id
        );
        newRemovedLevels.splice(index, 1);
      }
    });

    setRemovedLevels(newRemovedLevels);
  };

  const removeLevelId = (index: number) => {
    let newIds = [...(orderedLevelIds ?? [])];
    newIds.splice(index, 1);

    setOrderedLevelIds(newIds);
  };

  const removeFromLevelOrder = (areaId: string) => {
    const index = orderedLevelIds?.findIndex((id: string) => id === areaId);

    if (!isNullOrUndefined(index)) {
      removeLevelId(index);
      const newRemovedAreas = [...removedLevels];
      if (!removedLevels.includes(areaId)) {
        // Add to removedAreas array
        newRemovedAreas.push(areaId);
      }

      setRemovedLevels(newRemovedAreas);
    }
  };

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newOrderedAreaIds = reorder(
      orderedLevelIds ?? [],
      result.source.index,
      result.destination.index
    );

    setOrderedLevelIds(newOrderedAreaIds);
  }

  const gameModel = useGameModel();
  const [isNewOpen, setIsNewOpen] = useState(false);

  const onSave = () => {
    //Update and save level orders object
    saveLevelOrder(currentLevelOrder, orderedLevelIds, filters, levelOrders);
  };

  return (
    <>
      <FormContent
        formValue={filters}
        model={filtersModel}
        page="level_order"
        setFormValue={setFilters}
        incorporateLabels
        inline
      />
      <Box
        mt={4}
        py={2}
        display="flex"
        position="sticky"
        top="0"
        bgcolor="background.default"
        justifyContent="space-between"
        zIndex={998}
      >
        <Box>
          <Button
            variant="outlined"
            onClick={async () => {
              setIsNewOpen(true);
            }}
            disabled={isLoading}
          >
            Add Level
          </Button>

          <CopyLevelOrderModal
            categories={categories}
            gameEditionOptions={gameEditionOptions}
            orderedLevelIds={orderedLevelIds}
            levelOrders={levelOrders}
            levelFilters={filters}
          />
        </Box>

        <Button
          startIcon={<FaSave />}
          onClick={() => {
            onSave();
          }}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Save Level Order
        </Button>
      </Box>
      {!isLoading ? (
        <Box
          bgcolor={darken(theme.palette.background.paper, 0.2)}
          p={2}
          borderRadius={4}
          mt={5}
          mb={3}
        >
          <SearchBar
            sx={{ marginBottom: 1 }}
            debounce
            value={searchTerm}
            setValue={(value: string) => setSearchTerm(value)}
          />
          <Divider sx={{ marginY: 2 }} />
          <DragDropContext
            onDragEnd={(result, provided) => onDragEnd(result, provided)}
          >
            <Droppable droppableId="area-orders">
              {(topDropProvided) => (
                <div
                  ref={topDropProvided.innerRef}
                  {...topDropProvided.droppableProps}
                >
                  {orderedLevelIds?.map((id: string, index: number) => {
                    const level = levels?.find(
                      (level: ILevel) => level.object_id === id
                    );

                    if (
                      !!searchTerm &&
                      !level?.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return null;
                    }

                    if (!level) {
                      return (
                        <BrokenLevelReference
                          id={id}
                          index={index}
                          removeLevelId={removeLevelId}
                        />
                      );
                    }

                    const key = `${id}-${index}`;
                    return (
                      <DraggableLevel
                        addToLevelOrder={addLevelIds}
                        modelOverride={gameModel}
                        key={key}
                        id={key}
                        level={level}
                        index={index}
                        removeFromLevelOrder={removeFromLevelOrder}
                        levels={levels}
                        orderedLevelIds={orderedLevelIds}
                        saveLevelOrder={(levelIds) =>
                          saveLevelOrder(
                            currentLevelOrder,
                            levelIds,
                            filters,
                            levelOrders
                          )
                        }
                        page={page}
                        endpoint={Endpoints.LEVELS}
                      />
                    );
                  })}

                  {topDropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {orderedLevelIds?.length === 0 && (
            <Alert severity="info">No levels in order</Alert>
          )}
          <DragDropContext
            onDragEnd={(result, provided) => onDragEnd(result, provided)}
          >
            <Droppable droppableId="not-in-order-levels">
              {(topDropProvided) => (
                <div
                  ref={topDropProvided.innerRef}
                  {...topDropProvided.droppableProps}
                >
                  {unaddedLevelIds.length !== 0 && (
                    <>
                      <Typography sx={{ marginTop: 4 }} variant="h3">
                        Not in Level Order
                      </Typography>
                      <NotInLevelOrderLevels
                        searchTerm={searchTerm}
                        unaddedLevelIds={unaddedLevelIds}
                        levelIds={allLevelIds}
                        levels={levels}
                        modelOverride={gameModel}
                        addToLevelOrder={addLevelIds}
                        saveLevelOrder={(levelIds: string[]) =>
                          saveLevelOrder(
                            currentLevelOrder,
                            levelIds,
                            filters,
                            levelOrders
                          )
                        }
                        orderedLevelIds={orderedLevelIds}
                        page={page}
                        endpoint={Endpoints.LEVELS}
                      />
                    </>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Tooltip title="Scroll to Not in Order Levels" placement="top">
            <Fab
              sx={{ position: "fixed", right: 50, bottom: 50 }}
              href="#not-in-order"
              color="secondary"
              aria-label="scroll"
            >
              <KeyboardDoubleArrowDown />
            </Fab>
          </Tooltip>
        </Box>
      ) : (
        <Flex width="100%" m={2} p={1} justifyContent="center">
          <CircularProgress />
        </Flex>
      )}
      {isNewOpen && (
        <ItemModal
          endpoint={Endpoints.LEVELS}
          defaultValueOverrides={{
            category_id: "default",
            level_type: "default",
          }}
          model={gameModel}
          isOpen={isNewOpen}
          onClose={() => setIsNewOpen(false)}
          isCreate={true}
          createItem={async (obj: any) => {
            try {
              let object: any = (await createItem(
                Endpoints.LEVELS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }

              const { url, objectPath } = getEndpointData(
                Endpoints.LEVELS,
                page,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: [
                      ...((prev && prev[objectPath ?? ""]) ?? []),
                      object,
                    ],
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          updateItem={async (obj: any) => {
            try {
              const object: any = (await updateItem(
                Endpoints.LEVELS,
                obj,
                false,
                currentGameId
              )) as any;
              if (isNullOrUndefined(object)) {
                return;
              }
              const newArray = [...levels];
              const index = newArray.findIndex(
                (item) => item.object_id === obj.object_id
              );
              newArray[index] = object;
              const { url, objectPath } = getEndpointData(
                Endpoints.LEVELS,
                page,
                currentGameId
              );

              mutate(
                url,
                async (prev: any) => {
                  return {
                    [objectPath]: newArray,
                  };
                },
                false
              );
            } catch (e) {
              console.error(e);
            }
          }}
          //@ts-ignore
          page={page}
        />
      )}
    </>
  );
};
