import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Flex } from "../../components/Flex";
import { SettingsDialog } from "../../components/SettingsDialog";
import {
  NestedSetting,
  NestedSettings,
  ValueType,
  VersionedSettings,
} from "../../model/versioned-settings.model";
import { createSettingTitle } from "../../utils/createSettingTitle";
import { getSettingsSources } from "../../utils/getSettingsSources";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { settingsSelector } from "../AddToAll/addToAllSlice";
import { selectCurrentPlatform } from "../general/generalSlice";

export interface SettingsSource {
  id: string;
  settingIds: string[];
  nested_settings?: NestedSettings;
}

interface SourcePickerProps {
  onChange: (sourceId?: string) => void;
  selected?: string;
}

const StyledSourcePicker = styled(Box)`
  border: 1px solid #1d2b3b;
  border-radius: 4px;
  padding: 0.5rem;
  margin: 0.5rem 0;
  max-height: 300px;
  overflow-y: auto;
`;

export const JanusSourcePicker = ({
  selected,
  onChange,
}: SourcePickerProps) => {
  const settings = useSelector(settingsSelector);

  const currentPlatform: string = useSelector(selectCurrentPlatform) ?? "";

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [settingsTitles, setSettingsTitles] = useState("");

  const [selectedSettings, setSelectedSettings] = useState<
    NestedSetting | undefined
  >();
  const [sources, setSources] = useState<SettingsSource[] | undefined>();

  useEffect(() => {
    const newSources = getSettingsSources(settings);
    setSources(newSources);
  }, [settings]);

  const getFiltereData = () => {
    return settings.find(
      (item: VersionedSettings) => item.game_edition_id === currentPlatform
    );
  };
  return (
    <>
      <small>
        Pick a source from the settings below. <br />
        <br />
        Settings are grouped as{" "}
        <strong>
          <i>unique</i>
        </strong>{" "}
        settings objects. Hover a setting to view the other equivalent settings
        or click a setting to view it's contents.
      </small>
      <StyledSourcePicker>
        {isNullOrUndefined(sources) ? (
          <CircularProgress />
        ) : (
          <Flex justifyContent="flex-start" flexDirection="column">
            {currentPlatform && (
              <Box alignSelf="center">
                <FormControlLabel
                  label={
                    <Button
                      variant="contained"
                      size="small"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      sx={{ my: 2 }}
                      color={"secondary"}
                      onClick={() => {
                        const setting = {
                          __description__: "",
                          __modified__: "",
                          __tag__: "",
                          __type__: ValueType.OBJECT,
                          __user__: "",
                          __value__:
                            sources.find((i: any) =>
                              i.settingIds.includes(getFiltereData().object_id)
                            )?.nested_settings ?? {},
                        };

                        const settingTitles =
                          sources
                            .find((i: any) =>
                              i.settingIds.includes(getFiltereData().object_id)
                            )
                            ?.settingIds.map((id) => {
                              const setting = settings?.find(
                                (propSetting: VersionedSettings) =>
                                  propSetting.object_id === id
                              );
                              return createSettingTitle(setting) ?? id;
                            })
                            .join(" | ") ?? "";

                        setSelectedSettings(setting);
                        setSettingsTitles(settingTitles);
                        setIsSettingsDialogOpen(true);
                      }}
                    >
                      View
                    </Button>
                  }
                  id="hideTooltips"
                  control={
                    <Checkbox
                      checked={
                        selected ===
                        (sources ?? []).find((i: any) =>
                          i.settingIds.includes(getFiltereData()?.object_id)
                        )?.id
                      }
                      onChange={() =>
                        onChange(
                          sources.find((i: any) =>
                            i.settingIds.includes(getFiltereData()?.object_id)
                          )?.id
                        )
                      }
                    />
                  }
                />
              </Box>
            )}
          </Flex>
        )}
      </StyledSourcePicker>
      <SettingsDialog
        readOnly
        isOpen={isSettingsDialogOpen}
        onClose={() => setIsSettingsDialogOpen(false)}
        settings={selectedSettings}
        text={settingsTitles}
      />
    </>
  );
};
