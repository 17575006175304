import { useMemo } from "react";

export const useEndpointAsOptions = (
  response: any,
  words: string[],
  keys: string[],
  separator: string,
  valueReplacer?: {
    [id: string]: (value: string, obj: any) => string | undefined;
  }
) => {
  // Generate display string
  return useMemo(() => {
    const options: { [id: string]: string } = {};

    response
      ?.filter((item: any) =>
        item.name
          ? !item.name.includes("DELETED")
          : !item.code?.includes("DELETED")
      )
      ?.forEach((item: any) => {
        let displayString = ``;
        words.forEach((word, index) => {
          if (index !== 0) {
            word = separator + word;
          }
          displayString = displayString + word;

          return displayString;
        });
        keys.forEach((key, index) => {
          let word;
          try {
            word = JSON.parse(item[key]);
          } catch {
            word = item[key];
          }

          word = typeof word === "object" ? word[""] : word;
          if (index !== 0 || words.length !== 0) {
            word = separator + word;
          }

          if (valueReplacer && valueReplacer[key]) {
            displayString = displayString + valueReplacer[key](word, item);
          } else {
            displayString = displayString + word;
          }
        });

        options[item.object_id!] = displayString;
      });

    return options;
  }, [response, words, keys, separator, valueReplacer]);
};
