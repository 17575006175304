import { Box, Checkbox, FormControlLabel, Link } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { ICheckbox } from "../../model/sidebar.model";

const StyledFooter = styled(Box)`
  box-sizing: border-box;
`;

interface CheckboxesProps {
  isMobile: boolean;
  expanded: boolean;
  setKeepSidebarOpen: (bool: boolean) => void;
  sidebarOpen: boolean;
  version?: string;
  checkboxes?: ICheckbox[];
  bugReportLink?: string;
}

export const Checkboxes = ({
  isMobile,
  expanded,
  setKeepSidebarOpen,
  sidebarOpen,
  version,
  checkboxes,
  bugReportLink,
}: CheckboxesProps) => {
  return (
    <StyledFooter display={isMobile && !expanded ? "none" : "block"} p="0.5rem">
      <Box display="flex" flexDirection="column" justifyContent="flex-start">
        {/*{setKeepSidebarOpen && !isMobile && (*/}
        {/*  <FormControlLabel*/}
        {/*    control={*/}
        {/*      <Checkbox*/}
        {/*        checked={sidebarOpen ?? false}*/}
        {/*        onChange={(event) => setKeepSidebarOpen(event.target.checked)}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label="Keep Sidebar Open"*/}
        {/*  />*/}
        {/*)}*/}
        {checkboxes &&
          checkboxes.map((checkbox: any) => (
            <FormControlLabel
              key={checkbox.id}
              control={
                <Checkbox
                  checked={checkbox.value}
                  onChange={(event) => checkbox.onChange(event.target.checked)}
                />
              }
              label={checkbox.label}
            />
          ))}
      </Box>
      <Box display="flex" justifyContent="space-between">
        {bugReportLink && (
          <Box>
            <Link underline="hover" target="_blank" href={bugReportLink}>
              Submit Bug Report
            </Link>
          </Box>
        )}
        <Box>version {version}</Box>
      </Box>
    </StyledFooter>
  );
};
