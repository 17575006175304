import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { UserRegistry } from "../model/user-accounts.model";
import { getData } from "./request";

type UseUserRegistry = {
  userRegistry: UserRegistry[];
  isLoading: boolean;
  isError: boolean;
};

export function useUserRegistry() {
  const { data, error } = useSWR(Endpoints.USER_REGISTRY.url, getData);

  const userRegistry: UseUserRegistry = {
    userRegistry:
      data &&
      (data as { [id: string]: any })[Endpoints.USER_REGISTRY.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return userRegistry;
}
