import styled from "@emotion/styled";
import { Box, Chip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { FormItem } from "../../../model/crud.model";
import { Flex } from "../../Flex";

interface SuggestionProps {
  suggestions: string | { [id: string]: any } | any[];
  onChange: (update: { [id: string]: any }) => void;
  valueProp?: any;
  isValueArray?: boolean;
  field: FormItem;
}

const StyledTag = styled(Chip)`
  &:hover {
    background-color: #929292;
  }
`;

export const Suggestions = ({
  suggestions,
  onChange,
  valueProp,
  isValueArray,
  field,
}: SuggestionProps) => {
  const type = typeof suggestions;
  const isString = type === "string";
  const isAccountID = field.name === "account_id";
  const isPlatforms = field.name === "game_platforms";

  let suggestionsArr = [];

  // Convert field suggestions to array and to correct value form
  if (isPlatforms) {
    Object.keys(suggestions).forEach((key) => {
      //@ts-ignore
      const value = suggestions[key];

      suggestionsArr.push({
        code: key,
        platform_id:
          field.suggestionsCollection && field.suggestionsCollection[key],
        store_id: value.store_id,
      });
    });
  } else if (isAccountID) {
    //@ts-ignore
    suggestions.forEach((suggestion: { id: string }) => {
      suggestionsArr.push({
        ...suggestion,
        id: suggestion.id.replace("act_", ""),
      });
    });
  } else if (isString) {
    suggestionsArr.push(suggestions);
  } else {
    //@ts-ignore
    suggestionsArr = suggestions;
  }

  const filteredSuggestions = suggestionsArr.filter((suggestion) => {
    // If suggestion is currently the value or contained within the value array then hide
    if (isAccountID) {
      return (
        valueProp !==
        (field.suggestionsCollection &&
          field.suggestionsCollection[suggestion.id])
      );
    }

    return isPlatforms
      ? !valueProp?.some((item: any) =>
          _.isEqual(
            {
              platform_id: suggestion.platform_id,
              store_id: suggestion.store_id,
            },
            {
              platform_id: item.platform_id,
              store_id: item.store_id,
            }
          )
        )
      : isValueArray
      ? !valueProp?.some((item: any) => _.isEqual(suggestion, item))
      : valueProp !== suggestion;
  });

  return (
    <Box mt={2}>
      {filteredSuggestions.length !== 0 && <small>Suggestions:</small>}
      <Box my={2}>
        {filteredSuggestions.map((suggestion, index) => {
          const notInCollection = !Object.keys(
            field.suggestionsCollection ?? {}
          )?.includes(suggestion?.id);

          const accountIdNotFound =
            field.name === "account_id" && notInCollection;
          if (isString) {
            return (
              <StyledTag key={`s${index}`} onClick={() => onChange(suggestion)}>
                {suggestion}
              </StyledTag>
            );
          } else {
            return (
              <Flex key={`s${index}`} mb={2} flexDirection="column">
                <StyledTag
                  variant="outlined"
                  color={accountIdNotFound ? "primary" : "secondary"}
                  label={
                    isAccountID
                      ? suggestion.name
                      : isPlatforms
                      ? suggestion.code
                      : suggestion.store_id
                  }
                  onClick={() => {
                    // If account facebook id doesn't exist in accounts collection
                    if (accountIdNotFound) {
                      // Open accounts page in new tab
                      const win = window.open(
                        `/accounts?fb_id=${suggestion.id.replace(
                          "act_",
                          ""
                        )}&name=${suggestion.name}`,
                        "_blank"
                      );
                      win?.focus();
                    } else {
                      if (isAccountID) {
                        onChange(
                          field.suggestionsCollection &&
                            field.suggestionsCollection[suggestion.id]
                        );
                      } else {
                        isPlatforms
                          ? onChange([
                              ...valueProp,
                              {
                                platform_id: suggestion.platform_id,
                                store_id: suggestion.store_id,
                              },
                            ])
                          : isValueArray
                          ? onChange([...valueProp, suggestion])
                          : onChange(suggestion);
                      }
                    }
                  }}
                />

                {accountIdNotFound && (
                  <small>
                    No account with this Facebook ID found, click suggestion to
                    create
                  </small>
                )}
              </Flex>
            );
          }
        })}
      </Box>
    </Box>
  );
};
