import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { AdminLogPostData } from "../../../model/log";

interface FilterState {
  darkMode: boolean;
  filters: { [id: string]: any };
  userColumnVisibility?: { [key in keyof AdminLogPostData]: boolean };
}

const initialState: FilterState = {
  darkMode: true,
  filters: {},
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    UPDATE_FILTER: (state, { payload }) => {
      state.filters[payload.key] = payload.value;
    },
    RESET_FILTERS: (state) => {
      state.filters = {};
    },
    SET_DARK_MODE: (state, { payload }) => {
      state.darkMode = payload;
    },
    UPDATE_COLUMN_VISIBILITY: (state, { payload }) => {
      state.userColumnVisibility = payload;
    },
  },
});

export const {
  SET_DARK_MODE,
  UPDATE_FILTER,
  RESET_FILTERS,
  UPDATE_COLUMN_VISIBILITY,
} = filterSlice.actions;

// Selectors
export const selectDarkMode = (state: RootState) => state.filter.darkMode;
export const selectFilters = (state: RootState) => state.filter.filters;
export const selectColumnVisibility = (state: RootState) =>
  state.filter.userColumnVisibility;

export default filterSlice.reducer;
