import { useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import {
  LocalisationDataRow,
  LocalisationObject,
} from "../features/Localisation/types";
import { selectSession } from "../features/session/sessionSlice";
import { getData, postData } from "./request";

export function useGetLocalisation() {
  const { game_id: gameId } = useSelector(selectSession);

  const url = Endpoints.LOCALISATION.url.replace("{game_id}", gameId ?? "");
  const { data, error, mutate } = useSWR<{
    objects: Record<string, LocalisationObject>;
  }>(url, getData, {
    suspense: false,
  });

  return {
    localisations: data?.objects,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export function useGetVersionMap() {
  const { game_id: gameId } = useSelector(selectSession);

  const url = Endpoints.LOCALISATIONVERSIONMAP.url.replace(
    "{game_id}",
    gameId ?? ""
  );
  const { data, error } = useSWR<{
    active_version_data: Record<string, number>;
  }>(url, getData, {
    suspense: false,
  });

  return {
    versionMap: data?.active_version_data,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useGetLocalisationObject(version: string | null | undefined) {
  const url = Endpoints.LOCALISATION_OBJECT.url.replace(
    "{instance_id}",
    version ?? ""
  );
  const { data, error } = useSWR<{
    objects: LocalisationObject;
    extra_data: LocalisationDataRow[];
  }>(!!version ? url : null, getData, {
    suspense: false,
  });

  return useMemo(
    () => ({
      localisationObjectData: data && data?.extra_data,
      isLoading: !error && !data,
      isError: error,
    }),
    [data, error]
  );
}

export async function updateLocalisation(gameId: string, data: any) {
  const body = new FormData();
  const file = new File([JSON.stringify(data)], "localisation.json");
  body.append("file", file);
  const result = await postData(
    Endpoints.LOCALISATION.url.replace("{game_id}", gameId),
    body
  );
  return result.object as LocalisationObject;
}

export async function updateVersionMap(gameId: string, data: any) {
  const body = new FormData();
  const file = new File([JSON.stringify(data)], "version_map.json");
  body.append("file", file);
  return postData(
    Endpoints.UPDATE_VERSION_MAP.url.replace("{game_id}", gameId),
    body
  );
}
