import { useState } from "react";
import { mutate } from "swr";
import { store } from "../../app/store";
import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import { setSaving } from "../../components/CollectionEditor/crudService";
import Endpoints from "../../constants/endpoints";
import { AnalyticsEventGroupModel } from "../../model/analytics_event_group.model";
import { FieldType, Form } from "../../model/crud.model";
import { postData } from "../../network/request";
import { useConfig } from "../../network/useConfig";
import { useEvents } from "../../network/useEvent";
import { useEventGroup } from "../../network/useEventGroup";
import { useGameEditions } from "../../network/useGameEditions";
import { getGameID } from "../../utils/game-admin.util";
import { getEndpointData } from "../../utils/getEndpointData";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { AnalyticsEventModal } from "./AnalyticsEventModal";

export const AnalyticsEventGroup = () => {
  const { eventGroup, isLoading } = useEventGroup();
  const { events } = useEvents();

  const { config } = useConfig();

  const currentGameId = getGameID();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedObject, setSelectedObject] =
    useState<AnalyticsEventGroupModel | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const gamesAvailable: Record<string, string> | undefined =
    config?.games_available;

  const { gameEditions } = useGameEditions();
  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );
  const model: Form = [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Description",
      name: "description",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Game",
      name: "game_id",
      type: FieldType.SELECT,
      options: gamesAvailable,
    },
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.SELECT,
      options: gameEditionOptions,
    },
    {
      display_name: "Sampling Rate",
      name: "sampling_rate",
      type: FieldType.NUMBER,
      defaultValue: 0,
      min: 0,
    },
    {
      display_name: "Event List",
      name: "event_id_list",
      type: FieldType.MULTISELECT,
      defaultValue: [],
      selectOptions: events?.map((i, idx) => ({
        value: i.object_id,
        label: i.name,
      })),

      limitTags: 10,
    },
    {
      display_name: "Is Restricted",
      name: "is_restricted",
      type: FieldType.CHECKBOX,
      defaultValue: false,
    },
  ];

  const handleUpdate = async (obj: AnalyticsEventGroupModel) => {
    try {
      await postData(
        `${Endpoints.ANALYTICS_EVENT_GROUP.url}/${obj.object_id}/update`,
        obj
      );

      const newArray = (eventGroup ?? []).map(
        (item: AnalyticsEventGroupModel) =>
          item.object_id === obj.object_id ? obj : item
      );

      const { url, objectPath } = getEndpointData(
        Endpoints.ANALYTICS_EVENT_GROUP,
        "event_group",
        currentGameId
      );
      setSaving(objectPath, true);

      mutate(
        url,
        async (prev: any) => {
          return {
            [objectPath]: newArray,
          };
        },
        false
      );
      setSaving(objectPath, false);

      store.dispatch(
        queueNotification({
          message: "Updated Successfully",
          options: {
            key: "update_notification",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
      setSaving(objectPath, undefined);
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setErrorMessage(error.response.data.error_message);
        setShowConfirmationModal(true);
        setSelectedObject(obj);
      } else {
        store.dispatch(
          queueNotification({
            message: error.response.data.error_message,
            options: {
              key: "update_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
    }
  };

  const handleConfirmation = async (confirm: boolean) => {
    setShowConfirmationModal(false);

    if (confirm && selectedObject) {
      try {
        // Update with swap_event_ids set to true
        const updatedObject: AnalyticsEventGroupModel = {
          ...(selectedObject as AnalyticsEventGroupModel),
          swap_event_ids: true,
        };

        await handleUpdate(updatedObject);
      } catch (error: any) {
        if (error.response && error.response.status === 409) {
          setShowConfirmationModal(true);
        }
      }
    }
  };

  return (
    <>
      <CollectionEditor
        response={eventGroup}
        page="event_groups"
        isLoading={isLoading}
        displayNameTemplate={`{name}`}
        model={model}
        endpoint={Endpoints.ANALYTICS_EVENT_GROUP}
        onUpdate={handleUpdate}
      />

      {showConfirmationModal && (
        <AnalyticsEventModal
          open={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={() => handleConfirmation(true)}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
