import { createSlice, nanoid, current } from "@reduxjs/toolkit";
import { ValueType } from "../../model/versioned-settings.model";

const handleValueType = (type: string) => {
  switch (type) {
    case ValueType.ARRAY:
      return { 0: { __value__: "", id: nanoid(), __type__: ValueType.STRING } };
    case ValueType.BOOLEAN:
      return false;
    case ValueType.NUMBER:
      return 0;
    case ValueType.STRING:
      return "";
    case ValueType.OBJECT:
      return {
        [nanoid()]: { __value__: "", id: nanoid(), __type__: ValueType.STRING },
      };
  }
};

const createConfigSlice = (name: string) => {
  interface ConfigSliceState {
    expandedSettings: string[];
    configData: any;
    currentId: any;
  }

  const initialState: ConfigSliceState = {
    expandedSettings: [],
    configData: {},
    currentId: "",
  };

  return createSlice({
    name,
    initialState,

    reducers: {
      collapseSetting: (state, action) => {
        state.expandedSettings = state.expandedSettings.filter(
          (item) => item !== action.payload
        );
      },
      expandSetting: (state, action) => {
        state.expandedSettings = [...state.expandedSettings, action.payload];
      },
      saveConfigData: (state, action) => {
        state.configData = action.payload;
      },
      updateConfig: (state, action) => {
        let temp = state.configData;
        for (
          let index = 0;
          index < action.payload.parentName.length - 1;
          index++
        ) {
          temp = temp[action.payload.parentName[index]].__value__;
        }

        const key =
          action.payload.parentName[action.payload.parentName.length - 1];

        const value = temp[key];

        delete temp[key];

        temp[action.payload.value] = value;
      },

      updateConfigValue: (state, action) => {
        let temp = state.configData;
        for (
          let index = 0;
          index < action.payload.parentName.length - 1;
          index++
        ) {
          temp = temp[action.payload.parentName[index]].__value__;
        }

        const key =
          action.payload.parentName[action.payload.parentName.length - 1];

        temp[key].__value__ = action.payload.value;
      },

      updateConfigValueType: (state, action) => {
        let temp = state.configData;
        for (
          let index = 0;
          index < action.payload.parentName.length - 1;
          index++
        ) {
          temp = temp[action.payload.parentName[index]].__value__;
        }

        const key =
          action.payload.parentName[action.payload.parentName.length - 1];

        temp[key].__value__ = handleValueType(action.payload.type);
        temp[key].__type__ = action.payload.type;
      },

      addSubChild: (state, action) => {
        let temp = state.configData;
        for (
          let index = 0;
          index < action.payload.parentName.length - 1;
          index++
        ) {
          temp = temp[action.payload.parentName[index]].__value__;
        }

        const key =
          action.payload.parentName[action.payload.parentName.length - 1];

        temp[key].__value__ = {
          ...temp[key].__value__,
          ...(temp[key].__type__ === ValueType.ARRAY
            ? {
                [Object.entries(temp[key].__value__).length]: {
                  __value__: "",
                  id: nanoid(),
                  __type__: ValueType.STRING,
                },
              }
            : {
                [nanoid()]: {
                  __value__: "",
                  id: nanoid(),
                  __type__: ValueType.STRING,
                },
              }),
        };
      },
      addChild: (state) => {
        state.configData = {
          ...state.configData,
          ...{
            [nanoid()]: {
              __value__: "",
              id: nanoid(),
              __type__: ValueType.STRING,
            },
          },
        };
      },
      deleteConfigItem: (state, action) => {
        let temp = state.configData;
        for (
          let index = 0;
          index < action.payload.parentName.length - 1;
          index++
        ) {
          temp = temp[action.payload.parentName[index]].__value__;
        }

        const key =
          action.payload.parentName[action.payload.parentName.length - 1];

        delete temp[key];
      },
      saveConfigItems: (state, action) => {},
    },
  });
};

export default createConfigSlice;

export type ConfigSlice = ReturnType<typeof createConfigSlice>;
