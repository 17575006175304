import createConfigSlice from "./createConfigSlice";
import { RootState } from "../../app/store";

const adminConfigSlice = createConfigSlice("adminConfig");

export const selectExpandedSettings = (state: RootState) =>
  state.adminConfigReducer.expandedSettings;

export const selectCurrentId = (state: RootState) =>
  state.adminConfigReducer.currentId;

export const getConfigData = (state: RootState) =>
  state.adminConfigReducer.configData;

export const {
  collapseSetting,
  expandSetting,
  saveConfigData,
  updateConfig,
  updateConfigValue,
  updateConfigValueType,
  addSubChild,
  deleteConfigItem,
  addChild,
  saveConfigItems,
} = adminConfigSlice.actions;

export default adminConfigSlice.reducer;
