import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { UserAccount } from "../model/user-accounts.model";
import { getData } from "./request";

type UseUserAccounts = {
  userAccounts: UserAccount[];
  isLoading: boolean;
  isError: boolean;
};

export function useUserAccounts() {
  const { data, error } = useSWR(Endpoints.USER_ACCOUNTS.url, getData);

  const userAccounts: UseUserAccounts = {
    userAccounts:
      data &&
      (data as { [id: string]: any })[Endpoints.USER_ACCOUNTS.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return userAccounts;
}
