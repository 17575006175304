import md5 from "md5";
import { SettingsSource } from "../features/AddToAll/components/SourcePicker";
import {
  NestedSettings,
  VersionedSettingss,
} from "../model/versioned-settings.model";

export const getSettingsSources = (
  settings?: VersionedSettingss
): SettingsSource[] => {
  const valueHashMap: { [id: string]: string[] } = {};

  // Create value hash for each settings
  settings?.forEach((setting) => {
    const orderedNestedSettings: NestedSettings = Object.keys(
      setting.nested_settings
    )
      .sort()
      .reduce((obj: { [id: string]: any }, key) => {
        obj[key] = setting.nested_settings[key];
        return obj;
      }, {});

    const hash = md5(
      Object.values(orderedNestedSettings)
        .map((nestedSetting) => nestedSetting.__valuehash__)
        .join()
    );

    if (valueHashMap[hash]) {
      valueHashMap[hash].push(setting.object_id);
    } else {
      valueHashMap[hash] = [setting.object_id];
    }
  });

  // Convert into setting sources
  return Object.entries(valueHashMap).map(([key, value]) => {
    return {
      id: key,
      settingIds: value,
      nested_settings: settings?.find(
        (setting) => setting.object_id === value[0]
      )?.nested_settings,
    };
  });
};
