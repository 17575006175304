import { TextField } from "@mui/material";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import React, { useState } from "react";

interface Props {
  onChange: (text: string) => void;
  placeholder: string;
}

const TooltipSearch = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSetSearchTerm = AwesomeDebouncePromise(props.onChange, 500);

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder={props.placeholder}
      value={searchTerm}
      onChange={(event) => {
        setSearchTerm(event.target.value);
        debouncedSetSearchTerm(event.target.value);
      }}
    />
  );
};

export default TooltipSearch;
