import _ from "lodash";
import moment from "moment";
import { serverTimeFormat } from "../model/database-object.model";
import {
  NestedSetting,
  NestedSettings,
  SettingValue,
  ValueType,
  VersionedSettings,
} from "../model/versioned-settings.model";

export default function sanitiseSettings<T extends Partial<VersionedSettings>>(
  settings: T
): T {
  let newSettings = _.cloneDeep(settings);
  let newNestedSettings: NestedSettings = {};

  for (const settingKey in newSettings.nested_settings) {
    newNestedSettings[settingKey] = sanitiseSetting(
      newSettings.nested_settings[settingKey],
      settingKey
    );
  }

  newSettings = { ...newSettings, nested_settings: newNestedSettings };

  return newSettings;
}

export function sanitiseSetting(setting: NestedSetting, key?: string) {
  const clonedSetting = _.cloneDeep(setting);
  if (!clonedSetting) {
    return {
      __description__: "",
      __modified__: moment().format(serverTimeFormat),
      __tag__: "",
      __type__: ValueType.NULL,
      __user__: "",
      __value__: null,
    };
  }

  // Clone setting with only allowed keys
  let newSetting: NestedSetting = {
    __description__: clonedSetting.__description__,
    __modified__: clonedSetting.__modified__,
    __tag__: clonedSetting.__tag__,
    __type__: clonedSetting.__type__,
    __user__: clonedSetting.__user__,
    __value__: clonedSetting.__value__,
    __valuehash__: clonedSetting.__valuehash__,
  };

  if (!newSetting.__type__) {
    newSetting.__type__ = getValueType(clonedSetting.__value__);
  }

  let debug =
    newSetting.__type__ !== ValueType.NULL && newSetting.__value__ === null;
  if (debug) {
    console.groupCollapsed(
      `Attempting to fix ${key} (${newSetting.__type__} has a null value)`
    );
    console.group("Before");
    console.log(newSetting.__value__);
    console.groupEnd();
  }

  if (newSetting.__type__ !== ValueType.NULL && newSetting.__value__ === null) {
    switch (newSetting.__type__) {
      case ValueType.BOOLEAN:
        newSetting.__value__ = false;
        break;
      case ValueType.INT:
        newSetting.__value__ = 0;
        break;
      case ValueType.DOUBLE:
        newSetting.__value__ = 0.0;
        break;
      default:
        console.log(`Unable to fix ${key} (data type ${newSetting.__type__})`);
        break;
    }
  }

  if (debug) {
    console.group("After");
    console.log(newSetting.__value__);
    console.groupEnd();
    console.groupEnd();
  }

  if (!newSetting.__description__) {
    newSetting.__description__ = "";
  }

  if (!newSetting.__tag__) {
    newSetting.__tag__ = "";
  }

  if (!newSetting.__user__) {
    newSetting.__user__ = "";
  }

  if (!newSetting.__modified__) {
    newSetting.__modified__ = moment().format(serverTimeFormat);
  }

  // If collection update children
  if (newSetting.__type__ === ValueType.ARRAY) {
    newSetting.__value__ = [];
    if (
      typeof clonedSetting.__value__ === "object" &&
      !Array.isArray(clonedSetting.__value__) &&
      clonedSetting.__value__ !== null
    ) {
      let i = 0;
      for (const childSetting of Object.values(clonedSetting.__value__)) {
        newSetting.__value__.push(
          sanitiseSetting(childSetting, generateKey(key, (i++).toString()))
        );
      }
    } else {
      let i = 0;
      for (const element of clonedSetting.__value__ as SettingValue<ValueType.ARRAY>) {
        newSetting.__value__.push(
          sanitiseSetting(element, generateKey(key, (i++).toString()))
        );
      }
    }
  } else if (newSetting.__type__ === ValueType.OBJECT) {
    const value = newSetting.__value__ as Record<string, NestedSetting>;
    const settingValue = clonedSetting.__value__ as Record<
      string,
      NestedSetting
    >;
    for (const elementKey in value) {
      value[elementKey] = sanitiseSetting(
        settingValue[elementKey],
        generateKey(key, elementKey)
      );
    }
  }

  return newSetting;
}

function getValueType(value: SettingValue<any>): ValueType {
  if (Array.isArray(value)) {
    return ValueType.ARRAY;
  }
  switch (typeof value) {
    case "number":
      return ValueType.INT;
    case "string":
      return ValueType.STRING;
    case "object":
      return ValueType.OBJECT;
    case "boolean":
      return ValueType.BOOLEAN;
    default:
      return ValueType.NULL;
  }
}

function generateKey(key: string | null | undefined, addition: string) {
  if (!key) {
    key = "";
  }

  if (key.length > 0) {
    key += ".";
  }

  return key + addition;
}
