import { Box, IconButton, TextField } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FaPlus, FaTimes } from "react-icons/fa";
import { Flex } from "../../components/Flex";
import { FieldType, FormItem } from "../../model/crud.model";
import { useCategories } from "./hooks/useCategories";
import { useLevelTypes } from "./hooks/useLevelTypes";

export const defaultLevelTypes = { default: "Default" };
export const defaultCategories = { default: "Default" };

export const DefaultLevelFields = () => {
  const levelTypes = useLevelTypes();
  const categories = useCategories();

  const defaultLevelFields: FormItem[] = [
    {
      main_field: true,
      display_name: "Name",
      name: "name",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Client ID",
      name: "client_id",
      required: true,
      type: FieldType.STRING,
      defaultValue: "",
    },
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: categories,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Level Type",
      name: "level_type",
      type: FieldType.SELECT,
      options: levelTypes,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Stage IDs",
      name: "stage_ids",
      valueFunction: (obj) => obj,
      type: FieldType.CUSTOM,
      renderOverride: (option, onChangeFunction, value) => {
        return (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <DragDropContext
              onDragEnd={(result: any) => {
                if (!result.destination) return;

                const items = Array.isArray(option?.stage_ids)
                  ? option.stage_ids
                  : [];

                const [reorderedItem] = items.splice(result.source.index, 1);
                items.splice(result.destination.index, 0, reorderedItem);

                return onChangeFunction({
                  [value]: items,
                });
              }}
            >
              <Droppable droppableId="characters">
                {(provided) => (
                  <div
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {(Array.isArray(option?.stage_ids)
                      ? option.stage_ids
                      : []
                    ).map((item: any, index: any) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={`drag-stage-id-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Flex
                                flexDirection="row"
                                alignItems="center"
                                sx={{ mt: 2 }}
                              >
                                <TextField
                                  id="outlined-basic"
                                  size="small"
                                  value={item}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    let newArray = option.stage_ids;

                                    newArray[index] = event.target.value;

                                    return onChangeFunction({
                                      [value]: newArray,
                                    });
                                  }}
                                  variant="outlined"
                                />
                                {option?.stage_ids.length - 1 === index && (
                                  <IconButton
                                    size="small"
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                      return onChangeFunction({
                                        [value]: [...option.stage_ids, ""],
                                      });
                                    }}
                                  >
                                    <FaPlus />
                                  </IconButton>
                                )}

                                <IconButton
                                  size="small"
                                  sx={{ ml: 1 }}
                                  onClick={() => {
                                    return onChangeFunction({
                                      [value]: option.stage_ids.filter(
                                        (k: any, kdx: any) => kdx !== index
                                      ),
                                    });
                                  }}
                                >
                                  <FaTimes />
                                </IconButton>
                              </Flex>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {(!option?.stage_ids || !option?.stage_ids.length) && (
              <IconButton
                size="small"
                onClick={() => {
                  return onChangeFunction({ [value]: [""] });
                }}
              >
                <FaPlus />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  return defaultLevelFields;
};
