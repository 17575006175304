export default class BiMap<K, V> implements Map<K, V> {
  private forwardMap = new Map<K, V>();
  private reverseMap = new Map<V, K>();
  get size() {
    return this.forwardMap.size;
  }
  clear() {
    this.forwardMap.clear();
    this.reverseMap.clear();
  }
  delete(key: K) {
    if (this.forwardMap.has(key)) {
      const val = this.forwardMap.get(key)!;
      this.forwardMap.delete(key);
      this.reverseMap.delete(val);

      return true;
    }

    return false;
  }
  deleteValue(key: V) {
    if (this.reverseMap.has(key)) {
      const val = this.reverseMap.get(key)!;
      this.forwardMap.delete(val);
      this.reverseMap.delete(key);

      return true;
    }

    return false;
  }
  forEach(
    callbackfn: (value: V, key: K, map: Map<K, V>) => void,
    thisArg?: any,
  ) {
    this.forwardMap.forEach(callbackfn, thisArg);
  }
  get(key: K) {
    return this.forwardMap.get(key);
  }
  getKey(value: V) {
    return this.reverseMap.get(value);
  }
  has(key: K) {
    return this.forwardMap.has(key);
  }
  set(key: K, value: V) {
    if (this.forwardMap.has(key)) {
      const val = this.forwardMap.get(key)!;
      this.reverseMap.delete(val);
    }
    if (this.reverseMap.has(value)) {
      const val = this.reverseMap.get(value)!;
      this.forwardMap.delete(val);
    }
    this.forwardMap.set(key, value);
    this.reverseMap.set(value, key);

    return this;
  }
  entries() {
    return this.forwardMap.entries();
  }
  keys() {
    return this.forwardMap.keys();
  }
  values() {
    return this.forwardMap.values();
  }
  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.forwardMap[Symbol.iterator]();
  }
  readonly [Symbol.toStringTag] = 'BiMap';
}
