import { Box, Collapse } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/all";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch } from "../../app/hooks";
import { setLoadingIFrame } from "../../features/general/generalSlice";
import { INavigationRoute } from "../../model/sidebar.model";
import { usePrevious } from "../../utils/usePrevious";

const generateHighlightedTitle = (title: string, searchTerm?: string) => {
  if (!searchTerm) {
    return title;
  }
  const parts = title.split(new RegExp(`(${searchTerm})`, "gi"));

  return (
    <>
      {parts.map((part, index) => (
        <span
          key={index}
          className={classNames({
            highlight: part.toLowerCase() === searchTerm.toLowerCase(),
          })}
        >
          {part}
        </span>
      ))}
    </>
  );
};

const StyledText = styled(Box)`
  list-style: none;
  margin-bottom: 0;

  .highlight {
    font-weight: 600;
    background: #ffff00;
    border-radius: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 2px;
    color: black;
  }
`;

const StyledLink = styled(NavLink)`
  color: ${() => "#fff"};
  text-decoration: none;
  padding: 0.2rem;
  margin: 0.2rem 0.5rem;
  border-radius: 10px;
  transition: 0.05s ease-in background-color;

  &:hover {
    text-decoration: none;
    background-color: #2e3048;
  }

  &.selected {
    background-color: #323877;
  }
`;

const StyledHoverItem = styled(Box)`
  display: flex;
  transition: color 0.1s ease-in-out, opacity 0.15s ease-in;
  cursor: pointer;
  padding-right: 0.5rem;
`;

export interface NavItemProps {
  item: INavigationRoute;
  nested?: boolean;
  isNavbarExpanded?: boolean;
  searchObject?: any;
  searchTerm?: string;
  parentPath?: string;
  textHoverColor?: string;
  setSearchTerm: (term: string) => void;
  setNavbarExpanded: (value: boolean) => void;
}

export const NavItem = ({
  item,
  nested,
  isNavbarExpanded,
  searchObject,
  searchTerm,
  parentPath,
  setSearchTerm,
  setNavbarExpanded,
}: NavItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const pathname = useLocation().pathname;

  const pathNameArr = pathname.split("/");

  const dispatch = useAppDispatch();

  const prevSearchObject = usePrevious(searchObject);

  let path = parentPath ?? "";

  if (item.id === "shortcuts") {
    path = "";
  } else if (!nested) {
    path = item.path;
  }

  const pathArr = path.split("/");

  const lastPathEl = pathArr[pathArr.length - 1];

  // On Load
  useEffect(() => {
    if (pathNameArr.includes(lastPathEl)) {
      setIsExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check if route contains selected route
    const containsSelectedItem = pathname.includes(path);

    // If searchObject becomes undefined from being defined and doesn't contain an active link then collapse the navItem
    if (!searchObject && prevSearchObject && !containsSelectedItem) {
      setIsExpanded(false);
    }
  }, [path, pathname, prevSearchObject, searchObject]);

  // Boolean that decides whether to render the item
  const match = searchObject
    ? searchObject.__match__ ||
      searchObject.__parent_match__ ||
      searchObject.__child_match__
    : true;

  // if there is a parent match and the item is not expanded then expand the navItem
  if (
    item.elements &&
    searchObject &&
    searchObject.__parent_match__ &&
    !isExpanded
  ) {
    setIsExpanded(true);
  }

  const navLink = (
    <StyledHoverItem
      sx={{
        borderRadius: 2,
        transition: "0.05s ease-in background-color",
        "&:hover": {
          textDecoration: "none",
          backgroundColor: "#2e3048",
        },
      }}
      onClick={() => item.elements && setIsExpanded(!isExpanded)}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" p="0.5rem" alignItems="center">
        {/*{!nested && <Box mr={1}>{item.icon && <item.icon />}</Box>}*/}
        {
          <StyledText
            fontSize={!nested ? 18 : 13}
            fontWeight={!nested ? 600 : 300}
          >
            {searchObject && searchObject.__match__
              ? generateHighlightedTitle(item.name, searchTerm)
              : item.name}
          </StyledText>
        }
      </Box>

      {item.elements && isNavbarExpanded && (
        <StyledHoverItem>
          {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </StyledHoverItem>
      )}
    </StyledHoverItem>
  );

  return match ? (
    <Box display="flex" flexDirection="column" ml={nested ? "0.5rem" : 0}>
      {item.elements ? (
        navLink
      ) : (
        <StyledLink
          activeClassName="selected"
          to={path}
          onClick={() => {
            setNavbarExpanded(false);
            setSearchTerm("");

            if (item?.isOldAdmin) {
              dispatch(setLoadingIFrame(true));
            }
          }}
        >
          {navLink}
        </StyledLink>
      )}

      {item.elements && (
        <Collapse in={isExpanded}>
          {item.elements
            .filter((item: any) => !item.hidden)
            .map((nestedItem: any, index: number) => (
              <NavItem
                isNavbarExpanded={isNavbarExpanded}
                nested={true}
                item={nestedItem}
                key={`${nestedItem.id}-${index}`}
                searchObject={searchObject && searchObject.elements[index]}
                searchTerm={searchTerm}
                parentPath={`${parentPath ?? ""}${nestedItem.path}`}
                setSearchTerm={setSearchTerm}
                setNavbarExpanded={setNavbarExpanded}
              />
            ))}
        </Collapse>
      )}
    </Box>
  ) : null;
};
