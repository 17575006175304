import styled from "@emotion/styled";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import Datetime from "react-datetime";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../../features/general/generalSlice";
import { FormItem } from "../../../model/crud.model";

interface DatePickerProps {
  onChange: (update: any) => void;
  value: any;
  field: FormItem;
}

const StyledDatePicker = styled(Datetime)`
  input {
    width: 100%;
  }
  input.form-control {
    box-sizing: border-box;
    background: none;
    padding: 9px 14px;
    border-radius: 4px;
    background-color: transparent !important;
    ${({ darkMode }: any) =>
      darkMode
        ? `
      border-color: rgba(255, 255, 255, 0.3);
      color: white;
  `
        : `

      border-color: rgba(0, 0, 0, 0.1);
      color: black;
  `}
  }

  .rdtPicker {
    ${({ darkMode }: any) =>
      darkMode
        ? `
    background: #1e242f;
    border: none;
    color: white;
  `
        : `
  background: white;
  border: none;
  color: black
  `}
  }
`;

export const DatePicker = ({ onChange, value, field }: DatePickerProps) => {
  value = parseInt(value);
  const darkMode = useSelector(selectDarkMode) === "dark";

  var yesterday = moment().subtract(1, "day");
  var valid = function (current: { isAfter: (arg0: moment.Moment) => any }) {
    return current.isAfter(yesterday);
  };

  return (
    <>
      <StyledDatePicker
        inputProps={{ disabled: field.disabled }}
        isValidDate={valid}
        //@ts-ignore
        darkMode={darkMode}
        value={moment(value, field.format).toDate()}
        utc={!field.locale}
        dateFormat={field.format}
        onChange={(changedValue: any) => {
          onChange({
            [field.name]: field.format
              ? moment(changedValue).format(field.format)
              : moment(changedValue).valueOf(),
          });
        }}
      />
      <Box pt={2}>
        {value === null ? (
          <p>Not yet updated</p>
        ) : (
          <p>
            ({moment(value, field.format).tz("Europe/London").format("lll")} UK)
          </p>
        )}
      </Box>
    </>
  );
};
