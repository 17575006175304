import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { FormItem } from "../../../model/crud.model";
import { Flex } from "../../Flex";
import { FormField } from "./FormField";

interface InputArrayProps {
  field: FormItem;
  inputArray: any[];
  isDisabled?: boolean;
  onChange: (newValue: any) => void;
  /* model for specifying the structure of each item in the array */
  model?: { [id: string]: any };
  setHasChanged?: (hasChanged: boolean) => void;
}

export const InputArray = ({
  field,
  inputArray,
  isDisabled,
  onChange,
  model,
  setHasChanged,
}: InputArrayProps) => {
  return (
    <FormControl fullWidth>
      {inputArray.map((item: string | number, indexMain: number) => (
        <Box mt={indexMain ? 2 : 0} key={`${item}-${indexMain}`}>
          <FormControl fullWidth>
            <Flex justifyContent="space-between" alignItems="center">
              {model ? (
                model?.map((field: FormItem, index: number) => {
                  return (
                    <Box key={index} mr="0.5rem" width="100%">
                      <FormControl fullWidth required={field.required}>
                        {!field.useAtomicValue && (
                          <FormLabel>{field.display_name}</FormLabel>
                        )}
                        <FormField
                          // @ts-ignore
                          formValue={item}
                          field={field}
                          //@ts-ignore
                          valueProp={
                            !model || field.useAtomicValue
                              ? item
                              : (item as any)[field.name]
                          }
                          onChange={(update) => {
                            let newArray = [...inputArray];

                            if (field.useAtomicValue) {
                              newArray[indexMain] = update;
                            } else {
                              if (model) {
                                newArray[indexMain] = {
                                  //@ts-ignore
                                  ...(item ?? {}),
                                  ...update,
                                };
                              } else {
                                newArray[indexMain] = update;
                              }
                            }
                            onChange(newArray);
                          }}
                          setHasChanged={setHasChanged}
                        />
                      </FormControl>
                    </Box>
                  );
                })
              ) : (
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  value={item}
                  onChange={(event) => {
                    let newArray = [...inputArray];

                    newArray[indexMain] = event.target.value;

                    onChange(newArray);
                  }}
                />
              )}

              {!field.fixedSize && (
                <IconButton
                  size="small"
                  disabled={isDisabled}
                  onClick={() => {
                    let newArray = [...inputArray];

                    newArray.splice(indexMain, 1);

                    onChange(newArray);

                    setHasChanged && setHasChanged(true);
                  }}
                >
                  <FaTimes />
                </IconButton>
              )}
            </Flex>
          </FormControl>
        </Box>
      ))}
      {!field.fixedSize && (
        <Button
          variant="outlined"
          style={{ alignSelf: "flex-start", margin: "1rem 0" }}
          onClick={() => {
            let newArray = [...inputArray];

            let obj: { [id: string]: string } = {};
            model?.forEach((itemModel: FormItem) => {
              obj[itemModel.name] = itemModel.defaultValue;
            });

            newArray.push(
              model && !field.useAtomicValue
                ? obj
                : field.itemModel && field.itemModel[0].defaultValue
            );

            onChange(newArray);

            setHasChanged && setHasChanged(true);
          }}
          disabled={isDisabled}
        >
          Add {field.itemModel && field.itemModel[0]?.display_name}
        </Button>
      )}
    </FormControl>
  );
};

export default InputArray;
