import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { getData } from "./request";

export function useGameApps(suspense?: boolean) {
  const { data, error } = useSWR(Endpoints.GAME_APP.url, getData, {
    suspense,
  });

  return {
    gameApps: (data as { [id: string]: any })?.game_apps,
    isLoading: !error && !data,
    isError: error,
  };
}
