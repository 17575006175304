import styled from "styled-components";
import React from "react";
import { Copyright } from "./Copyright";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  align-items: center;
  flex-shrink: 0;
  padding: 1rem;
`;

interface FooterProps {
  includeFeedback?: boolean;
}

export function Footer(props: FooterProps) {
  return (
    <StyledFooter>
      <Copyright />{" "}
      {props.includeFeedback && (
        <>
          <p>·</p>
          <p>Give Feedback</p>
        </>
      )}
    </StyledFooter>
  );
}
