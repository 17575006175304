import { Alert, Box, Button, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateItem } from "../../components/CollectionEditor/crudService";
import Endpoints from "../../constants/endpoints";
import { ValueType } from "../../model/versioned-settings.model";
import { getGameID } from "../../utils/game-admin.util";
import { handleType } from "./AdminConfig";
import { addChild, getConfigData } from "./clientConfigSlice";
import AdminRow from "./AdminRow";

type ClientContainerProps = {
  parentId?: number;
  data?: any;
};

export default function ClientContainer({ data }: ClientContainerProps) {
  const theme = useTheme();
  const items = useSelector(getConfigData);

  const dispatch = useDispatch();
  const currentGameId = getGameID();

  const decodeFormatRecursively = (config: any): any => {
    const p = Object.entries(config.__value__ ?? []).map(
      ([key, value]: any) => {
        if (
          value.__type__ === ValueType.BOOLEAN ||
          value.__type__ === ValueType.STRING ||
          value.__type__ === ValueType.NUMBER
        ) {
          return { [key]: value.__value__ };
        } else {
          return { [key]: handleFormat(value.__value__, value.__type__) };
        }
      }
    );

    return Object.fromEntries(p.flatMap(Object.entries));
  };

  const handleFormat = (value: any, type?: ValueType): any => {
    if (
      handleType(value) === ValueType.BOOLEAN ||
      handleType(value) === ValueType.STRING ||
      handleType(value) === ValueType.NUMBER
    ) {
      return value as any;
    }

    if (type === ValueType.ARRAY || handleType(value) === ValueType.ARRAY) {
      const x = Object.entries(value).map(([key, value]: any) => {
        return handleFormat(value.__value__, value.__type__);
      });

      return x;
    } else {
      const x = Object.entries(value)
        .map(([key, value]: any) => {
          return {
            key,
            value: handleFormat(value.__value__, value.__type__),
          };
        })
        .reduce(
          (obj: any, item: any) =>
            Object.assign(obj, { [item.key]: item.value }),
          {}
        );

      return x;
    }
  };

  const handleUpdateConfig = async (items: any) => {
    try {
      await updateItem(
        Endpoints.CLIENT_CONFIG,
        { settings: items },
        false,
        currentGameId,
        true
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" width="100%">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          <Box
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              sx={{ mx: 2, my: 2, px: 3 }}
              onClick={() => dispatch(addChild())}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="success"
              sx={{ my: 2, px: 3 }}
              onClick={() => {
                let r = decodeFormatRecursively({
                  __type__: ValueType.OBJECT,
                  __value__: items,
                });
                handleUpdateConfig(r);
              }}
            >
              Save
            </Button>
          </Box>
          {!!data ? (
            Object.entries(data).map(([key, value]: any, index) => (
              <AdminRow
                index={index}
                item={value as any}
                key={value.id}
                parentName={[key]}
                type="client"
              />
            ))
          ) : (
            <Box padding={1}>
              <Alert severity="info">No Settings</Alert>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
