import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { UserPermission } from "../model/user-accounts.model";
import { getData } from "./request";

type UseUserPermissions = {
  userPermissions: UserPermission[];
  isLoading: boolean;
  isError: boolean;
};

export function useUserPermissions() {
  const { data, error } = useSWR(Endpoints.USER_PERMISSIONS.url, getData);

  const userPermissions: UseUserPermissions = {
    userPermissions:
      data &&
      (data as { [id: string]: any })[Endpoints.USER_PERMISSIONS.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return userPermissions;
}
