import { IWeightedConfig } from "./JanusSetting";

export const normaliseWeightedConfigs = (
  configs: IWeightedConfig[],
  valueToKeepIndex?: number
) => {
  let newConfigs = [...configs];

  // Add up values and divide all values by this
  const weights = newConfigs.map((config) => config.weight);

  let total = 0;

  weights.forEach((weight) => (total += weight ?? 0));

  const normalisedConfigs: IWeightedConfig[] = [];

  if (!!valueToKeepIndex) {
    if ((newConfigs[valueToKeepIndex].weight ?? 0) >= 1) {
      newConfigs[valueToKeepIndex].weight = 0.99;
    }

    // Get index of value to keep for reinsertion
    const multiplier = 1 - (newConfigs[valueToKeepIndex].weight ?? 1);

    const filteredConfigs = newConfigs.filter(
      (config, index) => index !== valueToKeepIndex
    );

    total = 0;

    filteredConfigs.forEach((config) => (total += config.weight ?? 0));

    filteredConfigs.forEach((config) => {
      const weight = parseFloat(
        (((config.weight ?? 0) / total) * multiplier).toFixed(3)
      );

      const normalisedConfig = {
        ...config,
        weight,
      };

      normalisedConfigs.push(normalisedConfig);
    });

    normalisedConfigs.splice(valueToKeepIndex, 0, newConfigs[valueToKeepIndex]);
  } else {
    // Loop through each weight
    newConfigs.forEach((config) => {
      const weight = parseFloat(((config.weight ?? 0) / total).toFixed(3));

      const normalisedConfig = {
        ...config,
        weight,
      };

      normalisedConfigs.push(normalisedConfig);
    });
  }

  return normalisedConfigs;
};
