import {
  AddCircle,
  ChevronRight,
  ContentPaste,
  ExpandMore,
  MoreVert,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  darken,
  IconButton,
  Menu,
  MenuItem,
  styled,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProps } from "styled-components";
import { serverTimeFormat } from "../../model/database-object.model";
import {
  ValueType,
  ValueTypeColors,
} from "../../model/versioned-settings.model";

import {
  addSubChild as addSubChildAdmin,
  collapseSetting as collapseSettingAdmin,
  deleteConfigItem as deleteConfigItemAdmin,
  expandSetting as expandSettingAdmin,
  selectExpandedSettings as selectExpandedSettingsAdmin,
  updateConfig as updateConfigAdmin,
  updateConfigValue as updateConfigValueAdmin,
  updateConfigValueType as updateConfigValueTypeAdmin,
} from "./adminConfigSlice";

import {
  addSubChild as addSubChildClient,
  collapseSetting as collapseSettingClient,
  deleteConfigItem as deleteConfigItemClient,
  expandSetting as expandSettingClient,
  selectExpandedSettings as selectExpandedSettingsClient,
  updateConfig as updateConfigClient,
  updateConfigValue as updateConfigValueClient,
  updateConfigValueType as updateConfigValueTypeClient,
} from "./clientConfigSlice";

type AdminRowItem = {
  id: string;
  name: string;
  description: string;
  __value__: any;
  __type__: ValueType;
};

type AdminRowProps = {
  item: AdminRowItem;
  level?: number;
  index: number;
  parentName: string[];
  type: "admin" | "client";
};

const StyledRow = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  color: theme.palette.text.secondary,
  width: "100%",
}));

const StyledSettingContent = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "85%",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const StyledOptions = styled(Box)(() => ({
  display: "none",
}));

const StyledContainer = styled(Box)(
  ({ theme, type }: ThemeProps<any> & { index: number; type: ValueType }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&:hover": {
      "& .icon-options": {
        display: "block",
      },
    },
    border: ` 1px solid ${theme.palette.background.default}`,
    borderLeft: `2px solid ${ValueTypeColors[type]}`,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    padding: "5px",
  })
);

const SettingField = styled(TextField)(() => ({
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-input": {
    fontSize: "1em",
  },
}));

const StyledSettingValue = styled(TextField)(() => ({
  width: "100%",
  "& input": {
    paddingTop: "0.25rem",
    paddingBottom: "0.3rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    fontSize: "1em",
  },
}));

export const getNewModified = () => {
  return {
    user: localStorage.getItem("user_id")! ?? "external_user",
    modified: moment().format(serverTimeFormat),
  };
};

export default function AdminRow({
  item,
  level,
  children,
  index,
  parentName,
  type,
}: PropsWithChildren<AdminRowProps>) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const selectExpandedSettings =
    type === "admin"
      ? selectExpandedSettingsAdmin
      : selectExpandedSettingsClient;

  // Actions
  const addSubChild = type === "admin" ? addSubChildAdmin : addSubChildClient;
  const collapseSetting =
    type === "admin" ? collapseSettingAdmin : collapseSettingClient;
  const deleteConfigItem =
    type === "admin" ? deleteConfigItemAdmin : deleteConfigItemClient;
  const expandSetting =
    type === "admin" ? expandSettingAdmin : expandSettingClient;
  const updateConfig =
    type === "admin" ? updateConfigAdmin : updateConfigClient;
  const updateConfigValue =
    type === "admin" ? updateConfigValueAdmin : updateConfigValueClient;
  const updateConfigValueType =
    type === "admin" ? updateConfigValueTypeAdmin : updateConfigValueTypeClient;

  const expandedSettings = useSelector(selectExpandedSettings);

  const setIsCollapsed = (collapse: boolean) => {
    if (collapse) {
      dispatch(collapseSetting(item.id));
    } else {
      dispatch(expandSetting(item.id));
    }
  };
  const isCollapsed = !expandedSettings.includes(item.id);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [typeAnchorEl, setTypeAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const typeOpen = Boolean(typeAnchorEl);

  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar("Copied to clipboard");
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleTypeClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setTypeAnchorEl(event.currentTarget);
  };

  const handleTypeClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    dispatch(
      updateConfigValueType({
        type: event.currentTarget.dataset.typeValue,
        parentName,
      })
    );

    setTypeAnchorEl(null);
  };

  const isCollection =
    item.__type__ === ValueType.OBJECT || item.__type__ === ValueType.ARRAY;

  const [tempValue, setTempValue] = useState<any>(
    parentName[parentName.length - 1]
  );

  const [itemValue, setItemValue] = useState<any>(item.__value__);

  useEffect(() => {
    setTempValue(parentName[parentName.length - 1]);
  }, [parentName]);

  useEffect(() => {
    setItemValue(item.__value__);
  }, [item.__value__]);

  const normalBackgroundColor =
    index % 2 === 0
      ? theme.palette.background.paper
      : darken(theme.palette.background.paper, 0.05);

  return (
    <StyledRow key={item.id}>
      <Box
        width="100%"
        sx={{
          background: `linear-gradient(90deg, ${
            item.__type__ === ValueType.ARRAY
              ? darken(ValueTypeColors[item.__type__], 0.4)
              : darken(ValueTypeColors[ValueType.OBJECT], 0.4)
          } 0%, transparent 100%)`,
          borderRadius: "10px",
          marginLeft: level !== 0 ? theme.spacing(4) : 0,
        }}
      >
        <Tooltip arrow title={parentName[parentName.length - 1]}>
          <StyledContainer
            theme={theme}
            index={index}
            type={item.__type__}
            key={`section-${item.id}`}
            sx={{
              backgroundColor: normalBackgroundColor,
              transition: ".1s background-color ease-in",
              "&:hover": {
                backgroundColor: darken(normalBackgroundColor, 0.2),
              },
            }}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <StyledSettingContent>
              <Box width="35%" display="flex" alignItems="center">
                {(item.__type__ === ValueType.OBJECT ||
                  item.__type__ === ValueType.ARRAY) && (
                  <Box width="20%">
                    <IconButton
                      style={{ padding: "0.7rem" }}
                      size="small"
                      onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                      {isCollapsed ? <ChevronRight /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                )}
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  paddingX={2}
                >
                  <SettingField
                    onClick={(event) => event.stopPropagation()}
                    style={{ width: "100%", fontWeight: 600 }}
                    size="small"
                    variant="standard"
                    placeholder="Enter name"
                    disabled={parentName[0] === "games_available"}
                    value={tempValue}
                    onChange={(event) => setTempValue(event.target.value)}
                    onBlur={() => {
                      dispatch(
                        updateConfig({
                          value: tempValue,
                          parentName: parentName,
                        })
                      );
                    }}
                  />
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                width="55%"
                alignItems="start"
              >
                {item.__type__ === ValueType.STRING && (
                  <StyledSettingValue
                    onClick={(event) => event.stopPropagation()}
                    value={itemValue}
                    size="small"
                    onChange={(event) => setItemValue(event.target.value)}
                    variant="outlined"
                    type="text"
                    onBlur={() => {
                      dispatch(
                        updateConfigValue({
                          value: itemValue,
                          parentName: parentName,
                        })
                      );
                    }}
                  />
                )}

                {item.__type__ === ValueType.NUMBER && (
                  <StyledSettingValue
                    onClick={(event) => event.stopPropagation()}
                    value={itemValue}
                    size="small"
                    onChange={(event) => setItemValue(event.target.value)}
                    variant="outlined"
                    type="number"
                    onBlur={() => {
                      dispatch(
                        updateConfigValue({
                          value: itemValue,
                          parentName: parentName,
                        })
                      );
                    }}
                  />
                )}

                {item.__type__ === ValueType.BOOLEAN && (
                  <Button
                    size="small"
                    style={{
                      backgroundColor: itemValue ? "#198754" : "#dc3545",
                      borderColor: itemValue ? "#198754" : "#dc3545",
                      color: "#FFF",
                      width: "100%",
                    }}
                    value={itemValue}
                    onClick={(event) => {
                      event.stopPropagation();
                      setItemValue(!item.__value__);
                      dispatch(
                        updateConfigValue({
                          value: !itemValue,
                          parentName,
                        })
                      );
                    }}
                  >
                    {item.__value__.toString()}
                  </Button>
                )}
              </Box>
            </StyledSettingContent>

            <Box width="20%">
              <StyledOptions className="icon-options">
                {!(
                  item.__type__ === ValueType.OBJECT ||
                  item.__type__ === ValueType.ARRAY
                ) && (
                  <Tooltip title="Copy to clipboard">
                    <Badge badgeContent={0} color="primary">
                      <IconButton
                        style={{
                          padding: "0.7rem",
                        }}
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          copyToClipboard(item.__value__);
                        }}
                      >
                        <ContentPaste />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                )}
                {(item.__type__ === ValueType.OBJECT ||
                  item.__type__ === ValueType.ARRAY) && (
                  <Tooltip title="Add">
                    <span>
                      <IconButton
                        aria-label="add"
                        onClick={(event) => {
                          event.stopPropagation();
                          dispatch(addSubChild({ parentName }));
                        }}
                        size="small"
                        style={{ padding: "0.7rem" }}
                      >
                        <AddCircle />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </StyledOptions>
            </Box>
            <Box width="10%">
              <Chip
                size="small"
                label={`${item.__type__}`}
                clickable
                style={{
                  width: "75px",
                  backgroundColor: ValueTypeColors[item.__type__],
                  marginRight: "10px",
                }}
                onClick={handleTypeClick}
                icon={<ExpandMore style={{ width: "10px" }} />}
              />

              <Menu
                id="long-menu"
                anchorEl={typeAnchorEl ?? null}
                keepMounted
                open={typeOpen}
                onClose={handleTypeClose}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {Object.values(ValueType)
                  .filter(
                    (k, kdx) =>
                      k !== ValueType.DOUBLE &&
                      k !== ValueType.INT &&
                      k !== ValueType.NULL
                  )
                  .map((option) => (
                    <MenuItem
                      data-type-value={option}
                      key={option}
                      selected={option === item.__type__}
                      onClick={handleTypeClose}
                    >
                      {option}
                    </MenuItem>
                  ))}
              </Menu>
            </Box>

            <Box width="7%">
              <IconButton
                aria-label="more-options"
                size="small"
                style={{ padding: "0.7rem" }}
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>

              <Menu
                anchorEl={anchorEl ?? null}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
              >
                {isCollection && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(addSubChild({ parentName }));
                      handleClose(event);
                    }}
                  >
                    Add
                  </MenuItem>
                )}

                <MenuItem
                  disabled={!true}
                  onClick={(event) => {
                    event.stopPropagation();
                    dispatch(deleteConfigItem({ parentName }));
                    handleClose(event);
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </StyledContainer>
        </Tooltip>
        {isCollection && expandedSettings.includes(item.id) && (
          <Collapse in={!isCollapsed}>
            {Object.entries(item.__value__).map(([key, value], index) => (
              <AdminRow
                index={index}
                item={value as any}
                key={item.id}
                type={type}
                parentName={[...(parentName as string[]), key]}
              />
            ))}
          </Collapse>
        )}
      </Box>
    </StyledRow>
  );
}
