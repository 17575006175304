import { Launch } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  darken,
  Grid,
  InputLabel,
  lighten,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLiveEnv } from "../../data/AxiosConfig";
import {
  selectDarkMode,
  setDarkMode,
} from "../../features/general/generalSlice";
import { selectSession } from "../../features/session/sessionSlice";
import { NavigationItem } from "../../model/navigation.model";
import { useConfig } from "../../network/useConfig";
import { addParentMatches, recursiveSearch } from "../../utils/recursiveSearch";
import { cloneDeep } from "lodash";
import styled from "styled-components";
import { IAccountDropdown, ICheckbox } from "../../model/sidebar.model";
import { toReactSelectObject } from "../../utils/toReactSelectObject";
import { useViewport } from "../../utils/useViewport";
import { Checkboxes } from "./Checkboxes";
import { EnvSwitcher } from "./EnvSwitcher";
import { GamesDropdown } from "./GamesDropdown";
import { Header } from "./Header";
import { NavList } from "./NavList";
import { Search } from "./Search";
import { StylesProvider } from "@mui/styles";
import { Flex } from "../Flex";

const StyledNavbar = styled(Box)`
  display: flex;
  position: fixed;
  transition: width 0.15s ease-in, height 0.15s ease-in;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;

  p,
  svg,
  li {
    transition: opacity 0.15s ease-in;
  }

  .font-awesome-icon {
    text-align: center;
    opacity: 1;
    transition: opacity 0.15s ease-in;
    width: 20px;
  }
`;

export interface SidebarProps {
  title: string;
  keepSidebarOpen: boolean;
  setKeepSidebarOpen: (keepOpen: boolean) => void;
  searchColor?: string;
  textHoverColor?: string;
  searchPlaceholderTextColor?: string;
  checkboxes?: ICheckbox[];
  navItems?: NavigationItem[];
  accountDropdown?: IAccountDropdown[];
  version?: string;
  isSuperuser?: boolean;
  isLoadingNavItems?: boolean;
}

export const Sidebar = ({
  title,
  navItems,
  checkboxes,
  version,
  keepSidebarOpen,
  setKeepSidebarOpen,
  isSuperuser,
  isLoadingNavItems,
}: SidebarProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [placeholder, setPlaceholder] = useState(`"/" to search...`);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const { config } = useConfig();
  const lilacUsers = config?.lilac_theme as string[] | undefined;

  const { width } = useViewport();
  const isMobile = width < 769;

  const sidebarOpen = keepSidebarOpen && !isMobile;

  const expanded = searchFocus || sidebarOpen ? true : isExpanded;

  const searchObject: any = recursiveSearch(cloneDeep(navItems), searchTerm);

  if (searchObject) {
    addParentMatches(searchObject);
  }

  const theme = useTheme();
  const themeKey = useSelector(selectDarkMode);
  const isLightMode = themeKey === "light";

  const { user_id: user } = useSelector(selectSession);

  let backgroundColor = isLightMode
    ? "#051e34"
    : darken(theme.palette.background.paper, 0.5);
  let textColor = lighten(theme.palette.primary.main, 0.4);

  if (isLightMode) {
    textColor = "#ffffff";
  }

  const themeOptions: { [id: string]: string } = {
    dark: "Dark",
    light: "Light",
  };

  const isLilacUser = lilacUsers?.includes(user ?? "");

  if (isLilacUser) {
    themeOptions.lilac = "Lilac";
  }

  const oldAdminLink = isLiveEnv()
    ? "https://old.admin.servers.kwalee.com/"
    : "https://old.uat-admin.servers.kwalee.com/";

  const bugReportLink = config?.bug_report_link;

  return (
    <StyledNavbar
      width={isMobile ? "100vw" : expanded ? "300px" : "80px"}
      height={isMobile && expanded ? "100vh" : isMobile ? "65px" : "100vh"}
      justifyContent="space-between"
      color={textColor ?? "#093056"}
      p="1rem"
      onMouseEnter={() => !sidebarOpen && !isMobile && setIsExpanded(true)}
      onMouseLeave={() => !sidebarOpen && !isMobile && setIsExpanded(false)}
      bgcolor={backgroundColor}
    >
      <StylesProvider>
        <Header
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          title={title}
          expanded={expanded}
        />
        <EnvSwitcher expanded={expanded} />
        <GamesDropdown expanded={expanded} />

        <ListItem
          sx={{ marginBottom: 2, display: expanded ? "flex" : "none" }}
          disablePadding
        >
          <ListItemButton component="a" target="_blank" href={oldAdminLink}>
            <ListItemIcon>
              <Launch sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText primary="Go to Old Game Admin" />
          </ListItemButton>
        </ListItem>
        <Search
          expanded={expanded}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setPlaceholder={setPlaceholder}
          placeholder={placeholder}
          setSearchFocus={setSearchFocus}
        />
        {isLoadingNavItems ? (
          <Flex sx={{ marginTop: 4, flex: "1 1", justifyContent: "center" }}>
            <CircularProgress />
          </Flex>
        ) : (
          <NavList
            navItems={navItems}
            expanded={expanded}
            searchObject={searchObject}
            isMobile={isMobile}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setIsExpanded={setIsExpanded}
            isSuperuser={isSuperuser}
          />
        )}
        <Grid
          sx={{ display: expanded ? "flex" : "none" }}
          container
          alignItems="center"
          my={1}
        >
          <Grid item xs={3}>
            <InputLabel sx={{ color: "white" }}>Theme</InputLabel>
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              disableClearable
              size="small"
              sx={{ width: "100%" }}
              id="theme-dropdown"
              options={Object.keys(themeOptions ?? {}).map((id: string) =>
                toReactSelectObject(themeOptions ?? {}, id)
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              value={toReactSelectObject(themeOptions ?? {}, themeKey)}
              placeholder={`Select theme`}
              getOptionLabel={(option) => option.label ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "white",
                      borderColor: "white",
                    },
                  }}
                />
              )}
              onChange={(event: any, value?: any) => {
                dispatch(setDarkMode(value?.value));
              }}
            />
          </Grid>
        </Grid>
        <Checkboxes
          isMobile={isMobile}
          expanded={expanded}
          setKeepSidebarOpen={setKeepSidebarOpen}
          sidebarOpen={sidebarOpen}
          version={version}
          checkboxes={checkboxes}
          bugReportLink={bugReportLink}
        />
      </StylesProvider>
    </StyledNavbar>
  );
};
