import { useDispatch, useSelector } from "react-redux";
import { Switch, BrowserRouter, Route, Redirect } from "react-router-dom";
import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { QueryParamWrapper } from "./components/QueryParamWrapper";
import { LoginRoute } from "./constants/routes";
import { getEnvironmentName } from "./data/AxiosConfig";
import { setLoadingIFrame } from "./features/general/generalSlice";
import meta from "./meta";
import history from "./utils/history-util";
import { MainRoute } from "./components/authentication/MainRoute";
import { ProtectedRoute } from "./components/authentication/ProtectedRoute";
import ScrollToTop from "./utils/ScrollToTop";
//@ts-ignore
import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax
import { Login } from "./features/Authentication/Login";
import {
  selectSession,
  setIsLoggingIn,
  setIsLoggingOut,
  setToken,
} from "./features/session/sessionSlice";
import { Helmet } from "react-helmet-async";
import { GameSettingsBackgroundColor } from "./features/AddToAll/components/GameSettingsBackgroundColor";

export const APP_NAME = "Game Admin";

let workerInstance: any;

export function getWorkerInstance() {
  return workerInstance;
}

function App() {
  const { game_id, session_id, user_id, auth_data, isLoggingIn, isLoggingOut } =
    useSelector(selectSession);
  const isLoggedIn = !!session_id;

  const dispatch = useDispatch();

  type GameAdminAuth = {
    game_id: string;
    session_id: string;
    user_id: string;
    interface_id: string;
    auth_data: Record<string, never>;
  };

  window.addEventListener("message", ({ origin, data, source }) => {
    const allowedOrigins = [
      "http://127.0.0.1:8888",
      "http://old.uat-admin.servers.kwalee.com",
      "https://www.old.uat-admin.servers.kwalee.com",
      "http://www.old.admin.servers.kwalee.com",
      "https://old.uat-admin.servers.kwalee.com",
      "http://admin.servers.kwalee.com:55667",
      "https://admin.servers.kwalee.com:55667",
      "http://old.admin.servers.kwalee.com",
      "https://old.admin.servers.kwalee.com",
      "https://www.admin.servers.kwalee.com",
      "http://www.admin.servers.kwalee.com",
      "http://admin.servers.kwalee.com",
      "https://admin.servers.kwalee.com",
    ];

    if (allowedOrigins.includes(origin)) {
      // Send auth data
      if (data === "reload") {
        const count = Number(sessionStorage.getItem("reloadCount")) || 0;
        if (count < 2) {
          window.location.reload();
          sessionStorage.setItem(
            "reloadCount",
            (count + 1).toString() || String(1)
          );
        }
      } else if (data === "loaded") {
        postAuthData(source);
      } else if (data === "page-rendered") {
        dispatch(setLoadingIFrame(false));
      } else {
        try {
          // Receive auth data from parent
          const {
            game_id: newGameId,
            session_id: newSessionId,
            user_id: newUserId,
          }: GameAdminAuth = JSON.parse(data);

          dispatch(
            setToken({
              game_id: newGameId,
              session_id: newSessionId,
              user_id: newUserId,
            })
          );

          if (
            (newGameId !== game_id && !!game_id) ||
            newSessionId !== session_id
          ) {
            // Reload page if auth data is updated or game is changed

            const count = Number(sessionStorage.getItem("reloadCount")) || 0;

            if (count < 2) {
              window.location.reload();
              sessionStorage.setItem(
                "reloadCount",
                (count + 1).toString() || String(1)
              );
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  });

  const postAuthData = (source: MessageEventSource | null) => {
    const authData = {
      game_id,
      session_id,
      user_id,
      interface_id: `game_admin_${game_id}`,
      auth_data,
    };

    if (game_id && session_id && user_id && auth_data) {
      //@ts-ignore
      source?.window.postMessage(JSON.stringify(authData), "*");
    }
  };

  useEffect(() => {
    // onLoad postMessage to parent saying ready to receive credentials
    window.parent.postMessage("loaded", "*");
  }, []);

  useEffect(() => {
    workerInstance = worker();
  }, []);

  useEffect(() => {
    if (isLoggingIn) {
      dispatch(setIsLoggingIn(false));
    }

    if (isLoggingOut) {
      dispatch(setIsLoggingOut(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Helmet>
        <title>
          {getEnvironmentName()} {APP_NAME}
        </title>
        <meta name="description" content="Add and update game configurations" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <QueryParamWrapper>
          <GameSettingsBackgroundColor />
          <Switch>
            <Route path={LoginRoute} exact component={Login} />
            <ProtectedRoute
              path="/"
              component={MainRoute}
              isAuthorised={isLoggedIn}
            />
            <Redirect to="/" />
          </Switch>
        </QueryParamWrapper>
      </ConnectedRouter>
    </BrowserRouter>
  );
}

export default App;
