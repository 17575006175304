export interface AdminLog extends DatabaseObject {
  change_location: string;
  change_type: ChangeType;
  game_id: string;
  reason: string;
  related_object_id: string;
  related_object_type: string;
  user: string;
  user_platform: string;
}

export interface DataSnapshot extends DatabaseObject {
  snapshot_type: SnapshotType;
  log_id: string;
  data: any;
}

export enum SnapshotType {
  BEFORE = "before",
  AFTER = "after",
}

export interface SnapshotData {
  logId: string;
  snapshotTypes: SnapshotType[];
}

export enum ChangeType {
  CREATE = "create",
  UPDATE = "update",
  DESTROY = "destroy",
}

export interface DatabaseObject {
  created_on: number;
  deleted_on: null;
  object_id: string;
  object_type: string;
  updated_on: number;
}

export interface AdminLogPostData {
  target_game_id: string;
  related_object_id: string;
  related_object_type: string;
  user: string;
  user_platform: string;
  change_location: string;
  change_type: ChangeType;
  date: string;
  page: number;
  page_size: number;
  include_non_game_specific: boolean;
}

export interface XpromoLogPostData {
  created_on: string;
  object_id: string;
  log_type: string;
  interval_start: string;
  interval_end: string;
  interval_spend: number;
  page?: number;
  page_size?: number;
  xpromo_campaign_id: string;
}
