import { darken, lighten } from "@mui/material";

export const Styles = (theme: any) => {
  let btnBackground = lighten(theme.palette.background.paper, 0.05);
  const invalidBtnBackground = "rgba(160,13,13,0.3)";
  const color = theme.palette.text.primary;

  return {
    // Game app styles
    button: {
      color: "white",
      fontSize: 18,
      padding: 10,
      margin: "0px 3px 0px 3px",
      minWidth: "fit-content",
      width: 20,
    },
    buttonRemove: {
      backgroundColor: "#dc3545",
      "&:hover": {
        backgroundColor: "#b32a37",
      },
    },
    buttonSave: {
      backgroundColor: "#3664b1",
      "&:hover": {
        backgroundColor: "#2a5192",
      },
    },
    cardContainer: {
      backgroundColor: theme.palette.background.paper,
      padding: 20,
    },
    customTextfield: {
      fontSize: 15,
      padding: "6px 12px 12px 12px",
      margin: 0,
      "&::placeholder": {
        fontSize: 15,
      },
    },
    gameAppIdContainer: {
      textAlign: "right" as const,
    },
    helperText: {
      color: "#DC3545",
      margin: 0,
    },
    sectionBorder: {
      border: `1px solid #ced4da`,
      padding: "15px 10px 10px 10px",
    },
    sectionContainer: {
      backgroundColor: "inherit",
      boxShadow: "none",
      margin: "5px 0px 20px 0px",
    },
    sectionSubContainer: {},
    sectionTitle: {
      fontSize: 15,
    },
    textfieldIcon: {
      color: "#DC3545",
      cursor: "pointer",
      padding: 0,
      paddingBottom: 4,
    },
    textfieldSearchIcon: {
      cursor: "pointer",
      marginRight: 0,
      padding: 0,
      paddingBottom: 4,
    },

    collapseButton: (props: { error?: boolean }) => ({
      color,
      background: props.error ? invalidBtnBackground : btnBackground,
      borderRadius: "4px",
      height: "50px",
      padding: "0.5rem 1rem",
      cursor: "pointer",
      transition: "background-color 0.1s ease-in",
      border: `1px solid ${darken(
        props.error ? invalidBtnBackground : btnBackground,
        0.1
      )}`,
      marginTop: "0",
      justifyContent: "flex-start",
      "&:hover": {
        background: darken(
          props.error ? invalidBtnBackground : btnBackground,
          0.05
        ),
      },
    }),

    textFieldStyle: {
      width: "100%",
      marginTop: 5,
    },
    externalAddButton: {
      marginLeft: 4,
      marginTop: -4,
    },
  };
};
