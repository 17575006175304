import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Alert } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../app/store";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { DialogWrapper } from "../../../components/DialogWrapper";
import Endpoints from "../../../constants/endpoints";
import { FieldType } from "../../../model/crud.model";
import { postData } from "../../../network/request";
import { useConfig } from "../../../network/useConfig";
import { useEndpointAsOptions } from "../../../utils/useEndpointAsOptions";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";
import { gameEditionsSelector } from "../addToAllSlice";
import { selectVersionOptions } from "./Filter/filterSlice";

interface CutoffSettingsModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const CutoffSettingsModal = ({
  isOpen,
  onClose,
}: CutoffSettingsModalProps) => {
  const [newValues, setFormValue] = useState({});
  const [showOverrideError, setShowOverrideError] = useState(false);
  const [saving, setSaving] = useState(false);

  const versions = useSelector(selectVersionOptions);
  const gameEditions = useSelector(gameEditionsSelector);

  const { config } = useConfig();

  const { countries, country_groups } = config || {};

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const overrideErrorMessage =
    "Some of these VersionedSettings already exist, do you want to override those?";

  const formatCountries = (countries: any, country_groups: any) => {
    if (country_groups && countries) {
      const items = Object.entries(country_groups?.groups).map(
        ([key, value]) => {
          return {
            key: key,
            value: value,
          };
        }
      );

      const resp = items.map((i: any) => {
        return i.value.map((k: any) => ({
          group: i.key,
          value: k,
          label: countries?.find((j: any) => j.value === k).label,
        }));
      });

      return [
        { group: "Select All", value: "select-all", label: "Select All" },
        ...resp.flat(),
      ];
    }
  };

  const formattedCountries = useMemo(
    () => formatCountries(countries, country_groups),
    [countries, country_groups]
  );

  const model = [
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.SELECT,
      options: gameEditionOptions,
      required: true,
    },
    {
      display_name: "Countries",
      name: "country_codes",
      type: FieldType.MULTISELECT,
      selectOptions: formattedCountries,
      limitTags: 10,
      id: "country_autocomplete",
      label: "Country List",
      placeholder: "countries",
      disabled: false,
      needGrouping: true,
    },
    {
      display_name: "Version Number",
      name: "version_number",
      type: FieldType.SELECT,
      options: versions,
      required: true,
    },
  ];

  const cutoffSettings = async (override?: boolean) => {
    setSaving(true);

    // Try request
    try {
      await postData(Endpoints.SETTINGS_CUTOFF.url, {
        ...newValues,
        override_existing_versioned_settings: !!override,
      });

      store.dispatch(
        queueNotification({
          message: "Override Successful",
          options: {
            key: "override_success",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );

      onClose();
    } catch (error: any) {
      if (!!error.response.data.error_message) {
        const errorMessage = error.response.data.error_message;
        if (errorMessage === overrideErrorMessage) {
          setShowOverrideError(true);
        } else {
          store.dispatch(
            queueNotification({
              message: "errorMessage",
              options: {
                key: "error",
                variant: SnackbarVariant.ERROR,
              },
            })
          );
        }
      }
    }

    setSaving(false);
  };

  return (
    <DialogWrapper isOpen={!!isOpen} onClose={() => onClose()} size="sm">
      <DialogTitle>Cutoff Versioned Settings</DialogTitle>
      <DialogContent>
        <FormContent
          formValue={newValues}
          model={model}
          page="game_settings"
          setFormValue={setFormValue}
        />
        {showOverrideError && (
          <Alert severity="warning">
            <p>{overrideErrorMessage}</p>
            <Button
              disabled={saving}
              className="mr-2"
              variant="contained"
              onClick={() => cutoffSettings(true)}
            >
              Override
            </Button>
            <Button onClick={() => setShowOverrideError(false)}>Dismiss</Button>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          size="small"
          className="ml-1"
          color="secondary"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          disabled={saving}
          variant="contained"
          color="primary"
          onClick={async () => {
            cutoffSettings();
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
