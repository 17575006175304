import { faCaretRight, faNotEqual } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, Collapse } from "@mui/material";
import classNames from "classnames";
import * as Diff from "diff";
import moment from "moment";
import React, { Component } from "react";
import { FaEllipsisV } from "react-icons/fa";
import { isSettingValid } from "../../features/AddToAll/components/Setting/isSettingValid";
import { StyledExpandableSetting } from "../../features/AddToAll/components/Setting/SettingsContainer";
import {
  DifferenceEntry,
  DiffTooltipState,
  NestedSetting,
  serverTimeFormat,
  ValueType,
} from "../../model/versioned-settings.model";
import { checkIfCollection } from "../../utils/checkIfCollection";
import { DifferenceType } from "../../utils/DifferenceType";
import { getUser } from "../../utils/getUser";
import { hasBeenModified } from "../../utils/hasBeenModified";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import matches from "../../utils/matches";
import { recursiveHasBeenModified } from "../../utils/recursiveHasBeenModified";
import SettingParts, {
  SettingKeys,
  unchangedType,
} from "../../utils/SettingParts";
import { unique } from "../../utils/unique";
import { Flex } from "../Flex";
import { StyledMenu } from "../StyledMenu/StyledMenu";
import { StyledMenuItem } from "../StyledMenu/StyledMenuItem";

export interface Props {
  leftSetting?: NestedSetting;
  rightSetting?: NestedSetting;
  oldRightSetting?: NestedSetting;
  settingsDiff?: DifferenceEntry;
  leftSettingName?: string;
  rightSettingName?: string;
  depth: number;
  updateSettings: (isLeft: boolean, setting?: NestedSetting) => void;
  generateNewDiff: () => void;
  isParentDeletedOrAdded?: boolean;
  hideUnchanged?: boolean;
  ignoreChange?: (isIgnored?: boolean) => void;
  isIgnored?: boolean;
  ignoreAllChanges?: boolean;
  acceptAllChanges?: boolean;
  changeMade: () => void;
  isHighlighted?: boolean;
  setTooltip: (isLeft: boolean, state?: DiffTooltipState) => void;
  isDarkMode: boolean;
}

export interface State {
  settingOpen?: boolean;
  ignoredChildren: string[];
  highlightChildren?: boolean;
  anchorEl: null | HTMLElement;
}

class ExpandableDiffSetting extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const isLeftCollection = checkIfCollection(props.leftSetting);
    const isRightCollection = checkIfCollection(props.rightSetting);

    const isCollection = isLeftCollection || isRightCollection;

    this.state.settingOpen = !isCollection || props.depth < 0;
  }
  state: State = {
    ignoredChildren: [],
    anchorEl: null,
  };

  private headingTextRef = React.createRef<HTMLDivElement>();

  private tagTextRef = React.createRef<HTMLDivElement>();

  private leftSettingRef = React.createRef<HTMLDivElement>();
  private rightSettingRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.isIgnored !== this.props.isIgnored && this.props.isIgnored) {
      if (checkIfCollection(this.props.rightSetting)) {
        this.setState({
          ignoredChildren: Object.keys(
            this.props.rightSetting?.__value__ ?? {}
          ),
        });
      }
    }

    if (
      prevProps.ignoreAllChanges !== this.props.ignoreAllChanges &&
      this.props.ignoreAllChanges &&
      hasBeenModified(this.props.settingsDiff?.type) &&
      this.props.depth !== -1
    ) {
      this.keepChanges(true, [
        SettingKeys.VALUE,
        SettingKeys.TAG,
        SettingKeys.DESC,
      ]);
      this.ignoreChange(true);
    }

    // If acceptAllChanges and left setting is not different then accept change otherwise ignore
    if (
      prevProps.acceptAllChanges !== this.props.acceptAllChanges &&
      this.props.acceptAllChanges &&
      hasBeenModified(this.props.settingsDiff?.type) &&
      this.props.depth !== -1
    ) {
      if (this.props.leftSetting?.different) {
        // Ignore change
        this.keepChanges(true, [
          SettingKeys.VALUE,
          SettingKeys.TAG,
          SettingKeys.DESC,
        ]);
        this.ignoreChange(true);
      } else {
        // Accept change
        this.keepChanges(false, [
          SettingKeys.VALUE,
          SettingKeys.TAG,
          SettingKeys.DESC,
        ]);
        this.props.changeMade();
      }
    }
  }

  async keepChanges(
    isLeft: boolean,
    keysToKeep: (keyof typeof SettingParts)[],
    ignoreInverse?: boolean
  ) {
    let fromSetting: NestedSetting;
    let toSetting: NestedSetting;

    // Get inverse keep array of changes to accept/ignore
    const inverseKeysToKeep = Object.keys(SettingParts).filter(
      (part) => !keysToKeep.includes(part as keyof typeof SettingParts)
    );

    if (isLeft) {
      fromSetting = this.props.leftSetting!;
      toSetting = this.props.rightSetting!;
    } else {
      fromSetting = this.props.rightSetting!;
      toSetting = this.props.leftSetting!;
    }

    // Deep clone setting
    let clonedSetting = deepClone(fromSetting);

    // Don't clone settings that are in ignoredChildren
    if (checkIfCollection(fromSetting)) {
      this.state.ignoredChildren.forEach(
        (key) =>
          ((clonedSetting.__value__ as any)[key] = deepClone(
            (toSetting.__value__ as any)[key]
          ))
      );
    }

    //Copy inverseKeys over from toSetting to cloned setting
    inverseKeysToKeep.forEach((key) => {
      if (toSetting) {
        if (clonedSetting) {
          clonedSetting[key as keyof NestedSetting] =
            toSetting[key as keyof NestedSetting];
        }

        if (ignoreInverse) {
          //Ignore inverseKeys by also changing them on fromSetting
          if (fromSetting) {
            fromSetting[key as keyof NestedSetting] =
              toSetting[key as keyof NestedSetting];
          }
        }
      }
    });

    if (clonedSetting) {
      await this.updateSetting(!isLeft, clonedSetting);
    } else {
      await this.props.updateSettings(!isLeft);
    }

    // Generate new diff
    setTimeout(() => this.props.generateNewDiff(), 50);

    this._handleClose();
  }

  updateSetting(isLeft: boolean, setting: NestedSetting) {
    if (isSettingValid(setting)) {
      this.props.updateSettings(isLeft, {
        ...setting,
        __modified__: moment().format(serverTimeFormat),
        __user__: getUser(),
      });
    }
  }

  isLeftOrRightChanged() {
    const { settingsDiff } = this.props;

    // Check if setting has been modified on this level
    return recursiveHasBeenModified(settingsDiff);
  }

  ignoreChange(isIgnored?: boolean) {
    if (!this.props.ignoreChange) {
      return;
    }

    this.props.ignoreChange(isIgnored);
  }

  ignoreSetting(isIgnored: boolean, key: string) {
    const { settingsDiff } = this.props;

    this.setState((prevState) => {
      const ignoredChildren = [...prevState.ignoredChildren];

      if (isNullOrUndefined(isIgnored)) {
        isIgnored = !ignoredChildren.includes(key);
      }

      if (isIgnored && ignoredChildren.includes(key)) {
        return { ignoredChildren };
      }

      if (isIgnored) {
        ignoredChildren.push(key);
      } else {
        const index = ignoredChildren.indexOf(key);
        ignoredChildren.splice(index, 1);
      }

      const modifiedChildren = Object.keys(settingsDiff!.children).filter(
        (key: any) => {
          const type = settingsDiff!.children[key].type;

          return type.__value__ !== DifferenceType.UNCHANGED;
        }
      );

      if (
        matches(modifiedChildren, ignoredChildren, {
          fuzzyComparison: true,
          caseInsensitive: false,
        })
      ) {
        this.ignoreChange(true);
      }

      return { ignoredChildren };
    });
  }

  render() {
    const { hideUnchanged, isIgnored } = this.props;

    const isLeftOrRightChanged = this.isLeftOrRightChanged();

    return hideUnchanged
      ? isLeftOrRightChanged && !isIgnored && this._renderSettingsRow()
      : this._renderSettingsRow();
  }

  private _handleClick = (e: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorEl: e.currentTarget });
  };

  private _handleClose = (): void => {
    this.setState({ anchorEl: null });
  };

  private _renderSettingsRow() {
    const {
      leftSetting,
      rightSetting,
      oldRightSetting,
      leftSettingName,
      rightSettingName,
      settingsDiff,
      depth,
      isParentDeletedOrAdded,
      hideUnchanged,
      isIgnored,
      ignoreAllChanges,
      acceptAllChanges,
      changeMade,
      isDarkMode,
    } = this.props;
    const { settingOpen } = this.state;

    // Check if either setting is a collection
    const isLeftCollection = checkIfCollection(leftSetting);
    const isRightCollection = checkIfCollection(rightSetting);

    const isCollection = isLeftCollection || isRightCollection;

    // Get unique keys from each setting
    let keys: string[] = [];

    if (isLeftCollection) {
      keys.push(...Object.keys(leftSetting!.__value__!));
    }

    if (isRightCollection) {
      keys.push(...Object.keys(rightSetting!.__value__!));
    }

    keys = keys.filter(unique);

    if (leftSetting?.__type__ !== ValueType.ARRAY) {
      keys = keys.sort((a, b) => a.localeCompare(b));
    }

    const isLeftOrRightChanged = this.isLeftOrRightChanged();

    const leftSettingType = settingsDiff && settingsDiff.type;
    const rightSettingType = settingsDiff && settingsDiff.type;
    const isLeftAddition =
      leftSettingType?.__value__ === DifferenceType.ADDITION;
    const isLeftDeleted =
      leftSettingType?.__value__ === DifferenceType.DELETION;
    const isRightAddition =
      rightSettingType?.__value__ === DifferenceType.ADDITION;
    const isRightDeleted =
      rightSettingType?.__value__ === DifferenceType.DELETION;

    const renderedSetting = isIgnored ? oldRightSetting : rightSetting;

    const filteredSettingsDiff = Object.entries(
      settingsDiff?.type ?? {}
    ).filter(([key, value]) => value !== DifferenceType.UNCHANGED);

    return (
      <StyledExpandableSetting darkMode={isDarkMode}>
        <Flex display="flex" flexDirection="column" width="100%">
          <div
            className={classNames(
              "diff-setting",
              {
                "d-none": depth === -1,
              },
              { disabled: leftSetting?.different || rightSetting?.different }
            )}
          >
            {this._renderSetting(
              true,
              leftSetting,
              leftSettingName,
              leftSettingType
            )}
            {this._renderSetting(
              false,
              renderedSetting,
              rightSettingName,
              leftSettingType
            )}
            {isIgnored && (
              <small className="changed-text ignore">Ignored</small>
            )}
            {isLeftOrRightChanged && !isParentDeletedOrAdded && !isIgnored && (
              <Flex
                className="right-buttons"
                alignItems="center"
                onMouseEnter={() => this.setState({ highlightChildren: true })}
                onMouseLeave={() =>
                  this.setState({ highlightChildren: undefined })
                }
              >
                <Flex
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Button
                    color="success"
                    className="diff-button"
                    size="small"
                    disabled={leftSetting?.different || rightSetting?.different}
                    onClick={() => {
                      this.keepChanges(false, [
                        SettingKeys.VALUE,
                        SettingKeys.TAG,
                        SettingKeys.DESC,
                      ]);
                      changeMade();
                    }}
                  >
                    Accept All
                  </Button>
                  <Button
                    className="diff-button"
                    color="success"
                    size="small"
                    disabled={
                      rightSetting?.different ||
                      settingsDiff?.type.__value__ === DifferenceType.UNCHANGED
                    }
                    onClick={() => {
                      this.keepChanges(false, [SettingKeys.VALUE], true);
                      changeMade();
                    }}
                  >
                    Accept value
                  </Button>
                </Flex>
                <Button
                  className="diff-button reject-button"
                  size="small"
                  onClick={() => {
                    this.keepChanges(true, [
                      SettingKeys.VALUE,
                      SettingKeys.TAG,
                      SettingKeys.DESC,
                    ]);
                    this.ignoreChange(true);
                    changeMade();
                  }}
                >
                  Ignore
                </Button>

                <Button
                  className="more-options"
                  aria-controls="customized-menu"
                  style={{ minWidth: 32 }}
                  aria-haspopup="true"
                  endIcon={<FaEllipsisV />}
                  disabled={leftSetting?.different || rightSetting?.different}
                  onClick={(e: any) => this._handleClick(e)}
                />

                <StyledMenu
                  id="customized-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this._handleClose}
                >
                  {filteredSettingsDiff.map(
                    ([key, value], index) =>
                      (key === "__value__" ||
                        ((key === "__tag__" || key === "__description__") &&
                          settingsDiff?.type.__value__ !==
                            DifferenceType.DELETION)) && (
                        <Box key={key}>
                          <StyledMenuItem>
                            {SettingParts[key as keyof typeof SettingParts]}
                          </StyledMenuItem>
                          <StyledMenuItem
                            color="success"
                            onClick={() => this.keepChanges(false, [key])}
                          >
                            Accept
                          </StyledMenuItem>
                          <StyledMenuItem
                            className="text-muted"
                            onClick={() => this.keepChanges(true, [key])}
                          >
                            Ignore
                          </StyledMenuItem>
                          {index !==
                            Object.keys(filteredSettingsDiff ?? {}).length -
                              1 && <StyledMenuItem divider />}
                        </Box>
                      )
                  )}
                </StyledMenu>
              </Flex>
            )}
          </div>
          <div className="setting-content">
            <Collapse in={settingOpen}>
              {isCollection &&
                keys.map((key) => {
                  return (
                    <ExpandableDiffSetting
                      key={key}
                      depth={depth + 1}
                      leftSettingName={key}
                      rightSettingName={key}
                      updateSettings={(isLeft, value) => {
                        setTimeout(() => {
                          let setting = isLeft
                            ? this.props.leftSetting
                            : this.props.rightSetting;

                          if (!setting) {
                            return;
                          }

                          if (!value) {
                            delete (setting.__value__ as any)[key];
                          } else {
                            setting = {
                              ...setting,
                              __value__: {
                                ...(setting.__value__ as any),
                                [key]: value,
                              },
                            };
                          }
                          this.updateSetting(isLeft, setting);
                        }, 50);
                      }}
                      leftSetting={
                        leftSetting?.__value__
                          ? (leftSetting.__value__ as any)[key]
                          : undefined
                      }
                      rightSetting={
                        renderedSetting?.__value__
                          ? (renderedSetting.__value__ as any)[key]
                          : undefined
                      }
                      oldRightSetting={
                        oldRightSetting?.__value__
                          ? (oldRightSetting.__value__ as any)[key]
                          : undefined
                      }
                      settingsDiff={
                        settingsDiff ? settingsDiff.children[key] : undefined
                      }
                      generateNewDiff={this.props.generateNewDiff}
                      isParentDeletedOrAdded={
                        isLeftDeleted ||
                        isRightDeleted ||
                        isLeftAddition ||
                        isRightAddition
                      }
                      hideUnchanged={hideUnchanged}
                      ignoreChange={(isExpanded) => {
                        this.ignoreSetting(isExpanded!, key);
                      }}
                      isIgnored={this.state.ignoredChildren.includes(key)}
                      ignoreAllChanges={ignoreAllChanges}
                      acceptAllChanges={acceptAllChanges}
                      changeMade={changeMade}
                      isHighlighted={
                        this.state.highlightChildren ?? this.props.isHighlighted
                      }
                      setTooltip={this.props.setTooltip}
                      isDarkMode={isDarkMode}
                    />
                  );
                })}
            </Collapse>
          </div>
        </Flex>
      </StyledExpandableSetting>
    );
  }

  private _renderSetting(
    isLeft: boolean,
    setting?: NestedSetting,
    settingName?: string,
    differenceType: {
      [key in keyof typeof SettingParts]: DifferenceType;
    } = unchangedType
  ) {
    const { settingOpen } = this.state;
    const { isIgnored, leftSetting, rightSetting } = this.props;

    let depth = this.props.depth;
    if (!isLeft) {
      depth++;
    }

    const isAddition = differenceType.__value__ === DifferenceType.ADDITION;
    const isModified = hasBeenModified(differenceType);
    const isDeleted = differenceType.__value__ === DifferenceType.DELETION;

    const isChanged = isAddition || isModified || isDeleted;

    const isLeftCollection = checkIfCollection(leftSetting);
    const isRightCollection = checkIfCollection(rightSetting);

    const isCollection = isLeftCollection || isRightCollection;

    if (!setting) {
      if (isLeft) {
        return (
          <div
            className={classNames("setting")}
            style={{ marginLeft: depth * 15 }}
          />
        );
      }

      return (
        <div
          className={classNames(
            "setting",
            `setting-${this.props.leftSetting?.__type__}`
          )}
          style={{ marginLeft: depth * 15 }}
          onClick={() => this.setState({ settingOpen: !settingOpen })}
        >
          <div
            className={classNames(
              "setting-card align-items-center",
              {
                deleted: !isLeft,
              },
              {
                "hover-from-parent":
                  (this.props.isHighlighted || this.state.highlightChildren) &&
                  isChanged,
              },
              { "cursor-pointer": isCollection }
            )}
          >
            <Flex
              className="heading-tag-area"
              display="flex"
              flexDirection="column"
            >
              <Flex alignItems="center">
                {isCollection && (
                  <FontAwesomeIcon
                    className={classNames("caret", {
                      expanded: this.state.settingOpen,
                    })}
                    icon={faCaretRight}
                  />
                )}
                <div className="heading-container">
                  <div
                    className={classNames("heading", "non-editable", {
                      marquee:
                        this.headingTextRef.current &&
                        (this.headingTextRef.current.clientWidth >= 200 ||
                          (this.headingTextRef.current.clientWidth >= 140 &&
                            isChanged)),
                    })}
                    ref={this.headingTextRef}
                  >
                    {this.props.leftSettingName}{" "}
                  </div>
                </div>
                {(!isLeft || !isIgnored) && (
                  <Box ml="0.5rem">
                    <Chip label="Deleted" size="small" color="secondary" />
                  </Box>
                )}
              </Flex>
              {this.props.leftSetting?.__tag__ && (
                <div className="tag-container">
                  <div
                    className={classNames("tag", "non-editable", {
                      marquee:
                        this.tagTextRef.current &&
                        this.tagTextRef.current.clientWidth >= 200,
                    })}
                    ref={this.tagTextRef}
                  >
                    {this.props.leftSetting?.__tag__}
                  </div>
                </div>
              )}
            </Flex>
            {this.renderSettingInlineValue(isLeft, this.props.leftSetting)}
            <div className="badge-column">
              <Chip
                className="type-badge"
                label={`${this.props.leftSetting?.__type__} ${
                  isCollection
                    ? `(${
                        Object.keys(this.props.leftSetting?.__value__!).length
                      })`
                    : ""
                }`}
              ></Chip>
            </div>
          </div>
        </div>
      );
    }

    const settingId = `setting-${
      this.props.leftSetting
        ? this.props.leftSetting?.__valuehash__
        : this.props.rightSetting?.__valuehash__
    }-${
      this.props.leftSettingName
        ? this.props.leftSettingName.replace(/[\])}[{(]/g, "")
        : this.props.rightSettingName?.replace(/[\])}[{(]/g, "")
    }`;

    // Diff descriptions
    const diffDesc = Diff.diffChars(
      this.props.leftSetting?.__description__ ?? "",
      this.props.rightSetting?.__description__ ?? ""
    );

    // Diff tags
    const diffTag = Diff.diffChars(
      this.props.leftSetting?.__tag__ ?? "",
      this.props.rightSetting?.__tag__ ?? ""
    );

    return (
      <div
        className={classNames("setting", `setting-${setting?.__type__}`)}
        style={{ marginLeft: depth * 15 }}
        // aria-describedby={isLeft ? "left-" + settingId : "right-" + settingId}
        aria-owns={isLeft ? "left-" + settingId : "right-" + settingId}
        aria-haspopup="true"
        // id={isLeft ? "left-" + settingId : "right-" + settingId}
        onMouseEnter={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
          if (
            this.props.leftSetting?.__description__ ||
            this.props.rightSetting?.__description__
          ) {
            this.props.leftSetting &&
              this.props.setTooltip(true, {
                target: event.currentTarget,
                id: "left-" + settingId,
                text: this.props.leftSetting?.__description__ ?? "",
              });
            this.props.rightSetting &&
              this.props.setTooltip(false, {
                id: "right-" + settingId,
                target: event.currentTarget,
                text: diffDesc,
              });
          }
        }}
        onMouseLeave={() => {
          if (
            this.props.leftSetting?.__description__ ||
            this.props.rightSetting?.__description__
          ) {
            this.props.setTooltip(true);
            this.props.setTooltip(false);
          }
        }}
      >
        <div
          className={classNames(
            "setting-card align-items-center",
            {
              modified: isModified && !isLeft && !isIgnored,
              addition: isAddition && !isLeft && !isIgnored,
            },
            {
              "hover-from-parent":
                (this.props.isHighlighted || this.state.highlightChildren) &&
                isChanged,
            },
            { "cursor-pointer": isCollection }
          )}
          onClick={() => this.setState({ settingOpen: !settingOpen })}
        >
          <Flex
            display="flex"
            flexDirection="column"
            className="heading-tag-area"
          >
            <Flex display="flex" alignItems="center">
              {isCollection && (
                <FontAwesomeIcon
                  className={classNames("caret", {
                    expanded: this.state.settingOpen,
                  })}
                  icon={faCaretRight}
                />
              )}
              <div className="heading-container">
                <div
                  className={classNames("heading", "non-editable", {
                    marquee:
                      this.headingTextRef.current &&
                      (this.headingTextRef.current.clientWidth >= 200 ||
                        (this.headingTextRef.current.clientWidth >=
                          155 - depth * 10 &&
                          isChanged)) &&
                      !isCollection,
                  })}
                  ref={this.headingTextRef}
                >
                  {settingName}
                </div>
              </div>
              {isChanged && !isLeft && !isIgnored && (
                <Box ml="0.5rem">
                  <Chip
                    label={isAddition ? "Added" : isModified && "Modified"}
                    size="small"
                    color={isAddition ? "secondary" : "primary"}
                  />
                </Box>
              )}
            </Flex>
            {setting?.__tag__ && (
              <div className="tag-container">
                <div
                  className={classNames("tag", "non-editable", {
                    marquee:
                      this.tagTextRef.current &&
                      this.tagTextRef.current.clientWidth >= 200,
                  })}
                  ref={this.tagTextRef}
                >
                  {!isLeft
                    ? diffTag.map((el) => (
                        <span
                          key={el.value}
                          className={
                            el.added
                              ? "green-highlight highlight"
                              : el.removed
                              ? "red-highlight highlight"
                              : ""
                          }
                        >
                          {el.value}
                        </span>
                      ))
                    : setting.__tag__}
                </div>
              </div>
            )}
          </Flex>
          {this.props.leftSetting?.different && isLeft && (
            <Box ml="0.5rem">
              <Chip
                label={<FontAwesomeIcon icon={faNotEqual} />}
                size="small"
                color="primary"
              />
            </Box>
          )}
          {this.renderSettingInlineValue(isLeft)}
          <div className="badge-column">
            <Chip
              className="type-badge"
              label={`${setting.__type__} ${
                isCollection
                  ? `(${Object.keys(setting?.__value__!).length})`
                  : ""
              }`}
            />
          </div>
        </div>
      </div>
    );
  }

  private renderSettingInlineValue(isLeft: boolean, setting?: NestedSetting) {
    const { isIgnored, leftSetting, rightSetting, oldRightSetting } =
      this.props;

    // Check if either setting is a collection
    const isLeftCollection = checkIfCollection(leftSetting);
    const isRightCollection = checkIfCollection(rightSetting);

    const isCollection = isLeftCollection || isRightCollection;

    const renderedSetting = isIgnored ? oldRightSetting : rightSetting;

    return (
      !isCollection &&
      !(leftSetting?.different && isLeft) && (
        <div className="w-100 mx-2">
          <Flex
            display="flex"
            justifyContent="center"
            className="atomic-value-container "
          >
            {isLeft &&
            leftSetting?.__value__ !== undefined &&
            !isNullOrUndefined(leftSetting) ? (
              <div className="setting-atomic-value">
                <span>
                  {leftSetting?.__value__ === null
                    ? "null"
                    : leftSetting.__value__?.toString()}
                </span>
              </div>
            ) : !isLeft &&
              renderedSetting?.__value__ !== undefined &&
              !isNullOrUndefined(renderedSetting) ? (
              <div className="setting-atomic-value">
                <span>
                  {renderedSetting?.__value__ === null
                    ? "null"
                    : renderedSetting?.__value__?.toString()}
                </span>
              </div>
            ) : (
              <div className="setting-atomic-value">
                <span>
                  {setting?.__value__ === null
                    ? "null"
                    : setting?.__value__?.toString()}
                </span>
              </div>
            )}
          </Flex>
        </div>
      )
    );
  }
}

export function deepClone<T>(obj: T): any {
  if (typeof obj === "object") {
    if (isNullOrUndefined(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((el) => deepClone(el)) as never as T;
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepClone(value)])
    );
  }

  return obj;
}

export default ExpandableDiffSetting;
