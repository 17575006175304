import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ButtonGroup,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuProps,
  MenuItem,
  Collapse,
  Popover,
  Tooltip,
  Badge,
  IconButton,
  TextField,
  List,
  ListItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import {
  FaChevronCircleDown,
  FaChevronLeft,
  FaCopy,
  FaEdit,
  FaFileAlt,
  FaFileExport,
  FaFileImport,
  FaLevelDownAlt,
  FaSave,
  FaSyringe,
  FaTrash,
  RiAdvertisementFill,
} from "react-icons/all";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { store } from "../../../../app/store";
import { Flex } from "../../../../components/Flex";
import { useReactSelectGameEditions } from "../../../../utils/useReactSelectGameEditions";
import { selectDarkMode, setDarkMode } from "../../../general/generalSlice";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../notification/notificationSlice";
import { selectSession } from "../../../session/sessionSlice";
import { AddNewVersionedSettings } from "../AddNewVersionedSettings";
import { CutoffSettingsModal } from "../CutoffSettingsModal";
import { DeleteVersionedSettings } from "../DeleteVersionedSettings";
import { DuplicateVersionedSettings } from "../DuplicateVersionedSettings";
import { ExportSettingsModal } from "../ExportSettingsModal";
import { ImportSettingsModal } from "../ImportSettingsModal";
import {
  ReactSelectArray,
  VersionedSettingss,
} from "../../../../model/versioned-settings.model";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import {
  changesSelector,
  countriesSelector,
  isDisabledSelector,
  loadingSettingsSelector,
  selectDuplicateSettingsModalOpen,
  selectedSettingsObjectsSelector,
  selectedSettingsSelector,
  setAfterCombineYScrollPosition,
  setDuplicateSettingsModal,
  setIdsToOverrideFilters,
  settingsSelector,
  updateExpandedSettings,
} from "../../addToAllSlice";
import { useConfig } from "../../../../network/useConfig";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { InjectConversionTableModal } from "../InjectConversionTableModal";
import SelectedSettings from "../SelectedSettings";
import {
  dropdownsSelector,
  editModeSelector,
  filterCollapsedSelector,
  selectedSettingsCollapseSelector,
  selectHideDefaultSettings,
  selectHideModified,
  selectHideTooltips,
  selectVersionOptions,
  setHideDefaultSettings,
  setHideModified,
  setHideTooltips,
  toggleDarkMode,
  toggleEditMode,
  toggleFilterCollapse,
  toggleSelectedSettingsCollapse,
  updateDropdown,
  updateSwitcher,
} from "./filterSlice";

import { AutoCompleteSelect } from "../AutocompleteSelect/AutoCompleteSelect";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { createFilterOptions } from "@mui/material";
import { InjectAdSettingsModal } from "../InjectAdSettingsModal";
import { odiffResult } from "odiff";
import { getWorkerInstance } from "../../../../App";
import { saveSelectedSettings } from "../../addToAllThunks";

type NestedObject = {
  [key: string]: any;
};

function findEmptyDescriptions(obj: NestedObject, path: string = ""): string[] {
  let emptyDescriptions: string[] = [];

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      emptyDescriptions = emptyDescriptions.concat(
        findEmptyDescriptions(item, `${path}[${index}]`)
      );
    });
  } else if (typeof obj === "object" && obj !== null) {
    if ("__description__" in obj && obj["__description__"] === "") {
      emptyDescriptions.push(path);
    }
    Object.entries(obj).forEach(([key, value]) => {
      emptyDescriptions = emptyDescriptions.concat(
        findEmptyDescriptions(value, path ? `${path}.${key}` : key)
      );
    });
  }

  return emptyDescriptions;
}

function addMissingDescriptions(obj: NestedObject): NestedObject {
  if (Array.isArray(obj)) {
    return obj.map((item) => addMissingDescriptions(item));
  }

  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const newObj: NestedObject = {};
  for (const [key, value] of Object.entries(obj)) {
    // If the object has a "__description__" key and it's empty, set it to "No description"
    if (key === "__description__" && (value === "" || value == null)) {
      newObj[key] = "No description";
    } else {
      // Recursively apply this logic to all nested objects
      newObj[key] = addMissingDescriptions(value);
    }
  }

  return newObj;
}

const formatPath = (path: string): string => {
  return path
    .replace(/^\[\d+\]\./, "")
    .split(".")
    .filter((item) => item !== "__value__" && item !== "nested_settings")
    .map((item) => {
      if (item.startsWith("__value__[")) {
        return item.replace("__value__", "");
      }
      return item;
    })
    .join(" -> ")
    .replace(/\[(\d+)\]/g, " ($1)");
};

const StyledFilterWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const StyledFilterContainer = styled(Box)`
  padding: 0.5rem;
  transition: background-color 0.2s ease-in-out;

  background-color: ${(props) => props.theme.filter.backgroundColor};
  color: ${(props) => props.theme.textColor};
`;

interface StyledOptionsInterface {
  importToAll?: boolean;
}

const StyledOptionsContainer = styled.div<StyledOptionsInterface>`
  padding: ${(props) => (props.importToAll ? "0" : "0.4rem")};

  margin: auto;

  small {
    color: gray;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;

  @media only screen and (max-width: 550px) {
    display: block;
  }

  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 5;
    grid-row-end: 5;
  }
`;

export const StyledDropdown = styled.div<{ darkMode: boolean }>`
  width: 100%;
  padding: 0 0.25rem;
  small {
    font-size: 12px;
  }

  .advanced-select .as-field .as-field-buttons .as-field-button {
    padding: 0 8px;
  }

  .advanced-select {
    &.disabled {
      background-color: ${(props) => props.theme.collapseColor};
    }
  }

  .as-field {
    background-color: ${(props) => props.theme.dropdown?.backgroundColor};
    border-color: ${(props) => props.theme.dropdown?.borderColor};
  }

  .as-field-select-option {
    color: ${(props) => props.theme.textColor} !important;
  }
`;

const StyledPlatformDropdown = styled(StyledDropdown)`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;

  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
  }
`;

const StyledCountriesDropdown = styled(StyledDropdown)`
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;

  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 2;
    grid-row-end: 2;
  }
`;

const StyledVersionsDropdown = styled(StyledDropdown)`
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;

  @media only screen and (max-width: 768px) {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 3;
    grid-row-end: 3;
  }
`;

const StyledSaveButton = styled.div`
  small {
    margin-right: 0.5em;
    font-style: italic;
    font-weight: 300;
  }
  @media only screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;

    small {
      margin: 0.5em 0;
    }
  }
`;

export const StyledCollapseBar = styled.div<{ darkMode: boolean }>`
  text-align: center;
  padding: 0.1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  background-color: ${(props) => props.theme.filter.backgroundColor};
  color: ${(props) => props.theme.textColor};
  border-bottom: 1px solid #007bff;

  &:hover {
    background-color: ${(props) => (props.darkMode ? "#292929" : "#dbdbdb")};
  }

  svg {
    transition: transform 0.2s ease-in-out;
    ${(props: any) => props.filterCollapsed && "transform: rotateX(180deg);"}
  }
`;

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    // getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Filter = (props: any) => {
  const editMode = useSelector(editModeSelector);
  const selectedSettings = useSelector(selectedSettingsObjectsSelector);
  const changes = useSelector(changesSelector);
  const dispatch = useDispatch();
  const versions = useSelector(selectVersionOptions);
  const loadingSettings = useSelector(loadingSettingsSelector);
  const duplicateModalOpen = useSelector(selectDuplicateSettingsModalOpen);
  const hideModified = useSelector(selectHideModified);
  const hideDefaultSettings = useSelector(selectHideDefaultSettings);
  const hideTooltips = useSelector(selectHideTooltips);

  const dropdowns = useSelector(dropdownsSelector);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [conversionModalOpen, setConversionModalOpen] = useState(false);
  const [adSettingInjectionModalOpen, setAdSettingInjectionModalOpen] =
    useState(false);
  const [cutoffSettingsModalOpen, setCutoffSettingsModalOpen] = useState(false);
  const [addNewSettingsModal, setAddNewSettingsModal] = useState(false);
  const [deleteSettingsModal, setDeleteSettingsModal] = useState(false);

  const gameEditionsReactSelect = useReactSelectGameEditions();
  const { config } = useConfig();

  const [filterOpen, setFilterOpen] = useState(false);
  const [emptyDescriptions, setEmptyDescriptions] = useState<string[]>([]);

  const lilacUsers = config?.lilac_theme as string[] | undefined;

  const [importModal, setImportModal] = useState({
    importModalOpen: false,
    importType: "",
  });

  const handleSave = async () => {
    if (!changes) {
      store.dispatch(
        queueNotification({
          message: "No changes made to settings",
          options: {
            key: "no_changes_warning",
            variant: SnackbarVariant.WARNING,
          },
        })
      );

      return;
    }

    dispatch(setAfterCombineYScrollPosition(window.scrollY));

    // code
    const filteredChanges = changes?.filter(
      (change: odiffResult) => change.path.length > 1
    );

    const selectedSettingsWithChanges = filteredChanges
      ? await getWorkerInstance().applyChangesToSettings(
          selectedSettings,
          filteredChanges
        )
      : selectedSettings;

    handleCheckFilter(selectedSettingsWithChanges);
  };

  const handleCheckFilter = async (data: VersionedSettingss) => {
    const items = findEmptyDescriptions(data);

    const formattedItems = items.map(formatPath);

    if (formattedItems.length === 0) {
      await proceedWithSaving(data);
    } else {
      setEmptyDescriptions(formattedItems);
      setFilterOpen(true);
    }
  };

  const handleCloseFilter = () => {
    setFilterOpen(false);
  };

  const proceedWithSaving = async (data: VersionedSettingss) => {
    // @ts-ignore
    const resultAction = await dispatch(
      //@ts-ignore
      saveSelectedSettings({
        selectedSettings,
        changes,
        selectedSettingsWithChanges: data,
      })
    );
    //@ts-ignore
    if (!saveSelectedSettings.fulfilled.match(resultAction)) {
      //@ts-ignore
      const errorMessage = `Error saving settings: ${resultAction.error.message}`;
      store.dispatch(
        queueNotification({
          message: errorMessage,
          options: {
            key: "error_saving_settings",
            variant: SnackbarVariant.ERROR,
          },
        })
      );
    } else {
      store.dispatch(
        queueNotification({
          message: "Game settings successfully updated",
          options: {
            key: "success_saving_settings",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    }
  };

  const handleProceedFilter = async () => {
    const filteredChanges = changes?.filter(
      (change: odiffResult) => change.path.length > 1
    );

    const selectedSettingsWithChanges = filteredChanges
      ? await getWorkerInstance().applyChangesToSettings(
          selectedSettings,
          filteredChanges
        )
      : selectedSettings;
    const cleanedData = selectedSettingsWithChanges.map((item: any) =>
      addMissingDescriptions(item)
    );
    setFilterOpen(false);

    await proceedWithSaving(cleanedData);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearSettingIdsAndSwitcher = () => {
    dispatch(setIdsToOverrideFilters([]));
    dispatch(updateSwitcher(undefined));
    dispatch(updateExpandedSettings(undefined));
  };

  const theme = useSelector(selectDarkMode);
  const isDarkMode = theme === "dark";
  const isLilacMode = theme === "lilac";

  const { user_id: user } = useSelector(selectSession);

  const settingIdSelectedMessage = "Specific Settings Selected";

  const isLilacUser = lilacUsers?.includes(user ?? "");

  const [selectedSettingsAnchorEl, setSelectedSettingsAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleSelectedSettingsClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSelectedSettingsAnchorEl(event.currentTarget);
  };

  const handleSelectedSettingsClose = () => {
    setSelectedSettingsAnchorEl(null);
  };

  const deleteOption = (selectedOption: ReactSelectArray, label: string) => {
    const newSelectionOption = [...selectedOption];
    const index = newSelectionOption.findIndex((value) =>
      _.isEqual(value, {
        label: label,
        value: null,
      })
    );

    if (!isNullOrUndefined(index) && index >= 0) {
      newSelectionOption.splice(index, 1);
    }

    return newSelectionOption;
  };

  const open = Boolean(selectedSettingsAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const { countries, country_groups } = config || {};

  const formatCountries = (countries: any, country_groups: any) => {
    if (country_groups && countries) {
      const items = Object.entries(country_groups?.groups).map(
        ([key, value]) => {
          return {
            key: key,
            value: value,
          };
        }
      );

      const resp = items.map((i: any) => {
        return i.value.map((k: any) => ({
          group: i.key,
          value: k,
          label: countries?.find((j: any) => j.value === k).label,
        }));
      });

      return [
        { group: "Select All", value: "select-all", label: "Select All" },
        ...resp.flat(),
      ];
    }
  };

  const formattedCountries = useMemo(
    () => formatCountries(countries, country_groups),
    [countries, country_groups]
  );

  const checkGroup = (group: string) => {
    const groupLength = formattedCountries?.filter(
      (c: any) => c.group === group
    ).length;
    const selectedGroupLength = dropdowns?.countries?.filter(
      (c: any) => c.group === group
    ).length;

    return (
      groupLength === selectedGroupLength ||
      (formattedCountries ?? [])?.length - 1 === dropdowns?.countries?.length
    );
  };

  const selectGroup = (group: string) => {
    if (group === "Select All") {
      props.updateDropdown({
        dropdown: "countries",
        selected:
          dropdowns?.countries?.length ===
          (formattedCountries ?? [])?.length - 1
            ? []
            : formattedCountries?.filter((k) => k.group !== "Select All"),
      });
    } else if (
      dropdowns?.countries?.filter((k: any) => k.group === group).length ===
      formattedCountries?.filter((c: any) => c.group === group).length
    ) {
      props.updateDropdown({
        dropdown: "countries",
        selected: dropdowns?.countries?.filter(
          (item: any) => item.group !== group
        ),
      });
    } else {
      const selectedGroupCountries = formattedCountries?.filter(
        (c: any) => c.group === group
      );
      props.updateDropdown({
        dropdown: "countries",
        selected: [
          ...(dropdowns?.countries?.filter((k: any) => k.group !== group) ??
            []),
          ...(selectedGroupCountries ?? []),
        ],
      });
    }
  };

  const handleOptionSelected = (
    selectedOption: any,
    reason: string,
    type: string,
    values: any
  ): void => {
    if (selectedOption.find((option: any) => option.value === "select-all")) {
      if (dropdowns?.[type]?.length !== values.length) {
        props.updateDropdown({
          dropdown: type,
          selected: values,
        });
      } else {
        props.updateDropdown({
          dropdown: type,
          selected: [],
        });
      }
    } else {
      selectedOption = deleteOption(selectedOption, settingIdSelectedMessage);

      props.updateDropdown({
        dropdown: type,
        selected: selectedOption,
      });
    }

    clearSettingIdsAndSwitcher();
  };

  if (props.importToAll) {
    return (
      <StyledOptionsContainer importToAll={true}>
        <StyledFilterContainer>
          <Flex alignItems="center">
            <StyledPlatformDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                multiselect
                limitTags={4}
                id="platform-autocomp-import"
                disabled={
                  isNullOrUndefined(gameEditionsReactSelect) || props.disabled
                }
                options={gameEditionsReactSelect || []}
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                  option.value === value.value
                }
                selected={
                  gameEditionsReactSelect?.filter((item: any) =>
                    dropdowns?.platforms
                      ?.map((k: any) => k.value)
                      .includes(item.value)
                  ) ?? []
                }
                onOptionSelected={(event, selectedOption, reason) =>
                  handleOptionSelected(
                    selectedOption,
                    reason,
                    "platforms",
                    gameEditionsReactSelect
                  )
                }
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();

                  const filtered = filter(options, params);
                  return [
                    { label: "Select All", value: "select-all" },
                    ...filtered,
                  ];
                }}
                renderOptions={(props, option, { selected }) => {
                  const selectAllProps =
                    option.value === "select-all"
                      ? {
                          checked:
                            dropdowns?.platforms?.length ===
                            gameEditionsReactSelect.length,
                        }
                      : {};

                  return (
                    <li {...props}>
                      <Checkbox
                        color="primary"
                        key={option.value}
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...selectAllProps}
                      />
                      {option?.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="Platform"
                    placeholder="Select a Platform"
                  />
                )}
                size="small"
              />
            </StyledPlatformDropdown>

            <StyledVersionsDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                multiselect
                disabled={isNullOrUndefined(versions) || props.disabled}
                limitTags={4}
                id="version-autocomp-import"
                options={versions || []}
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                  option.value === value.value
                }
                selected={
                  versions?.filter((item: any) =>
                    dropdowns?.versions
                      ?.map((k: any) => k.value)
                      .includes(item.value)
                  ) ?? []
                }
                onOptionSelected={(event, selectedOption, reason) =>
                  handleOptionSelected(
                    selectedOption,
                    reason,
                    "versions",
                    versions
                  )
                }
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();

                  const filtered = filter(options, params);
                  return [
                    { label: "Select All", value: "select-all" },
                    ...filtered,
                  ];
                }}
                renderOptions={(props, option, { selected }) => {
                  const selectAllProps =
                    option.value === "select-all"
                      ? {
                          checked:
                            dropdowns?.versions?.length === versions.length,
                        }
                      : {};

                  return (
                    <li {...props}>
                      <Checkbox
                        color="primary"
                        key={option.value}
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...selectAllProps}
                      />
                      {option?.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Versions" />
                )}
                size="small"
              />
            </StyledVersionsDropdown>
            <StyledCountriesDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                multiselect
                disabled={
                  isNullOrUndefined(formattedCountries) || props.disabled
                }
                limitTags={4}
                id="countries-autocomp"
                options={formattedCountries || []}
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                  option.value === value.value
                }
                selected={
                  formattedCountries?.filter((item: any) =>
                    dropdowns?.countries
                      ?.map((k: any) => k.value)
                      .includes(item.value)
                  ) ?? []
                }
                onOptionSelected={(event, selectedOption, reason) =>
                  handleOptionSelected(
                    selectedOption,
                    reason,
                    "countries",
                    countries
                  )
                }
                group={(option: { group: string }) => option.group}
                renderOptions={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.label}
                    </li>
                  );
                }}
                renderGroup={(params) => {
                  return (
                    <>
                      <Checkbox
                        key={params.value}
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        checked={checkGroup(params.group)}
                        onChange={() => selectGroup(params.group)}
                      />
                      <span>{params.group}</span>
                      <div key={params.value}>
                        {params.group !== "Select All" && params.children}{" "}
                      </div>
                    </>
                  );
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Countries" />
                )}
                size="small"
              />
            </StyledCountriesDropdown>
          </Flex>
        </StyledFilterContainer>
      </StyledOptionsContainer>
    );
  }

  return (
    <StyledFilterWrapper>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxWidth: "600px" } }}
        open={filterOpen}
        onClose={handleCloseFilter}
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            These items have no description:
          </DialogContentText>
          <List>
            {emptyDescriptions.map((desc, index) => (
              <ListItem key={index}>{desc}</ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            color="success"
            variant="contained"
            onClick={handleProceedFilter}
          >
            Proceed
          </Button>
          <Button variant="contained" onClick={handleCloseFilter}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Collapse in={!props.filterCollapsed}>
        <StyledFilterContainer>
          <Flex alignItems="center">
            <StyledPlatformDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                multiselect
                limitTags={4}
                id="platform-autocomp"
                disabled={
                  isNullOrUndefined(gameEditionsReactSelect) || props.disabled
                }
                options={gameEditionsReactSelect || []}
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                  option.value === value.value
                }
                selected={
                  gameEditionsReactSelect?.filter((item: any) =>
                    dropdowns?.platforms
                      ?.map((k: any) => k.value)
                      .includes(item.value)
                  ) ?? []
                }
                onOptionSelected={(event, selectedOption, reason) =>
                  handleOptionSelected(
                    selectedOption,
                    reason,
                    "platforms",
                    gameEditionsReactSelect
                  )
                }
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();

                  const filtered = filter(options, params);
                  return [
                    { label: "Select All", value: "select-all" },
                    ...filtered,
                  ];
                }}
                renderOptions={(props, option, { selected }) => {
                  const selectAllProps =
                    option.value === "select-all"
                      ? {
                          checked:
                            dropdowns?.platforms?.length ===
                            gameEditionsReactSelect.length,
                        }
                      : {};
                  return (
                    <li {...props}>
                      <Checkbox
                        color="primary"
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...selectAllProps}
                      />
                      {option?.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Platform" />
                )}
                size="small"
              />
            </StyledPlatformDropdown>
            <StyledVersionsDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                multiselect
                disabled={isNullOrUndefined(versions) || props.disabled}
                limitTags={4}
                id="version-autocomp"
                options={versions || []}
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                  option.value === value.value
                }
                selected={
                  versions?.filter((item: any) =>
                    dropdowns?.versions
                      ?.map((k: any) => k.value)
                      .includes(item.value)
                  ) ?? []
                }
                onOptionSelected={(event, selectedOption, reason) =>
                  handleOptionSelected(
                    selectedOption,
                    reason,
                    "versions",
                    versions
                  )
                }
                filterOptions={(options, params) => {
                  const filter = createFilterOptions();

                  const filtered = filter(options, params);
                  return [
                    { label: "Select All", value: "select-all" },
                    ...filtered,
                  ];
                }}
                renderOptions={(props, option, { selected }) => {
                  const selectAllProps =
                    option.value === "select-all"
                      ? {
                          checked:
                            dropdowns?.versions?.length === versions.length,
                        }
                      : {};

                  return (
                    <li {...props}>
                      <Checkbox
                        color="primary"
                        key={option?.value}
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        {...selectAllProps}
                      />
                      {option?.label}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Versions" />
                )}
                size="small"
              />
            </StyledVersionsDropdown>

            <StyledCountriesDropdown darkMode={isDarkMode}>
              <AutoCompleteSelect
                multiselect
                disabled={
                  isNullOrUndefined(formattedCountries) || props.disabled
                }
                limitTags={4}
                id="countries-autocomp"
                options={formattedCountries || []}
                getOptionLabel={(option: any) => option.label}
                getOptionSelected={(option: any, value: any) =>
                  option.value === value.value
                }
                selected={
                  formattedCountries?.filter((item: any) =>
                    dropdowns?.countries
                      ?.map((k: any) => k.value)
                      .includes(item.value)
                  ) ?? []
                }
                onOptionSelected={(event, selectedOption, reason) =>
                  handleOptionSelected(
                    selectedOption,
                    reason,
                    "countries",
                    countries
                  )
                }
                group={(option: { group: string }) => option.group}
                renderOptions={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Checkbox
                        key={option.value}
                        color="primary"
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.label}
                    </li>
                  );
                }}
                renderGroup={(params) => {
                  return (
                    <li style={{ padding: 2 }}>
                      <Checkbox
                        key={params.value}
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        checked={checkGroup(params.group)}
                        onChange={() => selectGroup(params.group)}
                      />
                      <span>{params.group}</span>
                      <div key={params.value}>
                        {params.group !== "Select All" && params.children}{" "}
                      </div>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Countries" />
                )}
                size="small"
              />
            </StyledCountriesDropdown>
            <Tooltip title="View Selected Settings">
              <Box mt="0.9rem" mr="1rem" ml="0.5rem">
                <Badge
                  badgeContent={selectedSettings?.length}
                  max={999}
                  color="primary"
                >
                  <IconButton
                    size="small"
                    onClick={handleSelectedSettingsClick}
                  >
                    <FaChevronCircleDown />
                  </IconButton>
                </Badge>
              </Box>
            </Tooltip>
          </Flex>

          <Popover
            id={id}
            open={open}
            anchorEl={selectedSettingsAnchorEl}
            onClose={handleSelectedSettingsClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SelectedSettings selectedSettingsIds={props.selectedSettingsIds} />
          </Popover>
          <StyledOptionsContainer>
            <StyledButtonArea>
              <Flex display="flex" justifyContent="center" alignItems="center">
                <Box mr={2}>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClick}
                  >
                    Options
                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem
                      disabled={!editMode}
                      onClick={() => {
                        setAddNewSettingsModal(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaFileAlt fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="New Settings" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      disabled={loadingSettings || !editMode}
                      onClick={() => {
                        setImportModal({
                          importModalOpen: true,
                          importType: "game-settings/import-game-settings",
                        });
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaFileImport fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Import to Settings" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      disabled={loadingSettings || !editMode}
                      onClick={() => {
                        setCutoffSettingsModalOpen(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaLevelDownAlt fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Cutoff Settings" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      disabled={loadingSettings || !editMode}
                      onClick={() => {
                        setDeleteSettingsModal(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaTrash fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Delete Settings" />
                    </StyledMenuItem>

                    <StyledMenuItem
                      disabled={loadingSettings || !editMode}
                      onClick={() => {
                        dispatch(setDuplicateSettingsModal(true));
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Duplicate Settings" />
                    </StyledMenuItem>

                    <StyledMenuItem
                      disabled={loadingSettings}
                      onClick={() => {
                        setExportModalOpen(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaFileExport fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Export Settings" />
                    </StyledMenuItem>

                    <StyledMenuItem
                      disabled={loadingSettings || !editMode}
                      onClick={() => {
                        setConversionModalOpen(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FaSyringe fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Inject Conversion Table" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      disabled={loadingSettings || !editMode}
                      onClick={() => {
                        setAdSettingInjectionModalOpen(true);
                        handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <RiAdvertisementFill fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Inject adSettings" />
                    </StyledMenuItem>
                  </StyledMenu>
                </Box>

                <FormControlLabel
                  id="darkMode"
                  control={
                    <Checkbox
                      checked={isDarkMode ?? false}
                      onChange={() =>
                        dispatch(setDarkMode(isDarkMode ? "light" : "dark"))
                      }
                    />
                  }
                  label="Dark Mode"
                />

                {isLilacUser && (
                  <FormControlLabel
                    id="lilacMode"
                    control={
                      <Checkbox
                        checked={isLilacMode ?? false}
                        onChange={() =>
                          dispatch(setDarkMode(isLilacMode ? "dark" : "lilac"))
                        }
                      />
                    }
                    label="Lilac Mode"
                  />
                )}
                <FormControlLabel
                  id="hideModified"
                  control={
                    <Checkbox
                      checked={hideModified ?? false}
                      onChange={() => dispatch(setHideModified(!hideModified))}
                    />
                  }
                  label="Hide Modified By"
                />
                <FormControlLabel
                  id="hideDefaultSettings"
                  control={
                    <Checkbox
                      checked={hideDefaultSettings ?? false}
                      onChange={() =>
                        dispatch(setHideDefaultSettings(!hideDefaultSettings))
                      }
                    />
                  }
                  label="Hide Kwalee Settings"
                />
                <FormControlLabel
                  id="hideTooltips"
                  control={
                    <Checkbox
                      checked={hideTooltips ?? false}
                      onChange={() => dispatch(setHideTooltips(!hideTooltips))}
                    />
                  }
                  label="Hide Empty Tooltips in Edit mode"
                />
              </Flex>

              <StyledSaveButton>
                {editMode ? (
                  <>
                    <ButtonGroup size="small">
                      <Button
                        onClick={() => dispatch(toggleEditMode())}
                        variant="outlined"
                        size="small"
                        startIcon={<FaChevronLeft />}
                      >
                        Exit Editing
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        disabled={
                          props.disabled || selectedSettings?.length === 0
                        }
                        onClick={() => handleSave()}
                        startIcon={<FaSave />}
                        variant="contained"
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  </>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    className="ml-2"
                    disabled={props.disabled}
                    onClick={() => dispatch(toggleEditMode())}
                    startIcon={<FaEdit />}
                  >
                    Edit
                  </Button>
                )}
              </StyledSaveButton>
            </StyledButtonArea>
          </StyledOptionsContainer>
        </StyledFilterContainer>
      </Collapse>
      <StyledCollapseBar
        // @ts-ignore
        filterCollapsed={props.filterCollapsed}
        onClick={() => props.toggleFilterCollapse()}
        darkMode={isDarkMode}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </StyledCollapseBar>
      <ImportSettingsModal
        history={props.history}
        isOpen={importModal.importModalOpen}
        //currentSettingsId={selectedSettings?.object_id ?? null}
        onClose={() => {
          setImportModal({ importModalOpen: false, importType: "" });
        }}
        //versionedSettings={versionedSettings}
        importType={importModal.importType}
        returnPath={window.location.pathname}
        gameEditionsReactSelect={gameEditionsReactSelect}
      />
      <DuplicateVersionedSettings
        isOpen={duplicateModalOpen}
        onClose={() => dispatch(setDuplicateSettingsModal(false))}
      />
      <ExportSettingsModal
        isOpen={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
        settings={props.settings}
      />
      <InjectConversionTableModal
        isOpen={conversionModalOpen}
        onClose={() => setConversionModalOpen(false)}
      />
      <InjectAdSettingsModal
        isOpen={adSettingInjectionModalOpen}
        onClose={() => setAdSettingInjectionModalOpen(false)}
      />
      <CutoffSettingsModal
        isOpen={cutoffSettingsModalOpen}
        onClose={() => setCutoffSettingsModalOpen(false)}
      />
      <AddNewVersionedSettings
        isOpen={addNewSettingsModal}
        onClose={() => setAddNewSettingsModal(false)}
      />
      <DeleteVersionedSettings
        isOpen={deleteSettingsModal}
        onClose={() => setDeleteSettingsModal(false)}
      />
    </StyledFilterWrapper>
  );
};

const mapStateToProps = (state: any) => ({
  filterCollapsed: filterCollapsedSelector(state),
  selectedSettingsCollapse: selectedSettingsCollapseSelector(
    state.filterReducer
  ),
  countries: countriesSelector(state),
  settings: settingsSelector(state),
  selectedSettingsIds: selectedSettingsSelector(state),
  disabled: isDisabledSelector(state.addToAllReducer),
});

const mapDispatchToProps = {
  toggleDarkMode,
  toggleFilterCollapse,
  toggleSelectedSettingsCollapse,
  updateDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Filter));
