import { createSlice, current } from "@reduxjs/toolkit";

import odiff, { odiffResult } from "odiff";
import { RootState } from "../../app/store";
import { IGameEdition } from "../../model/game-edition.model";
import {
  NestedSetting,
  ReactSelectObject,
  VersionedSettings,
  VersionedSettingss,
} from "../../model/versioned-settings.model";
import { getGameID } from "../../utils/game-admin.util";
import { sanitiseSetting } from "../../utils/sanitiseSettings";

import { searchSettings } from "../../utils/searchSettings";
import { searchTermSelector } from "./components/Filter/filterSlice";
import { addParentMatches } from "./helpers/searchSettings";
import {
  combineSettings,
  fetchConfig,
  fetchGameEditions,
  fetchSettings,
  saveSelectedSettings,
} from "./addToAllThunks";

interface AddToAllState {
  config?: { [id: string]: any };
  settings?: any[];
  error?: string;
  loadingSettings: boolean;
  combinedSettings?: NestedSetting;
  combiningSettings?: boolean;
  currentDiffNumber?: number;
  changes?: odiffResult[];
  savingSettings?: boolean;
  keepImportChanges?: boolean;
  idsToOverrideFilters?: string[];
  gameEditionsRaw?: IGameEdition[];
  requestStatus?: string;
  currentSaveNumber?: number;
  duplicateSettingsModalOpen?: boolean;
  expandedSettings: { [id: string]: any };
  beforeSearchExpandedSettings?: { [id: string]: any };
  unsavedSettings?: VersionedSettingss;
  afterCombineYScrollPosition?: number;
  numberOfSettingsToSave?: number;
  currentDeleteNumber: number;
  deletingSettings?: boolean;
  numberOfSettingsToDelete?: number;
}

const initialState: AddToAllState = {
  loadingSettings: true,
  expandedSettings: {},
  currentDeleteNumber: -1,
};

const addToAllSlice = createSlice({
  name: "addToAll",
  initialState,
  reducers: {
    updateCurrentDiffNumber: (state, action) => {
      state.currentDiffNumber = action.payload;
    },
    updateCurrentSaveNumber: (state, action) => {
      state.currentSaveNumber = action.payload;
    },
    updateExpandedSettings: (state, action) => {
      state.expandedSettings = action.payload;
    },
    updateBeforeSearchExpandedSettings: (state, action) => {
      state.beforeSearchExpandedSettings = action.payload;
    },
    incrementCurrentSaveNumber: (state) => {
      if (!state.currentSaveNumber) {
        state.currentSaveNumber = 1;
      } else {
        state.currentSaveNumber++;
      }
    },
    setNumberOfSettingsToSave: (state, action) => {
      state.numberOfSettingsToSave = action.payload;
    },
    resetCurrentSaveNumber: (state) => {
      state.currentSaveNumber = 0;
    },
    clearChanges: (state) => {
      state.changes = undefined;
    },
    setAfterCombineYScrollPosition: (state, action) => {
      state.afterCombineYScrollPosition = action.payload;
    },
    updateCombinedSettings: (state, action) => {
      // Diff current combined settings and new one
      const diffs = odiff(
        sanitiseSetting(current(state.combinedSettings!)),
        sanitiseSetting(action.payload)
      );
      // Update changes
      state.changes = state.changes ? state.changes.concat(diffs) : diffs;
      // Update combined settings
      state.combinedSettings = action.payload;
    },
    importCombinedSettingsAndChanges: (state, action) => {
      state.combinedSettings = action.payload.combinedSettings;
      state.changes = action.payload.changes;
    },
    removeChangeByIndex: (state, action) => {
      const indexToRemove = action.payload;

      state.changes?.splice(indexToRemove, 1);
    },
    updateSettings: (state, action) => {
      // Update by matching ids
      const updatedSelectedSettings = [...action.payload];
      state.settings = state.settings?.map(
        (setting) =>
          updatedSelectedSettings.find(
            ({ object_id }) => object_id === setting.object_id
          ) || setting
      );
    },
    setUnsavedSettings: (state, action) => {
      state.unsavedSettings = action.payload;
    },
    addToUnsavedSettings: (state, action) => {
      if (state.unsavedSettings) {
        state.unsavedSettings.push(action.payload);
      } else {
        state.unsavedSettings = [];
      }
    },
    deleteSetting: (state, action) => {
      if (state.settings) {
        const index = state.settings.findIndex(
          (setting) => setting.object_id === action.payload
        );

        state.settings.splice(index, 1);
      }
    },
    addSetting: (state, action) => {
      if (!state.settings) {
        state.settings = [action.payload];
      } else {
        state.settings.push(action.payload);
      }
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
      state.loadingSettings = false;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setGameEditions: (state, action) => {
      state.gameEditionsRaw = action.payload.map(
        (edition: { value: any; label: any }) => ({
          name: edition.value,
          display_name: edition.label,
        })
      );
    },
    setIdsToOverrideFilters: (state, action) => {
      state.idsToOverrideFilters = action.payload;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setDuplicateSettingsModal: (state, action) => {
      state.duplicateSettingsModalOpen = action.payload;
    },
    setCurrentDeleteNumber: (state, action) => {
      state.currentDeleteNumber = action.payload;
    },
    incrementDeleteNumber: (state) => {
      state.currentDeleteNumber++;
    },
    setDeletingSettings: (state, action) => {
      state.deletingSettings = action.payload;
    },
    setNumberOfSettingsToDelete: (state, action) => {
      state.numberOfSettingsToDelete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      //@ts-ignore
      state.config = action.payload.config;
    });
    builder.addCase(fetchConfig.rejected, (state, action) => {
      if (action.payload) {
        //@ts-ignore
        state.error = action.payload.errorMessage;
      } else {
        //@ts-ignore
        state.error = action.error;
      }
    });
    builder.addCase(fetchGameEditions.fulfilled, (state, action) => {
      //@ts-ignore
      state.gameEditionsRaw = action.payload.game_editions;
    });
    builder.addCase(fetchGameEditions.rejected, (state, action) => {
      if (action.payload) {
        //@ts-ignore
        state.error = action.payload.errorMessage;
      } else {
        //@ts-ignore
        state.error = action.error;
      }
    });
    builder.addCase(fetchSettings.pending, (state, action) => {
      state.loadingSettings = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.settings = action.payload;
      state.loadingSettings = false;

      state.error = undefined;
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      if (action.payload) {
        //@ts-ignore
        state.error = action.payload.errorMessage;
      } else {
        //@ts-ignore
        state.error = action.error;
      }
      state.loadingSettings = false;
    });
    builder.addCase(combineSettings.pending, (state, action) => {
      state.combiningSettings = true;
      state.combinedSettings = undefined;
    });
    builder.addCase(combineSettings.fulfilled, (state, action) => {
      state.combinedSettings = action.payload;
      state.combiningSettings = false;
    });
    builder.addCase(combineSettings.rejected, (state, action) => {
      state.combiningSettings = false;
    });
    builder.addCase(saveSelectedSettings.pending, (state) => {
      state.savingSettings = true;
    });
    builder.addCase(saveSelectedSettings.fulfilled, (state) => {
      state.savingSettings = false;
    });
    builder.addCase(saveSelectedSettings.rejected, (state, action) => {
      state.savingSettings = false;
    });
  },
});

export const countriesSelector = (state: any) =>
  state.addToAllReducer.config?.settings?.countries;
export const settingsSelector = (state: any) => state.addToAllReducer.settings;

export const selectedIdsToOverrideFilters = (state: any) =>
  state.addToAllReducer.idsToOverrideFilters;

export const selectedSettingsSelector = (state: any) => {
  if (
    state.addToAllReducer.idsToOverrideFilters &&
    state.addToAllReducer.idsToOverrideFilters?.length !== 0
  ) {
    // Return ids
    const gameId = getGameID();

    const clonedIds = [...state.addToAllReducer.idsToOverrideFilters];

    if (gameId) {
      clonedIds?.forEach(
        (id: string, index: number) =>
          (clonedIds[index] = id.replace("[game_id]", gameId))
      );
    }

    return clonedIds;
  }

  const countries = state.filterReducer.dropdowns?.countries?.map(
    (countryObject: ReactSelectObject) => countryObject.value
  );

  const platforms = state.filterReducer.dropdowns?.platforms?.map(
    (platformObject: ReactSelectObject) => platformObject.value
  );

  const versions = state.filterReducer.dropdowns?.versions?.map(
    (versionObject: ReactSelectObject) => versionObject.value
  );

  const selectedSettings = state.addToAllReducer.settings?.filter(
    (setting: VersionedSettings) =>
      countries?.includes(setting.country) &&
      platforms?.includes(setting.game_edition_id) &&
      versions?.includes(setting.max_version)
  );

  return selectedSettings?.map(
    (setting: VersionedSettings) => setting.object_id
  );
};

export const selectedSettingsObjectsSelector = (state: any) => {
  const settingIds = selectedSettingsSelector(state);

  return state.addToAllReducer.settings?.filter((setting: VersionedSettings) =>
    settingIds.includes(setting.object_id)
  );
};

export const settingsSearchObjectSelector = (state: any) => {
  const combinedSettings = combinedSettingsSelector(state);
  const searchTerm = searchTermSelector(state);

  if (!searchTerm || !combinedSettings) {
    return undefined;
  }

  const searchObject = searchSettings(combinedSettings, searchTerm);
  if (searchObject) {
    addParentMatches(searchObject.__value__);
  }

  return searchObject;
};

export const selectVersionedSettings = (state: any) =>
  state.addToAllReducer.settings;

export const selectDuplicateSettingsModalOpen = (state: any) =>
  state.addToAllReducer.duplicateSettingsModalOpen;

export const loadingSettingsSelector = (state: any) =>
  state.addToAllReducer.loadingSettings;

export const combinedSettingsSelector = (state: any) =>
  state.addToAllReducer.combinedSettings;

export const combiningSettingsSelector = (state: any) =>
  state.addToAllReducer.combiningSettings;

export const numberOfSettingsToSaveSelector = (state: any) =>
  state.addToAllReducer.numberOfSettingsToSave;

export const currentDiffNumberSelector = (state: any) =>
  state.addToAllReducer.currentDiffNumber;

export const currentSaveNumberSelector = (state: any) =>
  state.addToAllReducer.currentSaveNumber;

export const errorSelector = (state: AddToAllState) => state.error;

export const isDisabledSelector = (state: AddToAllState) =>
  state.loadingSettings || !!state.error;

export const configSelector = (state: any) => state.addToAllReducer.config;

export const changesSelector = (state: any) => state.addToAllReducer.changes;

export const selectExpandedSettings = (state: any) =>
  state.addToAllReducer.expandedSettings;

export const selectBeforeSearchExpandedSettings = (state: any) =>
  state.addToAllReducer.beforeSearchExpandedSettings;

export const savingSettingsSelector = (state: any) =>
  state.addToAllReducer.savingSettings;

export const requestStatusSelector = (state: any) =>
  state.addToAllReducer.requestStatus;

export const gameEditionsSelector = (state: any) =>
  state.addToAllReducer.gameEditionsRaw;

export const selectAfterCombineYScrollPosition = (state: any) =>
  state.addToAllReducer.afterCombineYScrollPosition;

export const selectUnsavedSettings = (state: any) =>
  state.addToAllReducer.unsavedSettings;

export const selectCurrentDeleteNumber = (state: any) =>
  state.addToAllReducer.currentDeleteNumber;

export const selectChanges = (state: RootState) =>
  state.addToAllReducer.changes;

export const deletingSettingsSelector = (state: any) =>
  state.addToAllReducer.deletingSettings;

export const numberOfSettingsToDeleteSelector = (state: any) =>
  state.addToAllReducer.numberOfSettingsToDelete;

export const {
  setSettings,
  setGameEditions,
  setConfig,
  updateCurrentDiffNumber,
  updateCurrentSaveNumber,
  incrementCurrentSaveNumber,
  resetCurrentSaveNumber,
  updateCombinedSettings,
  clearChanges,
  updateSettings,
  addSetting,
  importCombinedSettingsAndChanges,
  setIdsToOverrideFilters,
  setRequestStatus,
  setDuplicateSettingsModal,
  deleteSetting,
  updateExpandedSettings,
  setUnsavedSettings,
  setAfterCombineYScrollPosition,
  setNumberOfSettingsToSave,
  updateBeforeSearchExpandedSettings,
  setCurrentDeleteNumber,
  incrementDeleteNumber,
  addToUnsavedSettings,
  removeChangeByIndex,
  setDeletingSettings,
  setNumberOfSettingsToDelete,
} = addToAllSlice.actions;

export default addToAllSlice.reducer;
