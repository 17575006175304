import { capitalize } from "@mui/material";
import {
  ReactSelectArray,
  ReactSelectObject,
  VersionedSettings,
  VersionedSettingss,
} from "../model/versioned-settings.model";

export const getVersions = (versionedSettings: VersionedSettingss) => {
  const versions = versionedSettings.map((setting: VersionedSettings) => {
    return {
      value: setting.max_version,
      label: capitalize(setting.max_version),
    };
  });

  // Remove repeated values
  return versions.reduce(
    (unique: ReactSelectArray, item: ReactSelectObject) =>
      unique.some((el) => el.value === item.value) ? unique : [...unique, item],
    []
  );
};
