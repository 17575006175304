import { useConfig } from "../../../network/useConfig";
import { useGameConfig } from "../../../network/useGameConfig";
import { defaultLevelTypes } from "../defaultLevelFields";

export const useLevelTypes = () => {
  const { gameConfig } = useGameConfig();
  const { config } = useConfig();

  if (gameConfig?.hasOwnProperty("level_types")) {
    return gameConfig.level_types;
  } else if (config?.hasOwnProperty("default_level_types")) {
    return config.default_level_types;
  } else {
    return defaultLevelTypes;
  }
};
