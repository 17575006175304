import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { parse } from "csv-parse/lib/sync";
import React, { useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { LocalisationDataRow } from "./types";

const parseCSV = (text: string) => {
  const records = parse(text, {
    columns: false,
    skip_empty_lines: false,
  });

  const processed: Record<string, LocalisationDataRow> = {};

  let languages: Record<number, string> = {};
  for (let i = 0; i < records.length; i++) {
    const row = records[i];

    for (let j = 1; j < row.length; j++) {
      const column = row[j];

      if (i === 0) {
        // Language row
        if (column === "") {
          // Empty column
          continue;
        }

        // Initialise language
        languages[j] = column;
        continue;
      }

      // Ignored columns
      if (languages[j] === undefined) {
        continue;
      }

      // Locale row
      const key = row[0];
      if (key === "") {
        // Empty key
        break;
      }

      let processedRow = processed[key];
      if (processedRow === undefined) {
        processedRow = {
          id: key,
        };
        processed[key] = processedRow;
      }

      // Get language
      const language = languages[j];
      if (language === undefined) {
        continue;
      }

      // Set value
      processedRow[language] = column;
    }
  }

  return Object.values(processed);
};

const useStyles = makeStyles((theme: Theme) => ({
  stickyColumn: {
    position: "sticky",
    left: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    width: 200,
    wordWrap: "break-word",
  },
  column: {
    zIndex: 0,
    width: 200,
    wordWrap: "break-word",
  },
}));

type Props = {
  open: boolean;
  onClose: (data?: LocalisationDataRow[]) => void;
};

export default function LocalisationImportModal({
  open,
  onClose: _onClose,
}: Props) {
  const dispatch = useAppDispatch();

  const classes = useStyles();

  const [data, setData] = useState<LocalisationDataRow[]>();

  const languages = useMemo(() => {
    if (!data) {
      return [];
    }

    const languages: string[] = [];
    const first = data[0];
    for (const key in first) {
      if (key === "id") {
        continue;
      }

      languages.push(key);
    }

    return languages;
  }, [data]);

  const processFile = (file: File) => {
    if (file.type !== "text/csv") {
      dispatch(
        queueNotification({
          message: "File must be a CSV!",
          options: {
            key: "localisation_import_invalid_type",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      return;
    }

    const reader = new FileReader();
    reader.onload = (event: any) => {
      const text = event.target.result;
      try {
        setData(parseCSV(text));
      } catch (e) {
        console.error(e);

        dispatch(
          queueNotification({
            message: "Failed to read CSV!",
            options: {
              key: "localisation_import_invalid_content",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      }
    };
    reader.readAsText(file);
  };

  const onFileSelect = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      processFile(file);
    };
    input.click();
  };

  const onImport = useCallback(() => {
    _onClose(data);
  }, [_onClose, data]);

  const onClose = useCallback(() => _onClose(), [_onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Import CSV</DialogTitle>
      <DialogContent>
        <Button variant="contained" onClick={onFileSelect} sx={{ mb: 2 }}>
          Select File
        </Button>

        {data && (
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.stickyColumn}
                    style={{ zIndex: 2 }}
                  >
                    Key
                  </TableCell>
                  {languages.map((language) => (
                    <TableCell
                      key={language}
                      className={classes.column}
                      style={{ zIndex: 1 }}
                    >
                      {language}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      className={classes.stickyColumn}
                      component="th"
                      scope="row"
                    >
                      {row.id}
                    </TableCell>
                    {languages.map((language) => (
                      <TableCell key={language} className={classes.column}>
                        {row[language]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onImport}>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}
