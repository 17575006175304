import {
  faClipboard,
  faDownload,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogContent, DialogTitle, Button, TextField } from "@mui/material";
import React, { Component } from "react";
import { store } from "../../../app/store";
import { DialogWrapper } from "../../../components/DialogWrapper";
import { Flex } from "../../../components/Flex";
import { getEnvironmentName } from "../../../data/AxiosConfig";
import {
  NestedSettings,
  ValueType,
  VersionedSettings,
  VersionedSettingss,
} from "../../../model/versioned-settings.model";
import { getGameID } from "../../../utils/game-admin.util";
import { getSettingsSources } from "../../../utils/getSettingsSources";
import sanitiseSettings from "../../../utils/sanitiseSettings";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";
import { generateAndDownloadCSV } from "./Setting/DownloadCSVButton";
import { SettingsSource, SourcePicker } from "./SourcePicker";

interface State {
  filename?: string;
  filenameChanged?: boolean;
  selectedSourceSettingsId?: string;
}

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  currentSettings?: VersionedSettings;
  settings?: VersionedSettingss;
}

export class ExportSettingsModal extends Component<Props, State> {
  state: State = {};
  componentDidMount(): void {
    this.resetFilename();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    // If current settings changes reset file name
    if (
      prevState.selectedSourceSettingsId !== this.state.selectedSourceSettingsId
    ) {
      this.resetFilename();
    }
  }

  private textareaRef = React.createRef<HTMLTextAreaElement>();

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  downloadSettingsAsJson() {
    const sources = getSettingsSources(this.props.settings);
    const settingsToDupe = sources.find(
      (source) => source.id === this.state.selectedSourceSettingsId
    )?.nested_settings;

    if (!settingsToDupe) {
      return;
    }

    if (!this.state.filename) {
      store.dispatch(
        queueNotification({
          message: "File name is required",
          options: {
            key: "no_file_name",
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      return;
    }

    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(settingsToDupe));

    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", this.state.filename + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  copyToClipboard() {
    const sources = getSettingsSources(this.props.settings);
    const settingsToDupe = sources.find(
      (source) => source.id === this.state.selectedSourceSettingsId
    )?.nested_settings;

    if (!settingsToDupe) {
      return;
    }

    const textArea = this.textareaRef.current;
    if (!textArea) {
      return;
    }

    textArea.focus();
    textArea.select();
    document.execCommand("copy");

    store.dispatch(
      queueNotification({
        message: "Copied to clipboard",
        options: {
          key: "copied_to_clipboard",
          variant: SnackbarVariant.SUCCESS,
        },
      })
    );
  }

  resetFilename() {
    const sources = getSettingsSources(this.props.settings);
    const settingsToDupe = sources.find(
      (source) => source.id === this.state.selectedSourceSettingsId
    )?.nested_settings;

    if (!settingsToDupe) {
      return;
    }

    this.setState({
      filename: `Combined_Settings_${getEnvironmentName()}_${new Date().toISOString()}`,
      filenameChanged: false,
    });
  }

  render() {
    const sources = getSettingsSources(this.props.settings);
    const setting = sources.find(
      (source) => source.id === this.state.selectedSourceSettingsId
    );

    let settingsToDupe: NestedSettings | undefined;

    if (setting) {
      settingsToDupe =
        sanitiseSettings<SettingsSource>(setting)?.nested_settings;
    }

    return (
      <DialogWrapper
        isOpen={!!this.props.isOpen}
        onClose={this.handleClose}
        size="sm"
      >
        <DialogTitle>Export Settings</DialogTitle>

        <DialogContent>
          <SourcePicker
            selected={this.state.selectedSourceSettingsId}
            onChange={(sourceId?: string) =>
              this.setState({ selectedSourceSettingsId: sourceId })
            }
          />
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              style={{ marginBottom: "0.5rem" }}
              startIcon={<FontAwesomeIcon icon={faClipboard} />}
              disabled={!settingsToDupe}
              onClick={(event) =>
                settingsToDupe &&
                generateAndDownloadCSV(
                  event,
                  `${getGameID()}_Settings_${getEnvironmentName()}_${new Date().toISOString()}`,
                  {
                    __type__: ValueType.OBJECT,
                    __value__: settingsToDupe,
                  }
                )
              }
            >
              Download CSV
            </Button>
            <Button
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faClipboard} />}
              onClick={() => this.copyToClipboard()}
            >
              Copy to clipboard
            </Button>
            <textarea
              ref={this.textareaRef}
              className="form-control export-textbox"
              value={JSON.stringify(settingsToDupe)}
              onChange={() => {}}
            />
          </Flex>
          <hr />
          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <small className="text-muted mb-3">or</small>
            <Flex
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <TextField
                variant="outlined"
                style={{ maxWidth: "400px" }}
                value={this.state.filename}
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({
                    filename: event.target.value,
                    filenameChanged: true,
                  })
                }
                placeholder="Enter file name..."
              />
              <span className="mx-2">.json</span>
              <Button
                variant="contained"
                onClick={() => this.downloadSettingsAsJson()}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              {this.state.filenameChanged && (
                <Button className="ml-2" onClick={() => this.resetFilename()}>
                  <FontAwesomeIcon icon={faUndo} title="Reset file name" />
                </Button>
              )}
            </Flex>
          </Flex>
        </DialogContent>
      </DialogWrapper>
    );
  }
}
