export function searchSettings<T>(
  obj: T,
  term?: string,
  parentMatch?: boolean
): any {
  if (!term) {
    return obj;
  }

  let newObj: any;

  // Check if match search term
  if (typeof obj === "object" && obj !== null) {
    newObj = {};
    if (Array.isArray(obj)) {
      newObj = [...obj];
      newObj = obj.map((el: any) => searchSettings(el, term));
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        let match = parentMatch ?? false;
        newObj[key] = value;
        // @ts-ignore
        if (value?.hasOwnProperty("__value__")) {
          newObj[key] = { ...value };
          if (parentMatch) {
            newObj[key]["__child_match__"] = true;
          }
          if (key.toLowerCase().includes(term.toLowerCase())) {
            newObj[key]["__match__"] = true;
            // Match must be inherited by all children of this value
            match = true;
          } else {
            newObj[key]["__match__"] = false;
          }
        }

        return [key, searchSettings(newObj[key], term, match)];
      })
    );
  } else {
    newObj = obj;
  }

  return newObj;
}

export function addParentMatches<T>(obj: T): any {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      //@ts-ignore
      obj = obj.map((el) => addParentMatches(el));
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        // @ts-ignore
        if (value?.hasOwnProperty("__value__")) {
          if (hasChildMatch(value.__value__) || value.__parent_match__) {
            value["__parent_match__"] = true;
          } else {
            value["__parent_match__"] = false;
          }
        }

        return [key, addParentMatches(value)];
      })
    );
  }

  return obj;
}

export function hasChildMatch<T>(obj?: T): boolean {
  let matchArray: boolean[] | undefined = [];
  if (typeof obj === "object") {
    if (Array.isArray(obj)) {
      matchArray = obj?.map((el: any) => hasChildMatch(el));
    }

    Object.entries(obj ?? {}).forEach(([key, value]: any) => {
      // @ts-ignore
      if (value.hasOwnProperty("__value__")) {
        if (value.__match__) {
          matchArray?.push(true);
        } else {
          matchArray?.push(hasChildMatch(value.__value__));
        }
      }
    });
  }

  return !!matchArray?.includes(true);
}
