import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { AD_TYPE, AdType } from "./AdType";

export const MAX_FILE_SIZE_MB = 2.5;

export default function IECGenerator() {
  const [adType, setAdType] = useState(Object.keys(AD_TYPE)[0] as AdType);

  const Component = useMemo(() => AD_TYPE[adType].component, [adType]);

  return (
    <Box>
      <Autocomplete
        sx={{ mb: 2 }}
        renderInput={(params) => (
          <TextField {...params} hiddenLabel variant="outlined" />
        )}
        options={Object.keys(AD_TYPE) as AdType[]}
        getOptionLabel={(option) => AD_TYPE[option].name}
        value={adType}
        onChange={(event, value) => setAdType(value!)}
      />

      <Component />
    </Box>
  );
}
