import { NavigationItem } from "../model/navigation.model";

export const mergeNavRoutes = (
  routes: NavigationItem[],
  serverRoutes?: NavigationItem[],
  currentGameName?: string
): NavigationItem[] => {
  let overriddenServerRoutes: NavigationItem[] | undefined = [];

  overriddenServerRoutes = serverRoutes?.map((serverRoute) => {
    const overrideRoute = routes?.find(
      (route) => route.name === serverRoute.name
    );

    if (overrideRoute) {
      if (overrideRoute.elements) {
        return {
          ...overrideRoute,
          elements: mergeNavRoutes(
            overrideRoute.elements,
            serverRoute.elements,
            currentGameName
          ),
        };
      }
      return overrideRoute;
    }

    return serverRoute;
  });

  overriddenServerRoutes = overriddenServerRoutes?.map((route) =>
    route.name === currentGameName
      ? { ...route, name: "Levels", id: "Levels" }
      : route
  );

  const newRoutes = routes.filter(
    (route) =>
      !overriddenServerRoutes
        ?.map((serverRoute) => serverRoute.name)
        .includes(route.name)
  );

  return (overriddenServerRoutes ?? [])
    .concat(newRoutes)
    .sort((a, b) => a.name.localeCompare(b.name));
};
