import _ from "lodash";
import moment from "moment";
import {
  DifferenceEntry,
  NestedSetting,
  serverTimeFormat,
} from "../model/versioned-settings.model";
import { checkIfCollection } from "./checkIfCollection";
import { DifferenceType } from "./DifferenceType";
import { getUser } from "./getUser";

export function updateModified(setting: NestedSetting, diff?: DifferenceEntry) {
  let newSetting = _.cloneDeep(setting);

  if (diff?.type.__value__ !== DifferenceType.UNCHANGED || !diff) {
    newSetting = {
      ...newSetting,
      __modified__: moment().format(serverTimeFormat),
      __user__: getUser(),
    };

    // If setting type is collection then recursively run
    if (checkIfCollection(newSetting)) {
      Object.keys(newSetting.__value__).forEach(
        (key) =>
          //@ts-ignore
          (newSetting.__value__[key] = updateModified(
            //@ts-ignore
            newSetting.__value__![key],
            //@ts-ignore
            diff?.children[key as keyof DifferenceEntry]
          ))
      );
    }
  }

  return newSetting;
}

export function updateModifiedByPath(
  setting: NestedSetting,
  path?: (string | number)[]
) {
  let newSetting = _.cloneDeep(setting);
  const newPath = [...(path ?? [])];

  newSetting = {
    ...newSetting,
    __modified__: moment().format(serverTimeFormat),
    __user__: getUser(),
  };

  // If setting type is collection then recursively run
  if (checkIfCollection(newSetting)) {
    newPath?.shift();

    Object.keys(newSetting.__value__).forEach((key) => {
      //@ts-ignore
      newSetting.__value__[key] =
        !path || (path && path[0] === key)
          ? updateModifiedByPath(
              //@ts-ignore
              newSetting.__value__![key],
              newPath
            )
          : //@ts-ignore
            newSetting.__value__![key];
    });
  }

  return newSetting;
}

export function updateParentDifferent(
  setting: NestedSetting,
  path?: (string | number)[]
) {
  let newSetting = _.cloneDeep(setting);
  const newPath = [...(path ?? [])];

  const isChildrenDifferent = !Object.values(newSetting.__value__ ?? {})
    .map((setting: any) => setting.different)
    .every((isDifferent) => !isDifferent);

  // Check if at current level all children are not different
  newSetting = {
    ...newSetting,
    // If not different set different to false
    different: isChildrenDifferent,
  };

  // If setting type is collection then recursively run
  if (checkIfCollection(newSetting)) {
    newPath?.shift();

    Object.keys(newSetting.__value__).forEach((key) => {
      //@ts-ignore
      return (newSetting.__value__[key] =
        !path || (path && path[0] === key)
          ? updateParentDifferent(
              //@ts-ignore
              newSetting.__value__![key],
              newPath
            )
          : //@ts-ignore
            newSetting.__value__![key]);
    });
  }

  return newSetting;
}
