import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ReactNode, useEffect, useState } from "react";

import { store } from "../../app/store";
import { AppStoreAccount } from "../../model/app-store.model";
import {
  IronsourceBridge,
  IronsourceBridges,
} from "../../model/game-app.model";
import { IGameEdition } from "../../model/game-edition.model";
import { getGameID } from "../../utils/game-admin.util";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  confirmColor?: string;
  confirmContent?: ReactNode;
  denyColor?: string;
  denyContent?: ReactNode;
  onClose?: () => void;
  onResolve?: (resolved: boolean) => void;
  addGameApp: (gameApp: any) => void;
  gameId?: string;
  ironsourceBridges?: IronsourceBridges;
  gameEditions?: IGameEdition[];
  appStoreAccounts?: AppStoreAccount[];
}

interface State {
  game_id?: string;
  game_edition_id?: string;
  bundle_id?: string;
  iron_source_bridge_id?: string;
  appstore_id: string;
}

const useStyles = makeStyles((theme: any) => {
  const isLightMode = theme.palette.mode === "light";
  return {
    main: {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      border: "2px solid #000",
      backgroundColor: isLightMode ? "#EDEDED" : "#1B2635",
      padding: theme.spacing(5),
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "row",
      paddingTop: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(1),
    },
    textFieldStyle: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  };
});

export const AddNewGameEditionModal = (props: Props) => {
  const [state, setState] = useState<State>({
    game_id: "",
    game_edition_id: "",
    iron_source_bridge_id: "",
    bundle_id: "",
    appstore_id: "",
  });

  useEffect(() => {
    const gameId = getGameID(props.gameId);

    if (gameId) setState({ ...state, game_id: gameId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.game_id]);

  const styles = useStyles();

  const handleCancel = () => {
    const { onResolve } = props;

    if (onResolve) {
      onResolve(false);
    }
  };

  const handleConfirm = () => {
    const { addGameApp, onResolve } = props;

    if (!state.game_id || !state.game_edition_id) {
      store.dispatch(
        queueNotification({
          message: "Please fill out all required fields",
          options: {
            key: "empty_fields",
            variant: SnackbarVariant.ERROR,
          },
        })
      );
    } else {
      addGameApp(state);

      if (onResolve) {
        onResolve(true);
      }
    }
  };

  const handleClose = () => {
    const { onClose } = props;

    if (onClose) {
      onClose();
    }
  };

  const handleChange = (stateName: string, e: any) => {
    e.stopPropagation();

    setState({ ...state, [stateName]: e.target.value });
  };

  const filterAppStoreAccounts = (stringValue?: string) => {
    const filteredAccounts = (props.appStoreAccounts || []).filter(
      (obj) => obj.game_edition_id === stringValue
    );

    return filteredAccounts;
  };

  return (
    <Modal
      open={props.isOpen || false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.main}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {props.title}
        </Typography>

        <Box id="modal-modal-description" sx={{ mt: 2 }}>
          <Alert severity="warning">
            <strong>
              Warning: You must create a Game Settings for this game app after
              creation
            </strong>
          </Alert>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <Typography component="div" className={styles.textFieldStyle}>
                Game ID*
              </Typography>
              <TextField
                disabled
                variant="outlined"
                size="small"
                placeholder="Enter Game ID..."
                value={state.game_id ? state.game_id : ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("game_id", event)
                }
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <Typography component="div" className={styles.textFieldStyle}>
                Game Edition ID*
              </Typography>
              <Select
                placeholder="Select Game Edition ID..."
                value={state.game_edition_id ? state.game_edition_id : ""}
                variant="outlined"
                onChange={(e) => handleChange("game_edition_id", e)}
              >
                <MenuItem value="">None</MenuItem>
                {props.gameEditions &&
                  props.gameEditions.map((edition: IGameEdition) => {
                    return (
                      <MenuItem
                        key={edition.object_id}
                        value={edition.object_id}
                      >
                        {`${edition.display_name}`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <Typography component="div" className={styles.textFieldStyle}>
                App Store ID
              </Typography>
              <Select
                variant="outlined"
                label="Appstore ID"
                disabled={!props.appStoreAccounts}
                placeholder="Select Appstore ID"
                value={state.appstore_id ? state.appstore_id : ""}
                onChange={(e) => handleChange("appstore_id", e)}
              >
                <MenuItem value="">None</MenuItem>
                {(filterAppStoreAccounts(state.game_edition_id) ?? [])?.map(
                  (item: AppStoreAccount) => {
                    return (
                      <MenuItem key={item?.object_id} value={item?.object_id}>
                        {item?.name.toUpperCase()}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <Typography component="div" className={styles.textFieldStyle}>
                Bundle ID
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Enter Bundle ID..."
                value={state.bundle_id ? state.bundle_id : ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange("bundle_id", event)
                }
              />
            </FormControl>
            <FormControl fullWidth size="small">
              <Typography component="div" className={styles.textFieldStyle}>
                Ironsource Bridge ID
              </Typography>
              <Select
                disabled={!props.ironsourceBridges}
                placeholder="Enter Ironsource Bundle ID"
                variant="outlined"
                value={
                  state.iron_source_bridge_id ? state.iron_source_bridge_id : ""
                }
                onChange={(e) => handleChange("iron_source_bridge_id", e)}
              >
                <MenuItem value="">None</MenuItem>
                {props.ironsourceBridges &&
                  props.ironsourceBridges.map((bridge: IronsourceBridge) => {
                    return (
                      <MenuItem key={bridge.object_id} value={bridge.object_id}>
                        {`${bridge.game_edition_id.toUpperCase()} | ${
                          bridge.app_user
                        }`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className={styles.buttonGroup}>
          <Button
            color="primary"
            onClick={handleConfirm}
            variant="outlined"
            className={styles.button}
          >
            {props.confirmContent || "Yes"}
          </Button>
          <Button color="secondary" onClick={handleCancel} variant="outlined">
            {props.denyContent || "No"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
