import React from "react";

export default function Homepage() {
  return (
    <div>
      <p>
        If you visit a page and get logged out, you've probably not got
        permission to view that page...
      </p>
    </div>
  );
}
