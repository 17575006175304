import { Alert, alpha, Box, CircularProgress, darken } from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import styled from "styled-components";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Flex } from "../../../../components/Flex";
import {
  serverTimeFormat,
  TooltipState,
} from "../../../../model/versioned-settings.model";
import { useConfig } from "../../../../network/useConfig";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import { selectDarkMode } from "../../../general/generalSlice";
import { selectSession } from "../../../session/sessionSlice";
import {
  combinedSettingsSelector,
  combiningSettingsSelector,
  countriesSelector,
  importCombinedSettingsAndChanges,
  selectAfterCombineYScrollPosition,
  selectedSettingsObjectsSelector,
  setAfterCombineYScrollPosition,
  settingsSearchObjectSelector,
  updateCombinedSettings,
} from "../../addToAllSlice";
import { combineSettings } from "../../addToAllThunks";
import DifferencesModal from "../DifferencesModal/DifferencesModal";
import {
  editModeSelector,
  searchTermSelector,
  selectHideDefaultSettings,
  selectHideModified,
  selectHideTooltips,
  selectTooltip,
  setEditMode,
  setTooltip,
  toggleEditMode,
} from "../Filter/filterSlice";
import SettingWrapper from "./Setting";

export const StyledExpandableSetting = styled.div<{ darkMode: boolean }>`
  width: 100%;

  .game-setting,
  .setting-card {
    &.disabled {
      .left-part-container {
        opacity: 0.6;
      }
      .badge-column {
        opacity: 0.6;
      }
      background-color: ${(props) =>
        alpha(props.theme.setting.backgroundColor, 0.6)};

      &:hover {
        background-color: ${(props) =>
          alpha(darken(props.theme.setting.backgroundColor, 0.1), 0.6)};
      }
    }
    &.testing {
      color: black;
      background-color: ${(props) =>
        props.theme.setting.testingBackgroundColor};

      &:hover {
        background-color: ${(props) =>
          darken(props.theme.setting.testingBackgroundColor, 0.2)};
      }
    }

    background-color: ${(props) => props.theme.setting.backgroundColor};
    border-color: transparent;

    box-shadow: 0 0.5px 0 0 ${(props) => props.theme.setting.boxShadow} inset,
      0 1px 2px 0 #b3b3b3;

    &:hover,
    &.hover-from-parent {
      background-color: ${(props) =>
        darken(props.theme.setting.backgroundColor, 0.1)};
    }

    &.modified {
      background-color: ${(props) => (props.darkMode ? "#046475" : "#c9f2f8")};

      &.hover-from-parent,
      &:hover {
        background-color: ${(props) =>
          darken(props.darkMode ? "#046475" : "#c9f2f8", 0.1)};
      }
    }

    &.deleted {
      background-color: ${(props) => (props.darkMode ? "#731921" : "#f8d7da")};

      &.hover-from-parent,
      &:hover {
        background-color: ${(props) =>
          darken(props.darkMode ? "#731921" : "#f8d7da", 0.1)};
      }
    }

    &.addition {
      background-color: ${(props) => (props.darkMode ? "#3d7504" : "#dbf8c9")};

      &.hover-from-parent,
      &:hover {
        background-color: ${(props) =>
          darken(props.darkMode ? "#3d7504" : "#dbf8c9", 0.1)};
      }
    }

    .different-badge {
      span {
        transition: background-color 0.1s ease-in-out;
      }
    }

    .heading-container {
      .heading {
        .highlight {
          color: black !important;
        }
      }
    }

    .caret,
    .heading,
    .tag {
      color: ${(props) => props.theme.setting.textColor} !important;

      &:hover,
      &:focus {
        color: #000 !important;
      }
    }

    &:hover {
      background-color: ${(props) => props.theme.setting.hoverColor};
    }

    @keyframes new-setting-fade {
      0% {
        background-color: ${(props) => props.darkMode && "burlywood"};
      }

      100% {
        background-color: ${(props) => props.theme.setting.backgroundColor};
      }
    }
  }
  .expandable-setting.expandable-setting-object > .expandable-setting-value {
    background: linear-gradient(
      90deg,
      ${(props) => props.darkMode && "#4a336f"} 0%,
      #09050e 80%,
      rgba(237, 237, 237, 0) 100%
    );
  }

  .expandable-setting.expandable-setting-array > .expandable-setting-value {
    background: linear-gradient(
      90deg,
      ${(props) => props.darkMode && "#714221"} 0%,
      #251205 80%,
      rgba(237, 237, 237, 0) 100%
    );
  }
`;

const SettingsContainer = (props: any) => {
  const dispatch = useDispatch();
  const differencesTooltip = useSelector(selectTooltip);

  const editMode = useSelector(editModeSelector);
  const selectedSettings = useSelector(selectedSettingsObjectsSelector);
  const countries = useSelector(countriesSelector);
  const combinedSettings = useSelector(combinedSettingsSelector);
  const combiningSettings = useSelector(combiningSettingsSelector);
  const afterCombineYScrollPosition = useSelector(
    selectAfterCombineYScrollPosition
  );
  const searchTerm = useSelector(searchTermSelector);
  const settingsSearchObject = useSelector(settingsSearchObjectSelector);
  const hideModified = useSelector(selectHideModified);
  const hideTooltips = useSelector(selectHideTooltips);
  const hideDefaultSettings = useSelector(selectHideDefaultSettings);
  const { config } = useConfig();

  const docsSettings = config?.docs_settings;

  const { user_id: user } = useSelector(selectSession);

  useDeepCompareEffect(() => {
    const combine = async () => {
      const resultAction = await dispatch(combineSettings(selectedSettings));

      if (
        //@ts-ignore
        combineSettings.fulfilled.match(resultAction) &&
        !isNullOrUndefined(afterCombineYScrollPosition)
      ) {
        setTimeout(() => window.scrollTo(0, afterCombineYScrollPosition), 200);
        dispatch(setAfterCombineYScrollPosition(undefined));
      }
    };
    // Clear url params if coming from reload
    if (
      !sessionStorage.active ||
      sessionStorage.active === window.location.href
    ) {
      props.history.push({
        pathname: "/game-settings/game-settings",
        state: {},
      });
    }
    sessionStorage.active = window.location.href;

    if (props.location.state?.storedCombinedSettings) {
      dispatch(
        importCombinedSettingsAndChanges({
          combinedSettings: props.location.state?.storedCombinedSettings,
          changes: props.location.state?.storedChanges,
        })
      );
      dispatch(setEditMode(true));
    } else {
      if (!props.setting) {
        combine();
      }
    }
  }, [dispatch, selectedSettings]);

  const setDifferencesTooltip = (state?: TooltipState) => {
    if (props.inTooltip) {
      return;
    }

    dispatch(setTooltip(state));
  };

  const darkMode = useSelector(selectDarkMode);
  const isDarkMode = darkMode === "dark";

  if (combiningSettings) {
    return (
      <Flex flexDirection="column" p={2} alignItems="center">
        <CircularProgress />{" "}
        <Box mt={2}>
          <small className="mt-3 text-muted">Combining Settings</small>
        </Box>
      </Flex>
    );
  }

  if (!combinedSettings && !props.setting) {
    return <Alert severity="info">No Settings Selected</Alert>;
  }

  return (
    <StyledExpandableSetting
      id="versioned-settings-container"
      darkMode={isDarkMode}
    >
      <SettingWrapper
        inTooltip={!!props.setting}
        key={"top-level"}
        name={"top-level"}
        topLevel
        //@ts-ignore
        setting={props.setting ? props.setting : combinedSettings}
        setDifferencesTooltip={setDifferencesTooltip}
        differencesTooltip={differencesTooltip}
        setTooltip={() => {}}
        isVisible
        isEditing={editMode && !props.readOnly}
        updateSettings={(value: any) => {
          if (value !== undefined) {
            value = {
              ...value,
              __modified__: moment().format(serverTimeFormat),
              __user__: user,
            };
          }

          // @ts-ignore
          dispatch(updateCombinedSettings(value));
        }}
        isExpanded
        searchSettingsObject={settingsSearchObject}
        turnOnEditing={() => dispatch(toggleEditMode())}
        searchTerm={searchTerm}
        hideModifiedText={hideModified}
        hideTooltips={hideTooltips}
        hideDefaultSettings={hideDefaultSettings}
        readOnly={props.readOnly}
        abTestedSettings={props.abTestedSettings}
        docsSettings={docsSettings}
      />
      {!props.setting && !!differencesTooltip?.target && (
        <DifferencesModal
          tooltip={differencesTooltip}
          settings={selectedSettings}
          countries={countries}
        />
      )}
    </StyledExpandableSetting>
  );
};

export default withRouter(SettingsContainer);
