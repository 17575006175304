import {
  VersionedSettings,
  VersionedSettingss,
} from "../model/versioned-settings.model";

export const getSettingId = (
  settingToUpdate: Partial<VersionedSettings>,
  versionedSettings?: VersionedSettingss
): string | undefined => {
  const matchedSetting = versionedSettings?.find((setting) => {
    return (
      setting.country === settingToUpdate.country &&
      setting.max_version === settingToUpdate.max_version &&
      setting.game_edition_id === settingToUpdate.game_edition_id
    );
  });

  return matchedSetting?.object_id;
};
