import {
  DifferenceEntry,
  NestedSetting,
  ValueType,
} from "../model/versioned-settings.model";
import { DifferenceType } from "./DifferenceType";
import {
  addedType,
  deletedType,
  modifiedType,
  unchangedType,
} from "./SettingParts";
import { unique } from "./unique";

export function getDiff(a?: NestedSetting, b?: NestedSetting) {
  const diff: DifferenceEntry = {
    type: unchangedType,
    children: {},
  };
  if (!a) {
    // a doesn't have it
    if (!b) {
      // b doesn't have it either, it's unchanged technically
      diff.type = unchangedType;

      return diff;
    }
    // Must have been added
    diff.type = addedType;
  } else if (!b) {
    // b doesnt have it, must have been removed
    diff.type = deletedType;
  }
  // If same time, must be modified
  const sameType = a?.__type__ === b?.__type__;
  if (a && b && !sameType) {
    diff.type = modifiedType;
  }
  switch (a ? a.__type__ : b!.__type__) {
    case ValueType.ARRAY:
      // Compare arrays
      const aArr = (a as NestedSetting<ValueType.ARRAY>)?.__value__ ?? [];
      const bArr = (b as NestedSetting<ValueType.ARRAY>)?.__value__ ?? [];
      // Process arrays
      for (let i = 0; i < Math.max(aArr.length, bArr.length); i++) {
        const aObj = aArr[i];
        const bObj = bArr[i];
        const childDiff = getDiff(aObj, bObj);
        diff.children[i] = childDiff;
        if (sameType && childDiff.type.__value__ !== DifferenceType.UNCHANGED) {
          diff.type = { ...diff.type, __value__: DifferenceType.MODIFIED };
        }

        if (sameType && a!.__tag__?.toString() !== b!.__tag__?.toString()) {
          // Modified
          diff.type = { ...diff.type, __tag__: DifferenceType.MODIFIED };
        }

        if (
          sameType &&
          a!.__description__?.toString() !== b!.__description__?.toString()
        ) {
          // Modified
          diff.type = {
            ...diff.type,
            __description__: DifferenceType.MODIFIED,
          };
        }
      }
      break;
    case ValueType.OBJECT:
      // Compare objects
      const aObj = (a as NestedSetting<ValueType.OBJECT>)?.__value__ ?? {};
      const bObj = (b as NestedSetting<ValueType.OBJECT>)?.__value__ ?? {};
      // Process objects
      const aKeys = Object.keys(aObj);
      const bKeys = Object.keys(bObj);
      for (const key of [...aKeys, ...bKeys].filter(unique)) {
        const aVal = aObj[key];
        const bVal = bObj[key];
        const childDiff = getDiff(aVal, bVal);
        diff.children[key] = childDiff;
        if (sameType && childDiff.type.__value__ !== DifferenceType.UNCHANGED) {
          diff.type = { ...diff.type, __value__: DifferenceType.MODIFIED };
        }

        if (sameType && a!.__tag__?.toString() !== b!.__tag__?.toString()) {
          // Modified
          diff.type = { ...diff.type, __tag__: DifferenceType.MODIFIED };
        }

        if (
          sameType &&
          a!.__description__?.toString() !== b!.__description__?.toString()
        ) {
          // Modified
          diff.type = {
            ...diff.type,
            __description__: DifferenceType.MODIFIED,
          };
        }
      }
      break;
    case ValueType.NULL:
      // It's unchanged and we've already detected a type change, don't touch it
      break;
    case ValueType.BOOLEAN:
    case ValueType.DOUBLE:
    case ValueType.INT:
    case ValueType.STRING:
    default:
      // Same type means both must exist
      if (sameType && a?.__value__?.toString() !== b?.__value__?.toString()) {
        // Modified
        diff.type = { ...diff.type, __value__: DifferenceType.MODIFIED };
      }

      if (sameType && a?.__tag__?.toString() !== b?.__tag__?.toString()) {
        // Modified
        diff.type = { ...diff.type, __tag__: DifferenceType.MODIFIED };
      }

      if (
        sameType &&
        a?.__description__?.toString() !== b?.__description__?.toString()
      ) {
        // Modified
        diff.type = { ...diff.type, __description__: DifferenceType.MODIFIED };
      }
      break;
  }

  return diff;
}
