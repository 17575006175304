import { Popover, TextField } from "@mui/material";
import React, { Component } from "react";
import {
  DiffTooltipState,
  TooltipTextArray,
} from "../model/versioned-settings.model";

interface Props {
  tooltip: DiffTooltipState;
  isEditing: boolean;
  isDiffPage?: boolean;
}

interface State {
  tooltipEditingText: string | TooltipTextArray;
  anchorEl: HTMLButtonElement | null;
}

export default class DescriptionPopover extends Component<Props, State> {
  state: State = {
    tooltipEditingText: this.props.tooltip.text ? this.props.tooltip.text : "",
    anchorEl: null,
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.tooltip.target !== this.props.tooltip.target) {
      this.updateDescription(prevProps.tooltip);
      this.setState({ tooltipEditingText: this.props.tooltip.text });
    }
  }

  updateDescription(tooltip: DiffTooltipState = this.props.tooltip) {
    if (tooltip.onChange) {
      if (this.state.tooltipEditingText === tooltip.text) {
        return;
      }
      tooltip.onChange(this.state.tooltipEditingText);
    }
  }

  render() {
    return (
      <Popover
        open={!!this.props.tooltip}
        anchorEl={this.props.tooltip.target ?? ""}
        id={this.props.tooltip.id}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        {this.props.isEditing ? (
          <TextField
            type="textarea"
            placeholder="Add description"
            style={{ minHeight: "120px" }}
            value={this.state.tooltipEditingText.toString() ?? ""}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.persist();
              this.setState({ tooltipEditingText: event.target.value });
            }}
            onBlur={() => this.updateDescription()}
          />
        ) : Array.isArray(this.props.tooltip.text) ? (
          this.props.tooltip.text.map((el) => (
            <span
              key={el.value}
              className={
                el.added
                  ? "green-highlight highlight"
                  : el.removed
                  ? "red-highlight highlight"
                  : ""
              }
            >
              {el.value}
            </span>
          ))
        ) : (
          this.props.tooltip.text
        )}
      </Popover>
    );
  }
}
