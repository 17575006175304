import {
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  capitalize,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Collapse,
  LinearProgress,
  Typography,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import classNames from "classnames";
import * as H from "history";
import moment from "moment";
import React, { Component } from "react";
import { RouteChildrenProps, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { store } from "../../app/store";
import DescriptionPopover from "../../components/DescriptionPopover";
import Endpoints from "../../constants/endpoints";
import { IGameEdition } from "../../model/game-edition.model";
import { getDiff } from "../../utils/getDiff";
import { getUser } from "../../utils/getUser";
import { getVersions } from "../../utils/getVersions";
import { recursiveHasBeenModified } from "../../utils/recursiveHasBeenModified";
import { validateVersionedSettings } from "../../utils/validateVersionedSettings";
import {
  changesSelector,
  combinedSettingsSelector,
  combiningSettingsSelector,
  configSelector,
  countriesSelector,
  selectedSettingsObjectsSelector,
  setConfig,
  setGameEditions,
  setSettings,
  settingsSelector,
  updateCombinedSettings,
} from "../AddToAll/addToAllSlice";
import Switcher from "../AddToAll/components/Switcher";
import Filter, {
  StyledCollapseBar,
  StyledDropdown,
  StyledFilterContainer,
} from "../AddToAll/components/Filter/Filter";
import { darkTheme } from "../AddToAll/styles/DarkTheme";
import { lightTheme } from "../AddToAll/styles/LightTheme";
import { ThemeProvider } from "styled-components";
import { isEqual } from "lodash";
import { StyledProgressBarPopup } from "../AddToAll/AddToAll";
import { odiffResult } from "odiff";
import { convertToTopLevelSetting } from "../../worker";
import { updateModified } from "../../utils/updateModified";
import { combineSettings } from "../AddToAll/addToAllThunks";
import { setVersionOptions } from "../AddToAll/components/Filter/filterSlice";
import ExpandableDiffSetting, {
  deepClone,
} from "../../components/ExpandableDiffSetting/ExpandableDiffSetting";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { getData, postData } from "../../network/request";
import {
  DifferenceEntry,
  DiffTooltipState,
  GameEdition,
  GameEditionLabel,
  NestedSetting,
  NestedSettings,
  ReactSelectArray,
  ReactSelectObject,
  serverTimeFormat,
  SwitcherObject,
  ValueType,
  VersionedSettings,
  VersionedSettingss,
} from "../../model/versioned-settings.model";
import { selectDarkMode } from "../general/generalSlice";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { Alert } from "@mui/material";
import { Flex } from "../../components/Flex";
import { AutoCompleteSelect } from "../AddToAll/components/AutocompleteSelect/AutoCompleteSelect";

interface RouteParams {
  game_id?: string;
  leftSettingsId?: string;
  rightSettingsId?: string;
}

export interface LocationParams {
  importedSettings?: VersionedSettings;
  leftSettingsObject: VersionedSettings;
  storedCombinedSettings?: NestedSetting;
  storedChanges?: odiffResult[];
}

export interface Props {
  selectedSettings: VersionedSettingss;
  combinedSettings: NestedSetting;
  combineSettings: (settings: VersionedSettingss) => void;
  combiningSettings: boolean;
  updateCombinedSettings: (settings: VersionedSettings) => void;
  changes: odiffResult[];
  settingsFromStore: VersionedSettingss;
  config: any;
  countries: ReactSelectArray;
  setSettings: (settings: VersionedSettingss) => void;
  setConfig: (config: any) => void;
  setVersionOptions: (verions: ReactSelectArray) => void;
  setGameEditions: (gameEditions: ReactSelectArray) => void;
  isDarkMode: boolean;
}

export interface State {
  versionedSettings?: VersionedSettingss;
  versionedSettingsOptions?: ReactSelectArray;
  leftSettings?: VersionedSettings;
  leftSettingsObject?: NestedSetting;
  rightSettings?: VersionedSettings;
  oldRightSettings?: NestedSetting;
  rightSettingsObject?: NestedSetting;
  settingsDiff?: DifferenceEntry;
  errorMessage?: string;
  minimisedFilters?: boolean;
  hideUnchanged?: boolean;
  isSaving?: boolean;
  isTop?: boolean;
  ignoreAllChanges?: boolean;
  acceptAllChanges?: boolean;
  gameEdition?: ReactSelectObject | null;
  version?: ReactSelectObject | null;
  country?: ReactSelectObject | null;
  versions?: ReactSelectArray;
  countries?: ReactSelectArray;
  gameEditionsReactSelect?: ReactSelectArray;
  gameEditionsRaw?: IGameEdition[];
  noChanges: boolean;
  switcherOptions?: SwitcherObject[];
  combinedSettings?: VersionedSettings;
  returnPath?: string;
  leftTooltip?: DiffTooltipState;
  rightTooltip?: DiffTooltipState;
}

export enum DifferenceType {
  UNCHANGED = "unchanged",
  ADDITION = "addition",
  DELETION = "deletion",
  MODIFIED = "modified",
}

class ImportToAll extends Component<
  RouteChildrenProps<RouteParams, LocationParams> & Props,
  State
> {
  state: State = {
    hideUnchanged: true,
    isTop: true,
    gameEdition: {
      value: GameEdition.ANDROID,
      label: GameEditionLabel.ANDROID,
    },
    version: { value: "latest", label: "Latest" },
    country: { value: "DEFAULT", label: "Default" },
    noChanges: true,
  };

  private oldLeftSettings: VersionedSettings | undefined;

  componentDidMount(): void {
    // Use event listener to check if at the top of the page
    document.addEventListener("scroll", this.onScroll);

    sessionStorage.active = window.location.href;

    //@ts-ignore
    if (this.props.history?.location?.state?.returnPath) {
      this.setState({
        //@ts-ignore
        returnPath: this.props.history?.location?.state?.returnPath,
      });
    }

    // If settings in tab state
    //@ts-ignore
    if (this.props.history?.location?.state?.settings) {
      // Load into redux store
      const versionOptions = getVersions(
        //@ts-ignore
        this.props.history?.location?.state?.settings
      );
      this.props.setVersionOptions(versionOptions);
      //@ts-ignore
      this.props.setSettings(this.props.history?.location?.state?.settings);
    }

    // If config in tab state
    //@ts-ignore
    if (this.props.history?.location?.state?.config) {
      // Load into redux store
      //@ts-ignore
      this.props.setConfig(this.props.history?.location?.state?.config);
    }

    // If game editions in tab state
    //@ts-ignore
    if (this.props.history?.location?.state?.gameEditionsReactSelect) {
      // Load into redux store
      this.props.setGameEditions(
        //@ts-ignore
        this.props.history?.location?.state?.gameEditionsReactSelect
      );
    }

    this.getVersionedSettings();
  }

  componentWillUnmount(): void {
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const isTop = window.scrollY < 10;
    if (isTop !== this.state.isTop) {
      this.setState({ isTop });
    }
  };

  // @ts-ignore
  componentDidUpdate(
    prevProps: Readonly<RouteComponentProps<RouteParams> & Props>,
    prevState: Readonly<State>
  ) {
    if (
      prevProps.match.params.leftSettingsId !==
        this.props.match?.params.leftSettingsId ||
      prevProps.match.params.rightSettingsId !==
        this.props.match?.params.rightSettingsId
    ) {
      this.loadSettings();
    }

    if (
      prevProps.settingsFromStore === undefined &&
      this.props.settingsFromStore
    ) {
      this.getVersionedSettings();
    }

    // If selected settings changes update combined settings
    if (
      !isEqual(prevProps.selectedSettings, this.props.selectedSettings) &&
      this.props.selectedSettings.length !== 0 &&
      this.props.selectedSettings
    ) {
      this.props.combineSettings(this.props.selectedSettings);
    }

    if (prevProps.combinedSettings !== this.props.combinedSettings) {
      this.loadSettings(this.state.rightSettings);
    }

    // Check number of active changes (non different changes)
    if (
      !recursiveHasBeenModified(this.state.settingsDiff) &&
      !this.state.noChanges
    ) {
      // Ensure all settings have been ignored
      const { leftSettings } = this.state;

      // Replace oldRightSettings with setting in state
      const index = this.state.versionedSettings?.findIndex(
        (setting) => setting.object_id === this.state.rightSettings?.object_id
      );

      const newSettings = deepClone(this.props.settingsFromStore) ?? [];

      if (index) {
        const rightSetting = newSettings[index];

        if (rightSetting) {
          //@ts-ignore
          rightSetting.nested_settings = this.state.oldRightSettings?.__value__;
        }
      }

      const leftSettingIndex = this.props.settingsFromStore?.findIndex(
        (setting) => setting.object_id === leftSettings?.object_id
      );

      if (leftSettingIndex) {
        if (leftSettings) {
          newSettings[leftSettingIndex] = leftSettings;
        }
      }

      this.props.history.push({
        pathname: "/game-settings/game-settings",
        state: {
          leftSettingsObject: leftSettings,
          storedChanges: this.props.changes,
          storedCombinedSettings: convertToTopLevelSetting(leftSettings),
          settings: this.props.settingsFromStore,
          config: this.props.config,
          gameEditionsReactSelect: this.state.gameEditionsReactSelect,
        },
      } as H.LocationDescriptorObject<LocationParams>);
    }
  }

  changeMade() {
    this.setState({ noChanges: false });
  }

  setTooltip = (isLeft: boolean, state?: DiffTooltipState) => {
    if (
      this.state.leftTooltip &&
      state &&
      this.state.leftTooltip.target === state.target &&
      this.state.rightTooltip &&
      state &&
      this.state.rightTooltip.target === state.target
    ) {
      return;
    }

    // @ts-ignore
    this.setState({ [isLeft ? "leftTooltip" : "rightTooltip"]: state });
  };

  loadSettings(rightSetting?: VersionedSettings) {
    const oldRightSettings = deepClone(
      rightSetting ??
        (this.state.versionedSettings &&
          this.state.versionedSettings.find(
            (setting) =>
              setting.object_id === this.props.match?.params.rightSettingsId
          ))
    );

    const oldRightSettingsObject = {
      __value__: oldRightSettings?.nested_settings ?? "",
      __user__: "",
      __type__: ValueType.OBJECT,
      __modified__: "",
      __tag__: "",
      __description__: "",
    };

    this.setState(
      //@ts-ignore
      {
        leftSettings: {
          country: "",
          created_on: 1585564005481,
          deleted_on: null,
          game_edition_id: "",
          game_id: "",
          max_version: "",
          name: "",
          //@ts-ignore
          nested_settings:
            deepClone(this.props.combinedSettings?.__value__) ?? {},
          object_id: "",
          object_type: "versioned_settings",
          updated_on: 1591785166743,
          _cas: 1591785166818050048,
        },
        rightSettings:
          rightSetting ??
          (this.state.versionedSettings &&
            this.state.versionedSettings.find(
              (setting) =>
                setting.object_id === this.props.match?.params.rightSettingsId
            )),
        oldRightSettings: oldRightSettingsObject,
      },
      () => this.generateNewDiff()
    );
  }

  getVersionedSettings() {
    const fallbackGameEditions = [
      { object_id: "ios", name: "ios", display_name: "iOS" },
      { object_id: "android", name: "android", display_name: "Android" },
    ];

    // @ts-ignore
    if (!this.props.history?.location?.state?.gameEditionsReactSelect) {
      getData(Endpoints.GAME_EDITIONS.url)
        .then((res: any) => {
          this.setState({ gameEditionsRaw: res.game_editions });
        })
        //@ts-ignore
        .catch(() => this.setState({ gameEditionsRaw: fallbackGameEditions }));
    }

    // Get game id from URL

    const { settingsFromStore } = this.props;

    if (settingsFromStore) {
      let leftSettings: VersionedSettings | undefined;
      let rightSettings: VersionedSettings | undefined;

      const versionedSettings = settingsFromStore.map((settings) =>
        validateVersionedSettings(settings)
      );

      if (this.props.location.state?.leftSettingsObject) {
        const leftSettingsObject =
          this.props.location.state?.leftSettingsObject;
        const settingsId = leftSettingsObject.object_id;

        const index = versionedSettings.findIndex(
          (settings) => settings.object_id === settingsId
        );

        versionedSettings.splice(index, 1, leftSettingsObject);
        this.oldLeftSettings = deepClone(leftSettingsObject);
      }

      const versionOptions = getVersions(versionedSettings);
      let gameEditionOptions;

      //@ts-ignore
      if (!this.props.history?.location?.state?.gameEditionsReactSelect) {
        gameEditionOptions = this.state.gameEditionsRaw?.map((edition) => ({
          value: edition.name,
          label: edition.display_name,
        }));
      } else {
        gameEditionOptions =
          // @ts-ignore
          this.props.history?.location?.state?.gameEditionsReactSelect;
      }

      if (
        !this.props.match?.params.leftSettingsId ||
        this.props.match?.params.leftSettingsId === "null"
      ) {
        const leftSettingsIndex = versionedSettings.findIndex(
          (setting) =>
            setting.max_version === "latest" &&
            setting.game_edition_id === GameEdition.IOS
        );

        if (leftSettingsIndex >= 0) {
          leftSettings = versionedSettings[leftSettingsIndex];
        }
      }

      if (this.isImportedRightSetting) {
        if (!this.props.location.state?.importedSettings) {
          this.props.history.push("/");

          return;
        }
        rightSettings = this.props.location.state?.importedSettings;
      }

      if (
        !this.props.match?.params.rightSettingsId ||
        this.props.match?.params.rightSettingsId === "null"
      ) {
        const rightSettingsIndex = versionedSettings.findIndex(
          (setting) =>
            setting.max_version === "latest" &&
            setting.game_edition_id === GameEdition.ANDROID &&
            setting.country === "DEFAULT"
        );

        if (rightSettingsIndex >= 0) {
          rightSettings = versionedSettings[rightSettingsIndex];
        }
      }

      // Create versionedSettings options
      const versionedSettingsOptions = versionedSettings.map((setting) => {
        return {
          value: setting.object_id,
          label: `${
            setting.game_edition_id === "ios"
              ? "iOS"
              : capitalize(setting.game_edition_id)
          }, ${capitalize(
            setting.max_version
          )}, ${setting.country.toUpperCase()}`,
        };
      });

      this.setState({
        versionedSettings,
        versionedSettingsOptions,
        versions: versionOptions,
        gameEditionsReactSelect: gameEditionOptions,
      });

      if (
        leftSettings ||
        (rightSettings &&
          this.props.match?.params.rightSettingsId !== "imported")
      ) {
        this.openSettings(
          leftSettings?.object_id ?? this.props.match?.params.leftSettingsId,
          rightSettings?.object_id ?? this.props.match?.params.rightSettingsId
        );
      } else {
        this.loadSettings(rightSettings);
      }
    }
  }

  generateNewDiff() {
    const { versionedSettings, leftSettings, rightSettings } = this.state;

    if (!versionedSettings || !leftSettings || !rightSettings) {
      return;
    }

    const leftSettingsObject = {
      __value__: leftSettings.nested_settings,
      __user__: "",
      __type__: ValueType.OBJECT,
      __modified__: "",
      __tag__: "",
      __description__: "",
    };

    const rightSettingsObject = {
      __value__: rightSettings.nested_settings,
      __user__: "",
      __type__: ValueType.OBJECT,
      __modified__: "",
      __tag__: "",
      __description__: "",
    };

    this.setState(
      {
        leftSettingsObject,
        rightSettingsObject,
      },
      () =>
        this.setState({
          settingsDiff: getDiff(
            this.state.leftSettingsObject,
            this.state.rightSettingsObject
          ),
        })
    );
  }

  updateSettings = (isLeft: boolean, settings: NestedSettings) => {
    const { versionedSettings, leftSettings, rightSettings } = this.state;

    if (!versionedSettings) {
      return;
    }

    const setting = isLeft ? leftSettings : rightSettings;

    const index = versionedSettings.findIndex(
      (s) => s.object_id === setting?.object_id
    );

    if (isNullOrUndefined(index) || index < 0) {
      return;
    }

    const versionedSetting = versionedSettings[index];

    const arr: VersionedSettings[] = [...versionedSettings];

    arr[index] = {
      ...versionedSetting,
      nested_settings: settings,
    };

    //@ts-ignore
    this.setState({
      versionedSettings: arr,
      [isLeft ? "leftSettings" : "rightSettings"]: arr[index],
    });
  };

  async keepAllChanges(isLeft: boolean) {
    let setting: NestedSettings | undefined;
    const newSetting: NestedSettings | undefined = {};
    if (isLeft) {
      setting = this.state.leftSettings?.nested_settings;
    } else {
      setting = this.state.rightSettings?.nested_settings;
    }

    // Recursive assign but override __modified__ and __user__ only for changed settings
    if (setting) {
      // @ts-ignore
      setting = Object.keys(setting).forEach((key) => {
        const value = setting![key];

        newSetting![key] = updateModified(
          value,
          //@ts-ignore
          this.state.settingsDiff!.children[key as keyof DifferenceEntry]
        );
      });
    }

    if (newSetting) {
      await this.updateSettings(!isLeft, newSetting);
    }

    // Generate new diff
    this.generateNewDiff();
  }

  saveSettings() {
    const { leftSettings } = this.state;

    this.setState({ isSaving: true });

    postData(
      `admin/versioned-settings/${leftSettings?.object_id}/update`,
      leftSettings
    )
      .then(() => {
        store.dispatch(
          queueNotification({
            message: "Game settings successfully updated",
            options: {
              key: `game_settings_update`,
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );
        this.setState({ isSaving: false });
      })
      .catch(() => {
        store.dispatch(
          queueNotification({
            message: "Error updating game settings, please try again",
            options: {
              key: `game_settings_update_error`,
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  }

  openSettings(left?: string, right?: string) {
    let url = "/game-settings/import-game-settings";
    if (left) {
      url += `/${left}`;
    } else if (right) {
      url += "/null";
    }

    if (right) {
      url += `/${right}`;
    }

    this.props.history.push(url);
  }

  getSettingsID() {
    const { gameEdition, version, country, versionedSettings } = this.state;

    if (!versionedSettings || !gameEdition || !version || !country) {
      return;
    }

    const index = versionedSettings.findIndex(
      (setting) =>
        setting.game_edition_id === gameEdition.value &&
        setting.max_version === version.value &&
        setting.country === country.value
    );

    if (isNullOrUndefined(index) || index < 0) {
      return;
    }

    return versionedSettings[index].object_id;
  }

  get isImportedRightSetting() {
    return this.props.match?.params.rightSettingsId === "imported";
  }

  filterPlatform = (dim: string, key: keyof VersionedSettings) => {
    const platform = this.state.gameEdition;
    if (!platform) {
      return true;
    }

    return !!this.state.versionedSettings?.some(
      (settings) =>
        settings.game_edition_id === platform.value && settings[key] === dim
    );
  };

  filterVersion = (dim: string, key: keyof VersionedSettings) => {
    const version = this.state.version;
    if (!version) {
      return true;
    }

    return !!this.state.versionedSettings?.some(
      (settings) =>
        settings.max_version === version.value && settings[key] === dim
    );
  };

  filterCountry = (dim: string, key: keyof VersionedSettings) => {
    const country = this.state.country;
    if (!country) {
      return true;
    }

    return !!this.state.versionedSettings?.some(
      (settings) => settings.country === country.value && settings[key] === dim
    );
  };

  get filteredPlatforms() {
    return this.state.gameEditionsReactSelect
      ?.filter((gameEdition) =>
        this.filterVersion(gameEdition.value, "game_edition_id")
      )
      .filter((gameEdition) =>
        this.filterCountry(gameEdition.value, "game_edition_id")
      );
  }

  get filteredVersions() {
    return this.state.versions
      ?.filter((version) => this.filterPlatform(version.value, "max_version"))
      .filter((version) => this.filterCountry(version.value, "max_version"))
      .sort((a: ReactSelectObject, b: ReactSelectObject) =>
        b.value
          // @ts-ignore
          .replace(/\d+/g, (n) => +n + 100000)
          .localeCompare(
            // @ts-ignore
            a.value.replace(/\d+/g, (n) => +n + 100000)
          )
      );
  }

  get filteredCountries() {
    return this.props.countries
      ?.filter((country) => this.filterPlatform(country.value, "country"))
      .filter((country) => this.filterVersion(country.value, "country"))
      .sort((a, b) => a.value.localeCompare(b.value));
  }

  render() {
    const {
      leftSettingsObject,
      rightSettingsObject,
      settingsDiff,
      errorMessage,
      hideUnchanged,
      leftSettings,
      rightSettings,
      oldRightSettings,
      ignoreAllChanges,
      acceptAllChanges,
    } = this.state;

    const { isDarkMode } = this.props;

    const isUnchanged = !recursiveHasBeenModified(settingsDiff);

    return (
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        {this.props.combiningSettings && (
          <StyledProgressBarPopup>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h6">Combining Settings</Typography>
              <LinearProgress color="primary" value={100} />
            </Box>
          </StyledProgressBarPopup>
        )}
        <Flex display="flex" flexDirection="column">
          {this._renderFilters()}
          <Flex
            display="flex"
            width="100%"
            className="flex-grow-1 flex-shrink-1 overflow-auto"
          >
            <div className="page-content">
              {errorMessage ? (
                <Alert severity="error" className="text-center">
                  {errorMessage}
                </Alert>
              ) : leftSettingsObject || rightSettingsObject ? (
                <>
                  {isNullOrUndefined(rightSettings) ||
                  isNullOrUndefined(this.props.combinedSettings) ? (
                    <Flex
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ m: 3 }}
                    >
                      <Alert color="info" className="w-100 text-center">
                        No settings selected
                      </Alert>
                    </Flex>
                  ) : (
                    <Flex
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      className="diff-tool-container"
                    >
                      <ExpandableDiffSetting
                        leftSetting={leftSettingsObject}
                        rightSetting={rightSettingsObject}
                        oldRightSetting={oldRightSettings}
                        settingsDiff={settingsDiff}
                        leftSettingName="top-level"
                        rightSettingName="top-level"
                        depth={-1}
                        updateSettings={(isLeft, value) => {
                          if (value !== undefined) {
                            value = {
                              ...value,
                              __modified__: moment().format(serverTimeFormat),
                              __user__: getUser(),
                            };
                          }

                          // If left setting is updated update combined settings
                          if (isLeft) {
                            // @ts-ignore
                            this.props.updateCombinedSettings(value);
                          }

                          // @ts-ignore
                          this.updateSettings(isLeft, value?.__value__);
                        }}
                        generateNewDiff={this.generateNewDiff.bind(this)}
                        hideUnchanged={hideUnchanged}
                        ignoreAllChanges={ignoreAllChanges}
                        acceptAllChanges={acceptAllChanges}
                        changeMade={this.changeMade.bind(this)}
                        setTooltip={this.setTooltip.bind(this)}
                        isDarkMode={isDarkMode}
                      />

                      {this.state.leftTooltip && (
                        <DescriptionPopover
                          isEditing={false}
                          tooltip={this.state.leftTooltip}
                          isDiffPage
                        />
                      )}
                      {this.state.rightTooltip && (
                        <DescriptionPopover
                          isEditing={false}
                          tooltip={this.state.rightTooltip}
                          isDiffPage
                        />
                      )}
                    </Flex>
                  )}
                  {rightSettings && hideUnchanged && isUnchanged && (
                    <Flex
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ m: 3 }}
                    >
                      {!leftSettings ||
                        (!rightSettings && "No imported settings found")}
                      <Alert color="info" className="w-100 text-center">
                        No changes between game settings, return to the editor
                        to make changes, or select different settings from the
                        filters above.
                      </Alert>
                    </Flex>
                  )}
                </>
              ) : (
                <Box width="100%" textAlign="center">
                  <CircularProgress color="primary" />
                </Box>
              )}
            </div>
          </Flex>
        </Flex>
      </ThemeProvider>
    );
  }

  private _renderFilters() {
    const { leftSettings, rightSettings, hideUnchanged, isTop, settingsDiff } =
      this.state;

    const { isDarkMode } = this.props;

    const isUnchanged =
      settingsDiff?.type.__value__ === DifferenceType.UNCHANGED;

    return (
      <div
        className={classNames([
          "d-flex flex-grow-0 flex-shrink-0",
          "flex-column",
          "text-left",
          "filters",
          "w-100",
          { scrolled: !isTop },
        ])}
      >
        <Collapse className="filter-collapse" in={!this.state.minimisedFilters}>
          <StyledFilterContainer className="filters-container">
            <Box px={2}>
              <small className="text-muted">Target Settings</small>
              <Filter importToAll={true} />
              <small className="text-muted">Imported Settings</small>
              <form>
                <Grid container>
                  <Grid item md={4}>
                    <FormGroup>
                      <StyledDropdown darkMode={isDarkMode}>
                        <Box sx={{ mb: 1 }}>Platform</Box>

                        <AutoCompleteSelect
                          disabled={
                            !this.state.gameEditionsReactSelect ||
                            this.isImportedRightSetting
                          }
                          id="platform-id"
                          options={this.filteredPlatforms || []}
                          getOptionLabel={(option: any) => option.label}
                          getOptionSelected={(option: any, value: any) =>
                            option.value === value.value
                          }
                          selected={
                            this.isImportedRightSetting
                              ? { label: "Clipboard/File", value: null }
                              : this.state.gameEdition
                          }
                          onOptionSelected={(event, selectedOption, reason) => {
                            this.setState(
                              {
                                //@ts-ignore
                                gameEdition: selectedOption,
                              },
                              () =>
                                this.openSettings(
                                  leftSettings?.object_id ??
                                    this.props.match?.params.leftSettingsId,
                                  this.getSettingsID()
                                )
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              placeholder="Select..."
                            />
                          )}
                          size="small"
                        />
                      </StyledDropdown>
                    </FormGroup>
                  </Grid>
                  <Grid item md={4}>
                    <FormGroup>
                      <StyledDropdown darkMode={isDarkMode}>
                        <Box sx={{ mb: 1 }}>Version</Box>

                        <AutoCompleteSelect
                          getOptionLabel={(option: any) => option.label}
                          disabled={this.isImportedRightSetting}
                          //@ts-ignore
                          selected={
                            this.isImportedRightSetting
                              ? { label: "Clipboard/File", value: null }
                              : this.state.version
                          }
                          options={this.filteredVersions || []}
                          id="version-id"
                          getOptionSelected={(option: any, value: any) =>
                            option.value === value.value
                          }
                          onOptionSelected={(event, selectedOption, reason) => {
                            this.setState(
                              {
                                version: selectedOption,
                              },
                              () =>
                                this.openSettings(
                                  leftSettings?.object_id ??
                                    this.props.match?.params.leftSettingsId,
                                  this.getSettingsID()
                                )
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              placeholder="Select Version..."
                            />
                          )}
                          size="small"
                        />
                      </StyledDropdown>
                    </FormGroup>
                  </Grid>
                  <Grid item md={4}>
                    <FormGroup>
                      <StyledDropdown darkMode={isDarkMode}>
                        <Box sx={{ mb: 1 }}>Country</Box>

                        <AutoCompleteSelect
                          getOptionLabel={(option: any) => option.label}
                          disabled={this.isImportedRightSetting}
                          //@ts-ignore
                          selected={
                            this.isImportedRightSetting
                              ? { label: "Clipboard/File", value: null }
                              : this.state.country
                          }
                          options={this.filteredCountries || []}
                          id="version-id"
                          getOptionSelected={(option: any, value: any) =>
                            option.value === value.value
                          }
                          onOptionSelected={(event, selectedOption, reason) => {
                            this.setState(
                              {
                                country: selectedOption,
                              },
                              () =>
                                this.openSettings(
                                  leftSettings?.object_id ??
                                    this.props.match?.params.leftSettingsId,
                                  this.getSettingsID()
                                )
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              placeholder="Select Version..."
                            />
                          )}
                          size="small"
                        />
                      </StyledDropdown>
                    </FormGroup>
                  </Grid>
                </Grid>
              </form>
              <Flex
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 2 }}
                className="action-buttons"
              >
                <Flex
                  display="flex"
                  alignItems="center"
                  className="secondary-buttons"
                >
                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      className="mr-md-1"
                      disabled={!this.state.versionedSettings}
                      id="add-button"
                      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                      onClick={() => {
                        if (this.state.noChanges) {
                          this.props.history.push({
                            pathname:
                              this.state.returnPath ??
                              "/game-settings/game-settings",
                            state: {
                              leftSettingsObject: this.oldLeftSettings,
                              settings: this.props.settingsFromStore,
                              config: this.props.config,
                              gameEditionsReactSelect:
                                this.state.gameEditionsReactSelect,
                            },
                          } as H.LocationDescriptorObject<LocationParams>);
                        } else {
                          if (
                            window.confirm(
                              "Any changes made to settings will be lost, are you sure you want to go back?"
                            )
                          ) {
                            this.props.history.push({
                              pathname:
                                this.state.returnPath ??
                                "/game-settings/game-settings",
                              state: {
                                leftSettingsObject: this.oldLeftSettings,
                                settings: this.props.settingsFromStore,
                                config: this.props.config,
                                gameEditionsReactSelect:
                                  this.state.gameEditionsReactSelect,
                              },
                            } as H.LocationDescriptorObject<LocationParams>);
                          }
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Box mr={2}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      className="mr-md-1"
                      disabled={!this.state.versionedSettings}
                      id="add-button"
                      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/game-settings/game-settings",
                          state: {
                            leftSettingsObject: leftSettings,
                            storedChanges: this.props.changes,
                            storedCombinedSettings:
                              convertToTopLevelSetting(leftSettings),
                            settings: this.props.settingsFromStore,
                            config: this.props.config,
                            gameEditionsReactSelect:
                              this.state.gameEditionsReactSelect,
                          },
                        } as H.LocationDescriptorObject<LocationParams>);
                      }}
                    >
                      Return with Changes
                    </Button>
                  </Box>

                  <Button
                    color="secondary"
                    size="small"
                    className="mr-md-1"
                    disabled={!this.state.versionedSettings}
                    startIcon={<FontAwesomeIcon icon={faTimes} />}
                    onClick={() =>
                      this.setState(
                        {
                          country: null,
                          version: null,
                          gameEdition: null,
                        },
                        () => {
                          this.openSettings(
                            leftSettings?.object_id ??
                              this.props.match?.params.leftSettingsId,
                            this.getSettingsID()
                          );
                        }
                      )
                    }
                  >
                    Clear filters
                  </Button>
                </Flex>
                <Flex
                  display="flex"
                  alignItems="center"
                  className="secondary-buttons"
                >
                  <FormControlLabel
                    id="hideUnchanged"
                    control={
                      <Checkbox
                        disabled={!leftSettings || !rightSettings}
                        checked={hideUnchanged ?? false}
                        onChange={() =>
                          this.setState({
                            hideUnchanged: !this.state.hideUnchanged,
                          })
                        }
                      />
                    }
                    label="Hide unchanged settings"
                  />
                </Flex>
              </Flex>
            </Box>
          </StyledFilterContainer>
        </Collapse>

        <StyledCollapseBar
          darkMode={isDarkMode}
          onClick={() =>
            this.setState({ minimisedFilters: !this.state.minimisedFilters })
          }
        >
          <Flex
            display="flex"
            justifyContent="center"
            className="collapse-arrow-containercollapse-arrow-container"
          >
            <FontAwesomeIcon
              icon={this.state.minimisedFilters ? faChevronDown : faChevronUp}
            />
          </Flex>
        </StyledCollapseBar>
        <StyledFilterContainer className="both-settings-headers d-flex justify-content-center py-2">
          <Flex
            display="flex"
            alignItems="baseline"
            className="settings-heading"
            sx={{ width: "43%" }}
          >
            <div className="w-100">
              <h4 className="mr-2 mb-0">Target Settings</h4>
              <Switcher
                switcherOptions={
                  this.props.config?.settings["game_settings_quick_switcher"]
                }
              />
            </div>
          </Flex>
          <Flex
            width="50%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className="settings-heading"
            style={{ marginLeft: 15 }}
          >
            <Flex display="flex" flexDirection="column">
              <h4 className="mr-2 mb-0">Imported Settings</h4>
              {rightSettings?.object_id &&
                `${
                  rightSettings?.game_edition_id === "ios"
                    ? "iOS"
                    : capitalize(rightSettings?.game_edition_id ?? "")
                }, ${capitalize(
                  rightSettings?.max_version ?? ""
                )}, ${rightSettings?.country.toUpperCase()}`}
              <small className="text-muted">
                Accept or Ignore all changes to return to the settings editor
              </small>
            </Flex>
            <Flex
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              sx={{ pr: 3 }}
            >
              <Button
                className="p-0 text-left accept-button"
                disabled={isUnchanged || !rightSettings}
                onClick={() => {
                  this.setState({ acceptAllChanges: true });
                  this.changeMade();
                }}
              >
                {" "}
                Accept remaining
              </Button>
              <Button
                className="p-0 text-left reject-button"
                disabled={isUnchanged || !rightSettings}
                onClick={() => {
                  this.setState({ ignoreAllChanges: true });
                  this.changeMade();
                }}
              >
                {" "}
                Ignore remaining
              </Button>
            </Flex>
          </Flex>
        </StyledFilterContainer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  combinedSettings: combinedSettingsSelector(state),
  selectedSettings: selectedSettingsObjectsSelector(state),
  combiningSettings: combiningSettingsSelector(state),
  changes: changesSelector(state),
  settingsFromStore: settingsSelector(state),
  config: configSelector(state),
  countries: countriesSelector(state),
  isDarkMode: selectDarkMode(state) === "dark",
});

const mapDispatchToProps = {
  combineSettings,
  updateCombinedSettings,
  setSettings,
  setConfig,
  setGameEditions,
  setVersionOptions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  //@ts-ignore
)(ImportToAll);

export const onRefresh = (e: any) => {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = "";
};
