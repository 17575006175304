import _ from "lodash";
import { Permissions } from "../model/navigation.model";
import { isEmptyObject } from "../utils/isEmptyObject";
import isNullOrUndefined from "../utils/isNullOrUndefined";

export const checkPermissions = (
  routePermissions?: Permissions,
  userPermissions?: Permissions,
  isSuperuser?: boolean
) => {
  if (isSuperuser) {
    return true;
  }

  if (isNullOrUndefined(routePermissions)) {
    return true;
  }

  if (!isEmptyObject(routePermissions)) {
    return true;
  }

  if (isNullOrUndefined(userPermissions)) {
    return false;
  }

  return _.isMatch(userPermissions, routePermissions);
};
