import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { store } from "../../../../app/store";
import { FormContent } from "../../../../components/CollectionEditor/FormContent";
import { DialogWrapper } from "../../../../components/DialogWrapper";
import { Flex } from "../../../../components/Flex";
import Endpoints from "../../../../constants/endpoints";
import { getData, postData } from "../../../../network/request";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../notification/notificationSlice";
import { BidReductionModel } from "./BidReductionModel";

type BidReductionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const BidReductionModal = ({
  isOpen,
  onClose,
}: BidReductionModalProps) => {
  const { data: bidReduction, error } = useSWR(
    Endpoints.BID_REDUCTION.url,
    (url: string) => getData(Endpoints.BID_REDUCTION.url)
  );

  const [formValue, setFormValue] = useState({
    margin: bidReduction?.global_margin?.margin ?? 0,
  });

  useEffect(() => {
    setFormValue({
      margin: bidReduction?.global_margin?.margin ?? 0,
    });
  }, [bidReduction]);

  const updateBidReduction = async (data: any) => {
    return postData(Endpoints.BID_REDUCTION.url, data)
      .then((response) => {
        store.dispatch(
          queueNotification({
            message: "Updated Successfully",
            options: {
              key: "update_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );
        onClose();
      })
      .catch(() => {
        store.dispatch(
          queueNotification({
            message: "Error updating",
            options: {
              key: "update_notification",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  };

  const isLoading = isNullOrUndefined(bidReduction);

  return (
    <DialogWrapper isOpen={isOpen} onClose={onClose}>
      <DialogTitle>Edit Bid Reduction</DialogTitle>
      <DialogContent>
        {(isLoading || error) && (
          <Box m={2}>
            <Typography>
              Ensure you have your WireGuard VPN active and have{" "}
              <code>10.100.0.0/22</code> added to your WireGuard AllowedIPs, you
              can do this by clicking "Edit" then adding the IP to the list.
              Contact the Server team if you have any issues.
            </Typography>
          </Box>
        )}
        {error ? (
          <>
            <Alert severity="error">Error fetching margin</Alert>
          </>
        ) : isLoading ? (
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress />
            <small className="mt-3">Loading...</small>
          </Flex>
        ) : (
          <>
            <small>bid = probability * ltv - probability * ltv * margin</small>
            <FormContent
              page="xpromo_campaigns"
              setFormValue={setFormValue}
              formValue={formValue}
              model={BidReductionModel()}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button
          variant="contained"
          disabled={error || isNullOrUndefined(bidReduction)}
          onClick={() => updateBidReduction(formValue)}
        >
          Save
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
};
