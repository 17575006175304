import { DifferenceType } from "./DifferenceType";

const SettingParts = {
  __value__: "Value",
  __tag__: "Tag",
  __description__: "Description",
};

export enum SettingKeys {
  VALUE = "__value__",
  TAG = "__tag__",
  DESC = "__description__",
}

export const unchangedType = {
  __value__: DifferenceType.UNCHANGED,
  __tag__: DifferenceType.UNCHANGED,
  __description__: DifferenceType.UNCHANGED,
};

export const modifiedType = {
  __value__: DifferenceType.MODIFIED,
  __tag__: DifferenceType.MODIFIED,
  __description__: DifferenceType.MODIFIED,
};

export const addedType = {
  __value__: DifferenceType.ADDITION,
  __tag__: DifferenceType.ADDITION,
  __description__: DifferenceType.ADDITION,
};

export const deletedType = {
  __value__: DifferenceType.DELETION,
  __tag__: DifferenceType.DELETION,
  __description__: DifferenceType.DELETION,
};

export default SettingParts;
