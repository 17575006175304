import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import compressFile from "./compressFile";
import GeneratorError from "./GeneratorError";
import { MAX_FILE_SIZE_MB } from "./IECGenerator";
import readFile from "./readFile";

const HTML = `
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no" />
    <style type="text/css">
      html,
      body {
        height: 100%;
        padding: 0;
        margin: 0;
        height: -webkit-fill-available;
      }

      body {
        background: #000;
        background-size: auto 100%;
        overflow: hidden;
        text-align: center;
      }

      video {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    </style>
  </head>
  <body>
    <video playsInline autoPlay loop muted></video>
    <script type='text/javascript'>
      function generateVideoFile(src, name) {
        const arr = src.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const videoFile = new File([u8arr], name, {
          type: mime
        });
        return URL.createObjectURL(videoFile);
      }
      const videoUrl = generateVideoFile("{source}", "{name}");
      const vidEl = document.querySelector('video');
      vidEl.src = videoUrl;
      vidEl.load();
      vidEl.addEventListener('loadeddata', onLoaded);
      vidEl.addEventListener('click', onClick);

      function gameStart() {
        vidEl.play();
      }

      function gameClose() {
        vidEl.pause();
      }

      function onLoaded() {
        window.gameReady && window.gameReady();
      }

      function onClick() {
        window.gameEnd && window.gameEnd();
        window.install && window.install();
      }
    </script>
  </body>
</html>
`;

type Values = {
  videos: File[];
};

export default function Mindworks() {
  const [downloading, setDownloading] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const exportFile = async (file: File) => {
    setDownloading((prev) => [...prev, file]);

    const original = file;
    file = await compressFile(file);
    const b64 = await readFile(file);

    const fileType = b64.substring(5, b64.indexOf(";"));

    let fileContents = HTML;

    // Replacements
    fileContents = fileContents.replace(/{type}/gi, fileType);
    fileContents = fileContents.replace(/{name}/gi, file.name);
    fileContents = fileContents.replace(/{source}/gi, b64);

    // Timestamp
    const currentDate = new Date();
    fileContents =
      `<!-- Generated: ${currentDate.getUTCDate()}/${
        currentDate.getUTCMonth() + 1
      }/${currentDate.getUTCFullYear()} -->\n` + fileContents;

    const blob = new Blob([fileContents], { type: "text/html" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    let name = file.name;
    name = name.replace(/[^a-z0-9]+/gi, "_");
    const extensionIndex = name.lastIndexOf(".");
    if (extensionIndex > -1) {
      name = name.substring(0, extensionIndex);
    }
    a.download = name + "_mindworks.html";

    if (blob.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      throw new GeneratorError(
        `Output file size can not exceed ${MAX_FILE_SIZE_MB}MB, please reduce video size or try recompression...`,
        [file]
      );
    }

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    setDownloading((prev) => prev.filter((f) => f !== original));
  };

  const formik = useFormik<Values>({
    initialValues: {
      videos: [],
    },
    onSubmit: async (values, { setSubmitting }) => {
      const promises: Promise<void>[] = [];
      for (const file of values.videos) {
        if (downloading.includes(file)) {
          continue;
        }

        promises.push(exportFile(file));
      }

      await Promise.allSettled(promises);

      setSubmitting(false);
    },
  });

  const onDragOver = (event: DragEvent) => {
    event.preventDefault();

    if (!event.dataTransfer) {
      return;
    }

    event.dataTransfer.dropEffect = "copy";
  };

  const onDrop = (event: DragEvent) => {
    event.preventDefault();

    if (!event.dataTransfer) {
      return;
    }

    formik.setFieldValue("videos", [
      ...formik.values.videos,
      ...Array.from(event.dataTransfer.files),
    ]);
  };

  useEffect(() => {
    document.addEventListener("dragover", onDragOver);
    document.addEventListener("drop", onDrop);

    return () => {
      document.removeEventListener("dragover", onDragOver);
      document.removeEventListener("drop", onDrop);
    };
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Video</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formik.values.videos.map((video, index) => (
                <TableRow key={`${index}-${video.name}`}>
                  <TableCell>{video.name}</TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="flex-end" gap={2}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => exportFile(video)}
                        disabled={downloading.includes(video)}
                      >
                        Download
                      </Button>

                      <IconButton
                        color="error"
                        onClick={() => {
                          const videos = [...formik.values.videos];
                          videos.splice(index, 1);
                          formik.setFieldValue("videos", videos);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: "right" }}>
                  <Button onClick={() => inputRef.current?.click()}>
                    Add Videos
                  </Button>
                  <input
                    ref={inputRef}
                    type="file"
                    hidden
                    multiple
                    accept="video/mp4"
                    onChange={(event) => {
                      formik.setFieldValue("videos", [
                        ...formik.values.videos,
                        ...Array.from(inputRef.current?.files ?? []),
                      ]);

                      setTimeout(() => (event.target.value = ""), 0);
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box textAlign="right" mt={2}>
          <Button
            type="submit"
            variant="contained"
            disabled={formik.values.videos.length < 1 || formik.isSubmitting}
          >
            Download All
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}
