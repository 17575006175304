import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemIcon, ListItemText } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NestedSetting } from "../../../../model/versioned-settings.model";
import { sanitiseSetting } from "../../../../utils/sanitiseSettings";

interface Props {
  settingName: string;
  settingData: NestedSetting;
  onClose: () => void;
}

const CopyJSONToClipboard = (props: Props) => {
  return (
    <>
      <ListItemIcon>
        <FontAwesomeIcon icon={faSave} />
      </ListItemIcon>
      <CopyToClipboard
        text={JSON.stringify(sanitiseSetting(props.settingData), null, 4)}
      >
        <ListItemText
          primary="Copy JSON to Clipboard"
          onClick={(event) => {
            event.stopPropagation();
            props.onClose();
          }}
        />
      </CopyToClipboard>
    </>
  );
};

export default CopyJSONToClipboard;
