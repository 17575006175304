import { Box, Button, FormControl, Grid, Alert } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  queueNotification,
  SnackbarVariant,
} from "../../features/notification/notificationSlice";
import {
  FieldType,
  FormItem,
  IError,
  MultipleFormValue,
} from "../../model/crud.model";
import { createDefaultObject } from "../../utils/createDefaultObject";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { Flex } from "../Flex";
import { CrudKeys } from "./crudSlice";
import { FieldLabel } from "./FieldLabel";
import { FormField } from "./Fields/FormField";
import { Suggestions } from "./Fields/Suggestions";
import { validateForm } from "./Fields/validateForm";

interface FormContentProps {
  index?: number;
  isCreate?: boolean;
  isDupe?: boolean;
  createItem?: (obj: any) => void;
  updateItem?: (obj: any, index?: number) => void;
  model?: FormItem[];
  page: keyof CrudKeys;
  defaultValueOverrides?: { [id: string]: any };
  parentOnChange?: (
    update: { [id: string]: any },
    parentObject?: { [id: string]: any }
  ) => void;
  setFormValue: (obj: any) => void;
  formValue: { [id: string]: any };
  parentFormValue?: any;
  setHasChanged?: (hasChanged: boolean) => void;
  initialFormValue?: { [id: string]: any };
  onModalOpen?: (formValue: { [id: string]: any }) => void;
  validObj?: { [id: string]: any };
  incorporateLabels?: boolean;
  inline?: boolean;
  multipleFormValues?: Record<string, MultipleFormValue>;
  setMultipleFormValues?: (obj: any) => void;
}

export const FormContent = ({
  index,
  isCreate,
  isDupe,
  updateItem,
  model,
  defaultValueOverrides,
  parentOnChange,
  setFormValue,
  formValue,
  parentFormValue,
  page,
  setHasChanged,
  initialFormValue,
  onModalOpen,
  validObj,
  incorporateLabels,
  inline,
  multipleFormValues,
  setMultipleFormValues,
}: FormContentProps) => {
  const dispatch = useDispatch();

  const startingFormObject = {
    ...createDefaultObject(model ?? [], defaultValueOverrides),
    ...(initialFormValue ?? {}),
  };
  useEffect(() => {
    if (isCreate) {
      setFormValue(startingFormObject);
    }

    onModalOpen && onModalOpen(formValue);
    // eslint-disable-next-line
  }, []);

  const updateObj = (
    currentObject: { [id: string]: any },
    update: { [id: string]: any },
    parentKey?: string
  ) => {
    const updatedObject = { ...(currentObject ?? {}), ...update };

    setFormValue(updatedObject);
    if (parentKey && parentFormValue) {
      if (Array.isArray(parentFormValue[parentKey])) {
        const newArray = [...parentFormValue[parentKey]];

        if (!isNullOrUndefined(index) && index !== -1) {
          newArray[index] = updatedObject;
        }

        parentOnChange &&
          parentOnChange(
            {
              [parentKey]: newArray,
            },
            formValue
          );
      } else if (parentOnChange) {
        parentOnChange(
          {
            [parentKey]: updatedObject,
          },
          formValue
        );
      } else {
        parentFormValue[parentKey] = updatedObject;
      }

      updateItem && updateItem(updatedObject, index);
    }
  };

  const nonRowFieldTypes = [
    FieldType.COLLECTION,
    FieldType.OBJECT,
    FieldType.MULTIPLEVIEWS,
  ];

  const someDifferent = Object.values(multipleFormValues ?? {}).some(
    (value) => value.different
  );

  return (
    <Flex flexDirection={inline ? "row" : "column"}>
      {someDifferent && (
        <Alert severity="info">
          <p>
            Some fields are different, start editing to override them or leave
            them be to keep the current values.
          </p>
        </Alert>
      )}
      {model?.map((field: FormItem, index) => {
        const visibleOnCreate =
          (isCreate || isDupe) && field.notAvailableOnCreate;
        const hiddenOnCreate = !isCreate && field.onlyAvailableOnCreate;
        const hiddenFromFunction =
          field.hiddenFunction &&
          field.hiddenFunction(formValue[field.name], formValue);
        const hidden = field.hidden;

        const isHidden =
          visibleOnCreate || hiddenOnCreate || hiddenFromFunction || hidden;

        const newValidObj: { [id: string]: any } = validateForm(
          startingFormObject ?? formValue,
          model
        );
        const invalid = !!(newValidObj && newValidObj[field.name]);

        if (isHidden && !invalid) {
          // eslint-disable-next-line array-callback-return
          return;
        }

        const error: IError | undefined =
          field.errorFunction && field.errorFunction(formValue);

        const handleJSONUpload = (
          //@ts-ignore
          e: Event<HTMLInputElement>,
          fieldName: string,
          parentName?: string
        ) => {
          const fileReader = new FileReader();
          fileReader.readAsText(e.target?.files[0], "UTF-8");
          fileReader.onload = (e) => {
            try {
              // @ts-ignore
              const json = JSON.parse(e.target.result);
              const update = { [fieldName]: json };
              updateObj(formValue, update, parentName);
            } catch (e) {
              console.error(e);
              dispatch(
                queueNotification({
                  message: "Invalid JSON file",
                  options: {
                    key: "create_error",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            }
          };
        };

        const hideLabel =
          field.hideLabel ||
          nonRowFieldTypes.includes(field.type) ||
          field.incorporatedLabel ||
          incorporateLabels;

        const vertLabel =
          nonRowFieldTypes.includes(field.type) &&
          field.type !== FieldType.MULTIPLEVIEWS;

        const isDifferent =
          multipleFormValues && multipleFormValues[field.name]?.different;
        return (
          <Box
            key={field.name}
            style={{ width: "100%" }}
            sx={
              inline && model.length === 2
                ? {
                    margin: "0 0.2rem",
                  }
                : inline
                ? {
                    "&:not(:first-of-type):not(:last-child)": {
                      margin: "0 0.2rem",
                    },
                  }
                : {}
            }
          >
            <FormControl style={{ width: "100%" }} required={field.required}>
              <Grid mt={2} container alignItems="center">
                <Grid item xs={hideLabel ? 12 : 4}>
                  {!hideLabel && (
                    <FieldLabel
                      serverName={field.name}
                      displayName={field.display_name}
                    />
                  )}
                </Grid>
                {vertLabel && (
                  <Box mb={1} mt={4}>
                    <FieldLabel
                      serverName={field.name}
                      displayName={field.display_name}
                    />
                  </Box>
                )}

                <Grid item xs={hideLabel ? 12 : 8}>
                  {field.importValueFromJSON && (
                    <Box mt={2}>
                      <input
                        type="file"
                        onChange={(event) =>
                          handleJSONUpload(event, field.name, field.parentField)
                        }
                      />
                    </Box>
                  )}
                  <Box style={{ opacity: isDifferent ? 0.4 : 1 }}>
                    <FormField
                      validObj={validObj && validObj[field.name]}
                      index={index}
                      isCreate={isCreate}
                      field={{ ...field, incorporatedLabel: incorporateLabels }}
                      valueProp={formValue[field.name]}
                      onChange={(update) => {
                        updateObj(formValue, update, field.parentField);
                        if (isDifferent) {
                          setMultipleFormValues &&
                            setMultipleFormValues({
                              ...multipleFormValues,
                              [field.name]: {
                                different: false,
                                value: update[field.name],
                              },
                            });
                        }
                      }}
                      objectId={
                        formValue.object_id ?? parentFormValue?.object_id
                      }
                      formValue={formValue}
                      page={page}
                      error={error}
                      setHasChanged={setHasChanged}
                    />

                    {field.suggestions && (
                      <Suggestions
                        suggestions={field.suggestions}
                        onChange={(newValue) => {
                          const update = { [field.name]: newValue };
                          updateObj(formValue, update, field.parentField);
                        }}
                        valueProp={formValue[field.name]}
                        isValueArray={field.type === FieldType.ARRAY}
                        field={field}
                      />
                    )}

                    {error?.error && (
                      <Alert sx={{ marginY: 2 }} severity="warning">
                        {error?.message}
                      </Alert>
                    )}

                    {field.helperText && (
                      <Box my={1} mx={1}>
                        <small>{field.helperText}</small>
                      </Box>
                    )}

                    {isDifferent && (
                      <Box my={1} mx={1}>
                        <small>Different values</small>
                        <Button
                          onClick={() => {
                            setMultipleFormValues &&
                              setMultipleFormValues({
                                ...multipleFormValues,
                                [field.name]: {
                                  different: false,
                                  value: field.defaultValue,
                                },
                              });
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        );
      })}
    </Flex>
  );
};
