import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { getPageName } from "../features/XpromoCampaigns/components/RenewDialog";
import { CrudKeys, selectIsSaving } from "./CollectionEditor/crudSlice";

interface Props {
  isOpen?: boolean;
  title?: ReactNode;
  confirmContent?: ReactNode;
  denyContent?: ReactNode;
  onClose: () => void;
  onResolve: (resolved: boolean) => void;
  page?: keyof CrudKeys;
  children: ReactNode;
}

export const ConfirmationModal = ({
  children,
  title,
  isOpen,
  onClose,
  onResolve,
  page,
  denyContent,
  confirmContent,
}: Props) => {
  const isSaving = useSelector(selectIsSaving);

  return (
    <Dialog maxWidth="sm" fullWidth open={!!isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => onResolve(false)}>{denyContent || "No"}</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={page && isSaving[getPageName(page)]}
          onClick={() => onResolve(true)}
        >
          {confirmContent || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
