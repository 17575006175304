export const mostMatchedArray = (arrayOfArrays: any, comparisonArray: any) => {
  return arrayOfArrays.reduce(
    (a: any, b: any) => {
      let matches = 0;
      for (let i = 0; i < comparisonArray.length; i++) {
        if (b.includes(comparisonArray[i])) {
          matches++;
        }
      }
      return matches > a.matches ? { array: b, matches: matches } : a;
    },
    { array: [], matches: 0 }
  ).array;
};
