import { useMemo } from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { NavigationRoutes } from "../constants/navigationRoutes";
import { selectSession } from "../features/session/sessionSlice";
import { mergeNavRoutes } from "../layout/mergeNavRoutes";
import { NavigationItem } from "../model/navigation.model";
import { getData } from "./request";
import { useConfig } from "./useConfig";

export function useMenuTrees(suspense?: boolean) {
  const { game_id: gameId } = useSelector(selectSession);
  const isSolitaire = useMemo(() => gameId === "cassolitairehf", [gameId]);

  const { games } = useConfig();

  const currentGameName = (gameId && games && games[gameId])?.replace(
    /\s*\(.*?\)\s*/g,
    ""
  );

  const { data, error, isValidating } = useSWR(
    [Endpoints.MENU_TREES.url, gameId],
    () =>
      getData(Endpoints.MENU_TREES.url, {
        frontend_ids: JSON.stringify([
          "game_admin_core",
          `game_admin_${gameId}`,
        ]),
      }),
    {
      suspense,
    }
  );

  const convertMenuTrees = (trees?: any) => {
    const newTrees: NavigationItem[] = [];

    trees?.forEach((tree: any) => {
      const id = tree.object_id
        ? `/${tree.object_id}`
        : tree.menu_url
            ?.replace(".html", "")
            ?.replace(`/game-admin-${gameId}`, "")
            .split("?")[0];
      const newTree = {
        id,
        name: tree.name,
        path: id,
        exact: true,
        elements: undefined,
        isOldAdmin: true,
        oldAdminURL: tree.menu_url,
        permissions: tree.minimum_required_permissions || {},
        hidden: tree.name === "Account",
      };

      if (tree?.root_node?.child_nodes) {
        // @ts-ignore
        newTree.elements = convertMenuTrees(tree?.root_node?.child_nodes) ?? [];
      }

      if (tree.name !== "{{BLANK}}") {
        newTrees.push(newTree);
      }
    });

    return newTrees;
  };

  const menuTrees =
    data && (data as { [id: string]: any })[Endpoints.MENU_TREES.object_path];

  const convertedMenuTrees = convertMenuTrees(menuTrees);

  const mergedNavRoutes = mergeNavRoutes(
    NavigationRoutes(isSolitaire),
    convertedMenuTrees,
    currentGameName
  );

  return {
    convertedMenuTrees,
    menuTrees,
    isLoading: !data,
    isError: error,
    isValidating,
    mergedNavRoutes,
  };
}
