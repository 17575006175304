import {
  AppBar,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
  Alert,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Autocomplete } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/all";
import { useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import styled from "styled-components";
import {
  FieldType,
  FormItem,
  IError,
  metricValues,
  timeMetrics,
} from "../../../model/crud.model";
import { Endpoint, resourceUrl } from "../../../model/types";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { toReactSelectObject } from "../../../utils/toReactSelectObject";
import { Flex } from "../../Flex";
import CollectionEditor from "../CollectionEditor";
import { CollectionItem, CollectionItemContext } from "../CollectionItem";
import {
  createItem,
  createItems,
  deleteItem,
  deleteItems,
  deployItem,
  updateItem,
  uploadFile,
} from "../crudService";
import { CrudKeys, selectIsSaving } from "../crudSlice";
import { FormContent } from "../FormContent";
import { ButtonField } from "./ButtonField";
import { DatePicker } from "./DatePicker";
import InputArray from "./InputArray";
import { ObjectField } from "./ObjectField";

const StyledTabs = styled(SwipeableViews)`
  .react-swipeable-view-container {
    height: 100% !important;
  }

  .react-swipeable-view-container > div[aria-hidden="false"] {
    height: 100%;
  }

  .react-swipeable-view-container > div[aria-hidden="true"] {
    height: 0;
  }
`;

interface FormFieldProps {
  index: number;
  field: FormItem;
  valueProp?: any;
  onChange: (
    update: { [id: string]: any },
    parentObject?: { [id: string]: any }
  ) => void;
  options?: { [id: string]: string };
  isCreate?: boolean;
  objectId?: any;
  formValue: { [id: string]: any };
  endpoint?: Endpoint;
  page: keyof CrudKeys;
  error?: IError;
  setHasChanged?: (hasChanged: boolean) => void;
  validObj?: { [id: string]: any } | string;
}

export const FormField = ({
  field,
  valueProp,
  onChange,
  isCreate,
  objectId,
  formValue,
  endpoint,
  page,
  error,
  setHasChanged,
  validObj,
}: FormFieldProps) => {
  const [timeMetric, setTimeMetric] = useState<string>(
    field.defaultMetric ? field.defaultMetric : "minutes"
  );

  const isSaving = useSelector(selectIsSaving);

  const { parentIndex } = useContext(CollectionItemContext);

  let value = field.valueFunction ? field.valueFunction(formValue) : valueProp;

  const [valueState, setValueState] = useState<any>([]);

  useEffect(() => {
    return setValueState(
      field.selectOptions?.filter((item: any) => value?.includes(item.value))
    );
  }, [field.selectOptions, value]);

  useEffect(() => {
    // If field is a pseudo field and it does not exist in the formValue
    if (
      field.name.startsWith("pseudo_") &&
      !formValue.hasOwnProperty(field.name)
    ) {
      onChange({ [field.name]: value });
    }

    if (field.type === FieldType.PROBABILITY) {
      setTempValue((value ?? 0) * 100);
    } else {
      setTempValue(value);
    }
    // eslint-disable-next-line
  }, [field.name, isCreate, valueProp, formValue]);

  // If disabled or (is create and disabled on create)
  let isDisabled =
    field.disabled ||
    (isCreate && field.disabledOnCreate) ||
    (field.disableOnUpdate === !isCreate && !!value);

  if (field.disabledFunction) {
    isDisabled = field.disabledFunction(value, formValue);
  }

  const [tempValue, setTempValue] = useState(value);

  const tempOptions: { [id: string]: any } = {};
  if (Array.isArray(field.options)) {
    field.options?.forEach((id: any) =>
      typeof id === "string"
        ? (tempOptions[id] = id)
        : (tempOptions[id.value] = id.label)
    );
  }

  const handleOptionSelected = (selectedOption: any, reason: string): void => {
    if (selectedOption.find((option: any) => option.value === "select-all")) {
      if (valueState.length !== field.selectOptions.length - 1) {
        const filteredVal = field.selectOptions.filter(
          (k: any) => k.value !== "select-all"
        );
        setValueState(filteredVal);

        onChangeFunction({
          [field.name]: filteredVal.map((k: any) => k.value),
        });
      } else {
        setValueState([]);
        onChangeFunction({
          [field.name]: [],
        });
      }
    } else {
      setValueState(selectedOption);
      onChangeFunction({
        [field.name]: selectedOption.map((k: any) => k.value),
      });
    }
  };

  const checkGroup = (group: string) => {
    const groupLength = field.selectOptions?.filter(
      (c: any) => c.group === group
    ).length;
    const selectedGroupLength = valueState?.filter(
      (c: any) => c.group === group
    ).length;

    return (
      groupLength === selectedGroupLength ||
      (field.selectOptions ?? [])?.length - 1 === valueState?.length
    );
  };

  const selectGroup = (group: string) => {
    if (group === "Select All") {
      const val =
        field.selectOptions?.length - 1 === valueState?.length
          ? []
          : field.selectOptions?.filter((k: any) => k.group !== "Select All");
      setValueState(val);

      onChangeFunction({
        [field.name]: val.map((k: any) => k.value),
      });
    } else if (
      valueState.filter((k: any) => k.group === group).length ===
      field.selectOptions?.filter((c: any) => c.group === group).length
    ) {
      setValueState(valueState.filter((i: any) => i.group !== group));
      onChangeFunction({
        [field.name]: valueState
          .filter((i: any) => i.group !== group)
          .map((k: any) => k.value),
      });
    } else {
      const selectedGroup = field.selectOptions?.filter(
        (c: any) => c.group === group
      );

      setValueState([
        ...valueState.filter((k: any) => k.group !== group),
        ...(selectedGroup ?? []),
      ]);
      onChangeFunction({
        [field.name]: [
          ...valueState.filter((k: any) => k.group !== group),
          ...(selectedGroup ?? []).map((k: any) => k.value),
        ],
      });
    }
  };

  // Filter field options
  const filteredOptions =
    field.filterOptions && field.filterValue
      ? field.filterOptions(field.filterValue(formValue))
      : Array.isArray(field.options)
      ? tempOptions
      : field.options;

  const onChangeFunction = (update?: { [id: string]: any } | any) => {
    if (field.onChangeOverride) {
      const newUpdate = field.onChangeOverride(update, formValue);
      onChange({ ...update, ...newUpdate });
    } else {
      onChange(update);
    }
    setHasChanged && setHasChanged(true);
  };

  const [tabValue, setTabValue] = React.useState(error?.error ? 1 : 0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTabValue(index);
  };

  const validMessage =
    typeof validObj === "string" ? validObj : validObj && validObj[field.name];

  switch (field.type) {
    case FieldType.STRING:
      return (
        <TextField
          size="small"
          variant="outlined"
          helperText={validMessage}
          error={!!validMessage}
          fullWidth
          disabled={isDisabled}
          placeholder={`Enter ${field.display_name}...`}
          type="text"
          value={tempValue ?? ""}
          onBlur={() => onChangeFunction({ [field.name]: tempValue })}
          onChange={(event) => setTempValue(event.target.value)}
        />
      );
    case FieldType.NUMBER:
      return (
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          helperText={validMessage}
          error={!!validMessage}
          disabled={isDisabled}
          placeholder={`Enter ${field.display_name}...`}
          type="number"
          value={tempValue ?? ""}
          onBlur={() => {
            if (!isNullOrUndefined(field.max) && tempValue > field.max) {
              onChangeFunction(
                field.useAtomicValue
                  ? field.max || 0
                  : { [field.name]: field.max || 0 }
              );
            } else if (!isNullOrUndefined(field.min) && tempValue < field.min) {
              onChangeFunction(
                field.useAtomicValue
                  ? field.min || 0
                  : { [field.name]: field.min || 0 }
              );
            } else {
              onChangeFunction(
                field.useAtomicValue
                  ? parseFloat(tempValue) || 0
                  : { [field.name]: parseFloat(tempValue) || 0 }
              );
            }
          }}
          onChange={(event) => setTempValue(event.target.value)}
          inputProps={{ min: field.min, max: field.max, step: field.step }}
        />
      );
    case FieldType.PROBABILITY:
      return (
        <Box display="flex" alignItems="center" width="100%">
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            hiddenLabel
            disabled={isDisabled}
            placeholder={`Enter ${field.display_name}...`}
            type="number"
            value={tempValue ?? ""}
            onBlur={() =>
              onChangeFunction(
                field.useAtomicValue
                  ? parseFloat(tempValue) / 100 || 0
                  : { [field.name]: parseFloat(tempValue) / 100 || 0 }
              )
            }
            onChange={(event) => setTempValue(parseInt(event.target.value))}
          />
          <Box mx={1}>%</Box>
        </Box>
      );
    case FieldType.MULTIPLEVIEWS:
      return (
        <>
          <AppBar position="static" color="default">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {field.views?.map((view, index) => (
                <Tab key={index} label={view.title} />
              ))}
            </Tabs>
          </AppBar>
          <StyledTabs
            axis="x"
            index={tabValue}
            onChangeIndex={handleChangeIndex}
            value={tabValue}
            animateHeight
          >
            {field.views?.map((view, index) => (
              <Box key={index}>
                <FormContent
                  formValue={formValue}
                  model={view.fields}
                  setFormValue={onChange}
                  page={page}
                  setHasChanged={setHasChanged}
                />
              </Box>
            ))}
          </StyledTabs>
        </>
      );
    case FieldType.BUTTON_MODAL:
      return (
        <>
          <ButtonField
            onChange={() => onChangeFunction()}
            //@ts-ignore
            options={field.options}
            valueProp={valueProp}
          />

          {field.modal && (
            <field.modal
              setParentFormValue={(formValue: { [id: string]: any }) =>
                onChangeFunction({ status: formValue.status })
              }
            />
          )}
        </>
      );
    case FieldType.TIMEINPUT:
      const currentMetric: number = metricValues[timeMetric];
      const adjustedValue = tempValue / currentMetric;
      return (
        <Flex alignItems="center">
          <Box width="60%" mr={1}>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              hiddenLabel
              helperText={validMessage}
              error={!!validMessage}
              disabled={isDisabled}
              placeholder={`Enter ${field.display_name}...`}
              type="number"
              value={!isNaN(adjustedValue) ? adjustedValue ?? "" : 0}
              onBlur={() =>
                onChangeFunction({
                  [field.name]: parseFloat(tempValue as unknown as string) || 0,
                })
              }
              onChange={(event) =>
                setTempValue(parseInt(event.target.value) * currentMetric)
              }
            />
          </Box>

          <Box display="flex" width="40%" flexGrow={1}>
            <Autocomplete
              fullWidth
              size="small"
              id={field.name}
              options={Object.keys(timeMetrics ?? {}).map((id) =>
                toReactSelectObject(timeMetrics ?? {}, id)
              )}
              value={toReactSelectObject(timeMetrics ?? {}, timeMetric)}
              placeholder={`Select ${field.display_name}`}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option) => option.value}
              renderInput={(params) => (
                <TextField {...params} hiddenLabel variant="outlined" />
              )}
              onChange={(event: any, value?: any) =>
                setTimeMetric(value?.value)
              }
            />
          </Box>
        </Flex>
      );
    case FieldType.PASSWORD:
      return (
        <TextField
          disabled={isDisabled}
          placeholder={field.display_name}
          type="password"
          value={value ?? ""}
          onChange={(event) =>
            onChangeFunction({ [field.name]: event.target.value })
          }
        />
      );
    case FieldType.DATETIME:
      return (
        <DatePicker field={field} value={value} onChange={onChangeFunction} />
      );
    case FieldType.SELECT:
      const labelOptions = [
        {
          label: "Name",
          value: "name",
        },
        { label: "Object ID", value: "object_id" },
      ];

      const valueOption = toReactSelectObject(filteredOptions ?? {}, value);

      return (
        <Flex>
          <Autocomplete
            disabled={isDisabled}
            size="small"
            style={{ width: "100%" }}
            id={field.name}
            getOptionDisabled={(option) =>
              field.isOptionDisabled
                ? field.isOptionDisabled(option.value)
                : option.isDisabled ?? false
            }
            options={Object.keys(filteredOptions ?? {}).map((id) =>
              toReactSelectObject(filteredOptions ?? {}, id)
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                {field.renderOptionOverride
                  ? field.renderOptionOverride(option)
                  : option.label}
              </li>
            )}
            groupBy={(option) => (field.groupBy ? field.groupBy(option) : "")}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={valueOption?.value === null ? null : valueOption}
            placeholder={`Select ${field.display_name}`}
            getOptionLabel={(option) => option.label ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={validMessage}
                error={!!validMessage}
                label={!field.incorporatedLabel ? "" : field.display_name}
                hiddenLabel={!field.incorporatedLabel}
                variant="outlined"
              />
            )}
            onChange={(event: any, value?: any) => {
              onChangeFunction({
                [field.name]: value?.value,
              });
            }}
          />
          {field.changeAutocompleteLabel && (
            <Box ml={1} width="40%">
              <Autocomplete
                disabled={isDisabled}
                size="small"
                id="autocomplete-label-change"
                options={labelOptions}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                value={labelOptions.find(
                  (option) => option.value === field.autoCompleteLabelValue
                )}
                placeholder={`Select ${field.display_name}`}
                getOptionLabel={(option) => option.label ?? ""}
                renderInput={(params) => (
                  <TextField {...params} hiddenLabel variant="outlined" />
                )}
                onChange={(event: any, value?: any) =>
                  field.changeAutocompleteLabel &&
                  field.changeAutocompleteLabel(value?.value)
                }
              />
            </Box>
          )}
        </Flex>
      );
    case FieldType.MULTISELECT:
      return (
        <>
          <Autocomplete
            multiple
            limitTags={field.limitTags}
            id={field.id}
            disabled={field.disabled}
            options={field.selectOptions || []}
            getOptionLabel={(option: any) => option.label}
            isOptionEqualToValue={(option: any, value: any) => {
              return option.value === value.value;
            }}
            onChange={(event, selectedOption, reason) => {
              handleOptionSelected(selectedOption, reason);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  variant="outlined"
                  {...params}
                  label={field.label}
                  placeholder={field.placeholder}
                />
              );
            }}
            renderTags={(value, getTagProps) => {
              const numTags = value.length;
              return (
                <>
                  {value.slice(0, field.limitTags).map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      size={field.chipSize || "medium"}
                      label={option.label}
                    />
                  ))}
                  {numTags > (field.limitTags || 0) &&
                    ` +${numTags - (field.limitTags || 0)} more`}
                </>
              );
            }}
            renderOption={(props, option, { selected }) => {
              const selectAllProps =
                option.value === "select-all"
                  ? {
                      checked:
                        valueState.length === field.selectOptions.length - 1,
                    }
                  : {};
              return (
                <li {...props}>
                  <Checkbox
                    color="primary"
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBoxOutlined fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    key={option.inputValue}
                    {...selectAllProps}
                  />
                  {option?.label}
                </li>
              );
            }}
            groupBy={
              field.needGrouping ? (option: any) => option.group : undefined
            }
            loading={field.loading}
            size={field.selectSize}
            value={valueState ?? []}
            renderGroup={
              field.needGrouping
                ? (params) => (
                    <>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBoxOutlined fontSize="small" />}
                        checked={checkGroup(params.group)}
                        onChange={() => selectGroup(params.group)}
                        key={params.group}
                      />
                      <span>{params.group}</span>
                      <div key={params.key}>
                        {params.group !== "Select All" && params.children}{" "}
                      </div>
                    </>
                  )
                : undefined
            }
          />
        </>
      );
    case FieldType.CHECKBOX:
      return (
        <Checkbox
          checked={value ?? false}
          onChange={(event) =>
            onChangeFunction({ [field.name]: event.target.checked })
          }
        />
      );

    case FieldType.CUSTOM:
      return (
        <div>
          {field.renderOverride
            ? field.renderOverride(
                value,
                onChangeFunction,
                field.name,
                formValue
              )
            : null}
        </div>
      );
    case FieldType.OBJECT:
      return field.keyModel && field.itemModel ? (
        <ObjectField
          setHasChanged={setHasChanged}
          field={field}
          isDisabled={isDisabled}
          isCreate={isCreate}
          value={value ?? {}}
          onChange={(newValue) => {
            onChangeFunction({ [field.name]: newValue });
          }}
          valueModel={field.itemModel}
          keyModel={field.keyModel}
        />
      ) : null;
    case FieldType.ARRAY:
      return (
        <InputArray
          field={field}
          isDisabled={isDisabled}
          inputArray={value ?? []}
          onChange={(newValue) => {
            onChangeFunction({ [field.name]: newValue });
          }}
          model={field.itemModel}
          setHasChanged={setHasChanged}
        />
      );
    case FieldType.FILEUPLOAD:
      const FileType: { [id: string]: string } = {
        "video/mp4": "mp4",
        "image/png": "png",
        "image/jpg": "jpg",
        "image/jpeg": "jpg",
        "image/gif": "gif",
        "text/html": "html5",
      };

      const savingString = `${field.fileUploadUrl
        ?.split("/")[1]
        .replace("/", "")}${objectId}${parentIndex}`;

      // Handles file upload event and updates state
      const handleUpload = async (event: any) => {
        setHasChanged && setHasChanged(true);
        const file = event.target.files[0];
        // Start file upload await url response
        const response = await uploadFile(
          field.fileUploadUrl ?? "",
          file,
          objectId,
          savingString
        );

        //@ts-ignore
        if (response?.status === 200) {
          // Set asset type
          onChange({
            asset_type: FileType[file.type],
            url: response.data.url,
          });
        }
      };

      const url = formValue.video_url_s3
        ? formValue.video_url_s3
        : formValue.url;

      const type = formValue.asset_type;

      // If uploading a file return spinner
      if (isSaving[savingString as keyof CrudKeys]) {
        return <CircularProgress />;
      }

      switch (type) {
        case "html5":
          return (
            <div id="upload-box">
              <Box mb={3}>
                <iframe
                  title="playable"
                  src={`${resourceUrl}${url}`}
                  height="500"
                />
              </Box>
              <Box mb={3}>
                <a
                  href={`${resourceUrl}${url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Open Playable in seperate tab <FaExternalLinkAlt />
                </a>
              </Box>

              <input
                type="file"
                disabled={isDisabled}
                onChange={handleUpload}
              />
            </div>
          );
        case "gif":
        case "png":
        case "jpg":
          return (
            <div id="upload-box">
              <Box mb={1}>
                <img
                  alt={`${resourceUrl}${url}`}
                  style={{ width: "100%" }}
                  src={`${resourceUrl}${url}`}
                />
              </Box>

              <input
                type="file"
                disabled={isDisabled}
                onChange={handleUpload}
              />
            </div>
          );
        case "mp4":
          return (
            <div id="upload-box">
              {url && (
                <Box mb="0.5rem">
                  <video controls>
                    <source src={`${resourceUrl}${url}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              )}

              <input
                type="file"
                disabled={isDisabled}
                onChange={handleUpload}
              />
            </div>
          );
        default:
          return (
            <>
              <input
                type="file"
                disabled={isDisabled || !objectId}
                onChange={handleUpload}
              />
              {!objectId && (
                <Box mt={3}>
                  <Alert severity="warning">
                    You must save this creative before uploading a file
                  </Alert>
                </Box>
              )}
            </>
          );
      }

    case FieldType.COLLECTION:
      if (!endpoint && !field.nestedModel) {
        return null;
      }

      if (field.isSingleton) {
        return (
          <CollectionItem
            useModal={false}
            index={0}
            model={field.collectionModel!}
            obj={value ?? {}}
            key={field.name}
            id={field.name}
            page={page ?? ""}
            selected={[]}
            setSelected={() => {}}
            isSingleton
            parentObject={formValue}
            parentOnChange={onChangeFunction}
          />
        );
      }

      return (
        <>
          {!objectId && field.page === "batches" && (
            <Alert severity="warning">
              You must save this batch before creatives can be added
            </Alert>
          )}
          {field.page && (
            <CollectionEditor
              isSingleton={field.isSingleton}
              nestedCrud
              parentObject={formValue}
              parentField={field.name}
              parentOnChange={onChangeFunction}
              page={field.page}
              response={field.collectionResponse}
              nestedModel={field.nestedModel}
              model={field.collectionModel}
              getDisplayName={field.getDisplayName}
              filterObjectIdArray={
                !field.nestedModel
                  ? field.value
                    ? field.value(formValue)
                    : value
                  : undefined
              }
              endpoint={endpoint}
              deployItem={async (obj: any) => {
                if (field.nestedModel) {
                } else if (endpoint) {
                  try {
                    const responseItem = await deployItem(endpoint, obj);
                    if (!responseItem) {
                      return;
                    }
                    const newArray = [...field.collectionResponse.data];
                    const index = newArray.findIndex(
                      (item) => item.object_id === obj.object_id
                    );
                    newArray[index] = responseItem;
                    field.collectionResponse.mutate(newArray, false);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              createMultipleItems={async (arr: any[]) => {
                if (field.nestedModel) {
                } else if (endpoint) {
                  try {
                    const newResponseItems = await createItems(
                      endpoint,
                      arr,
                      true
                    );

                    // Add creative to array
                    field.collectionResponse.mutate(
                      [
                        ...(field.collectionResponse.data ?? []),
                        ...newResponseItems,
                      ],
                      false
                    );
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              deleteMultipleItems={async (arr: any[]) => {
                if (field.nestedModel) {
                } else if (endpoint) {
                  const creativesInBatch =
                    field.value && field.value(formValue);
                  const newArr = arr.filter((obj) =>
                    creativesInBatch?.includes(obj.object_id)
                  );

                  try {
                    await deleteItems(endpoint, arr);

                    // Remove from array
                    const newArray = [...field.collectionResponse.data];
                    for (const obj of newArr) {
                      const index = newArray.findIndex(
                        (item) => item.object_id === obj.object_id
                      );
                      newArray.splice(index, 1);
                    }

                    field.collectionResponse.mutate(newArray, false);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              onCreate={async (newObject: any) => {
                if (field.nestedModel) {
                  // Update parent object with model
                  onChange &&
                    onChange(
                      {
                        [field.name as string]: [
                          ...formValue[field.name as string],
                          newObject,
                        ],
                      },
                      formValue
                    );
                  setHasChanged && setHasChanged(true);
                } else if (endpoint) {
                  newObject[field.parentField ?? ""] = objectId;
                  try {
                    const responseItem = await createItem(
                      endpoint,
                      newObject,
                      true
                    );

                    // Add creative to array
                    field.collectionResponse.mutate(
                      [...(field.collectionResponse.data ?? []), responseItem],
                      false
                    );
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              onUpdate={async (newObject: any, index?: number) => {
                if (field.nestedModel) {
                  const newArray = [...formValue[field.name as string]];

                  if (!isNullOrUndefined(index) && index !== -1) {
                    newArray[index] = newObject;
                  }

                  onChange &&
                    onChange(
                      {
                        [field.name as string]: newArray,
                      },
                      formValue
                    );
                  setHasChanged && setHasChanged(true);
                } else if (endpoint) {
                  try {
                    const responseItem = await updateItem(
                      endpoint,
                      newObject,
                      true
                    );
                    if (!responseItem) {
                      return;
                    }
                    const newArray = [...field.collectionResponse.data];
                    const index = newArray.findIndex(
                      (item) => item.object_id === newObject.object_id
                    );
                    newArray[index] = responseItem;
                    field.collectionResponse.mutate(newArray, false);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
              onDelete={async (newObject: any, index?: number) => {
                if (field.nestedModel) {
                  const newArray = [...formValue[field.name as string]];

                  if (!isNullOrUndefined(index) && index !== -1) {
                    newArray.splice(index, 1);
                  }

                  onChange &&
                    onChange(
                      {
                        [field.name as string]: newArray,
                      },
                      formValue
                    );
                  setHasChanged && setHasChanged(true);
                } else if (endpoint) {
                  try {
                    await deleteItem(
                      `${endpoint.url}/${newObject.object_id}/delete`
                    );

                    // Remove from array
                    const newArray = [...field.collectionResponse.data];
                    const index = newArray.findIndex(
                      (item) => item.object_id === newObject.object_id
                    );
                    newArray.splice(index, 1);
                    field.collectionResponse.mutate(newArray, false);
                  } catch (e) {
                    console.error(e);
                  }
                }
              }}
            />
          )}
        </>
      );
  }

  return null;
};
