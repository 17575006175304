import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

import { useSelector } from "react-redux";
import { store } from "../../../app/store";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../features/notification/notificationSlice";
import { Form, FormItem } from "../../../model/crud.model";
import { GameId } from "../../../model/sidebar.model";
import { Endpoint } from "../../../model/types";
import { DialogWrapper } from "../../DialogWrapper";
import { CrudKeys, selectIsSaving } from "../crudSlice";
import { validateForm } from "../Fields/validateForm";
import { FormContent } from "../FormContent";
import { AutoModalCloser } from "./AutoModalCloser";
import { getPageName } from "./ConfirmDialog";

interface StatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  page: keyof CrudKeys;
  formValue?: { [id: string]: any };
  baseModel: Form;
  endpoint?: Endpoint;
  createItem?: (obj: any, endpoint?: Endpoint) => void;
  currentGameId?: GameId;
}

export const DuplicateModal = ({
  isOpen,
  onClose,
  page,
  formValue,
  baseModel,
  endpoint,
  createItem,
}: StatusModalProps) => {
  const [newValues, setFormValue] = useState<{ [id: string]: any }>(
    formValue ?? {}
  );

  const fieldsToUnhide = ["game_edition_id", "target_game_id", "ad_type"];

  const model: Form = [];

  baseModel.forEach((field: FormItem) =>
    model.push({
      ...field,
      hidden:
        page === "xpromo_creatives" && fieldsToUnhide.includes(field.name)
          ? false
          : field.hidden,
    })
  );

  const isSaving = useSelector(selectIsSaving);
  const cancelRef = React.createRef<HTMLButtonElement>();
  const isInvalid = Object.keys(validateForm(newValues, model)).length !== 0;

  return (
    <>
      <DialogWrapper isOpen={isOpen} onClose={onClose}>
        <AutoModalCloser onClose={onClose} page={page} />

        <DialogTitle>Duplicate</DialogTitle>

        <DialogContent>
          <p>You can adjust the values below before duplicating.</p>

          <FormContent
            isDupe
            formValue={newValues}
            model={model}
            page={page ?? ""}
            setFormValue={setFormValue}
            validObj={validateForm(newValues, model)}
          />
        </DialogContent>

        <DialogActions>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSaving[getPageName(page)] || isInvalid}
            color="primary"
            variant="contained"
            onClick={() => {
              if (!validateForm(newValues, model)) {
                store.dispatch(
                  queueNotification({
                    message: "Please fill in all required fields",
                    options: {
                      key: "invalid_form",
                      variant: SnackbarVariant.ERROR,
                    },
                  })
                );
              } else {
                const newFormValue: { [id: string]: any } = {};

                Object.keys(formValue ?? {}).forEach((key: string) => {
                  if (!key.includes("pseudo_") && formValue) {
                    newFormValue[key] = newValues[key];
                  }
                });

                if (newFormValue.hasOwnProperty("name")) {
                  newFormValue.name = `${newFormValue.name}`;
                }

                createItem && createItem(newFormValue, endpoint);
              }
            }}
          >
            Duplicate
          </Button>
        </DialogActions>
      </DialogWrapper>
    </>
  );
};
