import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";

import styled from "styled-components";
import {
  isDisabledSelector,
  selectAfterCombineYScrollPosition,
  setAfterCombineYScrollPosition,
} from "../addToAllSlice";
import {
  editModeSelector,
  filterCollapsedSelector,
  searchTermSelector,
  updateSearchTerm,
} from "./Filter/filterSlice";

export const StyledSearch = styled.div<{
  isEditing: boolean;
  isTopOfPage: boolean;
  filterCollapsed?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
  border: 1px solid ${(props) => props.theme.lightTextColor};
  width: ${(props) => (props.isEditing ? "91%" : "98%")};
  position: sticky;
  top: ${(props) => (props.filterCollapsed ? "30px" : "170px")};
  z-index: 15;
  border-radius: 4px;
  padding: 0 10px;
  background-color: ${(props) => props.theme.selectedSettings.backgroundColor};
  transition: box-shadow 0.1s ease-in-out, border 0.1s ease-in-out,
    top 0.2s ease-in-out;
  box-shadow: ${(props) =>
    props.isTopOfPage ? "none" : "2px 4px 15px -4px rgb(0 0 0 / 75%)"};

  input {
    color: ${(props) => props.theme.textColor};
  }

  small {
    color: ${(props) => props.theme.lightTextColor};
    transition: color 0.2s ease-in-out;
  }

  .submit,
  .clear {
    position: absolute;
    right: 5px;
    padding: 5px;
    cursor: pointer;
    color: ${(props) => props.theme.lightTextColor};
    transition: color 0.2s ease-in-out;
    font-size: 1em;

    &:hover {
      color: ${(props) => props.theme.textColor};
    }
  }

  .clear {
    right: 100px;
  }

  &:focus-within {
    border: 1px solid ${(props) => props.theme.textColor};
    small {
      color: ${(props) => props.theme.textColor};
    }
  }

  &:hover {
    border: 1px solid ${(props) => props.theme.textColor};
    small {
      color: ${(props) => props.theme.textColor};
    }
  }
`;

const Search = (props: any) => {
  const [searchTerm, setSearchTerm] = useState("");

  const isEditing = useSelector(editModeSelector);
  const filterCollapsed = useSelector(filterCollapsedSelector);

  const [isTopOfPage, setIsTopOfPage] = useState(true);

  const searchRef = useRef<HTMLInputElement>();

  useEffect(() => {
    // Listen for search bar focus
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "K" && event.ctrlKey) {
        setTimeout(() => searchRef.current?.focus(), 10);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return function cleanup() {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // @ts-ignore
  useEffect(() => {
    window.onscroll = () => {
      window.pageYOffset === 0
        ? setIsTopOfPage(true)
        : isTopOfPage && setIsTopOfPage(false);
    };

    return () => (window.onscroll = null);
  }, [isTopOfPage]);

  const clearSearch = () => {
    setSearchTerm("");
    setTimeout(
      () => window.scrollTo(0, props.afterCombineYScrollPosition),
      200
    );
    props.setAfterCombineYScrollPosition(undefined);
    props.updateSearchTerm("");
  };

  const setSearch = () => {
    props.setAfterCombineYScrollPosition(window.scrollY);
    props.updateSearchTerm(searchTerm);
  };

  return (
    <StyledSearch
      filterCollapsed={filterCollapsed}
      isTopOfPage={isTopOfPage}
      isEditing={isEditing}
    >
      <small>
        <FontAwesomeIcon icon={faSearch} className="mr-2" />
      </small>
      <TextField
        variant="outlined"
        size="small"
        inputRef={searchRef}
        disabled={props.disabled}
        className="px-2 pt-0 pb-0 small outline-none search-textbox"
        value={searchTerm ?? ""}
        sx={{
          width: "100%",
          "& fieldset": { border: "none" },
        }}
        placeholder="Search..."
        onChange={(event) => {
          setSearchTerm(event.target.value);

          if (event.target.value === "") {
            clearSearch();
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearch();
          }

          if (e.key === "Escape") {
            clearSearch();
          }
        }}
      />

      {searchTerm && (
        <>
          <div
            className="clear"
            onClick={() => {
              if (!props.disabled) {
                clearSearch();
              }
            }}
            title="Clear"
          >
            <FontAwesomeIcon icon={faTimesCircle} /> (Esc)
          </div>
          <div className="submit" onClick={() => setSearch()} title="Search">
            Search (↵)
          </div>
        </>
      )}
    </StyledSearch>
  );
};

const mapStateToProps = (state: any) => ({
  afterCombineYScrollPosition: selectAfterCombineYScrollPosition(state),
  searchTerm: searchTermSelector(state),
  disabled: isDisabledSelector(state.addToAllReducer),
});

const mapDispatchToProps = { updateSearchTerm, setAfterCombineYScrollPosition };

export default connect(mapStateToProps, mapDispatchToProps)(Search);
