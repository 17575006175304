import { useLocation } from "react-router-dom";
import { flattenedRoutes } from "../constants/navigationRoutes";

export const useCurrentPage = () => {
  const paths = useLocation()?.pathname.split("/");

  let pathArr: any[] = [];
  paths.forEach((path) => {
    const pathObject = flattenedRoutes.find(
      //@ts-ignore
      (route) => route.current_path === `/${path}`
    );

    if (pathObject) {
      pathArr.push(pathObject);
    }
  });

  return pathArr && pathArr.length !== 0 && pathArr[pathArr.length - 1];
};
