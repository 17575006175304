import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { IAccount } from "../model/game-app.model";
import { getData } from "./request";

type UseAppStoreAccounts = {
  appStoreAccounts: IAccount[];
  isLoading: boolean;
  isError: boolean;
};

export function useAppStoreAccounts() {
  const { data, error } = useSWR(Endpoints.APP_STORE_ACCOUNTS.url, getData);

  const appStoreAccounts: UseAppStoreAccounts = {
    appStoreAccounts:
      data &&
      (data as { [id: string]: any })[Endpoints.APP_STORE_ACCOUNTS.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return appStoreAccounts;
}
