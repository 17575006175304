import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { store } from "../../../app/store";
import {
  CrudKeys,
  selectIsSaving,
} from "../../../components/CollectionEditor/crudSlice";
import { validateForm } from "../../../components/CollectionEditor/Fields/validateForm";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { AutoModalCloser } from "../../../components/CollectionEditor/Modals/AutoModalCloser";
import { FieldType } from "../../../model/crud.model";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";

interface DeleteConfirmDialogProps {
  page: keyof CrudKeys;
  isOpen: boolean;
  onClose: () => void;
  updateItem?: (obj: any) => void;
  formValue: any;
}

export const getPageName = (pageName: string): keyof CrudKeys => {
  if (pageName === "ctest" || pageName === "cpitest") {
    return "campaigns";
  } else {
    return pageName as keyof CrudKeys;
  }
};

export const ExtendDialog = ({
  isOpen,
  onClose,
  updateItem,
  formValue,
  page,
}: DeleteConfirmDialogProps) => {
  const isSaving = useSelector(selectIsSaving);
  const cancelRef = React.createRef<HTMLButtonElement>();

  const [newValues, setObj] = useState<{ [id: string]: any }>({});

  const calculatedEndTime = (formValue.end ?? 0) + (newValues.amount ?? 0);

  const remainingTimeLeft = formValue.end - formValue.budget_updated_on;
  const remainingBudget = formValue.budget - formValue.spend;
  const defaultBudget = remainingBudget / remainingTimeLeft;

  const model = [
    {
      main_field: true,
      display_name: "Amount to add",
      name: "amount",
      type: FieldType.TIMEINPUT,
      required: true,
      defaultValue: null,
    },
    {
      display_name: "The Campaign will end on",
      name: "pseudo_end_time",
      type: FieldType.DATETIME,
      locale: false,
      disabled: true,
      required: true,
      valueFunction: () => calculatedEndTime,
    },
    {
      display_name: "Budget to add",
      name: "extra_budget",
      type: FieldType.NUMBER,
      required: true,
      defaultValue: defaultBudget,
    },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={onClose}>
      <AutoModalCloser onClose={onClose} page={page} />
      <DialogTitle>Extend Campaign?</DialogTitle>

      <DialogContent>
        <p>How long would you like to extend the test?</p>

        <FormContent
          formValue={newValues}
          model={model}
          page={page}
          setFormValue={setObj}
        />
      </DialogContent>
      <DialogActions>
        <Button ref={cancelRef} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaving[getPageName(page)]}
          onClick={() => {
            if (!validateForm(newValues, model)) {
              store.dispatch(
                queueNotification({
                  message: "Please fill in all required fields",
                  options: {
                    key: "invalid_form",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            } else {
              const updatedValues = {
                budget: formValue.budget + newValues.extra_budget,
                end: calculatedEndTime,
              };
              // Extend Test
              updateItem && updateItem({ ...formValue, ...updatedValues });
            }
          }}
        >
          Extend
        </Button>
      </DialogActions>
    </Dialog>
  );
};
