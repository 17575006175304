import _ from "lodash";
import {
  NestedSetting,
  SettingValue,
  ValueType,
} from "../model/versioned-settings.model";

export function removeSettingMetadata(
  setting: Partial<NestedSetting> &
    Pick<NestedSetting, "__value__" & "__type__">
) {
  const clonedSetting = _.cloneDeep(setting);

  let settingValue: SettingValue<any> = clonedSetting.__value__;

  // If collection update children
  if (clonedSetting.__type__ === ValueType.ARRAY) {
    settingValue = [];
    for (const element of clonedSetting.__value__ as SettingValue<ValueType.ARRAY>) {
      settingValue.push(removeSettingMetadata(element));
    }
  } else if (clonedSetting.__type__ === ValueType.OBJECT) {
    const value = settingValue as Record<string, NestedSetting>;
    const objectSettingValue = clonedSetting.__value__ as Record<
      string,
      NestedSetting
    >;
    for (const elementKey in value) {
      value[elementKey] = removeSettingMetadata(objectSettingValue[elementKey]);
    }
  }

  return settingValue;
}
