import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifications, removeNotification } from "./notificationSlice";
import { useSnackbar, SnackbarKey } from "notistack";

const displayed = new Set<SnackbarKey>();

export function Notifier() {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: SnackbarKey) => displayed.add(key);
  const removeDisplayed = (key: SnackbarKey) => displayed.delete(key);

  useEffect(() => {
    notifications.forEach((notification: any) => {
      if (notification.dismissed) {
        closeSnackbar(notification.options.key);
        return;
      }

      if (displayed.has(notification.options.key)) {
        return;
      }

      enqueueSnackbar(notification.message, {
        ...notification.options,
        onClose: (event, reason, key) => {
          if (notification.options.onClose) {
            notification.options.onClose(event, reason, key);
          }
        },
        onExited: (event, key) => {
          dispatch(removeNotification(key));
          removeDisplayed(key);
        },
      });

      storeDisplayed(notification.options.key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
