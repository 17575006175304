import { createAsyncThunk } from "@reduxjs/toolkit";
import { IGameWizardRules } from "./game-wizard.model";
import { getData, postData } from "../../network/request";

export const fetchRules = createAsyncThunk("fetch/rules", async () => {
  const response = await getData("admin/game-wizard/rules");

  return response.game_wizard_rules;
});

export const fetchUsers = createAsyncThunk("fetch/users", async () => {
  const response = await getData("/admin/admin-users");

  return response.users;
});

export const fetchGameVersions = createAsyncThunk(
  "fetch/game_versions",
  async () => {
    const response = await getData("/admin/game-apps");

    return response.game_apps;
  }
);

export const fetchConfig = createAsyncThunk("fetch/config", async () => {
  const response = await getData("/admin/config");

  return response.config;
});

export const fetchModelTemplateGroups = createAsyncThunk(
  "fetch/model_templates_group",
  async () => {
    const response = await getData("/admin/game-wizard/model-templates-group");

    return response.game_wizard_model_templates_groups;
  }
);

export const saveRules = createAsyncThunk(
  "save/rules",
  async (data?: IGameWizardRules) => {
    if (!data) {
      return;
    }

    try {
      const response = await postData("admin/game-wizard/rules", data);

      return response.game_wizard_rules;
    } catch (e) {
      return false;
    }
  }
);
