import styled from "styled-components";
import moment from "moment";
import React from "react";

const StyledCopyright = styled.small`
  a {
    color: #6e9ce4;
  }
`;

export function Copyright() {
  return (
    <StyledCopyright>
      {moment().format("YYYY")} ©{" "}
      <a href="https://kwalee.com" target="_blank" rel="noopener noreferrer">
        Kwalee Ltd
      </a>
    </StyledCopyright>
  );
}
