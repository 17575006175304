import createConfigSlice from "./createConfigSlice";
import { RootState } from "../../app/store";

const clientConfigSlice = createConfigSlice("clientConfig");

export const selectExpandedSettings = (state: RootState) =>
  state.clientConfigReducer.expandedSettings;

export const selectCurrentId = (state: RootState) =>
  state.clientConfigReducer.currentId;

export const getConfigData = (state: RootState) =>
  state.clientConfigReducer.configData;

export const {
  collapseSetting,
  expandSetting,
  saveConfigData,
  updateConfig,
  updateConfigValue,
  updateConfigValueType,
  addSubChild,
  deleteConfigItem,
  addChild,
  saveConfigItems,
} = clientConfigSlice.actions;
export default clientConfigSlice.reducer;
