import { FieldType, Form } from "../../../model/crud.model";
import { CountryConfig } from "../../../model/versioned-settings.model";
import { useConfig } from "../../../network/useConfig";
import { useGameConfig } from "../../../network/useGameConfig";
import { RewardsModel } from "../Tasks/TasksModel";

export const ObjectivesModel = () => {
  const { config } = useConfig();
  const allCountries = config?.countries.filter(
    (country: CountryConfig) => country.value !== "DEFAULT"
  );
  const defaultCountry = { label: "Default", value: "" };
  const countries = [...(allCountries ?? []), defaultCountry];

  const { gameConfig } = useGameConfig();
  const objectiveTypes = gameConfig?.objective_type_id_enum;

  const model: Form = [
    {
      display_name: "Name (Localised)",
      name: "name",
      type: FieldType.OBJECT,
      isLocalised: true,
      main_field: true,
      itemModel: [
        {
          display_name: "value",
          name: "value",
          type: FieldType.STRING,
          disableOnUpdate: true,
        },
      ],
      keyModel: {
        name: "country",
        display_name: "Country",
        type: FieldType.SELECT,
        options: countries,
        defaultValue: "",
      },
      defaultValue: {},
    },
    {
      display_name: "Description (Localised)",
      name: "description",
      type: FieldType.OBJECT,
      isLocalised: true,
      itemModel: [
        {
          display_name: "value",
          name: "value",
          type: FieldType.STRING,
        },
      ],
      keyModel: {
        name: "country",
        display_name: "Country",
        type: FieldType.SELECT,
        options: countries,
        defaultValue: "",
      },
      defaultValue: {},
    },
    {
      display_name: "Reward",
      name: "reward",
      type: FieldType.COLLECTION,
      isSingleton: true,
      required: true,
      nestedModel: true,
      collectionModel: RewardsModel("reward"),
      page: "objectives",
      defaultValue: {
        reward_type: undefined,
        reward_resource_code: undefined,
        reward_value: undefined,
      },
    },
    {
      display_name: "Target Value",
      name: "objective_target_value",
      type: FieldType.NUMBER,
    },
    {
      display_name: "Type",
      name: "objective_type",
      type: FieldType.SELECT,
      options: objectiveTypes,
    },
  ];

  return model;
};
