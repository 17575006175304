import SettingParts from "./SettingParts";
import { DifferenceType } from "./DifferenceType";

export function hasBeenModified(
  type?: { [key in keyof typeof SettingParts]: DifferenceType }
) {
  if (!type) {
    return false;
  }

  return Object.values(type).some(
    (value) => value !== DifferenceType.UNCHANGED
  );
}
