import {
  faCaretRight,
  faClone,
  faCompressAlt,
  faEdit,
  faEllipsisV,
  faExpandAlt,
  faPlus,
  faStickyNote,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandMore, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import classNames from "classnames";
import stringify from "json-stable-stringify";
import _ from "lodash";
import md5 from "md5";
import moment from "moment";
import { odiffResult } from "odiff";
import React, { Component } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import {
  FaClipboard,
  FaEdit,
  FaInfoCircle,
  FaNotEqual,
  FaPlus,
} from "react-icons/all";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { store } from "../../../../app/store";
import { ConfirmationModal } from "../../../../components/ConfirmationModal";
import { DescriptionField } from "../../../../components/DescriptionField";
import { Flex } from "../../../../components/Flex";
import { StyledMenu } from "../../../../components/StyledMenu/StyledMenu";
import { StyledMenuItem } from "../../../../components/StyledMenu/StyledMenuItem";
import {
  IPath,
  NestedSetting,
  ReactSelectArray,
  serverTimeFormat,
  TooltipState,
  ValueType,
  ValueTypeColors,
  VersionedSettingss,
} from "../../../../model/versioned-settings.model";
import BiMap from "../../../../utils/BiMap";
import { checkIfCollection } from "../../../../utils/checkIfCollection";
import { getSettingPath } from "../../../../utils/getSettingPath";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import { keyAlreadyExists } from "../../../../utils/keyAlreadyExists";
import { keys } from "../../../../utils/keys";
import { mostMatchedArray } from "../../../../utils/mostMatchedArray";
import { removeDescAndTag } from "../../../../utils/removeDescAndTag";
import { reorder } from "../../../../utils/reorder";
import { updateModified } from "../../../../utils/updateModified";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../notification/notificationSlice";
import { selectSession } from "../../../session/sessionSlice";
import {
  countriesSelector,
  removeChangeByIndex,
  selectBeforeSearchExpandedSettings,
  selectChanges,
  selectedSettingsObjectsSelector,
  selectExpandedSettings,
  updateBeforeSearchExpandedSettings,
  updateExpandedSettings,
} from "../../addToAllSlice";
import CopyJSONToClipboard from "./CopyJSONToClipboard";
import {
  currentJanusSettingSelector,
  selectCurrentJanusSetting,
  UPDATE_JANUS_SETTING,
  SET_SELECTOR,
  getSelectedCountry,
  selectCountrySetting,
} from "../../../JanusSettings/JanusSettingsSlice";
import {
  IJanusSetting,
  ISettingsConfig,
} from "../../../JanusSettings/JanusSetting";
import DownloadCSVButton from "./DownloadCSVButton";
import { isSettingValid } from "./isSettingValid";

const COLLAPSE_DELAY = 300;

interface Props {
  id?: string;
  name: string;
  setting: NestedSetting;
  setTooltip: (state?: TooltipState) => void;
  updateSettings: (setting?: NestedSetting) => void;
  isVisible?: boolean;
  isEditing?: boolean;
  parentType?: ValueType;
  onKeyChange?: (key: string) => void;
  addSetting?: (
    amount: number,
    setting?: NestedSetting,
    settingName?: string
  ) => void;
  ref?: React.RefObject<HTMLElement>;
  dragging?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  parentKeys?: (string | number)[];
  topLevel?: boolean;
  toggleExpand?: (expanded?: boolean) => void;
  isExpanded?: boolean;
  searchSettingsObject?: any;
  turnOnEditing: () => void;
  searchTerm?: string;
  hideModifiedText?: boolean;
  hideTooltips?: boolean;
  hideDefaultSettings?: boolean;
  editMultiple?: (setting: any) => void;
  selectedSettingsObjects?: VersionedSettingss;
  isParentDifferent?: boolean;
  countries?: ReactSelectArray;
  setDifferencesTooltip?: (state?: TooltipState) => void;
  differencesTooltip?: TooltipState;
  readOnly?: boolean;
  expandedSettings: { [id: string]: any };
  beforeSearchExpandedSettings: { [id: string]: any };
  updateExpandedSettings: (newExpandedObject: { [id: string]: any }) => void;
  updateBeforeSearchExpandedSettings: (newExpandedObject: {
    [id: string]: any;
  }) => void;
  settingPath?: IPath[];
  abTestedSettings?: { [id: string]: any };
  inTooltip?: boolean;
  parentValues?: Record<string, any>;
  currentLevel?: number;
  changes?: odiffResult[];
  removeChangeByIndex: (indexToRemove: number) => void;
  userId?: string;
  docsSettings?: Record<string, string>;
  currentJanusSetting: string;
  currentJanusSettingObject: IJanusSetting;
  getSelectedCountry: string;
  UPDATE_JANUS_SETTING: (obj: { id: string; [id: string]: any }) => void;
  SET_SELECTOR: (obj: { object_id?: string; isOpen: boolean }) => void;
  selectCountrySetting: string;
}

interface State {
  areChildrenVisible?: boolean;
  inputHeight?: any;
  newSettingName?: string;
  confirmDeleteModalOpen?: boolean;
  nestedSettingsOrder: string[];
  settingName: string;
  settingTag: string;
  settingValue: any;
  newSettingAmount: number;
  duplicateSettingAmount: number;
  expanded: string[];
  showDifferencesTooltip?: boolean;
  anchorEl: null | HTMLElement;
  anchorElBadge: null | HTMLElement;
}

class Setting extends Component<Props, State> {
  state!: State;
  private newSettingNumber = 1;
  private keyToIDMap = new BiMap<string, string>();

  private nameInputRef = React.createRef<HTMLInputElement>();
  private nameTextRef = React.createRef<HTMLDivElement>();

  private tagTextRef = React.createRef<HTMLDivElement>();

  private valueInputRef = React.createRef<HTMLInputElement>();

  private notEqualChipRef = React.createRef<HTMLButtonElement>();

  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      areChildrenVisible: props.isExpanded,
      nestedSettingsOrder: [],
      settingName: "",
      settingTag: "",
      settingValue: undefined,
      newSettingAmount: 1,
      duplicateSettingAmount: 1,
      expanded: [],
      anchorEl: null,
      anchorElBadge: null,
    };
  }

  componentDidMount(): void {
    this.setTextboxValues();
    this.generateUniqueIDs();

    let expanded: string[] = [];
    if (checkIfCollection(this.props.setting)) {
      expanded = Object.keys(this.props.setting.__value__!)
        .filter(
          (id) => !checkIfCollection((this.props.setting.__value__ as any)[id])
        )
        .map((id) => {
          return this.keyToIDMap.getKey(id)!;
        });
      this.setState({ expanded });
    }
    this.sortKeys();

    const isMatch =
      this.props.searchSettingsObject &&
      (this.props.searchSettingsObject.__parent_match__ ||
        this.props.searchSettingsObject.__match__);

    const path = this.props.settingPath?.map((pathObj) => pathObj.node_key);
    const previouslyExpanded = _.has(this.props.expandedSettings, path ?? []);

    if (isMatch || (previouslyExpanded && !this.props.readOnly)) {
      this.toggleExpand(true);
    }

    document.addEventListener("wheel", function () {
      //@ts-ignore
      if (document.activeElement?.type === "number") {
        //@ts-ignore
        document.activeElement?.blur();
      }
    });
  }

  componentWillUnmount() {
    // Update value before unmount if needed
    if (this.state.settingValue !== this.props.setting?.__value__) {
      this.validateSettingValue();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.dragging) {
      this.toggleExpand(false);
    }

    if (prevProps.name !== this.props.name) {
      this.setState({ settingName: this.props.name });
    }

    if (prevProps.setting?.__tag__ !== this.props.setting?.__tag__) {
      this.setState({ settingTag: this.props.setting?.__tag__ });
    }

    if (prevProps.setting?.__value__ !== this.props.setting?.__value__) {
      this.setState({ settingValue: this.props.setting?.__value__ });
    }

    if (prevProps.isExpanded !== this.props.isExpanded) {
      if (this.props.isExpanded) {
        this.setState({ areChildrenVisible: true });
      } else {
        setTimeout(
          () => this.setState({ areChildrenVisible: false }),
          COLLAPSE_DELAY
        );
      }
    }

    if (
      prevProps.searchSettingsObject !== this.props.searchSettingsObject &&
      this.props.searchSettingsObject &&
      (this.props.searchSettingsObject.__parent_match__ ||
        this.props.searchSettingsObject.__match__)
    ) {
      this.toggleExpand(true);
    }

    const searchCleared =
      this.props.searchSettingsObject === undefined &&
      prevProps.searchSettingsObject !== undefined;

    // If a searchterm is present from undefined
    if (
      prevProps.searchTerm === undefined &&
      this.props.searchTerm !== undefined
    ) {
      // Save copy of toggleExpand object to beforeSearchExpandedSettings
      this.props.updateBeforeSearchExpandedSettings(
        this.props.expandedSettings
      );
    }

    // If a searchterm is undefined from present
    if (
      prevProps.searchTerm !== undefined &&
      this.props.searchTerm === undefined
    ) {
      // Restore of toggleExpand object from beforeSearchExpandedSettings
      this.props.updateExpandedSettings(
        this.props.beforeSearchExpandedSettings
      );
    }

    // If this setting is not in the previous toggle expand before the search then close it
    const settingIsInExpandedSettings = !!_.get(
      this.props.expandedSettings,
      this.getSettingPath(this.props.settingPath) ?? []
    );

    if (
      searchCleared &&
      !settingIsInExpandedSettings &&
      this.props.isExpanded
    ) {
      this.toggleExpand(false);
    }
  }

  getSettingPath(settingPath?: IPath[]) {
    return settingPath?.map((pathObj) => pathObj.node_key);
  }

  setTextboxValues() {
    const { setting, name } = this.props;

    this.setState({
      settingName: name,
      settingTag: setting?.__tag__,
      settingValue: setting?.__value__,
    });
  }

  generateUniqueIDs() {
    const { setting } = this.props;
    if (!setting) {
      return;
    }

    // Generate uuid mapping
    if (
      setting.__type__ === ValueType.OBJECT ||
      setting.__type__ === ValueType.ARRAY
    ) {
      const keyToIDMap = new BiMap<string, string>();
      keys(
        (setting as NestedSetting<ValueType.OBJECT | ValueType.ARRAY>).__value__
      ).forEach((key) => {
        // @ts-ignore
        keyToIDMap.set(uuidv4(), key.toString());
      });

      if (this.state) {
        const prevMap = this.keyToIDMap;

        this.setState((prevState) => {
          const expanded: string[] = [];
          for (const id of prevState.expanded) {
            let key = prevMap.get(id);
            if (!key) {
              continue;
            }

            key = keyToIDMap.getKey(key);
            if (!key) {
              continue;
            }

            expanded.push(key);
          }

          return { expanded };
        });
      }

      this.keyToIDMap = keyToIDMap;
    }
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  sortKeys() {
    if (!this.props) {
      return;
    }

    const { setting } = this.props;

    if (!setting) {
      return;
    }

    if (
      !(setting as NestedSetting<ValueType.ARRAY | ValueType.OBJECT>).__value__
    ) {
      return;
    }
    let nestedSettingsOrder: string[] = Object.keys(
      (setting as NestedSetting<ValueType.ARRAY | ValueType.OBJECT>).__value__
    );

    if (setting.__type__ === ValueType.OBJECT) {
      nestedSettingsOrder = nestedSettingsOrder.sort((a, b) =>
        a.localeCompare(b)
      );
    }

    nestedSettingsOrder = nestedSettingsOrder.map(
      (key) => this.keyToIDMap.getKey(key)!
    );

    this.setState({ nestedSettingsOrder });
  }

  toggleExpand(expanded?: boolean) {
    const isObject = this.props.setting.__type__ === ValueType.OBJECT;
    const isArray = this.props.setting.__type__ === ValueType.ARRAY;
    const isCollection = isObject || isArray;

    if (!this.props.toggleExpand || !isCollection) {
      return;
    }

    // Add current setting path to expanded settings object
    const newExpandedSettings = {
      ...this.props.expandedSettings,
    };
    const path = this.getSettingPath(this.props.settingPath);

    if (path) {
      const remainingPath = newExpandedSettings[path[0]];

      if (this.props.isExpanded) {
        _.unset(newExpandedSettings, path);
      } else {
        _.set(
          newExpandedSettings,
          path,
          remainingPath ? remainingPath : expanded ?? true
        );
      }
    }

    if (
      !_.isEqual(newExpandedSettings, this.props.expandedSettings) &&
      !this.props.readOnly
    ) {
      this.props.updateExpandedSettings(newExpandedSettings);
    }

    this.props.toggleExpand(expanded);
  }

  expandInParentSettingIdMap(isExpanded: boolean, key: string) {
    this.setState((prevState) => {
      const expanded = [...prevState.expanded];

      if (isNullOrUndefined(isExpanded)) {
        isExpanded = !expanded.includes(key);
      }

      if (isExpanded && expanded.includes(key)) {
        return { expanded };
      }

      if (isExpanded) {
        expanded.push(key);
      } else {
        const index = expanded.indexOf(key);
        expanded.splice(index, 1);
      }

      return { expanded };
    });
  }

  expandDirectChildren() {
    if (!this.props.isExpanded) {
      this.toggleExpand(true);
    }

    let expanded: string[] = [];
    if (checkIfCollection(this.props.setting)) {
      expanded = Object.keys(this.props.setting.__value__!).map((id) => {
        return this.keyToIDMap.getKey(id)!;
      });
      this.setState({ expanded });
    }
  }

  collapseDirectChildren() {
    this.setState({ expanded: [] });
  }

  async addSetting(
    amount: number,
    setting?: NestedSetting,
    settingName?: string
  ) {
    const { userId } = this.props;

    if (!amount || !this.state.newSettingAmount) {
      amount = 1;
    }

    if (amount > 100) {
      store.dispatch(
        queueNotification({
          message: "You can only add a maximum of 100 settings at one time",
          options: {
            key: `exceeded_max_add`,
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      return;
    }

    let value: NestedSetting<ValueType.ARRAY | ValueType.OBJECT>["__value__"];
    let newIDs: string[] = [];

    switch (this.props.setting.__type__) {
      case ValueType.OBJECT:
        value = {
          ...(this.props.setting as NestedSetting<ValueType.OBJECT>).__value__,
        };
        break;
      case ValueType.ARRAY:
        value = [
          ...(this.props.setting as NestedSetting<ValueType.ARRAY>).__value__,
        ];
        break;
      default:
        break;
    }

    let childKey = "";

    for (let i = 0; i < amount; i++) {
      let newSetting: NestedSetting;
      let keyName: string;

      newSetting = {
        __description__: "No Description",
        __user__: userId ?? "unknown_user",
        __value__: "",
        __tag__: "",
        __type__: ValueType.STRING,
        __modified__: moment().format(serverTimeFormat),
      };

      if (setting) {
        newSetting = setting;
      }

      if (settingName) {
        keyName = settingName + "(Copy)";
      } else {
        keyName = "newSetting";
      }

      // Create new setting name
      let id: string;
      let key: string | number;

      switch (this.props.setting.__type__) {
        case ValueType.OBJECT:
          // If key already exists
          this.newSettingNumber = 1;
          id = uuidv4();
          key = keyName + (this.newSettingNumber++).toString();
          for (const currentKey of Array.from(this.keyToIDMap.values())) {
            if (currentKey.includes(keyName)) {
              key = keyName + (this.newSettingNumber++).toString();
            }
          }

          value = {
            // @ts-ignore
            ...value,
            [key]: newSetting,
          };
          break;
        case ValueType.ARRAY:
          id = uuidv4();
          key = (this.props.setting as NestedSetting<ValueType.ARRAY>).__value__
            .length;
          // @ts-ignore
          value = [...value, newSetting];
          break;
        default:
          return;
      }
      this.keyToIDMap.set(id, key.toString());
      newIDs.push(id);

      if (i === amount - 1) {
        //@ts-ignore
        childKey = key;
      }
    }

    this.updateSetting(
      {
        // @ts-ignore
        __value__: value,
      },
      childKey
    );

    this.refreshOrder((prevOrder) => {
      switch (this.props.setting.__type__) {
        case ValueType.OBJECT:
          return newIDs.concat(prevOrder);
        case ValueType.ARRAY:
          return prevOrder.concat(newIDs);
        default:
          return [];
      }
    }).then(() => {
      this.toggleExpand(true);

      setTimeout(() => {
        const elem = document.getElementById(newIDs[0]);
        if (elem) {
          elem.scrollIntoView({ block: "center" });
        }
      }, 200);

      // Highlight new elements
      for (const id of newIDs) {
        const elem = document.getElementById(`tooltip-${id}`);

        if (elem) {
          elem.classList.add("new-setting");
        }
      }

      if (!setting || !checkIfCollection(setting)) {
        newIDs = newIDs.filter((id) => !this.state.expanded.includes(id));
        this.setState({ expanded: [...this.state.expanded, ...newIDs] });
      }
    });
  }

  duplicateSetting(
    amount: number,
    setting: NestedSetting,
    settingName: string
  ) {
    const newSetting = updateModified(removeDescAndTag(setting));

    this.props.addSetting!(amount, newSetting, settingName);
  }

  deleteSetting() {
    this.props.setTooltip();
    // No setting parameter provided to delete setting on level higher
    this.props.updateSettings();
  }

  updateSettings(
    value: NestedSetting<ValueType> | undefined,
    key: number | string
  ) {
    const isObject = this.props.setting.__type__ === ValueType.OBJECT;
    const isArray = this.props.setting.__type__ === ValueType.ARRAY;

    if (value === undefined) {
      const id = this.keyToIDMap.getKey(key.toString());

      this.refreshOrder((prevOrder) => prevOrder.filter((uuid) => uuid !== id));
    }

    if (isObject) {
      const newVal: any = {
        ...(this.props.setting.__value__ as {}),
      };
      if (value === undefined) {
        delete newVal[key];
      } else {
        newVal[key] = value;
      }
      this.updateSetting(
        {
          __value__: newVal,
        },
        undefined,
        undefined
      );
    } else if (isArray) {
      const arr: any[] = [...(this.props.setting.__value__ as [])];
      if (value === undefined) {
        arr.splice(key as number, 1);
      } else {
        arr[key as number] = value;
      }

      this.updateSetting(
        {
          __value__: arr,
        },
        undefined,
        undefined
      );
    }
  }

  updateSetting(
    setting: Partial<NestedSetting>,
    childKey?: string,
    oldChildKey?: string
  ) {
    const { userId } = this.props;
    // Generate new value hash at each level
    const valuehash = md5(
      stringify(
        {
          __type__: setting.__type__,
          __value__: setting.__value__,
          __tag__: setting.__tag__,
          __description__: setting.__description__,
        },
        { space: 2 }
      )
    );

    const newSetting = {
      ...this.props.setting,
      ...setting,
      __modified__: moment().format(serverTimeFormat),
      __user__: userId ?? "unknown_user",
      __valuehash__: valuehash,
    };

    if (isSettingValid(newSetting)) {
      this.props.updateSettings(newSetting);
    }
  }

  updateSettingValue(newValue: string | number) {
    if (this.props.setting.__value__ === newValue) {
      return;
    }

    this.updateSetting({
      __value__: newValue,
    });
  }

  updateSettingTag(newTag: string) {
    if (this.props.setting.__tag__ === newTag) {
      return;
    }

    this.updateSetting({
      __tag__: newTag,
    });
  }

  updateSettingKey(key: string, newKey: string) {
    if (key === newKey) {
      return;
    }

    // Check if key already exists
    const setting = this.props.setting as NestedSetting<ValueType.OBJECT>;
    const newValue = { ...setting.__value__ };
    const value = newValue[key];

    delete newValue[key];

    newValue[newKey] = value;

    const id = this.keyToIDMap.getKey(key);
    this.keyToIDMap.set(id!, newKey);

    this.updateSetting(
      {
        __value__: newValue,
      },
      newKey,
      key
    );
  }

  validateSettingName() {
    if (
      this.props.parentKeys &&
      keyAlreadyExists(
        this.props.name,
        this.state.settingName,
        this.props.parentKeys
      )
    ) {
      // Check if setting path is unset or rm in changes array
      const pathLength = this.props.settingPath?.length ?? 0;
      const pathNode: IPath = {
        node_type: "dict",
        node_key: this.state.settingName,
      };

      const clonedPath: IPath[] | undefined = _.cloneDeep(
        this.props.settingPath
      );
      const originalPath: IPath[] | undefined = _.cloneDeep(
        this.props.settingPath
      );

      clonedPath?.splice(pathLength - 1, 1, pathNode);

      const proposedSettingPath: IPath[] = (
        [
          {
            node_type: "dict",
            node_key: "__value__",
          },
        ] as IPath[]
      ).concat(
        ...(clonedPath?.map((segment, index) =>
          index === clonedPath?.length - 1
            ? [segment]
            : [
                segment,
                {
                  node_type: "dict",
                  node_key: "__value__",
                } as IPath,
              ]
        ) ?? [])
      );

      const originalSettingPath: IPath[] = (
        [
          {
            node_type: "dict",
            node_key: "__value__",
          },
        ] as IPath[]
      ).concat(
        ...(originalPath?.map((segment, index) =>
          index === originalPath?.length - 1
            ? [segment]
            : [
                segment,
                {
                  node_type: "dict",
                  node_key: "__value__",
                } as IPath,
              ]
        ) ?? [])
      );

      const settingChanges = this.props.changes?.filter(
        (change) =>
          change.type === "unset" &&
          change.path.join(",") ===
            getSettingPath(proposedSettingPath)?.join(",")
      );

      // If the unset array is not zero then allow edit
      if (!!settingChanges?.length) {
        // Update setting name from parent
        if (this.props.onKeyChange) {
          this.props.onKeyChange(this.state.settingName);
        }

        // Remove original set of newSetting change from changes array
        const indexToRemove = this.props.changes?.findIndex(
          (change) =>
            change.path.join(",") ===
            getSettingPath(originalSettingPath)?.join(",")
        );

        if (!isNullOrUndefined(indexToRemove)) {
          this.props.removeChangeByIndex(indexToRemove);
        }
      } else {
        store.dispatch(
          queueNotification({
            message: "Setting name already exists",
            options: {
              key: `setting_name_already_exists`,
              variant: SnackbarVariant.ERROR,
            },
          })
        );
        this.setState({ settingName: this.props.name });
      }
    } else if (!this.state.settingName.trim().length) {
      store.dispatch(
        queueNotification({
          message: "Setting name cannot be empty",
          options: {
            key: `setting_name_empty`,
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      // Reset name to original key
      this.setState({ settingName: this.props.name });

      return;
    } else {
      // Update setting name from parent
      if (this.props.onKeyChange) {
        this.props.onKeyChange(this.state.settingName);
      }
    }
  }

  validateSettingValue() {
    if (
      this.props.setting.__type__ === ValueType.INT &&
      !Number.isInteger(parseFloat(this.state.settingValue))
    ) {
      store.dispatch(
        queueNotification({
          message:
            "Integers must be whole numbers, use the double type for floating point numbers",
          options: {
            key: `integer_validation_error`,
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      this.setState({ settingValue: this.props.setting.__value__ });
    } else if (this.props.setting.__type__ === ValueType.INT) {
      this.updateSettingValue(parseInt(this.state.settingValue));
    } else if (this.props.setting.__type__ === ValueType.DOUBLE) {
      this.updateSettingValue(parseFloat(this.state.settingValue));
    } else {
      this.updateSettingValue(this.state.settingValue);
    }
  }

  async changeType(type: ValueType) {
    this.newSettingNumber = 1;

    if (type === this.props.setting.__type__) {
      return;
    }

    // Add description/tag etc to empty object
    const value = this.getNewSettingInitialValue(type);

    if (value === undefined) {
      return;
    }

    await this.updateSetting({
      __value__: value,
      __type__: type,
    });

    // // Automatically add first element if array or object
    // if (type === ValueType.OBJECT || type === ValueType.ARRAY) {
    //   this.addSetting(1);
    // }

    // Generate and sort ids for new children
    this.generateUniqueIDs();
    this.sortKeys();

    // Auto expand setting
    this.toggleExpand(true);
  }

  getNewSettingInitialValue(type: ValueType) {
    let value;

    switch (type) {
      case ValueType.ARRAY:
        value = [];
        break;
      case ValueType.BOOLEAN:
        value = false;
        break;
      case ValueType.INT:
        value = 0;
        break;
      case ValueType.DOUBLE:
        value = 0.0;
        break;
      case ValueType.NULL:
        value = null;
        break;
      case ValueType.OBJECT:
        value = {};
        break;
      case ValueType.STRING:
        value = "";
        break;
      default:
        return;
    }

    return value;
  }

  onSettingDragEnd(result: DropResult) {
    // Dropped outside list
    if (!result.destination) {
      return;
    }

    this.updateSetting({
      __value__: reorder(
        (this.props.setting as NestedSetting<ValueType.ARRAY>).__value__,
        result.source.index,
        result.destination.index
      ),
    });

    this.refreshOrder((prevOrder) =>
      reorder(prevOrder, result.source.index, result.destination!.index)
    );
  }

  refreshOrder(orderProcessor: (prevOrder: string[]) => string[]) {
    return new Promise((resolve) => {
      this.setState(
        (prevState) => {
          const nestedSettingOrder = orderProcessor(
            prevState.nestedSettingsOrder
          );
          const isArray = this.props.setting.__type__ === ValueType.ARRAY;

          nestedSettingOrder.forEach((id, index) => {
            const settingName = this.keyToIDMap.get(id)!;
            this.keyToIDMap.set(id, isArray ? index.toString() : settingName);
          });

          return {
            nestedSettingsOrder: nestedSettingOrder,
          };
        },
        () => resolve(void 0)
      );
    });
  }

  openDeleteModal = () => {
    this.setState({ confirmDeleteModalOpen: true });
  };

  generateHighlightedTitle(searchTerm: string, title: string) {
    const parts = title.split(new RegExp(`(${searchTerm})`, "gi"));

    return (
      <>
        {parts.map((part, index) => (
          <span
            key={index}
            className={classNames({
              highlight: part.toLowerCase() === searchTerm.toLowerCase(),
            })}
          >
            {part}
          </span>
        ))}
      </>
    );
  }

  async copyValueToClipboard() {
    try {
      await navigator.clipboard.writeText(
        this.props.setting.__value__!.toString()
      );

      store.dispatch(
        queueNotification({
          message: "Copied to clipboard",
          options: {
            key: "copied_to_clipboard",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  existsOnAllSettings() {
    const { setting } = this.props;
    const settingsValues = Object.values(setting.values ?? {});

    return !settingsValues.includes(null);
  }

  render() {
    const { id, setting, isEditing, topLevel } = this.props;
    const { confirmDeleteModalOpen } = this.state;

    if (!setting) {
      return null;
    }

    if (
      this.props.searchSettingsObject &&
      !(
        this.props.searchSettingsObject.__match__ ||
        this.props.searchSettingsObject.__parent_match__ ||
        this.props.searchSettingsObject.__child_match__
      ) &&
      !topLevel
    ) {
      return null;
    }

    return (
      <>
        {isEditing && topLevel && (
          <Button
            className="top-level-add-button"
            variant="outlined"
            onClick={() => this.addSetting(1)}
            startIcon={<FaPlus />}
            size="small"
          >
            Add
          </Button>
        )}
        <div
          id={id}
          className={`expandable-setting expandable-setting-${setting.__type__}`}
        >
          {this.renderHeader()}
          {this.renderCollapsibleValue()}
        </div>
        <ConfirmationModal
          isOpen={confirmDeleteModalOpen}
          title="Delete Setting?"
          confirmContent="Delete"
          denyContent="Cancel"
          onResolve={(resolved) => {
            if (resolved) {
              this.deleteSetting();
            }
            this.setState({ confirmDeleteModalOpen: false });
          }}
          onClose={() => this.setState({ confirmDeleteModalOpen: false })}
        >
          Are you sure you want to delete this setting?
        </ConfirmationModal>
      </>
    );
  }

  private renderHeader() {
    const {
      id,
      setting,
      parentType,
      dragHandleProps,
      isEditing,
      topLevel,
      hideTooltips,
      hideModifiedText,
      hideDefaultSettings,
    } = this.props;

    const isObject = setting.__type__ === ValueType.OBJECT;
    const isArray = setting.__type__ === ValueType.ARRAY;
    const isCollection = isObject || isArray;

    const settings: string[] = [
      "adPlacementProviderWaterfall",
      "adSettings",
      "adsPlacementProviders",
      "appsFlyerAttributionSettings",
      "crossPromotionGlobalSettings",
      "firehoseSettings",
      "gdpr",
      "idfaSettings",
      "loadGameSettings",
      "marketSettings",
      "mutationHistorySettings",
      "pushNotificationInfos",
      "slowDeviceCheckSettings",
      "subscriptionSettings",
    ];

    if (hideDefaultSettings && settings.includes(this.props.name)) {
      return;
    }

    return (
      <Tooltip
        arrow
        title={
          isEditing ? (
            !hideTooltips ? (
              ""
            ) : (
              <DescriptionField
                updateSetting={(setting: Partial<NestedSetting>) =>
                  this.updateSetting(setting)
                }
                initialValue={setting.__description__}
              />
            )
          ) : (
            setting.__description__
          )
        }
      >
        <div
          onClick={() => {
            if (this.state.anchorElBadge === null) {
              this.toggleExpand();

              if (
                setting.__type__ === ValueType.INT ||
                setting.__type__ === ValueType.DOUBLE
              ) {
                this.props.setDifferencesTooltip &&
                  this.props.setDifferencesTooltip();
                // Otherwise pick as setting path and close modal

                if (!this.props.currentJanusSetting) return;

                const newCountryConfigs = _.cloneDeep(
                  this.props.currentJanusSettingObject
                );

                if (newCountryConfigs.settings_config?.length) {
                  if (this.props.selectCountrySetting) {
                    newCountryConfigs.settings_config =
                      newCountryConfigs.settings_config.map((k) =>
                        k.key === this.props.selectCountrySetting
                          ? {
                              key: this.props.settingPath?.slice(0, 1)[0]
                                .node_key as string,
                              path: this.props.settingPath as IPath[],
                            }
                          : k
                      );
                  } else {
                    newCountryConfigs.settings_config = [
                      ...newCountryConfigs.settings_config,
                      {
                        key: this.props.settingPath?.slice(0, 1)[0]
                          .node_key as string,
                        path: this.props.settingPath as IPath[],
                      },
                    ];
                  }
                } else {
                  newCountryConfigs.settings_config = [
                    {
                      key: this.props.settingPath?.slice(0, 1)[0]
                        .node_key as string,
                      path: this.props.settingPath as IPath[],
                    },
                  ];
                }

                this.props.UPDATE_JANUS_SETTING({
                  id: this.props.currentJanusSetting,
                  settings_config: newCountryConfigs.settings_config,
                });

                // Close modal
                this.props.SET_SELECTOR({
                  object_id: undefined,
                  isOpen: false,
                });
              }
            }
          }}
          id={`tooltip-${id}`}
          className={classNames(
            `game-setting align-items-center justify-content-between`,
            {
              "cursor-grab": isEditing && parentType === ValueType.ARRAY,
            },
            { "d-none": topLevel },
            {
              "cursor-pointer":
                (!isEditing && isCollection) ||
                (isCollection && this.props.parentType !== ValueType.ARRAY),
            },
            { disabled: setting.different },
            {
              testing: this.props.abTestedSettings,
            }
          )}
          {...dragHandleProps}
        >
          {this.renderHeadingAndTag()}

          <Flex
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            width="100%"
            sx={{ pl: 2 }}
            className={classNames("value-container")}
          >
            {!isCollection && this.renderInlineValue()}

            {!hideModifiedText && (
              <small
                className={classNames(
                  "font-weight-light last-edited text-left w-100",
                  { "pt-1": !isCollection }
                )}
              >
                Modified{" "}
                {moment(setting.__modified__).format("DD/MM/YYYY HH:mm")} by{" "}
                {setting.__user__}
              </small>
            )}
          </Flex>

          <Flex display="flex" alignItems="center" className="dropdown-group">
            {!isCollection && !setting.different && (
              <Tooltip title="Copy to Clipboard" aria-label="copy">
                <Box mx="1rem">
                  <div
                    className="inline-edit-button"
                    title="Copy to Clipboard"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.copyValueToClipboard();
                    }}
                  >
                    <FaClipboard />
                  </div>
                </Box>
              </Tooltip>
            )}
            {!this.props.isEditing && !this.props.readOnly && (
              <Tooltip title="Edit" aria-label="edit">
                <div
                  className="inline-edit-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    this.props.turnOnEditing();

                    if (!this.props.isExpanded) {
                      this.toggleExpand(true);
                    }
                  }}
                >
                  <FaEdit />
                </div>
              </Tooltip>
            )}
            {this.renderTypeBadgeDropdown()}
            {this.renderOptionsDropdown()}
          </Flex>
        </div>
      </Tooltip>
    );
  }

  private renderHeadingAndTag() {
    const { setting, parentType, isEditing, parentValues } = this.props;

    const isParentArray = parentType === ValueType.ARRAY;
    const isObject = setting.__type__ === ValueType.OBJECT;
    const isArray = setting.__type__ === ValueType.ARRAY;
    const isCollection = isObject || isArray;

    const showTestingTooltip =
      this.props.abTestedSettings &&
      Object.keys(this.props.abTestedSettings).some((key) =>
        key.includes("UKCV_cohorted_test_")
      );

    const existsOnAllSettings = this.existsOnAllSettings();

    return (
      <Flex className="left-part-container" display="flex" alignItems="center">
        {isCollection && (
          <FontAwesomeIcon
            className={classNames("caret", { expanded: this.props.isExpanded })}
            icon={faCaretRight}
          />
        )}
        <Flex flexDirection="column" sx={{ width: "100%" }}>
          <Box className="heading-container" width="100%">
            {isEditing ? (
              <>
                <TextField
                  size="small"
                  variant="standard"
                  style={{
                    height: this.state.inputHeight,
                    paddingTop: "5px",
                    width: "100%",
                  }}
                  placeholder="Enter setting name"
                  ref={this.nameInputRef}
                  value={this.state.settingName ?? ""}
                  disabled={isParentArray || !existsOnAllSettings}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onBlur={() => {
                    this.validateSettingName();
                  }}
                  onChange={(event) => {
                    this.setState({ settingName: event.target.value });
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </>
            ) : (
              <Flex display="flex">
                <div
                  className={classNames("heading", "non-editable", {
                    marquee:
                      this.nameTextRef.current &&
                      this.nameTextRef.current.clientWidth > 300,
                  })}
                  ref={this.nameTextRef}
                >
                  {this.props.searchSettingsObject &&
                  this.props.searchSettingsObject.__match__
                    ? this.generateHighlightedTitle(
                        this.props.searchTerm!,
                        this.state.settingName
                      )
                    : this.state.settingName}
                </div>
              </Flex>
            )}
          </Box>
          {(setting.__tag__ || isEditing) && (
            <Box sx={{ ml: 1 }} className="tag-container">
              {isEditing ? (
                <TextField
                  size="small"
                  variant="standard"
                  style={{ height: this.state.inputHeight }}
                  placeholder="Add new tag"
                  className={classNames(
                    {
                      "only-show-on-hover": isEditing && !setting.__tag__,
                    },
                    "tag"
                  )}
                  value={this.state.settingTag ?? ""}
                  disabled={!isEditing || setting.different}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  onBlur={() => {
                    this.updateSettingTag(this.state.settingTag);
                  }}
                  onChange={(event) => {
                    this.setState({ settingTag: event.target.value });
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-input": {
                      fontSize: "0.8em",
                      fontWeight: 300,
                    },
                  }}
                />
              ) : (
                <Flex display="flex">
                  <div
                    className={classNames("tag", "non-editable", {
                      marquee:
                        this.tagTextRef.current &&
                        this.tagTextRef.current.clientWidth > 300,
                    })}
                    ref={this.tagTextRef}
                  >
                    {this.state.settingTag}
                  </div>
                </Flex>
              )}
            </Box>
          )}
        </Flex>
        <Flex mx={2} flexDirection="column">
          {this.props.abTestedSettings && (
            <Flex mb={2} alignItems="center">
              <Tooltip
                title={
                  showTestingTooltip
                    ? Object.entries(this.props.abTestedSettings).map(
                        ([key, value]) => (
                          <>
                            <span key={key}>
                              <strong>{key}</strong>{" "}
                              {typeof value !== "object" && value.toString()}
                            </span>
                            <br />
                          </>
                        )
                      )
                    : ""
                }
                aria-label="add"
              >
                <Chip
                  icon={showTestingTooltip ? <FaInfoCircle /> : undefined}
                  id={`testing-${this.props.id}`}
                  size="small"
                  color="secondary"
                  label="Testing"
                />
              </Tooltip>
            </Flex>
          )}
          {setting.different && (
            <>
              <Box
                className="different-badge"
                onClick={(event) => {
                  event.stopPropagation();

                  if (setting.different) {
                    this.props.setDifferencesTooltip &&
                      this.props.setDifferencesTooltip({
                        text: "",
                        //@ts-ignore
                        target: "open",
                        values: setting.values,
                        parentValues: parentValues,
                        isCollection,
                        path: this.props.settingPath,
                        currentLevel: this.props.currentLevel,
                      });
                  }
                }}
              >
                <Button
                  id={`not-equal-${this.props.id}`}
                  startIcon={<FaNotEqual />}
                  ref={this.notEqualChipRef}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  <small>View</small>
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    );
  }

  private renderInlineValue() {
    const { setting } = this.props;

    const isBoolean = setting.__type__ === ValueType.BOOLEAN;
    const isInt = setting.__type__ === ValueType.INT;
    const isDouble = setting.__type__ === ValueType.DOUBLE;

    let isErroneousValue = false;
    if (isBoolean) {
      isErroneousValue =
        this.state.settingValue !== true && this.state.settingValue !== false;
    } else {
      isErroneousValue =
        this.state.settingValue !== null &&
        this.state.settingValue !== undefined &&
        typeof this.state.settingValue !== "string" &&
        typeof this.state.settingValue !== "number";
    }

    return (
      <div className="container-variable">
        {!this.props.setting.different &&
          (isBoolean ? (
            <Button
              onClick={() => {
                this.setState(
                  {
                    settingValue: !this.state.settingValue,
                  },
                  () => this.updateSettingValue(this.state.settingValue)
                );
              }}
              style={{
                backgroundColor:
                  this.state.settingValue === true
                    ? "#198754"
                    : this.state.settingValue === false
                    ? "#dc3545"
                    : "#dcbb35",
                borderColor:
                  this.state.settingValue === true
                    ? "#198754"
                    : this.state.settingValue === false
                    ? "#dc3545"
                    : "#dcbb35",
                color: "#FFF",
              }}
              disabled={!this.props.isEditing}
              className="text-left"
              variant="contained"
            >
              {this.state.settingValue === true
                ? "True"
                : this.state.settingValue === false
                ? "False"
                : this.state.settingValue === null ||
                  this.state.settingValue === undefined
                ? "null"
                : JSON.stringify(this.state.settingValue)}
            </Button>
          ) : (
            <TextField
              variant="outlined"
              color={isErroneousValue ? "warning" : "primary"}
              sx={
                isErroneousValue
                  ? (theme) => ({
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.warning.main,
                      },
                    })
                  : undefined
              }
              fullWidth
              size="small"
              inputRef={this.valueInputRef}
              type={isInt || isDouble ? "number" : "text"}
              inputProps={{
                step: isDouble ? "0.1" : "0",
              }}
              placeholder="Enter setting value"
              value={
                isErroneousValue
                  ? JSON.stringify(this.state.settingValue)
                  : this.state.settingValue ?? ""
              }
              disabled={!this.props.isEditing}
              onBlur={() => {
                this.validateSettingValue();
              }}
              onChange={(event) =>
                this.setState({ settingValue: event.target.value })
              }
            />
          ))}
      </div>
    );
  }

  private _handleBadgeClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    this.setState({ anchorElBadge: e.currentTarget });
  };

  private _handleBadgeClose = (): void => {
    this.setState({ anchorElBadge: null });
  };

  private renderTypeBadgeDropdown() {
    const { setting, isEditing } = this.props;

    const isObject = setting.__type__ === ValueType.OBJECT;
    const isArray = setting.__type__ === ValueType.ARRAY;
    const isCollection = isObject || isArray;

    return (
      <>
        <Chip
          disabled={!isEditing}
          size="small"
          label={`${setting.__type__ ?? "Different Types"} ${
            isCollection
              ? `(${
                  Object.keys(
                    (
                      setting as NestedSetting<
                        ValueType.ARRAY | ValueType.OBJECT
                      >
                    ).__value__
                  ).length
                })`
              : ""
          }`}
          clickable
          style={{
            width: "95px",
            backgroundColor: ValueTypeColors[setting.__type__],
            marginRight: "10px",
          }}
          onClick={this._handleBadgeClick}
          icon={
            isEditing ? <ExpandMore style={{ width: "10px" }} /> : undefined
          }
        />

        <StyledMenu
          id="customized-menu"
          anchorEl={this.state.anchorElBadge}
          keepMounted
          open={Boolean(this.state.anchorElBadge)}
          onClose={this._handleBadgeClose}
        >
          {Object.values(ValueType)
            .filter((type) => type !== setting.__type__)
            .map((type, index) => {
              return (
                <Box key={index}>
                  <StyledMenuItem>
                    <ListItemText
                      key={type}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.changeType(type);
                        this._handleBadgeClose();
                      }}
                      primary={type}
                    />
                  </StyledMenuItem>
                </Box>
              );
            })}
        </StyledMenu>
      </>
    );
  }

  private renderOptionsDropdown() {
    const { setting, parentType, isEditing } = this.props;

    const isObject = setting.__type__ === ValueType.OBJECT;
    const isArray = setting.__type__ === ValueType.ARRAY;
    const isCollection = isObject || isArray;

    const isParentCollection =
      parentType === ValueType.ARRAY || parentType === ValueType.OBJECT;

    const existsOnAllSettings = this.existsOnAllSettings();

    return (
      <>
        <Box mr={2}>
          <IconButton
            aria-controls="customized-menu"
            aria-haspopup="true"
            size="small"
            style={{ padding: "0.7rem" }}
            onClick={(e: any) => this.handleClick(e)}
            disabled={
              (!isEditing && !isCollection) ||
              (!isEditing &&
                isCollection &&
                Object.keys(this.props.setting.__value__! as any).length === 0)
            }
          >
            <MoreVert />
          </IconButton>

          <StyledMenu
            id="customized-menu"
            anchorEl={this.state.anchorEl}
            keepMounted
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <Box>
              {isEditing && isParentCollection && !setting.different && (
                <StyledMenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    this.duplicateSetting(
                      this.state.duplicateSettingAmount,
                      this.props.setting,
                      this.props.name
                    );
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faClone} />
                  </ListItemIcon>
                  <ListItemText primary="Duplicate" />
                  <TextField
                    style={{ marginLeft: 4 }}
                    value={this.state.duplicateSettingAmount}
                    type="number"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(event) =>
                      this.setState({
                        duplicateSettingAmount: parseInt(event.target.value),
                      })
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.duplicateSetting(
                          this.state.duplicateSettingAmount,
                          this.props.setting,
                          this.props.name
                        );
                        this.handleClose();
                      }
                    }}
                  />
                </StyledMenuItem>
              )}

              {isCollection && (
                <>
                  {(this.state.expanded.length !==
                    Object.keys(this.props.setting.__value__! as any).length ||
                    !this.props.isExpanded) && (
                    <StyledMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        this.expandDirectChildren();
                        this.handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faExpandAlt} />
                      </ListItemIcon>
                      <ListItemText primary="Expand direct children" />
                    </StyledMenuItem>
                  )}
                  {this.state.expanded.length !== 0 && this.props.isExpanded && (
                    <StyledMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        this.collapseDirectChildren();
                        this.handleClose();
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={faCompressAlt} />
                      </ListItemIcon>
                      <ListItemText primary="Collapse direct children" />
                    </StyledMenuItem>
                  )}
                </>
              )}

              {isEditing && (
                <StyledMenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    this.addSetting(this.state.newSettingAmount);
                    this.handleClose();
                  }}
                  disabled={!existsOnAllSettings || !isCollection}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faPlus} />
                  </ListItemIcon>
                  <ListItemText primary="Add" />
                  <TextField
                    style={{ marginLeft: 4 }}
                    value={this.state.newSettingAmount}
                    type="number"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    size="small"
                    onChange={(event) => {
                      let newSettingAmount = 1;

                      if (event.target.value.length) {
                        try {
                          newSettingAmount = parseInt(event.target.value);
                        } catch (e) {}
                      }
                      this.setState({
                        newSettingAmount: Math.max(
                          1,
                          Math.min(100, newSettingAmount)
                        ),
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.addSetting(this.state.newSettingAmount);
                        this.handleClose();
                      }
                    }}
                  />
                </StyledMenuItem>
              )}
              <StyledMenuItem>
                <DownloadCSVButton
                  settingData={setting}
                  settingName={this.state.settingName}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <CopyJSONToClipboard
                  settingName={this.state.settingName}
                  settingData={setting}
                  onClose={() => this.handleClose()}
                />
              </StyledMenuItem>
              {isEditing && (
                <StyledMenuItem
                  disabled={!existsOnAllSettings}
                  className="inline-menu button-delete font-weight-lighter"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    this.deleteSetting();
                    this.handleClose();
                  }}
                >
                  <Divider light />
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTrash} />
                  </ListItemIcon>
                  <ListItemText primary="Delete" />
                </StyledMenuItem>
              )}
              <StyledMenuItem
                onClick={(event) => {
                  event.stopPropagation();

                  const p = mostMatchedArray(
                    Object.keys(this.props.docsSettings ?? {}).map((i: any) =>
                      i.split(",")
                    ),
                    this.props.settingPath?.map((i) => i.node_key) ?? []
                  ).join(",");

                  const url =
                    (this.props.docsSettings ?? {})[p!] ||
                    "https://docs.kwalee.com/";

                  window.open(url, "_blank");

                  this.handleClose();
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={faStickyNote} />
                </ListItemIcon>
                Link to Docs
              </StyledMenuItem>
            </Box>
          </StyledMenu>
        </Box>
      </>
    );
  }

  private renderCollapsibleValue() {
    const {
      setting,
      setTooltip,
      topLevel,
      isExpanded,
      setDifferencesTooltip,
      differencesTooltip,
    } = this.props;

    const isObject = setting.__type__ === ValueType.OBJECT;
    const isArray = setting.__type__ === ValueType.ARRAY;
    const isCollection = isObject || isArray;
    let isCollectionEmpty = false;
    //let isCollectionOneValue = false;

    if (setting.__value__ !== null && isCollection) {
      isCollectionEmpty =
        Object.keys(
          (setting as NestedSetting<ValueType.ARRAY | ValueType.OBJECT>)
            .__value__
        ).length === 0;

      /*isCollectionOneValue =
        Object.keys(
          (setting as NestedSetting<ValueType.ARRAY | ValueType.OBJECT>)
            .__value__,
        ).length === 1;*/
    }

    return (
      <div
        className={classNames(
          "expandable-setting-value",
          {
            "pl-5": isCollection && !this.props.topLevel,
          },
          { "pb-2": isCollection && isExpanded && !topLevel },
          { "border-0": this.props.topLevel }
        )}
      >
        <Collapse in={isExpanded}>
          {this.state.areChildrenVisible &&
            setting.__type__ &&
            !isNullOrUndefined(setting.__value__) &&
            (isCollectionEmpty ? (
              <Flex
                display="flex"
                flexDirection="column"
                className="container-variable"
              >
                <small className="text-muted px-3 pt-3 text-left">
                  No elements found.
                </small>
                <small className="text-muted p-3 text-left">
                  To add a new element click the{" "}
                  <span className="example-button">
                    <FontAwesomeIcon icon={faEdit} /> Edit
                  </span>{" "}
                  button,{" "}
                  {!topLevel && (
                    <>
                      then the{" "}
                      <span className="example-button">
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </span>{" "}
                      dropdown menu for this setting,
                    </>
                  )}{" "}
                  then the{" "}
                  <span className="example-button">
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </span>{" "}
                  option.
                </small>
              </Flex>
            ) : (
              <DragDropContext
                onDragStart={() => this.props.setTooltip()}
                onDragEnd={(dropResult) => this.onSettingDragEnd(dropResult)}
              >
                <Droppable
                  droppableId="droppable"
                  isDropDisabled={
                    !this.props.isEditing ||
                    this.props.setting.__type__ !== ValueType.ARRAY ||
                    setting.different
                  }
                >
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {this.state.nestedSettingsOrder.map((key, index) => {
                        const id = this.keyToIDMap.get(key)!;
                        if (!id) {
                          return null;
                        }

                        return (
                          <Draggable
                            key={key}
                            draggableId={key}
                            index={index}
                            isDragDisabled={
                              !this.props.isEditing ||
                              this.props.setting.__type__ !== ValueType.ARRAY ||
                              setting.different
                            }
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={classNames([
                                  { dragging: snapshot.isDragging },
                                ])}
                                style={provided.draggableProps.style}
                              >
                                <SettingWrapper
                                  inTooltip={this.props.inTooltip}
                                  dragHandleProps={provided.dragHandleProps}
                                  id={key}
                                  name={(isArray ? index : id).toString()}
                                  setting={(setting.__value__ as any)[id]}
                                  setTooltip={setTooltip}
                                  setDifferencesTooltip={setDifferencesTooltip}
                                  differencesTooltip={differencesTooltip}
                                  isVisible={this.state.areChildrenVisible}
                                  isEditing={this.props.isEditing}
                                  updateSettings={(
                                    value: NestedSetting<ValueType> | undefined
                                  ) => {
                                    this.updateSettings(value, id);
                                  }}
                                  isExpanded={this.state.expanded.includes(key)}
                                  toggleExpand={(isExpanded: any) => {
                                    this.expandInParentSettingIdMap(
                                      isExpanded!,
                                      key
                                    );
                                  }}
                                  parentType={setting.__type__}
                                  onKeyChange={(newKey: string) =>
                                    this.updateSettingKey(id.toString(), newKey)
                                  }
                                  parentKeys={Array.from(
                                    this.keyToIDMap.values()
                                  )}
                                  addSetting={this.addSetting.bind(this)}
                                  searchSettingsObject={
                                    this.props.searchSettingsObject
                                      ?.__value__ &&
                                    this.props.searchSettingsObject?.__value__[
                                      id
                                    ]
                                  }
                                  turnOnEditing={this.props.turnOnEditing}
                                  searchTerm={this.props.searchTerm}
                                  hideModifiedText={this.props.hideModifiedText}
                                  hideDefaultSettings={
                                    this.props.hideDefaultSettings
                                  }
                                  hideTooltips={this.props.hideTooltips}
                                  isParentDifferent={
                                    setting.different && !this.props.topLevel
                                  }
                                  readOnly={this.props.readOnly}
                                  currentLevel={
                                    this.props.topLevel
                                      ? 0
                                      : (this.props?.currentLevel ?? 0) + 1
                                  }
                                  settingPath={
                                    !this.props.settingPath ||
                                    this.props.settingPath.length === 0
                                      ? [
                                          {
                                            node_type: isArray
                                              ? "list"
                                              : "dict",
                                            node_key: id,
                                          },
                                        ]
                                      : [
                                          ...this.props.settingPath,
                                          {
                                            node_type: isArray
                                              ? "list"
                                              : "dict",
                                            node_key: id,
                                          },
                                        ]
                                  }
                                  abTestedSettings={
                                    this.props.abTestedSettings &&
                                    this.props.abTestedSettings[id]
                                  }
                                  parentValues={setting.values}
                                  docsSettings={this.props.docsSettings}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ))}
        </Collapse>
      </div>
    );
  }
}

export const checkIfNestedSetting = (setting: any) => {
  return setting.hasOwnProperty("__modified__");
};

const mapStateToProps = (state: any) => ({
  selectedSettingsObjects: selectedSettingsObjectsSelector(state),
  countries: countriesSelector(state),
  expandedSettings: selectExpandedSettings(state),
  beforeSearchExpandedSettings: selectBeforeSearchExpandedSettings(state),
  changes: selectChanges(state),
  userId: selectSession(state).user_id,
  currentJanusSetting: selectCurrentJanusSetting(state),
  currentJanusSettingObject: currentJanusSettingSelector(state),
  getSelectedCountry: getSelectedCountry(state),
  selectCountrySetting: selectCountrySetting(state),
});

const mapDispatchToProps = {
  updateExpandedSettings,
  updateBeforeSearchExpandedSettings,
  removeChangeByIndex,
  UPDATE_JANUS_SETTING,
  SET_SELECTOR,
};

// @ts-ignore
const SettingWrapper = connect(mapStateToProps, mapDispatchToProps)(Setting);

export default SettingWrapper;
