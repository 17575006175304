import { FieldType } from "../../../../model/crud.model";
import { DefaultLevelFields } from "../../defaultLevelFields";

export const displayNameTemplate = "{name}_{category_id}_{shot_count}";

export const nestedKeys = ["category_id", "level_type"];

export const sliceitModel = () => {
  return [
    ...DefaultLevelFields(),
    {
      display_name: "Max Indicator Size",
      name: "max_indicator_size",
      type: FieldType.NUMBER,
      defaultValue: 0,
    },
    {
      display_name: "Shot Count",
      name: "shot_count",
      type: FieldType.NUMBER,
      defaultValue: 0,
    },
    {
      display_name: "Min Completion Percentage",
      name: "min_completion_percentage",
      type: FieldType.NUMBER,
      defaultValue: 0,
    },
    {
      display_name: "Indicator Sensitivity",
      name: "indicator_sensitivity",
      type: FieldType.NUMBER,
      defaultValue: 0,
    },
  ];
};
