import { faClone, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, Component } from "react";

import * as H from "history";
import { store } from "../../../app/store";
import { DialogWrapper } from "../../../components/DialogWrapper";
import {
  ReactSelectArray,
  ValueType,
  VersionedSettings,
  VersionedSettingss,
} from "../../../model/versioned-settings.model";
import Dropzone from "../../../components/Dropzone/Dropzone";
import {
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
} from "@mui/material";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  currentSettingsId?: string;
  history?: H.History;
  versionedSettings?: VersionedSettingss;
  config?: any;
  importType?: string;
  returnPath?: string;
  gameEditionsReactSelect?: ReactSelectArray;
}

interface LocationParams {
  importedSettings?: VersionedSettings;
  leftSettingsObject: VersionedSettings;
}

export class ImportSettingsModal extends Component<Props, {}> {
  private _reader = new FileReader();

  constructor(props: Props) {
    super(props);

    this._reader.addEventListener("loadstart", () => {
      this.setState({
        progress: {
          loaded: 0,
          total: 0,
        },
      });
    });

    // On load complete
    this._reader.addEventListener("load", () => {
      let content;
      if (typeof this._reader.result === "string") {
        content = this._reader.result;
      }

      this.setState({ progress: undefined });

      if (content) {
        this.onImport(content);
      }
    });

    // On error
    this._reader.addEventListener("error", (err) => {
      console.error(err);

      this.setState({
        progress: undefined,
      });
    });
  }

  handleChange(
    stateName: string,
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    e.stopPropagation();

    // @ts-ignore
    this.setState({ [stateName]: e.target.value });
  }

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  onNavigate() {
    const { currentSettingsId, versionedSettings, config } = this.props;

    const leftSettingsObject = versionedSettings?.find(
      (settings) => settings.object_id === currentSettingsId
    );

    this.props.history?.push({
      pathname: `/${this.props.importType}/${currentSettingsId}/`,
      state: {
        returnPath: this.props.returnPath,
        leftSettingsObject: leftSettingsObject,
        settings: versionedSettings,
        config: config,
        gameEditionsReactSelect: this.props.gameEditionsReactSelect,
      },
    } as H.LocationDescriptorObject<LocationParams>);
  }

  onImport(settingsString: string) {
    const { currentSettingsId, versionedSettings, config } = this.props;

    const leftSettingsObject = versionedSettings?.find(
      (settings) => settings.object_id === currentSettingsId
    );

    try {
      let nestedSettings = JSON.parse(settingsString);

      let importedSettings = nestedSettings;

      if (!nestedSettings.hasOwnProperty("nested_settings")) {
        // If a key of nestedSettings does not have __value__
        let oldFormat = false;
        Object.keys(nestedSettings).forEach((key) => {
          const setting = nestedSettings[key];
          if (!setting.hasOwnProperty("__value__")) {
            oldFormat = true;
          }
        });
        // Convert to newSettings format
        if (oldFormat) {
          // If has settings/gameid keys on top level extract settings
          if (
            nestedSettings.hasOwnProperty("settings") &&
            nestedSettings.hasOwnProperty("game_id")
          ) {
            nestedSettings = nestedSettings.settings;
          }
          const convertedImportedSettings = {};
          Object.keys(nestedSettings).forEach((key) => {
            const value = nestedSettings[key];

            //@ts-ignore
            convertedImportedSettings[key] = this.convertToNewSettings(value);
          });

          nestedSettings = convertedImportedSettings;
        }

        importedSettings = {
          deleted_on: null,
          game_id: "",
          max_version: "",
          game_edition_id: "",
          object_id: "",
          name: "",
          nested_settings: nestedSettings,
          created_on: 1,
          object_type: "versioned_settings",
          country: "",
          updated_on: 1,
        };
      }

      this.props.history?.push({
        pathname: `/${this.props.importType}/${currentSettingsId}/imported`,
        state: {
          returnPath: this.props.returnPath,
          leftSettingsObject: leftSettingsObject,
          importedSettings: importedSettings,
          settings: versionedSettings,
          config: config,
          gameEditionsReactSelect: this.props.gameEditionsReactSelect,
        },
      } as H.LocationDescriptorObject<LocationParams>);
    } catch (e) {
      store.dispatch(
        queueNotification({
          message: "Invalid settings JSON",
          options: {
            key: `invalid_hash_code`,
            variant: SnackbarVariant.ERROR,
          },
        })
      );

      throw e;
    }
  }

  isInt(n: any) {
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n: any) {
    return Number(n) === n && n % 1 !== 0;
  }

  convertToNewSettings(settings: any): any {
    const extraKeys = {
      __description__: "",
      __tag__: "",
      __modified__: null,
      __user__: null,
    };
    let newSetting: any;

    if (settings === null) {
      return {
        __value__: settings,
        __type__: ValueType.NULL,
        ...extraKeys,
      };
    }
    switch (typeof settings) {
      case "number":
        if (this.isInt(settings)) {
          newSetting = {
            __value__: settings,
            __type__: ValueType.INT,
            ...extraKeys,
          };
        } else if (this.isFloat(settings))
          newSetting = {
            __value__: settings,
            __type__: ValueType.DOUBLE,
            ...extraKeys,
          };
        break;
      case "boolean":
        newSetting = {
          __value__: settings,
          __type__: ValueType.BOOLEAN,
          ...extraKeys,
        };
        break;
      case "string":
        newSetting = {
          __value__: settings,
          __type__: ValueType.STRING,
          ...extraKeys,
        };
        break;
      case "object":
        if (Array.isArray(settings)) {
          newSetting = {
            __type__: ValueType.ARRAY,
          };
          // If array
          newSetting.__value__ = settings.map((setting) =>
            this.convertToNewSettings(setting)
          );
        } else {
          // If object
          newSetting = {
            __type__: ValueType.OBJECT,
            __value__: {},
          };
          Object.keys(settings).forEach((key) => {
            const value = settings[key];

            //@ts-ignore
            newSetting.__value__[key] = this.convertToNewSettings(value);
          });
        }

        break;
    }

    return newSetting;
  }

  render() {
    return (
      <DialogWrapper isOpen={!!this.props.isOpen} onClose={this.handleClose}>
        <DialogTitle>Import</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box display="flex" alignItems="center" flexDirection="column">
              <TextField
                type="textarea"
                name="text"
                variant="outlined"
                size="small"
                id="exportText"
                placeholder="Import from clipboard, paste text here."
                onPaste={(event: any) => {
                  const paste =
                    event.clipboardData && event.clipboardData.getData
                      ? event.clipboardData.getData("text/plain")
                      : false;
                  if (paste) {
                    const text = (
                      event.clipboardData || event.clipboardData
                    ).getData("text");
                    this.onImport(text);
                  }
                }}
              />
            </Box>

            <small className="text-muted m-2">or</small>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              className="import-option"
            >
              <Dropzone
                onFilesAdded={(files) => {
                  const file = files[0];

                  this._reader.readAsText(file);
                }}
              >
                <FontAwesomeIcon icon={faFileUpload} /> Import JSON file
              </Dropzone>
            </Box>
            <small className="text-muted m-2">or</small>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              className="import-option"
            >
              <Button variant="outlined" onClick={() => this.onNavigate()}>
                <FontAwesomeIcon icon={faClone} /> Import from other settings
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </DialogWrapper>
    );
  }
}
