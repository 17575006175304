export function flattenPaths(a: any[], prefix = ""): any[] {
  return a.reduce(function (
    flattened: any[],
    {
      path,
      component,
      elements,
      id,
      exact,
      icon,
      name,
      subtitle,
      isOldAdmin,
      oldAdminURL,
      hidden,
    }: any
  ) {
    const current_path = path;
    path = prefix + path;

    return flattened
      .concat([
        {
          path,
          component,
          id,
          exact,
          icon,
          name,
          current_path,
          subtitle,
          isOldAdmin,
          oldAdminURL,
          hidden,
        },
      ])
      .concat(elements ? flattenPaths(elements, path) : []);
  },
  []);
}
