import { Chip, SxProps, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { ReactNode } from "react";

export interface Props {
  disabled?: boolean;
  options?: any;
  getOptionLabel?: any;
  getOptionSelected?: any;
  onOptionSelected?: (
    event: React.ChangeEvent<{}>,
    selectedOption: any,
    reason: string
  ) => any;
  label?: string;
  placeholder?: string;
  id: string;
  multiselect?: boolean;
  limitTags?: number;
  loading?: boolean;
  size?: "small" | "medium";
  renderInput?: (params: any) => ReactNode;
  renderOptions?: (props: any, option: any, { selected }: any) => ReactNode;
  renderGroup?: (params: any) => ReactNode;
  filterOptions?: (options: any, params: any) => any;
  selected?: any;
  group?: (option: { value: string; label: string; group: string }) => string;
  disableClearable?: boolean;
  sx?: SxProps;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const AutoCompleteSelect = (props: Props) => {
  return (
    <Autocomplete
      blurOnSelect
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}
      sx={props.sx}
      disableClearable={props.disableClearable}
      multiple={props.multiselect}
      limitTags={props.limitTags}
      id={props.id}
      disabled={props.disabled}
      options={props.options ?? []}
      getOptionLabel={props.getOptionLabel}
      isOptionEqualToValue={props.getOptionSelected}
      onChange={props.onOptionSelected}
      renderInput={
        props.renderInput ??
        ((params) => (
          <TextField {...params} label={props.label ?? "Select..."} />
        ))
      }
      renderTags={(value, getTagProps) => {
        const numTags = value.length;
        return (
          <>
            {value.slice(0, props.limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={index}
                label={
                  props.getOptionLabel
                    ? props.getOptionLabel(option)
                    : typeof option === "string"
                    ? option
                    : option?.label
                }
                variant="outlined"
                size="small"
              />
            ))}
            <small style={{ marginLeft: "0.5remS", fontSize: "0.8em" }}>
              {numTags > (props.limitTags || 0) &&
                ` + ${numTags - (props.limitTags || 0)} more`}
            </small>
          </>
        );
      }}
      renderOption={props.renderOptions}
      groupBy={props.group}
      loading={props.loading}
      placeholder={props.placeholder}
      size={props.size ?? "small"}
      filterOptions={props.filterOptions}
      value={props.selected}
      renderGroup={props.renderGroup}
    />
  );
};
