import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { SnapshotType } from "../../../model/log";
import {
  selectSnapshots,
  selectLoadingSnapshots,
  SET_SHOW_UNCHANGED,
  selectShowUnchanged,
} from "./expandedLogSlice";
//@ts-ignore
import JsonDiffReact from "jsondiffpatch-for-react";
import { fetchSnapshots } from "./expandedLogThunks";
import moment from "moment";
import { ToastTypes } from "../ToastTypes";
import { QUEUE_NOTIFICATION } from "../logSlice";
import { store } from "../../../app/store";

import {
  Chip,
  CircularProgress,
  Box,
  Checkbox,
  Typography,
  Button,
} from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import { Flex } from "../../../components/Flex";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";

export interface Props {
  row: any;
}

const StyledCheckboxHeader = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-left: 1rem;
  }
`;

const ExpandedLog = ({ row }: Props) => {
  const dispatch = useDispatch();
  const snapshots = useSelector(selectSnapshots);
  const snapshotsLoading = useSelector(selectLoadingSnapshots);
  const showUnchanged = useSelector(selectShowUnchanged);

  let currentUnchanged = showUnchanged && showUnchanged[row.object_id];

  if (currentUnchanged === undefined) {
    dispatch(SET_SHOW_UNCHANGED({ key: row.object_id, value: false }));
    currentUnchanged = false;
  }

  const currentSnapshots = snapshots && snapshots[row.object_id];

  useEffect(() => {
    const handleFetchSnapshots = async () => {
      const resultAction = await dispatch(
        fetchSnapshots({
          logId: row.object_id,
          snapshotTypes: [SnapshotType.BEFORE, SnapshotType.AFTER],
        })
      );
      //@ts-ignore
      if (!fetchSnapshots.fulfilled.match(resultAction)) {
        //@ts-ignore
        const errorMessage = `Error fetching snapshots: ${resultAction.error.message}`;
        dispatch(
          QUEUE_NOTIFICATION({
            message: errorMessage,
            type: ToastTypes.ERROR,
            id: `error_fetch_snapshot_${row.object_id}`,
          })
        );
      }
    };

    if (!snapshots[row.object_id]) {
      handleFetchSnapshots();
    }
  }, [dispatch, row.object_id, snapshots]);

  const before = currentSnapshots?.find(
    (snapshot: any) => snapshot.snapshot_type === SnapshotType.BEFORE
  )?.data;

  const after = currentSnapshots?.find(
    (snapshot: any) => snapshot.snapshot_type === SnapshotType.AFTER
  )?.data;

  return (
    <Box maxWidth="100vw" sx={{ mt: 3, mx: 3 }}>
      <small>{row.object_id}</small>
      <h3>
        <span>{row.change_type}</span>: {row.related_object_id}{" "}
        <Chip label={row.related_object_type} />
      </h3>
      <small>{`${moment(row.created_on).format("LLLL")} · ${row.user} · ${
        row.user_platform
      }`}</small>
      <hr />
      <p>
        <strong>Location:</strong> {row.change_location}
      </p>
      {row.reason !== "No Reason Provided" && (
        <p>
          <strong>Reason:</strong> {row.reason}
        </p>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <StyledCheckboxHeader>
          <h4>Changes</h4>
          <Box>
            <Typography>
              <Checkbox
                key={row.object_id}
                id="remember"
                name="remember"
                checked={currentUnchanged}
                onClick={() =>
                  dispatch(
                    SET_SHOW_UNCHANGED({
                      key: row.object_id,
                      value: !currentUnchanged,
                    })
                  )
                }
              />
              Show Unchanged
            </Typography>
          </Box>
        </StyledCheckboxHeader>
        <Box>
          <CopyToClipboard
            text={JSON.stringify(before, null, 4)}
            onCopy={() => {
              store.dispatch(
                queueNotification({
                  message: "Successfully copied Snapshot data.",
                  options: {
                    key: `before_successful`,
                    variant: SnackbarVariant.SUCCESS,
                  },
                })
              );
            }}
          >
            <Button
              variant="outlined"
              disabled={
                !before ||
                Object.keys(before).length === 0 ||
                !Object.getPrototypeOf(before) === Object.prototype
              }
            >
              Copy Before
            </Button>
          </CopyToClipboard>
          <CopyToClipboard
            text={JSON.stringify(after, null, 4)}
            onCopy={() => {
              store.dispatch(
                queueNotification({
                  message: "Successfully copied Snapshot data.",
                  options: {
                    key: `after_successful`,
                    variant: SnackbarVariant.SUCCESS,
                  },
                })
              );
            }}
          >
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              disabled={
                !after ||
                Object.keys(after).length === 0 ||
                !Object.getPrototypeOf(after) === Object.prototype
              }
            >
              Copy After
            </Button>
          </CopyToClipboard>
        </Box>
      </Flex>

      {snapshotsLoading && !snapshots[row.object_id] ? (
        <CircularProgress />
      ) : !currentSnapshots ? (
        <small className="text-muted">No snapshots found</small>
      ) : currentSnapshots.length < 2 ? (
        <small className="text-muted">Only one snapshot available</small>
      ) : !(
          Object.keys(before ?? {}).length === 0 &&
          Object.keys(after ?? {}).length === 0
        ) ? (
        <JsonDiffReact
          key={row.object_id}
          left={before}
          right={after}
          show={currentUnchanged}
        />
      ) : (
        <small className="text-muted">
          Before and after snapshots are empty objects
        </small>
      )}
    </Box>
  );
};

export default ExpandedLog;
