import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { RootState } from "../../app/store";
import { FETCH_JANUS_SETTINGS, SAVE_SETTINGS } from "./JanusSettingsThunks";
import isNullOrUndefined from "../../utils/isNullOrUndefined";
import { IJanusSetting } from "./JanusSetting";
import { ToastTypes } from "../LogsPage/ToastTypes";

interface JanusSettingsState {
  darkMode: any;
  loadingJanusSetting: boolean;
  janusSettings?: IJanusSetting[];
  changedSettings: string[];
  deletedSettings: string[];
  addedSettings: string[];
  selectedJanusSetting?: string;
  selectedCountrySetting?: string;
  newSettingCounter: number;
  openSelector?: boolean;
  savingSettings: boolean;
  currentToast?: { type: ToastTypes; message: string };
  selectedCountry: string;
}

const initialState: JanusSettingsState = {
  janusSettings: [],
  changedSettings: [],
  addedSettings: [],
  deletedSettings: [],
  darkMode: false,
  loadingJanusSetting: true,
  savingSettings: false,
  newSettingCounter: 1,
  selectedCountry: "",
  selectedCountrySetting: "",
};

export const janusSettingsSlice = createSlice({
  name: "janus_settings",
  initialState,
  reducers: {
    SET_DARKMODE: (state, { payload }) => {
      state.darkMode = payload;
    },
    UPDATE_JANUS_SETTING: (state, { payload }) => {
      const settingToUpdate = state.janusSettings?.find(
        (setting) => setting.object_id === payload.id
      );

      // Update setting
      // For each key in payload that isn't id
      Object.keys(payload).forEach((key) => {
        if (key !== "id" && settingToUpdate) {
          // @ts-ignore
          settingToUpdate[key as keyof IJanusSetting] = payload[key];

          if (key === "path" || key === "game_edition_id") {
            // Reset janus setting values
          }
        }
      });

      // Add to changed array
      if (!state.changedSettings.includes(payload.id)) {
        state.changedSettings.push(payload.id);
      }
    },
    SET_SELECTED_JANUS_SETTING: (state, { payload }) => {
      state.selectedJanusSetting = payload;
    },
    SET_SELECTED_COUNTRY: (state, { payload }) => {
      state.selectedCountry = payload;
    },
    ADD_NEW_JANUS_SETTING: (state, { payload }) => {
      const newId = `UKCV_new_janus_setting_${state.newSettingCounter}`;
      const newSetting = {
        game_id: payload.game_id ?? "",
        game_edition_id: "android",
        status: "disabled",
        country_setting_config: [
          {
            country: "DEFAULT",
            weighted_values: [{ weight: 1, value: 0 }],
          },
        ],
        settings_config: [],
        scope: "new_players",
        object_id: newId,
        created_on: moment().valueOf(),
      };
      // @ts-ignore
      state.janusSettings?.push(newSetting);
      state.addedSettings.push(newId);
      state.newSettingCounter++;
    },
    DELETE_JANUS_SETTING: (state, { payload }) => {
      const index = state.janusSettings?.findIndex(
        (setting) => setting.object_id === payload
      );
      if (!isNullOrUndefined(index)) {
        state.janusSettings?.splice(index, 1);
        state.deletedSettings.push(payload);
      }
    },
    SET_SELECTOR: (state, { payload }) => {
      state.selectedJanusSetting = payload.object_id;
      state.selectedCountrySetting = payload.selectedId;
      state.openSelector = payload.isOpen;
    },
    SHOW_TOAST(state, { payload }) {
      state.currentToast = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FETCH_JANUS_SETTINGS.pending, (state) => {
      state.loadingJanusSetting = true;
    });
    builder.addCase(FETCH_JANUS_SETTINGS.fulfilled, (state, { payload }) => {
      //@ts-ignore
      state.janusSettings = payload;
      state.loadingJanusSetting = false;
    });
    builder.addCase(FETCH_JANUS_SETTINGS.rejected, (state) => {
      state.loadingJanusSetting = false;
    });
    builder.addCase(SAVE_SETTINGS.pending, (state) => {
      state.savingSettings = true;
    });
    builder.addCase(SAVE_SETTINGS.fulfilled, (state, { payload }) => {
      // Replace saved settings from store with ones from server
      payload.forEach((updatedSetting) => {
        // Replace in store
        const index = state.janusSettings?.findIndex((setting) => {
          return setting?.object_id === updatedSetting?.object_id;
        });

        if (index !== -1 && !isNullOrUndefined(index)) {
          state.janusSettings?.splice(index, 1, updatedSetting);
        }
      });
      state.savingSettings = false;
      state.currentToast = {
        type: ToastTypes.SUCCESS,
        message: "Janus settings saved successfully",
      };
      // Clear arrays
      state.addedSettings = [];
      state.deletedSettings = [];
      state.changedSettings = [];
    });
    builder.addCase(SAVE_SETTINGS.rejected, (state) => {
      state.savingSettings = false;
      state.currentToast = {
        type: ToastTypes.ERROR,
        message: "An error occurred while saving",
      };
    });
  },
});

export const {
  SET_DARKMODE,
  UPDATE_JANUS_SETTING,
  SET_SELECTED_JANUS_SETTING,
  SET_SELECTED_COUNTRY,
  ADD_NEW_JANUS_SETTING,
  DELETE_JANUS_SETTING,
  SET_SELECTOR,
  SHOW_TOAST,
} = janusSettingsSlice.actions;

// Selectors
export const selectDarkMode = (state: RootState) =>
  state.janus_settings.darkMode;

export const selectJanusSettings = (state: RootState) =>
  state.janus_settings.janusSettings;

export const selectloadingJanusSetting = (state: RootState) =>
  state.janus_settings.loadingJanusSetting;

export const selectCurrentJanusSetting = (state: RootState) =>
  state.janus_settings.selectedJanusSetting;

export const selectCountrySetting = (state: RootState) =>
  state.janus_settings.selectedCountrySetting;

export const selectChangedSettings = (state: RootState) =>
  state.janus_settings.changedSettings;

export const selectNewSettings = (state: RootState) =>
  state.janus_settings.addedSettings;

export const selectDeletedSettings = (state: RootState) =>
  state.janus_settings.deletedSettings;

export const selectIsSelectorOpen = (state: RootState) =>
  state.janus_settings.openSelector;

export const currentToastSelector = (state: any) =>
  state.janus_settings.currentToast;

export const currentJanusSettingSelector = (state: RootState) => {
  return state.janus_settings.janusSettings?.find(
    (setting) => setting.object_id === state.janus_settings.selectedJanusSetting
  );
};

export const getSelectedCountry = (state: RootState) =>
  state.janus_settings.selectedCountry;

export default janusSettingsSlice.reducer;
