import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { AnalyticsEventGroupModel } from "../model/analytics_event_group.model";
import { getData } from "./request";

type Event = {
  eventGroup: AnalyticsEventGroupModel[];
  isLoading: boolean;
  isError: boolean;
};

export function useEventGroup() {
  const { data, error } = useSWR<Record<string, any>>(
    Endpoints.ANALYTICS_EVENT_GROUP.url,
    getData,
    {
      suspense: true,
    }
  );

  const eventGroup: Event = {
    eventGroup: data?.[Endpoints.ANALYTICS_EVENT_GROUP.object_path] ?? [],
    isLoading: !error && !data,
    isError: error,
  };

  return eventGroup;
}
