import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface CrudKeys {
  cpitest?: boolean;
  ctest?: boolean;
  games?: boolean;
  creatives?: boolean;
  batches?: boolean;
  campaigns?: boolean;
  users?: boolean;
  platforms?: boolean;
  login?: boolean;
  xpromo_creatives?: boolean;
  xpromo_campaigns?: boolean;
  restricted_event_group_managers?: boolean;
  sampling_events?: boolean;
  event_groups?: boolean;
  level_setup?: boolean;
  level_order?: boolean;
  changes_made?: boolean;
  game_settings?: boolean;
  areas?: boolean;
  app_store_accounts?: boolean;
  objectives?: boolean;
  daily_tasks?: boolean;
  game_creation?: boolean;
}

export enum CrudKeyNames {
  CPITEST = "cpitest",
  CTEST = "ctest",
  GAMES = "games",
  CREATIVES = "creatives",
  BATCHES = "batches",
  CAMPAIGNS = "campaigns",
  USERS = "users",
  PLATFORMS = "platforms",
  LOGIN = "login",
  XPROMO_CREATIVES = "xpromo_creatives",
  XPROMO_CAMPAIGNS = "xpromo_campaigns",
  LEVEL_SETUP = "level_setup",
  LEVEL_ORDER = "level_order",
  CHANGES_MADE = "changes_made",
  EXAMPLE_CRUD = "example_crud",
  GAME_CREATION = "game_creation",
  RESTRICTED_GROUP_MANAGERS = "restricted_event_group_managers",
  ANALYTICS_EVENT = "sampling_events",
  ANALYTICS_EVENT_GROUP = "event_groups",
}

interface CrudState {
  saving: CrudKeys;
  selected: string[];
  searchTerm: string;
  searchField: string;
}

const initialState: CrudState = {
  saving: {},
  selected: [],
  searchField: "display_name",
  searchTerm: "",
};

export const crudSlice = createSlice({
  name: "crud",
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<any>) => {
      state.searchTerm = action.payload;
    },
    setSearchField: (state, action: PayloadAction<any>) => {
      state.searchField = action.payload;
    },
    setIsSaving: (state, action: PayloadAction<any>) => {
      state.saving[action.payload.key as keyof CrudKeys] = action.payload.value;
    },
    setSelected: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    addSelected: (state, action: PayloadAction<any>) => {
      if (!state.selected.includes(action.payload)) {
        state.selected.push(action.payload);
      }
    },
    removeSelected: (state, action: PayloadAction<any>) => {
      if (state.selected.includes(action.payload)) {
        const index = state.selected.findIndex((id) => id === action.payload);
        state.selected.splice(index, 1);
      }
    },
    clearSelected: (state) => {
      state.selected = [];
    },
  },
});

export const {
  setIsSaving,
  setSelected,
  setSearchTerm,
  setSearchField,
  clearSelected,
  addSelected,
  removeSelected,
} = crudSlice.actions;

export const selectIsSaving = (state: RootState) => state.crud.saving;
export const selectSelected = (state: RootState) => state.crud.selected;
export const selectSearchTerm = (state: RootState) => state.crud.searchTerm;
export const selectSearchField = (state: RootState) => state.crud.searchField;

export default crudSlice.reducer;
