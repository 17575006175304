import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ToastType } from "../../model/toast";
import { fetchLogs, fetchConfig } from "./logThunks";
import { AdminLog } from "../../model/log";

interface LogState {
  logs?: AdminLog[];
  config?: any;
  loadingConfig: boolean;
  loadingLogs: boolean;
  totalLogs?: number;
  toast?: ToastType;
}

const initialState: LogState = {
  loadingLogs: true,
  loadingConfig: true,
};

export const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    QUEUE_NOTIFICATION: (state, { payload }) => {
      state.toast = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogs.pending, (state) => {
      state.loadingLogs = true;
    });
    builder.addCase(fetchLogs.fulfilled, (state, { payload }) => {
      //@ts-ignore
      state.logs = payload.logs;
      //@ts-ignore
      state.totalLogs = payload.total_logs;
      state.loadingLogs = false;
    });
    builder.addCase(fetchLogs.rejected, (state) => {
      state.loadingLogs = false;
      state.logs = [];
      //@ts-ignore
      state.totalLogs = 0;
    });
    builder.addCase(fetchConfig.pending, (state) => {
      state.loadingConfig = true;
    });
    builder.addCase(fetchConfig.fulfilled, (state, { payload }) => {
      //@ts-ignore
      state.config = payload.config;
      state.loadingConfig = false;
    });
    builder.addCase(fetchConfig.rejected, (state) => {
      state.loadingConfig = false;
    });
  },
});

export const { QUEUE_NOTIFICATION } = logSlice.actions;

// Selectors
export const selectLogs = (state: RootState) => state.log.logs;
export const selectLoadingLogs = (state: RootState) => state.log.loadingLogs;
export const selectTotalLogs = (state: RootState) => state.log.totalLogs;

export const selectToast = (state: RootState) => state.log.toast;

export const selectConfig = (state: RootState) => state.log.config;
export const selectLoadingConfig = (state: RootState) =>
  state.log.loadingConfig;

export default logSlice.reducer;
