import breakpoints from "./breakpoints";

export default {
  MuiButton: {
    root: {
      textTransform: "none",
    },
  },
  MuiChip: {
    root: {
      borderRadius: "6px",
      padding: 0,
    },
    label: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  MuiDivider: {
    root: {
      marginTop: 24,
      marginBottom: 24,
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: "13px",

      [breakpoints.up("sm")]: {
        minHeight: "auto",
      },
    },
  },
} as const;
