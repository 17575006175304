import { Box, Button, FormControl, Grid } from "@mui/material";
import React, { useState } from "react";
import { FormItem } from "../../../model/crud.model";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";
import { SearchBar } from "../../SearchBar";
import { FormField } from "./FormField";
import { Virtuoso } from "react-virtuoso";

interface ObjectFieldProps {
  field: FormItem;
  value: { [id: string]: any };
  isDisabled?: boolean;
  onChange: (newValue: any) => void;
  /* model for specifying the value to be used for the object key */
  keyModel: FormItem;
  /* model for specifying the structure of the value of the object */
  valueModel: FormItem[];
  setHasChanged?: (hasChanged: boolean) => void;
  isCreate?: boolean;
}

const MAX_HEIGHT = 800;

export const ObjectField = ({
  value,
  keyModel,
  valueModel,
  onChange,
  isDisabled,
  setHasChanged,
  isCreate,
}: ObjectFieldProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  if (typeof value !== "object") {
    value = JSON.parse(value);
  }

  // Recursively find height of field
  const heightOfElements =
    Object.values(value).reduce(
      (previousValue, currentValue) =>
        previousValue +
        (Array.isArray(currentValue) && currentValue.length !== 0
          ? currentValue.length
          : 1),
      0
    ) * 75;

  const height =
    heightOfElements === 0 ? 0 : Math.min(heightOfElements, MAX_HEIGHT);

  const filteredValue = Object.keys(value)
    .filter(
      (key) =>
        searchTerm === "" ||
        (keyModel.options &&
          keyModel.options
            //@ts-ignore
            .find((option) => option.value === key)
            ?.label.toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    )
    .reduce((obj, key) => {
      //@ts-ignore
      obj[key] = value[key];
      return obj;
    }, {});

  return (
    <FormControl fullWidth>
      <Box my="1rem">
        <SearchBar
          value={searchTerm}
          setValue={setSearchTerm}
          placeholder={`Search for ${keyModel.display_name}...`}
        />
      </Box>
      <Box border="1px solid lightgray" borderRadius="4px">
        <Virtuoso
          style={{ height }}
          totalCount={Object.keys(filteredValue).length}
          itemContent={(index) => {
            const item = Object.values(filteredValue)[index];
            const key = Object.keys(filteredValue)[index];
            return (
              <Box key={key} mx={2} mt={2}>
                <FormControl sx={{ width: "100%" }}>
                  <Grid container alignItems="flex-top">
                    <Grid item xs={4}>
                      <FormField
                        index={index}
                        // @ts-ignore
                        formValue={key}
                        field={keyModel}
                        //@ts-ignore
                        valueProp={key}
                        onChange={(update) => {
                          const newKey = update[keyModel.name];

                          const valueClone = { ...value };

                          // Update via index
                          const newValue = Object.values(value)[index];

                          delete valueClone[key];

                          onChange({ ...valueClone, [newKey]: newValue });
                        }}
                        setHasChanged={setHasChanged}
                      />
                    </Grid>

                    <Grid item xs={8}>
                      <Box sx={{ marginLeft: 1 }}>
                        <FormField
                          key={`${key}-${index}`}
                          index={index}
                          // @ts-ignore
                          formValue={item}
                          field={valueModel[0]}
                          //@ts-ignore
                          valueProp={item}
                          isCreate={isCreate}
                          onChange={(update) => {
                            onChange({
                              ...value,
                              [key]: update[valueModel[0].name],
                            });
                          }}
                          setHasChanged={setHasChanged}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            );
          }}
        />
      </Box>

      <Button
        variant="outlined"
        style={{ alignSelf: "flex-start", marginTop: "1rem" }}
        onClick={() => {
          let newObj = { ...value };

          if (isNullOrUndefined(newObj[""])) {
            newObj[""] = valueModel[0].defaultValue;
          } else {
            newObj["UNKNOWN"] = valueModel[0].defaultValue;
          }

          onChange(newObj);

          setHasChanged && setHasChanged(true);
        }}
        disabled={isDisabled}
      >
        Add {keyModel.display_name}
      </Button>
    </FormControl>
  );
};
