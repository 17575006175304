import { Box, SxProps, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FaTimesCircle } from "react-icons/all";
import styled from "styled-components";
import useDebounce from "../utils/useDebounce";
import { Flex } from "./Flex";

const StyledSearch = styled(TextField)`
  width: 100%;
  border-radius: 0.375rem !important;
  border: none !important;
  color: white !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  .MuiFilledInput-input {
    padding: 12px;
  }

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:hover,
  .MuiFilledInput-underline:active {
    border-bottom: none;
  }
`;

const StyledClearIcon = styled(Flex)`
  transition: color 0.1s ease-in-out, opacity 0.15s ease-in;
  cursor: pointer;
  padding-right: 0.5rem;
  z-index: 2;
  position: absolute;
  right: 5px;
  top: 12px;
`;

interface SearchBarProps {
  value: string;
  setValue: (newValue: string) => void;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps;
  debounce?: boolean;
}

export const SearchBar = ({
  value,
  setValue,
  placeholder = "Search...",
  disabled,
  sx,
  debounce,
}: SearchBarProps) => {
  const [tempValue, setTempValue] = useState("");
  const debouncedSearchTerm = useDebounce(tempValue, 500);

  useEffect(() => {
    if (debounce) {
      setValue(debouncedSearchTerm);
    }
  }, [debounce, debouncedSearchTerm, setValue]);

  return (
    <Box position="relative">
      <StyledSearch
        sx={sx}
        variant="filled"
        placeholder={placeholder}
        value={debounce ? tempValue : value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (debounce) {
            setTempValue(event.target.value);
          } else if (event.target.value !== "/") {
            setValue(event.target.value);
          }
        }}
        disabled={disabled}
      />
      {value && (
        <StyledClearIcon
          className="clear-icon"
          onClick={() => (debounce ? setTempValue("") : setValue(""))}
        >
          <FaTimesCircle />
        </StyledClearIcon>
      )}
    </Box>
  );
};
