export function searchSettings<T>(
  obj: T,
  term?: string,
  parentMatch?: boolean
): any {
  if (!term) {
    return obj;
  }

  let newObj: any;

  // Check if match search term
  if (typeof obj === "object" && obj !== null) {
    newObj = {};
    if (Array.isArray(obj)) {
      newObj = [...obj];
      newObj.map((el: any) => searchSettings(el, term));
    }

    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        let match = parentMatch ?? false;
        newObj[key] = value;
        // @ts-ignore
        if (value?.hasOwnProperty("__value__")) {
          newObj[key] = { ...value };
          if (parentMatch) {
            newObj[key]["__child_match__"] = true;
          }
          if (key.toLowerCase().includes(term.toLowerCase())) {
            newObj[key]["__match__"] = true;
            // Match must be inherited by all children of this value
            match = true;
          } else {
            newObj[key]["__match__"] = false;
          }
        }

        return [key, searchSettings(newObj[key], term, match)];
      })
    );
  } else {
    newObj = obj;
  }

  return newObj;
}
