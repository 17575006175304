import React, { useEffect, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changelog } from "../../changelog";
import { NavigationRoutes } from "../../constants/navigationRoutes";
import { LoginRoute } from "../../constants/routes";
import {
  selectShowAllChangelogs,
  selectWhatsNewOpen,
  setWhatsNewOpen,
} from "../../features/general/generalSlice";
import {
  selectSession,
  setReturnPath,
} from "../../features/session/sessionSlice";
import { checkPermissions } from "../../layout/checkPermissions";
import { Layout } from "../../layout/Layout";
import { useMenuTrees } from "../../network/useMenuTrees";
import { flattenPaths } from "../../utils/flatttenPaths";
import { WhatsNewModal } from "../WhatsNewModal";

export function MainRoute() {
  const dispatch = useAppDispatch();

  const { search, pathname: path } = useLocation();

  const { game_id, session_id, return_path, auth_data } =
    useAppSelector(selectSession);
  const whatsNewOpen = useAppSelector(selectWhatsNewOpen);
  const showAllChangelogs = useAppSelector(selectShowAllChangelogs);
  const isLoggedIn = !!session_id;
  const isSuperuser = auth_data?.superuser ?? false;

  const { mergedNavRoutes, isLoading } = useMenuTrees();

  const flattenedRoutes = useMemo(
    () =>
      flattenPaths(mergedNavRoutes)
        .filter((route) => route.name !== "Logout")
        .filter((route) =>
          checkPermissions(
            route.permissions,
            auth_data?.generated_permissions,
            isSuperuser
          )
        )
        .filter((route) => !route.hidden),
    [mergedNavRoutes, auth_data, isSuperuser]
  );

  const isLoginPage = path === LoginRoute;

  // Check if current path is included in navItems, otherwise go to homepage

  const testDiffPath = "/game-settings/import-game-settings";

  const isDiffTool = path.includes(testDiffPath);

  useEffect(() => {
    if (
      !isLoading &&
      !flattenedRoutes.map((route) => route.path).includes(path) &&
      !isLoginPage &&
      path !== "/" &&
      !isDiffTool
    ) {
      dispatch(setReturnPath(""));
    }
  }, [dispatch, isLoading, flattenedRoutes, isLoginPage, path, isDiffTool]);

  // Home page
  const homePage = return_path ? return_path : "/";

  const isSolitaire = useMemo(() => game_id === "cassolitairehf", [game_id]);

  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) {
      history.push(LoginRoute);
    }
  }, [history, isLoggedIn]);

  return (
    <Layout>
      <Switch>
        {flattenPaths(NavigationRoutes(isSolitaire)).map((route: any) => (
          <Route key={route.id} {...route} />
        ))}
        <Route
          path="*"
          exact={true}
          render={() => (
            <Redirect
              to={{
                pathname: homePage,
                search,
              }}
            />
          )}
        />
      </Switch>
      <WhatsNewModal
        open={whatsNewOpen || showAllChangelogs}
        setOpen={(isOpen) => dispatch(setWhatsNewOpen(isOpen))}
        changelog={changelog}
      />
    </Layout>
  );
}
