import { mutate } from "swr";
import { store } from "../../../../../app/store";
import Endpoints from "../../../../../constants/endpoints";
import { ILeverOrder } from "../../../../../model/level.model";
import { postData } from "../../../../../network/request";
import {
  queueNotification,
  SnackbarVariant,
} from "../../../../notification/notificationSlice";
import { ISolitaireAreaOrder } from "../types";

export const saveAreaOrder = (
  currentAreaOrder?: ISolitaireAreaOrder[] | undefined,
  orderedAreaIds?: string[] | undefined,
  filters?: { [id: string]: any },
  areaOrders?: any
) => {
  const newAreaOrders = [...areaOrders];

  if (currentAreaOrder) {
    const requestMap = currentAreaOrder.map((item: ISolitaireAreaOrder) =>
      postData(
        `${Endpoints.SOLITAIRE_AREA_ORDERS.url}/${item?.object_id}/update`,
        { ordered_area_ids: orderedAreaIds },
        Endpoints.SOLITAIRE_AREA_ORDERS.single_object_path
      )
    );

    Promise.allSettled(requestMap)
      .then((response) => {
        store.dispatch(
          queueNotification({
            message: "Updated Area Order Successfully",
            options: {
              key: "update_area_order_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );

        const responseAreaOrder: any | undefined = (response[0] as any)?.value;

        const indexToReplace = newAreaOrders.findIndex(
          (order: ILeverOrder) =>
            order.object_id === responseAreaOrder?.object_id
        );
        newAreaOrders.splice(indexToReplace, 1, responseAreaOrder);

        mutate(
          Endpoints.SOLITAIRE_AREA_ORDERS.url,
          async (prev: any) => {
            return {
              [Endpoints.SOLITAIRE_AREA_ORDERS.object_path]: newAreaOrders,
            };
          },
          false
        );

        return response;
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "update_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  } else {
    postData(
      `${Endpoints.SOLITAIRE_AREA_ORDERS.url}`,
      { ...filters, ordered_area_ids: orderedAreaIds },
      Endpoints.SOLITAIRE_AREA_ORDERS.single_object_path
    )
      .then((response) => {
        store.dispatch(
          queueNotification({
            message: "Updated Area Order Successfully",
            options: {
              key: "update_area_order_notification",
              variant: SnackbarVariant.SUCCESS,
            },
          })
        );

        newAreaOrders.push(response);

        mutate(
          Endpoints.SOLITAIRE_AREA_ORDERS.url,
          async (prev: any) => {
            return {
              [Endpoints.SOLITAIRE_AREA_ORDERS.object_path]: newAreaOrders,
            };
          },
          false
        );

        return response;
      })
      .catch((err) => {
        store.dispatch(
          queueNotification({
            message: err.response.data.error_message,
            options: {
              key: "update_error",
              variant: SnackbarVariant.ERROR,
            },
          })
        );
      });
  }
};
