import React from "react";
import CollectionEditor from "../../components/CollectionEditor/CollectionEditor";
import Endpoints from "../../constants/endpoints";
import { FieldType, Form } from "../../model/crud.model";
import { useAppStoreAccounts } from "../../network/useAppStoreAccounts";
import { useGameEditions } from "../../network/useGameEditions";
import { useEndpointAsOptions } from "../../utils/useEndpointAsOptions";

export const AppStoreAccount = () => {
  const { gameEditions } = useGameEditions();
  const { appStoreAccounts, isLoading } = useAppStoreAccounts();

  const gameEditionOptions = useEndpointAsOptions(
    gameEditions,
    [],
    ["display_name"],
    "_"
  );

  const model: Form = [
    {
      display_name: "Name",
      name: "name",
      type: FieldType.STRING,
      main_field: true,
    },
    {
      display_name: "Store Name",
      name: "store_name",
      type: FieldType.STRING,
      main_field: true,
    },
    {
      display_name: "Game Edition",
      name: "game_edition_id",
      type: FieldType.SELECT,
      options: gameEditionOptions,
    },
  ];

  return (
    <CollectionEditor
      response={appStoreAccounts}
      page="app_store_accounts"
      isLoading={isLoading}
      parentField="store_name"
      model={model}
      endpoint={Endpoints.APP_STORE_ACCOUNTS}
    />
  );
};
