import { lighten } from "@mui/material";
import { themePalettes } from "../../../theme/options/palette";

export const darkTheme = {
  darkMode: true,
  backgroundColor: themePalettes.dark.background,
  textColor: "white",
  lightTextColor: "#5a5a5a",
  filter: {
    backgroundColor: themePalettes.dark.background?.paper,
  },
  dropdown: {
    backgroundColor: themePalettes.dark.background?.paper,
    borderColor: "#676767",
  },
  badge: {
    //@ts-ignore
    hoverColor: themePalettes.dark.primary?.main,
  },
  selectedSettings: {
    backgroundColor: "#132149",
    textColor: "white",
    collapseColor: "#051c37",
    collapseHoverColor: "#122e4c",
  },
  setting: {
    backgroundColor: themePalettes.dark.background?.paper,
    hoverColor: lighten(themePalettes.dark.background?.paper ?? "", 0.1),
    textColor: "white",
    boxShadow: "#333333",
    borderColor: "#333333",
    testingBackgroundColor: "#69013c",
  },
};
