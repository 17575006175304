import { PaletteOptions } from "@mui/material/styles/createPalette";

const dark: PaletteOptions = {
  mode: "dark",
  background: {
    default: "#1B2635",
    paper: "#2d3748",
  },
  text: { primary: "#fff" },
  primary: {
    main: "#417AD5",
  },
  error: {
    main: "#D32F2F",
  },
  divider: "rgba(255, 255, 255, 0.85)",
};

export default dark;

export const themePalettes: { [id: string]: PaletteOptions } = {
  dark,
  light: {
    mode: "light",
    background: {
      default: "#f6f7f9",
      paper: "#ffffff",
    },
    text: {
      primary: "#000",
    },
    primary: {
      main: "#417AD5",
    },
    secondary: {
      main: "#BC5021",
    },
    error: {
      main: "#D32F2F",
    },
    divider: "rgba(0, 0, 0, 0.85)",
  },
  lilac: {
    mode: "dark",
    background: {
      default: "#261040",
      paper: "#482f67",
    },
    text: { primary: "#fff" },
    primary: {
      main: "#7642c3",
    },
    error: {
      main: "#D32F2F",
    },
    divider: "rgb(191,174,221)",
  },
  kwalee: {
    mode: "dark",
    background: {
      default: "#dbc20a",
      paper: "#b79c1a",
    },
    text: { primary: "#000" },
    primary: {
      main: "#000000",
    },
    error: {
      main: "#D32F2F",
    },
    divider: "rgb(164,151,41)",
  },
  classic: {
    mode: "light",
    background: {
      default: "#bababa",
      paper: "#ab6f12",
    },
    text: { primary: "#000000" },
    primary: {
      main: "#000000",
    },
    error: {
      main: "#D32F2F",
    },
    divider: "rgb(164,151,41)",
  },
};
