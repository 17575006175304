export const keyAlreadyExists = (
  key: string,
  newKey: string,
  keys: (string | number)[]
) => {
  // Get array of keys
  const indexOfOriginalKey = keys.indexOf(key);

  // Remove key
  if (indexOfOriginalKey > -1) {
    keys.splice(indexOfOriginalKey, 1);
  }

  for (const key of keys) {
    if (key === newKey) {
      return true;
    }
  }

  return false;
};
