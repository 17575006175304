import {
  FaFill,
  FaFirstOrder,
  FaGlobe,
  FaHatWizard,
  FaHome,
  FaList,
  FaTasks,
  FaUser,
  FaUserAlt,
  FaWrench,
} from "react-icons/all";
import AddToAll from "../features/AddToAll/AddToAll";
import { AppStoreAccount } from "../features/Appstore/AppstoreAccount";
import { ObjectivesCrud } from "../features/DailyTasks/Objectives/ObjectivesCrud";
import { TasksCrud } from "../features/DailyTasks/Tasks/TasksCrud";
import GameApp from "../features/GameApp/GameApp";
import Homepage from "../features/Homepage/Homepage";
import IECGenerator from "../features/IECGenerator/IECGenerator";
import { Areas } from "../features/Levels/GameSpecific/cassolitairehf/Areas/Areas";
import GameWizardRulesForm from "../features/GameWizardRulesForm/GameWizardRulesForm";
import ImportToAll from "../features/ImportToAll/ImportToAll";
import { Levels } from "../features/Levels/Levels";
import { SolitaireLevels } from "../features/Levels/GameSpecific/cassolitairehf/SolitaireLevels";
import LogsPage from "../features/LogsPage/LogsPage";
import { XpromoCampaigns } from "../features/XpromoCampaigns/XpromoCampaigns";
import { XpromoCreatives } from "../features/XpromoCreatives/XpromoCreatives";
import { NavigationItem } from "../model/navigation.model";
import { flattenPaths } from "../utils/flatttenPaths";
import { UserManager } from "../features/Users/UserManager";
import { UserPermission } from "../features/Users/UserPermission";
import { GameCreation } from "../features/GameCreation/GameCreation";
import { AdminConfig } from "../features/Users/AdminConfig";
import { ClientConfig } from "../features/Users/ClientConfig";
import Localisation from "../features/Localisation/Localisation";
import JanusSettingsPage from "../features/JanusSettings/JanusSettingsPage";
import { VersionedLevels } from "../features/Levels/VersionedLevels";
import { RestrictedGroupManager } from "../features/AnalyticsEventSampling/RestrictedGroupManager";
import { AnalyticsEvent } from "../features/AnalyticsEventSampling/AnalyticsEvent";
import { AnalyticsEventGroup } from "../features/AnalyticsEventSampling/AnalyticsEventGroup";

export const NavigationRoutes = (isSolitaire?: boolean) => {
  const navigationItems: NavigationItem[] = [
    {
      exact: true,
      icon: FaHome,
      id: "homepage",
      name: "Welcome to Game Admin",
      path: "/",
      hidden: true,
      component: Homepage,
    },
    {
      elements: [
        {
          component: isSolitaire ? SolitaireLevels : Levels,
          exact: true,
          icon: FaFill,
          id: "edit-levels",
          name: "Game Levels",
          path: "/edit-levels",
          subtitle:
            "Add, remove, modify and adjust order of levels for this game.",
        },
        {
          component: VersionedLevels,
          exact: true,
          icon: FaFill,
          id: "versioned-levels",
          name: "Versioned Levels",
          path: "/versioned-levels",
          subtitle:
            "Add, remove, modify and adjust order of versioned levels for this game.",
        },
        {
          component: Areas,
          exact: true,
          icon: FaFill,
          id: "level-areas",
          name: "Level Areas",
          path: "/level-areas",
          hidden: !isSolitaire,
          subtitle:
            "Add, remove, modify and adjust order of areas for this game.",
        },
      ],
      exact: true,
      icon: FaTasks,
      id: "levels",
      name: "Levels",
      path: "/levels",
    },
    {
      elements: [
        {
          component: LogsPage,
          exact: true,
          icon: FaFill,
          id: "data-logs",
          name: "Data Logs",
          path: "/data-logs",
          subtitle: "View logs for game admin object changes.",
        },
        {
          component: GameWizardRulesForm,
          exact: true,
          icon: FaHatWizard,
          id: "game-wizard-rules",
          name: "Game Wizard Rules",
          path: "/game-wizard-rules",
          subtitle: "Modify default game config and game settings.",
        },
        {
          component: UserManager,
          exact: true,
          icon: FaWrench,
          id: "/users",
          name: "User Manager",
          path: "/users",
          subtitle: "Create and edit user accounts.",
        },
        {
          component: UserPermission,
          exact: true,
          icon: FaWrench,
          id: "permission-roles",
          name: "User Permission Roles",
          path: "/permission-roles",
          subtitle: "Create and edit user permissions.",
        },
        {
          component: AdminConfig,
          exact: true,
          icon: FaFirstOrder,
          id: "admin-config",
          name: "Admin Config",
          path: "/admin-config",
          subtitle: "Admin Config",
        },
        {
          component: ClientConfig,
          exact: true,
          icon: FaFirstOrder,
          id: "client-config",
          name: "Client Config",
          path: "/client-config",
          subtitle: "Client Config",
        },

        {
          component: GameCreation,
          exact: true,
          icon: FaFirstOrder,
          id: "game-creation",
          name: "Game Creation",
          path: "/ops-tools",
          subtitle: "Create and update Games",
        },
      ],
      exact: true,
      icon: FaTasks,
      id: "ops",
      name: "Ops",
      path: "/ops",
    },
    {
      elements: [
        {
          component: AnalyticsEvent,
          exact: true,
          icon: FaWrench,
          id: "analytics-event",
          name: "Event Sampling",
          path: "/event-sampling",
          subtitle: "Create and edit Event Sampling.",
        },
        {
          component: AnalyticsEventGroup,
          exact: true,
          icon: FaWrench,
          id: "analytics-event-group",
          name: "Event Group",
          path: "/event-group",
          subtitle: "Create and edit Event Groups.",
        },
        {
          component: RestrictedGroupManager,
          exact: true,
          icon: FaWrench,
          id: "restricted-group-mannager",
          name: "Restricted Group Manager",
          path: "/restricted-group-manager",
          subtitle: "Create and edit Restricted Group Manager.",
        },
      ],
      exact: true,
      icon: FaTasks,
      id: "analytics",
      name: "Analytics",
      path: "/analytics",
    },
    {
      elements: [
        {
          component: ObjectivesCrud,
          exact: true,
          icon: FaFirstOrder,
          id: "objectives",
          name: "Objectives",
          path: "/objectives",
          subtitle: "Create and update Objectives for Daily Tasks",
        },
        {
          component: TasksCrud,
          exact: true,
          icon: FaFirstOrder,
          id: "daily-tasks",
          name: "Daily Tasks",
          path: "/daily-tasks",
          subtitle: "Create and update Daily Tasks",
        },
      ],
      exact: true,
      icon: FaUser,
      id: "players",
      name: "Players",
      path: "/players",
      isOldAdmin: true,
      oldAdminURL: "/players.html",
    },
    {
      elements: [
        {
          component: XpromoCampaigns,
          exact: true,
          icon: FaFirstOrder,
          id: "xpromo-campaigns",
          name: "Xpromo Campaigns",
          path: "/xpromo-campaigns",
          subtitle: "Create and update Xpromo campaigns",
        },
        {
          component: XpromoCreatives,
          exact: true,
          icon: FaFirstOrder,
          id: "xpromo-creatives",
          name: "Xpromo Creatives",
          path: "/xpromo-creatives",
          subtitle: "Create and update Xpromo creatives",
        },
        {
          component: IECGenerator,
          exact: true,
          icon: FaFirstOrder,
          id: "iec-generator",
          name: "IEC Generator",
          path: "/iec-generator",
          subtitle: "Generate IECs",
        },
      ],
      exact: true,
      icon: FaGlobe,
      id: "marketing",
      name: "Marketing",
      path: "/marketing",
    },
    {
      elements: [
        {
          component: GameApp,
          exact: true,
          icon: FaWrench,
          id: "game-app",
          name: "Game App",
          path: "/game-app",
          subtitle: "Create and modify game apps",
        },
        {
          component: AddToAll,
          exact: true,
          icon: FaWrench,
          id: "game-settings-page",
          name: "Game Settings",
          path: "/game-settings",
          subtitle: "Create and modify game settings",
        },
        {
          component: JanusSettingsPage,
          exact: true,
          icon: FaWrench,
          id: "janus-settings-page",
          name: "Janus Settings",
          path: "/janus-settings",
          subtitle: "Create and modify Janus settings",
        },
        {
          //@ts-ignore
          component: ImportToAll,
          icon: FaWrench,
          id: "import-game-settings",
          name: "Import Game Settings",
          path: "/import-game-settings/:leftSettingsId?/:rightSettingsId?",
          subtitle: "Import external settings into game settings",
          hidden: true,
        },
        {
          component: Localisation,
          exact: true,
          icon: FaWrench,
          id: "localisation-settings-page",
          name: "Localisation Settings",
          path: "/localisation-settings",
          subtitle: "Create and modify localisation settings",
        },
      ],
      exact: true,
      icon: FaWrench,
      id: "game-settings",
      name: "Game Settings",
      path: "/game-settings",
    },
    {
      elements: [
        {
          component: AppStoreAccount,
          exact: true,
          icon: FaList,
          id: "app-store-account",
          name: "App Store Accounts",
          path: "/app-store-account",
          subtitle: "App store account page",
          requiresSuperuser: true,
        },
      ],
      exact: true,
      icon: FaUserAlt,
      id: "admin",
      name: "Admin",
      path: "/admin",
      requiresSuperuser: true,
    },
  ];

  return navigationItems;
};

export const flattenedRoutes: NavigationItem[] = flattenPaths(
  NavigationRoutes()
);

export const getPath = (id: string): string | undefined => {
  return flattenedRoutes.find((route) => route.id === id)?.path;
};
