import { getData } from "../../network/request";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminLogPostData } from "../../model/log";

export const fetchLogs = createAsyncThunk(
  "logs/fetch",
  async (data?: Partial<AdminLogPostData>) => {
    return await getData("data/logs", data);
  }
);

export const fetchConfig = createAsyncThunk("config/fetch", async () => {
  return await getData("admin/config");
});
