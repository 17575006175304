import { axiosConfig, getServerURL } from "../../data/AxiosConfig";
import { getRequestAdditions, RequestAdditions } from "../../network/request";
import { v4 } from "uuid";

export default async function compressFile(file: File) {
  const API_URL = getServerURL();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("filename", file.name);
  formData.append("field_name", "iec_generator");
  formData.append("object_id", v4());

  const config = {
    baseURL: API_URL,
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  // Append request additions
  const requestAdditions = getRequestAdditions();
  Object.keys(requestAdditions).forEach((key) => {
    formData.append(key, requestAdditions[key as keyof RequestAdditions]);
  });

  // Send file for compression
  const result = await axiosConfig.post(
    "admin/promotion-creatives/file-upload",
    formData,
    config
  );

  // Download new file
  const url = result.data.url;
  const response = await fetch(`https://d2kre6881rwtik.cloudfront.net/${url}`);
  const data = await response.blob();
  const metadata = {
    type: file.type,
  };

  // Return new file object
  return new File([data], file.name, metadata);
}
