import { createSlice } from "@reduxjs/toolkit";
import {
  ReactSelectArray,
  TooltipState,
} from "../../../../model/versioned-settings.model";

interface FilterState {
  darkMode?: boolean;
  editMode?: boolean;
  filterCollapsed?: boolean;
  selectedSettingsCollapse?: boolean;
  dropdowns?: { [id: string]: ReactSelectArray | undefined };
  searchTerm?: string;
  switcher?: string;
  allPlatforms: any[];
  versionOptions?: any;
  tooltip: TooltipState;
  hideTooltips: boolean;
  hideModified: boolean;
  hideDefaultSettings: boolean;
}

export const blankTooltip = {
  target: null,
  text: "",
};

const initialState: FilterState = {
  allPlatforms: [
    { value: "ios", label: "iOS" },
    { value: "android", label: "Android" },
    { value: "amazon", label: "Amazon" },
    { value: "chinaios", label: "China iOS" },
  ],
  hideModified: false,
  hideTooltips: true,
  hideDefaultSettings: false,
  tooltip: blankTooltip,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
    toggleEditMode(state) {
      state.editMode = !state.editMode;
    },
    setEditMode(state, action) {
      state.editMode = action.payload;
    },
    toggleFilterCollapse(state) {
      state.filterCollapsed = !state.filterCollapsed;
    },
    toggleSelectedSettingsCollapse(state) {
      state.selectedSettingsCollapse = !state.selectedSettingsCollapse;
    },
    updateDropdown(state, action) {
      if (!state.dropdowns) {
        state.dropdowns = {};
      }
      state.dropdowns[action.payload.dropdown] = action.payload.selected;
    },
    updateSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    updateSwitcher(state, action) {
      state.switcher = action.payload;
    },
    setVersionOptions(state, action) {
      state.versionOptions = action.payload;
    },
    setTooltip(state, action) {
      state.tooltip = action.payload;
    },
    setHideModified(state, action) {
      state.hideModified = action.payload;
    },
    setHideTooltips(state, action) {
      state.hideTooltips = action.payload;
    },
    setHideDefaultSettings(state, action) {
      state.hideDefaultSettings = action.payload;
    },
  },
});

export const darkModeSelector = (state: FilterState) => state.darkMode;
export const editModeSelector = (state: any) => state.filterReducer.editMode;
export const filterCollapsedSelector = (state: any) =>
  state.filterReducer.filterCollapsed;
export const dropdownsSelector = (state: any): FilterState["dropdowns"] =>
  state.filterReducer.dropdowns;
export const searchTermSelector = (state: any) =>
  state.filterReducer.searchTerm;
export const switcherSelector = (state: FilterState) => state.switcher;
export const selectedSettingsCollapseSelector = (state: FilterState) =>
  state.selectedSettingsCollapse;
export const selectAllPlatforms = (state: any) =>
  state.filterReducer.allPlatforms;
export const selectVersionOptions = (state: any) =>
  state.filterReducer.versionOptions;

export const selectTooltip = (state: any) => state.filterReducer.tooltip;
export const selectHideModified = (state: any) =>
  state.filterReducer.hideModified;
export const selectHideDefaultSettings = (state: any) =>
  state.filterReducer.hideDefaultSettings;
export const selectHideTooltips = (state: any) =>
  state.filterReducer.hideTooltips;

export const {
  toggleDarkMode,
  toggleEditMode,
  toggleFilterCollapse,
  toggleSelectedSettingsCollapse,
  updateDropdown,
  updateSearchTerm,
  updateSwitcher,
  setVersionOptions,
  setEditMode,
  setTooltip,
  setHideTooltips,
  setHideModified,
  setHideDefaultSettings,
} = filterSlice.actions;

export default filterSlice.reducer;
