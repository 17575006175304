import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { store } from "../../../app/store";
import {
  CrudKeys,
  selectIsSaving,
} from "../../../components/CollectionEditor/crudSlice";
import { validateForm } from "../../../components/CollectionEditor/Fields/validateForm";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { AutoModalCloser } from "../../../components/CollectionEditor/Modals/AutoModalCloser";
import { FieldType, Form } from "../../../model/crud.model";
import {
  queueNotification,
  SnackbarVariant,
} from "../../notification/notificationSlice";

interface DeleteConfirmDialogProps {
  page: keyof CrudKeys;
  isOpen: boolean;
  onClose: () => void;
  createItem?: (obj: any) => void;
  formValue: any;
}

export const getPageName = (pageName: string): keyof CrudKeys => {
  if (pageName === "ctest" || pageName === "cpitest") {
    return "campaigns";
  } else {
    return pageName as keyof CrudKeys;
  }
};

export const RenewDialog = ({
  isOpen,
  onClose,
  createItem,
  formValue,
  page,
}: DeleteConfirmDialogProps) => {
  const isSaving = useSelector(selectIsSaving);
  const cancelRef = React.createRef<HTMLButtonElement>();

  const [newValues, setObj] = useState({});

  const defaultStartDate = moment().utc().endOf("day").valueOf();
  const defaultEndDate = moment(defaultStartDate)
    .utc()
    .add(formValue.end - formValue.start, "millisecond")
    .valueOf();

  const model: Form = [
    {
      main_field: true,
      display_name: "Start",
      name: "start",
      type: FieldType.DATETIME,
      required: true,
      defaultValue: defaultStartDate,
      locale: false,
    },
    {
      display_name: "End",
      name: "end",
      type: FieldType.DATETIME,
      required: true,
      defaultValue: defaultEndDate,
      locale: false,
    },
    {
      display_name: "Budget",
      name: "budget",
      type: FieldType.NUMBER,
      required: true,
      defaultValue: formValue.budget,
    },
  ];

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={onClose}>
      <AutoModalCloser onClose={onClose} page={page} />
      <DialogTitle>Renew Campaign?</DialogTitle>

      <DialogContent>
        <p>Select the values below to renew.</p>

        <FormContent
          formValue={newValues}
          model={model}
          page={page}
          setFormValue={setObj}
          isCreate
        />
      </DialogContent>
      <DialogActions>
        <Button ref={cancelRef} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaving[getPageName(page)]}
          onClick={() => {
            if (!validateForm(newValues, model)) {
              store.dispatch(
                queueNotification({
                  message: "Please fill in all required fields",
                  options: {
                    key: "invalid_form",
                    variant: SnackbarVariant.ERROR,
                  },
                })
              );
            } else {
              // Reset probabilities to 0
              const newProbs: { [id: string]: number[] } = {};
              const currentProbs: { [id: string]: number[] } =
                formValue.probabilities_per_country;

              Object.keys(currentProbs ?? {}).forEach((country) => {
                currentProbs[country].forEach((prob) => {
                  if (newProbs[country]) {
                    newProbs[country].push(0);
                  } else {
                    newProbs[country] = [];
                  }
                });
              });

              createItem &&
                createItem({
                  ...formValue,
                  ...newValues,
                  probabilities_per_country: newProbs,
                });
            }
          }}
        >
          Renew
        </Button>
      </DialogActions>
    </Dialog>
  );
};
