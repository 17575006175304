import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SaveIcon from "@mui/icons-material/Save";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLocalisation,
  useGetLocalisation,
  useGetLocalisationObject,
} from "../../network/useLocalisation";
import useDebounce from "../../utils/useDebounce";
import usePrevState from "../../utils/usePrevState";
import {
  queueNotification,
  SnackbarVariant,
} from "../notification/notificationSlice";
import { selectSession } from "../session/sessionSlice";
import LocalisationImportModal from "./LocalisationImportModal";
import LocalisationModal from "./LocalisationModal";
import { LocalisationDataRow } from "./types";
import { useSearchParams } from "./useSearchParams";

const useStyles = makeStyles((theme: Theme) => ({
  stickyColumn: {
    position: "sticky",
    left: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
    width: 200,
  },
  column: {
    zIndex: 0,
    width: 200,
  },
}));

export default function Localisation() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { game_id } = useSelector(selectSession);

  const [{ version: selectedVersion }, setSearchParams] =
    useSearchParams<"version">();

  const { localisations, isLoading, mutate } = useGetLocalisation();
  const { localisationObjectData } = useGetLocalisationObject(selectedVersion);

  const [editedLocalisation, setEditedLocalisation] = useState<
    LocalisationDataRow[]
  >([]);
  const [languages, setLanguages] = useState<string[]>([]);

  const localisationVersions = Object.values(localisations ?? {}).sort(
    (a, b) => b.loc_version - a.loc_version
  );

  const [isVersionMapModalOpen, setVersionMapModalOpen] = useState(false);
  const [isImportModalOpen, setImportModalOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - editedLocalisation.length)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (localisationVersions.length < 1 || !!selectedVersion) {
      return;
    }

    setSearchParams({ version: localisationVersions[0].object_id });
  }, [localisationVersions, selectedVersion, setSearchParams]);

  useEffect(() => {
    if (!localisationObjectData) {
      return;
    }

    const newLocalisation = localisationObjectData.map((item) => ({
      ...item,
    }));

    setEditedLocalisation(newLocalisation);

    const uniqueLanguages = Array.from(
      new Set(
        localisationObjectData.flatMap((item) =>
          Object.keys(item).filter((key) => key !== "id")
        )
      )
    ).sort((a, b) => a.localeCompare(b));
    setLanguages(uniqueLanguages);
  }, [localisationObjectData]);

  const getLocalisationLabel = useCallback(
    (object_id: string) => {
      const version = localisationVersions.find(
        (item) => item.object_id === object_id
      );
      if (!version) {
        return object_id;
      }

      return `Ver. ${version.loc_version} - ${moment(version.created_on).format(
        "DD/MM/YYYY"
      )}`;
    },
    [localisationVersions]
  );

  const onVersionChange = useCallback(
    (version: string) => {
      setSearchParams({ version });
    },
    [setSearchParams]
  );

  const onImportModalClose = useCallback(
    (data?: LocalisationDataRow[]) => {
      if (data !== undefined) {
        const newLocalisation = [...editedLocalisation];
        for (const row of data) {
          const index = newLocalisation.findIndex((item) => item.id === row.id);
          if (index === -1) {
            // Add missing languages to row
            for (const language of languages) {
              if (row[language] === undefined) {
                row[language] = "";
              }
            }

            newLocalisation.push(row);
          } else {
            // Merge objects
            const newRow = newLocalisation[index];
            for (const [key, value] of Object.entries(row)) {
              if (key === "id") {
                continue;
              }

              newRow[key] = value;
            }
          }
        }

        setEditedLocalisation(newLocalisation);

        const uniqueLanguages = Array.from(
          new Set(
            newLocalisation.flatMap((item) =>
              Object.keys(item).filter((key) => key !== "id")
            )
          )
        ).sort((a, b) => a.localeCompare(b));
        setLanguages(uniqueLanguages);
      }

      setImportModalOpen(false);
    },
    [setImportModalOpen, setEditedLocalisation]
  );

  const onVersionSave = useCallback(async () => {
    try {
      const newObject = await updateLocalisation(game_id!, editedLocalisation);
      setSearchParams({ version: newObject.object_id });
      mutate();

      dispatch(
        queueNotification({
          message: "Localisation saved successfully",
          options: {
            key: "localisation_save_success",
            variant: SnackbarVariant.SUCCESS,
          },
        })
      );
    } catch (e) {
      console.error(e);

      dispatch(
        queueNotification({
          message: "Failed to save localisation version, please try again...",
          options: {
            key: "localisation_save_fail",
            variant: SnackbarVariant.ERROR,
          },
        })
      );
    }
  }, [dispatch, editedLocalisation, game_id, setSearchParams]);

  return (
    <>
      <Box textAlign="right" mb={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setVersionMapModalOpen(true)}
        >
          Version Map
        </Button>
      </Box>

      <Box display="flex" gap={2} alignItems="center" mb={2}>
        <Autocomplete
          fullWidth
          disableClearable
          size="small"
          sx={{ flex: 1 }}
          loading={isLoading}
          options={localisationVersions.map((item) => item.object_id)}
          value={(selectedVersion ?? null) as string}
          getOptionLabel={getLocalisationLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a version"
              variant="outlined"
              fullWidth
            />
          )}
          onChange={(event, newValue) => onVersionChange(newValue)}
        />

        <Button
          color="primary"
          variant="outlined"
          onClick={() => setImportModalOpen(true)}
        >
          Import CSV
        </Button>

        <Button color="primary" variant="contained" onClick={onVersionSave}>
          Save Localisation
        </Button>
      </Box>

      <TableContainer>
        <Table stickyHeader style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.stickyColumn} style={{ zIndex: 2 }}>
                Key
              </TableCell>
              {languages.map((language) => (
                <TableCell
                  key={language}
                  className={classes.column}
                  style={{ zIndex: 1 }}
                >
                  <KeyField
                    value={language}
                    onChange={(value) => {
                      const newLocalisation = editedLocalisation.map(
                        (item) => ({ ...item })
                      );
                      for (const item of newLocalisation) {
                        const temp = item[language];
                        delete item[language];
                        item[value] = temp;
                      }

                      setEditedLocalisation(newLocalisation);

                      const newLanguages = [...languages];
                      const index = newLanguages.indexOf(language);
                      newLanguages[index] = value;
                      setLanguages(
                        newLanguages.sort((a, b) => a.localeCompare(b))
                      );
                    }}
                    onDelete={() => {
                      const newLocalisation = editedLocalisation.map(
                        (item) => ({ ...item })
                      );
                      for (const item of newLocalisation) {
                        delete item[language];
                      }

                      setEditedLocalisation(newLocalisation);

                      const newLanguages = [...languages];
                      const index = newLanguages.indexOf(language);
                      newLanguages.splice(index, 1);
                      setLanguages(newLanguages);
                    }}
                    existingKeys={languages.filter((l) => l !== language)}
                  />
                </TableCell>
              ))}
              <TableCell className={classes.column} style={{ zIndex: 1 }}>
                <AddKeyField
                  onCreate={(value) => {
                    const newLocalisation = editedLocalisation.map((item) => ({
                      ...item,
                    }));
                    for (const item of newLocalisation) {
                      item[value] = "";
                    }

                    setEditedLocalisation(newLocalisation);

                    const newLanguages = [...languages];
                    newLanguages.push(value);
                    setLanguages(
                      newLanguages.sort((a, b) => a.localeCompare(b))
                    );
                  }}
                  existingKeys={languages}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? editedLocalisation.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : editedLocalisation
            ).map((row, _index) => {
              const index = _index + page * rowsPerPage;

              return (
                <TableRow key={row.id}>
                  <TableCell
                    className={classes.stickyColumn}
                    component="th"
                    scope="row"
                  >
                    <KeyField
                      value={row.id}
                      onChange={(value) => {
                        const newLocalisation = [...editedLocalisation];
                        newLocalisation[index] = {
                          ...newLocalisation[index],
                          id: value,
                        };
                        setEditedLocalisation(newLocalisation);
                      }}
                      onDelete={() => {
                        const newLocalisation = [...editedLocalisation];
                        newLocalisation.splice(index, 1);
                        setEditedLocalisation(newLocalisation);
                      }}
                      existingKeys={editedLocalisation
                        .map((item) => item.id)
                        .filter((id) => id !== row.id)}
                    />
                  </TableCell>
                  {languages.map((language) => (
                    <TableCell key={language} className={classes.column}>
                      <TableCellField
                        value={row[language]}
                        onChange={(value) => {
                          const newLocalisation = [...editedLocalisation];
                          newLocalisation[index] = {
                            ...newLocalisation[index],
                            [language]: value,
                          };
                          setEditedLocalisation(newLocalisation);
                        }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell
                className={classes.stickyColumn}
                component="th"
                scope="row"
              >
                <AddKeyField
                  onCreate={(value) => {
                    const newLocalisation = [...editedLocalisation];
                    newLocalisation.push({
                      id: value,
                      ...languages.reduce((acc, language) => {
                        acc[language] = "";
                        return acc;
                      }, {} as { [key: string]: string }),
                    });
                    setEditedLocalisation(newLocalisation);
                    setPage(
                      Math.ceil(newLocalisation.length / rowsPerPage) - 1
                    );
                  }}
                  existingKeys={editedLocalisation.map((item) => item.id)}
                />
              </TableCell>
            </TableRow>

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex">
        <TablePagination
          rowsPerPageOptions={[5, 15, 25, 50, 75, { label: "All", value: -1 }]}
          colSpan={3}
          count={editedLocalisation.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ flex: 1 }}
        />
      </Box>

      <LocalisationModal
        open={isVersionMapModalOpen}
        onClose={() => setVersionMapModalOpen(false)}
      />

      <LocalisationImportModal
        open={isImportModalOpen}
        onClose={onImportModalClose}
      />
    </>
  );
}

type TableCellFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

function TableCellField({ value, onChange }: TableCellFieldProps) {
  const prevValue = usePrevState(value);
  const [temp, setTemp] = useState(value);
  const debouncedTemp = useDebounce(temp, 500);

  useEffect(() => {
    setTemp(value);
  }, [value]);

  const triggerChange = useCallback(() => {
    if (value === prevValue && temp !== value) {
      onChange(temp);
    }
  }, [temp, value, onChange]);

  useEffect(() => {
    triggerChange();
  }, [debouncedTemp, triggerChange]);

  return (
    <TextField
      fullWidth
      size="small"
      variant="standard"
      value={temp}
      onChange={(event) => setTemp(event.target.value)}
      onBlur={triggerChange}
    />
  );
}

type KeyFieldProps = {
  value: string;
  onChange: (value: string) => void;
  onDelete: () => void;
  existingKeys: string[];
};

function KeyField({ value, onChange, onDelete, existingKeys }: KeyFieldProps) {
  const prevValue = usePrevState(value);
  const [temp, setTemp] = useState(value);

  useEffect(() => {
    setTemp(value);
  }, [value]);

  const triggerChange = useCallback(() => {
    if (value === prevValue && temp !== value) {
      onChange(temp);
    }
  }, [temp, value, onChange]);

  const error = useMemo(() => {
    if (existingKeys.includes(temp)) {
      return "Key already exists";
    }

    return undefined;
  }, [temp, existingKeys]);

  return (
    <TextField
      fullWidth
      size="small"
      variant="standard"
      value={temp}
      onChange={(event) => setTemp(event.target.value)}
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position="end">
              <IconButton
                edge="end"
                disabled={!!error || value === temp}
                onClick={triggerChange}
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
            <InputAdornment position="end">
              <IconButton edge="end" onClick={onDelete}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}

type AddKeyFieldProps = {
  onCreate: (value: string) => void;
  existingKeys: string[];
};

function AddKeyField({ onCreate, existingKeys }: AddKeyFieldProps) {
  const [temp, setTemp] = useState("");

  const error = useMemo(() => {
    if (existingKeys.includes(temp)) {
      return "Key already exists";
    }

    return undefined;
  }, [temp, existingKeys]);

  const triggerCreate = () => {
    onCreate(temp);
    setTemp("");
  };

  return (
    <TextField
      fullWidth
      size="small"
      variant="standard"
      value={temp}
      onChange={(event) => setTemp(event.target.value)}
      error={!!error}
      helperText={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              disabled={!!error || temp.trim().length < 1}
              onClick={triggerCreate}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
