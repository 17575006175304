import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NestedSetting } from "../../../../model/versioned-settings.model";
import { getObjectDepth } from "../../../../utils/getObjectDepth";
import { removeSettingMetadata } from "../../../../utils/removeSettingMetadata";
import isNullOrUndefined from "../../../../utils/isNullOrUndefined";
import { ListItemIcon, ListItemText } from "@mui/material";

const generateCSVLines = (
  value: any,
  maxDepth: number,
  currentDepth = 0
): string[] => {
  const line: string[] = [];

  if (Array.isArray(value)) {
    for (const [index, val] of Object.entries(value)) {
      line.push(...generateLine(val, maxDepth, currentDepth, index.toString()));
    }
  } else if (typeof value === "object") {
    const keys = Object.keys(value).sort((a, b) => a.localeCompare(b));
    for (const key of keys) {
      line.push(...generateLine(value[key], maxDepth, currentDepth, key));
    }
  }

  return line;
};

const generateLine = (
  value: any,
  maxDepth: number,
  currentDepth = 0,
  key?: string
) => {
  const line: string[] = [];

  for (let i = 0; i < currentDepth; i++) {
    line.push(",");
  }

  if (key) {
    line.push(key);
  }
  if (isNullOrUndefined(value)) {
    line.push(",", " ");
  } else if (typeof value === "string") {
    line.push(",", value);
  } else if (typeof value === "number") {
    line.push(",", value.toString());
  } else if (typeof value === "boolean") {
    line.push(",", value.toString());
  }

  for (let i = currentDepth; i < maxDepth; i++) {
    line.push(",");
  }

  line.push("\n");

  const newLines = generateCSVLines(value, maxDepth, currentDepth + 1);
  line.push(...newLines);

  return line;
};

const downloadCSV = (file: string, filename: string) => {
  const blob = new Blob([file], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const generateAndDownloadCSV = (
  event: { stopPropagation: () => void },
  settingName: string,
  settingData: Partial<NestedSetting> &
    Pick<NestedSetting, "__value__" & "__type__">
) => {
  event.stopPropagation();

  const settingWithoutMetaData = {
    [settingName]: removeSettingMetadata(settingData),
  };

  const maxDepth = getObjectDepth(settingWithoutMetaData);

  const csvArray = generateCSVLines(settingWithoutMetaData, maxDepth);

  const csvFile = csvArray.join("");

  downloadCSV(csvFile, `${settingName}-${Date.now().toLocaleString()}.csv`);
};

const DownloadCSVButton = ({
  settingData,
  settingName,
}: {
  settingData: NestedSetting;
  settingName: string;
}) => {
  return (
    <>
      <ListItemIcon>
        <FontAwesomeIcon icon={faFileDownload} />
      </ListItemIcon>
      <ListItemText
        primary="Download CSV"
        onClick={(event) =>
          generateAndDownloadCSV(event, settingName, settingData)
        }
      />
    </>
  );
};

export default DownloadCSVButton;
