import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormContent } from "../../../components/CollectionEditor/FormContent";
import { FieldType, Form } from "../../../model/crud.model";
import { saveLevelOrder } from "../levelOrderService";
import { ILeverOrder } from "../../../model/level.model";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  gameEditionOptions: any;
  categories: any;
  orderedLevelIds?: string[];
  levelOrders: ILeverOrder[];
  levelFilters: any;
}

export default function CopyLevelOrderModal(props: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filtersModel: Form = [
    {
      display_name: "Category",
      name: "category_id",
      type: FieldType.SELECT,
      options: props.categories,
      defaultValue: "",
      hidden: true,
    },
    {
      display_name: "Copy From",
      name: "copy_from",
      type: FieldType.SELECT,
      options: props.gameEditionOptions,
      defaultValue: "",
    },
    {
      display_name: "Copy To",
      name: "game_edition_id",
      type: FieldType.SELECT,
      options: props.gameEditionOptions,
      defaultValue: "",
    },
  ];

  const [filters, setFilters] = React.useState<{
    [id: string]: any;
  }>({
    copy_from: "ios",
    game_edition_id: "ios",
    category_id: "default",
  });

  const currentLevelOrder: ILeverOrder | undefined = props.levelOrders?.find(
    (order: ILeverOrder) =>
      order?.game_edition_id === filters.copy_from &&
      order?.category_id === filters.category_id
  );

  return (
    <React.Fragment>
      <Button onClick={handleOpen} variant="outlined" sx={{ ml: 2 }}>
        Copy Level Order
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to copy the level order?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Where would you like to copy this level order to?
          </Typography>
          <Typography textAlign="center" sx={{ mt: 2 }}>
            {`${props.gameEditionOptions[filters.copy_from]}, Level Order → ${
              props.gameEditionOptions[filters.game_edition_id]
            }, Level Order`}
          </Typography>
          <FormContent
            formValue={filters}
            model={filtersModel}
            page="level_order"
            setFormValue={setFilters}
            incorporateLabels
          />

          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => {
              // check if level orders are already created for the selected game edition
              const current: ILeverOrder[] = props.levelOrders
                .filter(
                  (i, idx) => i.game_edition_id === filters.game_edition_id
                )
                .map((j, jdx) => ({
                  ...j,
                  ordered_level_ids: props.orderedLevelIds?.filter(
                    (item, index) =>
                      currentLevelOrder?.ordered_level_ids.includes(item)
                  ) as string[],
                }));

              saveLevelOrder(
                current.length ? current : undefined,
                props.orderedLevelIds?.filter((item, index) =>
                  currentLevelOrder?.ordered_level_ids.includes(item)
                ),
                {
                  game_edition_id: filters.game_edition_id,
                  category_id: filters.category_id,
                },
                props.levelOrders
              );
              handleClose();
            }}
          >
            Save Level Order
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
