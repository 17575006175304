import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { IImageResource } from "../model/game-app.model";
import { getData } from "./request";

type ImageResources = {
  image_resources: IImageResource[];
  isLoading: boolean;
  isError: boolean;
};

export function useImageResources() {
  const { data, error } = useSWR(Endpoints.IMAGE_RESOURCES.url, getData);

  const imageResources: ImageResources = {
    image_resources:
      data &&
      (data as { [id: string]: any })[Endpoints.IMAGE_RESOURCES.object_path],
    isLoading: !error && !data,
    isError: error,
  };

  return imageResources;
}
