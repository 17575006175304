import { useSelector } from "react-redux";
import { gameEditionsSelector } from "../features/AddToAll/addToAllSlice";

export const useReactSelectGameEditions = () => {
  const gameEditions = useSelector(gameEditionsSelector);

  return gameEditions?.map((edition: { display_name: any; name: any }) => ({
    label: edition.display_name,
    value: edition.name,
  }));
};
