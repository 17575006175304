import { FormItem } from "../../model/crud.model";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export const getDisplayName = (
  model: FormItem[],
  obj?: any,
  displayNameTemplate?: string,
  page?: string
) => {
  const keyField = model?.find((field) => field.main_field)?.name;

  let displayName = keyField && obj && obj[keyField];
  if (!isNullOrUndefined(displayNameTemplate)) {
    displayName = displayNameTemplate.replace(/{(\w+)}/g, function (_, k) {
      let value;
      try {
        value = JSON.parse(obj[k]);
      } catch {
        value = obj[k];
      }
      return typeof value === "object" ? value[""] : value;
    });
  }

  return displayName;
};
