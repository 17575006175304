import * as React from "react";
import { CrudKeys } from "../components/CollectionEditor/crudSlice";

export enum FieldType {
  STRING = "string",
  SELECT = "select",
  MULTISELECT = "multiselect",
  NUMBER = "number",
  ARRAY = "array",
  CHECKBOX = "checkbox",
  CUSTOM = "custom",
  PASSWORD = "password",
  MULTIARRAY = "multiarray",
  COLLECTION = "collection",
  FILEUPLOAD = "fileupload",
  TIMEINPUT = "timeinput",
  OBJECT = "object",
  DATETIME = "datetime",
  PROBABILITY = "probability",
  MULTIPLEVIEWS = "multiple_views",
  BUTTON_MODAL = "button_modal",
}

export interface FormItem {
  /* The main field will automatically be chosen for as the display name in dropdowns and other functions */
  main_field?: boolean;
  display_name: string;
  name: string;
  type: FieldType;
  required?: boolean;
  options?: { [id: string]: string } | any[];
  /* Allows for nested multiselect options, parent options can be selected/deselected, selecting/deselecting all children */
  nestedOptions?: {
    [id: string]: { name: string; options: { value: string; label: string }[] };
  };
  disabled?: boolean;
  /* Function to check some condition of the field value or object value to determine whether to disable */
  disabledFunction?: (value: any, obj?: any) => boolean;
  defaultValue?: any;
  /* Model for the value of an array or object field */
  itemModel?: FormItem[];
  /* Model for the key of an object field, e.g. Country dropdown */
  keyModel?: FormItem;
  /* The SWR collection response to allow for mutating and updating if field is a collection from an endpoint */
  collectionResponse?: any;
  /* Details the form model to use if field is a Collection */
  collectionModel?: FormItem[];
  /* Display name for collections */
  getDisplayName?: (obj: any) => string;
  /* Parent field key name if a nested model */
  parentField?: string;
  hidden?: boolean;
  /* Function to check some condition of the field value or object value to determine whether to hide */
  hiddenFunction?: (value: any, obj?: any) => boolean;
  notAvailableOnCreate?: boolean;
  onlyAvailableOnCreate?: boolean;
  disabledOnCreate?: boolean;
  helperText?: string;
  /* Overrides the onchange function of the field */
  onChangeOverride?: (value?: any, obj?: any) => { [id: string]: any } | void;
  /* Returns a object id array to provide as a prop to the Collection field type, e.g. for use with a nested collection from an endpoint */
  value?: (obj?: any) => string[];
  /* Filters the options of a select or multiselect based on a certain condition, receives the filterValue prop as a parameter, returns the filtered options */
  filterOptions?: (options: any[]) => { [id: string]: any };
  /* The values to pass to filter options function */
  filterValue?: (obj: any) => any[];
  /* A nested form model to provide if the field type is a Collection */
  nestedModel?: boolean;
  /* The page the crud exists on if conditional behaviour is required */
  page?: keyof CrudKeys;
  /* Use base value instead of object structure, e.g. 5 instead of { fieldName: 5 }, mainly used in input arrays */
  useAtomicValue?: boolean;
  /* Multiple Material UI tabs splitting a section of the form */
  views?: { title: string; fields: Form }[];
  /* Should the form label be hidden */
  hideLabel?: boolean;
  /* Function to determine the current value if the field is derived */
  valueFunction?: (obj?: any) => any;
  /* Function to determine whether field should display an error based on the current form value */
  errorFunction?: (obj?: any) => IError;
  /* Metric to default time input field to e.g. TimeInput.MILLISECONDS */
  defaultMetric?: string;
  /* The url to upload a file field to */
  fileUploadUrl?: string;
  /* Displays an option to import a JSON file as the value of the field */
  importValueFromJSON?: boolean;
  /* Provides suggestions for the field's value, can be pulled from an endpoint */
  suggestions?: string | { [id: string]: any } | any[];
  /* If the field requires a collection it can be provided here */
  suggestionsCollection?: { [id: string]: any };
  /* Material UI Autocomplete disabled option */
  isOptionDisabled?: (option?: any, obj?: any) => boolean;
  /* Material UI Autocomplete option override */
  renderOptionOverride?: (option: {
    value: string;
    label: string;
  }) => React.ReactNode;
  renderOverride?: (
    option: any,
    onChangeFunction: any,
    value: any,
    formValue?: { [id: string]: any }
  ) => React.ReactNode;
  /* Material UI Autocomplete string to group values by, options must be sorted by this value */
  groupBy?: (option: { value: string; label: string }) => string;
  /* Modal to be opened when onChange is triggered, will open a separate modal */
  modal?: (setParentFormValue: any) => JSX.Element;
  /* Allows changing of the key to be used as the searchable label in a material autocomplete dropdown */
  changeAutocompleteLabel?: (label: any) => void;
  autoCompleteLabelValue?: string;
  /* Whether the array can be added to and subtracted from */
  fixedSize?: boolean;
  /* If the label should be part of the field or separate */
  incorporatedLabel?: boolean;
  /** the type of date format to use */
  format?: string;
  /** whether to show datetime in locale or utc */
  locale?: boolean;

  /* The minimum value for the field if a number */
  min?: number;
  /* The maximum value for the field if a number */
  max?: number;
  /* The step value for the field if a number */
  step?: number;
  maxLength?: number;
  id?: string;
  multiselect?: boolean;
  limitTags?: number;
  chipSize?: "small" | "medium" | undefined;
  loading?: boolean;
  renderSelectGroup?: (params: any) => React.ReactNode;
  selected?: any;
  onOptionSelected?: (
    event: React.ChangeEvent<{}>,
    selectedOption: any,
    reason: string
  ) => any;
  label?: string;
  placeholder?: string;
  selectOptions?: any;
  selectSize?: "small" | "medium" | undefined;
  needGrouping?: boolean;

  /* If the field is a localised text field - follows the format {"": "test", "DE": "prüfung" } */
  isLocalised?: boolean;

  /* Used by collection field type - denotes a single object rather than an array of objects */
  isSingleton?: boolean;
  disableOnUpdate?: boolean;
}

export interface IError {
  error: boolean;
  message?: string;
}

export type Form = FormItem[];

export interface CollectionEditorOptions {
  disableAddButton?: (val: any) => boolean;
  disabledValueKey?: string;
}

export interface IExternalObjectForNesting {
  fieldName: string;
  keysToOverride: string[];
  data: any[];
  model: Form;
}

export enum TimeMetric {
  MILLISECONDS = "milliseconds",
  SECONDS = "seconds",
  MINUTES = "minutes",
  HOURS = "hours",
  DAYS = "days",
}

export const timeMetrics: { [id: string]: string } = {
  milliseconds: "Milliseconds",
  seconds: "Seconds",
  minutes: "Minutes",
  hours: "Hours",
  days: "Days",
};

export const metricValues: { [id: string]: number } = {
  milliseconds: 1,
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000,
  days: 24 * 60 * 60 * 1000,
};

export interface StatusBadge {
  key: string;
  value: string | number;
  display_name: string;
  backgroundColor: string;
  color: string;
  renderBadge?: () => JSX.Element;
}

export type MultipleFormValue = {
  different: boolean;
  value?: any;
};
