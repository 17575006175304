import { Box, BoxProps } from "@mui/material";
import React from "react";

export const Flex = (props: JSX.IntrinsicAttributes & BoxProps) => {
  return (
    <Box display="flex" {...props}>
      {props.children}
    </Box>
  );
};
