import CollectionEditor from "../../../components/CollectionEditor/CollectionEditor";

import Endpoints from "../../../constants/endpoints";
import { IObjective } from "../../../model/daily-tasks.model";
import { useCollection } from "../../../network/useCollection";
import { ObjectivesModel } from "./ObjectivesModel";

export const ObjectivesCrud = () => {
  const { collection: objectives, isLoading } = useCollection<IObjective>(
    Endpoints.OBJECTIVES
  );
  const model = ObjectivesModel();

  return (
    <CollectionEditor
      isLoading={isLoading}
      page="objectives"
      response={objectives}
      model={model}
      endpoint={Endpoints.OBJECTIVES}
      displayNameTemplate={`{name}`}
    />
  );
};
