import useSWR from "swr";
import Endpoints from "../constants/endpoints";
import { getData } from "./request";

export function useConfig(suspense?: boolean) {
  const { data, error } = useSWR(Endpoints.CONFIG.url, getData, {
    suspense,
  });

  return {
    gameIds: Object.keys(data?.config.settings?.games_available ?? {}) as
      | string[]
      | undefined,
    games: data?.config.settings?.games_available as
      | Record<string, any>
      | undefined,
    config: data?.config.settings as Record<string, any> | undefined,
    isLoading: !error && !data,
    isError: error,
  };
}
