import React, { useEffect, useState } from "react";
import { Button, Input, CircularProgress, Alert, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { faFile, faSave } from "@fortawesome/free-solid-svg-icons";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  selectDarkMode,
  selectGameEditions,
  selectLoadingGeneral,
  setCurrentPlatform,
} from "../general/generalSlice";
import {
  FETCH_COHORTED_TESTS,
  FETCH_GAME_EDITIONS,
} from "../general/generalThunks";
import {
  currentToastSelector,
  selectChangedSettings,
  selectDeletedSettings,
  selectIsSelectorOpen,
  selectloadingJanusSetting,
  selectNewSettings,
  selectJanusSettings,
  SET_SELECTOR,
  ADD_NEW_JANUS_SETTING,
} from "./JanusSettingsSlice";
import { FETCH_JANUS_SETTINGS, SAVE_SETTINGS } from "./JanusSettingsThunks";
import { ToastTypes } from "../LogsPage/ToastTypes";
import { QUEUE_NOTIFICATION } from "../LogsPage/logSlice";
import JanusSetting, { IJanusSetting } from "./JanusSetting";
import { fetchConfig, fetchSettings } from "../AddToAll/addToAllThunks";
import { getGameID } from "../../utils/game-admin.util";
import JanusSettingsSelector from "./JanusSettingSelector";
import { combinedSettingsSelector } from "../AddToAll/addToAllSlice";
import { darkTheme } from "../AddToAll/styles/DarkTheme";
import { lightTheme } from "../AddToAll/styles/LightTheme";
import { lilacTheme } from "../AddToAll/styles/LilacTheme";
import { Flex } from "../../components/Flex";

const GlobalStyle = createGlobalStyle`
body {
transition: background-color .2s ease-in-out, color .2s ease-in-out;

  background-color: ${(props) =>
    //@ts-ignore
    props.theme.backgroundColor};
  
  color: ${(props) =>
    //@ts-ignore
    props.theme.textColor};
}
`;

const JanusSettingsPage = () => {
  const dispatch = useDispatch();
  const janusSettings = useSelector(selectJanusSettings);
  const loadingJanusSettings = useSelector(selectloadingJanusSetting);

  const darkMode = useSelector(selectDarkMode);
  const isDarkMode = darkMode === "dark";
  const isLilacMode = darkMode === "lilac";

  const changedSettings = useSelector(selectChangedSettings);
  const newSettings = useSelector(selectNewSettings);
  const deletedSettings = useSelector(selectDeletedSettings);

  const [searchTerm, setSearchTerm] = useState("");

  const openSelector = useSelector(selectIsSelectorOpen);

  const currentToast = useSelector(currentToastSelector);

  useEffect(() => {
    const handleFetch = async (name: string, func: () => void) => {
      try {
        await dispatch(func());
      } catch (error) {
        const errorMessage = `Error fetching ${name}`;

        dispatch(
          QUEUE_NOTIFICATION({
            message: errorMessage,
            type: ToastTypes.ERROR,
            id: `error_fetch_${name}`,
          })
        );
      }
    };

    handleFetch("janus settings", () => dispatch(FETCH_JANUS_SETTINGS()));
    //@ts-ignore
    handleFetch("settings", fetchSettings);
    handleFetch("config", fetchConfig);
    handleFetch("game editions", () => dispatch(FETCH_GAME_EDITIONS()));
    handleFetch("cohorted tests", () => dispatch(FETCH_COHORTED_TESTS()));
  }, [dispatch]);

  useEffect(() => {
    if (currentToast) {
      switch (currentToast.type) {
        case ToastTypes.ERROR:
          dispatch(
            QUEUE_NOTIFICATION({
              message: currentToast.message,
              type: ToastTypes.ERROR,
              id: `error_fetching`,
            })
          );
          break;
        case ToastTypes.SUCCESS:
          dispatch(
            QUEUE_NOTIFICATION({
              message: currentToast.message,
              type: ToastTypes.SUCCESS,
              id: `success_fetching`,
            })
          );
          break;
        case ToastTypes.WARN:
          dispatch(
            QUEUE_NOTIFICATION({
              message: currentToast.message,
              type: ToastTypes.WARN,
              id: `success_fetching`,
            })
          );

          break;
      }
      dispatch(QUEUE_NOTIFICATION(undefined));
    }
  }, [currentToast, dispatch]);

  const gameEditions = useSelector(selectGameEditions);
  const loadingGameEditions = useSelector(selectLoadingGeneral).gameEditions;

  return (
    <ThemeProvider
      theme={isDarkMode ? darkTheme : isLilacMode ? lilacTheme : lightTheme}
    >
      <GlobalStyle />
      <>
        <Box mb={3} p={2}>
          <Box my={3} display="flex" justifyContent="flex-end">
            <Box alignSelf="flex-start">
              <Button
                disabled={loadingJanusSettings}
                sx={{ mx: 2 }}
                color="primary"
                variant="outlined"
                onClick={() => {
                  dispatch(
                    SAVE_SETTINGS({
                      janusSettings,
                      changedSettings,
                      newSettings,
                      deletedSettings,
                    })
                  );
                }}
              >
                Save Changes
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={loadingJanusSettings}
                onClick={() =>
                  dispatch(ADD_NEW_JANUS_SETTING({ game_id: getGameID() }))
                }
              >
                Add New
              </Button>
            </Box>
          </Box>
        </Box>

        <Box mb={3} sx={{ mx: "auto" }}>
          {loadingJanusSettings ? (
            <Flex justifyContent="center">
              <CircularProgress />
            </Flex>
          ) : (
            <>
              <Input
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
                fullWidth
                placeholder="Search..."
              />
              {janusSettings
                ?.filter((k) =>
                  k.object_id?.toLowerCase()?.includes(searchTerm.toLowerCase())
                )
                .sort(
                  (a: IJanusSetting, b: IJanusSetting) =>
                    b.created_on! - a.created_on!
                )
                .map((janusSetting: IJanusSetting, index: number) => (
                  <JanusSetting
                    janusSettingIndex={index}
                    key={janusSetting.object_id}
                    janusSetting={janusSetting}
                    gameEditions={gameEditions}
                    loadingGameEditions={loadingGameEditions || false}
                  />
                ))}
              {janusSettings && janusSettings.length === 0 && (
                <Alert color="info">No Janus Settings Found</Alert>
              )}
              <JanusSettingsSelector
                isOpen={openSelector ?? false}
                handleClose={() => {
                  dispatch(
                    SET_SELECTOR({
                      isOpen: false,
                      object_id: undefined,
                      selectedId: undefined,
                    })
                  );
                  dispatch(setCurrentPlatform(undefined));
                }}
              />
            </>
          )}
        </Box>
      </>
    </ThemeProvider>
  );
};

export default JanusSettingsPage;
